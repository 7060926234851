import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useState } from 'react'

interface UseEntitySearchProps {
  onSearch: (searchTerm: string) => void
}

export const useEntitySearchByField = ({ onSearch }: UseEntitySearchProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  const searchHandler = async (term: string) => {
    if (term.length >= 3 || term.length === 0) {
      onSearch(term)
    }
  }

  const debouncedLoadData = useCallback(debounce(searchHandler, 750), [])

  const handleInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(ev.target.value)
      debouncedLoadData(ev.target.value)
    },
    [debouncedLoadData],
  )

  return {
    searchTerm,
    setSearchTerm,
    handleInputChange,
  }
}
