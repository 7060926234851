import { ChangeEvent } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'
import { Textarea, ProgressBar, SectionTitle } from 'components'
import { useProgressBarMessages } from 'hooks'

interface LogLineCardProps {
  title?: string
  titleClassName?: string
  exampleTitle?: string
  exampleLogline: string
  progressColor?: string
  value: string
  handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  descriptions?: string
  isEdit?: boolean
  isStep?: boolean
}

const MAX_AMOUNT = 500

export const LogLineCard = ({
  exampleTitle = 'Example logline',
  exampleLogline,
  progressColor,
  value,
  descriptions,
  handleChange,
  title,
  titleClassName,
  isEdit,
  isStep,
}: LogLineCardProps) => {
  const valueLength = value?.length ?? 0
  const { maxLengthMessage } = useProgressBarMessages({
    value: valueLength,
    max: MAX_AMOUNT,
  })

  return (
    <div className={twMerge('rounded-md', isStep && 'bg-grey-3 p-3')}>
      {!isEdit && (
        <>
          {title && <SectionTitle title={title} className={titleClassName} />}
          {descriptions && (
            <p className='w-full pb-3 text-sm leading-5 text-primary-grey'>
              {descriptions}
            </p>
          )}
          <p className='pt-1 text-xs font-medium text-primary-grey'>
            {exampleTitle}
          </p>
          <p className='pb-3 text-sm italic leading-5 text-red-9'>
            {exampleLogline}
          </p>
        </>
      )}
      <Textarea
        value={value}
        onChange={handleChange}
        wrapperClassName='pb-2'
        className={twJoin(
          'h-[128px]',
          maxLengthMessage &&
            'border-status-warning enabled:data-[error=false]:hover:border-status-warning',
        )}
      />
      <ProgressBar
        value={valueLength}
        max={MAX_AMOUNT}
        progressClassName='bg-primary-black'
        hasRightContent
        wrapperClassName={progressColor}
      />
    </div>
  )
}
