import type {
  MgmtTableColData,
  MgmtTableRowData,
  MgmtTableTypes,
} from '../../types'
import {
  MgmtTableCell,
  MgmtTableHeaderCell,
  MgmtTableHeaderRow,
  MgmtTableRow,
} from './components'

export const defaultHeaderRowRenderer = (
  params: MgmtTableTypes['HeaderRowRendererParams'],
) => <MgmtTableHeaderRow>{params.rowContent}</MgmtTableHeaderRow>

export const defaultRowRenderer = (
  params: MgmtTableTypes['RowRendererParams'],
) => <MgmtTableRow>{params.rowContent}</MgmtTableRow>

export const defaultHeaderCellRenderer = <
  Row extends MgmtTableRowData,
  Col extends MgmtTableColData,
>({
  cellContent,
  ...rest
}: MgmtTableTypes<Row, Col>['HeaderCellRendererParams']) => (
  <MgmtTableHeaderCell {...rest}>{cellContent}</MgmtTableHeaderCell>
)

export const defaultCellRenderer = <
  Row extends MgmtTableRowData,
  Col extends MgmtTableColData,
>({
  cellContent,
  ...rest
}: MgmtTableTypes<Row, Col>['CellRendererParams']) => (
  <MgmtTableCell {...rest}>{cellContent}</MgmtTableCell>
)
