import { twMerge } from 'tailwind-merge'

interface TitleBlockStepProps {
  title: string
  descriptions: string
  wrapperClassName?: string
}

export const TitleBlockStep = ({
  title,
  descriptions,
  wrapperClassName,
}: TitleBlockStepProps) => {
  return (
    <div className={twMerge(wrapperClassName)}>
      <h2 className='text-lg font-medium leading-6'>{title}</h2>
      <span className='text-sm text-primary-grey'>{descriptions}</span>
    </div>
  )
}
