import { useEffect, useState } from 'react'
import { Checkbox, RadioButton, SectionTitle } from 'components'
import { OptionFilter } from 'types'
import { twMerge } from 'tailwind-merge'
import { useCallback } from 'react'

interface FilterColumnGroupProps {
  category: string
  title?: string
  optionsFilter: OptionFilter[]
  selectedFilters: { [key: string]: string[] }
  onChange: (selected: { [key: string]: string[] }) => void
  isSelect?: boolean
  isCheckbox?: boolean
  titleClassName?: string
  labelRadioClass?: string
  hasAll?: boolean
  allLabel?: string
  name?: string
  isSingleSelection?: boolean
  allAsReset?: boolean
  selectOptionToReset?: number
  checkboxSize?: 'small' | 'medium'
  filterByString?: string
}

export const FilterColumnGroup = ({
  category,
  title,
  optionsFilter,
  selectedFilters,
  onChange,
  isSelect = false,
  titleClassName,
  labelRadioClass,
  hasAll = false,
  allLabel = 'All',
  name,
  isSingleSelection = false,
  allAsReset = false,
  selectOptionToReset,
  checkboxSize = 'medium',
  filterByString = '',
}: FilterColumnGroupProps) => {
  const numberToReset = selectOptionToReset || optionsFilter.length
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([])
  const handleCheckboxChange = useCallback(
    (value: string, index?: number) => {
      if (isSingleSelection) {
        const newSelectedFilters: { [key: string]: string[] } = {}
        newSelectedFilters[category] = [value]

        return onChange(newSelectedFilters)
      } else {
        const newSelectedFilters = { ...selectedFilters }
        if (allAsReset) {
          newSelectedFilters[category] = newSelectedFilters[category]?.filter(
            item => item !== 'all',
          )
        }
        if (newSelectedFilters[category]?.includes(value)) {
          newSelectedFilters[category] = newSelectedFilters[category].filter(
            item => item !== value,
          )
          if (allAsReset && newSelectedFilters[category].length === 0) {
            newSelectedFilters[category] = ['all']
          }
        } else {
          newSelectedFilters[category] = [
            ...(newSelectedFilters[category] || []),
            value,
          ]
        }

        onChange(newSelectedFilters)
      }
    },
    [onChange],
  )

  const handleSelectAll = () => {
    if (allAsReset) {
      return onChange({ [category]: ['all'] })
    }
    const newSelectedFilters = { ...selectedFilters }
    if (!isAllSelected) {
      newSelectedFilters[category] = optionsFilter.map(option => option.value)
    } else {
      newSelectedFilters[category] = optionsFilter
        .filter(option => option.isDisabled)
        .map(option => option.value)
    }
    onChange(newSelectedFilters)
  }

  const isAllSelected =
    allAsReset && selectedFilters[category]?.length === numberToReset
      ? onChange({ [category]: ['all'] })
      : (allAsReset && selectedFilters[category]?.includes('all')) ||
        (numberToReset > 0 &&
          selectedFilters[category]?.length === numberToReset)

  return (
    <div className='flex w-max flex-col gap-2'>
      {title && (
        <SectionTitle
          title={title ?? ''}
          className={twMerge(
            'text-left text-xs text-primary-black',
            titleClassName,
          )}
        />
      )}

      {!isSelect && !hasAll && (
        <div className='flex items-center gap-2'>
          <Checkbox
            checked={
              isAllSelected || selectedFilters[category]?.includes('all')
            }
            onChange={handleSelectAll}
            isNotAllChecked={
              selectedFilters[category]?.length > 0 && !isAllSelected
            }
            kind={checkboxSize}
          />
          <span className='text-xs text-primary-grey'>{allLabel}</span>
        </div>
      )}

      <ul className='flex flex-col gap-2'>
        {optionsFilter
          .filter(
            option =>
              !filterByString ||
              option.label.toLowerCase().includes(filterByString.toLowerCase()),
          )
          .map((option, index) => {
            return (
              <li key={`${option.id}_${index + 1}`}>
                {!isSelect ? (
                  <Checkbox
                    label={option.label}
                    checked={selectedFilters[category]?.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value, index)}
                    disabled={
                      option.isDisabled ||
                      (selectedIndexes.length > 0 &&
                        !selectedIndexes.includes(index) &&
                        !selectedIndexes.includes(index - 1) &&
                        !selectedIndexes.includes(index + 1))
                    }
                    kind={checkboxSize}
                  />
                ) : (
                  <RadioButton
                    label={option.label}
                    checked={selectedFilters[category]?.includes(option.value)}
                    onChange={() => {
                      handleCheckboxChange(option.value)
                    }}
                    labelClass={labelRadioClass}
                    id={option.value}
                    name={
                      name
                        ? name.replace('/', '_')
                        : title && title.replace('/', '_')
                    }
                    slug={title}
                    disabled={option.isDisabled}
                    tooltipText={option.tooltipText}
                  />
                )}
              </li>
            )
          })}
      </ul>
    </div>
  )
}
