import { twMerge } from 'tailwind-merge'
import { useProgressBarMessages } from 'hooks'

interface ProgressBarProps {
  value: number
  max: number
  progressClassName?: string
  wrapperClassName?: string
  hasRightContent?: boolean
  maxLengthTextClassName?: string
}

export const ProgressBar = ({
  value,
  max,
  progressClassName,
  wrapperClassName,
  hasRightContent,
  maxLengthTextClassName,
}: ProgressBarProps) => {
  const { bestResultsMessage, percentage, maxLengthMessage } =
    useProgressBarMessages({ value, max })
  const isAmountValue = hasRightContent && value > 0 && value !== max

  return (
    <div className='flex items-center gap-1'>
      <div
        className={twMerge(
          'h-2 w-12 overflow-hidden rounded-full bg-primary-black/10',
          wrapperClassName,
        )}
      >
        <div
          className={twMerge(
            'h-2 rounded-bl-full rounded-br rounded-tl-full rounded-tr',
            value > max - 4 && 'rounded-br-full rounded-tr-full',
            progressClassName,
            maxLengthMessage && 'bg-status-warning',
          )}
          style={{ width: `${percentage}%` }}
        />
      </div>

      {isAmountValue && !bestResultsMessage && (
        <p className='text-xs text-primary-grey'>{`${value}/${max}`}</p>
      )}

      {bestResultsMessage && !maxLengthMessage && (
        <p className='text-xs text-primary-grey'>{bestResultsMessage}</p>
      )}

      {maxLengthMessage && (
        <p
          className={twMerge(
            'text-xs text-status-warning',
            maxLengthTextClassName,
          )}
        >
          {maxLengthMessage}
        </p>
      )}
    </div>
  )
}
