import { api } from '../api'
export const addTagTypes = ['FilmMetrics'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      filmMetricsControllerGetFilmTracking: build.query<
        FilmMetricsControllerGetFilmTrackingApiResponse,
        FilmMetricsControllerGetFilmTrackingApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics`,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            sortBy: queryArg.sortBy,
            sortOrder: queryArg.sortOrder,
            audienceFilter: queryArg.audienceFilter,
            theatergoersOnly: queryArg.theatergoersOnly,
            dist: queryArg.dist,
            groups: queryArg.groups,
            filmName: queryArg.filmName,
            filmIds: queryArg.filmIds,
            metricsType: queryArg.metricsType,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmMetricsByFilmId: build.query<
        FilmMetricsControllerGetFilmMetricsByFilmIdApiResponse,
        FilmMetricsControllerGetFilmMetricsByFilmIdApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/film/${queryArg.filmId}/metrics`,
          params: {
            metricsType: queryArg.metricsType,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmTrackingDistributors: build.query<
        FilmMetricsControllerGetFilmTrackingDistributorsApiResponse,
        FilmMetricsControllerGetFilmTrackingDistributorsApiArg
      >({
        query: () => ({ url: `/filmMetrics/distributors` }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmTrackingGroups: build.query<
        FilmMetricsControllerGetFilmTrackingGroupsApiResponse,
        FilmMetricsControllerGetFilmTrackingGroupsApiArg
      >({
        query: () => ({ url: `/filmMetrics/groups` }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmTrackingFilters: build.query<
        FilmMetricsControllerGetFilmTrackingFiltersApiResponse,
        FilmMetricsControllerGetFilmTrackingFiltersApiArg
      >({
        query: () => ({ url: `/filmMetrics/filters` }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetSearchFilm: build.query<
        FilmMetricsControllerGetSearchFilmApiResponse,
        FilmMetricsControllerGetSearchFilmApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/films`,
          params: {
            filmName: queryArg.filmName,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetChartData: build.query<
        FilmMetricsControllerGetChartDataApiResponse,
        FilmMetricsControllerGetChartDataApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/charts`,
          params: {
            attribute: queryArg.attribute,
            filmIds: queryArg.filmIds,
            metricsType: queryArg.metricsType,
            audienceFilter: queryArg.audienceFilter,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetBoxOffice: build.query<
        FilmMetricsControllerGetBoxOfficeApiResponse,
        FilmMetricsControllerGetBoxOfficeApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/boxOffice/${queryArg.filmId}`,
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetOpeningWeekendProjections: build.query<
        FilmMetricsControllerGetOpeningWeekendProjectionsApiResponse,
        FilmMetricsControllerGetOpeningWeekendProjectionsApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/owProjections/${queryArg.filmId}`,
        }),
        providesTags: ['FilmMetrics'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as filmMetricsApi }
export type FilmMetricsControllerGetFilmTrackingApiResponse =
  /** status 200 Retrieve paginated list of films */ PaginatedFilmMetricsResponse
export type FilmMetricsControllerGetFilmTrackingApiArg = {
  pageSize?: number
  pageNumber?: number
  sortBy?:
    | 'awareness'
    | 'interestawaretab'
    | 'interest'
    | 'location'
    | 'paytosee'
    | 'unaidedawareness'
    | 'awarenessinterest'
    | 'heat'
    | 'plf'
    | 'latest_date_survey_id'
    | 'film_name'
    | 'company_name'
    | 'film_group_key'
    | 'rating'
  sortOrder?: 'ASC' | 'DESC'
  audienceFilter?:
    | 'ALL'
    | 'GENDER_FEMALE'
    | 'GENDER_MALE'
    | 'AGE_SEGMENT_35_PLUS'
    | 'AGE_SEGMENT_UNDER_35'
    | 'AGE_GENDER_SEGMENT_M_PLUS'
    | 'AGE_GENDER_SEGMENT_M_LESS'
    | 'AGE_GENDER_SEGMENT_W_PLUS'
    | 'AGE_GENDER_SEGMENT_W_LESS'
    | 'IDENTITY_LGBT_QI'
    | 'RACE_BLACK'
    | 'RACE_BLACK_GENDER_MALE'
    | 'RACE_BLACK_GENDER_FEMALE'
    | 'RACE_BLACK_AGE_SEGMENT_UNDER_35'
    | 'RACE_BLACK_AGE_SEGMENT_35_PLUS'
    | 'RACE_WHITE'
    | 'RACE_WHITE_GENDER_MALE'
    | 'RACE_WHITE_GENDER_FEMALE'
    | 'RACE_WHITE_AGE_SEGMENT_UNDER_35'
    | 'RACE_WHITE_AGE_SEGMENT_35_PLUS'
    | 'RACE_HISPANIC'
    | 'RACE_HISPANIC_GENDER_MALE'
    | 'RACE_HISPANIC_GENDER_FEMALE'
    | 'RACE_HISPANIC_AGE_SEGMENT_UNDER_35'
    | 'RACE_HISPANIC_AGE_SEGMENT_35_PLUS'
  theatergoersOnly?: boolean
  /** array of Company ids to filter */
  dist?: number[]
  /** array of group ids to filter */
  groups?: number[]
  filmName?: string
  /** array of film ids */
  filmIds?: number[]
  metricsType?: 'PERCENTAGE' | 'RANK' | 'COUNT'
}
export type FilmMetricsControllerGetFilmMetricsByFilmIdApiResponse =
  /** status 200 Retrieve paginated list of films */ FilmMetricsDetailResponseDto
export type FilmMetricsControllerGetFilmMetricsByFilmIdApiArg = {
  filmId: number
  metricsType?: 'PERCENTAGE' | 'RANK' | 'COUNT'
}
export type FilmMetricsControllerGetFilmTrackingDistributorsApiResponse =
  unknown
export type FilmMetricsControllerGetFilmTrackingDistributorsApiArg = void
export type FilmMetricsControllerGetFilmTrackingGroupsApiResponse = unknown
export type FilmMetricsControllerGetFilmTrackingGroupsApiArg = void
export type FilmMetricsControllerGetFilmTrackingFiltersApiResponse =
  /** status 200 Retrieve film filters group, distributors */ FilmFilterResponse
export type FilmMetricsControllerGetFilmTrackingFiltersApiArg = void
export type FilmMetricsControllerGetSearchFilmApiResponse =
  /** status 200 Search Film by Name */ FilmSearchByName[]
export type FilmMetricsControllerGetSearchFilmApiArg = {
  filmName: string
}
export type FilmMetricsControllerGetChartDataApiResponse =
  /** status 200 Retrieve Chart data by film ids and attribute */ FilmChartData[]
export type FilmMetricsControllerGetChartDataApiArg = {
  attribute:
    | 'awareness'
    | 'interest'
    | 'location'
    | 'paytosee'
    | 'unaidedawareness'
    | 'awarenessinterest'
    | 'heat'
    | 'plf'
  /** array of film ids */
  filmIds: number[]
  metricsType?: 'PERCENTAGE' | 'RANK' | 'COUNT'
  audienceFilter?:
    | 'ALL'
    | 'GENDER_FEMALE'
    | 'GENDER_MALE'
    | 'AGE_SEGMENT_35_PLUS'
    | 'AGE_SEGMENT_UNDER_35'
    | 'AGE_GENDER_SEGMENT_M_PLUS'
    | 'AGE_GENDER_SEGMENT_M_LESS'
    | 'AGE_GENDER_SEGMENT_W_PLUS'
    | 'AGE_GENDER_SEGMENT_W_LESS'
    | 'IDENTITY_LGBT_QI'
    | 'RACE_BLACK'
    | 'RACE_BLACK_GENDER_MALE'
    | 'RACE_BLACK_GENDER_FEMALE'
    | 'RACE_BLACK_AGE_SEGMENT_UNDER_35'
    | 'RACE_BLACK_AGE_SEGMENT_35_PLUS'
    | 'RACE_WHITE'
    | 'RACE_WHITE_GENDER_MALE'
    | 'RACE_WHITE_GENDER_FEMALE'
    | 'RACE_WHITE_AGE_SEGMENT_UNDER_35'
    | 'RACE_WHITE_AGE_SEGMENT_35_PLUS'
    | 'RACE_HISPANIC'
    | 'RACE_HISPANIC_GENDER_MALE'
    | 'RACE_HISPANIC_GENDER_FEMALE'
    | 'RACE_HISPANIC_AGE_SEGMENT_UNDER_35'
    | 'RACE_HISPANIC_AGE_SEGMENT_35_PLUS'
}
export type FilmMetricsControllerGetBoxOfficeApiResponse =
  /** status 200 Response box-office universe data for the given filmId */ FilmBoxOfficeResponse
export type FilmMetricsControllerGetBoxOfficeApiArg = {
  filmId: number
}
export type FilmMetricsControllerGetOpeningWeekendProjectionsApiResponse =
  /** status 200 Response opening weekend projection table data */ FilmProjectionResponse
export type FilmMetricsControllerGetOpeningWeekendProjectionsApiArg = {
  filmId: number
}
export type Distribution = {
  /** Name of the distribution company */
  name: string | null
  /** Abbreviation for the distributor */
  abbr: string | null
  /** Value associated with this distributor */
  value: number | null
}
export type Group = {
  /** Name of the group */
  name: string | null
  /** Abbreviation for the group */
  abbr: string | null
  /** Value associated with this group */
  value: number | null
}
export type ChangeInfo = {
  /** Abbreviation for the change information */
  abbr: string | null
  /** Value of the change */
  value: string | null
}
export type Demographic = {
  /** Abbreviation */
  abbr: string | null
  /** Value for this demographic group */
  value: number | null
}
export type HiddenInfo = {
  /** Change information */
  change: ChangeInfo | null
  /** Average information */
  avg: Demographic | null
  /** Location for men */
  location_men: Demographic | null
  /** Location for women */
  location_women: Demographic | null
}
export type GeneralData = {
  /** General value */
  general: number | null
  /** Additional hidden demographic information */
  hiddenInfo: HiddenInfo | null
}
export type FilmData = {
  /** Film ID */
  id: number
  /** Film name */
  film: string | null
  /** Film release date in YYYYMMDD format */
  date: number | null
  /** Distribution information */
  dist: Distribution | null
  /** Group information */
  group: Group | null
  /** Film rating */
  rating: number | null
  /** General heat metric */
  general_heat: number | null
  /** General PLF metric */
  general_plf: number | null
  /** General location metrics */
  general_location: GeneralData | null
  /** General interest metrics */
  general_interest: GeneralData | null
  /** General interest aware tab metrics */
  general_interest_aware: GeneralData | null
  /** General pay-to-see metrics */
  general_pay_to_see: GeneralData | null
  /** General awareness metrics */
  general_awareness: GeneralData | null
  /** General awareness interest metric */
  general_awareness_interest: number | null
  /** General unaided awareness metric */
  general_unaided_awareness: number | null
}
export type PaginatedFilmMetricsResponse = {
  /** Total records count */
  totalRecords: number | null
  /** Total pages count */
  totalPages: number | null
  /** Current page number */
  currentPage: number | null
  /** Page size */
  pageSize: number | null
  /** List of film data for this page */
  data: FilmData[] | null
}
export type CastDto = {
  /** Name of the cast member */
  name: string
  /** Unique key for the cast member */
  key: string
  /** URL of the cast member's image */
  img?: string | null
  /** Id of the cast member */
  id: number | null
}
export type CompanyDataDto = {
  /** ID of the company */
  company_id: number
  /** Name of the company */
  company_name: string
}
export type FilmMetricDto = {
  /** Metric key (e.g., awareness, interest) */
  key: string
  /** Metric label for display purposes */
  label: string
  /** Value of the metric */
  value: number
}
export type FilmMetricsDetailResponseDto = {
  /** Unique ID of the film */
  film_id: number
  /** Unique key for the film */
  film_key: string | null
  /** Name of the film */
  film_name: string | null
  /** Aggregate rating of the film */
  aggregate_rating?: number | null
  /** Cast members of the film */
  cast: CastDto[] | null
  /** Countries of origin of the film */
  countries_of_origin?: string | null
  /** External links related to the film */
  external_links?: string | null
  /** IMDb URL for the film */
  imdb_url?: string | null
  /** Indicates if the film is for adult audiences */
  is_adult?: boolean | null
  /** Plot of the film */
  plot?: string | null
  /** Primary image URL for the film */
  primary_image_url?: string | null
  /** Rating of the film */
  rating?: number | null
  /** Reason for the film's rating */
  rating_reason?: string | null
  /** Release day of the film */
  release_day?: number | null
  /** Release month of the film */
  release_month?: number | null
  /** Release year of the film */
  release_year?: number | null
  /** Runtime of the film in minutes */
  runtime?: number | null
  /** Subgenres of the film */
  subgenres?: string | null
  /** Total vote count for the film */
  vote_count?: string | null
  /** ID of the film's group */
  film_group_id?: number | null
  /** ID of the film's Genre */
  genre_id?: number | null
  /** Type of the film */
  film_type?: string | null
  /** Company data related to the film */
  companies_data: CompanyDataDto[] | null
  /** List of film metrics */
  film_metrics: FilmMetricDto[] | null
  /** Film Group key */
  film_group_key?: string | null
  /** Film Group name */
  film_group_name?: string | null
  /** Film Genre key */
  genre_key?: string | null
  /** Film Genre name */
  genre_name?: string | null
}
export type FilmGroup = {
  /** Unique identifier for the film group */
  film_group_id: number
  /** Key for the film group */
  film_group_key: string | null
  /** Name of the film group */
  film_group_name: string | null
}
export type Distributor = {
  /** Name of the company */
  company_name: string | null
  /** Unique identifier for the company */
  company_id: number
  /** Type of distributor */
  distributor_type: string | null
  /** Abbreviation for the distributor */
  abbreviation: string | null
  /** Company type */
  company_type: string | null
  /** Company key identifier */
  company_key: string | null
}
export type FilmFilterResponse = {
  /** List of film groups */
  groups: FilmGroup[] | null
  /** List of distributors */
  distributors: Distributor[] | null
}
export type FilmSearchByName = {
  /** Unique identifier for the film group */
  film_id: string
  /** Name of the film */
  film_name: string
}
export type ChartData = {
  /** Date of the data value in YYYYMMDD format */
  date_survey_id: number
  /** The recorded value for this date */
  value: number
  /** days before release */
  days_before_release: number
}
export type FilmChartData = {
  /** Unique identifier for the film */
  film_id: number
  /** Name of the film film */
  film_name: string
  /** Release date of the film YYYYMMDD format */
  release_date?: number | null
  /** Array of data values by date for the film */
  data: ChartData[]
}
export type FilmBoxOfficeData = {
  /** The unique identifier of the film */
  film_id: number
  /** The rating of the film */
  rating: string
  /** The name of the film */
  film_name: string
  /** The opening weekend revenue of the film */
  open_weekend: number | null
  /** The adjusted opening weekend revenue of the film */
  ow_adjust: number
  /** The number of theatres for the film */
  ow_theatres: number | null
  /** The total gross revenue of the film */
  total_gross: number | null
  /** The distribution company or companies */
  dist: string
  /** Date of release value in YYYYMMDD format */
  release_date: number
}
export type FilmBoxOfficeResponse = {
  /** The box office data for the film */
  data: FilmBoxOfficeData[]
  /** Box office note for current film */
  boxOfficeNote: string | null
}
export type FilmProjectionRow = {
  row_num: number
  row_value: string
  c42: boolean
  c35: boolean
  c28: boolean
  c21: boolean
  c14: boolean
  c7: boolean
  c0: boolean
}
export type FilmProjectionResponse = {
  data: FilmProjectionRow[]
  currentProjection: string | null
}
export const {
  useFilmMetricsControllerGetFilmTrackingQuery,
  useLazyFilmMetricsControllerGetFilmTrackingQuery,
  useFilmMetricsControllerGetFilmMetricsByFilmIdQuery,
  useLazyFilmMetricsControllerGetFilmMetricsByFilmIdQuery,
  useFilmMetricsControllerGetFilmTrackingDistributorsQuery,
  useLazyFilmMetricsControllerGetFilmTrackingDistributorsQuery,
  useFilmMetricsControllerGetFilmTrackingGroupsQuery,
  useLazyFilmMetricsControllerGetFilmTrackingGroupsQuery,
  useFilmMetricsControllerGetFilmTrackingFiltersQuery,
  useLazyFilmMetricsControllerGetFilmTrackingFiltersQuery,
  useFilmMetricsControllerGetSearchFilmQuery,
  useLazyFilmMetricsControllerGetSearchFilmQuery,
  useFilmMetricsControllerGetChartDataQuery,
  useLazyFilmMetricsControllerGetChartDataQuery,
  useFilmMetricsControllerGetBoxOfficeQuery,
  useLazyFilmMetricsControllerGetBoxOfficeQuery,
  useFilmMetricsControllerGetOpeningWeekendProjectionsQuery,
  useLazyFilmMetricsControllerGetOpeningWeekendProjectionsQuery,
} = injectedRtkApi
