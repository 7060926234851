import { BaseModal, Button, InformationBox, RoleList } from 'components'
import { SquarePlus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { useActions, useTypedSelector } from 'hooks'
import { RoleProject } from 'types'
import { useCreateProjectRoleMutation } from 'store/api'
import { createEmptyProjectRole, isProjectRoleComplete } from 'utils'

interface AddRoleModalContentProps {
  isOpen: boolean
  onClose: () => void
  projectId: number
}

export const AddRoleModalContent = ({
  isOpen,
  onClose,
  projectId,
}: AddRoleModalContentProps) => {
  const { tempRoles } = useTypedSelector(state => state.myProjectReducer)
  const { deleteTempRole, updateTempRole, setTempRoles, resetTempRoles } =
    useActions()
  const [createProjectRole] = useCreateProjectRoleMutation()

  const handleDeleteRole = (id: string) => {
    deleteTempRole(id)
  }

  const handleAddNewRole = () => {
    setTempRoles(createEmptyProjectRole())
  }

  const postRole = (role: RoleProject, projectId: number) =>
    createProjectRole({
      role: {
        name: role.roleName,
        type: role.roleType.value,
        description: role.description,
        talentId: +role.attachedTalent.id,
        status: 'active',
      },
      projectId: projectId,
    })

  const handleSaveMyRole = () => {
    tempRoles.forEach(role => {
      if (isProjectRoleComplete(role)) {
        postRole(role, projectId)
      }
    })
    resetTempRoles()
    onClose()
  }

  const handleUpdateChange = (roleId: string, data: Partial<RoleProject>) => {
    updateTempRole({ roleId, ...data })
  }

  return (
    <BaseModal isOpen={isOpen} handleClose={onClose} maxWidth='880px'>
      <div className='flex max-h-[700px] flex-col overflow-y-auto'>
        <span className='block py-3 text-lg font-medium leading-6 text-primary-black'>
          Add new Role(s)
        </span>
        <div className='flex w-full flex-col space-y-5 rounded bg-grey-3 p-3'>
          <RoleList
            roles={tempRoles}
            onDeleteRole={handleDeleteRole}
            onUpdateRole={handleUpdateChange}
          />
          <div className='flex flex-col space-y-5 rounded bg-grey-3 p-3'>
            <div className='flex w-full gap-4'>
              <Button
                kind='text'
                size='small'
                className='flex min-w-fit items-center gap-1'
                onClick={handleAddNewRole}
              >
                <SquarePlus fill={Colors.BASE_ICON} width={16} height={16} />
                <span>Add another Role</span>
              </Button>
              <div className='mb-3 w-full border-b border-b-primary-black/10' />
            </div>
            <InformationBox wrapperClassName='bg-primary-black/5 rounded-md'>
              <span className='text-sm text-primary-black'>
                Is the Talent you want to attach not coming up? Don’t worry.
                Leave the custom entry there. Our system will try to add the
                missing talent soon.
              </span>
            </InformationBox>
          </div>
        </div>
        <div className='ml-auto flex gap-3 pt-4'>
          <Button
            kind='text'
            size='medium'
            className='w-fit py-1.5'
            onClick={onClose}
          >
            <span className='px-1'>Cancel</span>
          </Button>
          <Button
            kind='filled'
            size='medium'
            className='w-fit bg-primary-red py-1.5 hover:bg-red-9 active:bg-red-10'
            onClick={handleSaveMyRole}
          >
            <span className='px-1'>Save</span>
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
