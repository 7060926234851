import { MyProjectLoglineItem, RadioButton } from 'components'
import { Logline } from 'types'
import { twJoin, twMerge } from 'tailwind-merge'
import { formatDate } from 'utils'

interface LoglineCardProps {
  loglineData: Logline
  isActive: boolean
  toggleActive: () => void
  onOpenEdit: () => void
}

export const LoglineCard = ({
  loglineData,
  isActive,
  toggleActive,
  onOpenEdit,
}: LoglineCardProps) => {
  const { name, myLogline, lastEdited, id } = loglineData

  return (
    <div className='h-fit overflow-hidden rounded border border-primary-black/10 shadow-md'>
      <div
        className={twMerge(
          'border-b border-b-primary-black/20 bg-primary-white p-3',
          !isActive && 'bg-grey-3',
        )}
      >
        <div className='flex justify-between'>
          <span className='font-lg block font-medium leading-6 text-primary-black'>
            {name}
          </span>
          <div className='flex items-center'>
            {isActive && (
              <span className='pr-2 text-sm text-green-6'>Active</span>
            )}
            <RadioButton
              id={id}
              onClick={toggleActive}
              checked={isActive}
              wrapperClass={twJoin(!isActive && 'bg-transparent')}
              readOnly
            />
          </div>
        </div>
        <span className='text-xs text-grey-7'>
          Last Edited:{formatDate(lastEdited, 'dddd,MMM DD,YYYY')}
        </span>
      </div>
      <div className='flex h-full flex-col space-y-2 bg-grey-3 p-3'>
        <MyProjectLoglineItem
          descriptions={myLogline}
          isActive={isActive}
          onOpenEdit={onOpenEdit}
        />
      </div>
    </div>
  )
}
