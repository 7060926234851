import React, { FC } from 'react'
import { Search } from '../icons'
import { Colors } from 'constnants'

interface ResultLookupErrorProps {
  title?: string
  description?: string
}

const ResultLookupError = React.memo(
  ({
    title = 'No results found',
    description = 'Please check spelling \n and filter settings.',
  }: ResultLookupErrorProps) => (
    <div className='flex flex-col items-center gap-2 py-2'>
      <Search fill={Colors.BASE_ICON} width={24} height={24} />
      <div className='flex flex-col items-center gap-0.5'>
        <div className='text-[14px]/[24px] font-semibold'>{title}</div>
        <div className='text-center text-xs text-primary-grey'>
          {description}
        </div>
      </div>
    </div>
  ),
)

ResultLookupError.displayName = 'ResultLookupError'

export default ResultLookupError
