import React, { Key, ReactElement, useEffect, useState } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'
import { TabHeader, TabProps } from 'components'

export interface TabsProps {
  children: Array<ReactElement<TabProps>>
  initialSelectedTab?: Key | null
  wrapperClasses?: string
  tabHeaderClasses?: string
  handleSelectTab?: (tab: Key | null) => void
}

export const Tabs = ({
  children,
  initialSelectedTab,
  wrapperClasses,
  tabHeaderClasses,
  handleSelectTab,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState<React.Key | null>(null)
  const tabHeaders: TabHeader[] = children.map(
    (item: ReactElement<TabProps>) => {
      return {
        key: item.key,
        label: item.props.label,
        icon: item.props.icon,
        headerClasses: item.props.headerClasses,
        activeTabHeaderClasses: item.props.activeTabHeaderClasses,
        wrapperClasses: item.props.wrapperClasses,
      }
    },
  )

  useEffect(() => {
    if (initialSelectedTab !== activeTab) {
      setActiveTab(initialSelectedTab || tabHeaders[0].key)
    }
  }, [initialSelectedTab, activeTab, tabHeaders])

  return (
    <div
      className={twMerge('flex w-full min-w-[300px] flex-col', wrapperClasses)}
    >
      <div
        className={twMerge('grid auto-cols-fr grid-flow-col', tabHeaderClasses)}
      >
        {tabHeaders.map(tabHeader => (
          <div
            key={tabHeader.key}
            onClick={() => {
              handleSelectTab && handleSelectTab(tabHeader.key)
              setActiveTab(tabHeader.key)
            }}
            className={twMerge(
              'flex min-w-max cursor-pointer flex-row justify-center border-b-2 border-b-grey-5 bg-primary-black/5 p-2 text-sm font-semibold uppercase text-primary-grey transition-colors duration-300 hover:bg-primary-black/10',
              tabHeader.key === activeTab
                ? twJoin(
                    'border-primary-blue bg-primary-white text-primary-blue',
                    tabHeader.activeTabHeaderClasses,
                  )
                : '',
              tabHeader.headerClasses,
            )}
          >
            <div className='flex flex-row items-center justify-start gap-1'>
              <div>{tabHeader.icon && tabHeader.icon}</div>
              {tabHeader.label}
            </div>
          </div>
        ))}
      </div>

      {children.map((child: ReactElement<TabProps>) => {
        return (
          <div
            key={`${child.key}_content`}
            className={twMerge(
              'flex flex-col',
              child.key !== activeTab ? 'hidden' : '',
              child.props?.wrapperClasses,
            )}
          >
            {child.props?.children || null}
          </div>
        )
      })}
    </div>
  )
}
