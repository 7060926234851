import { twMerge } from 'tailwind-merge'

interface AssigneeAvatarProps {
  initials: string
  className?: string
}

export const AssigneeAvatar = ({
  initials,
  className,
}: AssigneeAvatarProps) => {
  return (
    <div
      className={twMerge(
        'flex h-6 w-6 items-center justify-center rounded-full border border-primary-black/10 bg-green-1 text-[10px] font-semibold text-green-4',
        className,
      )}
    >
      {initials}
    </div>
  )
}
