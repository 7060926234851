import { sortAlphabetically } from 'utils'
import { DataFiltersType, NumericRangeFilter, OptionFilter } from '../types'
import { dataDistributorsCompFinder } from './filmOptions'

export const releaseMonthOptions: OptionFilter[] = [
  { id: '1', value: '1', label: 'January' },
  { id: '2', value: '2', label: 'February' },
  { id: '3', value: '3', label: 'March' },
  { id: '4', value: '4', label: 'April' },
  { id: '5', value: '5', label: 'May' },
  { id: '6', value: '6', label: 'June' },
  { id: '7', value: '7', label: 'July' },
  { id: '8', value: '8', label: 'August' },
  { id: '9', value: '9', label: 'September' },
  { id: '10', value: '10', label: 'October' },
  { id: '11', value: '11', label: 'November' },
  { id: '12', value: '12', label: 'December' },
]

const hybridOptions: OptionFilter[] = [
  {
    id: '1',
    value: 'no',
    label: 'No',
  },
  {
    id: '2',
    value: 'yes',
    label: 'Yes',
  },
]

const boxOfficeOptions: NumericRangeFilter[] = [
  {
    minValue: '0',
    maxValue: '400',
  },
]

const totalGrossOptions: NumericRangeFilter[] = [
  {
    minValue: '0',
    maxValue: '999',
  },
]

const openingWeekendOptions: NumericRangeFilter[] = [
  {
    minValue: '500',
    maxValue: '5000',
  },
]

const ratingOptions: OptionFilter[] = [
  {
    id: '1',
    value: 'G',
    label: 'G',
  },
  {
    id: '2',
    value: 'PG',
    label: 'PG',
  },
  {
    id: '3',
    value: 'PG-13',
    label: 'PG-13',
  },
  {
    id: '4',
    value: 'R',
    label: 'R',
  },
  {
    id: '5',
    value: 'NR',
    label: 'NR',
  },
]

const budgetOptions: OptionFilter[] = [
  { id: '1', value: 'M_BELOW_50', label: '< 50' },
  { id: '2', value: 'M50_99', label: '50-99' },
  { id: '3', value: 'M100_124', label: '100-124' },
  { id: '4', value: 'M125Plus', label: '125+' },
]

const psrrOptions: OptionFilter[] = [
  { id: '1', value: 'prequel', label: 'Prequel' },
  { id: '2', value: 'sequel', label: 'Sequel' },
  { id: '3', value: 'remake/reboot', label: 'Remake/Reboot' },
]

const franchiseOptions: OptionFilter[] = sortAlphabetically(
  [
    { id: '1', value: 'austin_powers', label: 'Austin Powers' },
    { id: '2', value: 'batman', label: 'Batman' },
    { id: '3', value: 'bourne', label: 'Bourne' },
    { id: '4', value: 'captain_america', label: 'Captain America' },
    { id: '5', value: 'fast & furious', label: 'Fast & Furious' },
    { id: '6', value: 'harry_potter', label: 'Harry Potter' },
    { id: '7', value: 'indiana_jones', label: 'Indiana Jones' },
    { id: '8', value: 'the_XXX', label: 'The XXX' },
  ],
  it => it.label,
)

const universeOptions: OptionFilter[] = sortAlphabetically(
  [
    { id: '1', value: 'DC', label: 'DC' },
    { id: '2', value: 'marvel', label: 'Marvel' },
    { id: '3', value: 'MCU', label: 'MCU' },
    { id: '4', value: 'fast & furious', label: 'Fast & Furious' },
  ],
  it => it.label,
)

const primaryGenreOptions: OptionFilter[] = sortAlphabetically(
  [
    { id: '1', value: 'ACTION', label: 'Action' },
    { id: '2', value: 'COMEDY', label: 'Comedy' },
    { id: '3', value: 'DRAMA', label: 'Drama' },
    { id: '4', value: 'HORROR', label: 'Horror' },
    { id: '5', value: 'ANIMATION', label: 'Animation' },
    { id: '6', value: 'FAMILY (LIVE ACTION)', label: 'Family - Live Action' },
  ],
  it => it.label,
)

const secondaryGenreOptions: OptionFilter[] = sortAlphabetically(
  [
    { id: '1', value: 'ACTION', label: 'Action' },
    { id: '2', value: 'COMEDY', label: 'Comedy' },
    { id: '3', value: 'DRAMA', label: 'Drama' },
    { id: '4', value: 'HORROR', label: 'Horror' },
    { id: '5', value: 'ANIMATION', label: 'Animation' },
    { id: '6', value: 'FAMILY (LIVE ACTION)', label: 'Family (LIVE ACTION)' },
    { id: '7', value: 'REALITY', label: 'Reality' },
    { id: '8', value: 'ADULT', label: 'Adult' },
    { id: '9', value: 'ADVENTURE', label: 'Adventure' },
    { id: '10', value: 'BIOGRAPHY', label: 'Biography' },
    { id: '11', value: 'CRIME', label: 'Crime' },
    { id: '12', value: 'DOCUMENTARY', label: 'Documentary' },
    { id: '13', value: 'FAMILY', label: 'Family' },
    { id: '14', value: 'FANTASY', label: 'Fantasy' },
    { id: '15', value: 'GAME-SHOW', label: 'Game-Show' },
    { id: '16', value: 'HISTORY', label: 'History' },
    { id: '17', value: 'MUSIC', label: 'Music' },
    { id: '18', value: 'MUSICAL', label: 'Musical' },
    { id: '19', value: 'MYSTERY', label: 'Mystery' },
    { id: '20', value: 'NEWS', label: 'News' },
    { id: '21', value: 'REALITY-TV', label: 'Reality-TV' },
    { id: '22', value: 'ROMANCE', label: 'Romance' },
    { id: '23', value: 'SCI-FI', label: 'Sci-Fi' },
    { id: '24', value: 'SHORT', label: 'Short' },
    { id: '25', value: 'SPORT', label: 'Sport' },
    { id: '26', value: 'TALK-SHOW', label: 'Talk-Show' },
    { id: '27', value: 'THRILLER', label: 'Thriller' },
    { id: '28', value: 'WAR', label: 'War' },
    { id: '29', value: 'WESTERN', label: 'Western' },
  ],
  it => it.label,
)

const groupsOptions: OptionFilter[] = [
  { id: '1', value: 'MEDIUN', label: 'Mediun' },
  { id: '2', value: 'ANIMATION', label: 'Animation' },
  {
    id: '3',
    value: 'ANIMATION (ALSO LIVE ACTION)',
    label: 'Animation (Also Live Action)',
  },
  { id: '4', value: 'ANIMATION/FAMILY', label: 'Animation/Family' },
  { id: '5', value: 'DC/MC', label: 'DC/MC' },
  { id: '6', value: 'DC/MCU', label: 'DC/MCU' },
  { id: '7', value: 'DC/MCU (ORI)', label: 'DC/MCU (ORI)' },
  { id: '8', value: 'DC/MCU (SEQ)', label: 'DC/MCU (SEQ)' },
  { id: '9', value: 'DC/MCU (SEQUEL)', label: 'DC/MCU (Sequel)' },
  { id: '10', value: 'HORROR', label: 'Horror' },
  { id: '11', value: 'LOW', label: 'Low' },
  { id: '12', value: 'MEDIUM', label: 'Medium' },
  { id: '13', value: 'N/A', label: 'N/A' },
  { id: '14', value: 'TENTPOLE', label: 'Tentpole' },
  { id: '15', value: 'TENTPOLE (ORIG.)', label: 'Tentpole (Orig.)' },
  { id: '16', value: 'TENTPOLE (ORIGINAL)', label: 'Tentpole (Original)' },
  { id: '17', value: 'TENTPOLE (SEQUEL)', label: 'Tentpole (Sequel)' },
]

export const dataFiltersInitial: DataFiltersType[] = [
  {
    id: 'distributor',
    name: 'Distributor',
    options: dataDistributorsCompFinder,
  },
  { id: 'releaseMonth', name: 'Release Month', options: releaseMonthOptions },
  { id: 'releaseYears', name: 'Release Year', options: [] },
  { id: 'hybrid', name: 'Hybrid', options: hybridOptions },
  {
    id: 'openingWeekend',
    name: 'Opening Weekend',
    options: openingWeekendOptions,
  },
  { id: 'boxOffice', name: 'Box Office', options: boxOfficeOptions },
  { id: 'totalGross', name: 'Total Gross', options: totalGrossOptions },
  { id: 'budget', name: 'Budget', options: budgetOptions },
  { id: 'rating', name: 'Rating', options: ratingOptions },
  { id: 'psrr', name: 'PSRR', options: psrrOptions },
  { id: 'franchise', name: 'Franchise', options: franchiseOptions },
  { id: 'universe', name: 'Universe', options: universeOptions },
  { id: 'primaryGenre', name: 'Primary Genre', options: primaryGenreOptions },
  {
    id: 'secondaryGenre',
    name: 'Secondary Genre',
    options: secondaryGenreOptions,
  },
  { id: 'groups', name: 'Groups', options: groupsOptions },
]
