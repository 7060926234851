import { ReleaseCalendarData } from 'types'

export const releaseCalendarData: ReleaseCalendarData[] = [
  {
    label: '20T',
    value: 'major',
    id: '20thCenturyStudios(20T)',
    events: [
      {
        name: 'Back in Black',
        startDay: '2024-10-02',
        endDay: '2024-10-08',
        isOutline: true,
      },
    ],
  },
  {
    label: 'A24',
    id: 'A24',
    value: 'major',
  },
  {
    label: 'DSN',
    value: 'major',
    id: 'disney',
    events: [
      {
        name: 'Garfield',
        startDay: '2024-10-04',
        endDay: '2024-11-11',
      },
      {
        name: 'Back in Black',
        startDay: '2024-07-05',
        endDay: '2024-07-14',
      },
    ],
  },
  {
    label: 'FF',
    id: 'focusFeatures',
    value: 'major',
  },
  {
    label: 'LIO',
    id: 'lionsgate',
    value: 'major',
  },
  {
    label: 'MGM',
    value: 'major',
    id: 'mgmAmazon',
    events: [
      {
        name: 'The Strangers: Chapter 1',
        startDay: '2024-10-10',
        endDay: '2024-10-14',
      },
      {
        name: 'Nope',
        startDay: '2024-10-15',
        endDay: '2024-10-21',
      },
    ],
  },
  {
    label: 'NEO',
    value: 'major',
    id: 'neon',
    events: [
      {
        name: 'The Watchers',
        startDay: '2024-10-04',
        endDay: '2024-10-29',
      },
    ],
  },
  {
    label: 'SNY',
    value: 'major',
    id: 'sony',
    events: [
      {
        name: 'Back in Black',
        startDay: '2024-10-22',
        endDay: '2024-10-28',
      },
    ],
  },
  {
    label: 'PRM',
    value: 'major',
    id: 'paramount',
  },
  {
    label: 'WB',
    value: 'major',
    id: 'warnerBros',
    events: [
      {
        name: 'The Strangers: Chapter 1',
        startDay: '2024-10-01',
        endDay: '2024-10-07',
      },
    ],
  },
  {
    label: 'SRL',
    value: 'major',
    id: 'searchlight',
  },
  {
    label: 'UNV',
    value: 'major',
    id: 'universal',
  },
  {
    label: 'Minor',
    value: 'minor',
    id: 'minor',
    events: [
      {
        name: 'Bikeriders, T...(FOC)',
        startDay: '2024-10-02',
        endDay: '2024-10-10',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, N...(FOC)',
        startDay: '2024-10-01',
        endDay: '2024-10-07',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-10-01',
        endDay: '2024-10-07',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-10-15',
        endDay: '2024-10-21',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-10-29',
        endDay: '2024-10-04',
        hasBackground: true,
      },
    ],
  },
  {
    label: 'AS',
    value: 'minor',
    id: 'angelStudios',
  },
  {
    label: 'APL',
    value: 'minor',
    id: 'apple',
  },
  {
    label: 'BS',
    value: 'minor',
    id: 'bleeckerStreet',
    events: [
      {
        name: 'Bikeriders, T...(FOC)',
        startDay: '2024-08-02',
        endDay: '2024-08-08',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, N...(FOC)',
        startDay: '2024-08-01',
        endDay: '2024-08-07',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, N...(FOC)',
        startDay: '2024-08-01',
        endDay: '2024-08-07',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-08-01',
        endDay: '2024-08-07',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-08-15',
        endDay: '2024-08-21',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-08-29',
        endDay: '2024-08-04',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-08-29',
        endDay: '2024-08-04',
        hasBackground: true,
      },
    ],
  },
  {
    label: 'CR',
    value: 'minor',
    id: 'crunchyRoll',
  },
  {
    label: 'NTF',
    value: 'minor',
    id: 'netflix',
    events: [
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-09-01',
        endDay: '2024-09-07',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-09-15',
        endDay: '2024-09-21',
        hasBackground: true,
      },
      {
        name: 'Bikeriders, M...(FOC)',
        startDay: '2024-08-29',
        endDay: '2024-08-04',
        hasBackground: true,
      },
    ],
  },
  {
    label: 'VIV',
    value: 'minor',
    id: 'viva',
  },
  {
    label: 'RA',
    value: 'minor',
    id: 'roadsideAttractions',
  },
  {
    label: 'Other',
    value: 'other',
    id: 'other',
    events: [
      {
        name: 'Bikeriders, T...(FOC)',
        startDay: '2024-09-10',
        endDay: '2024-09-20',
        hasBackground: true,
      },
    ],
  },
  {
    label: '',
    value: 'premieresEvents',
    id: 'premieresEvents',
  },
  {
    label: '',
    value: 'sportingEvents',
    id: 'sportingEvents',
    events: [
      {
        name: 'NBA: Finals 2024',
        startDay: '2024-10-01',
        endDay: '2024-10-07',
        hasBackground: true,
      },
      {
        name: 'UEFA: Euro 2024',
        startDay: '2024-09-15',
        endDay: '2024-09-21',
        hasBackground: true,
      },
    ],
  },
  {
    label: '',
    value: 'holidayAndSchool',
    id: 'holidayAndSchool',
    events: [
      {
        name: 'Memorial Day',
        startDay: '2024-09-01',
        endDay: '2024-09-07',
        hasBackground: true,
      },
      {
        name: 'Summer Break',
        startDay: '2024-10-15',
        endDay: '2024-10-03',
        hasBackground: true,
        hasLine: true,
      },
    ],
  },
]
