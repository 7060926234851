import { ChangeEvent, useEffect, useState } from 'react'
import { Button, LogLineCard, TitleBlockStep } from 'components'
import { useActions, useTypedSelector } from 'hooks'

interface ProjectLogLinesStepProps {
  nextStep: () => void
  setStepCompleted: () => void
}

export const ProjectLogLinesStep = ({
  nextStep,
  setStepCompleted,
}: ProjectLogLinesStepProps) => {
  const { projectLogline } = useTypedSelector(state => state.projectReducer)
  const { setProjectLogline, resetProjectLogline } = useActions()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    validateForm()
  }, [projectLogline])

  const validateForm = () => {
    setIsButtonDisabled(!projectLogline)
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.length <= 500) {
      setProjectLogline(value)
    }
  }

  const handleSaveAndNextStep = () => {
    setStepCompleted()
    nextStep()
  }

  return (
    <div className='flex w-3/5 flex-col space-y-5'>
      <TitleBlockStep
        title='Project Loglines (optional)'
        descriptions='Please add three loglines per project.
        The first logline is only one sentence with no more than 75 characters.
        The second logline is a more fleshed-out description.
        The third logline is the same as the second one but can include the names of actors or filmmakers.'
      />
      <LogLineCard
        title='Logline'
        exampleLogline='“Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet. “otestate sunt infirmi, servilis, licet, lex pertinet.  Sunt infirmi, servilis, licet, lex pertinet. “otestate sunt infirmi, servilis, licet, lex pertinet.”'
        value={projectLogline}
        handleChange={handleChange}
        isStep
      />
      <div className='flex gap-3'>
        <Button
          kind='filled'
          size='medium'
          className='w-fit py-1.5'
          onClick={handleSaveAndNextStep}
          disabled={isButtonDisabled}
        >
          <span className='px-1'>Save and next step</span>
        </Button>
        <Button
          kind='text'
          size='medium'
          className='w-fit py-1.5'
          onClick={() => {
            nextStep()
            resetProjectLogline()
          }}
        >
          <span className='px-1'>Skip and enter later</span>
        </Button>
      </div>
    </div>
  )
}
