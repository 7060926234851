import { Colors } from 'constnants'
import { TalentListMenuItem } from 'components'
import { ChevronRight } from 'components/ui/icons'
import { Roles } from 'types'

interface RolesListProps {
  roles: Roles[] | undefined
  selectedRole?: Roles
  handleSelectRole?: (role: Roles) => void
}

export const RolesList = ({
  roles,
  selectedRole,
  handleSelectRole,
}: RolesListProps) => {
  return (
    <>
      {roles && (
        <ul className='flex w-full flex-col gap-1'>
          <p className='px-1 text-xs uppercase text-primary-grey'>Roles</p>
          {roles &&
            roles.map((role, i) => (
              <li key={`${role.value}_${i}`}>
                <TalentListMenuItem
                  role={role}
                  selectedItem={selectedRole && selectedRole.value}
                  handleSelectedItem={() =>
                    handleSelectRole && handleSelectRole(role)
                  }
                  icon={
                    <ChevronRight
                      fill={Colors.BASE_ICON}
                      width={16}
                      height={16}
                    />
                  }
                />
              </li>
            ))}
        </ul>
      )}
    </>
  )
}
