import { List, Rows } from 'components/ui/icons'
import { Colors } from './colors'
import { SizeTalentRows } from './sizeTalentRows'

export const tableDirectionTabs = [
  {
    value: SizeTalentRows.LARGE_ROWS,
    icon: <Rows fill={Colors.BASE_ICON} width={16} height={16} />,
  },
  {
    value: SizeTalentRows.SMALL_ROWS,
    icon: <List fill={Colors.BASE_ICON} width={16} height={16} />,
  },
]
