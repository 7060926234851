import { ReleaseCalendarSelectKey } from 'types'

export const getColorClass = (value: string | undefined) => {
  switch (value) {
    case ReleaseCalendarSelectKey.MAJOR:
      return 'bg-yellow-5'
    case ReleaseCalendarSelectKey.MINOR:
      return 'bg-yellow-2'
    case ReleaseCalendarSelectKey.OTHER:
      return 'bg-yellow-2'
    case ReleaseCalendarSelectKey.SPORTING_EVENTS:
      return 'bg-blue-6'
    case ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL:
      return 'bg-green-6'
    case ReleaseCalendarSelectKey.STREAMERS:
    case ReleaseCalendarSelectKey.CABLE_BROADCAST:
      return 'bg-red-10'
    default:
      return ''
  }
}

export const getDotColorClass = (value: string | undefined) => {
  switch (value) {
    case ReleaseCalendarSelectKey.MINOR:
      return 'bg-yellow-2'
    case ReleaseCalendarSelectKey.OTHER:
      return 'bg-yellow-2'
    case ReleaseCalendarSelectKey.SPORTING_EVENTS:
      return 'bg-blue-6'
    case ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL:
      return 'bg-green-6'
    case ReleaseCalendarSelectKey.STREAMERS:
      return 'bg-red-10'
    case ReleaseCalendarSelectKey.CABLE_BROADCAST:
      return 'bg-red-10/50'
    default:
      return ''
  }
}

export const lineAllowed = (value: string | undefined) => {
  switch (value) {
    case ReleaseCalendarSelectKey.MAJOR:
      return false
    case ReleaseCalendarSelectKey.MINOR:
      return false
    case ReleaseCalendarSelectKey.OTHER:
      return false
    case ReleaseCalendarSelectKey.SPORTING_EVENTS:
      return true
    case ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL:
      return true
    case ReleaseCalendarSelectKey.STREAMERS:
      return false
    default:
      return false
  }
}

export const isOther = (value: string | undefined) => {
  switch (value) {
    case ReleaseCalendarSelectKey.MAJOR:
      return false
    case ReleaseCalendarSelectKey.OTHER:
      return true
    case ReleaseCalendarSelectKey.SPORTING_EVENTS:
      return false
    case ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL:
      return false
    case ReleaseCalendarSelectKey.MINOR:
    case ReleaseCalendarSelectKey.STREAMERS:
    case ReleaseCalendarSelectKey.CABLE_BROADCAST:
      return true
    default:
      return false
  }
}
