import { ReactNode, forwardRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { FilterMenu } from 'components'

interface FilterSearchButtonProps {
  disabled?: boolean
  leftIcon?: ReactNode
  name: string
  children: ReactNode
  trianglePosition?: 'left' | 'right' | 'center'
  nameClass?: string
  isComponentVisible: boolean
  setIsComponentVisible?: () => void
  containerMenuClassName?: string
  filterWrapperClassName?: string
}

export const FilterSearchButton = forwardRef<
  HTMLDivElement,
  FilterSearchButtonProps
>(
  (
    {
      disabled,
      leftIcon,
      name,
      children,
      trianglePosition,
      nameClass,
      isComponentVisible,
      setIsComponentVisible,
      containerMenuClassName,
      filterWrapperClassName,
    }: FilterSearchButtonProps,
    ref,
  ) => {
    useEffect(() => {
      if (isComponentVisible && disabled) {
        setIsComponentVisible?.()
      }
    }, [isComponentVisible, disabled])

    const handleToggle = () => {
      if (!disabled) {
        setIsComponentVisible?.()
      }
    }

    return (
      <div
        className={twMerge(
          disabled ? ' cursor-not-allowed' : 'relative hover:cursor-pointer',
        )}
        ref={ref}
      >
        <div
          className={twMerge(
            'flex w-fit  flex-row items-center rounded bg-transparent duration-300',
            disabled
              ? 'cursor-not-allowed gap-1 px-2 text-xs font-semibold text-primary-black opacity-50'
              : 'relative',
          )}
          onClick={handleToggle}
        >
          <div
            className={twMerge('flex flex-row items-center rounded px-2 py-1')}
          >
            {leftIcon && <div className='pr-1'>{leftIcon}</div>}
            <span
              className={twMerge(
                'text-xs font-medium text-primary-black',
                disabled && 'text-grey-7',
                nameClass,
              )}
            >
              {name}
            </span>
          </div>
        </div>

        {isComponentVisible && (
          <FilterMenu
            trianglePosition={trianglePosition}
            wrapperClassName={twMerge('p-0', filterWrapperClassName)}
            containerClassName={containerMenuClassName}
          >
            {children}
          </FilterMenu>
        )}
      </div>
    )
  },
)

FilterSearchButton.displayName = 'FilterSearchButton'
