import { TVFilmCompanyColKey } from '../../types'
import { type TVFilmCompanyCol } from './types'

export const columns: TVFilmCompanyCol[] = [
  {
    key: TVFilmCompanyColKey.Name,
    sortKey: TVFilmCompanyColKey.Name,
    title: 'Company Name',
  },
  { key: TVFilmCompanyColKey.Abbr, title: 'Abbreviation' },
  { key: TVFilmCompanyColKey.ImdbId, title: 'IMDB ID' },
  {
    key: TVFilmCompanyColKey.CompanyType,
    sortKey: TVFilmCompanyColKey.CompanyType,
    title: 'Company Type',
  },
  {
    key: TVFilmCompanyColKey.DistributorType,
    sortKey: TVFilmCompanyColKey.DistributorType,
    title: 'Distributor Type',
  },
  { key: TVFilmCompanyColKey.EditEntry, width: 48 },
]
