import { SectionTitle, TalentDetailAttributeItem } from 'components'
import { Attributes, AttributeType, NameValuePair } from 'types'
import { formatNumberWithSuffix, getDisplayAttributeValue } from 'utils'
import { optionAttributes, optionSocialMedia, optionSearch } from 'constnants'

interface TalentDetailAttributesListProps<T extends object> {
  isAttributes?: boolean
  values: (Attributes | NameValuePair<T>)[]
  title?: string
  hasTitle?: boolean
  selectedAttribute: string
  contentTypeView?: AttributeType
  isSocial?: boolean
}

export const TalentDetailAttributesList = <T extends object>({
  isAttributes,
  values,
  title,
  hasTitle = true,
  selectedAttribute,
  contentTypeView = 'count',
  isSocial = false,
}: TalentDetailAttributesListProps<T>) => {
  return (
    <div className='block max-h-64 min-w-fit'>
      {hasTitle ? (
        <SectionTitle title={title ?? ''} className='pb-4' />
      ) : (
        <div className='h-8' />
      )}
      <ul className='flex flex-col space-y-0.5'>
        {values?.map((value, index) => {
          const displayValue = getDisplayAttributeValue(value, isAttributes)
          const mergedAttributes = [
            ...optionAttributes,
            ...optionSocialMedia,
            ...optionSearch,
          ]
          const currentAttributeName = mergedAttributes.find(
            opt => opt.value === value.name,
          )?.label
          const isSelectedItem = selectedAttribute === value.name
          const isFormatting = value.name === 'sentiment'
          let valueToDisplay: string | number | undefined

          if (contentTypeView === 'percentage') {
            valueToDisplay = displayValue.percentage
          } else if (contentTypeView === 'rank') {
            valueToDisplay = formatNumberWithSuffix(displayValue.rank ?? 0)
          } else {
            valueToDisplay = isFormatting
              ? displayValue.count
              : formatNumberWithSuffix(displayValue.count)
          }

          return (
            <li key={`${value.name}_${index + 1}`}>
              <TalentDetailAttributeItem
                name={currentAttributeName ?? value.name}
                percent={valueToDisplay ?? ''}
                isAttributes={isAttributes}
                isSelected={isSelectedItem}
                hasInfo={value.hasInfo}
                contentTypeView={contentTypeView}
                tooltipText={value?.value?.tooltipText}
                columnName={title ?? Math.random().toString()}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
