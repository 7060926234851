import { TVFilmCompanyType, TVFilmDistributorType } from '../types'

export const tVFilmDistributorLabelByType: Record<
  TVFilmDistributorType,
  string
> = {
  [TVFilmDistributorType.Major]: 'Major',
  [TVFilmDistributorType.Minor]: 'Minor',
  [TVFilmDistributorType.Other]: 'Other',
}

export const tVFilmCompanyLabelByType: Record<TVFilmCompanyType, string> = {
  [TVFilmCompanyType.FilmDistributor]: 'Film - Distributor',
  [TVFilmCompanyType.TVBroadcast]: 'TV - Broadcast',
  [TVFilmCompanyType.TVStreamer]: 'TV - Streamer',
  [TVFilmCompanyType.TVCable]: 'TV - Cable',
}
