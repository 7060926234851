import { ArrowLeft, CheckCircle } from '../../components/ui/icons'
import { Colors } from '../../constnants'
import { Input, Select } from '../../components'
import { AdminUserData } from '../../types'
import { twMerge } from 'tailwind-merge'
import React, { ChangeEvent, useState } from 'react'

interface UserEditPageProps {
  userData: AdminUserData
  setUserProfileOpen: (user: AdminUserData) => void
  initialUserProfile: AdminUserData
}

export const UserEditPage = ({
  userData,
  setUserProfileOpen,
  initialUserProfile,
}: UserEditPageProps) => {
  const [isEditState, setIsEditState] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const preparedFormData = {
    userName: userData.userName,
    userEmail: userData.email,
    userType: userData.userType,
    userDateJoined: userData.dateJoined,
    userLastLogin: userData.lastLogin,
    userAccountStatus: userData.accountStatus,
  }
  const [inputValues, setInputValues] = useState(preparedFormData)
  const [defaultUserData, setDefaultUserData] = useState(preparedFormData)
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputValues,
  ) => {
    const { value } = e.target
    setInputValues(prevState => ({ ...prevState, [key]: value || '' }))
  }

  const handleSelect = (
    option: { id: string; value: string; label: string },
    key: string,
  ) => {
    setInputValues(prevState => ({ ...prevState, [key]: option.value }))
    if (key === 'userType') {
      setInputValues(prevState => ({ ...prevState, userAccessLevel: '' }))
    }
  }

  const clearStatuses = () => {
    setIsEditState(false)
    setShowSuccessMessage(true)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
    }, 3000)
  }

  const handleSave = () => {
    setSuccessMessage('User Details Updated')
    clearStatuses()
    setDefaultUserData(inputValues)
  }

  return (
    <div className={'h-full overflow-auto pb-4'}>
      <div
        className={
          'mb-12 flex items-center gap-4 border-b border-b-primary-black/10 px-4 py-3'
        }
      >
        <ArrowLeft
          fill={'currentColor'}
          width={24}
          height={24}
          onClick={() => {
            setUserProfileOpen(initialUserProfile)
          }}
          className='cursor-pointer text-grey-7 hover:text-primary-red'
        />
        <div>
          <p className={'text-xs font-semibold opacity-50'}>
            {defaultUserData.userType}
          </p>
          <h1 className='text-2xl font-semibold text-primary-black'>
            {defaultUserData.userName}
          </h1>
        </div>
      </div>

      <div className='mx-auto w-[800px] max-w-[90%]'>
        <div className='mb-3 flex items-center justify-between'>
          <p className={'text-sm font-medium text-primary-black'}>
            User Details
          </p>
          <div>
            {isEditState ? (
              <>
                <button
                  className='px-[10px] text-xs font-semibold hover:text-primary-red'
                  onClick={() => setIsEditState(false)}
                >
                  Cancel
                </button>
                <button
                  className={twMerge(
                    'rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white',
                    (!inputValues.userName ||
                      !inputValues.userEmail ||
                      !inputValues.userType ||
                      !inputValues.userLastLogin) &&
                      'disabled:bg-gray-500',
                  )}
                  onClick={() => {
                    handleSave()
                  }}
                  disabled={
                    !inputValues.userName ||
                    !inputValues.userEmail ||
                    !inputValues.userType ||
                    !inputValues.userLastLogin
                  }
                >
                  Save
                </button>
              </>
            ) : (
              <button
                className='rounded-full bg-primary-grey px-[10px] py-1 text-xs font-semibold text-primary-white transition-colors hover:bg-primary-grey/50'
                onClick={() => setIsEditState(true)}
              >
                Edit Details
              </button>
            )}
          </div>
        </div>
        <div className='mb-[44px] rounded border border-primary-black/20'>
          <div className='flex items-center border-b px-4 py-3'>
            <p className='w-24 text-sm font-medium lg:w-48'>Name</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Input
                  containerWrapperClassName='pr-5'
                  wrapperClassName='w-full h-8 rounded-md'
                  inputClassName='pl-3 text-md py-2'
                  value={inputValues.userName}
                  onChange={e => handleInputChange(e, 'userName')}
                />
              ) : (
                <p className='px-3 py-1 text-sm'>{defaultUserData.userName}</p>
              )}
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Email</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Input
                  containerWrapperClassName='pr-5'
                  wrapperClassName='w-full h-8 rounded-md'
                  inputClassName='pl-3 text-md py-2'
                  value={inputValues.userEmail}
                  onChange={e => handleInputChange(e, 'userEmail')}
                />
              ) : (
                <p className='px-3 py-1 text-sm'>{defaultUserData.userEmail}</p>
              )}
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>User Type</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='px-3 py-1 text-sm capitalize'>
                {defaultUserData.userType || 'N/A'}
              </p>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Date Joined</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='px-3 py-1 text-sm'>
                {defaultUserData.userDateJoined}
              </p>
            </div>
          </div>
          <div className='flex items-center p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Last Log In</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='flex items-center gap-1 px-3 py-1 text-sm'>
                {userData.lastLogin === 'Online' && (
                  <span className='h-2 w-2 rounded-full bg-green-6'></span>
                )}
                {userData.lastLogin === 'Paused' && (
                  <span className='h-2 w-2 rounded-full bg-red-10'></span>
                )}
                {userData.accountStatus === 'Inactive' &&
                  userData.lastLogin === 'Inactive' && (
                    <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
                  )}
                {userData.lastLogin}
              </p>
            </div>
          </div>
          <div className='flex items-center p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Account Status</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Select
                  id={'userStatus'}
                  containerClass='w-full mr-5'
                  value={inputValues.userAccountStatus}
                  handleClick={option => {
                    setInputValues(prevState => ({
                      ...prevState,
                      userAccountStatus: option.value,
                    }))
                  }}
                  buttonClass='h-8 items-center'
                  options={[
                    { id: '1', value: 'Active', label: 'Active' },
                    { id: '2', value: 'Pending', label: 'Pending' },
                  ]}
                  size='small'
                  placeholder={inputValues.userAccountStatus}
                  hasRadioButton={false}
                />
              ) : (
                <p className='flex w-full items-center justify-between px-3 py-1 text-sm text-primary-black'>
                  <span className={twMerge('flex items-baseline gap-1')}>
                    {defaultUserData.userAccountStatus === 'Pending' ? (
                      <>
                        <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
                        Pending
                      </>
                    ) : defaultUserData.userAccountStatus === 'Inactive' ? (
                      <>
                        <span className='h-2 w-2 rounded-full bg-red-10'></span>
                        Inactive
                      </>
                    ) : (
                      <>
                        <span className='h-2 w-2 rounded-full bg-green-6'></span>
                        Active
                      </>
                    )}
                  </span>
                  {defaultUserData.userAccountStatus === 'Pending' && (
                    <button className='rounded-full bg-primary-grey px-[10px] py-1 text-xs font-semibold text-primary-white transition-colors hover:bg-primary-grey/50'>
                      Resend Invite
                    </button>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div className='fixed bottom-4 right-4 rounded-md bg-primary-white p-3 shadow-md'>
          <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
            <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
            {successMessage}
          </p>
        </div>
      )}
    </div>
  )
}
