import { RankWithSuffix } from 'components'
import { Attribute, AttributeType, SocialItem } from 'types'
import { twMerge } from 'tailwind-merge'

const TalentAttributesContent = ({
  value,
  isLargeRows,
  contentTypeView,
}: {
  value: string | number | Attribute | undefined
  isLargeRows: boolean
  contentTypeView?: AttributeType
}) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return <span>{value}</span>
  }

  if (typeof value === 'undefined') {
    return null
  }

  const preparedValue = (value: Attribute, contentTypeView?: AttributeType) => {
    if (contentTypeView) {
      return value[contentTypeView]
    }

    return value.count
  }

  return (
    <>
      {!isLargeRows && (
        <span>
          {contentTypeView === 'rank' ? (
            <RankWithSuffix rank={value.rank} />
          ) : (
            preparedValue(value, contentTypeView)
          )}
        </span>
      )}
      {isLargeRows && (
        <div className='flex w-full flex-col'>
          <span className='pb-1'>{value.percentage}</span>
          <span className='border-b border-t border-b-green-1 border-t-green-1 py-[5px]'>
            {<RankWithSuffix rank={value.rank} />}
          </span>
          <span className='pt-1'>{value.count}</span>
        </div>
      )}
    </>
  )
}

interface CellContentProps {
  value: string | string[] | number | Attribute | SocialItem | undefined
  isLargeRows: boolean
  contentTypeView?: AttributeType
}

export const CellContent = ({
  value,
  isLargeRows,
  contentTypeView,
}: CellContentProps) => {
  if (value && typeof value === 'object' && 'count' in value) {
    return (
      <TalentAttributesContent
        value={value as Attribute}
        isLargeRows={isLargeRows}
        contentTypeView={contentTypeView}
      />
    )
  } else if (Array.isArray(value)) {
    return (
      <span
        className={twMerge(
          'inline-flex w-full content-center items-center justify-between text-center',
          isLargeRows && 'h-[84px]',
        )}
      >
        {value.map((item, key) => {
          return (
            <span
              key={key}
              className={twMerge(
                'text-xs font-medium',
                'h-full flex-1 content-center px-[4px] py-[7px] text-center',
                key === 0 && 'rounded-l',
                key === 1 && 'border-x-[1px]',
                key === 2 && 'rounded-r',
              )}
            >
              {item}
            </span>
          )
        })}
      </span>
    )
  } else {
    return <span>{String(value)}</span>
  }
}
