import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { OptionType, TalentType, Urls } from 'types'
import { useSearchParams } from 'react-router-dom'
import {
  Close,
  Search,
  SquareMinus,
  SquarePlus,
  Star,
} from 'components/ui/icons'
import {
  AddToExistingContent,
  SearchListMenu,
  FilterSearchButton,
  Input,
  IconButton,
} from 'components'
import { useClickOutsideComponent, useSearchFilter } from 'hooks'
import { Colors } from 'constnants'
import useTalentSimple from '../../../hooks/useTalentSimple'
import { buildParams, removeSubstring } from 'utils'

interface CompareWindowHeaderProps {
  talents: TalentType[]
  onRemoveTalentFromCompare: (talentId: string) => void
  selectedIds?: string[]
  selectedRows?: string[]
}

export const CompareWindowHeader = ({
  talents,
  selectedIds,
  onRemoveTalentFromCompare,
  selectedRows,
}: CompareWindowHeaderProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const talentIds = searchParams.getAll('talentId')
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const {
    ref: refExisting,
    isComponentVisible: isExistingComponentVisible,
    setIsComponentVisible: setIsExistingComponentVisible,
  } = useClickOutsideComponent(false)
  const {
    ref: refRemove,
    isComponentVisible: isRemoveComponentVisible,
    setIsComponentVisible: setIsRemoveComponentVisible,
  } = useClickOutsideComponent(false)
  const { searchTerm, debouncedValue, handleSearchChange, setSearchTerm } =
    useSearchFilter()
  const { talentsList, getTalentsList } = useTalentSimple(true)

  useEffect(() => {
    debouncedValue && getTalentsList(debouncedValue)
  }, [debouncedValue])

  const params = new URLSearchParams(location.search)
  const talentsIdsQueryParams = params.getAll('talentId')

  const handleCompare = ({ id }: OptionType) => {
    const idClean = removeSubstring(id.toString())
    const queryParameters = buildParams({
      talentId: [...talentsIdsQueryParams, idClean],
    })

    navigate(`${Urls.TALENT}${Urls.COMPARE}?${queryParameters}`)

    setIsComponentVisible(false)
    setSearchTerm('')
  }

  const amountTalents = `Compare ${talents.length} names`

  const talentsOptions = useMemo(() => {
    const filteredTalents = talentsList?.filter(
      talent => !selectedIds?.includes(talent.talent_id.toString()),
    )
    return (
      filteredTalents?.map(({ talent_id, talent_name }) => ({
        id: talent_id,
        label: talent_name,
        value: talent_id,
      })) || []
    )
  }, [talentsList, selectedIds])

  const removeTalentListOptions = useMemo(() => {
    return talents?.map(({ id, talentPerson }) => ({
      id: id,
      label: talentPerson.talentName,
      value: id,
    }))
  }, [talents])

  return (
    <div className='flex h-fit w-full justify-between bg-primary-white px-3 py-2'>
      <div className='flex items-center space-x-2'>
        <span className='block text-xs font-medium text-primary-black'>
          {amountTalents}
        </span>

        <FilterSearchButton
          name='Add to existing List'
          isComponentVisible={isExistingComponentVisible}
          setIsComponentVisible={() =>
            setIsExistingComponentVisible(prevState => !prevState)
          }
          disabled={
            talentIds.length === 0 ||
            (selectedRows && selectedRows.length === 0)
          }
          leftIcon={<Star fill={Colors.BASE_ICON} width={16} height={16} />}
          ref={refExisting}
        >
          <AddToExistingContent
            selectedTalents={talentIds}
            close={() => setIsExistingComponentVisible(false)}
          />
        </FilterSearchButton>
        <FilterSearchButton
          name='Add Talent to compare'
          leftIcon={<SquarePlus fill={Colors.BASE_ICON} />}
          isComponentVisible={isComponentVisible}
          setIsComponentVisible={() =>
            setIsComponentVisible(prevState => !prevState)
          }
          ref={ref}
          disabled={talentIds.length >= 4}
        >
          <div className='pt-3'>
            <Input
              value={searchTerm}
              onChange={e => handleSearchChange(e)}
              leftIcon={
                <Search fill={Colors.BASE_ICON} width={16} height={16} />
              }
              containerWrapperClassName='px-3'
            />
            <SearchListMenu
              list={talentsOptions}
              handleChange={handleCompare}
            />
          </div>
        </FilterSearchButton>
        <FilterSearchButton
          name='Remove Talent'
          leftIcon={<SquareMinus fill={Colors.BASE_ICON} />}
          isComponentVisible={isRemoveComponentVisible}
          setIsComponentVisible={() =>
            setIsRemoveComponentVisible(prevState => !prevState)
          }
          ref={refRemove}
          disabled={talentIds.length < 2}
        >
          <div className='pt-3'>
            <SearchListMenu
              list={removeTalentListOptions}
              handleChange={({ id }) => {
                onRemoveTalentFromCompare(removeSubstring(id.toString()))
                setSearchTerm('')
              }}
            />
          </div>
        </FilterSearchButton>
      </div>

      <IconButton
        className='rounded hover:bg-opacityGrey-10'
        onClick={() => navigate(-1)}
      >
        <Close fill={Colors.BASE_ICON} width={16} height={16} />
      </IconButton>
    </div>
  )
}
