import { api } from './api'
import {
  CompaniesList,
  CompaniesListQuery,
  CompanyItem,
  GetCompanyType,
  NewCompanyType,
} from '../../types'
import { prepareUrlParams } from '../../utils'
/*TODO need to replace hardcoded Company ID*/
export const myCompanyApi = api.injectEndpoints({
  endpoints: builder => ({
    getCompaniesList: builder.query<
      {
        id: string
        name: string
      }[],
      { companyName?: string }
    >({
      query: queryParams => {
        const params = prepareUrlParams(queryParams)
        return {
          url: `/company${params}`,
          method: 'GET',
        }
      },
      providesTags: [{ type: 'MyCompany' }],
    }),
    getCompanyById: builder.query<
      {
        domain_name: string
        id: string
        name: string
        is_guest_link_allowed: boolean
        owner_id: string
      },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/company/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'MyCompany' }],
    }),
    updatedCompany: builder.mutation<
      {
        name: string
        domain_name: string
        is_guest_link_allowed: boolean
      },
      {
        id: number
        data: {
          name?: string
          domain_name?: string
          is_guest_link_allowed?: boolean
        }
      }
    >({
      query: company => ({
        body: { ...company.data },
        url: `/company/7a9a0a34-f892-4c20-8353-5caa4daa95c1`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyCompany' }],
    }),
    getAdminCompanies: builder.query<CompaniesList, CompaniesListQuery>({
      query: queryParams => {
        const params = prepareUrlParams(queryParams)
        return {
          url: `/ga-admin/company${params}`,
          method: 'GET',
        }
      },
      providesTags: ['MyCompany'],
    }),
    newAdminCompany: builder.mutation<CompanyItem, NewCompanyType>({
      query: NewCompanyType => ({
        body: { ...NewCompanyType },
        url: `/ga-admin/company/invite`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'MyCompany' }],
    }),
    updatedAdminCompany: builder.mutation<
      CompanyItem,
      { id: string; data: NewCompanyType }
    >({
      query: company => ({
        body: { ...company.data },
        url: `/ga-admin/company/${company.id}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyCompany' }],
    }),
    getAdminCompany: builder.query<GetCompanyType, { id: string }>({
      query: ({ id }) => ({
        url: `/ga-admin/company/${id}`,
        method: 'GET',
      }),
      providesTags: ['MyCompany'],
    }),
  }),
})

export const {
  useGetCompaniesListQuery,
  useUpdatedCompanyMutation,
  useGetCompanyByIdQuery,
  useGetAdminCompaniesQuery,
  useNewAdminCompanyMutation,
  useUpdatedAdminCompanyMutation,
  useGetAdminCompanyQuery,
} = myCompanyApi
