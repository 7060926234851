import { Key, useEffect, useState, useMemo } from 'react'
import { commonToastStyles, mapObjectTalentsLists } from 'utils'
import { ExistingMyLists, ExistingProjectList, Tabs, Tab } from 'components'
import {
  useGetTelentsListsQuery,
  useGetProjectCompanyWithRoleDataQuery,
  useUpdateTelentListMutation,
} from 'store/api'
import { OptionType } from 'types'
import { toast } from 'react-toastify'
import { Success } from 'components/ui/icons'

interface AddToExistingContentProps {
  close: () => void
  selectedTalents: string[]
}

export const AddToExistingContent = ({
  close,
  selectedTalents,
}: AddToExistingContentProps) => {
  const [initialSelectTab, setInitialSelectTab] = useState<Key | null>('first')
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    null,
  )
  const [selectedSubProjectId, setSelectedSubProjectId] = useState<
    string | null
  >(null)
  const handleSelectTab = (tab: Key | null) => {
    setInitialSelectTab(tab)
  }

  const { data: getDataForTelentsLists, isError: isErrorTelentsLists } =
    useGetTelentsListsQuery({})

  const dataForTelentsLists = isErrorTelentsLists ? [] : getDataForTelentsLists

  const mappedTalentsLists: OptionType[] = useMemo(() => {
    return (
      dataForTelentsLists
        ?.filter(item => {
          if (!item.project_id && !item.role_id) {
            return item
          }
        })
        .map(mapObjectTalentsLists) ?? []
    )
  }, [dataForTelentsLists])

  useEffect(() => {
    setSelectedProjectId(null)
    setSelectedSubProjectId(null)
  }, [initialSelectTab])

  const { data: myProjectsData } = useGetProjectCompanyWithRoleDataQuery()
  const [updateTelentList] = useUpdateTelentListMutation()

  const mappedProjects = useMemo(
    () =>
      myProjectsData?.projects
        ?.filter(
          project =>
            project.roles &&
            project.roles.length > 0 &&
            project.roles.some(role => role.lists && role.lists.length > 0),
        )
        .map(project => ({
          id: project.projectId.toString(),
          value: project.projectName,
          label: project.projectName,
          ProjectRoles: project.roles.map(role => ({
            id: role.roleId.toString(),
            value: role.roleName,
            label: role.roleName,
            lists: role.lists,
          })),
        })) || [],
    [myProjectsData],
  )

  const selectedProjectLabel = useMemo(
    () =>
      mappedProjects.find(project => project.id === selectedProjectId)?.label,
    [mappedProjects, selectedProjectId],
  )

  const handleAddToExistingList = (listId: string, listLabel: string) => {
    if (!selectedTalents.length) {
      return
    }
    close()
    updateTelentList({
      listId,
      talentIdsToAdd: selectedTalents,
    })
      .unwrap()
      .then(() =>
        toast.success(
          <>
            Talent added to <b>My Lists:</b>
            <br />
            <b>{listLabel}</b>
          </>,
          {
            icon: <Success width={24} height={24} />,
            ...commonToastStyles,
          },
        ),
      )
  }

  return (
    <div className='flex flex-col'>
      <span className='px-3 pt-2 text-start text-xs text-primary-grey'>
        Add selected names to
      </span>

      <Tabs
        initialSelectedTab={initialSelectTab}
        handleSelectTab={handleSelectTab}
        tabHeaderClasses='flex w-full border-b border-b-opacityGrey-10'
      >
        <Tab
          key='first'
          label='Project Lists'
          headerClasses='font-medium normal-case bg-primary-white hover:bg-trasnparent'
          activeTabHeaderClasses='border-b-2 border-b-red-9 text-red-9'
        >
          <ExistingProjectList
            list={mappedProjects}
            selectedProjectId={selectedProjectId}
            setSelectedProjectId={setSelectedProjectId}
            selectedSubProjectId={selectedSubProjectId}
            setSelectedSubProjectId={setSelectedSubProjectId}
            close={close}
            selectedProjectLabel={selectedProjectLabel}
            selectedTalents={selectedTalents}
          />
        </Tab>
        <Tab
          key='second'
          label='My Lists'
          headerClasses='font-medium normal-case bg-primary-white hover:bg-trasnparent'
          activeTabHeaderClasses='border-b-2 border-b-red-9 text-red-9'
        >
          <ExistingMyLists
            list={mappedTalentsLists}
            handleAddToExistingList={handleAddToExistingList}
          />
        </Tab>
      </Tabs>
    </div>
  )
}
