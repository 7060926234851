import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { IconButton, ReleaseCalendarSelectList, TitleFilter } from 'components'
import { CloseMenu, OpenMenu } from 'components/ui/icons'
import { useActions, useKeyPress, useTypedSelector } from 'hooks'
import { Colors } from 'constnants'
import { OptionFilter, ReleaseCalendarSelectKey } from 'types'
import { useGetSidebarDataQuery } from 'store/api'

interface ReleaseCalendarSidebarProps {
  isOpen: boolean
  handleClose: () => void
  handleOpen: () => void
}

export const ReleaseCalendarSidebar = ({
  isOpen,
  handleClose,
  handleOpen,
}: ReleaseCalendarSidebarProps) => {
  const {
    switchTitle,
    switchFilmSelect,
    selectedOptions,
    distributors,
    tvDistributors,
  } = useTypedSelector(state => state.releaseCalendarReducer)
  const {
    setSwitchTitle,
    setSwitchFilmSelect,
    addOptionToReleaseCalendar,
    initCompanies,
    setLoading,
    setError,
  } = useActions()

  const { data, isLoading, isError } = useGetSidebarDataQuery()

  useEffect(() => {
    if (isLoading) {
      setLoading()
    } else if (isError) {
      setError(isError.toString())
    } else if (data) {
      initCompanies(data) // Pass the array to the init action
    }
  }, [data, isLoading, isError])

  const handleToggle = () => {
    if (isOpen) {
      handleClose()
    } else {
      handleOpen()
    }
  }

  const handleOptionChange = (id: string, options: OptionFilter[]) => {
    // setOption({ id, options })
    console.log('handleOptionChange', id, options)
    addOptionToReleaseCalendar({ id, options })
  }

  useKeyPress(() => {
    if (isOpen) {
      handleClose()
    }
  }, ['Escape'])

  return (
    <div
      className={twMerge(
        'sticky top-0 flex h-[calc(100vh-192px)] flex-col gap-2 overflow-hidden rounded-bl rounded-tl bg-grey-4 p-2' +
          ' transition-all duration-300',
        isOpen ? 'w-[271px] min-w-[271px] overflow-auto' : 'w-12 min-w-12',
      )}
    >
      <div>
        <IconButton
          onClick={handleToggle}
          className={twMerge(
            'rounded-md p-1.5 transition-all duration-300 hover:bg-primary-black/10',
            isOpen ? 'float-right' : 'float-left',
          )}
        >
          {isOpen ? (
            <CloseMenu fill={Colors.BASE_ICON} width={20} height={20} />
          ) : (
            <OpenMenu fill={Colors.BASE_ICON} width={20} height={20} />
          )}
        </IconButton>
      </div>

      <div
        className={twMerge(
          'flex w-full flex-col transition-opacity',
          isOpen ? 'opacity-100 delay-75 duration-500' : 'opacity-0',
        )}
      >
        <TitleFilter
          id={ReleaseCalendarSelectKey.HEAT_MAP}
          title='Film Boxoffice Heat Map'
          checked={switchTitle.heatMap}
          onChange={checked =>
            setSwitchTitle({
              id: ReleaseCalendarSelectKey.HEAT_MAP,
              checked: checked,
            })
          }
          color='bg-green-5'
          wrapperClassName='py-3 mb-4'
        />
        <TitleFilter
          id={ReleaseCalendarSelectKey.THEATRICAL}
          title='Theatrical'
          checked={switchTitle.theatrical}
          onChange={checked =>
            setSwitchTitle({
              id: ReleaseCalendarSelectKey.THEATRICAL,
              checked: checked,
            })
          }
          color='bg-yellow-5'
          wrapperClassName='py-3 mb-1 mt-1'
        />

        <span className='mb-1 text-xs text-primary-grey'>Distributor</span>
        <ReleaseCalendarSelectList
          selects={distributors}
          selectedOptions={selectedOptions}
          handleSelectChange={(id, checked) =>
            setSwitchFilmSelect({ id, checked })
          }
          filmSelect={switchFilmSelect}
          onOptionChange={handleOptionChange}
          mainToggleOff={!switchFilmSelect.allGroups}
        />

        <TitleFilter
          id={ReleaseCalendarSelectKey.PREMIERES_EVENTS}
          title='TV Premieres, Events'
          checked={switchTitle.premieresEvents}
          onChange={checked =>
            setSwitchTitle({
              id: ReleaseCalendarSelectKey.PREMIERES_EVENTS,
              checked: checked,
            })
          }
          color='bg-red-10'
          wrapperClassName='pt-10 pb-3'
        />

        <ReleaseCalendarSelectList
          selects={tvDistributors}
          selectedOptions={selectedOptions}
          handleSelectChange={(id, checked) =>
            setSwitchFilmSelect({ id, checked })
          }
          filmSelect={switchFilmSelect}
          onOptionChange={handleOptionChange}
          mainToggleOff={!switchFilmSelect.allTVTypes}
        />

        <TitleFilter
          id={ReleaseCalendarSelectKey.SPORTING_EVENTS}
          title='Sporting Events'
          checked={switchTitle.sportingEvents}
          onChange={checked =>
            setSwitchTitle({
              id: ReleaseCalendarSelectKey.SPORTING_EVENTS,
              checked: checked,
            })
          }
          color='bg-blue-6'
          wrapperClassName='pt-10 pb-3'
        />
        <TitleFilter
          id={ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL}
          title='Holiday & School Closing'
          checked={switchTitle.holidayAndSchool}
          onChange={checked =>
            setSwitchTitle({
              id: ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL,
              checked: checked,
            })
          }
          color='bg-green-6'
          wrapperClassName='pt-5'
        />
      </div>
    </div>
  )
}
