import { ChangeEvent, useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'

import {
  Input,
  TitleBlockStep,
  ProjectDetailsSelectorList,
  Button,
} from 'components'
import { useActions, useTypedSelector } from 'hooks'
import { ProjectDetails } from 'types'
import {
  expectedBudget,
  expectedMpaaRating,
  primaryGenre,
  secondaryGenre,
  tooltipProjectContent,
} from 'constnants'

interface ProjectDetailsStepProps {
  nextStep: () => void
  setStepCompleted: () => void
}

export const ProjectDetailsStep = ({
  nextStep,
  setStepCompleted,
}: ProjectDetailsStepProps) => {
  const { setProjectDetails, setProjectDetailsErrors } = useActions()
  const { projectDetails, projectDetailsErrors } = useTypedSelector(
    state => state.projectReducer,
  )
  const [showTooltips, setShowTooltips] = useState<boolean>(false)

  useEffect(() => {
    const newErrors = {
      projectName: !projectDetails.projectName,
      primaryGenre: !projectDetails.primaryGenre,
      secondaryGenre:
        !projectDetails.secondaryGenre ||
        projectDetails.secondaryGenre.length < 1,
      expectedRating: !projectDetails.expectedRating,
      expectedBudget: !projectDetails.expectedBudget,
    }
    setProjectDetailsErrors(newErrors)
  }, [projectDetails, setProjectDetailsErrors])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof ProjectDetails,
  ) => {
    const { value } = e.target
    setProjectDetails({ ...projectDetails, [key]: value })
  }

  const handleProjectDetailsChange = (
    key: keyof ProjectDetails,
    value: string,
  ) => {
    if (key === 'secondaryGenre') {
      const currentSecondaryGenres = projectDetails.secondaryGenre || []

      if (currentSecondaryGenres.includes(value)) {
        const updatedGenres = currentSecondaryGenres.filter(
          genre => genre !== value,
        )
        setProjectDetails({
          ...projectDetails,
          [key]: updatedGenres,
        })
      } else if (currentSecondaryGenres.length < 2) {
        setProjectDetails({
          ...projectDetails,
          [key]: [...currentSecondaryGenres, value],
        })
      }
    } else {
      setProjectDetails({ ...projectDetails, [key]: value })
    }
  }

  const handleNextStep = () => {
    const isValid = Object.values(projectDetailsErrors).every(error => !error)
    setShowTooltips(true)
    if (isValid) {
      setStepCompleted()
      nextStep()
    }
  }

  const errorKeys = Object.entries(projectDetailsErrors)
    .filter(([, hasError]) => hasError)
    .map(([key, value]) => ({ key, value }))

  return (
    <div className='flex flex-col space-y-5'>
      <TitleBlockStep
        title='Project Details (required)'
        descriptions='Please add the project information below. Note that these inputs can be changed in the future.'
      />
      <div
        data-tooltip-id='projectName'
        data-tooltip-content={tooltipProjectContent.projectName}
      >
        <Input
          placeholder='Project name'
          wrapperClassName='w-96 h-10'
          inputClassName='pl-2 text-md py-2'
          value={projectDetails.projectName}
          label='choose project name'
          labelClassName='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
          onChange={e => handleChange(e, 'projectName')}
          isError={projectDetailsErrors.projectName && showTooltips}
        />
      </div>

      <ProjectDetailsSelectorList
        title='Primary Genre'
        description='Select 1 genre'
        selectors={primaryGenre}
        handleChange={value =>
          handleProjectDetailsChange('primaryGenre', value)
        }
        tooltipId='primaryGenre'
        tooltipContent={tooltipProjectContent.primaryGenre}
        keyName='primaryGenre'
      />
      <ProjectDetailsSelectorList
        title='Secondary Genre'
        description='Select up to 2 genres'
        selectors={secondaryGenre}
        wrapperListClassName='max-w-[1192px]'
        handleChange={value =>
          handleProjectDetailsChange('secondaryGenre', value)
        }
        keyName='secondaryGenre'
        tooltipId='secondaryGenre'
        tooltipContent={tooltipProjectContent.secondaryGenre}
      />
      <ProjectDetailsSelectorList
        title='Expected mpaa rating'
        selectors={expectedMpaaRating}
        sizeTypeSelector='small'
        handleChange={value =>
          handleProjectDetailsChange('expectedRating', value)
        }
        tooltipId='expectedRating'
        tooltipContent={tooltipProjectContent.expectedRating}
        keyName='expectedRating'
      />
      <ProjectDetailsSelectorList
        title='Expected budget'
        selectors={expectedBudget}
        kindSelector='rectangle'
        wrapperListClassName='max-w-[578px]'
        handleChange={value =>
          handleProjectDetailsChange('expectedBudget', value)
        }
        tooltipId='expectedBudget'
        tooltipContent={tooltipProjectContent.expectedBudget}
        keyName='expectedBudget'
      />

      {errorKeys.map(({ key, value }) => (
        <Tooltip
          key={key}
          id={key}
          place='left'
          style={stylesTooltip}
          render={({ content }) => <span>{content}</span>}
          opacity={1}
          isOpen={showTooltips && !!value}
        />
      ))}

      <Button
        kind='filled'
        size='medium'
        className='w-fit py-1.5'
        onClick={handleNextStep}
      >
        <span className='px-1'>Next Step</span>
      </Button>
    </div>
  )
}

const stylesTooltip = {
  width: '150px',
  backgroundColor: '#99292C',
  color: '#ffff',
  textSize: '12px',
  fontWeight: 500,
  borderRadius: '6px',
  boxShadow: '0px 8px 16px 0px #00000033',
  paddingLeft: '10px',
  paddingRight: '6px',
}
