import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface FilterMenuProps {
  children?: ReactNode
  wrapperClassName?: string
  trianglePosition?: 'left' | 'right' | 'center' | 'right-center'
  containerClassName?: string
  hideTriangle?: boolean
}

export const FilterMenu = ({
  children,
  wrapperClassName,
  trianglePosition = 'left',
  containerClassName,
  hideTriangle = false,
}: FilterMenuProps) => {
  return (
    <div
      className={twMerge(
        'absolute z-50 w-fit pt-3 duration-500',
        containerClassName,
      )}
      onClick={e => e.stopPropagation()}
    >
      {!hideTriangle && (
        <div
          className={twMerge(
            'triangle',
            trianglePosition === 'right-center'
              ? '!absolute left-full top-1/2 rotate-90'
              : trianglePosition === 'right'
                ? 'right-0'
                : trianglePosition === 'left'
                  ? 'left-0'
                  : 'left-1/2 -translate-x-1/2 transform-none',
          )}
        />
      )}
      <div
        className={twMerge(
          'top-5 max-h-[60vh] w-full overflow-auto rounded-md border border-primary-black/10 bg-primary-white p-5 text-center shadow',
          wrapperClassName,
        )}
      >
        {children}
      </div>
    </div>
  )
}
