import { twMerge } from 'tailwind-merge'

interface FilmWeekendProjectionProps {
  data: Record<string, any>
  colLabels: number[]
}
interface ProjectionCardProps {
  projection: Record<string, any>
  colLabels: number[]
}
interface ProjectionRowProps {
  label: string
  columns: { [key: string]: string | undefined }
  colLabels: number[]
}
interface ProjectionCellProps {
  colorClass?: string
  colCount: number
}

export const FilmWeekendProjection = ({
  data,
  colLabels,
}: FilmWeekendProjectionProps) => {
  return <ProjectionCard projection={data} colLabels={colLabels} />
}

const ProjectionCard = ({ projection, colLabels }: ProjectionCardProps) => {
  return (
    <div className='flex w-full flex-col'>
      {Object.entries(projection).map(([key, columns]) => (
        <ProjectionRow
          key={key}
          label={key}
          columns={columns}
          colLabels={colLabels}
        />
      ))}
    </div>
  )
}

const ProjectionRow = ({ label, columns, colLabels }: ProjectionRowProps) => {
  return (
    <div className='flex h-6 shrink-0 border-b'>
      <div className='flex h-full w-14 shrink-0 items-center justify-center text-primary-black'>
        <span className='text-xss'>{label}</span>
      </div>
      {colLabels.map((col, idx) => (
        <ProjectionCell
          key={idx}
          colorClass={columns[col]}
          colCount={colLabels.length}
        />
      ))}
    </div>
  )
}

const ProjectionCell = ({ colorClass, colCount }: ProjectionCellProps) => {
  const widthPercentage = `calc(100% / ${colCount})`
  const cellClass = twMerge(
    'h-full w-full border-l',
    colorClass ? `bg-${colorClass}` : '',
  )

  return <div className={cellClass} style={{ width: widthPercentage }} />
}
