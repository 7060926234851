import React, { useState, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { Close, Help, Logo } from 'components/ui/icons'
import { Colors } from '../../constnants'
import { EditCurrentUser } from '../EditCurrentUser'
import { useClickOutsideComponent, useAdminData } from '../../hooks'
import { useGetCurrentUserQuery } from 'store/api'

interface AvatarProps {
  initialsSize?: 'sm' | 'md' | 'lg'
  enableClick?: boolean
  justify?: 'left' | 'right' | 'center'
  className?: string
}

export const Avatar = ({
  initialsSize = 'sm',
  enableClick = false,
  justify = 'center',
  className,
}: AvatarProps) => {
  const { data: user, error } = useGetCurrentUserQuery()
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [initials, setInitials] = useState<string>('')
  useEffect(() => {
    if (user) {
      setInitials(user.first_name?.charAt(0) + user.last_name?.charAt(0))
    }
  }, [user])
  if (error && error?.data?.message === 'Unauthorized') {
    localStorage.removeItem('access_token')
    localStorage.setItem('auth', 'false')
    window.location.assign('/login')
  }

  const { isAdminPath } = useAdminData()

  return (
    <div
      className={twMerge(
        `flex items-center justify-${justify} px-1 hover:rounded-md hover:bg-primary-white/20`,
        enableClick && 'relative',
        className,
      )}
    >
      <div
        className={twMerge(
          'flex h-6 w-6 items-center justify-center rounded-full bg-green-1 hover:cursor-pointer',
          initialsSize === 'md' && 'h-8 w-8',
        )}
        onClick={() => {
          setIsComponentVisible(!isComponentVisible)
        }}
      >
        <span
          className={twMerge(
            'text-[10px] font-semibold uppercase leading-4 text-green-7',
            initialsSize === 'md' && 'text-sm',
          )}
        >
          {initials}
        </span>
      </div>
      {enableClick && isComponentVisible && (
        <div
          ref={ref}
          className='absolute right-0 top-full z-50 rounded-md bg-primary-white p-3 shadow-md'
        >
          <ul className='whitespace-nowrap'>
            <li
              className='rounded-md px-1 py-0.5 transition-colors hover:cursor-pointer hover:bg-green-2'
              onClick={() => {
                setIsModalVisible(true)
              }}
            >
              Your settings
            </li>
            {user && user.is_greenlight_admin && (
              <li
                className='rounded-md px-1 py-0.5 transition-colors hover:cursor-pointer hover:bg-green-2'
                onClick={() => {
                  window.location.assign(
                    !isAdminPath ? '/companyManagement' : '/myProjects',
                  )
                }}
              >
                {!isAdminPath
                  ? 'Switch to Admin menu'
                  : 'Switch to Default menu'}
              </li>
            )}
            <li
              className='rounded-md px-1 py-0.5 text-red-10 transition-colors hover:cursor-pointer hover:bg-green-2'
              onClick={() => {
                localStorage.removeItem('auth')
                localStorage.removeItem('access_token')
                localStorage.removeItem('password')
                localStorage.removeItem('companyId')
                localStorage.removeItem('userId')
                window.location.reload()
              }}
            >
              Log out
            </li>
          </ul>
        </div>
      )}
      {enableClick && isModalVisible && (
        <div className='fixed left-0 top-0 z-[51] flex h-full w-full flex-col bg-primary-blue'>
          <div className='flex items-center justify-between p-1.5 pr-3'>
            <Logo width={30} height={32} />
            <div className='flex items-center gap-3 py-3'>
              <Help
                fill={Colors.NAV_ICON_ONE}
                width={20}
                height={20}
                className='hover:cursor-pointer'
              />
              <Close
                fill={Colors.NAV_ICON_ONE}
                width={20}
                height={20}
                className='hover:cursor-pointer'
                onClick={() => {
                  setIsModalVisible(false)
                }}
              />
            </div>
          </div>
          <div className='flex grow items-center justify-center'>
            <EditCurrentUser />
          </div>
        </div>
      )}
    </div>
  )
}
