import { BaseModal } from 'components'
import {
  TVFilmCompanyForm,
  type TVFilmCompanyFormData,
} from '../TVFilmCompanyForm'
import { ToggleHookState, ToggleHookStateControls } from 'hooks'
import { useCallback } from 'react'
import { useUpdateTVFilmCompanyMutation } from 'store/api/mock-api'
import { TVFilmCompanyData } from '../../types'

interface TVFilmCompanyEditDialogProps {
  dialogState: ToggleHookState<TVFilmCompanyData>
  dialogStateControls: ToggleHookStateControls<TVFilmCompanyData>
}

export const TVFilmCompanyEditDialog = ({
  dialogState,
  dialogStateControls,
}: TVFilmCompanyEditDialogProps) => {
  const [updateCompany] = useUpdateTVFilmCompanyMutation()

  const { id: companyId } = dialogState.data ?? {}

  const handleSubmit = useCallback(
    async (updates: TVFilmCompanyFormData) => {
      if (!companyId) {
        return
      }
      try {
        await updateCompany({ companyId, updates })
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [companyId, dialogStateControls, updateCompany],
  )

  return (
    <>
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'Edit Company Details'}
        maxWidth='450px'
      >
        <TVFilmCompanyForm
          submitButtonLabel='Save'
          defaultValues={dialogState.data}
          onSubmit={handleSubmit}
          onClose={dialogStateControls.deactivate}
        />
      </BaseModal>
    </>
  )
}
