import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useSearchFilter } from 'hooks'
import { useSearchParams } from 'react-router-dom'

interface SaveAsFiltersDropdownProps {
  saveAsNewSearch: (val: boolean) => void
  setIsOpen: (val: boolean) => void
  isOpen: boolean
  selectedFilterPresetSearch?: string | null
  updateCurrentSearch?: () => void
  className?: string
  horizontalPostion?: 'left' | 'right'
  onUpdated?: () => void
}

interface SaveAsItemProps {
  text: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  setIsOpen: (val: boolean) => void
}

const SaveAsItem = ({ text, onClick, setIsOpen }: SaveAsItemProps) => {
  return (
    <div
      className={twMerge(
        'bg-custom-light flex h-[32px] w-full cursor-pointer flex-row items-center self-stretch rounded-md px-1 text-sm font-normal leading-6',
        'hover:bg-primary-black/5',
      )}
      onClick={event => {
        event.stopPropagation()
        setIsOpen(false)
        if (onClick) {
          onClick(event)
        }
      }}
    >
      {text}
    </div>
  )
}

export const SaveAsFiltersDropdown = ({
  onUpdated,
  saveAsNewSearch,
  setIsOpen,
  isOpen,
  selectedFilterPresetSearch,
  updateCurrentSearch,
  className,
  horizontalPostion = 'left',
}: SaveAsFiltersDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { handleEditSearchPreset } = useSearchFilter()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setIsOpen])

  if (!isOpen) return null

  return (
    <div
      ref={dropdownRef}
      className={twMerge(
        horizontalPostion === 'left' ? 'right-[-7px]' : 'left-[-7px]',
        'absolute top-26px z-30 flex max-h-[200px]',
        'w-[280px] cursor-default flex-col items-start overflow-y-auto',
        'rounded-md border border-[#0307181a] bg-white p-3 text-primary-black shadow-sm',
        className,
      )}
    >
      <SaveAsItem
        text='Save changes to current search'
        setIsOpen={setIsOpen}
        onClick={() => {
          if (updateCurrentSearch) {
            updateCurrentSearch()
          } else {
            const [name, id] = selectedFilterPresetSearch?.split('_') || [
              '',
              '',
            ]

            //TODO make hook
            const searchParameters = Object.fromEntries(searchParams.entries())
            const talentGender = searchParams.getAll('talentGender')
            const talentAge = searchParams.getAll('talentAge')
            const talentRace = searchParams.getAll('talentRace')

            searchParameters.talentGender =
              talentGender.length > 0 ? talentGender : []
            searchParameters.talentAge = talentAge.length > 0 ? talentAge : []
            searchParameters.talentRace =
              talentRace.length > 0 ? talentRace : []

            handleEditSearchPreset(
              id,
              name,
              searchParameters,
              <>
                Updated <b>My Search:</b> <br />
                <b>{name}</b>
              </>,
            )
          }
          onUpdated?.()
        }}
      />

      <SaveAsItem
        text='Save as new search'
        setIsOpen={setIsOpen}
        onClick={() => saveAsNewSearch(true)}
      />
    </div>
  )
}
