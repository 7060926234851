import { ColumnsTalent, OptionType, TalentType } from 'types'
import rupert from 'assets/images/rupert.png'
import christian from 'assets/images/christian.png'
import margo from 'assets/images/margo.png'
import joaquin from 'assets/images/joaquin.png'
import saoirse from 'assets/images/saoirse.png'
import timothe from 'assets/images/timothe.png'
import tony from 'assets/images/tony.png'
import zendaya from 'assets/images/zendaya.png'
import yara from 'assets/images/yara.png'
import letitia from 'assets/images/letitia.png'
import tom from 'assets/images/tom.png'
import dev from 'assets/images/dev.png'
import noah from 'assets/images/noah.png'
import kelvin from 'assets/images/kelvin.png'
import dakota from 'assets/images/dakota.png'
import taraji from 'assets/images/taraji.png'
import kiernan from 'assets/images/kiernan.png'
import emily from 'assets/images/emily.png'
import storm from 'assets/images/storm.png'
import jacob from 'assets/images/jacob.png'
import obi from 'assets/images/obi.png'

export const rowsPerPageOptions: OptionType<number>[] = [
  { id: '1', value: 50, label: '50' },
  { id: '2', value: 100, label: '100' },
  { id: '3', value: 250, label: '250' },
]

export const DEFAULT_PAGE_SIZE = 250
export const MIN_PAGE_SIZE = 50

export const columnsTalent: ColumnsTalent[] = [
  { title: 'Talent Name', accessor: '', key: 'TALENT_NAME', horizontal: true },
  {
    title: 'Last Fielding',
    accessor: 'lastFielding',
    key: 'DATE_SURVEY_ID',
    horizontal: true,
  },
  {
    title: 'Awareness',
    key: 'AWARENESS',
    accessor: 'awareness',
    attributes: true,
  },
  { title: 'Fandom', accessor: 'fandom', key: 'FANDOM', attributes: true },
  {
    title: 'Will See (Theatre)',
    accessor: 'willseetheater',
    key: 'WILL_SEE_THEATER',
    attributes: true,
  },
  {
    title: 'Will See (Streaming)',
    accessor: 'willseestreaming',
    key: 'WILL_SEE_STREAMING',
    attributes: true,
  },
  {
    title: 'Authentic',
    accessor: 'authentic',
    key: 'AUTHENTIC',
    attributes: true,
  },
  { title: 'Funny', accessor: 'funny', key: 'FUNNY', attributes: true },
  { title: 'Likable', accessor: 'likable', key: 'LIKABLE', attributes: true },
  {
    title: 'Inspirational',
    accessor: 'inspirational',
    key: 'INSPIRATIONAL',
    attributes: true,
  },
  { title: 'Trendy', accessor: 'trendy', key: 'TRENDY', attributes: true },
  {
    title: 'Good Actor',
    accessor: 'goodactor',
    key: 'GOODACTOR',
    attributes: true,
  },
  { title: 'Sexy', accessor: 'sexy', key: 'SEXY', attributes: true },
  {
    title: 'Overexposed',
    accessor: 'overexposed',
    key: 'OVEREXPOSED',
    attributes: true,
  },
  {
    title: 'Overrated',
    accessor: 'overrated',
    key: 'OVERRATED',
    attributes: true,
  },
  {
    title: 'Underrated',
    accessor: 'underrated',
    key: 'UNDERRATED',
    attributes: true,
  },
  {
    title: 'All Followers',
    accessor: 'allFollowers',
    key: 'SOCIAL_SUBSCRIBER_TOTAL_COUNT',
    social: true,
  },
  {
    title: 'Wikipedia Page Views',
    accessor: 'wikipediaPageViews',
    key: 'SOCIAL_WIKIPEDIA_TOTAL_COUNT',
    search: true,
  },
]

export const talentDataTable: TalentType[] = [
  {
    id: '1',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: rupert,
      talentId: '1',
      generalId: '3',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '20%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '44%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '90%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '80%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '82%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '43%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '68%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '95%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '11%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '3%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '8%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '70%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '59%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '2',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: margo,
      talentId: '2',
      generalId: '2',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '7.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 2,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '3',
    talentPerson: {
      firstName: 'Tony',
      lastName: 'Revolori',
      talentUrl: tony,
      talentId: '3',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/13/2024',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '10.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 40,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '4',
    talentPerson: {
      firstName: 'Joaquin',
      lastName: 'Phoenix',
      talentUrl: joaquin,
      talentId: '4',
      generalId: '3',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/14/2024',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '30%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '39%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '75%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '43%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '42%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '41%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '44%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '60%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '63%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '22%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '88%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '15M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 13,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '5',
    talentPerson: {
      firstName: 'Christian',
      lastName: 'Bale',
      talentUrl: christian,
      talentId: '5',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/15/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '30%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '39%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '75%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '43%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '42%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '41%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '44%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '60%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '63%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '22%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '88%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '8.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 3,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '6777',
    talentPerson: {
      firstName: 'Saoirse',
      lastName: 'Ronan',
      talentUrl: saoirse,
      talentId: '6777',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/16/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '11.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 88,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '6',
    talentPerson: {
      firstName: 'Timothée',
      lastName: 'Chalamet',
      talentUrl: timothe,
      talentId: '6',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/17/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '7',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: joaquin,
      talentId: '7',
      blackId: '2',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/18/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '8',
    talentPerson: {
      firstName: 'Zendaya',
      lastName: '',
      talentUrl: zendaya,
      talentId: '8',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/19/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '9',
    talentPerson: {
      firstName: 'Yara',
      lastName: 'Shahidi',
      talentUrl: yara,
      talentId: '9',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/20/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '10',
    talentPerson: {
      firstName: 'Letitia',
      lastName: 'Wright',
      talentUrl: letitia,
      talentId: '10',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/21/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '11',
    talentPerson: {
      firstName: 'Tom',
      lastName: 'Holland',
      talentUrl: tom,
      talentId: '11',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/22/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '12',
    talentPerson: {
      firstName: 'Dev',
      lastName: 'Patel',
      talentUrl: dev,
      blackId: '1',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/23/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '13',
    talentPerson: {
      firstName: 'Noah',
      lastName: 'Schnapp',
      talentUrl: noah,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/24/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '14',
    talentPerson: {
      firstName: 'Kelvin',
      lastName: 'Harrison',
      talentUrl: kelvin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/25/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '15',
    talentPerson: {
      firstName: 'Dakota',
      lastName: 'Johnson',
      talentUrl: dakota,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/26/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '16',
    talentPerson: {
      firstName: 'Taraji',
      lastName: 'P. Henson',
      talentUrl: taraji,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/27/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '17',
    talentPerson: {
      firstName: 'Kiernan',
      lastName: 'Shipka',
      talentUrl: kiernan,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/28/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '18',
    talentPerson: {
      firstName: 'Emily',
      lastName: 'Blunt',
      talentUrl: emily,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/29/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '19',
    talentPerson: {
      firstName: 'Storm',
      lastName: 'Reid',
      talentUrl: storm,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/30/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '20',
    talentPerson: {
      firstName: 'Jacob',
      lastName: 'Elordi',
      talentUrl: jacob,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '01/05/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '21',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '22',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '05/02/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '23',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '24',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: jacob,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '2335',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: taraji,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '25',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: tom,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '26',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '27',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: emily,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '28',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: rupert,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '29',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: timothe,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '30',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '31',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: joaquin,
      latinoId: '2',
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '32',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '88',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '89',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '90',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '91',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '92',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '93',
    talentPerson: {
      firstName: 'Rupert',
      lastName: 'Friend',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
  {
    id: '94',
    talentPerson: {
      firstName: 'Margot',
      lastName: 'Robbie',
      talentUrl: joaquin,
      description:
        'Rupert William Anthony Friend is an English actor. He first gained recognition for his roles in The Libertine and Mrs. Palfrey at the Claremont, both of which won him awards for best newcomer.',
      personalData: {
        born: '1981 (age 42 years)',
        height: '6’1',
      },
      latestProjects: [
        {
          id: '1',
          projectSrc: obi,
          name: 'Obi Wan Kenobi',
          date: '2023',
        },
        {
          id: '2',
          projectSrc: obi,
          name: 'High Desert',
          date: '2023',
        },
        {
          id: '3',
          projectSrc: obi,
          name: 'Asteroid City',
          date: '2023',
        },
      ],
    },
    lastFielding: '04/12/24',
    attributes: [
      {
        name: 'awareness',
        values: {
          firstAmount: '14%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'fandom',
        values: {
          firstAmount: '13%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeTheatre',
        values: {
          firstAmount: '12%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'willSeeStreaming',
        values: {
          firstAmount: '96%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'authentic',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'funny',
        values: {
          firstAmount: '36%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'likable',
        values: {
          firstAmount: '26%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'inspirational',
        values: {
          firstAmount: '16%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'trendy',
        values: {
          firstAmount: '46%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'goodActor',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'sexy',
        values: {
          firstAmount: '6%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overexposed',
        values: {
          firstAmount: '1%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'overrated',
        values: {
          firstAmount: '51%',
          secondAmount: 72,
          thirdAmount: '23.9M',
        },
      },
      {
        name: 'underrated',
        values: {
          firstAmount: '56%',
          secondAmount: 73,
          thirdAmount: '23.9M',
        },
      },
    ],
    social: {
      allFollowers: '78.9M',
      velocity: { count: '+78M', hasInfo: true },
      velocityPercent: { count: '+78M', hasInfo: true },
      mentions: { count: '+78M' },
      sentiment: { count: '78% / 62% / 24%', hasInfo: true },
      reach: { count: '78M', hasInfo: true },
    },
    search: {
      googleTrends: 23,
      pageViews: { count: '48K' },
      velocity: { count: '48K', hasInfo: true },
      velocityPercent: { count: '+48K', hasInfo: true },
    },
  },
]
