import { ReactNode, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { Colors } from 'constnants'
import { FilterMenu } from 'components'
import { useClickOutsideComponent } from 'hooks'
import { Close, Filter } from 'components/ui/icons'

interface FilterButtonProps {
  disabled?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  name: string
  totalSelectedFilters?: number | undefined
  hideTotalNumber?: boolean
  handleReset?: () => void
  children: ReactNode
  trianglePosition?: 'left' | 'right'
  hideTriangle?: boolean
  wrapperMenuClassName?: string
  nameCLassName?: string
  containerMenuClassName?: string
  isMenuVisible?: boolean
  containerClassName?: string
  wrapperClasName?: string
  innerClassName?: string
  setIsMenuVisible?: (value: boolean) => void
  label?: string
  disableClickOutside?: boolean
  totalNumberPosition?: 'left' | 'right'
}

export const FilterButton = ({
  disabled,
  leftIcon = <Filter fill={Colors.BASE_ICON} width={16} height={16} />,
  rightIcon,
  name,
  totalSelectedFilters,
  hideTotalNumber = false,
  handleReset,
  children,
  trianglePosition,
  wrapperMenuClassName,
  nameCLassName,
  isMenuVisible,
  containerMenuClassName,
  containerClassName,
  wrapperClasName,
  innerClassName,
  hideTriangle = false,
  setIsMenuVisible,
  label,
  disableClickOutside = false,
  totalNumberPosition = 'left',
}: FilterButtonProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false, undefined, disableClickOutside)
  useEffect(() => {
    if (isMenuVisible) {
      setIsComponentVisible(!isMenuVisible)
      setIsMenuVisible && setIsMenuVisible(!isMenuVisible)
    }
  }, [isMenuVisible, setIsComponentVisible])

  const handleToggle = () => {
    if (!disabled) {
      setIsComponentVisible(prevState => !prevState)
    }
  }

  const onResetChange = () => {
    if (!disabled) {
      handleReset && handleReset()
    }
  }

  const isEmptySelectedFilters = totalSelectedFilters === 0

  return (
    <div className={twMerge('relative', containerClassName)} ref={ref}>
      {label && (
        <span className='mb-1 whitespace-nowrap text-xs text-primary-grey'>
          {label}
        </span>
      )}
      <div
        className={twMerge(
          'flex w-fit flex-row items-center rounded border border-primary-black/10 bg-primary-white duration-300',
          wrapperClasName,
        )}
        onClick={handleToggle}
      >
        <div
          className={twMerge(
            'flex h-[24px] w-full flex-row items-center',
            disabled
              ? 'bg-primary-black/[3%] hover:cursor-default hover:bg-primary-black/[3%]'
              : 'hover:cursor-pointer hover:bg-primary-black/5',
            isEmptySelectedFilters
              ? 'pl-1.5 pr-1.5'
              : handleReset
                ? 'pl-1.5 pr-0.5'
                : 'pl-1.5 pr-1.5',
            innerClassName,
          )}
        >
          <div className='pr-1'>
            {isEmptySelectedFilters && totalNumberPosition === 'left' ? (
              <div>{leftIcon}</div>
            ) : (
              totalNumberPosition === 'left' && (
                <div
                  className={twMerge(
                    'flex h-4 w-4 items-center justify-center rounded-full bg-red-6 p-1 text-xs font-bold text-primary-white',
                    hideTotalNumber && 'hidden',
                  )}
                >
                  {totalSelectedFilters}
                </div>
              )
            )}
            {totalNumberPosition === 'right' && <div>{leftIcon}</div>}
          </div>
          <span
            className={twMerge(
              'text-xs font-semibold text-primary-grey',
              disabled && 'text-grey-7',
              nameCLassName,
            )}
          >
            {name}
          </span>
          {!isEmptySelectedFilters && totalNumberPosition === 'right' && (
            <div className={'flex w-auto flex-shrink flex-grow justify-end'}>
              <div
                className={twMerge(
                  'flex h-4 w-4 items-center justify-center rounded-full bg-red-6 p-1 text-xs font-bold' +
                    ' text-primary-white',
                  hideTotalNumber && 'hidden',
                  disabled && 'opacity-50',
                )}
              >
                {totalSelectedFilters}
              </div>
            </div>
          )}
          {rightIcon && rightIcon}
        </div>
        {!isEmptySelectedFilters && handleReset && (
          <div
            className={twMerge(
              'p-1',
              disabled
                ? 'bg-primary-black/[3%] hover:cursor-default hover:bg-primary-black/[3%]'
                : 'hover:cursor-pointer hover:bg-primary-black/5',
            )}
            onClick={e => {
              e.stopPropagation()
              onResetChange()
            }}
          >
            <Close fill={Colors.BASE_ICON} width={16} height={16} />
          </div>
        )}
      </div>

      {isComponentVisible && (
        <FilterMenu
          trianglePosition={trianglePosition}
          wrapperClassName={wrapperMenuClassName}
          containerClassName={containerMenuClassName}
          hideTriangle={hideTriangle}
        >
          {children}
        </FilterMenu>
      )}
    </div>
  )
}
