import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TVManagementColKey, TVManagementSortColKey } from '../types'

interface PageSorting {
  order: 'ASC' | 'DESC'
  by: TVManagementSortColKey
}

interface PageConfig {
  pageSize: number
  pageNumber: number
}

interface PageState {
  pageConfig: PageConfig
  sorting: PageSorting
}

const sortingInitialState: PageSorting = {
  order: 'ASC',
  by: TVManagementColKey.Title,
}

const pageConfigInitialState: PageConfig = {
  pageSize: 50,
  pageNumber: 1,
}

const initialState: PageState = {
  pageConfig: pageConfigInitialState,
  sorting: sortingInitialState,
}

export const tvManagementSlice = createSlice({
  name: 'tvFilmCompanies',
  initialState,
  reducers: {
    setPageConfig: (state, action: PayloadAction<PageState['pageConfig']>) => {
      state.pageConfig = action.payload
    },
    setSorting: (state, action: PayloadAction<Partial<PageSorting>>) => {
      state.sorting = {
        ...sortingInitialState,
        ...action.payload,
      }
    },
  },
  selectors: {
    root: state => state,
  },
})

export const {
  actions: tvManagementActions,
  selectors: tvManagementSelectors,
} = tvManagementSlice
