import { Tooltip } from 'react-tooltip'
import { AssigneeAvatar } from '../../AssigneeAvatar'
import { ProjectUser } from 'types'
import { getFullName, getInitials } from 'utils'

interface ProjectsTeamProps {
  teams: ProjectUser[]
}

const mockTeams: ProjectUser[] = [
  {
    id: '1',
    userProfile: {
      id: '1',
      userImage: '',
      firstName: 'Max',
      lastName: 'Max',
      email: '',
    },
  },
  {
    id: '2',
    userProfile: {
      id: '2',
      userImage: '',
      firstName: 'Serhii',
      lastName: 'Serhii',
      email: '',
    },
  },
  {
    id: '3',
    userProfile: {
      id: '3',
      userImage: '',
      firstName: 'Robert',
      lastName: 'Doe',
      email: '',
    },
  },
  {
    id: '4',
    userProfile: {
      id: '4',
      userImage: '',
      firstName: 'John',
      lastName: 'Doe',
      email: '',
    },
  },
  {
    id: '5',
    userProfile: {
      id: '5',
      userImage: '',
      firstName: 'Anna',
      lastName: 'Anna',
      email: '',
    },
  },
]

//TODO change mock team array

export const ProjectsTeam = ({ teams }: ProjectsTeamProps) => {
  const isMoreFour = mockTeams.length >= 4
  const visibleTeams = isMoreFour ? mockTeams.slice(0, 3) : mockTeams
  const hiddenTeams = isMoreFour ? mockTeams.slice(3) : []

  return (
    <div className='flex space-x-0.5'>
      {visibleTeams.map(team => {
        const userInitials = getInitials(
          getFullName(team.userProfile.firstName, team.userProfile.lastName),
        )

        return (
          <AssigneeAvatar key={`${team.id}`} initials={userInitials ?? ''} />
        )
      })}
      {isMoreFour && (
        <span
          data-tooltip-id='team-tooltip'
          className='ml-1 text-sm text-primary-black hover:cursor-pointer'
        >{`...${mockTeams.length} users`}</span>
      )}
      <Tooltip
        id='team-tooltip'
        place='bottom'
        style={stylesTooltip}
        render={() => (
          <div className='flex flex-col space-y-1'>
            {hiddenTeams.map(team => {
              const userInitial = getInitials(team.userProfile.firstName)
              const fullName = getFullName(
                team.userProfile.firstName,
                team.userProfile.lastName,
              )
              return (
                <div key={team.id} className='flex gap-1'>
                  <AssigneeAvatar
                    key={`${team.id}`}
                    initials={userInitial ?? ''}
                    className='h-4 w-4'
                  />
                  <span className='text-xs font-medium text-primary-white'>
                    {fullName}
                  </span>
                </div>
              )
            })}
          </div>
        )}
        opacity={1}
      />
    </div>
  )
}

const stylesTooltip = {
  width: 'auto',
  backgroundColor: '#4D4B4A',
  color: '#FFFFFF',
  borderRadius: '6px',
  boxShadow: '0 3px 3px 3px rgb(0 0 0 / 0.02)',
  padding: '8px',
}
