import { twMerge } from 'tailwind-merge'
import { CompanyTableHeaderProps } from '../CompanyTable.types'
import { ChevronDown, ChevronUp } from 'components/ui/icons'
import { Colors } from 'constnants'
import {
  CompaniesSortByOptions,
  CompaniesSortOrderOptions,
} from '../../../types'

export const CompanyTableHeader = ({
  columns,
  onSort,
  onSortOrder,
  sortingBY,
}: CompanyTableHeaderProps) => {
  const onSortHandler = (value: CompaniesSortByOptions) => {
    if (onSort) {
      if (sortingBY === value) {
        onSort((prevSort: CompaniesSortOrderOptions) =>
          prevSort === 'ASC' ? 'DESC' : 'ASC',
        )
      } else {
        onSort('ASC' as CompaniesSortOrderOptions)
      }
    }
    if (onSortOrder) {
      onSortOrder(value)
    }
  }

  return (
    <thead>
      <tr>
        {columns.map(column => (
          <th
            key={column.value}
            className={twMerge(
              'border-b border-b-primary-black/20 bg-grey-3 p-3 text-xs font-normal text-primary-grey hover:cursor-pointer',
            )}
          >
            <div>
              {column.title && (
                <div
                  className='flex items-center gap-2'
                  onClick={() =>
                    onSortHandler(column.value as CompaniesSortByOptions)
                  }
                >
                  {column.title}
                  <div>
                    <ChevronUp
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                      className='-mb-1'
                    />
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                    />
                  </div>
                </div>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}
