import { Budget, NewProjectTypeSelector } from '../types'

export const primaryGenre: NewProjectTypeSelector[] = [
  {
    name: 'Action',
    id: 'action1',
  },
  { name: 'Comedy', id: 'comedy1' },
  { name: 'Drama', id: 'drama1' },
  { name: 'Horror', id: 'horror1' },
  { name: 'Animation', id: 'animation1' },
  { name: 'Family - Live Action', id: 'familyLiveAction1' },
]

export const secondaryGenre: NewProjectTypeSelector[] = [
  {
    name: 'Action',
    id: 'action2',
  },
  { name: 'Adventure', id: 'adventure2' },
  { name: 'Anime', id: 'anime2' },
  { name: 'Animation', id: 'animation2' },
  { name: 'Biography', id: 'biography2' },
  { name: 'Comedy', id: 'comedy2' },
  { name: 'Crime', id: 'crime2' },
  { name: 'Documentary', id: 'documentary2' },
  { name: 'Drama', id: 'drama2' },
  { name: 'Faith Based', id: 'faithBased2' },
  { name: 'Family - Live Action', id: 'familyLiveAction2' },
  { name: 'Fantasy', id: 'fantasy2' },
  { name: 'History', id: 'history2' },
  { name: 'Horror', id: 'horror2' },
  { name: 'Independent', id: 'independent2' },
  { name: 'Musical', id: 'musical2' },
  { name: 'Mystery', id: 'mystery2' },
  { name: 'Romance', id: 'romance2' },
  { name: 'Sci-Fi', id: 'sci-fi2' },
  { name: 'Sports', id: 'sports2' },
  { name: 'Superhero', id: 'superhero2' },
  { name: 'Thriller', id: 'thriller2' },
  { name: 'War', id: 'war2' },
  { name: 'Western', id: 'western2' },
]

export const expectedMpaaRating: NewProjectTypeSelector[] = [
  {
    name: 'G',
    id: 'g',
  },
  {
    name: 'PG',
    id: 'pg',
  },
  {
    name: 'PG-13',
    id: 'pg-13',
  },
  {
    name: 'R',
    id: 'r',
  },
]

export const expectedBudget: NewProjectTypeSelector[] = [
  { name: '<1 - 9M', id: '<1 - 9M' },
  { name: '10 - 24M', id: '10 - 24M' },
  { name: '25 - 49M', id: '25 - 49M' },
  { name: '50 - 74M', id: '50 - 74M' },
  { name: '75 - 99M', id: '75 - 99M' },
  { name: '100 - 149M', id: '100 - 149M' },
  { name: '150 - 199M', id: '150 - 199M' },
  { name: '>200M', id: '>200M' },
]

export const preparedExpectedBudget: Budget[] = expectedBudget.map(
  (budget, index) => ({
    id: index + 1,
    name: budget.name,
  }),
)
