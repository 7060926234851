import { TestingTableCellProps } from '../CompanyTable.types'
import { twMerge } from 'tailwind-merge'
import { Pencil } from '../../ui/icons'
import { Colors } from '../../../constnants'
import { useNavigate } from 'react-router-dom'

export const CompanyTableCell = ({
  column,
  row,
  isLoading = false,
  setCompanyDataToEdit,
}: TestingTableCellProps) => {
  const navigate = useNavigate()
  switch (column.value) {
    case 'name':
      return (
        <span
          className={twMerge(
            'inline-flex text-xs font-medium text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.name}
        </span>
      )
    case 'usersLink':
      return (
        <span
          className={twMerge(
            'flex text-red-9',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          <span
            className='text-sm  hover:cursor-pointer'
            onClick={() => {
              navigate(`/userManagement?company=${row.id}`)
            }}
          >
            View Users
          </span>
        </span>
      )
    case 'status':
      return (
        <span
          className={twMerge(
            'flex items-center gap-1 text-xs font-medium capitalize',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.status && !isLoading && (
            <span
              className={twMerge(
                'h-2 w-2 rounded-full',
                row.status === 'active' ? 'bg-green-6' : 'bg-red-10',
              )}
            ></span>
          )}
          {row.status ? row.status : 'N/A'}
        </span>
      )
    case 'created_at':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.created_at}
        </span>
      )
    default:
      return (
        <span
          className={'cursor-pointer'}
          onClick={() =>
            setCompanyDataToEdit({
              id: row.id,
              companyName: row.name,
              companyType: row.type ? row.type : '',
              addressLine1: row.address?.addressLine1
                ? row.address.addressLine1
                : '',
              addressLine2: row.address?.addressLine2
                ? row.address.addressLine2
                : '',
              city: row.address?.city ? row.address.city : '',
              state: row.address?.state ? row.address.state : '',
              zipCode: row.address?.zipCode ? row.address.zipCode : '',
              primaryContact: row.primary_contact ? row.primary_contact : '',
              email: row.owner.email ? row.owner.email : '',
              accountStatus: row.status ? row.status : '',
            })
          }
        >
          <Pencil fill={Colors.BASE_ICON} width={16} height={16} />
        </span>
      )
  }
}
