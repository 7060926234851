import React, { ReactNode, useEffect, useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { moviesFiltersType, OptionType } from 'types'
import { Button, SaveAsFiltersDropdown, TalentListMenuItem } from 'components'
import { useCompFinderSearchFilter } from '../../hooks'
import PortalModal from '../PortalModal/PortalModal'

interface RoleItemListProps {
  list?: OptionType[]
  title?: string
  rightButton?: ReactNode
  hasEditTrash?: boolean
  icon?: ReactNode
  handleOpenDelete?: (id: string, value: string) => void
  handleSelectRole?: (role: OptionType) => void
  hasTrash?: boolean
  onClickItem?: (role: OptionType) => void
  activeSavedSearch?: string
  isSavedSearchChanged?: boolean
  setIsSavedSearchChanged?: (isChanged: boolean) => void
  handleOpenEdit?: (id: string, value: string) => void
  activeFilters?: moviesFiltersType
  searchToModify?: string
  setUpdatedFilters?: (filters: moviesFiltersType) => void
  setSearchModalOpened?: (status: boolean) => void
  onReset?: (option: OptionType) => void
  onUpdateSearchPreset?: () => void
}

type savedSearchType = {
  search_id: string
  name: string
  search_parameters: string
}

export const RoleItemList = ({
  list,
  title,
  rightButton,
  hasEditTrash = false,
  icon,
  handleOpenDelete,
  handleSelectRole,
  hasTrash,
  onClickItem,
  activeSavedSearch,
  isSavedSearchChanged,
  setIsSavedSearchChanged,
  handleOpenEdit,
  activeFilters,
  searchToModify,
  setUpdatedFilters,
  setSearchModalOpened,
  onReset,
  onUpdateSearchPreset,
}: RoleItemListProps) => {
  const { listId } = useParams<{ listId: string }>()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const presetSearchFromUrl = searchParams.get('presetSearch') || ''
  const [_, presetSearchId] = presetSearchFromUrl.split('_')

  const { handleEditSearchPreset } = useCompFinderSearchFilter()
  const [searchToModifyTitle, setSearchToModifyTitle] = useState<string>('')
  const [isOpenSaveAsSearch, setIsOpenSaveAsSearch] = useState(false)
  const specialDivRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (searchToModify) {
      const item = list?.find(
        (item: savedSearchType) => item.id === searchToModify,
      )
      if (item) {
        setSearchToModifyTitle(item.value)
      }
    } else {
      setSearchToModifyTitle('')
    }
  }, [searchToModify, list])

  return (
    <>
      {list && (
        <ul className='flex w-full flex-col gap-1'>
          <div className='flex w-full items-center justify-between'>
            {title && (
              <p className='px-2 pb-1 text-xs uppercase text-primary-grey'>
                {title}
              </p>
            )}
            {rightButton && <>{rightButton}</>}
          </div>

          {list.map((role, i) => {
            const isFilterChanged =
              isSavedSearchChanged && activeSavedSearch === role.id

            const isActive =
              (listId || presetSearchId) &&
              (role.id === listId || role.id === presetSearchId)

            return (
              <React.Fragment key={`${role.value}_${i}`}>
                <li
                  onClick={() => {
                    onClickItem && onClickItem(role)
                    handleSelectRole && handleSelectRole(role)
                  }}
                >
                  <TalentListMenuItem
                    role={role}
                    isActive={isActive}
                    hasEditTrash={hasEditTrash}
                    icon={icon}
                    handleOpenDelete={e => {
                      e.stopPropagation()
                      handleOpenDelete &&
                        handleOpenDelete(role.id ?? '', role.label ?? '')
                    }}
                    handleOpenEdit={e => {
                      e.stopPropagation()
                      handleOpenEdit && handleOpenEdit(role.id, role.value)
                    }}
                    hasTrash={hasTrash}
                    textClassName={
                      isFilterChanged || activeSavedSearch === role.id
                        ? 'bg-red-2 text-red-9'
                        : ''
                    }
                    handleClick={setIsSavedSearchChanged}
                  />
                </li>
                {isFilterChanged && (
                  <div className={'mt-2 flex gap-2'}>
                    <Button
                      kind={'filled'}
                      size={'small'}
                      onClick={() => {
                        setIsOpenSaveAsSearch(true)
                      }}
                      className={'relative'}
                    >
                      <span className='flex px-1' ref={specialDivRef}>
                        Save
                      </span>
                    </Button>
                    <Button
                      kind={'text'}
                      size={'small'}
                      onClick={() => onReset?.(role)}
                    >
                      Reset
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </ul>
      )}
      <PortalModal
        isOpen={isOpenSaveAsSearch}
        onClose={() => setIsOpenSaveAsSearch(false)}
        targetRef={specialDivRef}
      >
        <SaveAsFiltersDropdown
          isOpen={isOpenSaveAsSearch}
          setIsOpen={setIsOpenSaveAsSearch}
          saveAsNewSearch={setSearchModalOpened}
          updateCurrentSearch={() => {
            if (typeof onUpdateSearchPreset === 'function') {
              onUpdateSearchPreset()
            } else {
              handleEditSearchPreset(
                activeFilters,
                searchToModify,
                searchToModifyTitle,
                setUpdatedFilters,
              )
            }
          }}
          className={'static'}
        />
      </PortalModal>
    </>
  )
}
