import { PropsWithChildren, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Colors } from 'constnants'
import { Info } from '../icons'

export type InformationBoxProps = PropsWithChildren<{
  icon?: ReactNode
  label?: string
  wrapperClassName?: string
  containerClassName?: string
  iconWrapperClassName?: string
}>

export const InformationBox = ({
  icon,
  label,
  children,
  wrapperClassName,
  iconWrapperClassName,
  containerClassName,
}: InformationBoxProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-row items-center gap-x-2 px-3 py-2',
        wrapperClassName,
      )}
    >
      <div className={twMerge(iconWrapperClassName)}>
        {icon ? icon : <Info fill={Colors.BASE_ICON} width={16} height={16} />}
      </div>

      <div className={twMerge('text-primary-dark', containerClassName)}>
        {label || children}
      </div>
    </div>
  )
}
