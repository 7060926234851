export const DashedLines = () => {
  return (
    <div>
      {Array.from({ length: 6 }, (_, i) => (
        <div
          key={i}
          className='absolute h-full border-r border-dashed'
          style={{
            left: `${((i + 1) * 100) / 7}%`,
            width: '1px',
          }}
        />
      ))}
    </div>
  )
}
