import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { IconButton, Label } from 'components'
import { Pencil } from 'components/ui/icons'
import { Colors } from 'constnants'

interface MovieDetailsItemProps {
  label: string
  children: ReactNode
  wrapperClassName?: string
  handleOpen?: () => void
  tooltipId?: string
  isEditable?: boolean
}

const NotEditableMock = () => (
  <IconButton className='ml-auto hover:opacity-70'>
    <div className='ml-auto h-4 w-4 hover:opacity-70'></div>
  </IconButton>
)

export const MovieDetailsItem = ({
  label,
  children,
  wrapperClassName,
  handleOpen,
  tooltipId,
  isEditable = true,
}: MovieDetailsItemProps) => {
  return (
    <div
      className={twMerge(
        'flex items-center gap-4 border-b border-b-primary-black/20 py-2',
        wrapperClassName,
      )}
      data-tooltip-id={tooltipId}
    >
      <Label label={label} labelClassName='min-w-[88px] text-xs' />
      {children}
      {isEditable ? (
        <IconButton className='ml-auto hover:opacity-70' onClick={handleOpen}>
          <Pencil fill={Colors.BASE_ICON} width={16} height={16} />
        </IconButton>
      ) : (
        <NotEditableMock />
      )}
    </div>
  )
}
