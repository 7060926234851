import React, { ReactNode } from 'react'
import {
  CompareWindowPage,
  MyProjectsPage,
  ProjectDetailPage,
  TalentDetailPage,
  TalentPage,
  TalentListDetailPage,
  FilmTrackingPage,
  ReleaseCalendarPage,
  FilmDetailPage,
  ConceptTestingPage,
  AssetTestingPage,
  RoleTestingPage,
  CompanyManagementPage,
  UserManagementPage,
  AdminManagementPage,
  HomePage,
  TestingModulesPage,
  CompFinderPage,
  FilmTrackingComparePage,
} from 'pages'
import { Colors } from './colors'
import {
  Calendar,
  CompFinder,
  FilmTracking,
  Folder,
  Help,
  Home,
  Settings,
  Talent,
  ConceptTesting,
  RoleTesting,
  AssetTesting,
} from 'components/ui/icons'
import { Urls } from 'types'
import { TVFilmCompaniesPage, TVManagementPage } from 'modules/management'

export type RouteType = {
  name: string
  path: string
  icon?: ReactNode
  component?: ReactNode
  isSidebar?: boolean
  isAdminNavigation?: boolean
}

export const routeDefs = {
  home: {
    name: 'Home',
    path: Urls.HOME,
    icon: <Home fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <HomePage />,
    isSidebar: true,
  },
  detailProject: {
    name: 'Detail Project',
    path: Urls.DETAIL_PROJECT,
    icon: <Home fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <ProjectDetailPage />,
  },
  myProjects: {
    name: 'My Projects',
    path: Urls.MY_PROJECTS,
    icon: <Folder fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <MyProjectsPage />,
    isSidebar: true,
  },
  talent: {
    name: 'Talent',
    path: Urls.TALENT,
    icon: <Talent fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <TalentPage />,
    isSidebar: true,
  },
  talentDetail: {
    name: 'Talent Detail',
    path: Urls.TALENT_DETAIL,
    component: <TalentDetailPage />,
  },
  talentListDetail: {
    name: 'Talent List Detail',
    path: Urls.TALENT_LIST_DETAIL,
    component: <TalentListDetailPage />,
  },
  compare: {
    name: 'Compare',
    path: `${Urls.TALENT}${Urls.COMPARE}`,
    component: <CompareWindowPage />,
  },
  compFinder: {
    name: 'Comp Finder',
    path: Urls.COMP_FINDER,
    icon: <CompFinder fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <CompFinderPage />,
    isSidebar: true,
  },
  releaseCalendar: {
    name: 'Release Calendar',
    path: Urls.RELEASE_CALENDAR,
    icon: <Calendar fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <ReleaseCalendarPage />,
    isSidebar: true,
  },
  filmTracking: {
    name: 'Film Tracking',
    path: Urls.FILM,
    icon: <FilmTracking fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <FilmTrackingPage />,
    isSidebar: true,
  },
  filmDetail: {
    name: 'Film Detail',
    path: Urls.FILM_DETAIL,
    component: <FilmDetailPage />,
  },
  filmsCompare: {
    name: 'Films Compare',
    path: `${Urls.FILM}${Urls.COMPARE}`,
    component: <FilmTrackingComparePage />,
  },
  conceptTesting: {
    name: 'Concept Testing',
    path: Urls.CONCEPT_TESTING,
    icon: <ConceptTesting fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <ConceptTestingPage />,
    isSidebar: true,
  },
  roleTesting: {
    name: 'Role Testing',
    path: Urls.ROLE_TESTING,
    icon: <RoleTesting fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <RoleTestingPage />,
    isSidebar: true,
  },
  assetTesting: {
    name: 'Asset Testing',
    path: Urls.ASSET_TESTING,
    icon: <AssetTesting fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <AssetTestingPage />,
    isSidebar: true,
  },
  settings: {
    name: 'Settings',
    path: 'settings_modal',
    icon: <Settings fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <></>,
    isSidebar: true,
  },
  help: {
    name: 'Help',
    path: Urls.HELP,
    icon: <Help fill={Colors.NAV_ICON_ONE} width={20} height={20} />,
    component: <></>,
    isSidebar: false,
  },
  companyMgmt: {
    name: 'Company Management',
    path: Urls.COMPANY_MANAGEMENT,
    component: <CompanyManagementPage />,
    isAdminNavigation: true,
  },
  userMgmt: {
    name: 'User Management',
    path: Urls.USER_MANAGEMENT,
    component: <UserManagementPage />,
    isAdminNavigation: true,
  },
  adminMgmt: {
    name: 'Admin Management',
    path: Urls.ADMIN_MANAGEMENT,
    component: <AdminManagementPage />,
    isAdminNavigation: true,
  },
  testingModules: {
    name: 'Testing Modules',
    path: Urls.TESTING_MODULES,
    component: <TestingModulesPage />,
    isAdminNavigation: true,
  },
  tvFilmCompanies: {
    name: 'TV & Film Companies',
    path: Urls.MANAGEMENT_TV_FILM_COMPANIES,
    component: <TVFilmCompaniesPage />,
    isAdminNavigation: true,
  },
  tvMgmt: {
    name: 'TV Management',
    path: Urls.TV_MANAGEMENT,
    component: <TVManagementPage />,
    isAdminNavigation: true,
  },
} satisfies Record<string, RouteType>

export const routes: RouteType[] = Object.values(routeDefs)
