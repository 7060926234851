import { Project, ProjectStatus } from '../types'

export const countProjectsByPhaseStatus = (
  projects: Project[] | undefined,
  phaseName: ProjectStatus,
): number => {
  return (
    projects?.filter(project => project.phase.name === phaseName).length || 0
  )
}
