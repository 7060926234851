import { useState, useEffect } from 'react'
import { BaseModal, Button, InformationBox, Input } from 'components'
import { Tooltip } from 'react-tooltip'
import { stylesTooltip } from './stylesTooltip'

interface EditTalentsListModalProps {
  isOpen: boolean
  handleClose: () => void
  handleEdit: (id: string, inputValue: string, currentListId?: string) => void
  currentListId?: string
  value: { id: string; value?: string }
}

const tooltipPresetSavedSearchContent = {
  name: 'Please enter your List name',
}

export const EditTalentsListModal = ({
  isOpen,
  handleClose,
  handleEdit,
  currentListId,
  value,
}: EditTalentsListModalProps) => {
  const initErrorsObj = { name: false }
  const [inputValue, setInputValue] = useState(value.value || '')
  const [errors, setErrors] = useState(initErrorsObj)

  useEffect(() => {
    setInputValue(value.value || '')
  }, [value])

  useEffect(() => {
    const v = validate()
    setErrors(prev => ({ ...prev, ...v.errs }))
  }, [inputValue])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const validate = () => {
    let hasErr = false
    const errs = { ...initErrorsObj }

    if (inputValue === '') {
      errs.name = true
      hasErr = true
    }

    return { hasErr: hasErr, errs }
  }

  const handleSaveClick = () => {
    const v = validate()

    if (!v.hasErr) {
      const id = value.id
      handleEdit(id, inputValue, currentListId)
      handleClose()
    }
  }
  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleClose}
      titleClass='pb-4'
      wrapperClass='p-0'
    >
      <div className='px-8 pb-4 pt-8'>
        <p className='pb-4 text-lg font-medium text-primary-black'>
          Rename List
        </p>

        <div
          data-tooltip-id='presetSavedSearchName'
          data-tooltip-content={tooltipPresetSavedSearchContent.name}
        >
          <Input
            containerWrapperClassName='pb-4'
            inputClassName='h-[40px] px-3'
            placeholder='Enter Search List name'
            value={inputValue}
            onChange={handleInputChange}
            isError={errors.name}
          />
        </div>

        <Tooltip
          id={'presetSavedSearchName'}
          place='left'
          style={stylesTooltip}
          render={({ content }) => <span>{content}</span>}
          opacity={1}
          isOpen={errors.name}
        />

        <div className='flex justify-end gap-2'>
          <Button
            kind='text'
            size='medium'
            onClick={handleClose}
            className='py-2.5'
          >
            <span className='px-2 text-xs font-semibold'>Cancel</span>
          </Button>

          <Button
            onClick={handleSaveClick}
            kind='filled'
            size='medium'
            className='bg-primary-red py-2.5'
          >
            <span className='px-2 text-xs font-semibold'>Save</span>
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
