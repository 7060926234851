import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { TestingTableBodyProps } from '../TestingTable.types'
import { ProjectType } from 'types'
import { TestingTableCell } from './TestingTableCell'

export const TestingTableBody = <T extends ProjectType>({
  data,
  columns,
  hasSpecialFirstCell = true,
  isLoading,
}: TestingTableBodyProps<T>) => {
  const placeholderData = Array.from({ length: 20 }, (_, index) => ({
    id: index,
    testName: 'testName',
    submitDate: 'submitDate',
    completeDate: 'completeDate',
    status: 'status',
    pdfLink: 'pdfLink',
  }))

  const [dataToMap, setDataToMap] = useState(data)

  useEffect(() => {
    setDataToMap(isLoading ? placeholderData : data)
  }, [isLoading, data])

  return (
    <tbody>
      {dataToMap.length > 0 &&
        dataToMap.map(row => (
          <tr key={row.testName + '_' + row.id} className='project-row'>
            {columns.map((column, index) => (
              <td
                key={column.value}
                className={twMerge(
                  'mb-10 rounded p-2',
                  hasSpecialFirstCell && 'special-cell',
                  index == 0 && 'w-[480px]',
                  isLoading && 'animate-pulse',
                )}
              >
                <TestingTableCell
                  row={row}
                  column={column}
                  isLoading={isLoading}
                />
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  )
}
