import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { OptionType } from 'types'

interface UseEntityLookupProps<T> {
  load: (searchTerm: string) => Promise<void>
  filter?: (option: OptionType<T>) => void
  options: OptionType<T>[]
}

export const useEntityLookupByField = <T>({
  load,
  filter,
  options,
}: UseEntityLookupProps<T>) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showAutocomplete, setShowAutocomplete] = useState(false)

  const optionsFiltered = useMemo(
    () => (filter ? options.filter(filter) : options),
    [options, filter],
  )

  const loadData = async (term: string) => {
    if (term.length < 3) {
      setShowAutocomplete(false)
      return
    }

    try {
      setIsLoading(true)
      setShowAutocomplete(false)
      await load(term)
      setShowAutocomplete(true)
    } catch (e) {
      setShowAutocomplete(false)
    } finally {
      setIsLoading(false)
    }
  }

  const debouncedLoadData = useCallback(debounce(loadData, 750), [])

  const handleInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(ev.target.value)
      debouncedLoadData(ev.target.value)
    },
    [debouncedLoadData],
  )

  return {
    searchTerm,
    setSearchTerm,
    showAutocomplete,
    isLoading,
    suggestions: isLoading ? [] : optionsFiltered,
    handleInputChange,
    setShowAutocomplete,
  }
}
