import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronDown, ChevronRight, Close, Search } from '../../ui/icons'
import {
  Colors,
  dataDistributorsCompFinder,
  dataFiltersInitial,
  releaseMonthOptions,
} from '../../../constnants'
import { FilterColumnGroup } from '../../FilterColumnGroup'
import { FilterButton } from '../../FilterButton'
import { Button, Checkbox, CustomRangeSlider, Input } from '../../ui'
import {
  DataFiltersType,
  OptionFilterDef,
  moviesFiltersType,
  OptionFilter,
  SavedSearchTypes,
  SelectedFilters,
} from '../../../types'

import { twMerge } from 'tailwind-merge'
import {
  useGetAllTalentsSerchesQuery,
  useGetDistributorsQuery,
  useGetReleaseYearsQuery,
} from '../../../store/api'
import { SaveAsFiltersDropdown } from '../../SaveAsFiltersDropdown'
import { FilterDisplay } from './FilterDisplay'
import { findLabel, toLocalNumber } from './utils'
import { useCompFinderSearchFilter } from '../../../hooks'
import { sortAlphabetically } from 'utils'
import _ from 'lodash'

interface CompFiltersProps {
  isOpened: boolean
  setIsOpened: (isOpened: boolean) => void
  activeFilters: moviesFiltersType
  setActiveFilters: (value: moviesFiltersType) => void
  updatedFilters: moviesFiltersType
  setUpdatedFilters: (value: moviesFiltersType) => void
  initialActiveFilters: moviesFiltersType
  setSearchModalOpened: (value: boolean) => void

  searchToModify?: string
  isSavedFilterChanged?: boolean
  resetSavedSearch: () => void
}

type savedSearchType = {
  search_id: string
  name: string
  search_parameters: string
}

export const CompFilters = ({
  isOpened,
  setIsOpened,
  activeFilters,
  setActiveFilters,
  updatedFilters,
  setUpdatedFilters,
  initialActiveFilters,
  setSearchModalOpened,
  searchToModify,
  isSavedFilterChanged,
  resetSavedSearch,
}: CompFiltersProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { data: allList } = useGetAllTalentsSerchesQuery({
    type: SavedSearchTypes.COMP,
  })
  const { data: releaseYears } = useGetReleaseYearsQuery()
  const { data: distributorsFilterData } = useGetDistributorsQuery()

  const { handleEditSearchPreset } = useCompFinderSearchFilter()
  const [searchToModifyTitle, setSearchToModifyTitle] = useState<string>('')

  const distributorOptions = useMemo(() => {
    if (!distributorsFilterData) {
      return []
    }

    const distributorsByTypeDict = _.groupBy(distributorsFilterData, it =>
      (it.distributor_type || 'hidden').toLowerCase(),
    )

    const distributorOptions: OptionFilterDef[] =
      dataDistributorsCompFinder.map(it => {
        const distributorsByType = distributorsByTypeDict[it.id]
        if (distributorsByType) {
          const options = distributorsByType.map(
            ({ company_id, company_name }) => ({
              id: company_id.toString(),
              value: company_id.toString(),
              label: company_name,
            }),
          )
          sortAlphabetically(options, it => it.label)
          return {
            ...it,
            options,
          }
        }

        return {
          ...it,
          options: [],
        }
      })
    return distributorOptions
  }, [distributorsFilterData])

  const releaseYearsOptions = useMemo(() => {
    if (!releaseYears) {
      return []
    }

    return releaseYears
      .filter(year => typeof year === 'number')
      .map((year, index) => ({
        id: (index + 1).toString(),
        value: year.toString(),
        label: year.toString(),
      }))
  }, [releaseYears])

  const dataFilters = useMemo((): DataFiltersType[] => {
    return dataFiltersInitial.map(filter => {
      if (filter.id === 'distributor') {
        return {
          ...filter,
          options: distributorOptions,
        }
      }
      if (filter.id === 'releaseYears') {
        return {
          ...filter,
          options: releaseYearsOptions,
        }
      }
      return filter
    })
  }, [distributorOptions, releaseYearsOptions])

  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const [openingWeekendRange, setOpeningWeekendRange] = useState([
    activeFilters.openingWeekendTheaters[0],
    activeFilters.openingWeekendTheaters[1],
  ])
  const [boxOfficeRange, setBoxOfficeRange] = useState([
    activeFilters.boxOffice[0],
    activeFilters.boxOffice[1],
  ])
  const [totalGrossRange, setTotalGrossRange] = useState([
    activeFilters.totalGross[0],
    activeFilters.totalGross[1],
  ])
  const [searchFranchise, setSearchFranchise] = useState('')
  const [searchUniverse, setSearchUniverse] = useState('')
  const [isOpenSaveAsSearch, setIsOpenSaveAsSearch] = useState(false)

  const resetOpeningWeekend = () => {
    setOpeningWeekendRange([500, 5000])
    setUpdatedFilters({
      ...updatedFilters,
      openingWeekendTheaters: [500, 5000],
    })
  }

  const resetBoxOffice = () => {
    setBoxOfficeRange([0, 400])
    setUpdatedFilters({
      ...updatedFilters,
      boxOffice: [0, 400],
    })
  }

  const resetTotalGross = () => {
    setTotalGrossRange([0, 990])
    setUpdatedFilters({
      ...updatedFilters,
      totalGross: [0, 990],
    })
  }

  const resetRangeFilters = () => {
    resetOpeningWeekend()
    resetBoxOffice()
    resetTotalGross()
  }

  useEffect(() => {
    setActiveFilters({
      ...activeFilters,
      openingWeekendTheaters: openingWeekendRange,
    })
  }, [openingWeekendRange])

  useEffect(() => {
    setActiveFilters({
      ...activeFilters,
      boxOffice: boxOfficeRange,
    })
  }, [boxOfficeRange])

  useEffect(() => {
    setActiveFilters({
      ...activeFilters,
      totalGross: totalGrossRange,
    })
  }, [totalGrossRange])

  useEffect(() => {
    if (
      JSON.stringify(activeFilters) !== JSON.stringify(initialActiveFilters)
    ) {
      setIsFilterChanged(true)
    } else {
      setIsFilterChanged(false)
    }
  }, [activeFilters])

  useEffect(() => {
    if (searchToModify) {
      const item = allList?.find(
        (item: savedSearchType) => item.search_id === searchToModify,
      )
      if (item) {
        setSearchToModifyTitle(item.name)
      }
    } else {
      setSearchToModifyTitle('')
    }
  }, [searchToModify, allList])

  useEffect(() => {
    const queryParams = window.location.search
    if (queryParams.includes('resetFilter')) {
      resetAllFilters()
      navigate(window.location.pathname)
    }
  }, [location])

  const handleSearchFranchise = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFranchise(e.target.value)
  }

  const handleSearchUniverse = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUniverse(e.target.value)
  }

  function formatArrayValues(
    dataFilters: DataFiltersType[],
    values: any[],
    labelType: string,
    sortFn?: (values: string[]) => string[],
  ): string {
    if (!Array.isArray(values)) return 'All'

    const sortedValues = sortFn ? sortFn(values) : values
    const formattedValues = sortedValues
      .map(value => findLabel(dataFilters, labelType, value))
      .filter(label => label)
      .join(', ')

    return formattedValues || 'All'
  }

  const resetAllFilters = () => {
    resetRangeFilters()
    setActiveFilters({
      ...initialActiveFilters,
    })
    setUpdatedFilters(initialActiveFilters)
    setSearchToModifyTitle('')
    localStorage.removeItem('dataToUpdateSearchList')
  }

  return (
    <div className='mx-3 mt-3 flex items-start gap-1 rounded bg-grey-4 p-2.5'>
      <button
        className='p-1'
        onClick={() => {
          setIsOpened(!isOpened)
        }}
      >
        <ChevronRight
          width={16}
          height={16}
          fill={Colors.BASE_ICON}
          className={isOpened ? 'rotate-90' : ''}
        />
      </button>
      <div>
        <div className='mb-1.5 flex items-center gap-1.5'>
          <p className='font-medium text-primary-black'>
            {isFilterChanged && !isOpened && 'Applied '}Filters
          </p>
          {isFilterChanged && !isOpened && !searchToModifyTitle && (
            <Button
              kind={'text'}
              size={'medium'}
              className='py-1 text-xs'
              onClick={() => resetAllFilters()}
            >
              Clear
            </Button>
          )}
          {searchToModifyTitle && !isOpened && (
            <div className='flex h-[24px] items-center rounded-full border border-red-12 bg-red-11 text-sm not-italic text-red-9'>
              <div className='max-w-52 overflow-hidden truncate whitespace-nowrap pl-[10px] pr-[2px]'>
                {searchToModifyTitle}
              </div>
              <Close
                fill={Colors.NAV_ICON_THREE}
                width={16}
                height={16}
                className='h-fit w-fit p-1 hover:cursor-pointer'
                onClick={() => {
                  resetAllFilters()
                }}
              />
            </div>
          )}
        </div>
        {!isOpened ? (
          <div className='text-sm text-primary-grey'>
            <FilterDisplay
              updatedFilters={updatedFilters}
              dataFilters={dataFilters}
            />
          </div>
        ) : (
          <div className='mb-3.5'>
            <div className='mt-2.5 flex flex-wrap gap-4'>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                innerClassName={'justify-between'}
                wrapperMenuClassName={'min-w-[128px] overflow-auto'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.distributor[0] === 'all'
                    ? 'All'
                    : activeFilters.distributor.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.distributor[0] === 'all'
                    ? 0
                    : activeFilters.distributor.length
                }
                hideTotalNumber={true}
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    distributor: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    distributor: ['all'],
                  })
                }}
                leftIcon={''}
                rightIcon={
                  activeFilters.distributor[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'Distributor'}
              >
                <div className='flex flex-col gap-2.5'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'distributor') {
                      let allOptionsLength = 0
                      options.forEach(item => {
                        allOptionsLength += item.options.length
                      })
                      return options.map((item, key) => {
                        return (
                          <React.Fragment key={item.id}>
                            {key === 0 && (
                              <Checkbox
                                label={'All'}
                                checked={activeFilters[id][0] === 'all'}
                                onChange={() =>
                                  setActiveFilters({
                                    ...activeFilters,
                                    distributor: ['all'],
                                  })
                                }
                                kind={'small'}
                              />
                            )}
                            <FilterColumnGroup
                              category={id}
                              title={item.name}
                              titleClassName={'text-grey-7 pb-0.5'}
                              optionsFilter={item.options as OptionFilter[]}
                              selectedFilters={activeFilters as SelectedFilters}
                              onChange={newFilters =>
                                setActiveFilters({
                                  ...activeFilters,
                                  distributor: newFilters.distributor,
                                })
                              }
                              allAsReset={true}
                              hasAll={true}
                              selectOptionToReset={allOptionsLength}
                              checkboxSize={'small'}
                            />
                          </React.Fragment>
                        )
                      })
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[128px]'}
                innerClassName={'justify-between truncate'}
                nameCLassName={'w-full truncate font-normal capitalize'}
                name={formatArrayValues(
                  dataFilters,
                  activeFilters.releaseMonth,
                  'releaseMonth',
                )}
                totalSelectedFilters={
                  activeFilters.releaseMonth[0] === 'all'
                    ? 0
                    : activeFilters.releaseMonth.length
                }
                hideTotalNumber={true}
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    releaseMonth: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    releaseMonth: ['all'],
                  })
                }}
                leftIcon={''}
                rightIcon={
                  activeFilters.releaseMonth[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'Release Month'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'releaseMonth') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters => {
                            if (newFilters.releaseMonth.length > 1) {
                              const sortedReleaseMonth: string[] = []
                              releaseMonthOptions.forEach(month => {
                                if (
                                  newFilters.releaseMonth.includes(month.value)
                                ) {
                                  sortedReleaseMonth.push(month.value)
                                }
                              })
                              setActiveFilters({
                                ...activeFilters,
                                releaseMonth: sortedReleaseMonth,
                              })
                            } else {
                              setActiveFilters({
                                ...activeFilters,
                                releaseMonth: newFilters.releaseMonth,
                              })
                            }
                          }}
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[128px]'}
                innerClassName={'justify-between truncate'}
                nameCLassName={'w-full truncate font-normal capitalize'}
                name={formatArrayValues(
                  dataFilters,
                  activeFilters.releaseYears,
                  'releaseYears',
                )}
                totalSelectedFilters={
                  activeFilters.releaseYears[0] === 'all'
                    ? 0
                    : activeFilters.releaseYears.length
                }
                hideTotalNumber={true}
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    releaseYears: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    releaseYears: ['all'],
                  })
                }}
                leftIcon={''}
                rightIcon={
                  activeFilters.releaseYears[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'Release Year'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'releaseYears') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters => {
                            setActiveFilters({
                              ...activeFilters,
                              releaseYears: newFilters.releaseYears.sort(
                                (a, b) => Number(a) - Number(b),
                              ),
                            })
                          }}
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[128px]'}
                innerClassName={'justify-between'}
                nameCLassName={'w-full capitalize font-normal'}
                name={activeFilters.hybrid[0]}
                totalSelectedFilters={0}
                leftIcon={''}
                rightIcon={
                  <ChevronDown fill={Colors.BASE_ICON} width={20} height={20} />
                }
                label={'Hybrid'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'hybrid') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters => {
                            setActiveFilters({
                              ...activeFilters,
                              hybrid: newFilters.hybrid,
                            })
                            if (
                              newFilters.hybrid[0] === 'no' &&
                              (isFilterChanged || searchToModifyTitle !== '') &&
                              !isSavedFilterChanged
                            ) {
                              setUpdatedFilters({
                                ...updatedFilters,
                                hybrid: ['no'],
                              })
                            }
                          }}
                          isSelect={true}
                          isSingleSelection={true}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                innerClassName={'justify-between'}
                wrapperMenuClassName={'w-[312px]'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.openingWeekendTheaters[0] === 500 &&
                  activeFilters.openingWeekendTheaters[1] === 5000
                    ? 'All'
                    : `${(activeFilters.openingWeekendTheaters[0] && toLocalNumber(activeFilters.openingWeekendTheaters[0])) || ''}${
                        activeFilters.openingWeekendTheaters[0] &&
                        toLocalNumber(activeFilters.openingWeekendTheaters[1])
                          ? '-'
                          : ''
                      }${activeFilters.openingWeekendTheaters[1] === 5000 ? `${toLocalNumber('5000')}+` : toLocalNumber(activeFilters.openingWeekendTheaters[1]) || ''}`
                }
                totalSelectedFilters={
                  activeFilters.openingWeekendTheaters[0] === 500 &&
                  activeFilters.openingWeekendTheaters[1] === 5000
                    ? 0
                    : 1
                }
                hideTotalNumber={true}
                handleReset={resetOpeningWeekend}
                leftIcon={''}
                rightIcon={
                  activeFilters.openingWeekendTheaters[0] === 500 &&
                  activeFilters.openingWeekendTheaters[1] === 5000 ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'OW Theaters'}
              >
                <div className='flex flex-col gap-5'>
                  {dataFilters.map(({ id }) => {
                    if (id === 'openingWeekend') {
                      return (
                        <React.Fragment key={id}>
                          <div className='flex justify-between'>
                            <Input
                              containerWrapperClassName={'w-[73px]'}
                              inputClassName={'py-1 px-2 pointer-events-none'}
                              value={
                                openingWeekendRange[0] <= 500
                                  ? '<500'
                                  : toLocalNumber(openingWeekendRange[0])
                              }
                              readOnly={true}
                            />
                            <Input
                              containerWrapperClassName={'w-[73px]'}
                              inputClassName={'py-1 px-2 pointer-events-none'}
                              value={
                                openingWeekendRange[1] >= 5000
                                  ? '5,000+'
                                  : toLocalNumber(openingWeekendRange[1])
                              }
                              readOnly={true}
                            />
                          </div>
                          <CustomRangeSlider
                            min={500}
                            max={5000}
                            step={100}
                            value={openingWeekendRange}
                            onChange={setOpeningWeekendRange}
                          />
                          <Button
                            kind={'text'}
                            size={'small'}
                            className='w-[52px] py-1 text-xs'
                            onClick={resetOpeningWeekend}
                          >
                            Reset
                          </Button>
                        </React.Fragment>
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                innerClassName={'justify-between'}
                wrapperMenuClassName={'w-[312px]'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.boxOffice[0] === 0 &&
                  activeFilters.boxOffice[1] === 400
                    ? 'All'
                    : `${activeFilters.boxOffice[0]}${
                        activeFilters.boxOffice[1] ? '-' : ''
                      }${activeFilters.boxOffice[1] === 400 ? '400' : activeFilters.boxOffice[1] || ''}`
                }
                totalSelectedFilters={
                  activeFilters.boxOffice[0] === 0 &&
                  activeFilters.boxOffice[1] === 400
                    ? 0
                    : 1
                }
                hideTotalNumber={true}
                handleReset={resetBoxOffice}
                leftIcon={''}
                rightIcon={
                  activeFilters.boxOffice[0] === 0 &&
                  activeFilters.boxOffice[1] === 400 ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'Box Office'}
              >
                <div className='flex flex-col gap-5'>
                  {dataFilters.map(({ id }) => {
                    if (id === 'boxOffice') {
                      return (
                        <React.Fragment key={id}>
                          <div className='flex justify-between'>
                            <Input
                              containerWrapperClassName={'w-[73px]'}
                              inputClassName={'py-1 px-2 pointer-events-none'}
                              value={boxOfficeRange[0]}
                              readOnly={true}
                            />
                            <Input
                              containerWrapperClassName={'w-[73px]'}
                              inputClassName={'py-1 px-2 pointer-events-none'}
                              value={boxOfficeRange[1]}
                              readOnly={true}
                            />
                          </div>
                          <CustomRangeSlider
                            min={0}
                            max={400}
                            step={10}
                            value={boxOfficeRange}
                            onChange={setBoxOfficeRange}
                          />
                          <p
                            className={
                              'mb-1 text-left text-xs text-primary-grey'
                            }
                          >
                            Dollars in Millions
                          </p>
                          <Button
                            kind={'text'}
                            size={'small'}
                            className='w-[52px] py-1 text-xs'
                            onClick={resetBoxOffice}
                          >
                            Reset
                          </Button>
                        </React.Fragment>
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                innerClassName={'justify-between'}
                wrapperMenuClassName={'w-[312px]'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.totalGross[0] === 0 &&
                  activeFilters.totalGross[1] === 990
                    ? 'All'
                    : `${activeFilters.totalGross[0]}${
                        activeFilters.totalGross[1] ? '-' : ''
                      }${activeFilters.totalGross[1] || ''}`
                }
                totalSelectedFilters={
                  activeFilters.totalGross[0] === 0 &&
                  activeFilters.totalGross[1] === 990
                    ? 0
                    : 1
                }
                hideTotalNumber={true}
                handleReset={resetTotalGross}
                leftIcon={''}
                rightIcon={
                  activeFilters.totalGross[0] === 0 &&
                  activeFilters.totalGross[1] === 990 ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'Total Gross'}
              >
                <div className='flex flex-col gap-5'>
                  {dataFilters.map(({ id }) => {
                    if (id === 'totalGross') {
                      return (
                        <React.Fragment key={id}>
                          <div className='flex justify-between'>
                            <Input
                              containerWrapperClassName={'w-[73px]'}
                              inputClassName={'py-1 px-2 pointer-events-none'}
                              value={totalGrossRange[0]}
                              readOnly={true}
                            />
                            <Input
                              containerWrapperClassName={'w-[73px]'}
                              inputClassName={'py-1 px-2 pointer-events-none'}
                              value={totalGrossRange[1]}
                              readOnly={true}
                            />
                          </div>
                          <CustomRangeSlider
                            min={0}
                            max={990}
                            step={10}
                            value={totalGrossRange}
                            onChange={setTotalGrossRange}
                          />
                          <p
                            className={
                              'mb-1 text-left text-xs text-primary-grey'
                            }
                          >
                            Dollars in Millions
                          </p>
                          <Button
                            kind={'text'}
                            size={'small'}
                            className='w-[52px] py-1 text-xs'
                            onClick={resetTotalGross}
                          >
                            Reset
                          </Button>
                        </React.Fragment>
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[128px]'}
                innerClassName={'justify-between'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.budget[0] === 'all'
                    ? 'All'
                    : activeFilters.budget.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.budget[0] === 'all'
                    ? 0
                    : activeFilters.budget.length
                }
                hideTotalNumber={true}
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    budget: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    budget: ['all'],
                  })
                }}
                leftIcon={''}
                rightIcon={
                  activeFilters.budget[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'Budget'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'budget') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters =>
                            setActiveFilters({
                              ...activeFilters,
                              budget: newFilters.budget,
                            })
                          }
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[128px]'}
                innerClassName={'justify-between'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.rating[0] === 'all'
                    ? 'All'
                    : activeFilters.rating.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.rating[0] === 'all'
                    ? 0
                    : activeFilters.rating.length
                }
                hideTotalNumber={true}
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    rating: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    rating: ['all'],
                  })
                }}
                leftIcon={''}
                rightIcon={
                  activeFilters.rating[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                label={'Rating'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'rating') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters =>
                            setActiveFilters({
                              ...activeFilters,
                              rating: newFilters.rating,
                            })
                          }
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[128px]'}
                innerClassName={'justify-between'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.psrr[0] === 'all'
                    ? 'All'
                    : activeFilters.psrr.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.psrr[0] === 'all'
                    ? 0
                    : activeFilters.psrr.length
                }
                leftIcon={''}
                rightIcon={
                  activeFilters.psrr[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    psrr: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    psrr: ['all'],
                  })
                }}
                hideTotalNumber={true}
                label={'PSRR'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'psrr') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters =>
                            setActiveFilters({
                              ...activeFilters,
                              psrr: newFilters.psrr,
                            })
                          }
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[232px]'}
                innerClassName={'justify-between'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.franchise[0] === 'all'
                    ? 'All'
                    : activeFilters.franchise.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.franchise[0] === 'all'
                    ? 0
                    : activeFilters.franchise.length
                }
                leftIcon={''}
                rightIcon={
                  activeFilters.franchise[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    franchise: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    franchise: ['all'],
                  })
                }}
                hideTotalNumber={true}
                label={'Franchise'}
              >
                <div className='flex flex-col gap-3.5'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'franchise') {
                      return (
                        <React.Fragment key={id}>
                          <Input
                            value={searchFranchise}
                            onChange={e => handleSearchFranchise(e)}
                            leftIcon={
                              <Search
                                fill={Colors.BASE_ICON}
                                width={16}
                                height={16}
                              />
                            }
                            placeholder={'Search'}
                          />
                          <FilterColumnGroup
                            category={id}
                            optionsFilter={options as OptionFilter[]}
                            selectedFilters={activeFilters as SelectedFilters}
                            onChange={newFilters =>
                              setActiveFilters({
                                ...activeFilters,
                                franchise: newFilters.franchise,
                              })
                            }
                            allAsReset={true}
                            checkboxSize={'small'}
                            filterByString={searchFranchise}
                          />
                        </React.Fragment>
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                wrapperMenuClassName={'min-w-[232px]'}
                innerClassName={'justify-between'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.universe[0] === 'all'
                    ? 'All'
                    : activeFilters.universe.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.universe[0] === 'all'
                    ? 0
                    : activeFilters.universe.length
                }
                leftIcon={''}
                rightIcon={
                  activeFilters.universe[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    universe: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    universe: ['all'],
                  })
                }}
                hideTotalNumber={true}
                label={'Universe'}
              >
                <div className='flex flex-col gap-3.5'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'universe') {
                      return (
                        <React.Fragment key={id}>
                          <Input
                            value={searchUniverse}
                            onChange={e => handleSearchUniverse(e)}
                            leftIcon={
                              <Search
                                fill={Colors.BASE_ICON}
                                width={16}
                                height={16}
                              />
                            }
                            placeholder={'Search'}
                          />
                          <FilterColumnGroup
                            key={id}
                            category={id}
                            optionsFilter={options as OptionFilter[]}
                            selectedFilters={activeFilters as SelectedFilters}
                            onChange={newFilters =>
                              setActiveFilters({
                                ...activeFilters,
                                universe: newFilters.universe,
                              })
                            }
                            allAsReset={true}
                            checkboxSize={'small'}
                            filterByString={searchUniverse}
                          />
                        </React.Fragment>
                      )
                    }
                  })}
                </div>
              </FilterButton>
            </div>
            <div className='mt-4 flex flex-wrap gap-4'>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperMenuClassName={'min-w-[156px]'}
                wrapperClasName={'w-[149px]'}
                innerClassName={'justify-between'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.primaryGenre[0] === 'all'
                    ? 'All'
                    : activeFilters.primaryGenre.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.primaryGenre[0] === 'all'
                    ? 0
                    : activeFilters.primaryGenre.length
                }
                leftIcon={''}
                rightIcon={
                  activeFilters.primaryGenre[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    primaryGenre: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    primaryGenre: ['all'],
                  })
                }}
                hideTotalNumber={true}
                label={'Primary Genre'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'primaryGenre') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters =>
                            setActiveFilters({
                              ...activeFilters,
                              primaryGenre: newFilters.primaryGenre,
                            })
                          }
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                innerClassName={'justify-between'}
                wrapperMenuClassName={'min-w-[156px]'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.secondaryGenre[0] === 'all'
                    ? 'All'
                    : activeFilters.secondaryGenre.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.secondaryGenre[0] === 'all'
                    ? 0
                    : activeFilters.secondaryGenre.length
                }
                leftIcon={''}
                rightIcon={
                  activeFilters.secondaryGenre[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    secondaryGenre: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    secondaryGenre: ['all'],
                  })
                }}
                hideTotalNumber={true}
                label={'Secondary Genre'}
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'secondaryGenre') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters =>
                            setActiveFilters({
                              ...activeFilters,
                              secondaryGenre: newFilters.secondaryGenre,
                            })
                          }
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <FilterButton
                containerClassName={'basis-[149px]'}
                wrapperClasName={'w-[149px]'}
                innerClassName={'justify-between'}
                wrapperMenuClassName={'min-w-[153px]'}
                nameCLassName={'w-full font-normal'}
                name={
                  activeFilters.groups[0] === 'all'
                    ? 'All'
                    : activeFilters.groups.length + ' selected'
                }
                totalSelectedFilters={
                  activeFilters.groups[0] === 'all'
                    ? 0
                    : activeFilters.groups.length
                }
                leftIcon={''}
                rightIcon={
                  activeFilters.groups[0] === 'all' ? (
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                    />
                  ) : (
                    ''
                  )
                }
                handleReset={() => {
                  setActiveFilters({
                    ...activeFilters,
                    groups: ['all'],
                  })
                  setUpdatedFilters({
                    ...updatedFilters,
                    groups: ['all'],
                  })
                }}
                hideTotalNumber={true}
                label='Group'
              >
                <div className='flex flex-row gap-10'>
                  {dataFilters.map(({ id, options }) => {
                    if (id === 'groups') {
                      return (
                        <FilterColumnGroup
                          key={id}
                          category={id}
                          optionsFilter={options as OptionFilter[]}
                          selectedFilters={activeFilters as SelectedFilters}
                          onChange={newFilters =>
                            setActiveFilters({
                              ...activeFilters,
                              groups: newFilters.groups,
                            })
                          }
                          allAsReset={true}
                          checkboxSize={'small'}
                        />
                      )
                    }
                  })}
                </div>
              </FilterButton>
              <div className='ml-6 pt-[30px]'>
                <Checkbox
                  checked={activeFilters.onlyPlatformMovies}
                  label={'Show Platform Movies'}
                  onChange={() => {
                    setActiveFilters({
                      ...activeFilters,
                      onlyPlatformMovies: !activeFilters.onlyPlatformMovies,
                    })
                    if (
                      !updatedFilters.onlyPlatformMovies &&
                      (isFilterChanged || searchToModifyTitle !== '') &&
                      !isSavedFilterChanged
                    ) {
                      setUpdatedFilters({
                        ...updatedFilters,
                        onlyPlatformMovies: !activeFilters.onlyPlatformMovies,
                      })
                    }
                  }}
                  kind={'small'}
                />
              </div>
            </div>
            <div className='mt-4 flex flex-wrap gap-2'>
              <Button
                kind={'filled'}
                size={'medium'}
                className={twMerge(
                  'w-[53px] bg-primary-red py-1 text-xs',
                  (!isFilterChanged || searchToModifyTitle !== '') &&
                    'disabled:bg-primary-red',
                )}
                onClick={() => {
                  setUpdatedFilters(activeFilters)
                  setIsOpened(false)
                  localStorage.removeItem('dataToUpdateSearchList')
                }}
                disabled={
                  (!isFilterChanged || searchToModifyTitle !== '') &&
                  !isSavedFilterChanged
                }
              >
                Apply
              </Button>
              {searchToModifyTitle && (
                <div className='flex h-[24px] items-center rounded-full border border-red-12 bg-red-11 text-sm not-italic text-red-9'>
                  <div className='max-w-52 overflow-hidden truncate whitespace-nowrap pl-[10px] pr-[2px]'>
                    {searchToModifyTitle}
                  </div>
                  <Close
                    fill={Colors.NAV_ICON_THREE}
                    width={16}
                    height={16}
                    className='h-fit w-fit p-1 hover:cursor-pointer'
                    onClick={() => {
                      resetAllFilters()
                    }}
                  />
                </div>
              )}
              {isFilterChanged && !searchToModifyTitle && (
                <>
                  <Button
                    kind={'filled'}
                    size={'medium'}
                    className='py-1 text-xs'
                    onClick={() => {
                      setSearchModalOpened(true)
                    }}
                  >
                    Save Search
                  </Button>
                  <Button
                    kind={'text'}
                    size={'medium'}
                    className='py-1 text-xs'
                    onClick={() => resetAllFilters()}
                  >
                    Clear All
                  </Button>
                </>
              )}
              {isSavedFilterChanged && searchToModifyTitle && (
                <>
                  <Button
                    kind='filled'
                    size='small'
                    onClick={() => {
                      setIsOpenSaveAsSearch(true)
                    }}
                  >
                    <span className='relative flex px-1'>
                      Save
                      <SaveAsFiltersDropdown
                        horizontalPostion={'right'}
                        isOpen={isOpenSaveAsSearch}
                        setIsOpen={setIsOpenSaveAsSearch}
                        saveAsNewSearch={setSearchModalOpened}
                        updateCurrentSearch={() =>
                          handleEditSearchPreset(
                            activeFilters,
                            searchToModify,
                            searchToModifyTitle,
                            setUpdatedFilters,
                          )
                        }
                      />
                    </span>
                  </Button>

                  <Button
                    kind={'text'}
                    size={'medium'}
                    className='py-1 text-xs'
                    onClick={() => resetSavedSearch()}
                  >
                    Reset
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
