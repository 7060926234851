import { OptionType } from '../types'

export const newProjectRoleOptions: OptionType[] = [
  {
    id: '1',
    value: 'lead',
    label: 'Lead',
  },
  {
    id: '2',
    value: 'supporting',
    label: 'Supporting',
  },
  {
    id: '3',
    value: 'cameo',
    label: 'Cameo',
  },
  {
    id: '4',
    value: 'withcredit',
    label: 'With Credit',
  },
  {
    id: '5',
    value: 'andcredit',
    label: 'And Credit',
  },
]
