export const buildParams = (data: Record<string, any>) => {
  const params = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(val => params.append(key, val?.toString()))
    } else {
      params.append(key, value.toString())
    }
  })

  return params.toString()
}

export const prepareUrlParams = (
  params: Record<string, any> = {},
  duplicateKey = false,
): string => {
  if (!params || Object.keys(params).length === 0) {
    return ''
  }

  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => {
      const value = params[key]
      if (Array.isArray(value)) {
        return duplicateKey
          ? value
              .map(it => `${encodeURIComponent(key)}=${encodeURIComponent(it)}`)
              .join('&')
          : `${encodeURIComponent(key)}=${encodeURIComponent(value.join(','))}`
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')

  return queryString ? `?${queryString}` : ''
}

export const addQueryParam = (key, value) => {
  let queryString = window.location.search

  if (queryString.charAt(0) === '?') {
    queryString = queryString.slice(1)
  }

  const params = {}
  queryString.split('&').forEach(function (param) {
    const split = param.split('=')
    params[split[0]] = split[1]
  })

  params[key] = value
  let urlParams = ''
  Object.keys(params)
    .filter(key => params[key])
    .map((key, i) => {
      urlParams += (i === 0 ? '?' : '&') + key + '=' + params[key]
    })

  // Update the URL with the modified query string
  return urlParams
}
