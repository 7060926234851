import { ChangeEvent, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface ToggleSwitchProps {
  id: string
  checked: boolean
  onChange?: (checked: boolean) => void
  disabled?: boolean
  color?: string
  containerClassName?: string
  className?: string
  labelClassName?: string
  size?: 'small' | 'medium' | 'large'
}

export const ToggleSwitch = ({
  checked,
  onChange,
  disabled = false,
  color,
  id,
  containerClassName,
  className,
  labelClassName,
  size = 'small',
}: ToggleSwitchProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current!.checked = checked
    }
  }, [checked])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // e.stopPropagation()
    if (!disabled && onChange) {
      onChange(e.target.checked)
    }
  }

  return (
    <div
      className={twMerge(
        'relative block  cursor-pointer transition',
        containerClassName,
        `${size === 'small' ? 'w-[22px]' : size === 'medium' ? 'w-[28px]' : 'w-[34px]'}`,
      )}
    >
      <input
        ref={inputRef}
        id={id}
        onChange={handleChange}
        type='checkbox'
        checked={checked}
        disabled={disabled}
        className={twMerge(
          'absolute top-1/2 block -translate-y-1/2 transform appearance-none rounded-full border bg-white transition-all duration-500',
          checked
            ? 'right-[2px]'
            : `${size === 'small' ? 'right-[12px]' : size === 'medium' ? 'right-[14px]' : 'right-[16px]'}`,
          disabled
            ? 'cursor-not-allowed'
            : 'cursor-pointer hover:cursor-pointer',
          className,
          `${size === 'small' ? 'h-2 w-2' : size === 'medium' ? 'h-3 w-3' : 'h-4 w-4'}`,
        )}
      />
      <label
        htmlFor={id}
        className={twMerge(
          'block  cursor-pointer overflow-hidden rounded-full bg-grey-7',
          checked ? color : 'bg-primary-black/20',
          `${size === 'small' ? 'h-3' : size === 'medium' ? 'h-4' : 'h-5'}`,
          labelClassName,
        )}
      />
    </div>
  )
}
