import { ArrowLeft } from '../../components/ui/icons'
import { Button } from '../../components'
import React, { useState } from 'react'
import { useDropzone, Accept, FileWithPath } from 'react-dropzone'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { testingManagement } from '../../types'
import { toastError } from '../../utils'
import { twMerge } from 'tailwind-merge'
import { DeleteModal } from '../../components'

interface UserEditPageProps {
  testingData: testingManagement
  setTestingItemOpen: (user: testingManagement) => void
  testingDataPlaceholder: testingManagement
  testingType: string
}

interface warningData {
  title: string
  description: string
  deleteTitle: string
  onConfirm?: () => void
  type: string
}

const initialData = {
  title: '',
  description: '',
  deleteTitle: '',
  type: '',
}

export const TestingEditPage = ({
  testingData,
  setTestingItemOpen,
  testingDataPlaceholder,
  testingType,
}: UserEditPageProps) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<FileWithPath | null>(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false)
  const [warningModalData, setWarningModalData] =
    useState<warningData>(initialData)
  const accept: Accept = {
    'application/pdf': [],
  }
  const onDrop = (acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0]
    setSelectedFile(file)
    uploadFile(file)
  }

  /*TODO Rebuild for the API*/

  // const uploadFile = (file: FileWithPath) => {
  //   const xhr = new XMLHttpRequest()
  //   xhr.open('POST', '/upload', true) // Replace with appropriate endpoint
  //
  //   xhr.upload.onprogress = event => {
  //     if (event.lengthComputable) {
  //       const percentComplete = (event.loaded / event.total) * 100
  //       setUploadProgress(percentComplete)
  //     }
  //   }
  //
  //   xhr.onerror = () => {
  //     console.log('error file upload') //eslint-disable-line
  //   }
  //
  //   const formData = new FormData()
  //   formData.append('file', file)
  //   xhr.send(formData)
  // }

  const uploadFile = (file: FileWithPath) => {
    let progress = 0
    const interval = setInterval(() => {
      progress += 10
      setUploadProgress(progress)
      if (progress >= 100) {
        clearInterval(interval)
      }
    }, 500)
  }

  const onDropRejected = (fileRejections: any) => {
    setErrorMessage(
      'File upload rejected. Please upload a valid PDF file under 25 MB.',
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    maxSize: 25 * 1024 * 1024,
  })

  const handleOpenWarning = (field: string, userId?: string) => {
    if (field === 'deny') {
      setWarningModalData({
        title: 'Are you sure you want to deny this request?',
        description:
          'This will permanently prevent the request from being sent to complete the survey.',
        deleteTitle: 'Deny',
        type: 'deny',
      })
    } else if (field === 'cancel') {
      setWarningModalData({
        title: 'Are you sure you want to cancel this test?',
        description:
          'This will send Prodege an automatic email to cancel the audience survey, and revert this test back to the ‘Needs Approval’ Status',
        deleteTitle: 'Cancel Test',
        type: 'cancel',
      })
    }
    setOpenWarningPopup(true)
  }

  const handleConfirm = async () => {
    try {
      if (warningModalData.type === 'deny') {
        setSuccessMessage(`Request denied`)
      } else if (warningModalData.type === 'cancel') {
        setSuccessMessage(`Test cancelled`)
      }
    } catch (e) {
      const { error, data } = e as { data?: any; error?: string }
      toastError(data, error)
    }
  }

  return (
    <div className={'h-full overflow-auto pb-4'}>
      <div
        className={
          'mb-12 flex items-center gap-4 border-b border-b-primary-black/10 px-4 py-3'
        }
      >
        <ArrowLeft
          fill={'currentColor'}
          width={24}
          height={24}
          onClick={() => {
            setTestingItemOpen(testingDataPlaceholder)
          }}
          className='cursor-pointer text-grey-7 hover:text-primary-red'
        />
        <div>
          <p className={'text-xs font-semibold capitalize opacity-50'}>
            {testingType} Test
          </p>
          <h1 className='text-2xl font-semibold text-primary-black'>
            {testingData.testName}
          </h1>
        </div>
      </div>
      <div className={'mx-14 w-[700px] max-w-[90%]'}>
        <div className={'mb-8 flex'}>
          {testingData.status === 'needsapproval' && (
            <>
              <Button
                className='mr-4 bg-green-6'
                onClick={() => {
                  toastError('Approval functionality is not implemented yet')
                }}
                kind={'filled'}
                size={'medium'}
              >
                Mark Submitted
              </Button>
              <Button
                size={'medium'}
                kind={'text'}
                className={'text-red-10'}
                onClick={() => handleOpenWarning('deny')}
              >
                Deny Test
              </Button>
            </>
          )}
          {testingData.status === 'submitedtoprodoge' && (
            <>
              <Button
                className='disabled mr-4 bg-green-6'
                onClick={() => {
                  toastError('Approval functionality is not implemented yet')
                }}
                kind={'filled'}
                size={'medium'}
                disabled={selectedFile === null}
              >
                Mark Complete
              </Button>
              <Button
                size={'medium'}
                kind={'text'}
                className={'text-red-10'}
                onClick={() => handleOpenWarning('cancel')}
              >
                Cancel Test Submission
              </Button>
            </>
          )}
        </div>
        <div className={'mb-8'}>
          <p className={'text-sm font-semibold uppercase text-grey-7'}>
            Test Name
          </p>
          <p>{testingData.testName}</p>
        </div>
        <div className={'mb-8'}>
          <p className={'text-sm font-semibold uppercase text-grey-7'}>
            Test Status
          </p>
          <p className={'flex items-baseline gap-1 text-xs'}>
            {testingData.status === 'needsapproval' && (
              <>
                <span className='h-2 w-2 rounded-full bg-primary-red'></span>
                Needs approval
              </>
            )}
            {testingData.status === 'Inactive' && (
              <>
                <span className='h-2 w-2 rounded-full bg-red-10'></span>
                Inactive
              </>
            )}
            {testingData.status === 'submitedtoprodoge' && (
              <>
                <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
                Submitted to Prodege
              </>
            )}
            {testingData.status === 'denied' && (
              <>
                <span className='h-2 w-2 rounded-full bg-red-10'></span>
                Denied
              </>
            )}
            {testingData.status === 'complete' && (
              <>
                <span className='h-2 w-2 rounded-full bg-[#00B447]'></span>
                Complete
              </>
            )}
          </p>
        </div>
        <div className={'mb-8'}>
          <p className={'text-sm font-semibold uppercase text-grey-7'}>
            Client
          </p>
          <p>{testingData.company}</p>
        </div>
        <div className={'mb-8'}>
          <p className={'text-sm font-semibold uppercase text-grey-7'}>
            Project
          </p>
          <p>{testingData.project}</p>
        </div>
        <div className={'mb-8'}>
          <p className={'text-sm font-semibold uppercase text-grey-7'}>
            Custom Audience
          </p>
          <p>
            {
              'People who love Christmas, People who watch the Olympics, Soccer Enthusiasts'
            }
          </p>
        </div>
        <div className={'mb-8'}>
          <p className={'text-sm font-semibold uppercase text-grey-7'}>
            Logline
          </p>
          <p>
            {
              "This family comedy follows Owen Long as he gets more than he bargained for when takes a job selling Christmas trees. He knew there would be long hours and that his hands and feet would get cold. What he didn't anticipate, however, were the rivalries between tree vendors, the shadowy figures and the backstabbing that have become synonymous with the Christmas tree business. "
            }
          </p>
        </div>
        {(testingData.status === 'submitedtoprodoge' ||
          testingData.status === 'complete') && (
          <>
            <p className={'mb-4 text-sm font-semibold uppercase text-grey-7'}>
              Upload Test results PDF
            </p>
            {selectedFile ? (
              <>
                <div className='inline-flex items-center overflow-hidden rounded border'>
                  <div
                    className={
                      'flex min-w-52 items-center justify-between gap-2 px-4 py-2'
                    }
                  >
                    <p>
                      {selectedFile.name} (
                      {(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                    </p>
                    <div
                      className={twMerge(
                        'h-4 w-4',
                        uploadProgress === 100 && 'opacity-0',
                      )}
                    >
                      <CircularProgressbar
                        value={uploadProgress}
                        strokeWidth={8}
                        styles={buildStyles({
                          strokeLinecap: 'butt',
                          trailColor: 'rgba(3, 7, 18, 0.1)',
                          pathColor: 'rgba(3, 7, 18, 0.5)',
                          pathTransition: '0.5s all linear',
                        })}
                      />
                    </div>
                  </div>
                  <button
                    className='min-h-10 border-l border-primary-black/10 px-3 py-2 font-semibold transition-colors hover:text-primary-red'
                    onClick={() => setSelectedFile(null)}
                  >
                    Remove
                  </button>
                </div>
                <Button kind={'filled'} size={'small'} className={'ml-4'}>
                  View PDF
                </Button>
              </>
            ) : (
              <>
                <div
                  {...getRootProps()}
                  className='inline-flex items-center overflow-hidden rounded border'
                >
                  <div
                    className={
                      'flex min-w-52 items-center justify-between gap-2 px-4 py-2'
                    }
                  >
                    <input {...getInputProps()} />
                    <span>No file chosen</span>
                  </div>
                  <button className='min-h-10 border-l border-primary-black/10 px-3 py-2 font-semibold transition-colors hover:text-green-6'>
                    Choose file
                  </button>
                </div>
                {errorMessage && <p className='text-red-500'>{errorMessage}</p>}
              </>
            )}
            <p className='text-xs text-primary-grey'>{`PDF, {Maximum 25 MB}`}</p>
          </>
        )}
        <DeleteModal
          isOpen={openWarningPopup}
          handleClose={() => setOpenWarningPopup(false)}
          title={warningModalData.title}
          description={warningModalData.description}
          onDelete={() => {
            handleConfirm()
          }}
          deleteTitle={warningModalData.deleteTitle}
          zIndex={900}
          descriptionClassName={'text-center'}
          titleClassName={'text-center mb-1'}
          hasTrash={false}
        />
      </div>
    </div>
  )
}
