import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { OptionType, RoleProject } from 'types'
import useTalentSimple from './useTalentSimple'

export const useHandleInputRoleChange = (
  updateRoleFn: (role: Partial<RoleProject>) => void,
) => {
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const [debouncedValue, setDebouncedValue] = useState('')
  const [talentName, setTalentName] = useState('')
  const { talentsList, getTalentsList } = useTalentSimple()

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedValue(talentName)
    }, 750)

    return () => clearTimeout(delayInputTimeoutId)
  }, [talentName])

  useEffect(() => {
    debouncedValue && getTalentsList(debouncedValue)
  }, [debouncedValue])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: keyof RoleProject) => {
      const { value } = e.target
      updateRoleFn({ [key]: value })

      if (key === 'attachedTalent') {
        setShowAutocomplete(true)
        if (value.trim() === '') {
          setShowAutocomplete(false)
        } else {
          setTalentName(value)
        }
      }
    },
    [updateRoleFn],
  )

  return {
    handleInputChange,
    showAutocomplete,
    filteredData: talentsList,
    setShowAutocomplete,
  }
}
