import { twMerge } from 'tailwind-merge'
import { OptionType } from 'types'

interface SearchListMenuProps<TOptionValue = string> {
  list: OptionType<TOptionValue>[]
  wrapperClass?: string
  itemClass?: string
  handleChange: (option: OptionType<TOptionValue>) => void
}

export const SearchListMenu = <TOptionValue,>({
  list,
  wrapperClass,
  itemClass,
  handleChange,
}: SearchListMenuProps<TOptionValue>) => {
  return (
    <div className='py-3'>
      <ul
        className={twMerge(
          'flex w-64 flex-col space-y-1 border-b border-t border-opacityGrey-10 px-2 py-3',
          wrapperClass,
          list.length > 5 && 'h-72 overflow-y-auto',
        )}
      >
        {list.length > 0 ? (
          list.map(item => (
            <li
              key={item.id}
              className={twMerge(
                'rounded p-0.5 pl-2 text-start text-sm text-primary-black hover:cursor-pointer hover:bg-opacityGrey-3',
                itemClass,
              )}
              onClick={() => handleChange(item)}
            >
              {item.label}
            </li>
          ))
        ) : (
          <li className='text-sm text-primary-black'>No results found</li>
        )}
      </ul>
    </div>
  )
}
