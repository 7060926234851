import { memo } from 'react'

export const MgmtTableLoading = memo(() => {
  return (
    <div className='absolute inset-0 z-10 flex h-full items-center justify-center bg-black/40'>
      <div
        className='h-16 w-16 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue-500'
        style={{
          borderWidth: '3px',
          borderColor: '#f3f3f3',
          borderTopColor: 'var(--Icon-Colored-icon-brand, #FF4449)',
        }}
      ></div>
    </div>
  )
})

MgmtTableLoading.displayName = 'MgmtTableLoading'
