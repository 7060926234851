import { twMerge } from 'tailwind-merge'
import { Button } from 'components'
import { Folder } from 'components/ui/icons'
import { Colors } from 'constnants'

interface CardActivityProps {
  name: string
  lastActivity?: string
  wrapperClassName?: string
  newResults?: boolean
  handleNewResults?: () => void
}

export const CardActivity = ({
  name,
  lastActivity,
  wrapperClassName,
  newResults,
  handleNewResults,
}: CardActivityProps) => {
  return (
    <div
      className={twMerge(
        'flex min-w-[330px] max-w-[330px] flex-col justify-between overflow-hidden rounded bg-primary-white',
        wrapperClassName,
      )}
    >
      <div className='flex p-3'>
        <Folder
          fill={Colors.ICON_BRAND}
          width={22}
          height={22}
          className='pt-1'
        />
        <span className='pl-1 text-lg font-medium text-primary-black'>
          {name}
        </span>
      </div>

      {lastActivity && (
        <div className='mt-auto flex h-[42px] items-center justify-between border-t border-t-primary-black/10 bg-grey-3 p-3'>
          <span className='block text-xs font-medium text-grey-7'>{`Last activity: ${lastActivity}`}</span>

          {newResults && (
            <Button
              kind='filled'
              size='small'
              className='bg-primary-red hover:bg-red-9 active:bg-red-10'
              onClick={handleNewResults}
            >
              <span className='px-1 uppercase'>New Results</span>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
