import { twMerge } from 'tailwind-merge'
import { ReactNode } from 'react'
import { IconTabsKind } from './IconTabs'

export type IconTab<T> = {
  value?: T
  label?: string
  icon?: ReactNode
}

export type IconTabProps<T> = {
  value: string
  label?: string
  icon: ReactNode | string
  onChange: () => void
  selectedTab?: IconTab<T>
  kind: IconTabsKind
  wrapperClasses?: string
}

export const IconTab = <T extends string>({
  value,
  label,
  icon,
  onChange,
  kind,
  selectedTab,
  wrapperClasses,
}: IconTabProps<T>) => {
  return (
    <div
      onClick={onChange}
      className={twMerge(
        'bg-primary-white hover:cursor-pointer hover:bg-primary-black/10',
        kind === 'outline'
          ? 'border-r border-r-primary-black/10 last:border-r-0'
          : 'rounded',
        selectedTab?.value === value && 'bg-primary-black/5',
        wrapperClasses,
      )}
    >
      {label && <span>{label}</span>}
      {icon && <div className='p-1'>{icon}</div>}
    </div>
  )
}
