import { useCallback, useState } from 'react'

import {
  BaseTable,
  IconTab,
  TalentHeader,
  LayoutContentWrapper,
  PaginationToolbar,
  PaginationChangeParams,
  TalentRightSidebar,
} from 'components'
import { dataTalent, SizeTalentRows } from 'constnants'
import { columnsTalent, rowsPerPageOptions } from 'constnants/talentTableData'
import useTalent, { QueryParams } from 'hooks/useTalent'
import {
  AttributeType,
  SocialSearchFilters,
  TalentSaveSearchResponce,
  TalentSelectedSearch,
} from 'types'
import {
  useProjectRightSidebar,
  useTalentHeaderData,
  useUpdateUrlParams,
} from '../../hooks'
import { dataAudience } from 'constnants/audienceOptions'
import PageLoader from '../../components/PageLoader'
import { getSocialSearchColumnDef } from 'components/BaseTable/socialSearchFilters'
import { TalentGuideTrigger } from './TalentGuideTrigger'
import { getTalentSelectedSearch } from 'utils'

interface TalentPageProps {
  listName?: string
  listId?: string
}

export const TalentPage = ({ listName, listId }: TalentPageProps) => {
  const handleUpdateUrlParams = useUpdateUrlParams()
  const [selectedSavedSearch, setSelectedSavedSearch] =
    useState<TalentSelectedSearch>()

  const [refreshTalentMetrics, setRefreshTalentMetrics] = useState([])

  const {
    talentList,
    totalPages,
    totalResults,
    currentPage,
    pageSize,
    metricsType,
    loading,
    error,
    audienceFilter,
    sortBy,
    sortOrder,
    topTwoBox,
    setTopTwoBox,
    setMetricsType,
    handleChangePagination,
    handleChangeFilter,
    handleSortChange,
  } = useTalent({ listId, refreshTalentMetrics })
  const [selectedTableTab, setSelectedTableTab] = useState<IconTab<string>>({
    value: SizeTalentRows.SMALL_ROWS,
  })
  const [contentTypeView, setContentTypeView] =
    useState<AttributeType>('percentage')
  const [selectedFilters, setSelectedFilters] = useState<QueryParams>({})
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [socialSearchFilters, setSocialSearchFilters] =
    useState<SocialSearchFilters>({
      social: ['allFollowers'],
      search: ['wikipediaPageViews'],
    })

  const { isOpenSidebar, handleOpenRightSidebar, handleCloseRightSidebar } =
    useProjectRightSidebar({ isOpened: !!listName })

  const { handleResetAllFilters } = useTalentHeaderData(
    dataTalent.concat(dataAudience),
  )
  const clearTalentFilters = () => {
    handleChangeFilter({
      talentGender: undefined,
      talentRace: undefined,
      talentAge: undefined,
      audienceFilter: undefined,
      talentName: undefined,
      presetSearch: undefined,
    })
    setContentTypeView('percentage')
    handleResetAllFilters()
  }

  const handleSelectFilters = (filters: QueryParams) =>
    setSelectedFilters(filters)

  const [isSavedSearchChanged, setIsSavedSearchChanged] = useState(false)

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      handleChangePagination({
        pageSize: params.pageSize.toString(),
        currentPage: params.page.toString(),
        sortBy,
        metricsType,
        sortOrder,
        topTwoBox: topTwoBox,
        ...selectedFilters,
      })
    },
    [handleChangePagination],
  )

  const handleChageSocialSearchFilters = (
    filterId: keyof SocialSearchFilters,
    value: SocialSearchFilters,
  ) => {
    setSocialSearchFilters(value)
    const isSortBySocial = sortBy.startsWith('SOCIAL')
    const isSortBySearch = sortBy.startsWith('SOCIAL_WIKIPEDIA')
    const isSocialColumn = filterId === 'social'
    const isSearchColumn = filterId === 'search'
    const isSortBySocialSearch =
      (isSearchColumn && isSortBySearch) ||
      (isSocialColumn && isSortBySocial && !isSortBySearch)

    if (isSortBySocialSearch) {
      handleSortChange({
        sortBy: getSocialSearchColumnDef(value[filterId][0]).key,
        sortOrder,
        metricsType,
        topTwoBox,
      })
    }
  }

  const handleSelectNewSavedSearch = (
    newSearch: TalentSaveSearchResponce | undefined,
  ) => {
    if (newSearch) {
      const selectedSearch = getTalentSelectedSearch({
        searchId: newSearch?.search_id,
        searchName: newSearch?.name,
        searchParameters: newSearch.search_parameters,
        selectedProject: selectedSavedSearch?.selectedProject,
        selectedRole: selectedSavedSearch?.selectedRole,
      })

      handleUpdateUrlParams(selectedSearch)
      setSelectedSavedSearch(selectedSearch)
    }
  }

  const handleSelectSavedSearch = (savedSearch: Record<string, any>) => {
    handleUpdateUrlParams(savedSearch)
    setSelectedSavedSearch(savedSearch)
  }

  const handleResetSavedSearch = () => {
    if (selectedSavedSearch) {
      setIsSavedSearchChanged(false)
      handleUpdateUrlParams(selectedSavedSearch)
      setSelectedSavedSearch(undefined)
    }
  }

  const isMySelectedSavedSearch =
    !!selectedSavedSearch &&
    !selectedSavedSearch?.selectedProject &&
    !selectedSavedSearch?.selectedRole

  const rightSidebar = (
    <TalentRightSidebar
      clearTalentFilters={clearTalentFilters}
      isOpen={isOpenSidebar}
      handleOpen={handleOpenRightSidebar}
      handleClose={handleCloseRightSidebar}
      selectedSavedSearch={selectedSavedSearch}
      isSavedSearchChanged={isSavedSearchChanged}
      setIsSavedSearchChanged={setIsSavedSearchChanged}
      onSelectSavedSearch={handleSelectSavedSearch}
      onSelectNewSavedSearch={handleSelectNewSavedSearch}
      onResetSavedSearch={handleResetSavedSearch}
    />
  )

  return (
    <LayoutContentWrapper rightSidebar={rightSidebar}>
      <div className='flex h-full w-full flex-col'>
        {loading && <PageLoader />}
        <TalentHeader
          listName={listName ?? ''}
          setSelectTab={setSelectedTableTab}
          selectedTableTab={selectedTableTab}
          onChangeTalentFilter={handleChangeFilter}
          onChangeContentTypeView={(typeView: string) => {
            setContentTypeView(typeView as AttributeType)
          }}
          setMetricsType={setMetricsType}
          setTopTwoBox={setTopTwoBox}
          topTwoBox={topTwoBox}
          sortBy={sortBy}
          sortOrder={sortOrder}
          metricsType={metricsType}
          handleSelectFilters={handleSelectFilters}
          setIsSavedSearchChanged={setIsSavedSearchChanged}
          isSavedSearchChanged={isSavedSearchChanged}
          onResetSavedSearch={handleResetSavedSearch}
          socialSearchFilters={socialSearchFilters}
          isMySelectedSavedSearch={isMySelectedSavedSearch}
          setSocialSearchFilters={handleChageSocialSearchFilters}
          onSelectNewSavedSearch={handleSelectNewSavedSearch}
        />
        <BaseTable
          listName={listName}
          listId={listId}
          setRefreshTalentMetrics={setRefreshTalentMetrics}
          columns={columnsTalent}
          data={talentList}
          contentTypeView={contentTypeView}
          metricsType={metricsType}
          tableRow={selectedTableTab}
          audienceFilter={audienceFilter}
          socialSearchFilters={socialSearchFilters}
          totalRows={totalResults}
          handleSortChange={handleSortChange}
          sortBy={sortBy}
          sortOrder={sortOrder}
          topTwoBox={topTwoBox}
          error={error}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          customFooter={
            <PaginationToolbar
              totalRows={totalResults}
              totalPages={totalPages}
              currentPage={currentPage}
              currentPageSize={pageSize}
              pageSizeOptions={rowsPerPageOptions}
              onChange={handlePagination}
              startContent={<TalentGuideTrigger />}
            />
          }
        />
      </div>
    </LayoutContentWrapper>
  )
}
