import React, { useState, useEffect, useRef } from 'react'
import { BaseModal, Button, InformationBox, Input } from 'components'
import { Tooltip } from 'react-tooltip'
import { stylesTooltip } from './stylesTooltip'
interface SaveSearchResultsTalentModalProps {
  isOpen: boolean
  handleClose: () => void
  handleSaveSearchFilters: (inputValue: string) => void
  title?: string
  footer: React.ReactNode
}
const tooltipPresetSavedSearchContent = {
  name: 'Please enter your Search name',
}
export const SaveSearchResultsTalentModal = ({
  isOpen,
  handleClose,
  handleSaveSearchFilters,
  title = '',
  footer,
}: SaveSearchResultsTalentModalProps) => {
  const initErrorsObj = { name: false }
  const [inputValue, setInputValue] = useState('')
  const [errors, setErrors] = useState(initErrorsObj)
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const v = validate()
    setErrors(prev => ({ ...prev, ...v.errs }))
  }, [inputValue])

  const validate = () => {
    let hasErr = false
    const errs = { ...initErrorsObj }

    if (inputValue === '') {
      errs.name = true
      hasErr = true
    }

    return { hasErr: hasErr, errs }
  }

  const handleCloseModal = () => {
    setInputValue('')
    handleClose()
    isFirstRender.current = true
  }

  const handleSaveClick = () => {
    const v = validate()

    if (!v.hasErr) {
      handleSaveSearchFilters(inputValue)
      handleCloseModal()
    }
  }
  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleCloseModal}
      titleClass='pb-4'
      wrapperClass='p-0'
    >
      <div className='px-8 pb-4 pt-8'>
        <p className='pb-4 text-lg font-medium text-primary-black'>{title}</p>
        <div
          data-tooltip-id='presetSavedSearchName'
          data-tooltip-content={tooltipPresetSavedSearchContent.name}
        >
          <div
            data-tooltip-id='presetSavedSearchName'
            data-tooltip-content={tooltipPresetSavedSearchContent.name}
          >
            <Input
              containerWrapperClassName='pb-4'
              inputClassName='h-[40px] px-3'
              placeholder='Enter Search name'
              value={inputValue}
              isError={errors.name}
              onChange={handleInputChange}
            />
          </div>
          <Tooltip
            id={'presetSavedSearchName'}
            place='left'
            style={stylesTooltip}
            render={({ content }) => <span>{content}</span>}
            opacity={1}
            isOpen={errors.name}
          />
        </div>
        <div className='flex justify-end gap-2'>
          <Button
            kind='text'
            size='medium'
            onClick={handleCloseModal}
            className='py-2.5'
          >
            <span className='px-2 text-xs font-semibold'>Cancel</span>
          </Button>

          <Button
            onClick={handleSaveClick}
            kind='filled'
            size='medium'
            className='bg-primary-red py-2.5'
            disabled={inputValue === ''}
          >
            <span className='px-2 text-xs font-semibold'>Save</span>
          </Button>
        </div>
      </div>

      <InformationBox wrapperClassName='bg-primary-black/5'>
        {footer}
      </InformationBox>
    </BaseModal>
  )
}
