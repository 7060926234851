import { twMerge } from 'tailwind-merge'
import { UsersTableCellProps } from '../UsersTable.types'

export const UsersTableCell = ({
  column,
  row,
  isLoading = false,
}: UsersTableCellProps) => {
  const userTypeDisplayMap: { [key: string]: string } = {
    admin: 'Admin',
    superadmin: 'Super Admin',
    moduleadmin: 'Module Admin',
    editor: 'Editor',
    viewer: 'Viewer',
  }
  switch (column.value) {
    case 'first_name':
      return (
        <span
          className={twMerge(
            'flex text-xs font-medium text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.firstName} {row.lastName}
        </span>
      )
    case 'email':
      return (
        <span
          className={twMerge(
            'flex',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          <span className='text-sm'>{row.email}</span>
        </span>
      )
    case 'company':
      return (
        <span
          className={twMerge(
            'flex items-center gap-1 text-xs font-medium capitalize',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.company}
        </span>
      )
    case 'job_title':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.jobTitle || '--'}
        </span>
      )
    case 'user_type':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {userTypeDisplayMap[row.userType] || '--'}
        </span>
      )
    case 'last_login_date':
      return (
        <span
          className={twMerge(
            'flex items-baseline gap-1 text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.lastLogin === 'Online' && (
            <span className='h-2 w-2 rounded-full bg-green-6'></span>
          )}
          {row.lastLogin === 'Inactive' && (
            <span className='h-2 w-2 rounded-full bg-red-10'></span>
          )}
          {row.lastLogin === '--' && (
            <>
              <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
              Pending
            </>
          )}
          {row.lastLogin !== '--' && row.lastLogin}
        </span>
      )
    default:
      return <span></span>
  }
}
