import { apiURL } from '../store/api/api'
import { UsersTableProps } from '../components'
import { formatDate } from 'utils'

export const fetchUsersData = async () => {
  const companyId = localStorage.getItem('companyId')
  try {
    const response = await fetch(apiURL + '/user/' + companyId, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      },
    })
    const data = await response.json()
    if (data.message === 'Unauthorized') {
      localStorage.removeItem('access_token')
      localStorage.setItem('auth', 'false')
      window.location.reload()
    }

    if (data) {
      const usersData: UsersTableProps[] = data.map(
        (user: {
          id: string
          first_name: string | null
          last_name: string
          role_id: number
          company_id: string
          email: string
          created_at: string
          updated_at: string
          last_login_date: string
          status: string
          user_type: string
          access_level: string
        }) => {
          const loginDate: Date = new Date(user.last_login_date)
          const currentDate: Date = new Date()
          const hourInMilliseconds = 60 * 60 * 1000
          const lastLogin =
            user.status === 'paused'
              ? 'Paused'
              : currentDate.getTime() - loginDate.getTime() <=
                  hourInMilliseconds
                ? 'Online'
                : loginDate.getFullYear() === 1970
                  ? 'N/A'
                  : formatDate(loginDate, 'MM/DD/YY')
          return {
            id: user.id,
            name: user.first_name + ' ' + user.last_name,
            email: user.email,
            access_level: user.access_level,
            user_type: user.user_type,
            last_login: lastLogin,
            date_joined: formatDate(user.created_at, 'MMM D, YYYY'),
            projects: [],
            module_access: [2, 3, 4, 5],
          }
        },
      )
      return usersData
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
