import { ChangeEvent, ReactNode } from 'react'
import { BaseModal, Button, Input } from 'components'

export interface EditModalProps {
  isOpen: boolean
  handleClose: () => void
  title: string
  currentInputValue?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  cancelTitle?: string
  saveTitle?: string
  children?: ReactNode
  maxWidth?: string
  handleSave?: () => void
  hasInput?: boolean
}

export const EditModal = ({
  isOpen,
  handleClose,
  title,
  currentInputValue,
  onChange,
  cancelTitle = 'Cancel',
  saveTitle = 'Save',
  children,
  maxWidth,
  handleSave,
  hasInput = false,
}: EditModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      titleClass='pb-4'
      maxWidth={maxWidth}
    >
      {hasInput && (
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-[40px] px-3'
          value={currentInputValue}
          onChange={onChange}
        />
      )}
      {children}
      <div className='flex justify-end gap-2'>
        <Button
          kind='text'
          size='medium'
          onClick={handleClose}
          className='py-2.5'
        >
          <span className='px-1 text-sm font-semibold'>{cancelTitle}</span>
        </Button>

        <Button
          kind='filled'
          size='medium'
          className='bg-primary-red py-2.5 hover:bg-red-9 active:bg-primary-red/50'
          onClick={handleSave}
        >
          <span className='px-2 text-sm font-semibold'>{saveTitle}</span>
        </Button>
      </div>
    </BaseModal>
  )
}
