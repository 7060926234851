import { useState, useEffect } from 'react'
import { OptionType, SelectState } from 'types'

export const useSelectOptions = (
  initialOptions: Record<string, OptionType[]>,
  defaultValues?: number,
) => {
  const [selects, setSelects] = useState<SelectState>({})

  useEffect(() => {
    const initialSelects: SelectState = {}
    Object.keys(initialOptions).forEach(name => {
      const countOptions = initialOptions[name][2]?.value === 'percentage'
      if (initialOptions[name].length > 0) {
        if (
          initialOptions[name][0]?.value === 'topBox' &&
          initialOptions[name][1]?.value
        ) {
          initialSelects[name] = initialOptions[name][1]?.value
        } else {
          initialSelects[name] = countOptions
            ? initialOptions[name][defaultValues ?? 2]?.value
            : initialOptions[name][0]?.value
        }
      }
    })
    setSelects(initialSelects)
  }, [initialOptions])

  const handleSelectOptions = (option: OptionType, name: string) => {
    setSelects(prevSelects => ({
      ...prevSelects,
      [name]: option.value,
    }))
  }

  return { selects, handleSelectOptions, setSelects }
}
