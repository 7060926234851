import { ChangeEvent, ReactNode, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { DayPicker } from 'react-day-picker'

import {
  Colors,
  currentYear,
  primaryGenre,
  secondaryGenre,
  yearsAhead,
} from 'constnants'
import {
  IconButton,
  MovieDetailsItem,
  ProjectStatusItem,
  Label,
  UploadImage,
  EditMovieDetailsModal,
  Input,
  EditModal,
  Button,
  ProjectCast,
  AssignedUsers,
} from 'components'
import { Pencil } from 'components/ui/icons'
import { ImageModel, MyProject, MovieDetailsKey } from 'types'
import { toastError } from 'utils'
import { useClickOutsideComponent } from 'hooks'
import { useUpdatedProjectMutation } from 'store/api'

interface MovieProps {
  project: MyProject | undefined
  isLoading: boolean
}

export interface MovieDetails {
  label: string
  value: string | ReactNode | Date
  id: string
  title?: string
  maxWidth?: string
  descriptions?: string
  isEditable?: boolean
}

export const Movie = ({ project, isLoading }: MovieProps) => {
  const [image, setImage] = useState<ImageModel | null>(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState<MovieDetails | null>(
    null,
  )
  const [isOpenNameModal, setIsOpenNameModal] = useState(false)
  const [currentInputValue, setCurrentInputValue] = useState<
    string | undefined
  >(project?.projectName)
  const [selectedRange, setSelectedRange] = useState<Date | string | null>(null)
  const { isComponentVisible, setIsComponentVisible, ref } =
    useClickOutsideComponent(false)

  const [updateProject] = useUpdatedProjectMutation()

  const handleImageChange = (newImage: ImageModel | null) => {
    setImage(newImage)
  }

  const openEditNameModal = (itemName: string) => {
    if (itemName === 'projectName') {
      setIsOpenNameModal(true)
      setCurrentInputValue(project?.projectName)
    }
  }

  const openEditModal = (item: MovieDetails, key: string) => {
    setCurrentEditItem(item)
    if (key !== MovieDetailsKey.RELEASE_DATE && key !== MovieDetailsKey.CAST) {
      setIsEditModalOpen(true)
      setCurrentInputValue('')
    }
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setCurrentEditItem(null)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentInputValue(e.target.value)
  }

  const updateProjectName = () => {
    try {
      updateProject({
        id: project!.projectId,
        project: { name: currentInputValue },
      })
        .unwrap()
        .then(() => setIsOpenNameModal(false))
    } catch (e) {
      toastError(e)
    }
  }

  const saveChanges = () => {
    closeEditModal()
  }

  const handleDateSelect = async (date: Date) => {
    setSelectedRange(date)
    setIsComponentVisible(false)
    try {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      const formattedDate = (date as Date).toLocaleDateString('en-US', options)
      await updateProject({
        id: project!.projectId,
        project: {
          name: project!.projectName,
          additional_details: {
            runTime: {
              hours: project?.runTime.hours || '',
              minutes: project?.runTime.minutes || '',
            },
            releaseDate: formattedDate,
            releasePattern: project?.releasePattern.toLowerCase() ?? '',
            expectedDirector: project?.director ?? '',
            expectedWriter: project?.writer ?? '',
            expectedRating: project?.rating?.id ?? 'r',
            primaryGenre: project?.genres.primaryGenre ?? '',
            secondaryGenre: project?.genres.secondaryGenre ?? [],
          },
        },
      })
    } catch (e) {
      toastError(e)
    }
  }

  const updatedGenres = {
    primaryGenre: primaryGenre.find(
      genre => genre.id === project?.genres.primaryGenre,
    ),
    secondaryGenre: secondaryGenre.filter(genre =>
      project?.genres.secondaryGenre?.includes(genre.id),
    ),
  }

  const movieDetails: MovieDetails[] = [
    {
      label: 'Genre',
      value: (
        <ul className='flex items-center space-x-1'>
          {updatedGenres.primaryGenre && (
            <ProjectStatusItem
              key={`primary_${updatedGenres.primaryGenre.id}`}
              status={updatedGenres.primaryGenre.name}
              wrapperClassName='bg-primary-blue-light'
              statusClassName='normal-case'
            />
          )}
          {updatedGenres.secondaryGenre.map((genre, index) => (
            <ProjectStatusItem
              key={`secondary_${genre.id}_${index}`}
              status={genre.name}
              wrapperClassName='bg-grey-4'
              statusClassName='normal-case text-primary-grey'
            />
          ))}
        </ul>
      ),
      id: MovieDetailsKey.GENRE,
      title: 'primary and secondary genres',
      maxWidth: '935px',
    },
    {
      label: 'Rating',
      value: (
        <ProjectStatusItem
          status={project?.rating.label}
          wrapperClassName='bg-grey-4'
          statusClassName='normal-case text-primary-grey'
        />
      ),
      id: MovieDetailsKey.RATING,
      title: 'MPAA rating',
      maxWidth: '420px',
    },
    {
      label: 'Run Time',
      value:
        project?.runTime?.hours &&
        `${project?.runTime.hours}h ${project?.runTime.minutes ? `${project?.runTime.minutes}m` : ''}`,
      id: MovieDetailsKey.RUN_TIME,
      title: 'run time',
      maxWidth: '420px',
    },
    {
      label: 'Release Date',
      value: project?.releaseDate,
      id: MovieDetailsKey.RELEASE_DATE,
      title: 'release date',
      maxWidth: '420px',
    },
    {
      label: 'Budget',
      value: project?.budget.name,
      id: MovieDetailsKey.BUDGET,
      title: 'expected budget',
      maxWidth: '642px',
    },
    {
      label: 'Release Pattern',
      value: project?.releasePattern,
      id: MovieDetailsKey.RELEASE_PATTERN,
      title: 'release pattern',
      maxWidth: '420px',
    },
    {
      label: 'Director',
      value: project?.director,
      id: MovieDetailsKey.DIRECTOR,
      title: 'director(s)',
      descriptions:
        'For projects with multiple directors, use commas between names. (e.g. Joel Coen, Ethan Jesse Coen)',
      maxWidth: '420px',
    },
    {
      label: 'Writer',
      value: project?.writer,
      id: MovieDetailsKey.WRITER,
      title: 'writer(s)',
      descriptions:
        'For projects with multiple writer(s), use commas between names. (e.g. Joel Coen, Ethan Jesse Coen)',
      maxWidth: '420px',
    },
    {
      label: 'Cast',
      value: <ProjectCast casts={project?.cast} />,
      id: MovieDetailsKey.CAST,
      isEditable: false,
    },
  ]

  const firstColumnDetails = movieDetails.slice(0, 5)
  const secondColumnDetails = movieDetails.slice(5, movieDetails.length)
  console.log('project:', project)

  return (
    <div className='flex gap-6 bg-primary-white p-5'>
      <UploadImage image={image} onImageChange={handleImageChange} />

      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <div className='w-full'>
          <div className='flex justify-between border-b border-b-primary-black/10 pb-2'>
            <div className='flex items-center gap-2'>
              <h2 className='text-2xl font-semibold text-primary-black'>
                {project?.projectName}
              </h2>
              <IconButton
                onClick={() => openEditNameModal('projectName')}
                className='hover:opacity-70'
              >
                <Pencil fill={Colors.BASE_ICON} width={16} height={16} />
              </IconButton>
            </div>
          </div>

          <div>
            <div className='flex space-x-16 pt-4'>
              <div className='flex items-center space-x-3'>
                <Label label='Status' />
                <ProjectStatusItem
                  status={project?.projectStatus}
                  wrapperClassName='bg-primary-blue-light'
                />
              </div>
              <AssignedUsers />
            </div>
            <span className='block w-2/4 py-4 text-sm text-primary-black'>
              {project?.descriptions}
            </span>
          </div>

          <div className='flex gap-8'>
            <div className='border-t border-t-primary-black/20'>
              {firstColumnDetails.map((detail, index) => {
                const isReleaseDate = detail.id === MovieDetailsKey.RELEASE_DATE
                return (
                  <MovieDetailsItem
                    key={`${detail.value}_${index + 1}`}
                    label={detail.label}
                    handleOpen={() =>
                      isReleaseDate
                        ? setIsComponentVisible(true)
                        : openEditModal(detail, detail.id)
                    }
                    tooltipId={isReleaseDate ? 'date-tooltip' : ''}
                    data-tooltip-id='date-tooltip'
                  >
                    <span className='text-xs font-medium text-primary-grey'>
                      {detail.value as string}
                    </span>
                  </MovieDetailsItem>
                )
              })}
            </div>

            <div className='border-t border-t-primary-black/20'>
              {secondColumnDetails.map((detail, index) => (
                <MovieDetailsItem
                  key={`${detail.value}_${index + 1}`}
                  label={detail.label}
                  isEditable={detail.isEditable}
                  handleOpen={() => openEditModal(detail, detail.id)}
                >
                  <span className='text-xs font-medium text-primary-grey'>
                    {detail.value as string}
                  </span>
                </MovieDetailsItem>
              ))}
            </div>
          </div>

          {isComponentVisible && (
            <div ref={ref}>
              <Tooltip
                id='date-tooltip'
                place='right-end'
                isOpen={isComponentVisible}
                clickable
                style={stylesTooltip}
                render={() => (
                  <DayPicker
                    mode='single'
                    selected={selectedRange as Date}
                    captionLayout='dropdown'
                    showOutsideDays
                    fromYear={currentYear}
                    toYear={currentYear + yearsAhead}
                    onSelect={date => handleDateSelect(date as Date)}
                  />
                )}
                opacity={1}
                classNameArrow='shadow-[1px_1px_0_0_rgba(3,7,18,0.1)]'
              />
            </div>
          )}

          <EditModal
            isOpen={isOpenNameModal}
            handleClose={() => setIsOpenNameModal(false)}
            title='Edit project name'
            maxWidth='420px'
            handleSave={updateProjectName}
          >
            <div className='pb-3'>
              <Input
                value={currentInputValue}
                containerWrapperClassName='w-full'
                inputClassName='h-10 pl-2'
                onChange={handleInputChange}
              />
            </div>
          </EditModal>
          {isEditModalOpen && (
            <EditMovieDetailsModal
              isOpen={isEditModalOpen}
              handleClose={closeEditModal}
              onChange={handleInputChange}
              currentInputValue={currentInputValue}
              handleSave={saveChanges}
              movieDetails={movieDetails}
              project={project}
              currentEditItem={currentEditItem}
              title={`Edit ${currentEditItem?.title}`}
            />
          )}
        </div>
      )}
    </div>
  )
}

const stylesTooltip = {
  width: 'auto',
  backgroundColor: '#ffffff',
  color: '#000000',
  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.4)',
  padding: '20px',
}
