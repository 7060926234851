import { useEffect, useState } from 'react'
import { apiURL } from '../store/api/api'

type TalentSimpleResponse = {
  talent_id: string
  talent_name: string
  positionIndex: number
}

export default function useTalentSimple(metrics?: boolean) {
  const [talentsList, setTalentsList] = useState<TalentSimpleResponse[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const fetchTalent = async (talentName: string) => {
    setLoading(true)
    const url = new URL(`${apiURL}/talent?talentName=${talentName}`)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    const data: TalentSimpleResponse[] = await response.json()

    return data || []
  }
  const fetchMetricsTalent = async (talentName: string) => {
    setLoading(true)
    const url = new URL(
      `${apiURL}/talentMetrics/talents?talentName=${talentName}`,
    )
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    const data: TalentSimpleResponse[] = await response.json()

    return data || []
  }

  const getTalentsList = async (talentName: string) => {
    try {
      if (talentName?.length > 2) {
        if (metrics) {
          fetchMetricsTalent(talentName).then(data => {
            if (data.length) {
              setTalentsList(data)
            }
          })
        } else {
          fetchTalent(talentName).then(data => {
            if (data.length) {
              setTalentsList(data)
            }
          })
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    talentsList,
    getTalentsList,
    loading,
    error,
  }
}
