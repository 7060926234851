import { Button } from 'components'
import { Colors } from 'constnants'
import { Plus } from 'components/ui/icons'

interface TestingTopContentProps {
  handleAddTest: () => void
  subTitle: string
  title: string
}

export const TestingTopContent = ({
  handleAddTest,
  subTitle,
  title,
}: TestingTopContentProps) => {
  return (
    <div className='flex items-center justify-between p-3'>
      <div>
        <span className='block text-xs font-semibold text-primary-black/50'>
          {subTitle}
        </span>
        <span className='text-lg font-medium leading-6 text-primary-black'>
          {title}
        </span>
      </div>
      <Button
        kind='filled'
        size='medium'
        className='bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10'
        onClick={handleAddTest}
      >
        <div className='flex items-center'>
          <span className='pr-1 text-sm font-semibold'>New Test</span>
          <Plus fill={Colors.ICON_WHITE} width={22} height={22} />
        </div>
      </Button>
    </div>
  )
}
