import { ColumnsCompFinder, OptionType } from 'types'
export const compFinderRowsOption: OptionType<number>[] = [
  { id: '1', value: 25, label: '25' },
  { id: '2', value: 50, label: '50' },
  { id: '3', value: 100, label: '100' },
]

export const CompFinderColumns: ColumnsCompFinder[] = [
  { title: 'Film', value: 'film_name', className: 'w-40 max-w-40' },
  { title: 'Dist.', value: 'abbreviation', className: 'w-14 min-w-14' },
  {
    title: 'Day of Release',
    value: 'release_date_id',
    className: 'w-24 min-w-24',
  },
  { title: 'OW Theaters', value: 'ow_theatres', className: 'w-32' },
  {
    title: 'Mon',
    value: 'monday',
    className: 'w-20 min-w-20 bg-grey-3',
    isHidden: true,
  },
  {
    title: 'Tue',
    value: 'tuesday',
    className: 'w-20 min-w-20 bg-grey-3',
    isHidden: true,
  },
  {
    title: 'Wed',
    value: 'wednesday',
    className: 'w-20 min-w-20 bg-grey-3',
    isHidden: true,
  },
  {
    title: 'Thu',
    value: 'thursday',
    className: 'w-20 min-w-20 bg-grey-3',
    isHidden: true,
  },
  { title: 'Open Weekend', value: 'open_weekend' },
  { title: 'Total', value: 'total_gross' },
  { title: 'Budget', value: 'budget_range' },
  { title: 'Group', value: 'film_group_key' },
  { title: 'Genre', value: 'genres' },
  { title: 'Tags', value: 'tags' },
  { title: 'Rating', value: 'rating' },
  { title: 'PSRR', value: 'psrr' },
  { title: 'Franchise', value: 'franchise' },
  { title: 'Universe', value: 'universe' },
]
