import {
  ReleaseCalendarSelectKey,
  SwitchFilmSelect,
  ToggleSwitchTitle,
} from 'types'

export const shouldIncludeReleaseData = (
  releaseData: { value?: string },
  switchTitle: ToggleSwitchTitle,
  switchFilmSelect: SwitchFilmSelect,
): boolean => {
  // console.log('releaseData',releaseData.value,  switchTitle, switchFilmSelect)
  if (!releaseData.value) {
    return false
  }

  switch (releaseData.value) {
    case ReleaseCalendarSelectKey.SPORTING_EVENTS:
      return switchTitle.sportingEvents

    case ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL:
      return switchTitle.holidayAndSchool

    case ReleaseCalendarSelectKey.PREMIERES_EVENTS:
      return switchTitle.premieresEvents

    default:
      return switchFilmSelect[releaseData.value]
  }
}
