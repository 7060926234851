import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useNavigate } from 'react-router-dom'

import {
  IconButton,
  ProjectDetailsEmptyContent,
  RoleItemList,
  RolesList,
  Select,
} from 'components'
import { ChevronLeft, Plus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { Roles, OptionType, Urls } from 'types'

interface ProjectRoleMenuProps {
  handleSelectProject: (option: OptionType) => void
  projectValue: string
  projectRoleOptions: OptionType[]
  roles: Roles[] | undefined
  handleOpenCreateList: () => void
  handleOpenSearch: () => void
  handleClickSearch?: (role: OptionType) => void
  handleClickItemTalentsLists?: (role: OptionType) => void
  talentsLists?: OptionType[]
  searchPresets?: OptionType[]
  setSelectedRole: (role: string) => void
  selectedRole: string
  onEditPresetSearchList: (id: string, value: string) => void
  handleDeletePresetSearchList?: (id: string) => void
  selectedRoleList: Roles
  setSelectedRoleList: (role: Roles) => void
  handleOpenEditModal?: (id: string, value: string) => void
  handleDeleteModal?: (id: string) => void
}

export const ProjectRoleMenu = ({
  handleSelectProject,
  projectValue,
  projectRoleOptions,
  roles,
  handleOpenCreateList,
  handleOpenSearch,
  handleClickSearch,
  handleClickItemTalentsLists,
  talentsLists,
  searchPresets,
  setSelectedRole,
  selectedRole,
  onEditPresetSearchList,
  handleDeletePresetSearchList,
  setSelectedRoleList,
  selectedRoleList,
  handleOpenEditModal,
  handleDeleteModal,
}: ProjectRoleMenuProps) => {
  const [isMainView, setIsMainView] = useState(true)
  const navigate = useNavigate()

  const handleSelectRoleList = (role: Roles) => {
    setSelectedRoleList(role)
    setIsMainView(false)
    setSelectedRole(role.id)
  }

  useEffect(() => {
    if (selectedRole && roles && roles.length > 0) {
      const role = roles?.find(role => role.id === selectedRole)
      if (role) {
        setSelectedRoleList(role)
        setIsMainView(false)
      }
    }
  }, [roles])

  const handleBackClick = () => {
    setIsMainView(true)
  }

  const onSelectProject = (option: OptionType) => {
    handleSelectProject(option)
    setIsMainView(true)
  }

  const isSelectedProjectHasRoles = roles && roles?.length > 0

  const recentProjects = projectRoleOptions?.slice(0, 3) ?? []

  return (
    <div className='flex h-full w-full flex-col gap-3 overflow-y-auto p-2'>
      {projectRoleOptions.length > 0 && (
        <Select
          value={projectValue}
          placeholder='Select Project'
          handleClick={onSelectProject}
          options={projectRoleOptions}
          hasSearch
          wrapperOptionClass='p-3 gap-3 flex flex-col'
          placeholderSearch='Search Projects'
          size='medium'
          selectedLabelClass='text-primary-red font-medium'
        />
      )}

      {isSelectedProjectHasRoles ? (
        <div className={twMerge(isMainView ? 'visible' : 'hidden')}>
          <RolesList
            roles={roles}
            selectedRole={selectedRoleList}
            handleSelectRole={handleSelectRoleList}
          />
        </div>
      ) : recentProjects.length > 0 ? (
        <RoleItemList
          title='Recent Projects'
          list={recentProjects}
          hasEditTrash={false}
          handleSelectRole={handleSelectProject}
          handleOpenEdit={onEditPresetSearchList}
        />
      ) : (
        <ProjectDetailsEmptyContent
          title='You don’t have any projects'
          descriptions='Go to My Projects, and create your first project now.'
          nameButton='Go to My Projects'
          wrapperClassName='bg-primary-white my-auto'
          descriptionClassName='text-xs text-primary-grey'
          hasPlus={false}
          titleClass='text-sm font-semibold'
          buttonTitleClassName='text-xs'
          widthIcon={36}
          heightIcon={36}
          onOpen={() => navigate(Urls.MY_PROJECTS)}
        />
      )}

      <div
        className={twMerge(
          !isMainView && isSelectedProjectHasRoles ? 'flex flex-col' : 'hidden',
        )}
      >
        <div
          className='flex items-center gap-1 pb-4 hover:cursor-pointer'
          onClick={handleBackClick}
        >
          <ChevronLeft width={16} height={16} fill={Colors.ICON_BRAND} />
          <span className='text-xs font-medium text-primary-red'>
            {selectedRoleList.label}
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <RoleItemList
            title='SAVED SEARCHES'
            list={searchPresets}
            hasEditTrash
            rightButton={
              <IconButton
                className='rounded bg-primary-grey'
                onClick={handleOpenSearch}
              >
                <Plus fill={Colors.ICON_WHITE} width={16} height={16} />
              </IconButton>
            }
            onClickItem={handleClickSearch}
            handleOpenEdit={onEditPresetSearchList}
            handleOpenDelete={handleDeletePresetSearchList}
          />

          <RoleItemList
            title='Talent List'
            list={talentsLists}
            onClickItem={handleClickItemTalentsLists}
            hasEditTrash
            rightButton={
              <IconButton
                className='rounded bg-primary-grey'
                onClick={handleOpenCreateList}
              >
                <Plus fill={Colors.ICON_WHITE} width={16} height={16} />
              </IconButton>
            }
            handleOpenEdit={handleOpenEditModal}
            handleOpenDelete={handleDeleteModal}
          />
        </div>
      </div>
    </div>
  )
}
