import { twMerge } from 'tailwind-merge'

interface FilmTitleProps {
  title: string
  size: 'xs' | 'sm' | 'm' | 'lg'
  className?: string
}

export const FilmTitle = ({ title, size, className }: FilmTitleProps) => {
  return (
    <span
      className={twMerge(
        'flex text-primary-black',
        size === 'xs' && 'text-xs',
        size === 'sm' && 'text-sm',
        size === 'm' && 'text-base',
        size === 'lg' && 'text-lg font-medium leading-6',
        className,
      )}
    >
      {title}
    </span>
  )
}
