import { IconButton, LayoutContentWrapper } from 'components'
import { ConceptTemplate } from './ConceptTemplate'
import { AssetTemplate } from './AssetTemplate'
import { RoleTemplate } from './RoleTemplate'
import { Close } from 'components/ui/icons'
import { Colors } from 'constnants'

interface NewTestingPageProps {
  handleCloseNewTesting: () => void
  subTitle: string
  title: string
  type: string
}

export const NewTestingPage = ({
  handleCloseNewTesting,
  subTitle,
  title,
  type,
}: NewTestingPageProps) => {
  const renderTemplate = () => {
    switch (type) {
      case 'concept':
        return <ConceptTemplate returnBack={handleCloseNewTesting} />
      case 'role':
        return <RoleTemplate returnBack={handleCloseNewTesting} />
      case 'asset':
        return <AssetTemplate returnBack={handleCloseNewTesting} />
      default:
        return <span></span>
    }
  }

  return (
    <LayoutContentWrapper wrapperClassName='bg-grey-4 p-4 overflow-y-auto'>
      <div className='flex items-center justify-between'>
        <div className='pb-1'>
          <span className='text-xs font-semibold text-primary-black/50'>
            {subTitle}
          </span>
          <h3 className='text-2xl font-semibold text-primary-black'>{title}</h3>
        </div>
        <IconButton
          className='rounded-full bg-primary-black/5 hover:bg-primary-black/15'
          onClick={handleCloseNewTesting}
        >
          <Close fill={Colors.BASE_ICON} width={20} height={20} />
        </IconButton>
      </div>
      <div className={'mt-3 rounded bg-primary-white px-7 py-5'}>
        {renderTemplate()}
      </div>
    </LayoutContentWrapper>
  )
}
