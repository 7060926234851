import { BaseQueryFn, EndpointBuilder } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import {
  TVFilmCompanySortColKey,
  TVFilmCompanyType,
  TVFilmDistributorType,
  type TVFilmCompanyData,
} from 'modules/management/data/types'

type PaginatedRequest = {
  pageSize: number
  pageNumber: number
  searchTerm: string | undefined
  sortBy: TVFilmCompanySortColKey
  sortOrder: 'ASC' | 'DESC'
  companyType: TVFilmCompanyType | undefined
  distributorType: TVFilmDistributorType | undefined
}

type PaginatedResponse = {
  totalRecords: number
  totalPages: number
  currentPage: number
  pageSize: number
  data: TVFilmCompanyData[]
}

type TVFilmCompanyCreateRequest = {
  name: string
  abbr: string
  imdbId: string
  companyType: TVFilmCompanyType
  distributorType?: TVFilmDistributorType | null
}

type TVFilmCompanyUpdateRequest = {
  name: string
  abbr: string
  imdbId: string
  companyType: TVFilmCompanyType
  distributorType?: TVFilmDistributorType | null
}

function simulatePaginatedApi(
  mockData: TVFilmCompanyData[],
  delay: number,
  request: PaginatedRequest,
): Promise<PaginatedResponse> {
  const {
    pageSize,
    pageNumber,
    sortOrder,
    sortBy,
    searchTerm,
    companyType,
    distributorType,
  } = request

  return new Promise(resolve => {
    setTimeout(() => {
      // Filter data by `name`, `companyType`, and `distributorType`
      const filteredData = mockData.filter(item => {
        const matchesSearchTerm = searchTerm
          ? item.name.toLowerCase().includes(searchTerm.toLowerCase())
          : true

        const matchesCompanyType = companyType
          ? item.companyType === companyType
          : true

        const matchesDistributorType = distributorType
          ? item.distributorType === distributorType
          : true

        return matchesSearchTerm && matchesCompanyType && matchesDistributorType
      })

      const totalRecords = filteredData.length

      // Sorting logic
      const sortedData = [...filteredData].sort((a, b) => {
        if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
          const comparison = (a[sortBy] as string).localeCompare(
            b[sortBy] as string,
          )
          return sortOrder === 'ASC' ? comparison : -comparison
        }
        if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
          const comparison = (a[sortBy] as number) - (b[sortBy] as number)
          return sortOrder === 'ASC' ? comparison : -comparison
        }
        return 0 // Default to no sorting if type mismatch
      })

      // Pagination logic
      const totalPages = Math.ceil(totalRecords / pageSize)
      const startIndex = (pageNumber - 1) * pageSize
      const endIndex = Math.min(startIndex + pageSize, totalRecords)
      const paginatedData = sortedData.slice(startIndex, endIndex)

      const response: PaginatedResponse = {
        totalRecords,
        totalPages,
        currentPage: pageNumber,
        pageSize,
        data: paginatedData,
      }

      resolve(response)
    }, delay)
  })
}

export function defineGetTVFilmCompaniesDataEndpoint(
  builder: EndpointBuilder<BaseQueryFn, 'TVFilmCompany', 'mockApi'>,
) {
  return builder.query<PaginatedResponse, PaginatedRequest>({
    queryFn: async request => {
      try {
        const response = await simulatePaginatedApi(
          tVFilmCompanyMockData,
          500,
          request,
        )
        return { data: response }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error fetching paginated data' },
        }
      }
    },
    providesTags: [{ type: 'TVFilmCompany', id: 'LIST' }],
  })
}

export function defineCreateTVFilmCompanyEndpoint(
  builder: EndpointBuilder<BaseQueryFn, 'TVFilmCompany', 'mockApi'>,
) {
  return builder.mutation<
    TVFilmCompanyCreateRequest & { id: string },
    TVFilmCompanyCreateRequest
  >({
    queryFn: async newData => {
      try {
        const newEntry = { ...newData, id: uuidv4() }
        tVFilmCompanyMockData.push(newEntry)
        return { data: newEntry }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error creating new company' },
        }
      }
    },
    invalidatesTags: [{ type: 'TVFilmCompany', id: 'LIST' }],
  })
}

export function defineUpdateTVFilmCompanyEndpoint(
  builder: EndpointBuilder<BaseQueryFn, 'TVFilmCompany', 'mockApi'>,
) {
  return builder.mutation<
    TVFilmCompanyData,
    { companyId: string; updates: Partial<TVFilmCompanyUpdateRequest> }
  >({
    queryFn: async ({ companyId, updates }) => {
      try {
        const index = tVFilmCompanyMockData.findIndex(
          item => item.id === companyId,
        )
        if (index === -1) {
          return {
            error: {
              status: 404,
              data: `Company with id ${companyId} not found`,
            },
          }
        }

        tVFilmCompanyMockData[index] = {
          ...tVFilmCompanyMockData[index],
          ...updates,
        }

        return { data: tVFilmCompanyMockData[index] }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error updating the company' },
        }
      }
    },
    invalidatesTags: [{ type: 'TVFilmCompany', id: 'LIST' }],
  })
}

const tVFilmCompanyMockData: TVFilmCompanyData[] = [
  {
    id: '1',
    name: '20th Century Studios',
    companyType: TVFilmCompanyType.FilmDistributor,
    abbr: '20TH',
    imdbId: 'co0005073',
    distributorType: TVFilmDistributorType.Major,
  },
  {
    id: '2',
    name: 'Apple TV+',
    companyType: TVFilmCompanyType.FilmDistributor,
    abbr: 'APL',
    imdbId: 'co0005073',
    distributorType: TVFilmDistributorType.Minor,
  },
  {
    id: '3',
    name: 'Apple TV+',
    companyType: TVFilmCompanyType.TVStreamer,
    abbr: 'APL+',
    imdbId: 'co0005073',
    distributorType: null,
  },
  {
    id: '4',
    name: 'Cinemax',
    companyType: TVFilmCompanyType.TVStreamer,
    abbr: 'CMX',
    imdbId: 'co0005073',
    distributorType: null,
  },
  {
    id: '5',
    name: 'HBO',
    companyType: TVFilmCompanyType.TVCable,
    abbr: 'HBO',
    imdbId: 'co0005073',
    distributorType: null,
  },
  {
    id: '6',
    name: 'Disney',
    companyType: TVFilmCompanyType.FilmDistributor,
    abbr: 'DIS',
    imdbId: 'co0005073',
    distributorType: TVFilmDistributorType.Major,
  },
  {
    id: '7',
    name: 'Disney Channel',
    companyType: TVFilmCompanyType.TVCable,
    abbr: 'DSN',
    imdbId: 'co0005073',
    distributorType: null,
  },
  {
    id: '8',
    name: 'Disney+',
    companyType: TVFilmCompanyType.TVStreamer,
    abbr: 'DIS+',
    imdbId: 'co0005073',
    distributorType: null,
  },
  {
    id: '9',
    name: 'Max',
    companyType: TVFilmCompanyType.TVStreamer,
    abbr: 'MAX',
    imdbId: 'co0005073',
    distributorType: null,
  },
  {
    id: '10',
    name: 'Netflix',
    companyType: TVFilmCompanyType.TVStreamer,
    abbr: 'NFX',
    imdbId: 'co0005073',
    distributorType: null,
  },
  {
    id: '11',
    name: 'Netflix',
    companyType: TVFilmCompanyType.FilmDistributor,
    abbr: 'NFLX',
    imdbId: 'co0005073',
    distributorType: TVFilmDistributorType.Other,
  },
  {
    id: '12',
    name: 'Universal',
    companyType: TVFilmCompanyType.FilmDistributor,
    abbr: 'UNI',
    imdbId: 'co0005073',
    distributorType: TVFilmDistributorType.Major,
  },
  {
    id: '13',
    name: 'Warner Bros.',
    companyType: TVFilmCompanyType.FilmDistributor,
    abbr: 'WB',
    imdbId: 'co0005073',
    distributorType: TVFilmDistributorType.Major,
  },
]
