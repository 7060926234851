import { twMerge } from 'tailwind-merge'

import { NewProjectTypeSelector, ProjectDetails, TypeSelectorKind } from 'types'
import { TypeSelector, TypeSelectorTitle } from 'components'
import { useTypedSelector } from 'hooks'
import { extractKeyValue, isArrayContainsValue } from 'constnants'

interface ProjectDetailsSelectorListProps {
  selectors: NewProjectTypeSelector[]
  title: string
  description?: string
  kindSelector?: TypeSelectorKind
  wrapperListClassName?: string
  sizeTypeSelector?: 'small' | 'large'
  handleChange: (value: string) => void
  keyName: keyof ProjectDetails
  tooltipId: string
  tooltipContent: string
}

export const ProjectDetailsSelectorList = ({
  title,
  selectors,
  description,
  kindSelector = 'oval',
  wrapperListClassName,
  sizeTypeSelector,
  handleChange,
  keyName,
  tooltipId,
  tooltipContent,
}: ProjectDetailsSelectorListProps) => {
  const { projectDetails } = useTypedSelector(state => state.projectReducer)

  return (
    <div className='relative w-fit'>
      <div
        className='pb-0.5'
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltipContent}
      >
        <TypeSelectorTitle title={title} />
        {description && (
          <span className='text-md leading-6 text-grey-7'>{description}</span>
        )}
      </div>
      <ul className={twMerge('flex flex-wrap gap-1', wrapperListClassName)}>
        {selectors.map(({ name, id }) => (
          <li key={id}>
            <TypeSelector
              title={name}
              kind={kindSelector}
              size={sizeTypeSelector}
              onClick={() => handleChange(id)}
              isSelected={
                keyName === 'secondaryGenre'
                  ? isArrayContainsValue(projectDetails.secondaryGenre, id)
                  : extractKeyValue(projectDetails, id)
              }
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
