import { Button, IconButton, Input, Textarea } from '../../components'
import { ChangeEvent } from 'react'
import { Close, SquarePlus } from '../../components/ui/icons'
import { Colors } from '../../constnants'

interface MultipleFilesInputProps {
  introText?: string
  listText?: string[]
  fields: { file: string; fileDescription: string }[]
  onChangeHandler: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
    index: number,
    objectKey?: string,
  ) => void
  handleAddNewFile: () => void
  removeFileRow: (index: number) => void
}
export const MultipleFilesInput = ({
  introText,
  listText,
  fields,
  onChangeHandler,
  handleAddNewFile,
  removeFileRow,
}: MultipleFilesInputProps) => {
  return (
    <div className='my-8 rounded bg-grey-3 px-6 py-4 pr-8'>
      <div className={'mb-4 text-sm text-grey-7'}>
        <p className={'font-semibold uppercase '}>Files ({fields.length}/5)</p>
        {introText && <p>{introText}</p>}
        {listText && (
          <ul className={'ml-5 list-disc'}>
            {listText.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        )}
      </div>
      <div>
        {fields.map((field, index) => (
          <div key={index} className={'relative'}>
            <p className={'mb-1 font-semibold text-primary-black'}>
              File {index + 1}
            </p>
            <Input
              placeholder={'File name'}
              value={field.file}
              onChange={e => {
                onChangeHandler(e, 'nonTrailerAssets', index, 'file')
              }}
              wrapperClassName='w-full h-8'
              inputClassName='pl-3 text-sm'
            />
            <Textarea
              onChange={e =>
                onChangeHandler(e, 'nonTrailerAssets', index, 'fileDescription')
              }
              placeholder={'File Description'}
              className={'mt-1 text-sm'}
            />
            {index !== fields.length - 1 && <hr className='my-4' />}
            {fields.length !== 1 && (
              <IconButton
                className='absolute left-full top-0 my-auto h-fit rounded hover:bg-primary-black/5'
                onClick={() => removeFileRow(index)}
              >
                <Close fill={Colors.BASE_ICON} width={20} height={20} />
              </IconButton>
            )}
          </div>
        ))}
        <div className='mt-2 flex w-full gap-4'>
          <Button
            kind='text'
            size='small'
            onClick={handleAddNewFile}
            className='flex min-w-fit items-center gap-1'
            disabled={fields.length === 5}
          >
            <SquarePlus fill={Colors.BASE_ICON} width={16} height={16} />
            <span>Add another File</span>
          </Button>
          <div className='mb-3 w-full border-b border-b-primary-black/10' />
        </div>
      </div>
    </div>
  )
}
