import { useMemo, useState } from 'react'
import { BaseModal, Button, InformationBox, RoleList } from 'components'

import { RoleProject } from 'types'
import { useUpdateProjectRoleMutation } from 'store/api'

interface EditRoleModalContentProps {
  isOpen: boolean
  onClose: () => void
  role: RoleProject
  projectId: number
}

export const EditRoleModalContent = ({
  isOpen,
  onClose,
  role,
  projectId,
}: EditRoleModalContentProps) => {
  const [currentRole, setCurrentRole] = useState(role)
  const [updateProjectRole] = useUpdateProjectRoleMutation()

  const updateRole = (role: RoleProject, projectId: number) =>
    updateProjectRole({
      role: {
        name: role.roleName,
        type: Number(role.roleType.id),
        description: role.description,
        talentId: +role.attachedTalent.id,
        status: 'active',
      },
      roleId: role.roleId,
      projectId,
    })

  const roles = useMemo(() => [currentRole], [currentRole])

  const handleSaveMyRole = () => {
    updateRole(currentRole, projectId)
    onClose()
  }

  const handleUpdateChange = (roleId: string, data: Partial<RoleProject>) => {
    setCurrentRole(prev => ({ ...prev, ...data, roleId }))
  }

  const handleDeleteRole = () => {}

  return (
    <BaseModal isOpen={isOpen} handleClose={onClose} maxWidth='880px'>
      <div className='flex max-h-[700px] flex-col overflow-y-auto'>
        <span className='block py-3 text-lg font-medium leading-6 text-primary-black'>
          Edit Role
        </span>
        <div className='flex w-full flex-col space-y-5 rounded bg-grey-3 p-3'>
          <RoleList
            roles={roles}
            onDeleteRole={handleDeleteRole}
            onUpdateRole={handleUpdateChange}
          />
          <div className='flex flex-col space-y-5 rounded bg-grey-3 p-3'>
            <InformationBox wrapperClassName='bg-primary-black/5 rounded-md'>
              <span className='text-sm text-primary-black'>
                Is the Talent you want to attach not coming up? Don’t worry.
                Leave the custom entry there. Our system will try to add the
                missing talent soon.
              </span>
            </InformationBox>
          </div>
        </div>
        <div className='ml-auto flex gap-3 pt-4'>
          <Button
            kind='text'
            size='medium'
            className='w-fit py-1.5'
            onClick={onClose}
          >
            <span className='px-1'>Cancel</span>
          </Button>
          <Button
            kind='filled'
            size='medium'
            className='w-fit bg-primary-red py-1.5 hover:bg-red-9 active:bg-red-10'
            onClick={handleSaveMyRole}
          >
            <span className='px-1'>Save</span>
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
