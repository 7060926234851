import { Input } from 'components'
import { Search, XCircle } from 'components/ui/icons'
import { Colors } from 'constnants'
import { useEntitySearchByField } from 'hooks'

interface EntitySearchByFieldProps {
  className?: string
  placeholder?: string
  onClear?: () => void
  onSearch: (searchTerm: string) => void
}

export const EntitySearchByField = ({
  className,
  placeholder = 'Name Lookup',
  onClear,
  onSearch,
}: EntitySearchByFieldProps) => {
  const { searchTerm, setSearchTerm, handleInputChange } =
    useEntitySearchByField({ onSearch })

  const handleClearSelection = () => {
    setSearchTerm('')
    onClear?.()
  }

  return (
    <Input
      containerWrapperClassName={className || 'w-[200px] h-6'}
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleInputChange}
      leftIcon={<Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />}
      rightIcon={
        searchTerm ? (
          <XCircle
            onClick={handleClearSelection}
            className='mr-1 hover:cursor-pointer'
          />
        ) : undefined
      }
      autoComplete='off'
    />
  )
}
