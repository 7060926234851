import { parseDateId } from './parseDateId'
import { formatDate } from './formatDate'

export const formatSurveyDate = (
  dateString: string | number,
  withDayName = false,
): string =>
  formatDate(
    parseDateId(dateString),
    withDayName ? 'ddd, MM/DD/YY' : 'MM/DD/YY',
  )
