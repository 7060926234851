import { twMerge } from 'tailwind-merge'
import { useState } from 'react'
import { useIsMobile } from 'hooks'
import { Button } from 'components'
import { Logo } from 'components/ui/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import './LoginHeader.css'

interface HeaderProps {
  isOpenSidenav?: boolean
}

export const LoginHeader = ({ isOpenSidenav }: HeaderProps) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useState<boolean>(false)

  const handleToggle = () => {
    setIsOpenMobileSidebar(!isOpenMobileSidebar)
  }

  const handleButtonClick = () => {
    if (location.pathname === '/login') {
      alert('Contact page/modal')
    } else {
      navigate('/login')
    }
  }

  const buttonText = location.pathname === '/login' ? 'Contact Us' : 'Log In'

  return (
    <header
      className={twMerge(
        'flex justify-between bg-primary-blue p-2 transition-all duration-300',
        isMobile && 'ml-0',
      )}
    >
      <div className='flex gap-2'>
        {!isMobile && <Logo width={30} height={32} />}
      </div>

      <div
        className={twMerge(
          'flex flex-row items-center justify-between pl-2',
          isMobile && 'justify-end',
        )}
      >
        <div className='flex hidden items-center gap-2'>
          <p className='text'>Already have an account?</p>
          <Button
            kind='filled'
            size='medium'
            className='login-btn'
            onClick={handleButtonClick}
          >
            <span>{buttonText}</span>
          </Button>
        </div>
      </div>
    </header>
  )
}
