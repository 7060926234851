import React, { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { UsersTableBodyProps } from '../UsersTable.types'
import { UsersTableCell } from './UsersTableCell'
import { MoreVertical } from '../../ui/icons'
import { userManagement } from '../../../types'

export const UsersTableBody = ({
  data,
  columns,
  isLoading,
  setUserProfileOpen,
  handleOpenWarning,
}: UsersTableBodyProps) => {
  const placeholderData = Array.from({ length: 20 }, (_, index) => ({
    firstName: 'userName',
    lastName: 'userName',
    userId: Math.random().toString(),
    email: 'email email',
    jobTitle: 'jobTitle jobTitle',
    company: 'company company',
    lastLogin: 'lastLogin',
    userType: 'superadmin',
  }))
  const [dataToMap, setDataToMap] = useState<userManagement[]>(data)
  const handleClickOutside = (event: MouseEvent) => {
    if (
      refs.current &&
      !refs.current.some(ref => ref && ref.contains(event.target as Node))
    ) {
      setVisibleComponent(null)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const refs = useRef<(HTMLDivElement | null)[]>([])
  const [visibleComponent, setVisibleComponent] = useState<number | null>(null)

  useEffect(() => {
    setDataToMap(isLoading ? placeholderData : data)
  }, [isLoading, data])

  return (
    <tbody>
      {dataToMap.length > 0 &&
        dataToMap.map((row: userManagement, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map(column => (
              <td
                key={column.value}
                className={twMerge(
                  'w-64 cursor-pointer rounded border-b border-b-primary-black/20 p-2 py-4',
                  isLoading && 'animate-pulse',
                )}
                onClick={() => setUserProfileOpen && setUserProfileOpen(row)}
              >
                <UsersTableCell
                  row={row}
                  column={column}
                  isLoading={isLoading}
                />
              </td>
            ))}
            <td
              className={twMerge(
                'relative w-12 cursor-pointer border-b border-b-primary-black/20 px-2.5 py-4 hover:text-red-10',
                isLoading && 'animate-pulse',
              )}
              onClick={() => {
                setVisibleComponent(rowIndex)
              }}
              ref={el => (refs.current[rowIndex] = el)}
            >
              <MoreVertical fill={'currentColor'} width={16} height={16} />
              {visibleComponent === rowIndex && (
                <div className='absolute right-full top-1/2 z-50 -translate-y-1/2 transform rounded-md bg-primary-white p-3 shadow-md'>
                  <ul className='min-w-[120px] whitespace-nowrap'>
                    <li
                      className={twMerge(
                        'rounded px-1 py-0.5 text-primary-black transition-colors hover:cursor-pointer hover:bg-primary-black/5',
                        row.lastLogin !== 'Inactive' &&
                          row.status !== 'paused' &&
                          'text-red-10',
                      )}
                      onClick={() =>
                        handleOpenWarning(
                          row.lastLogin !== 'Inactive' &&
                            row.status !== 'paused'
                            ? 'deactivate'
                            : 'reactivate',
                          row.firstName + ' ' + row.lastName,
                          row.userId.toString(),
                        )
                      }
                    >
                      {row.lastLogin !== 'Inactive' && row.status !== 'paused'
                        ? 'Deactivate'
                        : 'Reactivate'}
                    </li>
                  </ul>
                </div>
              )}
            </td>
          </tr>
        ))}
    </tbody>
  )
}
