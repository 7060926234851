import React, { useState, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import { CompFinderTableHeaderProps } from '../CompFinderTable.types'
import {
  ChevronDown,
  ChevronUp,
  CloseMenu,
  OpenMenu,
} from 'components/ui/icons'
import { Colors } from 'constnants'
import { Checkbox } from '../../ui'

export const CompFinderTableHeader: React.FC<CompFinderTableHeaderProps> = ({
  columns,
  onSort,
  onSortOrder,
  sortingBY,
  selectAllChecked,
  setSelectAllChecked,
  filteredFilms,
  setSelectedRows,
  activeTableTab,
  sortingOrder,
  isTheatersOpened,
  setIsTheatersOpened,
}) => {
  const [clicksToReset, setClicksToReset] = useState<number>(1)

  const onSortHandler = useCallback(
    (value: string) => {
      if (onSortOrder) {
        if (sortingBY === value) {
          if (clicksToReset === 0) {
            setClicksToReset(1)
            onSortOrder('ASC')
          } else {
            setClicksToReset(prev => prev - 1)
            onSortOrder(prevSort => (prevSort === 'ASC' ? 'DESC' : 'ASC'))
          }
        } else {
          setClicksToReset(1)
          onSortOrder('ASC')
        }
      }
      if (onSort) {
        if (clicksToReset === 0) {
          setClicksToReset(1)
          onSort('')
        } else {
          onSort(value)
        }
      }
    },
    [onSortOrder, onSort, sortingBY, clicksToReset],
  )

  const toggleSelectAll = useCallback(() => {
    const newSelectAllChecked = !selectAllChecked
    setSelectAllChecked?.(newSelectAllChecked)

    if (newSelectAllChecked) {
      const newSelectedRows = filteredFilms.map(item => item.film_id) ?? []
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }, [selectAllChecked, setSelectAllChecked, filteredFilms, setSelectedRows])

  const handleTheaterToggle = useCallback(() => {
    setIsTheatersOpened(prev => !prev)
  }, [setIsTheatersOpened])

  const getSortingIcon = useCallback(
    (columnValue: string) => (
      <div>
        <ChevronUp
          width={10}
          height={8}
          className={twMerge(
            '-mb-1',
            sortingBY === columnValue && sortingOrder === 'ASC'
              ? 'text-primary-red'
              : 'text-grey-7',
          )}
        />
        <ChevronDown
          width={10}
          height={8}
          className={twMerge(
            sortingBY === columnValue && sortingOrder === 'DESC'
              ? 'text-primary-red'
              : 'text-grey-7',
          )}
        />
      </div>
    ),
    [sortingBY, sortingOrder],
  )

  return (
    <thead className='sticky top-0 z-10'>
      <tr>
        <th className='w-9 border-b border-b-primary-black/20 bg-grey-3 pl-2.5'>
          <Checkbox
            kind='small'
            checked={selectAllChecked}
            onChange={toggleSelectAll}
          />
        </th>
        {columns
          .filter(column =>
            activeTableTab === 'Group'
              ? column.value !== 'genres'
              : column.value !== 'film_group_key',
          )
          .map(column => (
            <th
              key={column.value}
              className={twMerge(
                'whitespace-nowrap border-b border-b-primary-black/20 bg-grey-3 px-1 py-4 text-xs font-normal text-primary-grey',
                column.className,
                column.isHidden && !isTheatersOpened && 'hidden',
                column.value !== 'tags' && 'hover:cursor-pointer',
              )}
            >
              {column.title && (
                <div
                  className={
                    column.value === 'ow_theatres'
                      ? 'flex justify-between gap-2'
                      : ''
                  }
                >
                  <div
                    className='flex items-center gap-2'
                    onClick={() =>
                      column.value !== 'tags' && onSortHandler(column.value)
                    }
                  >
                    {column.title}
                    {column.value !== 'tags' && getSortingIcon(column.value)}
                  </div>
                  {column.value === 'ow_theatres' && (
                    <span onClick={handleTheaterToggle}>
                      {isTheatersOpened ? (
                        <CloseMenu
                          fill={Colors.ICON_BRAND}
                          width={16}
                          height={16}
                        />
                      ) : (
                        <OpenMenu
                          fill={Colors.ICON_BRAND}
                          width={16}
                          height={16}
                        />
                      )}
                    </span>
                  )}
                </div>
              )}
            </th>
          ))}
      </tr>
    </thead>
  )
}

export default React.memo(CompFinderTableHeader)
