export type TVManagementData = {
  id: string
  imdbId: string
  title: string
  date: string
  networkType: string
  network: string
  networkImdbId: string
  tvType: string
  tvSubType: string
  genre: string
}

export enum TVManagementColKey {
  EditEntry = 'editEntry',
  ImdbId = 'imdbId',
  Title = 'title',
  Date = 'date',
  NetworkType = 'networkType',
  Network = 'network',
  NetworkImdbId = 'networkImdbId',
  TvType = 'tvType',
  TvSubType = 'tvSubType',
  Genre = 'genre',
}

export type TVManagementSortColKey =
  | TVManagementColKey.Title
  | TVManagementColKey.ImdbId
  | TVManagementColKey.Network
