import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssignedUser, MyProjectState, RoleProject } from 'types'
import { createEmptyProjectRole } from 'utils'

const initialState: MyProjectState = {
  tempRoles: [createEmptyProjectRole()],
  logline: {
    id: '',
    name: '',
    lastEdited: '',
    myLogline: '',
  },
  assignedUsers: [],
  assignedUserList: [],
  assignedUser: {
    id: '',
    fullName: '',
    email: '',
    userType: '',
  },
}

export const myProjectSlice = createSlice({
  name: 'myProject',
  initialState,
  reducers: {
    setTempRoles: (state, action: PayloadAction<RoleProject>) => {
      state.tempRoles = [...state.tempRoles, action.payload]
    },
    updateTempRole: (state, action: PayloadAction<Partial<RoleProject>>) => {
      const index = state.tempRoles.findIndex(
        role => role.roleId === action.payload.roleId,
      )
      if (index !== -1) {
        state.tempRoles[index] = {
          ...state.tempRoles[index],
          ...action.payload,
        }
      }
    },

    deleteTempRole: (state, action: PayloadAction<string>) => {
      state.tempRoles = state.tempRoles.filter(
        role => role.roleId !== action.payload,
      )
    },
    resetTempRoles: state => {
      state.tempRoles = initialState.tempRoles
    },
    setAssignedUser: (state, action: PayloadAction<Partial<AssignedUser>>) => {
      state.assignedUser = { ...state.assignedUser, ...action.payload }
    },
    resetAssignedUser: state => {
      state.assignedUser = initialState.assignedUser
    },
    deleteAssignedUser: (state, action: PayloadAction<string>) => {
      state.assignedUsers = state.assignedUsers.filter(
        user => user.id !== action.payload,
      )
    },
    setAssignedUsers: (state, action: PayloadAction<AssignedUser[]>) => {
      state.assignedUsers = [...state.assignedUsers, ...action.payload]
      state.assignedUserList = initialState.assignedUserList
    },
    setAssignedUserList: (state, action: PayloadAction<AssignedUser>) => {
      state.assignedUserList = [...state.assignedUserList, action.payload]
    },
    resetAssignedUserList: state => {
      state.assignedUserList = initialState.assignedUserList
    },
  },
})

export const { actions: myProjectActions, reducer: myProjectReducer } =
  myProjectSlice
