import { ButtonHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export type ButtonKind = 'filled' | 'text' | 'outline'
export type ButtonSize = 'small' | 'medium'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  kind: ButtonKind
  size: ButtonSize
  className?: string
}

export const Button = ({
  children,
  type = 'button',
  kind,
  size,
  disabled = false,
  onClick,
  className,
  ...rest
}: ButtonProps) => {
  const baseStyles =
    'font-semibold transition-all duration-150 ease-linear py-1 disabled:cursor-not-allowed hover:cursor-pointer'

  const kindStyles = {
    filled:
      'bg-primary-grey text-primary-white rounded-full hover:bg-btnMedium-hover active:bg-btnMedium-pressed disabled:bg-primary-grey',
    text: 'bg-transparent text-primary-grey rounded-full hover:bg-primary-black/5 active:bg-primary-black/10 disabled:bg-primary-white',
    outline:
      'border border-primary-black/10 rounded hover:bg-primary-black/5 active:bg-primary-black/10 disabled:bg-primary-white',
  }

  const sizeStyles = {
    medium: 'px-2 text-sm',
    small: 'px-1.5 text-xs',
  }

  const combinedStyles = twMerge(
    baseStyles,
    kindStyles[kind],
    sizeStyles[size],
    disabled && 'opacity-50',
    className,
  )

  return (
    <button
      {...rest}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={combinedStyles}
    >
      {children}
    </button>
  )
}
