import { format, addWeeks, startOfWeek, endOfWeek, getISOWeek } from 'date-fns'
import { convertToDate, moveOneMonthBack } from 'utils'

export const buildWeeks = (months: number, startDate?: string | null) => {
  const weeksPerMonth = 4.33 // Average weeks per month
  const totalWeeks = Math.ceil(months * weeksPerMonth) // Approximate number of weeks for the given months
  const start = startDate
    ? convertToDate(moveOneMonthBack(startDate))
    : new Date()
  let currentWeekStart = startOfWeek(start, { weekStartsOn: 5 }) // Start of the current week (Friday)

  const weekData = []

  for (let i = 1; i <= totalWeeks; i++) {
    const startDate = format(currentWeekStart, 'yyyy-MM-dd')
    const endDate = format(
      endOfWeek(currentWeekStart, { weekStartsOn: 5 }),
      'yyyy-MM-dd',
    )
    const isoWeekNumber = getISOWeek(currentWeekStart)
    weekData.push({
      title: `${format(currentWeekStart, 'M/d')} - ${format(endOfWeek(currentWeekStart, { weekStartsOn: 5 }), 'M/d')} (W${isoWeekNumber})`,
      value: isoWeekNumber.toString(),
      startDate: startDate,
      endDate: endDate,
    })

    // Move to the next week
    currentWeekStart = addWeeks(currentWeekStart, 1)
  }
  console.log('weekData', months, startDate, weekData)
  return weekData
}
