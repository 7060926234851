import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AdditionalDetails,
  ProjectDetails,
  ProjectDetailsErrors,
  ProjectState,
  ProjectStep,
  RoleProject,
} from 'types'
import { createEmptyProjectRole } from 'utils'

const initialState: ProjectState = {
  currentStep: ProjectStep.PROJECT_DETAILS,
  projectDetails: {
    projectName: '',
    primaryGenre: '',
    secondaryGenre: [],
    expectedRating: '',
    expectedBudget: '',
  },
  projectDetailsErrors: {
    projectName: false,
    primaryGenre: false,
    secondaryGenre: false,
    expectedRating: false,
    expectedBudget: false,
  },
  additionalDetails: {
    runTime: { hours: '', minutes: '' },
    releaseDate: undefined,
    releasePattern: '',
    expectedDirector: '',
    expectedWriter: '',
    expectedRating: '',
    primaryGenre: '',
    rating: '',
    secondaryGenre: [],
  },
  projectLogline: '',
  roles: [createEmptyProjectRole()],
  completedSteps: Object.values(ProjectStep).reduce(
    (acc, step) => {
      acc[step] = false
      return acc
    },
    {} as Record<ProjectStep, boolean>,
  ),
}

const newProjectSlice = createSlice({
  name: 'newProject',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<ProjectStep>) => {
      state.currentStep = action.payload
    },
    setProjectDetails: (
      state,
      action: PayloadAction<Partial<ProjectDetails>>,
    ) => {
      state.projectDetails = { ...state.projectDetails, ...action.payload }
    },
    setProjectDetailsErrors: (
      state,
      action: PayloadAction<Partial<ProjectDetailsErrors>>,
    ) => {
      state.projectDetailsErrors = {
        ...state.projectDetailsErrors,
        ...action.payload,
      }
    },
    setAdditionalDetails: (
      state,
      action: PayloadAction<Partial<AdditionalDetails>>,
    ) => {
      state.additionalDetails = {
        ...state.additionalDetails,
        ...action.payload,
      }
    },
    resetAdditionalDetails: state => {
      state.additionalDetails = initialState.additionalDetails
    },
    setProjectLogline: (state, action: PayloadAction<string>) => {
      state.projectLogline = action.payload
    },
    resetProjectLogline: state => {
      state.projectLogline = initialState.projectLogline
    },
    addRoles: (state, action: PayloadAction<RoleProject>) => {
      state.roles = [...state.roles, { ...action.payload }]
    },
    updateRole: (state, action: PayloadAction<Partial<RoleProject>>) => {
      const index = state.roles.findIndex(
        role => role.roleId === action.payload.roleId,
      )
      if (index !== -1) {
        state.roles[index] = { ...state.roles[index], ...action.payload }
      }
    },
    deleteRole: (state, action: PayloadAction<string>) => {
      state.roles = state.roles.filter(role => role.roleId !== action.payload)
    },
    setStepCompleted: (state, action: PayloadAction<ProjectStep>) => {
      state.completedSteps[action.payload] = true
    },
    resetProjectState: state => {
      state.projectDetails = initialState.projectDetails
      state.roles = initialState.roles
      state.additionalDetails = initialState.additionalDetails
      state.currentStep = initialState.currentStep
      state.projectLogline = initialState.projectLogline
      state.completedSteps = initialState.completedSteps
      state.projectDetailsErrors = initialState.projectDetailsErrors
    },
  },
})

export const { actions: projectActions, reducer: projectReducer } =
  newProjectSlice
