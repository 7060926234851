import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { tvManagementActions } from '../slices/tvManagementSlice'

export const useTVManagementActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(tvManagementActions, dispatch),
    [dispatch],
  )
}
