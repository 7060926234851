import { ReactNode, useCallback } from 'react'
import { MgmtTableCell, MgmtTable } from 'modules/management/shared'
import { type TVFilmCompaniesTableTypes } from './types'
import { TVFilmCompanyData, TVFilmCompanyColKey } from '../../types'
import { twMerge } from 'tailwind-merge'
import { useToggle } from 'hooks'
import { columns } from './columns'
import {
  tVFilmCompanyLabelByType,
  tVFilmDistributorLabelByType,
} from '../../config'
import { TVFilmCompanyEditDialog } from '../TVFilmCompanyEditDialog'
import { EditEntryCellContent } from '../cellContent'

type TVFilmCompaniesTableProps = {
  rows: TVFilmCompanyData[]
  footer: ReactNode
  loading: boolean
  fetching: boolean
  sortConfig: TVFilmCompaniesTableTypes['SortConfig']
  onSortChange: TVFilmCompaniesTableTypes['OnSortChange']
}

export const TVFilmCompaniesTable = ({
  rows,
  footer,
  loading,
  fetching,
  sortConfig,
  onSortChange,
}: TVFilmCompaniesTableProps) => {
  const [editDialogState, editDialogStateControls] =
    useToggle<TVFilmCompanyData>()

  const cellRenderer: TVFilmCompaniesTableTypes['CellRenderer'] = useCallback(
    ({ column, cellContent }) => (
      <MgmtTableCell
        column={column}
        className={twMerge(column.key === 'name' && 'font-medium')}
      >
        {cellContent}
      </MgmtTableCell>
    ),
    [],
  )

  const rowKeyGetter: TVFilmCompaniesTableTypes['RowKeyGetter'] = useCallback(
    ({ row }) => row.id,
    [],
  )

  const cellContentGetter: TVFilmCompaniesTableTypes['CellContentGetter'] =
    useCallback(
      ({ column, row, getCellValue }) => {
        switch (column.key) {
          case TVFilmCompanyColKey.EditEntry: {
            return (
              <EditEntryCellContent
                onClick={() => editDialogStateControls.activate(row)}
              />
            )
          }
          case TVFilmCompanyColKey.CompanyType: {
            return getCellValue(
              column.key,
              value => tVFilmCompanyLabelByType[value],
            )
          }
          case TVFilmCompanyColKey.DistributorType: {
            return getCellValue(
              column.key,
              value => tVFilmDistributorLabelByType[value],
            )
          }
          default:
            return getCellValue(column.key)
        }
      },
      [editDialogStateControls],
    )

  return (
    <>
      <MgmtTable
        rows={rows}
        columns={columns}
        footer={footer}
        loading={loading}
        fetching={fetching}
        sortConfig={sortConfig}
        onSortChange={onSortChange}
        cellRenderer={cellRenderer}
        rowKeyGetter={rowKeyGetter}
        cellContentGetter={cellContentGetter}
      />
      <TVFilmCompanyEditDialog
        dialogState={editDialogState}
        dialogStateControls={editDialogStateControls}
      />
    </>
  )
}
