import { IEvent, ReleaseWeek } from 'types'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export interface GroupedWeekItems {
  [weekId: string]: IEvent[]
}

const isDateInRange = (
  date: string,
  startDate: string,
  endDate: string,
): boolean => {
  const targetDate = new Date(date)
  const start = new Date(startDate)
  const end = new Date(endDate)
  return targetDate >= start && targetDate <= end
}

export const groupWeekItems = (
  weekItems: IEvent[],
  weeks: ReleaseWeek[] | null,
): GroupedWeekItems => {
  const grouped: GroupedWeekItems = {}
  if (!weeks) return grouped

  // Initialize cumulative event count
  let cumulativeEventCount = 0
  let lastWeekDummyCount = 0
  weeks.forEach((week, index) => {
    // Filter events for the current week
    const weekEvents = weekItems.filter(
      event =>
        event.startDay &&
        isDateInRange(event.startDay, week.startDate, week.endDate),
    )

    // Get the number of events for the current week
    const weekEventCount = weekEvents.length
    if (lastWeekDummyCount >= weekEventCount) {
      cumulativeEventCount = 0
    }

    // Create a dummy event for the current week based on the previous week's total
    // for avoid the overlapping of events in UI display
    const dummyEvents: IEvent[] = Array.from(
      { length: cumulativeEventCount },
      (_, i) => ({
        id: `dummy-${week.value}-${i}`, // Unique ID for each dummy event
        name: `Dummy Event ${i + 1}`, // Dummy event name
        startDay: week.startDate, // Use the start date of the week
        endDay: week.endDate, // Use the end date of the week
        // Add other required properties for IEvent as necessary
        dummy: true, // Flag to identify dummy events
      }),
    )
    lastWeekDummyCount = dummyEvents.length
    cumulativeEventCount += weekEventCount

    // Store the dummy events followed by the current week's events
    grouped[week.value] = [...dummyEvents, ...weekEvents]
  })

  return grouped
}

export const isDateBetweenInRange = (
  date: string,
  startDate: string,
  endDate: string,
): boolean => {
  const targetDate = dayjs(date)
  const start = dayjs(startDate).subtract(2, 'day')
  const end = dayjs(endDate)
  return targetDate.isBetween(start, end, null, '[]')
}

export const filterEventsByDateRange = (
  events: IEvent[],
  startDate: string,
  endDate: string,
): IEvent[] => {
  return events.filter(
    event =>
      event.startDay &&
      isDateBetweenInRange(event.startDay, startDate, endDate),
  )
}
