import { Input } from 'components'
import { Search } from 'components/ui/icons'
import { Colors } from 'constnants'

interface MyProjectActiveProjectHeaderProps {
  amountProjects: string | undefined
}

export const MyProjectActiveProjectHeader = ({
  amountProjects,
}: MyProjectActiveProjectHeaderProps) => {
  return (
    <div className='flex w-full items-center justify-between'>
      <span className='text-xs font-semibold text-primary-black/50'>
        {amountProjects}
      </span>
      <div className='w-[200px]'>
        <Input
          placeholder='Name Lookup'
          leftIcon={<Search fill={Colors.BASE_ICON} width={16} height={16} />}
        />
      </div>
    </div>
  )
}
