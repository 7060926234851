import { ChangeEvent, Fragment, useState } from 'react'
import {
  EditModal,
  EditModalProps,
  TypeSelector,
  RunTime,
  Input,
  Select,
  MovieDetails,
  MovieDetailsGenreSection,
} from 'components'
import { MovieDetailsKey, MyProject, OptionType } from 'types'
import {
  expectedMpaaRating,
  preparedExpectedBudget,
  primaryGenre,
  releasePatternMenu,
  secondaryGenre,
} from 'constnants'
import { useUpdatedProjectMutation } from 'store/api'
import { toastError } from 'utils'

interface EditMovieDetailsModalProps extends EditModalProps {
  currentEditItem: MovieDetails | null
  project: MyProject | undefined
  movieDetails: MovieDetails[]
}

interface GenreSelection {
  primaryGenre: string
  secondaryGenre: string[]
}

export const EditMovieDetailsModal = ({
  isOpen,
  handleClose,
  currentInputValue,
  onChange,
  currentEditItem,
  project,
  movieDetails,
  title,
}: EditMovieDetailsModalProps) => {
  const [inputValues, setInputValues] = useState({
    director: project?.director,
    writer: project?.writer,
    hours: project?.runTime.hours,
    minutes: project?.runTime.minutes,
  })
  const [selectGenre, setSelectGenre] = useState<GenreSelection>({
    primaryGenre: project?.genres.primaryGenre ?? '',
    secondaryGenre: project?.genres.secondaryGenre ?? [],
  })

  const [selectedValues, setSelectedValues] = useState({
    budget: project?.budget,
    rating: project?.rating,
    releasePattern: project?.releasePattern,
    releaseDate: project?.releaseDate,
  })
  const [updatedProject] = useUpdatedProjectMutation()

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputValues,
  ) => {
    const { value } = e.target
    setInputValues(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSelectionChange = (
    key: keyof typeof selectedValues,
    value: OptionType | string | number,
  ) => {
    if (key === MovieDetailsKey.BUDGET) {
      setSelectedValues(prevState => ({
        ...prevState,
        [key]:
          preparedExpectedBudget.find(budget => budget.id === value) ??
          preparedExpectedBudget[0],
      }))
    } else {
      setSelectedValues(prevState => ({ ...prevState, [key]: value }))
    }
  }

  const handleProjectDetailsChange = (
    key: keyof typeof selectGenre,
    genreId: string,
  ) => {
    if (key === 'secondaryGenre') {
      if (selectGenre.secondaryGenre.includes(genreId)) {
        const updatedGenres = selectGenre.secondaryGenre.filter(
          genre => genre !== genreId,
        )

        setSelectGenre({
          ...selectGenre,
          secondaryGenre: updatedGenres,
        })
      } else if (selectGenre.secondaryGenre.length < 2) {
        setSelectGenre({
          ...selectGenre,
          secondaryGenre: [...selectGenre.secondaryGenre, genreId],
        })
      }
    } else {
      setSelectGenre({
        ...selectGenre,
        primaryGenre: genreId,
      })
    }
  }

  const handleSaveChanges = () => {
    try {
      updatedProject({
        id: project!.projectId,
        project: {
          name: project?.projectName,
          phase_status: 'InDevelopment',
          test_status: 'New',
          budget_id: selectedValues.budget?.name ?? '',
          additional_details: {
            runTime: {
              hours: inputValues.hours || '',
              minutes: inputValues.minutes || '',
            },
            releaseDate: selectedValues.releaseDate as string,
            releasePattern: selectedValues?.releasePattern ?? '',
            expectedDirector: inputValues.director ?? '',
            expectedWriter: inputValues.writer ?? '',
            expectedRating: selectedValues.rating?.id ?? 'r',
            primaryGenre: selectGenre.primaryGenre ?? '',
            secondaryGenre: selectGenre.secondaryGenre ?? [],
          },
        },
      })
        .unwrap()
        .then(() => handleClose())
    } catch (e) {
      const { error, data } = e as { data?: any; error?: string }
      toastError(data, error)
      handleClose()
    }
  }

  return (
    <EditModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      onChange={onChange}
      currentInputValue={currentInputValue}
      maxWidth={currentEditItem?.maxWidth}
      handleSave={handleSaveChanges}
    >
      {movieDetails.map(detail => (
        <Fragment key={detail.id}>
          {currentEditItem?.id === detail.id && (
            <>
              {detail.id === MovieDetailsKey.GENRE && (
                <div>
                  <MovieDetailsGenreSection
                    title='Primary Genre'
                    descriptions='Select 1 genre'
                    genres={primaryGenre}
                    selectedGenres={selectGenre.primaryGenre}
                    handleChange={genre =>
                      handleProjectDetailsChange('primaryGenre', genre)
                    }
                  />
                  <MovieDetailsGenreSection
                    title='Secondary Genre'
                    descriptions='Select up to 2 genres'
                    genres={secondaryGenre}
                    selectedGenres={selectGenre.secondaryGenre}
                    handleChange={genre =>
                      handleProjectDetailsChange('secondaryGenre', genre)
                    }
                  />
                </div>
              )}
              {detail.id === MovieDetailsKey.RATING && (
                <div className='flex space-x-1 pb-3'>
                  {expectedMpaaRating.map(rate => {
                    return (
                      <TypeSelector
                        key={rate.id}
                        title={rate.name}
                        kind='oval'
                        size='large'
                        isSelected={selectedValues.rating?.value === rate.name}
                        onClick={() =>
                          handleSelectionChange('rating', {
                            id: rate.id,
                            value: rate.name,
                            label: rate.name,
                          })
                        }
                      />
                    )
                  })}
                </div>
              )}
              {detail.id === MovieDetailsKey.RUN_TIME && (
                <div className='pb-3'>
                  <RunTime
                    hoursValue={inputValues.hours ?? ''}
                    minutesValue={inputValues.minutes ?? ''}
                    inputFirstWrapperClassName='h-10 w-32'
                    inputSecondWrapperClassName='h-10 w-32  '
                    handleRunTimeChange={(value, key) =>
                      handleInputChange(
                        value as ChangeEvent<HTMLInputElement>,
                        key,
                      )
                    }
                  />
                </div>
              )}
              {detail.id === MovieDetailsKey.BUDGET && (
                <div className='flex flex-wrap gap-1 pb-3'>
                  {preparedExpectedBudget.map(budget => {
                    return (
                      <TypeSelector
                        key={budget.id}
                        title={budget.name ?? ''}
                        kind='rectangle'
                        size='large'
                        isSelected={selectedValues.budget?.name === budget.name}
                        onClick={() =>
                          handleSelectionChange('budget', budget.id)
                        }
                      />
                    )
                  })}
                </div>
              )}
              {detail.id === MovieDetailsKey.DIRECTOR && (
                <div className='pb-3'>
                  <span className='-mt-3 block pb-3 text-sm text-primary-grey'>
                    {detail.descriptions}
                  </span>
                  <Input
                    value={inputValues.director}
                    containerWrapperClassName='w-full'
                    inputClassName='h-10 pl-2'
                    onChange={e => handleInputChange(e, 'director')}
                  />
                </div>
              )}
              {detail.id === MovieDetailsKey.WRITER && (
                <div className='pb-3'>
                  <span className='-mt-3 block pb-3 text-sm text-primary-grey'>
                    {detail.descriptions}
                  </span>
                  <Input
                    value={inputValues.writer}
                    containerWrapperClassName='w-full'
                    inputClassName='h-10 pl-2'
                    onChange={e => handleInputChange(e, 'writer')}
                  />
                </div>
              )}
              {detail.id === MovieDetailsKey.RELEASE_PATTERN && (
                <Select
                  value={selectedValues.releasePattern ?? ''}
                  options={releasePatternMenu}
                  buttonClass='py-2'
                  hasRadioButton={false}
                  containerClass='pb-3'
                  wrapperOptionClass='-mt-1.5'
                  handleClick={value =>
                    handleSelectionChange('releasePattern', value.value)
                  }
                />
              )}
            </>
          )}
        </Fragment>
      ))}
    </EditModal>
  )
}
