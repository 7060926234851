import { BaseModal } from 'components'
import {
  TVFilmCompanyForm,
  type TVFilmCompanyFormData,
} from '../TVFilmCompanyForm'
import { ToggleHookStateControls, useToggle } from 'hooks'
import { ReactNode, useCallback } from 'react'
import { useAddTVFilmCompanyMutation } from 'store/api/mock-api'

interface TVFilmCompanyAddDialogProps {
  renderTrigger: (dialogControls: ToggleHookStateControls) => ReactNode
}

export const TVFilmCompanyAddDialog = ({
  renderTrigger,
}: TVFilmCompanyAddDialogProps) => {
  const [dialogState, dialogStateControls] = useToggle()
  const [createCompany] = useAddTVFilmCompanyMutation()

  const handleSubmit = useCallback(
    async (data: TVFilmCompanyFormData) => {
      try {
        await createCompany(data)
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [createCompany, dialogStateControls],
  )

  return (
    <>
      {renderTrigger(dialogStateControls)}
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'Add New Company'}
        maxWidth='450px'
      >
        <TVFilmCompanyForm
          submitButtonLabel='Add New Company'
          onClose={dialogStateControls.deactivate}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    </>
  )
}
