import { IMyProjectRecentActivities } from 'types'

export const recentActivities: IMyProjectRecentActivities[] = [
  {
    name: 'Ronan',
    id: '1',
    lastActivity: '4 minutes ago',
  },
  {
    name: 'Freaky Friday',
    id: '2',
    lastActivity: '12 hours ago',
  },
  {
    name: 'Doctor Strange in the Mulriverse of Madness',
    id: '3',
    lastActivity: '01/18/24',
    newResults: true,
  },
]
