import { OptionType } from 'types'

type ResultLookupSuccessProps<TOptionValue = string> = {
  autocompleteData: OptionType<TOptionValue>[]
  handleAutocompleteClick: (option: OptionType<TOptionValue>) => void
  setIsComponentVisible: (value: boolean) => void
}

const ResultLookupSuccess = <TOptionValue,>({
  autocompleteData = [],
  handleAutocompleteClick,
  setIsComponentVisible,
}: ResultLookupSuccessProps<TOptionValue>) => {
  return (
    <>
      {autocompleteData.map((item, index) => (
        <div
          key={`${item.label}_${index}`}
          className='cursor-pointer rounded p-1 text-sm text-primary-black hover:bg-primary-black/5'
          onClick={() => {
            handleAutocompleteClick({
              id: item.id,
              label: item.label,
              value: item.value,
            })
            setIsComponentVisible(false)
          }}
        >
          {item.label}
        </div>
      ))}
    </>
  )
}

export default ResultLookupSuccess
