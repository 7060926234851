import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { OptionType, SelectedFilters, SelectState } from 'types'
import { isEmptyArrsInObj } from 'utils'
import {
  percentagesOptions,
  topOptions,
  initialOptions,
} from 'constnants/selectOptions'
import { useSelectOptions } from 'hooks'
import { dataTalent } from 'constnants'
import { dataAudience } from 'constnants/audienceOptions'

interface FilterData {
  id: string
  name: string
  options: OptionType[]
}

export const useTalentHeaderData = (dataParam: FilterData[] | undefined) => {
  const data = dataParam ? dataParam : dataTalent.concat(dataAudience)
  const location = useLocation()

  const [totalTalentFilters, setTotalTalentFilters] = useState<number>(0)

  const [totalAudienceFilters, setTotalAudienceFilters] = useState<number>(0)

  const [selectedFiltersTalent, setSelectedFiltersTalent] =
    useState<SelectedFilters>({})

  const [selectedFiltersAudience, setSelectedFiltersAudience] =
    useState<SelectedFilters>({})

  const selectedFilterPresetSearchRef = useRef<string | null>('')

  const [isInitialized, setIsInitialized] = useState(false)
  const [nameLookup, setNameLookup] = useState('')

  const { selects, handleSelectOptions, setSelects } =
    useSelectOptions(initialOptions)

  const preparedStructure = useMemo(() => {
    const structure: SelectedFilters = { ageSegment: [] }
    data?.forEach(option => {
      structure[option.id] = []
    })

    return structure
  }, [data])

  const audiencePreparedStructure = useMemo(
    () => ({ ...preparedStructure, general: ['ALL'] }),
    [data],
  )

  const initialSelectsState: SelectState = useMemo(
    () => ({
      percentages: percentagesOptions[2].value,
      top: topOptions[1].value,
    }),
    [],
  )

  useEffect(() => {
    if (
      isEmptyArrsInObj(selectedFiltersTalent) &&
      isEmptyArrsInObj(selectedFiltersAudience)
    ) {
      selectedFilterPresetSearchRef.current = ''
    }
  }, [selectedFiltersTalent, selectedFiltersAudience]) // Исправлен массив зависимостей

  useEffect(() => {
    updateTalentFiltersFromUrl()
    updateAudienceFiltersFromUrl()
    updateFilterPresetSearchFromUrl()
    updateNameLookupFromUrl()
  }, [location.search])

  useEffect(() => {
    setSelectedFiltersTalent(preparedStructure)
    setSelectedFiltersAudience(audiencePreparedStructure)
    setSelects(initialSelectsState)
    setIsInitialized(true)
    setNameLookup('')
  }, [])

  const handleFilterChangeTalent = useCallback((selected: SelectedFilters) => {
    setSelectedFiltersTalent(prevFilters => ({
      ...prevFilters,
      ...selected,
    }))
  }, [])

  const handleFilterChangeAudience = useCallback(
    (selected: SelectedFilters) => {
      setSelectedFiltersAudience({
        ...preparedStructure,
        ...selected,
      })
    },
    [],
  )

  const handleResetAllFilters = useCallback(() => {
    setSelectedFiltersTalent(preparedStructure)
    setSelectedFiltersAudience(audiencePreparedStructure)

    selectedFilterPresetSearchRef.current = ''

    setSelects(initialSelectsState)
  }, [
    preparedStructure,
    audiencePreparedStructure,
    initialSelectsState,
    setSelects,
  ])

  const handleResetFiltersTalent = useCallback(() => {
    setSelectedFiltersTalent(preparedStructure)
    if (isEmptyArrsInObj(selectedFiltersAudience)) {
      selectedFilterPresetSearchRef.current = ''
    }
  }, [preparedStructure])

  const handleResetFiltersAudience = useCallback(() => {
    setSelectedFiltersAudience(audiencePreparedStructure)

    if (isEmptyArrsInObj(selectedFiltersTalent)) {
      selectedFilterPresetSearchRef.current = ''
    }
  }, [audiencePreparedStructure])

  useEffect(() => {
    const totalFiltersTalent = Object.values(selectedFiltersTalent).reduce(
      (total, current) => total + current.length,
      0,
    )
    const totalFiltersAudience = Object.values(selectedFiltersAudience).reduce(
      (total, current) => total + current.length,
      0,
    )
    setTotalTalentFilters(totalFiltersTalent)
    setTotalAudienceFilters(totalFiltersAudience)
  }, [selectedFiltersTalent, selectedFiltersAudience])

  const hasChanges = useMemo(() => {
    if (!isInitialized) return false

    const noTalentFilterChanges =
      JSON.stringify(selectedFiltersTalent) ===
      JSON.stringify(preparedStructure)
    const noAudienceFilterChanges =
      JSON.stringify(selectedFiltersAudience) ===
      JSON.stringify(audiencePreparedStructure)
    const noNameLookupChanges = nameLookup === ''

    return !(
      noTalentFilterChanges &&
      noAudienceFilterChanges &&
      noNameLookupChanges
    )
  }, [
    selectedFiltersTalent,
    selectedFiltersAudience,
    preparedStructure,
    audiencePreparedStructure,
    isInitialized,
    nameLookup,
  ])

  const updateFilterPresetSearchFromUrl = () => {
    const searchParams = new URLSearchParams(location.search)

    const paramPresetSearch = searchParams.get('presetSearch')

    const presetSearch = paramPresetSearch ? paramPresetSearch : ''

    selectedFilterPresetSearchRef.current = presetSearch
  }

  const updateTalentFiltersFromUrl = () => {
    const searchParams = new URLSearchParams(location.search)

    const params: SelectedFilters = {
      gender: [],
      age: [],
      race: [],
    }

    const talentGender = searchParams.getAll('talentGender')
    const talentAge = searchParams.getAll('talentAge')
    const talentRace = searchParams.getAll('talentRace')

    params.gender = talentGender.length > 0 ? talentGender : []
    params.age = talentAge.length > 0 ? talentAge : []
    params.race = talentRace.length > 0 ? talentRace : []

    setSelectedFiltersTalent({
      ...preparedStructure,
      ...params,
    })
  }

  const updateAudienceFiltersFromUrl = () => {
    const searchParams = new URLSearchParams(location.search)

    const audienceFilterParam = searchParams.get('audienceFilter')

    setSelectedFiltersAudience({
      ...audiencePreparedStructure,
      ...(audienceFilterParam ? mapAudienceFromUrl(audienceFilterParam) : {}),
    })
  }
  const updateNameLookupFromUrl = () => {
    const searchParams = new URLSearchParams(location.search)
    const talentNameParam = searchParams.get('talentName') ?? ''
    setNameLookup(talentNameParam)
  }

  function mapAudienceFromUrl(urlParam: string): SelectedFilters {
    const obj: SelectedFilters = {
      gender: [],
      age: [],
      race: [],
      general: [],
      black: [],
      hispanic: [],
      white: [],
      identity: [],
      theaterfreq: [],
      ageSegment: [],
    }

    const categoryMapping: { [key: string]: keyof SelectedFilters } = {
      GENDER_: 'general',
      AGE_: 'general',
      RACE_BLACK: 'black',
      RACE_WHITE: 'white',
      RACE_HISPANIC: 'hispanic',
      IDENTITY_: 'identity',
      THEATER_FREQ_: 'theaterfreq',
      AGE_SEGMENT_: 'ageSegment',
    }

    for (const prefix in categoryMapping) {
      if (urlParam.startsWith(prefix)) {
        obj[categoryMapping[prefix]].push(urlParam)
        return obj
      }
    }

    // Default to 'general' if no other category matched
    obj.general.push(urlParam)
    return obj
  }

  return {
    handleResetAllFilters,
    handleFilterChangeTalent,
    handleFilterChangeAudience,
    selects,
    selectedFiltersTalent,
    selectedFilterPresetSearch: selectedFilterPresetSearchRef.current,
    selectedFiltersAudience,
    totalTalentFilters,
    totalAudienceFilters,
    handleSelectOptions,
    handleResetFiltersTalent,
    handleResetFiltersAudience,
    hasChanges,
    nameLookup,
    setNameLookup,
  }
}
