import { GetTalentSelectedSearchParams, TalentSelectedSearch } from 'types'
import { isEmptyArrsInObj } from './isEmptyArrsInObj'

export const getTalentSelectedSearch = ({
  searchId,
  searchName,
  searchParameters,
  selectedProject,
  selectedRole,
}: GetTalentSelectedSearchParams) => {
  const selectedSearch: TalentSelectedSearch = {
    ...searchParameters,
    selectedProject,
    selectedRole,
  }

  if (selectedSearch.audienceFilter || !isEmptyArrsInObj(searchParameters)) {
    selectedSearch.presetSearch = `${searchName}_${searchId}`
  }

  return selectedSearch
}
