export const TalentBiography = ({
  biography,
  birthYear,
  age,
  height,
}: {
  biography: string
  birthYear: string | number
  age: number | null
  height: string | number
}) => (
  <>
    <p className='text-xs text-primary-grey'>{biography}</p>
    <div className='mt-2'>
      <p className='text-xs text-primary-grey'>{`Born: ${birthYear || ''} (age ${age ?? ''} years)`}</p>
      <p className='text-xs text-primary-grey'>{`Height: ${height || ''}`}</p>
    </div>
  </>
)
