import { twMerge } from 'tailwind-merge'
import { Colors } from 'constnants'
import { CaretDown, CaretUp } from 'components/ui/icons'
import { DirectionSort, SortOrderKey } from 'types'

interface VerticalTableTextProps {
  vertical?: boolean | undefined
  title: string
  titleClass?: string
  containerClass?: string
  selectItem?: boolean
  hasIcon?: boolean
  directionSort?: SortOrderKey
}

export const VerticalTableText = ({
  vertical,
  title,
  titleClass,
  containerClass,
  selectItem,
  hasIcon,
  directionSort = DirectionSort.ASC,
}: VerticalTableTextProps) => {
  return (
    <div
      className={twMerge(
        'group relative flex items-center justify-center align-middle',
        containerClass,
      )}
    >
      <div
        className={twMerge(
          'text-xss text-primary-grey group-hover:opacity-70',
          vertical
            ? '[writing-mode:horizontal-tb]'
            : 'h-[72px] rotate-180 text-start [writing-mode:vertical-rl]',
          selectItem && 'font-medium text-primary-black',
          titleClass,
        )}
      >
        {title}
      </div>

      {hasIcon && (
        <div className={`pl-1 ${selectItem ? '' : 'invisible'}`}>
          {selectItem && directionSort === DirectionSort.DESC ? (
            <CaretDown fill={Colors.ICON_BRAND} width={16} height={16} />
          ) : (
            <CaretUp fill={Colors.ICON_BRAND} width={16} height={16} />
          )}
        </div>
      )}
    </div>
  )
}
