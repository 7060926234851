import { ChartCategory, ChartDetailAttributes } from 'types'

const attributes: ChartCategory[] = [
  {
    talentId: '1',
    id: 'awareness',
    name: 'Awareness',
    chartDetails: [
      {
        date: '02/03/20',
        value: '34',
      },
      {
        date: '12/04/20',
        value: '10',
      },
      {
        date: '03/04/20',
        value: '40',
      },
      {
        date: '03/04/20',
        value: '55',
      },
      {
        date: '01/05/20',
        value: '34',
      },
      {
        date: '12/04/21',
        value: '20',
      },
      {
        date: '11/04/21',
        value: '90',
      },
      {
        date: '04/06/21',
        value: '55',
      },
      {
        date: '02/03/21',
        value: '34',
      },
      {
        date: '12/04/22',
        value: '20',
      },
      {
        date: '11/04/22',
        value: '90',
      },
      {
        date: '03/04/22',
        value: '55',
      },
      {
        date: '12/04/23',
        value: '20',
      },
      {
        date: '09/04/23',
        value: '90',
      },
      {
        date: '03/04/23',
        value: '55',
      },
    ],
  },
  {
    talentId: '1',
    id: 'fandom',
    name: 'Fandom',
    chartDetails: [
      {
        date: '02/03/20',
        value: '90',
      },
      {
        date: '12/04/21',
        value: '80',
      },
      {
        date: '11/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '10',
      },
      {
        date: '03/04/24',
        value: '16',
      },
    ],
  },
  {
    talentId: '1',
    id: 'willSeeTheater',
    name: 'Will See (Theater)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'willSeeStreaming',
    name: 'Will See (Streaming)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'authentic',
    name: 'Authentic',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'funny',
    name: 'Funny',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'likable',
    name: 'Likable',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'inspirational',
    name: 'Inspirational',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'trendy',
    name: 'Trendy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'goodActor',
    name: 'Good Actor',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'sexy',
    name: 'Sexy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'overexposed',
    name: 'Overexposed',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'overrated',
    name: 'Overrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'underrated',
    name: 'Underrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
]

const socialMedia: ChartCategory[] = [
  {
    talentId: '1',
    id: 'allFollowers',
    name: 'All Followers',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'velocitySocial',
    name: 'Velocity (0.0)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'velocityPercentageSocial',
    name: 'Velocity %',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'mentions',
    name: 'Mentions',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'sentiment',
    name: 'Sentiment',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '1',
    id: 'reach',
    name: 'Reach',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
]

const attributes1: ChartCategory[] = [
  {
    talentId: '2',
    id: 'awareness',
    name: 'Awareness',
    chartDetails: [
      {
        date: '02/03/20',
        value: '70',
      },
      {
        date: '12/04/20',
        value: '20',
      },
      {
        date: '03/04/20',
        value: '50',
      },
      {
        date: '03/04/20',
        value: '30',
      },
      {
        date: '01/05/20',
        value: '20',
      },
      {
        date: '12/04/21',
        value: '13',
      },
      {
        date: '11/04/21',
        value: '30',
      },
      {
        date: '04/06/21',
        value: '18',
      },
      {
        date: '02/03/21',
        value: '10',
      },
      {
        date: '12/04/22',
        value: '23',
      },
      {
        date: '11/04/22',
        value: '33',
      },
      {
        date: '03/04/22',
        value: '33',
      },
      {
        date: '12/04/23',
        value: '21',
      },
      {
        date: '09/04/23',
        value: '77',
      },
      {
        date: '03/04/23',
        value: '90',
      },
    ],
  },
  {
    talentId: '2',
    id: 'fandom',
    name: 'Fandom',
    chartDetails: [
      {
        date: '02/03/20',
        value: '10',
      },
      {
        date: '12/04/21',
        value: '12',
      },
      {
        date: '11/04/22',
        value: '14',
      },
      {
        date: '03/04/23',
        value: '30',
      },
      {
        date: '03/04/24',
        value: '40',
      },
    ],
  },
  {
    talentId: '2',
    id: 'willSeeTheater',
    name: 'Will See (Theater)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '10',
      },
      {
        date: '12/04/21',
        value: '40',
      },
      {
        date: '16/04/22',
        value: '66',
      },
      {
        date: '03/04/23',
        value: '44',
      },
      {
        date: '03/04/24',
        value: '33',
      },
    ],
  },
  {
    talentId: '2',
    id: 'willSeeStreaming',
    name: 'Will See (Streaming)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '34',
      },
      {
        date: '12/04/21',
        value: '44',
      },
      {
        date: '16/04/22',
        value: '33',
      },
      {
        date: '03/04/23',
        value: '10',
      },
      {
        date: '03/04/24',
        value: '90',
      },
    ],
  },
  {
    talentId: '2',
    id: 'authentic',
    name: 'Authentic',
    chartDetails: [
      {
        date: '02/03/20',
        value: '12',
      },
      {
        date: '12/04/21',
        value: '40',
      },
      {
        date: '16/04/22',
        value: '50',
      },
      {
        date: '03/04/23',
        value: '90',
      },
      {
        date: '03/04/24',
        value: '60',
      },
    ],
  },
  {
    talentId: '2',
    id: 'funny',
    name: 'Funny',
    chartDetails: [
      {
        date: '02/03/20',
        value: '30',
      },
      {
        date: '12/04/21',
        value: '40',
      },
      {
        date: '16/04/22',
        value: '50',
      },
      {
        date: '03/04/23',
        value: '30',
      },
      {
        date: '03/04/24',
        value: '12',
      },
    ],
  },
  {
    talentId: '2',
    id: 'likable',
    name: 'Likable',
    chartDetails: [
      {
        date: '02/03/20',
        value: '5',
      },
      {
        date: '12/04/21',
        value: '50',
      },
      {
        date: '16/04/22',
        value: '64',
      },
      {
        date: '03/04/23',
        value: '33',
      },
      {
        date: '03/04/24',
        value: '60',
      },
    ],
  },
  {
    talentId: '2',
    id: 'inspirational',
    name: 'Inspirational',
    chartDetails: [
      {
        date: '02/03/20',
        value: '43',
      },
      {
        date: '12/04/21',
        value: '23',
      },
      {
        date: '16/04/22',
        value: '23',
      },
      {
        date: '03/04/23',
        value: '44',
      },
      {
        date: '03/04/24',
        value: '55',
      },
    ],
  },
  {
    talentId: '2',
    id: 'trendy',
    name: 'Trendy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '45',
      },
      {
        date: '12/04/21',
        value: '34',
      },
      {
        date: '16/04/22',
        value: '43',
      },
      {
        date: '03/04/23',
        value: '54',
      },
      {
        date: '03/04/24',
        value: '54',
      },
    ],
  },
  {
    talentId: '2',
    id: 'goodActor',
    name: 'Good Actor',
    chartDetails: [
      {
        date: '02/03/20',
        value: '44',
      },
      {
        date: '12/04/21',
        value: '55',
      },
      {
        date: '16/04/22',
        value: '76',
      },
      {
        date: '03/04/23',
        value: '89',
      },
      {
        date: '03/04/24',
        value: '84',
      },
    ],
  },
  {
    talentId: '2',
    id: 'sexy',
    name: 'Sexy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '33',
      },
      {
        date: '12/04/21',
        value: '37',
      },
      {
        date: '16/04/22',
        value: '47',
      },
      {
        date: '03/04/23',
        value: '39',
      },
      {
        date: '03/04/24',
        value: '30',
      },
    ],
  },
  {
    talentId: '2',
    id: 'overexposed',
    name: 'Overexposed',
    chartDetails: [
      {
        date: '02/03/20',
        value: '32',
      },
      {
        date: '12/04/21',
        value: '32',
      },
      {
        date: '16/04/22',
        value: '43',
      },
      {
        date: '03/04/23',
        value: '54',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '2',
    id: 'overrated',
    name: 'Overrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '43',
      },
      {
        date: '12/04/21',
        value: '23',
      },
      {
        date: '16/04/22',
        value: '54',
      },
      {
        date: '03/04/23',
        value: '89',
      },
      {
        date: '03/04/24',
        value: '55',
      },
    ],
  },
  {
    talentId: '2',
    id: 'underrated',
    name: 'Underrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '23',
      },
      {
        date: '12/04/21',
        value: '43',
      },
      {
        date: '16/04/22',
        value: '90',
      },
      {
        date: '03/04/23',
        value: '98',
      },
      {
        date: '03/04/24',
        value: '32',
      },
    ],
  },
]

const attributes3: ChartCategory[] = [
  {
    talentId: '3',
    id: 'awareness',
    name: 'Awareness',
    chartDetails: [
      {
        date: '02/03/20',
        value: '43',
      },
      {
        date: '12/04/20',
        value: '90',
      },
      {
        date: '03/04/20',
        value: '10',
      },
      {
        date: '03/04/20',
        value: '15',
      },
      {
        date: '01/05/20',
        value: '24',
      },
      {
        date: '12/04/21',
        value: '4',
      },
      {
        date: '11/04/21',
        value: '43',
      },
      {
        date: '04/06/21',
        value: '90',
      },
      {
        date: '02/03/21',
        value: '32',
      },
      {
        date: '12/04/22',
        value: '30',
      },
      {
        date: '11/04/22',
        value: '80',
      },
      {
        date: '03/04/22',
        value: '43',
      },
      {
        date: '12/04/23',
        value: '34',
      },
      {
        date: '09/04/23',
        value: '12',
      },
      {
        date: '03/04/23',
        value: '32',
      },
    ],
  },
  {
    talentId: '3',
    id: 'fandom',
    name: 'Fandom',
    chartDetails: [
      {
        date: '02/03/20',
        value: '90',
      },
      {
        date: '12/04/21',
        value: '80',
      },
      {
        date: '11/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '10',
      },
      {
        date: '03/04/24',
        value: '16',
      },
    ],
  },
  {
    talentId: '3',
    id: 'willSeeTheater',
    name: 'Will See (Theater)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'willSeeStreaming',
    name: 'Will See (Streaming)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'authentic',
    name: 'Authentic',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'funny',
    name: 'Funny',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'likable',
    name: 'Likable',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'inspirational',
    name: 'Inspirational',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'trendy',
    name: 'Trendy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'goodActor',
    name: 'Good Actor',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'sexy',
    name: 'Sexy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'overexposed',
    name: 'Overexposed',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'overrated',
    name: 'Overrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '3',
    id: 'underrated',
    name: 'Underrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
]

const attributes4: ChartCategory[] = [
  {
    talentId: '4',
    id: 'awareness',
    name: 'Awareness',
    chartDetails: [
      {
        date: '02/03/20',
        value: '90',
      },
      {
        date: '12/04/20',
        value: '93',
      },
      {
        date: '03/04/20',
        value: '24',
      },
      {
        date: '03/04/20',
        value: '43',
      },
      {
        date: '01/05/20',
        value: '54',
      },
      {
        date: '12/04/21',
        value: '43',
      },
      {
        date: '11/04/21',
        value: '93',
      },
      {
        date: '04/06/21',
        value: '40',
      },
      {
        date: '02/03/21',
        value: '85',
      },
      {
        date: '12/04/22',
        value: '54',
      },
      {
        date: '11/04/22',
        value: '23',
      },
      {
        date: '03/04/22',
        value: '54',
      },
      {
        date: '12/04/23',
        value: '65',
      },
      {
        date: '09/04/23',
        value: '76',
      },
      {
        date: '03/04/23',
        value: '6',
      },
    ],
  },
  {
    talentId: '4',
    id: 'fandom',
    name: 'Fandom',
    chartDetails: [
      {
        date: '02/03/20',
        value: '90',
      },
      {
        date: '12/04/21',
        value: '80',
      },
      {
        date: '11/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '10',
      },
      {
        date: '03/04/24',
        value: '16',
      },
    ],
  },
  {
    talentId: '4',
    id: 'willSeeTheater',
    name: 'Will See (Theater)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'willSeeStreaming',
    name: 'Will See (Streaming)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'authentic',
    name: 'Authentic',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'funny',
    name: 'Funny',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'likable',
    name: 'Likable',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'inspirational',
    name: 'Inspirational',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'trendy',
    name: 'Trendy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'goodActor',
    name: 'Good Actor',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'sexy',
    name: 'Sexy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'overexposed',
    name: 'Overexposed',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'overrated',
    name: 'Overrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '4',
    id: 'underrated',
    name: 'Underrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
]

const attributes5: ChartCategory[] = [
  {
    talentId: '5',
    id: 'awareness',
    name: 'Awareness',
    chartDetails: [
      {
        date: '02/03/20',
        value: '30',
      },
      {
        date: '12/04/20',
        value: '23',
      },
      {
        date: '03/04/20',
        value: '24',
      },
      {
        date: '03/04/20',
        value: '33',
      },
      {
        date: '01/05/20',
        value: '24',
      },
      {
        date: '12/04/21',
        value: '23',
      },
      {
        date: '11/04/21',
        value: '34',
      },
      {
        date: '04/06/21',
        value: '67',
      },
      {
        date: '02/03/21',
        value: '85',
      },
      {
        date: '12/04/22',
        value: '54',
      },
      {
        date: '11/04/22',
        value: '23',
      },
      {
        date: '03/04/22',
        value: '54',
      },
      {
        date: '12/04/23',
        value: '65',
      },
      {
        date: '09/04/23',
        value: '76',
      },
      {
        date: '03/04/23',
        value: '6',
      },
    ],
  },
  {
    talentId: '5',
    id: 'fandom',
    name: 'Fandom',
    chartDetails: [
      {
        date: '02/03/20',
        value: '90',
      },
      {
        date: '12/04/21',
        value: '80',
      },
      {
        date: '11/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '10',
      },
      {
        date: '03/04/24',
        value: '16',
      },
    ],
  },
  {
    talentId: '5',
    id: 'willSeeTheater',
    name: 'Will See (Theater)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'willSeeStreaming',
    name: 'Will See (Streaming)',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
    ],
  },
  {
    talentId: '5',
    id: 'authentic',
    name: 'Authentic',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'funny',
    name: 'Funny',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'likable',
    name: 'Likable',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'inspirational',
    name: 'Inspirational',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'trendy',
    name: 'Trendy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'goodActor',
    name: 'Good Actor',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'sexy',
    name: 'Sexy',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'overexposed',
    name: 'Overexposed',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'overrated',
    name: 'Overrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
  {
    talentId: '5',
    id: 'underrated',
    name: 'Underrated',
    chartDetails: [
      {
        date: '02/03/20',
        value: '19',
      },
      {
        date: '12/04/21',
        value: '10',
      },
      {
        date: '16/04/22',
        value: '55',
      },
      {
        date: '03/04/23',
        value: '99',
      },
      {
        date: '03/04/24',
        value: '76',
      },
    ],
  },
]

export const chartDetailAttributes: ChartDetailAttributes[] = [
  {
    id: 'attributes',
    name: 'Attributes',
    options: attributes
      .concat(attributes1)
      .concat(attributes3)
      .concat(attributes4)
      .concat(attributes5),
    selectOptions: attributes,
  },
]
