import { CheckCircle, CircleCross } from 'components/ui/icons'
import { Colors } from 'constnants'

export const ValidationIndicator = ({
  isValid,
  message,
}: {
  isValid: boolean
  message: string
}) => (
  <div className='flex items-center gap-2 text-gray-500'>
    {isValid ? (
      <CheckCircle fill={Colors.NAV_ICON_ONE} width={20} height={20} />
    ) : (
      <CircleCross fill={Colors.NAV_ICON_ONE} width={20} height={20} />
    )}{' '}
    {message}
  </div>
)
