import { api } from './api'
import {
  TalentsByProject,
  TalentTypeData,
  TalentSaveSearchQueryParams,
  TalentSaveSearchResponce,
  SearchRecordResponce,
  TalentSaveListResponce,
  GetTelentsListsResponce,
  UpdateListResponse,
  TalentMetricsChartResponse,
  SavedSearchTypes,
} from 'types'

export const myTalentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllTalents: builder.query<TalentTypeData[], void>({
      query: () => ({
        url: '/talent',
        method: 'GET',
      }),
      providesTags: ['MyTalent'],
    }),
    getTalentsByName: builder.query<TalentTypeData[], { name: string }>({
      query: ({ name }) => ({
        url: `/talent?talentName=${name}`,
        method: 'GET',
      }),
    }),
    getTalentMetricsByName: builder.query<TalentTypeData[], { name: string }>({
      query: ({ name }) => ({
        url: `/talentMetrics/talents?talentName=${name}`,
        method: 'GET',
      }),
    }),
    getAllTalentsSerches: builder.query<
      SearchRecordResponce[],
      {
        projectId?: string | null | undefined
        roleId?: string | null | undefined
        type?: SavedSearchTypes
      }
    >({
      query: ({ projectId, roleId, type }) => {
        const url =
          projectId && roleId
            ? `/searches?projectId=${projectId}&roleId=${roleId}&type=${type ? type : SavedSearchTypes.TALENT}`
            : `/searches?type=${type ? type : SavedSearchTypes.TALENT}`

        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['MyTalent'],
    }),
    getTalentByProjectId: builder.query<TalentsByProject[], { id: string }>({
      query: ({ id }) => ({
        url: `/talent/project/${id}`,
        method: 'GET',
      }),
      providesTags: ['MyTalent'],
    }),
    getTelentsLists: builder.query<
      GetTelentsListsResponce[],
      { projectId: string | null; roleId: string | null }
    >({
      query: ({ projectId, roleId }) => {
        const url =
          projectId && roleId
            ? `/lists?projectId=${projectId}&roleId=${roleId}`
            : '/lists'
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['MyTalent'],
    }),

    saveSearchPreset: builder.mutation<
      TalentSaveSearchResponce,
      {
        name: string
        searchParameters: TalentSaveSearchQueryParams
        projectId: number | null
        roleId: string | null
        type: SavedSearchTypes
      }
    >({
      query: ({ name, searchParameters, roleId, projectId, type }) => ({
        body: {
          name,
          searchParameters,
          roleId,
          projectId,
          type: type ? type : SavedSearchTypes.TALENT,
        },
        url: `/searches`,
        method: 'POST',
      }),
      invalidatesTags: ['MyTalent'],
    }),
    saveTelentList: builder.mutation<
      TalentSaveListResponce[],
      { name: string; talentIds: string[]; projectId?: number; roleId?: string }
    >({
      query: ({ name, talentIds, projectId, roleId }) => ({
        body: { name, talentIds, projectId, roleId },
        url: `/lists`,
        method: 'POST',
      }),
      invalidatesTags: ['MyTalent'],
    }),
    updateTelentList: builder.mutation<
      UpdateListResponse[],
      {
        listId: string
        talentIdsToAdd?: string[]
        talentIdsToRemove?: string[]
        projectId?: string
        roleId?: string
      }
    >({
      query: ({
        listId,
        talentIdsToAdd,
        talentIdsToRemove,
        projectId,
        roleId,
      }) => {
        return {
          body: {
            ...(talentIdsToRemove && { talentIdsToRemove }),
            ...(talentIdsToAdd && { talentIdsToAdd }),
            ...(projectId && { projectId }),
            ...(roleId && { roleId }),
          },

          url: `/lists/${listId}`,
          method: 'PUT',
        }
      },
      invalidatesTags: ['MyTalent'],
    }),
    editSavedSearchPreset: builder.mutation<
      TalentSaveListResponce,
      {
        id: string
        searchParameters?: TalentSaveSearchQueryParams
        name: string
      }
    >({
      query: ({ id, name, searchParameters }) => ({
        body: { name, searchParameters },
        url: `/searches/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyTalent' }],
    }),
    editTalentsList: builder.mutation<
      TalentSaveSearchResponce,
      {
        id: string
        name: string
      }
    >({
      query: ({ id, name }) => ({
        body: { name },
        url: `/lists/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyTalent' }],
    }),
    deleteSavedSearchPreset: builder.mutation<string, { id: string }>({
      query: ({ id }) => ({
        body: { id },
        url: `/searches/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'MyTalent' }],
    }),
    deleteSelectedTelent: builder.mutation<string, { id: string }>({
      query: ({ id }) => ({
        body: { id },
        url: `/lists/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'MyTalent' }],
    }),
    getTalentsChartData: builder.query<
      TalentMetricsChartResponse,
      {
        talentIds: number[]
        metricsType: string
        attribute: string
        duration?: string
        top2Box?: boolean
        audienceFilter?: string
      }
    >({
      query: ({
        talentIds,
        metricsType,
        attribute,
        duration = 'ALL',
        top2Box = false,
        audienceFilter,
      }) => {
        const talentIdsParams = talentIds
          .map(id => `talentIds[]=${id}`)
          .join('&')
        let queryString = [
          `metricsType=${metricsType}`,
          `attribute=${attribute}`,
          `duration=${duration}`,
          `top2Box=${top2Box}`,
        ].join('&')
        if (audienceFilter) {
          queryString = queryString.concat(`&audienceFilter=${audienceFilter}`)
        }
        return {
          url: `/talentMetrics/charts?${talentIdsParams}&${queryString}`,
          method: 'GET',
        }
      },
      providesTags: ['TalentMetricsChart'],
    }),
  }),
})

export const {
  useSaveSearchPresetMutation,
  useSaveTelentListMutation,
  useUpdateTelentListMutation,
  useEditSavedSearchPresetMutation,
  useEditTalentsListMutation,
  useDeleteSavedSearchPresetMutation,
  useDeleteSelectedTelentMutation,
  useGetAllTalentsQuery,
  useGetTalentsByNameQuery,
  useGetTalentMetricsByNameQuery,
  useLazyGetTalentMetricsByNameQuery,
  useGetAllTalentsSerchesQuery,
  useGetTalentByProjectIdQuery,
  useGetTelentsListsQuery,
  useGetTalentsChartDataQuery,
} = myTalentsApi
