import { SocialSearchColumnKey, SocialSearchSortKey } from 'types'

interface Column {
  key: SocialSearchSortKey
  title: string
}

const socialSearchColumns: Record<SocialSearchColumnKey, Column> = {
  allFollowers: {
    key: 'SOCIAL_SUBSCRIBER_TOTAL_COUNT',
    title: 'All followers',
  },
  socialVelocity: {
    key: 'SOCIAL_SUBSCRIBER_VELOCITY',
    title: 'Velocity (0.0)',
  },
  socialVelocityPercentage: {
    key: 'SOCIAL_SUBSCRIBER_VELOCITY_PERCENTAGE',
    title: 'Velocity (%)',
  },
  mentions: {
    key: 'SOCIAL_TOTAL_NEUTRAL_MENTIONS',
    title: 'Mentions',
  },
  sentiment: {
    key: 'SOCIAL_TOTAL_MENTIONS',
    title: 'Sentiment (pos, neu, neg)',
  },
  reach: {
    key: 'SOCIAL_REACH_TOTAL_COUNT',
    title: 'Reach',
  },
  wikipediaPageViews: {
    key: 'SOCIAL_WIKIPEDIA_TOTAL_COUNT',
    title: 'Wikipedia Page Views',
  },
  velocity: {
    key: 'SOCIAL_WIKIPEDIA_VELOCITY',
    title: 'Velocity (0.0)',
  },
  velocityPercentage: {
    key: 'SOCIAL_WIKIPEDIA_VELOCITY_PERCENTAGE',
    title: 'Velocity (%)',
  },
}

export const getSocialSearchColumnDef = (key: SocialSearchColumnKey) =>
  socialSearchColumns[key]
