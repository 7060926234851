import { ChangeEvent, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button, InformationBox, TitleBlockStep } from 'components'
import { RoleList } from './components'
import { useActions, useHandleInputRoleChange, useTypedSelector } from 'hooks'
import { SquarePlus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { OptionFilter, OptionType, ProjectCompany, RoleProject } from 'types'
import {
  useCreateProjectMutation,
  useCreateProjectRoleMutation,
} from 'store/api'
import { createEmptyProjectRole, isProjectRoleComplete } from 'utils'

interface AddRolesStepProps {
  setStepCompleted: () => void
  onClose: () => void
}

const initialRoleState: RoleProject = {
  roleId: '',
  roleName: '',
  roleType: { id: '', value: '', label: '' },
  attachedTalent: { id: '', value: '', label: '' },
  description: '',
  talents: [],
}

export const AddRolesStep = ({ onClose }: AddRolesStepProps) => {
  const { roles, projectLogline, projectDetails, additionalDetails } =
    useTypedSelector(state => state.projectReducer)
  const { addRoles, resetProjectState, deleteRole, updateRole } = useActions()
  const [createProject, { isLoading }] = useCreateProjectMutation()
  const [createProjectRole] = useCreateProjectRoleMutation()

  const companyId = localStorage.getItem('companyId')

  const postRole = (role: RoleProject, company_project_id: number) =>
    createProjectRole({
      role: {
        name: role.roleName,
        type: role.roleType.value,
        description: role.description,
        talentId: Number(role.attachedTalent.id),
        status: 'active',
      },
      projectId: company_project_id ?? 0,
    })

  const completeRoles = useMemo(
    () => roles.filter(isProjectRoleComplete),
    [roles],
  )

  const handleCreateProject = async () => {
    const loglineId = crypto.randomUUID()

    const newProject: Partial<ProjectCompany> = {
      name: projectDetails.projectName,
      team: 'string',
      company_id: companyId ?? '',
      phase_status: 'InDevelopment',
      test_status: 'New',
      budget_id: projectDetails.expectedBudget,
      additional_details: {
        ...additionalDetails,
        primaryGenre: projectDetails.primaryGenre,
        secondaryGenre: projectDetails.secondaryGenre,
        expectedRating: projectDetails.expectedRating,
      },
      log_lines: {
        logline_1: {
          id: loglineId,
          name: `Logline V1`,
          lastEdited: new Date(),
          myLogline: projectLogline,
          isActive: false,
        },
      },
    }

    await createProject(newProject).then(res => {
      const company_project_id = res.data?.company_project_id
      company_project_id &&
        completeRoles.forEach(role => postRole(role, company_project_id))
    })
    resetProjectState()
    onClose()
  }

  const handleAddNewRole = () => {
    addRoles(createEmptyProjectRole())
  }

  const handleUpdateChange = (roleId: string, data: Partial<RoleProject>) => {
    updateRole({ roleId, ...data })
  }

  const handleDeleteRole = (id: string) => {
    deleteRole(id)
  }

  return (
    <div className='flex w-3/4 flex-col space-y-5 p-3'>
      <TitleBlockStep
        title='Add Roles to your Project (optional)'
        descriptions='
        Please add information about project roles beginning with the role name, the role type, a brief description, and attached talent, if applicable.
        This information can be edited in the future.'
      />
      <div className='flex flex-col space-y-5 rounded bg-grey-3 p-3'>
        <RoleList
          roles={roles}
          onDeleteRole={handleDeleteRole}
          onUpdateRole={handleUpdateChange}
        />
        <div className='flex w-full gap-4'>
          <Button
            kind='text'
            size='small'
            onClick={handleAddNewRole}
            className='flex min-w-fit items-center gap-1'
            disabled={roles.length === 0}
          >
            <SquarePlus fill={Colors.BASE_ICON} width={16} height={16} />
            <span>Add another Role</span>
          </Button>
          <div className='mb-3 w-full border-b border-b-primary-black/10' />
        </div>
        <InformationBox wrapperClassName='bg-primary-black/5 rounded-md'>
          <span className='text-sm text-primary-black'>
            Is the Talent you want to attach not coming up? Don’t worry. Leave
            the custom entry there. Our system will try to add the missing
            talent soon.
          </span>
        </InformationBox>
        <div className='flex gap-3'>
          <Button
            kind='filled'
            size='medium'
            className='w-fit bg-primary-red py-1.5 hover:bg-red-9 active:bg-red-10'
            onClick={handleCreateProject}
            disabled={completeRoles.length === 0 || isLoading}
          >
            <span className='px-1'>Save and create project</span>
          </Button>
          <Button
            kind='text'
            size='medium'
            className='w-fit py-1.5'
            onClick={handleCreateProject}
            disabled={isLoading}
          >
            <span className='px-1'>Skip and create project</span>
          </Button>
        </div>
      </div>
    </div>
  )
}
