import { BaseModal, Button } from 'components'
import { Trash } from 'components/ui/icons'
import { Colors } from 'constnants'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface DeleteModalProps {
  isOpen: boolean
  handleClose: () => void
  onDelete: () => void
  title: string | ReactNode
  description: string
  deleteTitle: string
  cancelTitle?: string
  hasTrash?: boolean
  titleClassName?: string
  descriptionClassName?: string
  zIndex?: number
  icon?: ReactNode
  deleteClassName?: string
  maxWidth?: string
}

export const DeleteModal = ({
  isOpen,
  handleClose,
  title,
  description,
  deleteTitle,
  cancelTitle,
  onDelete,
  hasTrash = true,
  titleClassName,
  descriptionClassName,
  zIndex = 50,
  icon,
  deleteClassName,
}: DeleteModalProps) => {
  return (
    <BaseModal isOpen={isOpen} handleClose={handleClose} modalZIndex={zIndex}>
      <div className='flex flex-col items-center justify-center'>
        {hasTrash && !icon && (
          <Trash fill={Colors.ICON_NEGATIVE_RED} width={40} height={40} />
        )}
        {hasTrash && icon}
        <h3
          className={twMerge(
            'pb-2 pt-4 pt-6 text-[17px] text-lg font-medium text-primary-black',
            titleClassName,
          )}
        >
          {title}
        </h3>
        <p
          className={twMerge(
            'pb-1 text-[13px] leading-[20px]',
            descriptionClassName,
          )}
        >
          {description}
        </p>
      </div>
      <div className='flex w-full gap-2 pt-4'>
        <Button
          kind='text'
          size='medium'
          className='w-1/2 py-2.5'
          onClick={handleClose}
        >
          <span className='px-1 text-xs font-semibold'>
            {cancelTitle ? cancelTitle : 'Cancel'}
          </span>
        </Button>

        <Button
          kind='filled'
          size='medium'
          className={twMerge(
            'w-1/2 bg-red-10 py-2.5 hover:bg-red-10/80 active:bg-red-10/60',
            deleteClassName,
          )}
          onClick={onDelete}
        >
          <span className='px-1 text-xs font-semibold'>{deleteTitle}</span>
        </Button>
      </div>
    </BaseModal>
  )
}
