import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import {
  type FilmBoxOfficeUniverseType,
  type FilmRow,
} from 'types/FilmBoxOfficeUniverseType'
import {
  FilmBoxOfficeData,
  useFilmMetricsControllerGetBoxOfficeQuery,
} from 'store/api'
import { formatDateId, formatNumberWithSuffix } from 'utils'
import { Header } from './Header'
import { Table } from './Table'
import { toLocalNumber } from 'components/CompFinderHeader/parts'

interface FilmWeekendProjectTableProps {
  filmId: string
  className?: string
}

const transformFilmsArray = (films: FilmBoxOfficeData[]): FilmRow[] => {
  if (films.length === 0) return []
  return films.map(film => ({
    comps: film.film_name ? film.film_name : '--',
    releaseDate: film.release_date
      ? formatDateId(film.release_date, 'DD/MM/YYYY')
      : '--',
    dist: film.dist ? film.dist : '--',
    rating: film.rating ? film.rating : '--',
    ow: film.open_weekend ? `$${(film.open_weekend / 1e6).toFixed(1)}` : '--',
    owAdjusted: film.ow_adjust
      ? `$${formatNumberWithSuffix(film.ow_adjust)}`
      : '--',
    theatres: film.ow_theatres ? `${toLocalNumber(film.ow_theatres)}` : '--',
    totalGross: film.total_gross
      ? `$${(film.total_gross / 1e6).toFixed(1)} `
      : '--',
  }))
}

export const FilmBoxOfficeUniverseTable = ({
  filmId,
  className,
}: FilmWeekendProjectTableProps) => {
  const { data: boxOfficeData } = useFilmMetricsControllerGetBoxOfficeQuery({
    filmId: Number(filmId),
  })
  const [tableData, setTableData] = useState<FilmBoxOfficeUniverseType>({
    filmId: '',
    data: [],
    redText: null,
  })

  useEffect(() => {
    if (boxOfficeData?.data) {
      setTableData({
        filmId,
        redText: boxOfficeData.boxOfficeNote,
        data: transformFilmsArray(boxOfficeData.data),
      })
    }
  }, [boxOfficeData, filmId])

  return (
    <div
      className={twMerge(
        'flex min-h-80 flex-col rounded bg-grey-3 p-1 ',
        className,
      )}
    >
      <Header />
      <Table data={tableData.data} />
      {Object.keys(tableData) && tableData.redText && (
        <p className='px-2.5 py-1.5 text-xs text-red-9'>{tableData.redText}</p>
      )}
    </div>
  )
}
