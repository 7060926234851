import { ChangeEvent, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Image } from 'components'
import { Plus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { ImageModel } from 'types'

interface UploadImageProps {
  image?: ImageModel | null
  onImageChange: (image: ImageModel | null) => void
  wrapperClassName?: string
  imageClassName?: string
  wrapperImageClassName?: string
}

export const UploadImage = ({
  onImageChange,
  wrapperClassName,
  imageClassName,
  wrapperImageClassName,
  image,
}: UploadImageProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(
    image?.src ?? null,
  )

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.readAsDataURL(file)
    })
  }

  const uploadPhoto = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files[0]
    if (file) {
      const base64 = await convertFileToBase64(file)
      setPreviewImage(base64)
      onImageChange({
        src: base64,
        name: file.name,
        size: file.size,
        type: file.type,
      })
    }
  }

  const handleImageDelete = () => {
    setPreviewImage(null)
    onImageChange(null)
  }

  return (
    <>
      {!previewImage && (
        <label htmlFor='uploadInput'>
          <div
            className={twMerge(
              'flex h-[205px] w-[154px] items-center justify-center bg-grey-4 transition-opacity duration-300 hover:cursor-pointer hover:opacity-70',
              wrapperClassName,
            )}
          >
            <div className='flex h-6 w-6 items-center justify-center rounded-full border border-primary-black/20'>
              <Plus fill={Colors.BASE_ICON} width={16} height={16} />
            </div>
            <input
              ref={fileInputRef}
              type='file'
              multiple
              data-qa='upload-photo'
              id='uploadInput'
              accept='image/*'
              onChange={uploadPhoto}
              className='hidden'
            />
          </div>
        </label>
      )}

      {previewImage && (
        <div className='flex flex-wrap gap-5'>
          <div className='flex flex-row-reverse'>
            <div
              className={twMerge(
                'flex w-[154px] cursor-pointer hover:opacity-90',
                wrapperImageClassName,
              )}
              onClick={handleImageDelete}
            >
              <Image
                src={previewImage.toString()}
                alt='uploaded image'
                width={100}
                height={100}
                className={twMerge('h-[205px]', imageClassName)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
