import React, { useEffect, useState } from 'react'
import {
  FilterButton,
  FilterColumnGroup,
  Input,
  LayoutContentWrapper,
  Select,
} from 'components'
import { usePageTitle } from '../../hooks'
import { Search } from '../../components/ui/icons'
import { TestingManagementTable } from '../../components'
import { columnsTestingAdmin, Colors } from '../../constnants'
import {
  SortByManagementTestingOptions,
  SortOrderManagementTestingOptions,
  testingManagement,
} from '../../types'
import { twMerge } from 'tailwind-merge'
import { TestingEditPage } from './TestingEditPage'
const initialActiveFilters: {
  testingStatus: string[]
  companyFilter: string
  search: string
} = {
  testingStatus: [''],
  companyFilter: '',
  search: '',
}

const testingDataPlaceholder = {
  id: '',
  testName: '',
  project: '',
  company: '',
  status: '',
  submitDate: '',
  completeDate: '',
}

const initialTestingSingleData: testingManagement = testingDataPlaceholder

const conceptTestingList = [
  {
    id: 'c23f7363-59f5-4f26-9656-39842c30ba52',
    submitDate: '2024-08-27T17:16:02.913Z',
    completeDate: '2024-08-27T17:16:02.913Z',
    status: 'complete',
    testName: 'Horror films projects_CT001',
    project: 'Horror films projects',
    company: 'Pearl Street Films',
  },
  {
    id: '7546586f-fe8a-4924-998c-b2ea3789bd57',
    submitDate: '2024-08-28T15:28:49.011Z',
    completeDate: '2024-08-28T15:28:49.011Z',
    status: 'complete',
    testName: 'Horror film_CT001',
    project: 'Horror film',
    company: 'Pearl Street Films',
  },
  {
    id: 'dd8aea60-1230-4c03-91a6-389e80402947',
    submitDate: '2024-08-28T15:29:35.371Z',
    completeDate: '2024-08-28T15:29:35.371Z',
    status: 'complete',
    testName: 'Horror film_CT002',
    project: 'Horror film',
    company: 'Warner Bros.',
  },
  {
    id: '43b007b9-46fa-4045-baa6-fe4ad7db70e0',
    submitDate: '2024-08-28T16:23:13.154Z',
    completeDate: '2024-08-28T16:23:13.154Z',
    status: 'submitedtoprodoge',
    testName: 'Drama project 08.28.24_CT002',
    project: 'Drama project 08.28.24',
    company: 'Universal Studios',
  },
  {
    id: 'acdf9377-d598-4fd3-96ad-5de40a1dfbb4',
    submitDate: '2024-08-28T16:23:55.616Z',
    completeDate: '2024-08-28T16:23:55.616Z',
    status: 'needsapproval',
    testName: 'Project role1_CT001',
    project: 'Project role1',
    company: 'Warner Bros.',
  },
  {
    id: 'ab001210-3af5-4681-b345-be6b54bf28eb',
    submitDate: '2024-08-28T16:24:10.164Z',
    completeDate: '--',
    status: 'denied',
    testName: 'PROJECT NAME 2_CT001',
    project: 'PROJECT NAME 2',
    company: 'Pearl Street Films',
  },
  {
    id: 'cc05022e-7b29-41e7-9d0b-3b2b355e9d89',
    submitDate: '2024-08-28T17:15:13.296Z',
    completeDate: '2024-08-28T17:15:13.296Z',
    status: 'needsapproval',
    testName: 'DEMO project_CT004',
    project: 'DEMO project',
    company: 'Pearl Street Films',
  },
  {
    id: '0d057d97-20d9-46ab-be00-f6d40498e880',
    submitDate: '2024-08-29T09:17:04.277Z',
    completeDate: '--',
    status: 'complete',
    testName: 'DEMO project_CT005',
    project: 'DEMO project',
    company: 'Warner Bros.',
  },
]
const roleTestingList = [
  {
    id: 'c23f7363-59f5-4f26-9656-39842c30ba52',
    submitDate: '2024-08-27T17:16:02.913Z',
    completeDate: '2024-08-27T17:16:02.913Z',
    status: 'complete',
    testName: 'Horror films projects_RT001',
    project: 'Horror films projects',
    company: 'Pearl Street Films',
  },
  {
    id: '7546586f-fe8a-4924-998c-b2ea3789bd57',
    submitDate: '2024-08-28T15:28:49.011Z',
    completeDate: '2024-08-28T15:28:49.011Z',
    status: 'complete',
    testName: 'Horror film_RT001',
    project: 'Horror film',
    company: 'Pearl Street Films',
  },
  {
    id: 'dd8aea60-1230-4c03-91a6-389e80402947',
    submitDate: '2024-08-28T15:29:35.371Z',
    completeDate: '2024-08-28T15:29:35.371Z',
    status: 'complete',
    testName: 'Horror film_RT002',
    project: 'Horror film',
    company: 'Warner Bros.',
  },
  {
    id: '43b007b9-46fa-4045-baa6-fe4ad7db70e0',
    submitDate: '2024-08-28T16:23:13.154Z',
    completeDate: '2024-08-28T16:23:13.154Z',
    status: 'submitedtoprodoge',
    testName: 'Drama project 08.28.24_RT002',
    project: 'Drama project 08.28.24',
    company: 'Universal Studios',
  },
  {
    id: 'acdf9377-d598-4fd3-96ad-5de40a1dfbb4',
    submitDate: '2024-08-28T16:23:55.616Z',
    completeDate: '2024-08-28T16:23:55.616Z',
    status: 'needsapproval',
    testName: 'Project role1_RT001',
    project: 'Project role1',
    company: 'Warner Bros.',
  },
  {
    id: 'ab001210-3af5-4681-b345-be6b54bf28eb',
    submitDate: '2024-08-28T16:24:10.164Z',
    completeDate: '--',
    status: 'denied',
    testName: 'PROJECT NAME 2_RT001',
    project: 'PROJECT NAME 2',
    company: 'Pearl Street Films',
  },
  {
    id: 'cc05022e-7b29-41e7-9d0b-3b2b355e9d89',
    submitDate: '2024-08-28T17:15:13.296Z',
    completeDate: '2024-08-28T17:15:13.296Z',
    status: 'needsapproval',
    testName: 'DEMO project_RT004',
    project: 'DEMO project',
    company: 'Pearl Street Films',
  },
  {
    id: '0d057d97-20d9-46ab-be00-f6d40498e880',
    submitDate: '2024-08-29T09:17:04.277Z',
    completeDate: '--',
    status: 'complete',
    testName: 'DEMO project_RT005',
    project: 'DEMO project',
    company: 'Warner Bros.',
  },
]
const assetTestingList = [
  {
    id: 'c23f7363-59f5-4f26-9656-39842c30ba52',
    submitDate: '2024-08-27T17:16:02.913Z',
    completeDate: '2024-08-27T17:16:02.913Z',
    status: 'complete',
    testName: 'Horror films projects_AT001',
    project: 'Horror films projects',
    company: 'Pearl Street Films',
  },
  {
    id: '7546586f-fe8a-4924-998c-b2ea3789bd57',
    submitDate: '2024-08-28T15:28:49.011Z',
    completeDate: '2024-08-28T15:28:49.011Z',
    status: 'complete',
    testName: 'Horror film_AT001',
    project: 'Horror film',
    company: 'Pearl Street Films',
  },
  {
    id: 'dd8aea60-1230-4c03-91a6-389e80402947',
    submitDate: '2024-08-28T15:29:35.371Z',
    completeDate: '2024-08-28T15:29:35.371Z',
    status: 'complete',
    testName: 'Horror film_AT002',
    project: 'Horror film',
    company: 'Warner Bros.',
  },
  {
    id: '43b007b9-46fa-4045-baa6-fe4ad7db70e0',
    submitDate: '2024-08-28T16:23:13.154Z',
    completeDate: '2024-08-28T16:23:13.154Z',
    status: 'submitedtoprodoge',
    testName: 'Drama project 08.28.24_AT002',
    project: 'Drama project 08.28.24',
    company: 'Universal Studios',
  },
  {
    id: 'acdf9377-d598-4fd3-96ad-5de40a1dfbb4',
    submitDate: '2024-08-28T16:23:55.616Z',
    completeDate: '2024-08-28T16:23:55.616Z',
    status: 'needsapproval',
    testName: 'Project role1_AT001',
    project: 'Project role1',
    company: 'Warner Bros.',
  },
  {
    id: 'ab001210-3af5-4681-b345-be6b54bf28eb',
    submitDate: '2024-08-28T16:24:10.164Z',
    completeDate: '--',
    status: 'denied',
    testName: 'PROJECT NAME 2_AT001',
    project: 'PROJECT NAME 2',
    company: 'Pearl Street Films',
  },
  {
    id: 'cc05022e-7b29-41e7-9d0b-3b2b355e9d89',
    submitDate: '2024-08-28T17:15:13.296Z',
    completeDate: '2024-08-28T17:15:13.296Z',
    status: 'needsapproval',
    testName: 'DEMO project_AT004',
    project: 'DEMO project',
    company: 'Pearl Street Films',
  },
  {
    id: '0d057d97-20d9-46ab-be00-f6d40498e880',
    submitDate: '2024-08-29T09:17:04.277Z',
    completeDate: '--',
    status: 'complete',
    testName: 'DEMO project_AT005',
    project: 'DEMO project',
    company: 'Warner Bros.',
  },
]

// TODO: Refactor this to use the new API
const companyFilterOptions = [
  {
    id: 'df2e32da-cd27-4d5e-a9f6-134bdc5ac6d9',
    value: 'df2e32da-cd27-4d5e-a9f6-134bdc5ac6d9',
    label: 'Pearl Street Films test',
  },
  {
    id: '7a9a0a34-f892-4c20-8353-5caa4daa95c1',
    value: '7a9a0a34-f892-4c20-8353-5caa4daa95c1',
    label: 'Pearl Street Films',
  },
  {
    id: '8cf53565-ab38-430c-af66-bb8c002922d6',
    value: '8cf53565-ab38-430c-af66-bb8c002922d6',
    label: '111',
  },
  {
    id: '0f3fbd72-f296-4108-9b9c-bef32007f02a',
    value: '0f3fbd72-f296-4108-9b9c-bef32007f02a',
    label: 'NEw company',
  },
  {
    id: '77b7c964-cbc2-4837-9b80-18df6ec2d50a',
    value: '77b7c964-cbc2-4837-9b80-18df6ec2d50a',
    label: 'Test company',
  },
  {
    id: '2309212c-0a03-4c53-9d36-7d7400d2724c',
    value: '2309212c-0a03-4c53-9d36-7d7400d2724c',
    label: 'serhii',
  },
  {
    id: 'b51292c1-3800-4bac-97e6-0b8de8dea0f9',
    value: 'b51292c1-3800-4bac-97e6-0b8de8dea0f9',
    label: 'Test',
  },
  {
    id: '3238fe41-29d1-478e-a9d6-f076cf72efea',
    value: '3238fe41-29d1-478e-a9d6-f076cf72efea',
    label: 'Test company',
  },
]
const testingStatusFilter = [
  {
    id: '0',
    value: 'all',
    label: 'All',
  },
  {
    id: '1',
    value: 'needsapproval',
    label: 'Needs Approval',
  },
  {
    id: '2',
    value: 'submitedtoprodoge',
    label: 'Submitted to Prodoge',
  },
  {
    id: '3',
    value: 'complete',
    label: 'Complete',
  },
]

export const TestingModulesPage = () => {
  const [activeTab, setActiveTab] = useState('concept')
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  const [sortingBY, setSortingBY] =
    useState<SortByManagementTestingOptions>('name')
  const [sortingOrder, setSortingOrder] =
    useState<SortOrderManagementTestingOptions>('ASC')
  const [totalTestingCount, setTotalTestingCount] = useState(14)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [filteredTesting, setFilteredTesting] = useState<testingManagement[]>(
    [],
  )
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null,
  )
  const [testingItemOpen, setTestingItemOpen] = useState<testingManagement>(
    initialTestingSingleData,
  )
  const pageTitle = usePageTitle()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    const newTimeout = setTimeout(() => {
      setActiveFilters({ ...activeFilters, search: e.target.value })
    }, 500)
    setTypingTimeout(newTimeout)
  }
  const resetFilters = () => {
    setActiveFilters(initialActiveFilters)
    setSearchValue('')
  }

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      if (activeTab === 'concept') {
        setFilteredTesting(conceptTestingList)
      } else if (activeTab === 'role') {
        setFilteredTesting(roleTestingList)
      } else if (activeTab === 'asset') {
        setFilteredTesting(assetTestingList)
      }
      setIsLoading(false)
    }, 500)
  }, [activeFilters, searchValue, activeTab])

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-white'
      wrapperChildrenClassName='h-full'
    >
      {testingItemOpen.id ? (
        <TestingEditPage
          setTestingItemOpen={setTestingItemOpen}
          testingDataPlaceholder={testingDataPlaceholder}
          testingData={testingItemOpen}
          testingType={activeTab}
        />
      ) : (
        <div className='p-3'>
          <div className='flex items-center'>
            <h1 className={'text-2xl font-semibold text-primary-black'}>
              {pageTitle}
            </h1>
            <div className={'ml-[45px] flex grow gap-4'}>
              <div
                className={twMerge(
                  'w-[106px] cursor-pointer border-b-2 py-1.5 text-center',
                  activeTab === 'concept' &&
                    'border-b-blue-5 font-medium text-blue-5',
                )}
                onClick={() => setActiveTab('concept')}
              >
                Concept Tests
              </div>
              <div
                className={twMerge(
                  'w-[106px] cursor-pointer border-b-2 py-1.5 text-center',
                  activeTab === 'role' &&
                    'border-b-blue-5 font-medium text-blue-5',
                )}
                onClick={() => setActiveTab('role')}
              >
                Role Tests
              </div>
              <div
                className={twMerge(
                  'w-[106px] cursor-pointer border-b-2 py-1.5 text-center',
                  activeTab === 'asset' &&
                    'border-b-blue-5 font-medium text-blue-5',
                )}
                onClick={() => setActiveTab('asset')}
              >
                Assets Tests
              </div>
            </div>
            <div className={'flex items-center gap-4'}>
              <FilterButton
                name={'Filter'}
                containerMenuClassName='right-0'
                nameCLassName='font-normal text-sm items-center inline-flex'
                isMenuVisible={false}
                totalSelectedFilters={0}
                wrapperClasName='border-primary-black/20'
                wrapperMenuClassName='w-[300px] text-left'
                trianglePosition='right'
                innerClassName='py-0.5'
              >
                <div className='mb-5 flex items-center justify-between'>
                  <p className={'font-medium'}>Filter</p>
                  <span
                    className={'cursor-pointer text-red-9'}
                    onClick={() => resetFilters()}
                  >
                    Clear
                  </span>
                </div>
                <div className='mb-5 flex max-h-[400px] flex-row gap-10 overflow-auto'>
                  {
                    <FilterColumnGroup
                      key={'status'}
                      category={'testingStatuses'}
                      title={'Status'}
                      optionsFilter={testingStatusFilter}
                      isSelect={false}
                      selectedFilters={{
                        testingStatuses: activeFilters['testingStatus'],
                      }}
                      onChange={selected => {
                        setActiveFilters({
                          ...activeFilters,
                          testingStatus: selected['testingStatuses'],
                        })
                      }}
                      hasAll={true}
                      isSingleSelection={true}
                    />
                  }
                </div>
                <Select
                  label={'Filter by Company'}
                  labelClass={'uppercase text-xs text-primary-black'}
                  value={activeFilters['companyFilter']}
                  handleClick={option =>
                    setActiveFilters({
                      ...activeFilters,
                      companyFilter: option.value,
                    })
                  }
                  options={companyFilterOptions}
                  size='small'
                  placeholder={'All'}
                  defaultValue={'all'}
                  alignItems='start'
                  buttonClass={'whitespace-nowrap'}
                  hasRadioButton={false}
                  hasSearch={false}
                />
              </FilterButton>
              <Input
                containerWrapperClassName='w-[200px] h-6'
                placeholder='Search Name'
                value={searchValue}
                onChange={handleSearchChange}
                leftIcon={
                  <Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />
                }
              />
            </div>
          </div>
          <TestingManagementTable
            columns={columnsTestingAdmin}
            data={filteredTesting}
            isLoading={isLoading}
            onSort={setSortingBY}
            onSortOrder={setSortingOrder}
            sortingBY={sortingBY}
            totalTestsCount={totalTestingCount}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            setTestingItemOpen={setTestingItemOpen}
          />
        </div>
      )}
    </LayoutContentWrapper>
  )
}
