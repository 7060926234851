import { ReactNode, useCallback } from 'react'
import { MgmtTableCell, MgmtTable } from 'modules/management/shared'
import { type TVManagementTableTypes } from './types'
import { TVManagementColKey, type TVManagementData } from '../../types'
import { twMerge } from 'tailwind-merge'
import { columns } from './columns'
import { EditEntryCellContent } from '../cellContent'

type TVManagementTableProps = {
  rows: TVManagementData[]
  footer: ReactNode
  loading: boolean
  fetching: boolean
  sortConfig: TVManagementTableTypes['SortConfig']
  onSortChange: TVManagementTableTypes['OnSortChange']
}

export const TVManagementTable = ({
  rows,
  footer,
  loading,
  fetching,
  sortConfig,
  onSortChange,
}: TVManagementTableProps) => {
  const cellRenderer: TVManagementTableTypes['CellRenderer'] = useCallback(
    ({ column, cellContent }) => (
      <MgmtTableCell
        column={column}
        className={twMerge(column.key === 'title' && 'font-medium')}
      >
        {cellContent}
      </MgmtTableCell>
    ),
    [],
  )

  const rowKeyGetter: TVManagementTableTypes['RowKeyGetter'] = useCallback(
    ({ row }) => row.id,
    [],
  )

  const cellContentGetter: TVManagementTableTypes['CellContentGetter'] =
    useCallback(({ column, getCellValue }) => {
      switch (column.key) {
        case TVManagementColKey.EditEntry: {
          return <EditEntryCellContent />
        }
        default:
          return getCellValue(column.key)
      }
    }, [])

  return (
    <MgmtTable
      rows={rows}
      columns={columns}
      footer={footer}
      loading={loading}
      fetching={fetching}
      cellRenderer={cellRenderer}
      rowKeyGetter={rowKeyGetter}
      cellContentGetter={cellContentGetter}
      sortConfig={sortConfig}
      onSortChange={onSortChange}
    />
  )
}
