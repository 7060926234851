import {
  FilterButton,
  Button,
  Select,
  InclusiveFilter,
  SectionTitle,
  ExclusiveFilter,
  FilmTrackingLookup,
} from 'components'
import {
  Export,
  Search,
  Share,
  Compare,
  FilmTracking,
} from 'components/ui/icons'
import {
  Colors,
  groupFilterDef,
  distributorsFilterDef,
  theatergoersFilterDef,
} from 'constnants'
import {
  audienceFilterDef,
  visibleDemosFilterDef,
} from '../../constnants/audienceOptionsFilms'
import { twJoin, twMerge } from 'tailwind-merge'
import { useCallback, useMemo } from 'react'
import {
  AudienceDemoGroup,
  AudienceGeneralDemo,
  OptionType,
  Theatergoers,
  Urls,
} from '../../types'
import { useNavigate } from 'react-router-dom'
import { buildParams, sortAlphabetically } from 'utils'
import { useFilmMetricsControllerGetFilmTrackingFiltersQuery } from 'store/api'
import { useSelector } from 'react-redux'
import { filmTrackingPageSelectors, useFilmTrackingPageActions } from 'slices'

interface FilmTrackingHeaderProps {
  results: number | undefined
  selectedRows: string[]
  columnIsActive: boolean
}

export const FilmTrackingHeader = ({
  results = 0,
  selectedRows,
  columnIsActive,
}: FilmTrackingHeaderProps) => {
  const actions = useFilmTrackingPageActions()
  const filters = useSelector(filmTrackingPageSelectors.filters)
  const visibleDemos = useSelector(filmTrackingPageSelectors.visibleDemos)

  const { data: filtersData } =
    useFilmMetricsControllerGetFilmTrackingFiltersQuery()

  const { groupsFilter, distributorsFilter } = useMemo(() => {
    const distributors = filtersData?.distributors ?? []
    const groups = filtersData?.groups ?? []

    const distributorsFilterOptions: OptionType<number>[] = distributors.map(
      ({ company_id, company_name }) => ({
        id: company_id.toString(),
        value: company_id,
        label: company_name ?? '-',
      }),
    )
    sortAlphabetically(distributorsFilterOptions, it => it.label)

    const groupsFilterOptions: OptionType<number>[] = groups.map(
      ({ film_group_id, film_group_name }) => ({
        id: film_group_id.toString(),
        value: film_group_id,
        label: film_group_name ?? '-',
      }),
    )
    sortAlphabetically(groupsFilterOptions, it => it.label)

    return {
      distributorsFilter: {
        ...distributorsFilterDef,
        options: distributorsFilterOptions,
      },
      groupsFilter: {
        ...groupFilterDef,
        options: groupsFilterOptions,
      },
    }
  }, [filtersData])

  const isDisabledCompare = selectedRows.length === 0 || selectedRows.length > 4
  const navigate = useNavigate()
  const isSelectCompare =
    (selectedRows.length > 0 &&
      selectedRows.length < 5 &&
      `Compare ${selectedRows.length}/4`) ||
    (selectedRows.length > 3 && `Compare`)
  const selectAmountFilms = !selectedRows.length ? 'Compare' : isSelectCompare

  const handleCompareClick = () => {
    const searchParams = buildParams({ filmId: selectedRows })
    navigate(`${Urls.FILM}${Urls.COMPARE}?${searchParams}`)
  }

  const handleTheatergoersChangeFilms = useCallback(
    (option: OptionType<Theatergoers>) =>
      actions.setTheatergoersFilter(option.value),
    [],
  )

  const handleResetFiltersDistributors = useCallback(
    () => actions.setDistributorFilter([]),
    [],
  )

  const handleResetFiltersGroup = useCallback(
    () => actions.setGroupFilter([]),
    [],
  )

  const handleResetFiltersAudience = useCallback(
    () => actions.setAudienceFilter(AudienceGeneralDemo.ALL),
    [],
  )

  const handleResetVisibleDemos = useCallback(
    () => actions.resetVisibleDemos(),
    [],
  )

  const handleResetAllFilters = useCallback(() => actions.resetFilters(), [])

  const hasChanges = useMemo(
    () =>
      [
        filters.distributors.length,
        filters.groups.length,
        filters.theatergoers !== Theatergoers.ALL_RESPONDENTS,
        filters.audience !== AudienceGeneralDemo.ALL,
      ].some(Boolean),
    [filters],
  )

  const audienceTitle = useMemo(() => {
    for (const audienceGroup of audienceFilterDef.options) {
      const targetAudience = audienceGroup.options.find(
        it => it.value === filters.audience,
      )
      if (targetAudience) {
        if (audienceGroup.id !== AudienceDemoGroup.GENERAL) {
          return `${audienceGroup.name} ${targetAudience.label}`
        }

        if (targetAudience.value !== AudienceGeneralDemo.ALL) {
          return targetAudience.label
        }
      }
    }
    return audienceFilterDef.name
  }, [filters.audience])

  const formatTheatergoersLabel = useCallback(
    (option: OptionType<string> | undefined) => {
      if (!option) {
        return theatergoersFilterDef.name
      }
      return `${theatergoersFilterDef.name}: ${option.label}`
    },
    [],
  )

  return (
    <div>
      <div className='flex flex-row items-center justify-between p-2.5'>
        <div className='flex flex-row items-center gap-2'>
          <FilmTracking fill={Colors.ICON_BRAND} width={20} height={20} />
          <h2 className='text-lg font-medium text-primary-grey'>
            Film Tracking
          </h2>
          <Button
            kind='text'
            size='small'
            className={twJoin(
              'ml-2 flex items-center gap-1 px-2 text-xs font-semibold text-primary-black',
              isDisabledCompare && 'opacity-50',
            )}
            disabled={isDisabledCompare}
            onClick={handleCompareClick}
          >
            <Compare fill={Colors.BASE_ICON} width={16} height={16} />
            {selectAmountFilms}
          </Button>
        </div>

        <div className='flex flex-row items-center gap-3'>
          <div className='relative'>
            <FilmTrackingLookup
              onSelectFilm={filmId => navigate(`${Urls.FILM}/${filmId}`)}
            />
          </div>
          {/*  <div className='flex flex-row items-center gap-1'>
            <Share
              fill={Colors.NAV_ICON_THREE}
              width={16}
              height={16}
              className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'
            />
            <Export
              fill={Colors.NAV_ICON_THREE}
              width={16}
              height={16}
              className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'
            />
          </div> */}
        </div>
      </div>
      <div className='flex flex-row items-center border-t border-primary-black/10 bg-grey-2 p-2.5'>
        <div className='flex w-full flex-row items-center gap-3'>
          <FilterButton
            name={distributorsFilter.name}
            totalSelectedFilters={filters.distributors.length}
            handleReset={handleResetFiltersDistributors}
          >
            <div className='flex w-max flex-col gap-2'>
              <SectionTitle
                title={distributorsFilter.name}
                className={twMerge('text-left text-xs text-primary-black')}
              />
              <InclusiveFilter
                options={distributorsFilter.options}
                selected={filters.distributors}
                onChange={actions.setDistributorFilter}
              />
            </div>
          </FilterButton>
          <FilterButton
            name={groupsFilter.name}
            totalSelectedFilters={filters.groups.length}
            handleReset={handleResetFiltersGroup}
          >
            <div className='flex w-max flex-col gap-2'>
              <SectionTitle
                title={groupsFilter.name}
                className={twMerge('text-left text-xs text-primary-black')}
              />
              <InclusiveFilter
                options={groupsFilter.options}
                selected={filters.groups}
                onChange={actions.setGroupFilter}
              />
            </div>
          </FilterButton>
          {!columnIsActive && (
            <FilterButton
              name={audienceTitle}
              totalSelectedFilters={
                filters.audience === AudienceGeneralDemo.ALL ? 0 : 1
              }
              hideTotalNumber
              handleReset={handleResetFiltersAudience}
              trianglePosition='left'
              containerMenuClassName='left-0'
            >
              <div className='flex flex-row gap-10'>
                {audienceFilterDef.options.map(({ id, name, options }) => {
                  return (
                    <div key={id} className='flex w-max flex-col gap-2'>
                      <SectionTitle
                        title={name}
                        className={twMerge(
                          'text-left text-xs text-primary-black',
                        )}
                      />
                      <ExclusiveFilter
                        slug={id}
                        key={id}
                        options={options}
                        selected={filters.audience}
                        onChange={actions.setAudienceFilter}
                      />
                    </div>
                  )
                })}
              </div>
            </FilterButton>
          )}
          <Select
            size='small'
            alignItems='start'
            buttonClass={'whitespace-nowrap'}
            value={filters.theatergoers}
            options={theatergoersFilterDef.options}
            placeholder={theatergoersFilterDef.name}
            handleClick={handleTheatergoersChangeFilms}
            formatActiveLabel={formatTheatergoersLabel}
          />
          {hasChanges && (
            <div className='flex flex-row items-center gap-3'>
              <Button kind='text' size='small' onClick={handleResetAllFilters}>
                <span className='px-1'>Clear</span>
              </Button>
            </div>
          )}
          {columnIsActive && (
            <div className='flex w-full flex-auto justify-end'>
              <FilterButton
                name={visibleDemosFilterDef.name}
                hideTotalNumber
                totalSelectedFilters={visibleDemos.count}
                handleReset={handleResetVisibleDemos}
                trianglePosition='right'
                containerMenuClassName='right-0'
              >
                <div className='flex flex-row gap-10'>
                  {visibleDemosFilterDef.options.map(
                    ({ id, name, options }) => {
                      return (
                        <div key={id} className='flex w-max flex-col gap-2'>
                          <SectionTitle
                            title={name}
                            className={twMerge(
                              'text-left text-xs text-primary-black',
                            )}
                          />
                          <InclusiveFilter
                            key={id}
                            mode='explicit'
                            selectAllLabel='Select all'
                            options={options}
                            selected={visibleDemos.active[id]}
                            onChange={group =>
                              actions.setAudienceDemoFilter({ id, group })
                            }
                          />
                        </div>
                      )
                    },
                  )}
                </div>
              </FilterButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
