export interface ColumnsProjectTable {
  title: string
  value: string
}

export interface IProject {
  projectId: string
  name: string
}

export interface ITeam {
  name: string
}

export enum ProjectStatus {
  CONCEPT = 'Concept',
  IN_DEVELOPMENT = 'In Development',
  RELEASED = 'Released',
}

export interface ProjectType {
  id: string
  project: IProject
  created: string
  updated: string
  team: ITeam[]
  projectStatus: ProjectStatus
}
