import { useState } from 'react'
import { TalentMetricsResponse } from 'store/api'
import { AttributeType, SortOrderKey, TalentType } from 'types'
import { formatNumberWithSuffix } from '../utils'

export default function useSerializeTalentData() {
  const serializeTalent = (
    data?: TalentMetricsResponse,
    contentTypeView: AttributeType = 'percentage',
    itemNumber = 1,
  ) => {
    const { socialMediaData } = data ?? {}
    const prepareTalentData: TalentType = {
      socialMediaData,
      id: data?.talentId ?? '',
      talentPerson: {
        talentId: data?.talentId ?? '',
        talentName: data?.talentName ?? '',
        profileImage: '',
        biography: '',
        personalData: {
          born: '',
          height: '',
        },
        latestProjects: [],
        dateOfBirth: '',
      },
      lastFielding:
        data?.talentMetrics?.awareness[0]?.surveyDate?.toString() ?? '20240513',
      attributes: Object.keys(data?.talentMetrics ?? '').map(key => ({
        name: key,
        values: data?.talentMetrics[key][0] ?? {
          surveyDate: 0,
          percentage:
            contentTypeView === 'percentage'
              ? (data?.talentMetrics[key][0]?.percentage ?? '')
              : '',
          rank:
            contentTypeView === 'rank'
              ? (data?.talentMetrics[key][0]?.rank ?? 0)
              : 0,
          count:
            contentTypeView === 'count'
              ? (data?.talentMetrics[key][0]?.count ?? 0)
              : 0,
        },
      })),
      srNumber: data?.srNumber ?? itemNumber,
      social: {
        allFollowers: {
          count: socialMediaData?.subscriber_total_count?.toString() ?? '0',
          hasInfo: true,
          tooltipText: 'Instagram, X (followers)',
        },
        velocity: {
          count: socialMediaData?.subscriber_velocity ?? 0,
          hasInfo: true,
          tooltipText: 'Absolute change in followers',
        },
        velocityPercent: {
          count: `${Math.round((socialMediaData?.subscriber_velocity_percentage ?? 0) * 100) / 100}%`,
          hasInfo: true,
          tooltipText: 'Percent change in followers',
        },
        mentions: { count: socialMediaData?.total_mentions ?? 0 },
        sentiment: {
          count: `${formatNumberWithSuffix(socialMediaData?.positive_percentage ?? 0)}%, ${formatNumberWithSuffix(socialMediaData?.neutral_percentage ?? 0)}%, ${formatNumberWithSuffix(socialMediaData?.negative_percentage ?? 0)}%`,
          hasInfo: true,
          tooltipText:
            'Positive, Neutral, Negative \n' +
            'share of instagram conversation',
        },
        reach: {
          count: socialMediaData?.reach_total_count ?? 0,
          hasInfo: true,
          tooltipText: 'Total number of people\n' + 'who see a talent’s posts',
        },
      },
      search: {
        googleTrends: Number(socialMediaData?.wikipedia_total_count) ?? 0,
        pageViews: {
          count: socialMediaData?.wikipedia_total_count ?? 0,
          hasInfo: true,
          tooltipText: 'Last 24 hours',
        },
        velocity: {
          count: socialMediaData?.wikipedia_velocity ?? 0,
          hasInfo: true,
          tooltipText: 'Absolute change in page views',
        },
        velocityPercent: {
          count: `${Math.round((socialMediaData?.wikipedia_velocity_percentage ?? 0) * 100) / 100}%`,
          hasInfo: true,
          tooltipText: 'Percent change in page views',
        },
      },
    }

    return prepareTalentData
  }
  const [talentList, setTalentList] = useState<TalentType[]>([
    serializeTalent(undefined, 'percentage'),
  ])
  const [talent, setTalent] = useState<TalentType>(
    serializeTalent(undefined, 'percentage'),
  )

  const onSerializeTalent = (
    talentsData: TalentMetricsResponse | TalentMetricsResponse[],
    contentTypeView: AttributeType,
    params: {
      sortOrder?: SortOrderKey
      sortBy?: string
      contentTypeView?: string
    } = {},
  ) => {
    if (Array.isArray(talentsData)) {
      const { sortOrder, sortBy, contentTypeView } = params
      const talentList = talentsData.map((talent, itemNumber) => {
        return serializeTalent(talent, contentTypeView, itemNumber + 1)
      })

      if (sortOrder && sortBy && contentTypeView) {
        talentList.sort((a, b) => {
          let firstValue: string | number | undefined = ''
          let secondValue: string | number | undefined = ''
          if (sortBy === 'DATE_SURVEY_ID') {
            firstValue = a?.lastFielding
            secondValue = b?.lastFielding
          } else if (sortBy === 'TALENT_NAME') {
            firstValue = a?.talentPerson?.talentName
            secondValue = b?.talentPerson?.talentName
          } else if (sortBy === 'SR_NUMBER') {
            firstValue = a.srNumber
            secondValue = b.srNumber
          } else if (sortBy === 'SOCIAL_SUBSCRIBER_TOTAL_COUNT') {
            firstValue = a?.social?.allFollowers?.count
            secondValue = b?.social?.allFollowers?.count
          } else if (sortBy === 'SOCIAL_WIKIPEDIA_TOTAL_COUNT') {
            firstValue = a?.search?.pageViews?.count
            secondValue = b?.search?.pageViews?.count
          } else {
            firstValue =
              a?.attributes?.find(
                attribute =>
                  attribute.name === sortBy?.split('_')?.join('').toLowerCase(),
              )?.values[contentTypeView] ?? ''
            secondValue =
              b.attributes?.find(
                attribute =>
                  attribute.name === sortBy?.split('_')?.join('').toLowerCase(),
              )?.values[contentTypeView] ?? ''
          }
          if (!firstValue) {
            firstValue = ''
          }
          if (!secondValue) {
            secondValue = ''
          }
          if (typeof firstValue !== 'string') {
            firstValue = firstValue.toString()
          }
          if (typeof secondValue !== 'string') {
            secondValue = secondValue.toString()
          }
          if (typeof firstValue == 'string' && firstValue?.includes('%')) {
            const deletePercent = firstValue.split('%')[0]
            firstValue = deletePercent
          }

          if (typeof secondValue == 'string' && secondValue?.includes('%')) {
            const deletePercent = secondValue.split('%')[0]
            secondValue = deletePercent
          }

          if (sortBy === 'TALENT_NAME') {
            if (sortOrder === 'ASC') {
              return firstValue.localeCompare(secondValue)
            }
            return secondValue.localeCompare(firstValue)
          }

          if (sortOrder === 'ASC') {
            return firstValue - secondValue
          } else {
            return secondValue - firstValue
          }
        })
      }
      setTalentList(talentList)
      return talentList
    }

    const talent = serializeTalent(talentsData, contentTypeView)
    setTalent(talent)
    return talent
  }

  return {
    talent,
    talentList,
    onSerializeTalent,
  }
}
