import { useState } from 'react'
import { DayPicker, DateRange as PickerDateRange } from 'react-day-picker'
import { Calendar as IconCalendar } from 'components/ui/icons'
import { Button, FilterMenu } from 'components'
import { formatDate } from 'utils'
import { Colors, dateRange } from 'constnants'

import 'react-day-picker/dist/style.css'
import { useClickOutsideComponent } from 'hooks'
import { DateRangeKey } from 'types'

interface DateRangeProps {
  fromDate?: Date
  defaultRange?: DateRangeKey
  onApply: (rangeKey: DateRangeKey, customRange?: PickerDateRange) => void
}

const formatDateRange = (range: PickerDateRange | undefined) => {
  const from = range?.from
  const to = range?.to ?? from
  if (!range || !from || !to) {
    return ''
  }

  const fromDate = formatDate(from, 'MMM DD, YYYY')
  const toDate = formatDate(to, 'MMM DD, YYYY')
  return `${fromDate} - ${toDate}`
}

export const DateRange = ({
  onApply,
  fromDate,
  defaultRange = 'YEAR',
}: DateRangeProps) => {
  const [rangeKey, setRangeKey] = useState<DateRangeKey>(defaultRange)
  const [customRange, setCustomRange] = useState<PickerDateRange | undefined>()
  const isCustomRangeActive = rangeKey === 'DATE_RANGE'

  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)

  const applyDateRange = (
    rangeKey: DateRangeKey,
    range: PickerDateRange | undefined,
  ) => {
    setRangeKey(rangeKey)
    onApply(rangeKey, range)
  }

  const handleClickRange = (rangeKey: DateRangeKey) => {
    if (rangeKey === 'DATE_RANGE') {
      return setIsComponentVisible(true)
    }
    setCustomRange(undefined)
    applyDateRange(rangeKey, undefined)
  }

  const handleClearCustomRange = () => {
    setCustomRange(undefined)
    setIsComponentVisible(false)
    isCustomRangeActive && applyDateRange(defaultRange, undefined)
  }

  const handleApplyCustomRange = () => {
    setIsComponentVisible(false)
    customRange && applyDateRange('DATE_RANGE', customRange)
  }

  return (
    <div className='flex items-center'>
      {isCustomRangeActive && customRange && (
        <div className='pr-1 text-xs text-red-9 transition-all duration-500'>
          {formatDateRange(customRange)}
        </div>
      )}
      {dateRange.map(range => (
        <div key={range.key} className='first:reletive sticky z-10'>
          <Button
            kind='text'
            size='small'
            className='flex items-center rounded font-normal text-primary-black'
            style={
              range.key === rangeKey ? { backgroundColor: '#0307121A' } : {}
            }
            onClick={() => handleClickRange(range.key)}
          >
            {range.key === 'DATE_RANGE' && (
              <IconCalendar fill={Colors.BASE_ICON} width={16} height={16} />
            )}
            <span className='px-0.5'>{range.name}</span>
          </Button>

          {isComponentVisible && range.key === 'DATE_RANGE' && (
            <FilterMenu
              wrapperClassName='p-4'
              trianglePosition='right'
              containerClassName='right-0'
            >
              <div ref={ref}>
                <DayPicker
                  mode='range'
                  numberOfMonths={2}
                  selected={customRange}
                  onSelect={setCustomRange}
                  captionLayout='dropdown-buttons'
                  fromDate={fromDate}
                />
                <div className='border-b-md flex justify-end gap-2 border-t border-t-opacityGrey-20 pt-4'>
                  <Button
                    kind='text'
                    size='small'
                    className='py-0.5'
                    onClick={handleClearCustomRange}
                  >
                    <span className='px-1 text-sm font-semibold'>Clear</span>
                  </Button>

                  <Button
                    kind='filled'
                    size='small'
                    className='bg-primary-red py-0.5 hover:bg-red-9 active:bg-primary-red/50'
                    onClick={handleApplyCustomRange}
                  >
                    <span className='px-2 text-sm font-semibold'>Apply</span>
                  </Button>
                </div>
              </div>
            </FilterMenu>
          )}
        </div>
      ))}
    </div>
  )
}
