import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { OptionType } from 'types'
import { Input, Select } from 'components'
import { MgmtEntryForm } from 'modules/management/shared'
import {
  tVFilmCompanyTypeOptions,
  tVFilmDistributorTypeOptions,
} from '../../config'
import { TVFilmCompanyType } from '../../types'
import {
  tvFilmCompanyFormResolver,
  type TVFilmCompanyFormData,
} from './tVFilmCompanyFormSchema'

interface TVFilmCompanyFormProps {
  submitButtonLabel: string
  cancelButtonLabel?: string
  defaultValues?: TVFilmCompanyFormData
  onSubmit: (data: TVFilmCompanyFormData) => void
  onClose: () => void
}

export const TVFilmCompanyForm = ({
  submitButtonLabel,
  cancelButtonLabel,
  defaultValues,
  onSubmit,
  onClose,
}: TVFilmCompanyFormProps) => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TVFilmCompanyFormData>({
    resolver: tvFilmCompanyFormResolver,
    defaultValues,
  })

  const [companyType] = watch(['companyType'])

  const renderControlledInput = (
    name: keyof TVFilmCompanyFormData,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value ?? undefined}
          inputClassName='h-8 px-3'
          labelClassName='text-sm font-semibold text-primary-black'
          label={label}
          error={errors[name]?.message}
          isError={!!errors[name]}
          onChange={e => field.onChange(e)}
        />
      )}
    />
  )

  const renderControlledSelect = (
    name: keyof TVFilmCompanyFormData,
    label: string,
    options: OptionType[],
    onChange: (
      filed: ControllerRenderProps<TVFilmCompanyFormData, typeof name>,
      option: OptionType,
    ) => void = (field, option) => field.onChange(option.value),
    isDisabled = false,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          value={field.value ?? undefined}
          size='small'
          alignItems='start'
          errorClass='pt-1.5 text-xs'
          labelClass='pb-1 text-sm font-semibold text-primary-black'
          buttonClass='h-8 items-center whitespace-nowrap'
          hasRadioButton={false}
          label={label}
          isError={!!errors[name]}
          error={errors[name]?.message}
          options={options}
          disabled={isDisabled}
          handleClick={option => onChange(field, option)}
        />
      )}
    />
  )

  return (
    <MgmtEntryForm
      submitButtonLabel={submitButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      {renderControlledInput('name', 'Company Name')}
      {renderControlledInput('abbr', 'Abbreviation')}
      {renderControlledInput('imdbId', 'IMDB ID')}

      {renderControlledSelect(
        'companyType',
        'Company Type',
        tVFilmCompanyTypeOptions,
        (field, option) => {
          field.onChange(option.value)
          if (option.value !== TVFilmCompanyType.FilmDistributor) {
            setValue('distributorType', null)
          }
        },
      )}
      {renderControlledSelect(
        'distributorType',
        'Distributor Type',
        tVFilmDistributorTypeOptions,
        undefined,
        companyType !== TVFilmCompanyType.FilmDistributor,
      )}
    </MgmtEntryForm>
  )
}
