import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Layout } from 'components'
import { RouteType, routes } from 'constnants'
import { PrivateRoute } from 'components/PrivateRoute'
import {
  SignUp,
  Login,
  ForgotPassword,
  ResetPassword,
  PasswordResetEmailSent,
} from 'components'
import { useGetCurrentUserQuery } from 'store/api'
import { useAdminData, useHotjar } from 'hooks'

function App() {
  useHotjar()
  const { data: user } = useGetCurrentUserQuery()
  const [isPasswordResetEmailSent, setIsPasswordResetEmailSent] =
    useState(false)

  const handleForgotPasswordSubmit = (data: any) => {
    console.log(data)
    setIsPasswordResetEmailSent(true)
  }

  const handleResetPasswordSubmit = (data: any) => {
    console.log(data)
    // Handle password reset logic here
  }
  const { isAdminPath } = useAdminData()
  const isAdmin = user?.is_greenlight_admin && isAdminPath

  return (
    <Layout isAdmin={isAdmin}>
      <Routes>
        <Route path='/signup' element={<SignUp />} />
        <Route path='/login' element={<Login />} />
        <Route
          path='/forgot-password'
          element={
            isPasswordResetEmailSent ? (
              <PasswordResetEmailSent />
            ) : (
              <ForgotPassword onSubmit={handleForgotPasswordSubmit} />
            )
          }
        />
        <Route
          path='/reset-password'
          element={<ResetPassword onSubmit={handleResetPasswordSubmit} />}
        />
        {routes.map((route: RouteType, index: number) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute>{route.component}</PrivateRoute>}
            />
          )
        })}
      </Routes>
    </Layout>
  )
}

export default App
