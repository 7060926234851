import { twMerge } from 'tailwind-merge'

interface SectionTitleProps {
  title: string
  className?: string
}

export const SectionTitle = ({ title, className }: SectionTitleProps) => {
  return (
    <span
      className={twMerge('block pb-2 text-xs uppercase text-grey-7', className)}
    >
      {title}
    </span>
  )
}
