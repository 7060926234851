import { useCallback, useMemo, useState } from 'react'
import { CompFinderTableBody, CompFinderTableHeader } from './components'
import { OptionType } from 'types'
import { CompFinderTableProps } from './CompFinderTable.types'
import { twMerge } from 'tailwind-merge'
import { Colors, compFinderRowsOption } from 'constnants'
import { Folder, Plus, Search } from '../ui/icons'
import { Button } from '../ui'
import {
  PaginationChangeParams,
  PaginationToolbar,
} from 'components/FilmTrackingTable'
import { CompFinderGuideTrigger } from './CompFinderGuideTrigger'

export const CompFinderTable = ({
  columns,
  data,
  onSort,
  onSortOrder,
  sortingBY,
  sortingOrder,
  setCurrentPage,
  currentPage = 1,
  rowsPerPage = 50,
  setRowsPerPage,
  totalFilmsCount = 0,
  setSelectAllChecked,
  selectAllChecked,
  filteredFilms,
  selectedRows,
  setSelectedRows,
  handleCheckboxChange,
  activeTableTab,
  isSidebarOpened,
  generateDefaultMovieList,
  isLoading,
  isCompListEmpty,
}: CompFinderTableProps) => {
  const [isTheatersOpened, setIsTheatersOpened] = useState(false)
  const totalRows = totalFilmsCount
  const totalPages = useMemo(
    () => Math.ceil(totalRows / rowsPerPage),
    [totalRows, rowsPerPage],
  )

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage?.(params.page)
      setRowsPerPage?.(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  return (
    <div
      className={
        'flex max-h-[calc(100%-180px)] w-full flex-1 flex-shrink flex-grow flex-col overflow-auto overflow-x-hidden px-3'
      }
    >
      <div
        className={twMerge(
          'flex-1 overflow-auto transition-all duration-300',
          isSidebarOpened
            ? 'w-[calc(100vw_-_400px)]'
            : 'w-[calc(100vw_-_160px)]',
        )}
      >
        {!isLoading && filteredFilms.length === 0 ? (
          <>
            <table className='company-table whitespace-nowrap'>
              <CompFinderTableHeader
                columns={columns}
                onSort={onSort}
                onSortOrder={onSortOrder}
                sortingBY={sortingBY}
                sortingOrder={sortingOrder}
                setSelectAllChecked={setSelectAllChecked}
                selectAllChecked={selectAllChecked}
                filteredFilms={filteredFilms}
                setSelectedRows={setSelectedRows}
                activeTableTab={activeTableTab}
                setIsTheatersOpened={setIsTheatersOpened}
                isTheatersOpened={isTheatersOpened}
              />
            </table>
            {isCompListEmpty ? (
              <div className='mx-auto flex h-[calc(100%_-_65px)] flex-col items-center justify-center'>
                <Folder fill={Colors.GRAY_600} width={48} height={48} />
                <div className='my-4 text-center text-primary-black'>
                  <p className='text-lg font-medium'>Your Comp List is empty</p>
                  <p className='text-sm'>
                    Use the Comp Finder database tab to find comparable films
                    for your project.
                  </p>
                  <p className='text-sm'>
                    If you need help, use our proprietary comp generator to
                    produced a short list
                  </p>
                  <p className='text-sm'>
                    of comparable films to help get you started.
                  </p>
                </div>
                <Button
                  kind='filled'
                  size='medium'
                  className='bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10'
                  onClick={() => {
                    generateDefaultMovieList()
                  }}
                >
                  <div className='flex items-center'>
                    <span className='pr-1 text-sm font-semibold'>
                      Generate Comp List
                    </span>
                    <Plus fill={Colors.ICON_WHITE} width={22} height={22} />
                  </div>
                </Button>
              </div>
            ) : (
              <div className='mx-auto flex h-[calc(100%_-_65px)] flex-col items-center justify-center'>
                <Search fill={Colors.GRAY_600} width={48} height={48} />
                <div className='my-4 text-center text-primary-black'>
                  <p className='text-lg font-medium'>No results found</p>
                  <p className='text-sm'>
                    Please adjust your filters and try again.
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          <table className='company-table whitespace-nowrap'>
            <CompFinderTableHeader
              columns={columns}
              onSort={onSort}
              onSortOrder={onSortOrder}
              sortingBY={sortingBY}
              sortingOrder={sortingOrder}
              setSelectAllChecked={setSelectAllChecked}
              selectAllChecked={selectAllChecked}
              filteredFilms={filteredFilms}
              setSelectedRows={setSelectedRows}
              activeTableTab={activeTableTab}
              setIsTheatersOpened={setIsTheatersOpened}
              isTheatersOpened={isTheatersOpened}
            />
            <CompFinderTableBody
              data={data}
              columns={columns}
              selectedRows={selectedRows}
              handleCheckboxChange={handleCheckboxChange}
              activeTableTab={activeTableTab}
              sortingBY={sortingBY}
              isTheatersOpened={isTheatersOpened}
              isLoading={isLoading}
            />
          </table>
        )}
      </div>
      <div className='-mx-3'>
        <PaginationToolbar
          pageSizeOptions={compFinderRowsOption}
          totalRows={totalRows}
          totalPages={totalPages}
          currentPageSize={rowsPerPage}
          currentPage={currentPage}
          onChange={handlePagination}
          startContent={<CompFinderGuideTrigger />}
        />
      </div>
    </div>
  )
}
