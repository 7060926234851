import { AnchorHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  primary?: boolean
  text?: string
}

export const Anchor = ({
  primary = true,
  text,
  children,
  className,
  ...props
}: AnchorProps) => {
  return (
    <a
      className={twMerge(
        'cursor-pointer border-b text-sm font-medium text-primary-black transition-colors duration-300',
        primary
          ? 'border-primary-black/20 hover:border-primary-white/0 hover:text-primary-black/60'
          : 'border-b-primary-white/0 hover:border-primary-dark',
        className,
      )}
      {...props}
    >
      {text || children}
    </a>
  )
}
