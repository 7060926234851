interface TypeSelectorTitleProps {
  title: string
}

export const TypeSelectorTitle = ({ title }: TypeSelectorTitleProps) => {
  return (
    <h5 className='text-sm font-semibold uppercase leading-6 text-grey-7'>
      {title}
    </h5>
  )
}
