import { twJoin, twMerge } from 'tailwind-merge'

interface FilledWeekItemProps {
  name: string | undefined
  wrapperClassName?: string
  dotClassName?: string
  lineClassName?: string
  hasLine?: boolean
  nameClassName?: string
  releaseType?: string | null
  abbreviation?: string | null
  eventTotalDays?: number
}

export const FilledWeekItem = ({
  name,
  wrapperClassName,
  dotClassName,
  hasLine,
  lineClassName,
  nameClassName,
  releaseType,
  abbreviation,
  eventTotalDays,
}: FilledWeekItemProps) => {
  const lineWidth = eventTotalDays ? `${eventTotalDays * 18 + 2}px` : ''
  return (
    <div className={twJoin('flex w-full', wrapperClassName)}>
      <div
        className={twMerge(
          'flex w-full items-center gap-1',
          !hasLine && 'px-1.5',
        )}
      >
        {!hasLine && (
          <div
            className={twMerge(
              'max-h-1.5 min-w-1.5 rounded-full',
              dotClassName,
            )}
          />
        )}
        <span
          className={twMerge(
            'relative  flex justify-start text-xs font-medium text-primary-black',
            hasLine && 'pl-1',
            releaseType ? 'w-full items-center justify-between' : 'min-w-full',
          )}
        >
          <span className={twMerge(nameClassName)}>{name}</span>
          {abbreviation && <span className='ml-1'>({abbreviation})</span>}
          {releaseType && (
            <span className='ml-1 text-xss font-normal'>({releaseType})</span>
          )}
          {hasLine && (
            <div
              className={twMerge(
                'absolute -bottom-1.5 -right-0.5 left-0 flex items-center',
              )}
              style={{
                width: eventTotalDays && eventTotalDays < 7 ? lineWidth : '',
              }}
            >
              <div className={twMerge('h-2 w-0.5', lineClassName)} />
              <div className={twMerge('h-0.5 flex-1', lineClassName)} />
              <div className={twMerge('mr-[1px] h-2 w-0.5', lineClassName)} />
            </div>
          )}
        </span>
      </div>
    </div>
  )
}
