import { useSelector } from 'react-redux'
import { Select, EntitySearchByField } from 'components'
import { OptionType } from 'types'
import { MgmtCreateButton } from 'modules/management/shared'
import {
  tVFilmCompanyTypeFilterOptions,
  tVFilmDistributorTypeFilterOptions,
} from '../../config'
import { TVFilmCompanyType } from '../../types'
import { tvFilmCompaniesSelectors } from '../../slices'
import { useTVFilmCompaniesActions } from '../../hooks'
import { TVFilmCompanyAddDialog } from '../TVFilmCompanyAddDialog'

interface TVFilmCompaniesToolbarProps {
  onSearchChange: (value: string) => void
}

const renderTypeFilter = <T extends string>(
  options: OptionType<T>[],
  value: T,
  onChange: (value: T) => void,
  disabled = false,
) => (
  <Select
    size='small'
    alignItems='start'
    buttonClass='whitespace-nowrap'
    hasRadioButton={false}
    value={value}
    options={options}
    disabled={disabled}
    handleClick={option => onChange(option.value)}
  />
)

export const TVFilmCompaniesToolbar = ({
  onSearchChange,
}: TVFilmCompaniesToolbarProps) => {
  const actions = useTVFilmCompaniesActions()
  const { companyType, distributorType } = useSelector(
    tvFilmCompaniesSelectors.filters,
  )

  return (
    <div className='flex justify-between border-b border-primary-black/20 p-3'>
      <h1 className={'text-2xl font-semibold text-primary-black'}>
        TV & Film Companies
      </h1>
      <div className='flex items-center gap-4'>
        {renderTypeFilter(
          tVFilmCompanyTypeFilterOptions,
          companyType,
          actions.setCompanyTypeFilter,
        )}

        {renderTypeFilter(
          tVFilmDistributorTypeFilterOptions,
          distributorType,
          actions.setDistributorTypeFilter,
          companyType !== TVFilmCompanyType.FilmDistributor,
        )}

        <EntitySearchByField
          placeholder='Search Name'
          onSearch={onSearchChange}
          onClear={() => onSearchChange('')}
        />

        <TVFilmCompanyAddDialog
          renderTrigger={dialogControls => (
            <MgmtCreateButton
              label='Create New Company'
              onClick={() => dialogControls.activate()}
            />
          )}
        />
      </div>
    </div>
  )
}
