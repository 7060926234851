import { Button } from 'components'
import { Folder, Plus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { twMerge } from 'tailwind-merge'

interface ProjectDetailsEmptyContentProps {
  onOpen?: () => void
  title: string
  descriptions: string
  nameButton: string
  wrapperClassName?: string
  titleClassName?: string
  descriptionClassName?: string
  hasPlus?: boolean
  titleClass?: string
  buttonClassName?: string
  buttonTitleClassName?: string
  widthIcon?: number
  heightIcon?: number
}

export const ProjectDetailsEmptyContent = ({
  onOpen,
  title,
  descriptions,
  nameButton,
  wrapperClassName,
  titleClassName,
  descriptionClassName,
  hasPlus = true,
  titleClass,
  buttonClassName,
  buttonTitleClassName,
  widthIcon = 48,
  heightIcon = 48,
}: ProjectDetailsEmptyContentProps) => {
  return (
    <div
      className={twMerge(
        'flex h-full w-full items-center justify-center bg-grey-2',
        wrapperClassName,
      )}
    >
      <div
        className={twMerge(
          'flex flex-col items-center justify-center',
          titleClassName,
        )}
      >
        <Folder fill={Colors.GRAY_600} width={widthIcon} height={heightIcon} />
        <h4
          className={twMerge(
            'pt-3 text-lg font-normal leading-6 text-primary-black',
            titleClass,
          )}
        >
          {title}
        </h4>
        <span
          className={twMerge(
            'w-3/4 pb-3 text-center text-sm text-primary-black',
            descriptionClassName,
          )}
        >
          {descriptions}
        </span>

        <Button
          kind='filled'
          size='medium'
          className={twMerge(
            'w-fit bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10',
            buttonClassName,
          )}
          onClick={onOpen}
        >
          <div className='flex items-center'>
            <span
              className={twMerge(
                'pr-1 text-sm font-semibold',
                buttonTitleClassName,
              )}
            >
              {nameButton}
            </span>
            {hasPlus && (
              <Plus fill={Colors.ICON_WHITE} width={22} height={22} />
            )}
          </div>
        </Button>
      </div>
    </div>
  )
}
