import { DateRange } from 'types'

export const dateRange: DateRange[] = [
  {
    name: 'Date Range',
    hasIcon: true,
    key: 'DATE_RANGE',
  },
  {
    name: '1M',
    key: 'MONTH',
  },
  {
    name: '3M',
    key: 'QUARTER',
  },
  {
    name: '6M',
    key: 'HALF_YEAR',
  },
  {
    name: '1Y',
    key: 'YEAR',
  },
  {
    name: 'All',
    key: 'ALL',
  },
]
