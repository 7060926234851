import { ColumnsProjectTable, ProjectStatus, ProjectType } from 'types'

export const columnsProjectsData: ColumnsProjectTable[] = [
  {
    title: 'Project name',
    value: 'projectName',
  },
  {
    title: 'Created',
    value: 'createdAt',
  },
  {
    title: 'Updated',
    value: 'updatedAt',
  },
  {
    title: 'Team',
    value: 'team',
  },
  {
    title: 'Project status',
    value: 'projectStatus',
  },
]

export const projectsData: ProjectType[] = [
  {
    id: '1',
    project: { name: 'A Given name', projectId: '1' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
      { name: 'BC', id: '4' },
    ],
    projectStatus: ProjectStatus.CONCEPT,
  },
  {
    id: '2',
    project: { name: 'Q Given name', projectId: '2' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
      { name: 'BC', id: '4' },
    ],
    projectStatus: ProjectStatus.CONCEPT,
  },
  {
    id: '3',
    project: { name: 'W Given name', projectId: '3' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [{ name: 'BC', id: '1' }],
    projectStatus: ProjectStatus.IN_DEVELOPMENT,
  },
  {
    id: '4',
    project: { name: 'E Given name', projectId: '4' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
    ],
    projectStatus: ProjectStatus.IN_DEVELOPMENT,
  },
  {
    id: '5',
    project: { name: 'A Given name', projectId: '5' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
      { name: 'BC', id: '4' },
      { name: 'BC', id: '5' },
      { name: 'BC', id: '6' },
    ],
    projectStatus: ProjectStatus.CONCEPT,
  },
  {
    id: '6',
    project: { name: 'A Given name', projectId: '6' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
      { name: 'BC', id: '4' },
    ],
    projectStatus: ProjectStatus.IN_DEVELOPMENT,
  },
  {
    id: '7',
    project: { name: 'A Given name', projectId: '7' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
      { name: 'BC', id: '4' },
    ],
    projectStatus: ProjectStatus.CONCEPT,
  },
  {
    id: '8',
    project: { name: 'A Given name', projectId: '8' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
      { name: 'BC', id: '4' },
    ],
    projectStatus: ProjectStatus.IN_DEVELOPMENT,
  },
  {
    id: '9',
    project: { name: 'A Given name', projectId: '9' },
    created: '01/18/24',
    updated: '01/18/24',
    team: [
      { name: 'BC', id: '1' },
      { name: 'BC', id: '2' },
      { name: 'BC', id: '3' },
      { name: 'BC', id: '4' },
    ],
    projectStatus: ProjectStatus.CONCEPT,
  },
]
