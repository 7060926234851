import { useState } from 'react'
import {
  AttributeType,
  ChartCategory,
  ChartData,
  ChartDetail,
  CompareDetails,
  SelectedFilters,
} from 'types'
import { TalentMetricsResponse } from '../store/api'
import { formatBaseDate } from '../utils'

type PrepareDataChart = {
  data: TalentMetricsResponse | TalentMetricsResponse[]
  selectedAttributeDropdown: SelectedFilters
  contentType: AttributeType
  isUniqueLabels?: boolean
}

export const useChartData = () => {
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [],
  })
  const [maxChartY, setMaxChartY] = useState(100)
  const [compareDetailsList, setCompareDetailsList] = useState<
    CompareDetails[]
  >([])
  const [preparedChartData, setPreparedChartData] = useState<ChartData>({
    labels: [],
    datasets: [],
  })

  const updateChartData = (data: ChartData[]) => {
    console.log('NEED update', data)
    const tmp_data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'], // Example labels for X-axis
      datasets: [
        {
          label: 'Talent A',
          talentId: 'T001',
          filmId: 'F001',
          values: [65, 59, 80, 81, 56, 55, 40],
          color: 'rgba(75, 192, 192, 0.6)',
        },
        {
          label: 'Talent B',
          talentId: 'T002',
          filmId: 'F002',
          values: [null, 48, 40, 19, 86, 27, 90],
          color: 'rgba(153, 102, 255, 0.6)',
        },
        {
          label: 'Talent C',
          talentId: 'T003',
          filmId: 'F003',
          values: [null, null, 50, 39, 76, 47, 60],
          color: 'rgba(255, 159, 64, 0.6)',
        },
      ],
    }

    setChartData(tmp_data)
  }

  const updateData = (
    data: TalentMetricsResponse,
    selectedAttributeDropdown: SelectedFilters,
    contentType: AttributeType,
  ) => {
    const selectedAttributeType = selectedAttributeDropdown['attribute'][0]
    const selectedDataByType = data.talentMetrics[selectedAttributeType]

    const labelsData: string[] = []
    const valuesData: number[] = []
    const periods: ChartDetail[] = []

    selectedDataByType?.map(attribute => {
      const date = formatBaseDate(attribute.surveyDate, 'MM/DD/YY')
      labelsData.push(date)

      if (contentType === 'percentage') {
        const value = attribute[contentType]
        const deletePercentageSymbol = Number(
          value?.slice(0, value?.length - 1),
        )
        valuesData.push(deletePercentageSymbol)
      } else {
        valuesData.push(attribute[contentType] as number)
      }

      periods.push({
        date: date,
        value: attribute[contentType] as string,
      })
    })

    return {
      talentName: data.talentName,
      talentId: data.talentId,
      labelsData: labelsData,
      valuesData: valuesData,
      periods: periods,
    }
  }

  const onPrepareTalentsDataChart = ({
    data,
    selectedAttributeDropdown,
    contentType,
    isUniqueLabels = true,
  }: PrepareDataChart) => {
    if (Array.isArray(data)) {
      const updatedData = data
        ?.filter(talentMetrics => !!talentMetrics.talentId)
        ?.map(talentMetrics => {
          return updateData(
            talentMetrics,
            selectedAttributeDropdown,
            contentType,
          )
        })

      const labels = updatedData?.map(({ labelsData }) => labelsData).flat()
      const datasets = updatedData?.map(({ talentId, valuesData }) => ({
        label: '',
        talentId: talentId,
        filmId: '',
        values: valuesData,
      }))
      const uniqueLabels = isUniqueLabels ? [...new Set(labels)] : labels
      setPreparedChartData({
        labels: uniqueLabels,
        datasets: datasets,
      })

      const tableList = updatedData?.map(
        ({ talentName, talentId, periods }) => ({
          fullName: talentName ?? 'Name',
          talentId: talentId ?? '',
          periods: periods ?? [],
        }),
      )
      setCompareDetailsList(tableList)
      // setCompareDetailsList([{
      //   fullName: data[0]?.talentName ?? 'Name',
      //   talentId: updatedData[0]?.talentId ?? '',
      //   periods: updatedData[0]?.periods ?? [],
      // }])

      const allValues = updatedData?.map(({ valuesData }) => valuesData)
      const maxValue = Math.max(...allValues.flat())
      setMaxChartY(maxValue < 100 ? 100 : maxValue)

      return
    } else {
      const { labelsData, valuesData, periods } = updateData(
        data,
        selectedAttributeDropdown,
        contentType,
      )

      const maxValue = Math.max(...valuesData)
      setPreparedChartData({
        labels: labelsData,
        datasets: [
          {
            label: '',
            talentId: data.talentId,
            filmId: '',
            values: valuesData,
          },
        ],
      })
      setCompareDetailsList([
        {
          fullName: data?.talentName ?? 'Name',
          talentId: data?.talentId ?? '',
          periods: periods ?? [],
        },
      ])

      setMaxChartY(maxValue < 100 ? 100 : maxValue)
    }
  }

  return {
    maxChartY,
    updateChartData,
    chartData,
    compareDetailsList,
    preparedChartData,
    setChartData,
    onPrepareTalentsDataChart,
  }
}
