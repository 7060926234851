import RangeSlider from 'react-range-slider-input'
import { twMerge } from 'tailwind-merge'
import { moviesFiltersType } from 'types'
import React from 'react'

interface RangeSliderProps {
  min: number
  max: number
  className?: string
  value: moviesFiltersType
  onChange: (value: moviesFiltersType) => void
  step: number
}

export const CustomRangeSlider = ({
  min,
  max,
  className,
  value,
  onChange,
  step,
}: RangeSliderProps) => {
  return (
    <RangeSlider
      className={twMerge('custom-range-slider', className)}
      min={min}
      max={max}
      step={step}
      value={value}
      onInput={onChange}
    />
  )
}
