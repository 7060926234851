import { OptionFilter } from '../types'

export const optionAttributes: OptionFilter[] = [
  {
    id: 'awareness',
    value: 'awareness',
    label: 'Awareness',
    key: 'AWARENESS',
  },
  {
    id: 'fandom',
    value: 'fandom',
    label: 'Fandom',
    key: 'FANDOM',
  },
  {
    id: 'willseetheater',
    value: 'willseetheater',
    label: 'Will See (Theater)',
    key: 'WILL_SEE_THEATER',
  },
  {
    id: 'willseestreaming',
    value: 'willseestreaming',
    label: 'Will See (Streaming)',
    key: 'WILL_SEE_STREAMING',
  },
  {
    id: 'authentic',
    value: 'authentic',
    label: 'Authentic',
    key: 'AUTHENTIC',
  },
  {
    id: 'funny',
    value: 'funny',
    label: 'Funny',
    key: 'FUNNY',
  },
  {
    id: 'likable',
    value: 'likable',
    label: 'Likable',
    key: 'LIKABLE',
  },
  {
    id: 'inspirational',
    value: 'inspirational',
    label: 'Inspirational',
    key: 'INSPIRATIONAL',
  },
  {
    id: 'trendy',
    value: 'trendy',
    label: 'Trendy',
    key: 'TRENDY',
  },
  {
    id: 'goodactor',
    value: 'goodactor',
    label: 'Good Actor',
    key: 'GOODACTOR',
  },
  {
    id: 'sexy',
    value: 'sexy',
    label: 'Sexy',
    key: 'SEXY',
  },
  {
    id: 'overexposed',
    value: 'overexposed',
    label: 'Overexposed',
    key: 'OVEREXPOSED',
  },
  {
    id: 'overrated',
    value: 'overrated',
    label: 'Overrated',
    key: 'OVERRATED',
  },
  {
    id: 'underrated',
    value: 'underrated',
    label: 'Underrated',
    key: 'UNDERRATED',
  },
]

export const optionSocialMedia: OptionFilter[] = [
  {
    id: 'allFollowers',
    value: 'allFollowers',
    label: 'All Followers',
    key: 'SOCIAL_SUBSCRIBER_TOTAL_COUNT',
    isSocial: true,
  },
  {
    id: 'velocity',
    value: 'velocity',
    label: 'Velocity (0.0)',
    key: 'SOCIAL_SUBSCRIBER_VELOCITY',
    isSocial: true,
  },
  {
    id: 'velocityPercent',
    value: 'velocityPercent',
    label: 'Velocity (%)',
    key: 'SOCIAL_SUBSCRIBER_VELOCITY_PERCENTAGE',
    isSocial: true,
    isPercentage: true,
  },
  {
    id: 'mentions',
    value: 'mentions',
    label: 'Mentions',
    key: 'SOCIAL_TOTAL_MENTIONS',
    isSocial: true,
  },
  {
    id: 'sentiment',
    value: 'sentiment',
    label: 'Sentiment (pos, neu, neg)',
    key: 'SOCIAL_SENTIMENT',
    isSocial: true,
    isPercentage: true,
  },
  {
    id: 'reach',
    value: 'reach',
    label: 'Reach',
    key: 'SOCIAL_REACH_TOTAL_COUNT',
    isSocial: true,
  },
]

export const optionSearch: OptionFilter[] = [
  {
    id: 'googleTrends',
    value: 'googleTrends',
    label: 'Google Trends',
    isSocial: true,
    key: 'SOCIAL_GOOGLE_TRENDS',
  },
  {
    id: 'pageViews',
    value: 'pageViews',
    label: 'Wikipedia Page Views',
    key: 'SOCIAL_WIKIPEDIA_TOTAL_COUNT',
    isSocial: true,
  },
  {
    id: 'wikiVelocity',
    value: 'wikiVelocity',
    label: 'Velocity (0.0)',
    key: 'SOCIAL_WIKIPEDIA_VELOCITY',
    isSocial: true,
  },
  {
    id: 'wikiVelocityPercent',
    value: 'wikiVelocityPercent',
    label: 'Velocity (%)',
    key: 'SOCIAL_WIKIPEDIA_VELOCITY_PERCENTAGE',
    isSocial: true,
    isPercentage: true,
  },
]
