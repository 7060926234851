import type { MgmtTableTypes } from 'modules/management/shared'
import { twMerge } from 'tailwind-merge'

export const MgmtTableCell = ({
  children,
  column,
  className,
}: MgmtTableTypes['CellProps']) => (
  <td
    style={{ width: column.width }}
    className={twMerge('px-3 py-2 text-xs font-normal', className)}
  >
    {children}
  </td>
)
