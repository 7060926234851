import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { type IconProps, type OptionType } from 'types'
import { useClickOutsideComponent } from 'hooks'
import { IconButton } from '../IconButton'
import './Input.css'
import { EyeSlash, Eye } from '../icons'
import ResultLookupContainer from './ResultLookupContainer'
import ResultLookupSuccess from './ResultLookupSuccess'
import ResultLookupError from './ResultLookupError'

export interface InputProps<TOptionValue>
  extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean
  error?: string
  label?: ReactNode
  inputClassName?: string
  labelClassName?: string
  wrapperClassName?: string
  containerWrapperClassName?: string
  errorClassName?: string
  rightIcon?: ReactElement<IconProps>
  leftIcon?: ReactElement<IconProps>
  rightContent?: ReactNode
  autocompleteData?: OptionType<TOptionValue>[]
  handleAutocompleteClick?: (value: OptionType<TOptionValue>) => void
  isShowAutocomplete?: boolean
  autocompleteClassName?: string
  notFoundTitle?: string
  notFoundDescription?: string
}

const InputInner = <TOptionValue,>(
  {
    id,
    type,
    disabled,
    label,
    error,
    isError,
    rightIcon,
    leftIcon,
    inputClassName,
    labelClassName,
    wrapperClassName,
    containerWrapperClassName,
    errorClassName,
    rightContent,
    autocompleteData,
    handleAutocompleteClick,
    isShowAutocomplete,
    autocompleteClassName,
    notFoundTitle,
    notFoundDescription,
    ...rest
  }: InputProps<TOptionValue>,
  inputRef: ForwardedRef<HTMLInputElement>,
) => {
  const { value } = rest
  const { isComponentVisible, setIsComponentVisible, ref } =
    useClickOutsideComponent(false)
  const [isShowPassword, setIsShowPassword] = useState(true)
  const isPasswordType = type === 'password'
  const toggleType = isShowPassword ? 'password' : 'text'

  useEffect(() => {
    if (typeof isShowAutocomplete == 'boolean') {
      setIsComponentVisible(isShowAutocomplete)
    }
  }, [isShowAutocomplete, setIsComponentVisible])

  const toggleShowPassword = () => {
    if (!disabled) {
      setIsShowPassword(prev => !prev)
    }
  }

  const focusInput = () => {
    ref.current?.focus()
  }

  return (
    <div
      className={twMerge('flex w-full flex-col', containerWrapperClassName)}
      ref={ref}
    >
      {label && (
        <label htmlFor={id} className={twMerge(`pb-1`, labelClassName)}>
          {label}
        </label>
      )}

      <div
        className={twMerge(
          `flex w-full flex-row items-center overflow-hidden
          rounded border border-primary-black/10 bg-primary-white focus-within:border-primary-black/80 hover:border-primary-black/40 focus:border-primary-black/80`,
          disabled &&
            'cursor-not-allowed border-primary-dark/10 bg-primary-black/[3%] hover:border-primary-black/10 hover:bg-primary-black/[3%]',
          isError && 'border-red-8 bg-red-2 hover:border-red-8',
          wrapperClassName,
        )}
      >
        {leftIcon && (
          <IconButton className='pl-2' disabled={disabled} onClick={focusInput}>
            {leftIcon}
          </IconButton>
        )}

        <input
          {...rest}
          ref={inputRef}
          id={id}
          type={isPasswordType ? toggleType : type}
          disabled={disabled}
          className={twMerge(
            `no-arrows-input-number w-full flex-1 overflow-ellipsis border-0 bg-primary-white text-sm leading-4
            shadow-none placeholder:text-sm placeholder:text-primary-grey
            focus:shadow-none focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-primary-black/[3%]`,
            isError && 'border-red-medium bg-red-light hover:border-red-medium',
            inputClassName,
          )}
        />

        {isComponentVisible &&
          autocompleteData &&
          autocompleteData?.length > 0 && (
            <ResultLookupContainer
              autocompleteClassName={autocompleteClassName}
            >
              <ResultLookupSuccess
                autocompleteData={autocompleteData}
                handleAutocompleteClick={handleAutocompleteClick!}
                setIsComponentVisible={setIsComponentVisible}
              />
            </ResultLookupContainer>
          )}
        {isComponentVisible &&
          autocompleteData &&
          autocompleteData?.length === 0 &&
          value && (
            <ResultLookupContainer
              autocompleteClassName={autocompleteClassName}
            >
              <ResultLookupError
                title={notFoundTitle}
                description={notFoundDescription}
              />
            </ResultLookupContainer>
          )}

        {rightIcon && (
          <div
            className={twMerge(disabled && 'bg-primary-black/[3%]')}
            onClick={focusInput}
          >
            {rightIcon}
          </div>
        )}

        {rightContent && rightContent}

        {isPasswordType && (
          <IconButton className='pr-3' onClick={toggleShowPassword}>
            {isShowPassword ? <EyeSlash /> : <Eye />}
          </IconButton>
        )}
      </div>

      {isError && error && (
        <span
          className={twMerge(
            'pt-1.5 text-xs leading-4 text-red-500',
            errorClassName,
          )}
        >
          {error}
        </span>
      )}
    </div>
  )
}

InputInner.displayName = 'Input'

export const Input = forwardRef(InputInner) as <TOptionValue>(
  props: InputProps<TOptionValue> & {
    ref?: ForwardedRef<HTMLInputElement>
  },
) => ReturnType<typeof InputInner>
