import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeQueryParam } from 'utils'
import { Button, IconTab, SaveSearchResultsTalentModal } from 'components'
import { Talent, ArrowLeft } from 'components/ui/icons'
import { Colors } from 'constnants'
import {
  MetricsType,
  QueryParams,
  useSearchFilter,
  useUpdateUrlParams,
} from 'hooks'
import {
  Urls,
  SortOrderKey,
  SocialSearchFilters,
  TalentSaveSearchResponce,
} from 'types'
import { TalentFilters } from 'pages'
import { TalentLookup } from 'components/TalentLookup'

interface TalentHeaderProps {
  listName: string
  setSelectTab: (value: IconTab<string>) => void
  selectedTableTab: IconTab<string> | undefined
  onChangeContentTypeView: (typeView: string) => void
  onChangeTalentFilter: (query: QueryParams) => void
  setMetricsType: (type: MetricsType) => void
  handleSelectFilters: (filters: QueryParams) => void
  setTopTwoBox: (top2Box: string) => void
  setIsSavedSearchChanged: (value: boolean) => void
  topTwoBox?: string
  sortBy?: string
  sortOrder?: SortOrderKey
  metricsType?: MetricsType
  isMySelectedSavedSearch: boolean
  onResetSavedSearch: () => void
  isSavedSearchChanged: boolean
  socialSearchFilters: SocialSearchFilters
  onSelectNewSavedSearch: (
    newSearch: TalentSaveSearchResponce | undefined,
  ) => void
  setSocialSearchFilters: (
    filterId: keyof SocialSearchFilters,
    filters: SocialSearchFilters,
  ) => void
}

export const TalentHeader = ({
  listName,
  selectedTableTab,
  setSelectTab,
  onChangeTalentFilter,
  onChangeContentTypeView,
  setMetricsType,
  setTopTwoBox,
  handleSelectFilters,
  setIsSavedSearchChanged,
  sortBy,
  sortOrder,
  metricsType,
  socialSearchFilters,
  onSelectNewSavedSearch,
  setSocialSearchFilters,
}: TalentHeaderProps) => {
  const [isOpenSaveSearchModal, setIsOpenSaveSearchModal] =
    useState<boolean>(false)
  const navigate = useNavigate()
  const { handleSaveSearchPreset } = useSearchFilter()
  const handleUpdateUrlParams = useUpdateUrlParams()

  return (
    <div className='flex flex-row items-center justify-between bg-grey-3 p-2.5'>
      <div className='flex flex-row flex-wrap items-center gap-3'>
        <div className='flex items-center'>
          {listName ? (
            <div className='flex items-center'>
              <Button
                className='flex items-center'
                kind='text'
                size='small'
                onClick={() => navigate(Urls.TALENT)}
              >
                <ArrowLeft fill={Colors.BASE_ICON} width={16} height={16} />
              </Button>
              <h2 className='ml-1.5 text-lg font-medium text-primary-grey text-red-9'>
                {listName}
              </h2>
            </div>
          ) : (
            <>
              <Talent
                className='mr-[10px]'
                fill={Colors.ICON_BRAND}
                width={20}
                height={20}
              />
              <h2 className='text-lg font-bold text-primary-grey'>
                Talent Search
              </h2>
            </>
          )}
        </div>
        <div className='relative'>
          <TalentLookup
            onChange={val => {
              handleUpdateUrlParams(undefined, val)
            }}
            onClear={() => {
              const query = removeQueryParam('talentName', location.search)
              navigate(`${query}`)
            }}
          />
        </div>
      </div>

      <TalentFilters
        selectedTableTab={selectedTableTab}
        setIsSavedSearchChanged={setIsSavedSearchChanged}
        onChangeContentTypeView={onChangeContentTypeView}
        setMetricsType={setMetricsType}
        setTopTwoBox={setTopTwoBox}
        setSocialSearchFilters={setSocialSearchFilters}
        setSelectTab={setSelectTab}
        socialSearchFilters={socialSearchFilters}
        setIsOpenSaveSearchModal={setIsOpenSaveSearchModal}
        onChangeTalentFilter={onChangeTalentFilter}
        sortBy={sortBy}
        sortOrder={sortOrder}
        metricsType={metricsType}
        handleSelectFilters={handleSelectFilters}
      />

      <SaveSearchResultsTalentModal
        title='Create new personal search filter'
        isOpen={isOpenSaveSearchModal}
        handleClose={() => setIsOpenSaveSearchModal(false)}
        handleSaveSearchFilters={async searchName => {
          const newSearch = await handleSaveSearchPreset(searchName)
          onSelectNewSavedSearch(newSearch)
          setIsSavedSearchChanged(false)
        }}
        footer={
          <span className='text-sm leading-[20px] text-primary-black'>
            Your personal search filters are <strong>private</strong>, and can
            be used and edited by only you.
          </span>
        }
      />
    </div>
  )
}
