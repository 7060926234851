import { apiURL } from '../store/api/api'

interface testingDataProps {
  id: string
  testName: string
  submitDate: string
  completeDate: string
  status: string
  pdfLink: boolean | string
}

export const fetchTestingList = async (
  testingType: string,
  page: number,
  itemsPerPage: number,
  setIsLoading: (isLoading: boolean) => void,
  setTestingData: (data: testingDataProps[]) => void,
  setTotalTestsCount: (count: number) => void,
) => {
  setIsLoading(true)
  const companyId = localStorage.getItem('companyId')
  const API_Request = `${testingType}/${companyId}?`
  const attributes = [
    API_Request,
    'limit=' + itemsPerPage,
    'page=' + page,
  ].join('&')
  const response = await fetch(apiURL + '/test/' + attributes, {
    method: 'GET',
    headers: {
      Accept: '*/*',
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    },
  })
  const data = await response.json()

  if (data.statusCode === 401) {
    localStorage.removeItem('access_token')
    localStorage.setItem('auth', 'false')
    window.location.reload()
  }

  if (data.tests) {
    setTestingData(
      data.tests.map(
        (test: {
          id: string
          result_url: string | null
          status: string
          created_at: string
          updated_at: string
          name: string
        }) => ({
          id: test.id,
          submitDate: test.created_at,
          completeDate: test.updated_at,
          status: test.status,
          pdfLink: test.result_url,
          testName: test.name,
        }),
      ),
    )
    setTotalTestsCount(data.totalResults)
  }
  setIsLoading(false)
}
