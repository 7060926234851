import React, { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { UsersTableBodyProps } from '../UsersTable.types'
import { AdminUserData } from 'types'
import { UsersTableCell } from './UsersTableCell'
import { MoreVertical } from '../../ui/icons'

export const UsersTableBody = ({
  data,
  columns,
  isLoading,
  setUserProfileOpen,
  handleOpenWarning,
}: UsersTableBodyProps) => {
  const placeholderData = Array.from({ length: 20 }, (_, index) => ({
    id: index,
    userName: 'userName',
    userId: 'userId',
    email: 'email',
    jobTitle: 'jobTitle',
    userCompany: 'name',
    companyId: 'companyId',
    lastLogin: 'lastLogin',
    userType: 'userType',
  }))

  const [dataToMap, setDataToMap] = useState<AdminUserData[]>(data)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      refs.current &&
      !refs.current.some(ref => ref && ref.contains(event.target as Node))
    ) {
      setVisibleComponent(null)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const refs = useRef<(HTMLDivElement | null)[]>([])
  const [visibleComponent, setVisibleComponent] = useState<number | null>(null)

  useEffect(() => {
    setDataToMap(isLoading ? placeholderData : data)
  }, [isLoading, data])

  return (
    <tbody>
      {dataToMap.length > 0 &&
        dataToMap.map((row: AdminUserData, rowIndex) => (
          <tr key={row.userId + '_' + row.id}>
            {columns.map(column => (
              <td
                key={column.value}
                className={twMerge(
                  'w-64 cursor-pointer rounded border-b border-b-primary-black/20 p-2 py-4',
                  isLoading && 'animate-pulse',
                )}
                onClick={() => setUserProfileOpen && setUserProfileOpen(row)}
              >
                <UsersTableCell
                  row={row}
                  column={column}
                  isLoading={isLoading}
                />
              </td>
            ))}
            <td
              className={twMerge(
                'relative w-12 cursor-pointer border-b border-b-primary-black/20 px-2.5 py-4 hover:text-red-10',
                isLoading && 'animate-pulse',
              )}
              onClick={() => {
                setVisibleComponent(rowIndex)
              }}
              ref={el => (refs.current[rowIndex] = el)}
            >
              <MoreVertical fill={'currentColor'} width={16} height={16} />
              {visibleComponent === rowIndex && (
                <div className='absolute right-full top-1/2 z-50 -translate-y-1/2 transform rounded-md bg-primary-white p-3 shadow-md'>
                  <ul className='min-w-[120px] whitespace-nowrap'>
                    <li
                      className='rounded px-1 py-0.5 text-primary-black transition-colors hover:cursor-pointer hover:bg-primary-black/5'
                      onClick={() =>
                        handleOpenWarning(
                          row.lastLogin !== 'Inactive' ? 'pause' : 'reactivate',
                          row.userName,
                          row.id.toString(),
                        )
                      }
                    >
                      {row.lastLogin !== 'Inactive' ? 'Pause' : 'Reactivate'}
                    </li>
                    <li
                      className='rounded px-1 py-0.5 text-red-10 transition-colors hover:cursor-pointer hover:bg-primary-black/5'
                      onClick={() =>
                        handleOpenWarning(
                          'delete',
                          row.userName,
                          row.id.toString(),
                        )
                      }
                    >
                      Delete
                    </li>
                  </ul>
                </div>
              )}
            </td>
          </tr>
        ))}
    </tbody>
  )
}
