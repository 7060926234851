import { twMerge } from 'tailwind-merge'
import { TypeSelectorKind } from 'types'

interface TypeSelectorProps {
  title: string
  isSelected?: boolean
  kind: TypeSelectorKind | undefined
  size?: 'small' | 'large'
  disabled?: string
  onClick?: () => void
}

export const TypeSelector = ({
  title,
  kind,
  disabled,
  onClick,
  size = 'large',
  isSelected,
}: TypeSelectorProps) => {
  return (
    <div
      className={twMerge(
        'flex h-8 items-center justify-center border border-primary-black/80 bg-primary-white text-sm font-semibold leading-6 text-primary-grey hover:cursor-pointer',
        kind === 'oval' ? 'rounded-full' : 'rounded',
        disabled && 'opacity-50 hover:capitalize',
        size === 'large' ? 'w-[140px]' : 'w-[80px]',
        isSelected && 'border-0 bg-primary-blue-dark text-primary-white',
      )}
      onClick={onClick}
    >
      {title}
    </div>
  )
}
