import { twMerge } from 'tailwind-merge'
import React, { useState } from 'react'

import { useIsMobile } from 'hooks'
import { Avatar, Breadcrumb, Sidebar, IconButton } from 'components'
import {
  Bell,
  ChevronRight,
  CloseMenu,
  Logo,
  OpenMenu,
} from 'components/ui/icons'
import { Colors } from 'constnants'

interface HeaderProps {
  isOpenSidenav?: boolean
  toggleSettings: () => void
  isAdmin?: boolean
  closeSideNavigation?: () => void
  openSideNavigation?: () => void
}

export const Header = ({
  isOpenSidenav,
  toggleSettings,
  isAdmin = false,
  closeSideNavigation,
  openSideNavigation,
}: HeaderProps) => {
  const isMobile = useIsMobile()
  const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useState<boolean>(false)

  const handleToggle = () => {
    setIsOpenMobileSidebar(!isOpenMobileSidebar)
  }

  const handleToggleMainNavigation = () => {
    if (isOpenSidenav) {
      closeSideNavigation && closeSideNavigation()
    } else {
      openSideNavigation && openSideNavigation()
    }
  }

  return (
    <header
      className={twMerge(
        'flex bg-primary-blue px-2 py-2.5 transition-all duration-300',
        isOpenSidenav && !isMobile && !isAdmin ? 'ml-44' : 'ml-10',
        isMobile && 'ml-0',
        isAdmin && 'z-[51] ml-2 items-center',
      )}
    >
      <div className={twMerge('flex gap-2', isAdmin && 'items-center')}>
        {isMobile && <Logo width={26} height={28} />}
        {isAdmin && (
          <>
            {!isMobile && isAdmin && (
              <Logo width={30} height={32} className={'shrink-0'} />
            )}
            <p className={'mr-6 font-medium uppercase text-primary-white'}>
              Admin
            </p>
          </>
        )}
        {isAdmin && !isMobile && (
          <IconButton
            onClick={handleToggleMainNavigation}
            className={twMerge(
              'rounded-md p-1.5 transition-all duration-300 hover:bg-white/20',
            )}
          >
            {isOpenSidenav ? (
              <CloseMenu fill={Colors.NAV_ICON_ONE} width={20} height={20} />
            ) : (
              <OpenMenu fill={Colors.NAV_ICON_ONE} width={20} height={20} />
            )}
          </IconButton>
        )}
        {isMobile && (
          <IconButton
            onClick={handleToggle}
            className={twMerge(
              'rounded-md p-1.5 transition-all duration-300 hover:bg-white/20',
            )}
          >
            {isOpenMobileSidebar ? (
              <CloseMenu fill={Colors.NAV_ICON_ONE} width={20} height={20} />
            ) : (
              <OpenMenu fill={Colors.NAV_ICON_ONE} width={20} height={20} />
            )}
          </IconButton>
        )}
      </div>

      {isMobile && (
        <Sidebar
          isOpenMob={isOpenMobileSidebar}
          topGap={48}
          handleToggleMobile={handleToggle}
          toggleSettings={toggleSettings}
        />
      )}

      <div
        className={twMerge(
          'flex w-full flex-row items-center justify-between pl-2',
          isMobile && 'justify-end',
        )}
      >
        {!isMobile && (
          <Breadcrumb
            isAdmin={isAdmin}
            separator={
              <ChevronRight fill={Colors.NAV_ICON_TWO} width={16} height={16} />
            }
          />
        )}

        <div className='flex gap-2'>
          {/*{!isAdmin && (*/}
          {/*  <IconButton className='flex items-center justify-center  px-1 hover:bg-primary-white/10 hover:p-1'>*/}
          {/*    <Bell fill={Colors.NAV_ICON_ONE} width={20} height={20} />*/}
          {/*  </IconButton>*/}
          {/*)}*/}
          <Avatar enableClick />
        </div>
      </div>
    </header>
  )
}
