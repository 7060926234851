import { OptionType } from '../../types'

interface ExistingMyListsProps {
  list: OptionType[]
  classNameParentVal?: string
  handleAddToExistingList: (listId: string, listLabel: string) => void
}

export const ExistingMyLists = ({
  list,
  classNameParentVal,
  handleAddToExistingList,
}: ExistingMyListsProps) => {
  return (
    <ul
      className={
        classNameParentVal ||
        'flex max-h-72 flex-col space-y-1 overflow-y-auto px-1 py-2'
      }
    >
      {list.map(({ id, label }) => (
        <li
          onClick={() => handleAddToExistingList(id, label)}
          key={id}
          className={
            'rounded px-2 py-[5px] text-start text-sm leading-6 text-primary-black hover:cursor-pointer hover:bg-primary-black/5'
          }
        >
          {label}
        </li>
      ))}
    </ul>
  )
}
