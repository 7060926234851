import { useEffect, useMemo } from 'react'
import {
  Select,
  FilterSearchButton,
  Input,
  SearchListMenu,
  AddToExistingContent,
} from 'components'
import {
  initialOptions,
  percentagesOptions,
  topOptions,
} from 'constnants/selectOptions'
import { List, SquarePlus, Star, Search, Percent } from 'components/ui/icons'
import { Colors } from 'constnants'
import {
  useClickOutsideComponent,
  useSearchFilter,
  useSelectOptions,
} from 'hooks'
import useTalentSimple from '../../hooks/useTalentSimple'
import { useSearchParams } from 'react-router-dom'
import { removeSubstring } from 'utils'

interface TalentDetailContentHeaderProps {
  onAddTalentToCompare: (talentId: string) => void
  onChangeContentTypeView: (typeView: string) => void
  onChangeTop2Box: (top2Box: string) => void
  talentsCount: number
  selectedIds?: string[]
}

export const TalentDetailContentHeader = ({
  onAddTalentToCompare,
  onChangeContentTypeView,
  onChangeTop2Box,
  talentsCount,
  selectedIds,
}: TalentDetailContentHeaderProps) => {
  const [searchParams] = useSearchParams()
  const talentId = searchParams.get('talentId')
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const {
    ref: refExisting,
    isComponentVisible: isExistingComponentVisible,
    setIsComponentVisible: setIsExistingComponentVisible,
  } = useClickOutsideComponent(false)
  const { talentsList, getTalentsList } = useTalentSimple(true)

  const { searchTerm, debouncedValue, handleSearchChange, setSearchTerm } =
    useSearchFilter()
  const { selects, handleSelectOptions } = useSelectOptions(initialOptions, 2)

  useEffect(() => {
    debouncedValue && getTalentsList(debouncedValue)
  }, [debouncedValue])

  const handleCompare = (talentId: string) => {
    onAddTalentToCompare(talentId)
    setIsComponentVisible(false)
    setSearchTerm('')
  }

  const talentsOptions = useMemo(() => {
    const filteredTalents = talentsList?.filter(
      talent => !selectedIds?.includes(talent.talent_id.toString()),
    )
    return (
      filteredTalents?.map(({ talent_id, talent_name }) => ({
        id: talent_id,
        label: talent_name,
        value: talent_id,
      })) || []
    )
  }, [talentsList, selectedIds])
  const talentsIds = talentId ? [talentId] : null

  return (
    <div className='flex h-[40px] items-center justify-between bg-grey-2 px-2'>
      <div className='flex gap-2'>
        <FilterSearchButton
          name='Add to existing List'
          isComponentVisible={isExistingComponentVisible}
          setIsComponentVisible={() =>
            setIsExistingComponentVisible(prevState => !prevState)
          }
          leftIcon={<Star fill={Colors.BASE_ICON} width={16} height={16} />}
          ref={refExisting}
          disabled={!talentId}
        >
          <AddToExistingContent
            selectedTalents={talentsIds}
            close={() => setIsExistingComponentVisible(false)}
          />
        </FilterSearchButton>
        <FilterSearchButton
          name='Add Talent to compare'
          leftIcon={<SquarePlus fill={Colors.BASE_ICON} />}
          isComponentVisible={isComponentVisible}
          setIsComponentVisible={() =>
            setIsComponentVisible(prevState => !prevState)
          }
          ref={ref}
          disabled={talentsCount >= 4}
        >
          <div className='pt-3'>
            <Input
              value={searchTerm}
              onChange={e => handleSearchChange(e)}
              leftIcon={
                <Search fill={Colors.BASE_ICON} width={16} height={16} />
              }
              containerWrapperClassName='px-3'
            />
            <SearchListMenu
              list={talentsOptions}
              handleChange={({ id }) => {
                handleCompare(removeSubstring(id.toString()))
                setSearchTerm('')
              }}
            />
          </div>
        </FilterSearchButton>
      </div>
      <div className='flex gap-2'>
        <Select
          value={selects['percentages']}
          handleClick={option => {
            onChangeContentTypeView(option.value)
            handleSelectOptions(option, 'percentages')
          }}
          options={percentagesOptions}
          size='small'
          leftIcon={
            selects['percentages'] === 'percentage' ? (
              <Percent fill={Colors.BASE_ICON} />
            ) : (
              <div></div>
            )
          }
        />
        <Select
          value={selects['top']}
          handleClick={option => {
            handleSelectOptions(option, 'top')
            onChangeTop2Box(option.value)
          }}
          options={topOptions}
          size='small'
          leftIcon={<List fill={Colors.BASE_ICON} width={16} height={16} />}
        />
      </div>
    </div>
  )
}
