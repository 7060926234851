import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { TestingManagementTableCell } from './TestingManagementTableCell'
import { TestingManagementTableBodyProps } from '../../../types'

export const TestingManagementTableBody = ({
  data,
  columns,
  isLoading,
  setTestingItemOpen,
}: TestingManagementTableBodyProps) => {
  const placeholderData = Array.from({ length: 20 }, (_, index) => ({
    id: index,
    testName: 'testName',
    submitDate: 'submitDate',
    completeDate: 'completeDate',
    status: 'status',
    company: 'company',
    project: 'project name project name',
  }))

  const [dataToMap, setDataToMap] = useState(data)

  useEffect(() => {
    setDataToMap(isLoading ? placeholderData : data)
  }, [isLoading, data])

  return (
    <tbody>
      {dataToMap.length > 0 &&
        dataToMap.map(row => (
          <tr key={row.testName + '_' + row.id} className='project-row'>
            {columns.map(column => (
              <td
                key={column.value}
                className={twMerge(
                  'w-[calc(100%/6)] cursor-pointer rounded border-b border-b-primary-black/20 p-2 py-4',
                  isLoading && 'animate-pulse',
                )}
                onClick={() => setTestingItemOpen && setTestingItemOpen(row)}
              >
                <TestingManagementTableCell
                  row={row}
                  column={column}
                  isLoading={isLoading}
                />
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  )
}
