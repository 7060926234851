import { Image, FilmTitle, FilmCast, Anchor } from 'components'
import { Colors } from 'constnants'
import { type Cast, type FilmMetricsByFilmId } from 'types'
import { SectionTitle } from '../SectionTitle'
import { Url } from '../ui/icons'

interface FilmDetailProps {
  filmData: FilmMetricsByFilmId
}

const formatReleaseDate = (day: number, month: number, year: number) =>
  `${month}/${day}/${year}`

const convertSecondsToHoursMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return `${hours}h ${minutes}m`
}

export const FilmDetail = ({ filmData }: FilmDetailProps) => {
  if (!filmData) return null

  const {
    film_name,
    primary_image_url,
    imdb_url,
    companies_data,
    rating,
    release_day,
    release_month,
    release_year,
    cast,
    runtime,
    genre_name,
    film_group_name,
  } = filmData

  const releaseDate = formatReleaseDate(
    release_day!,
    release_month!,
    release_year!,
  )
  const companyName = companies_data?.[0]?.company_name || '--'

  return (
    <div className='h-full w-64 space-y-5 bg-primary-white px-3 py-2'>
      <div className='flex flex-col items-start gap-2'>
        <FilmTitle title={film_name!} size='lg' />
        <Image
          alt={film_name!}
          src={primary_image_url!}
          className='h-[95px] w-[63px]'
        />
        <p className='text-xs text-primary-grey'>
          {release_year} - {genre_name} -{' '}
          {convertSecondsToHoursMinutes(runtime!)}
        </p>
        <span className='rounded-sm border px-1 text-xs text-grey-7'>
          {rating}
        </span>
        <div>
          <p className='text-xs text-primary-grey'>{`Date: ${releaseDate}`}</p>
          <p className='text-xs text-primary-grey'>{`Distribution: ${companyName}`}</p>
          <p className='text-xs text-primary-grey'>{`Group: ${film_group_name || '--'}`}</p>
        </div>
      </div>
      <FilmCast casts={cast as Cast[]} title='Featured Cast' />
      <div>
        <SectionTitle title='IMDB Profile' className='pb-5' />
        <Anchor
          href={imdb_url!}
          target='_blank'
          className='flex w-fit items-center gap-0.5'
        >
          <span>View full profile on IMDB</span>
          <Url fill={Colors.BASE_ICON} width={12} height={12} />
        </Anchor>
      </div>
    </div>
  )
}
