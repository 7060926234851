import { type ColoredFilmMetric, type FilmMetric } from 'types'
import { topColumnsFilms } from 'constnants/filmsTableData'
import ButtonComponent from './ButtonComponent'

interface FilmDetailContentHeaderProps {
  onActiveButton: (key: string) => void
  activeButton: string
  metrics?: FilmMetric[]
}

export const FilmDetailContentHeader = ({
  onActiveButton,
  activeButton,
  metrics = [],
}: FilmDetailContentHeaderProps) => {
  const coloredMetrics =
    metrics?.map((metric, index): ColoredFilmMetric => {
      return {
        ...metric,
        primaryColor: topColumnsFilms[index].primaryColor,
        secondaryColor: topColumnsFilms[index].secondaryColor || '#EDF4FC',
      }
    }) || []

  const renderButton = (metric: ColoredFilmMetric, isTwoLines = false) => (
    <ButtonComponent
      key={metric.key}
      id={metric.key}
      label={metric.label}
      value={String(metric.value)}
      primaryColor={metric.primaryColor}
      labelSize={isTwoLines ? 'xs' : 'sm'}
      activeButton={activeButton}
      onActiveButton={onActiveButton}
    />
  )

  return (
    <div className='flex justify-start bg-primary-white py-3 text-primary-black'>
      <div className='mr-5 flex gap-1.5'>
        {coloredMetrics.slice(0, 4).map(metric => renderButton(metric))}
      </div>
      <div className='flex gap-1.5'>
        {coloredMetrics.slice(4).map(metric => {
          const isTwoLines =
            metric.key === '' || metric.key === 'awarenessinterest'
          return renderButton(metric, isTwoLines)
        })}
      </div>
    </div>
  )
}
