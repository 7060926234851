import { useEffect, useState } from 'react'
import { useGetCurrentUserQuery } from 'store/api'
import { routes } from '../constnants'

export const useAdminData = () => {
  const { data: user } = useGetCurrentUserQuery()
  const [isAdminPath, setIsAdminPath] = useState(false)
  const pathName = window.location.pathname
  const menuType = () => {
    const isAdminPath = routes.some(
      route => route.isAdminNavigation && route.path === pathName,
    )
    if (!isAdminPath && user?.is_greenlight_admin) {
      localStorage.setItem('defaultMenu', 'true')
    } else {
      localStorage.removeItem('defaultMenu')
    }
    setIsAdminPath(isAdminPath)
  }

  useEffect(() => {
    menuType()
  }, [user, pathName])

  return { isAdminPath }
}
