import { RevenueData } from 'types'
import { twMerge } from 'tailwind-merge'

interface BudgetRowProps {
  data: RevenueData[] | null
  wrapperBudgetClassName?: string
}

export const BudgetRow = ({ data, wrapperBudgetClassName }: BudgetRowProps) => {
  if (!data) return null
  return (
    <div className='flex w-fit flex-col border-r'>
      {data?.map((item, rowIndex) => (
        <div key={`${item.label}_${rowIndex}`} className='flex'>
          <div className='h-full w-2 bg-grey-4' />
          <div className='flex bg-grey-1 first:border-t'>
            <div className='flex h-full w-12 items-center border-r bg-grey-2 pl-2 text-xs font-medium text-grey-7'>
              {item.label}
            </div>

            <div className={twMerge('flex', wrapperBudgetClassName)}>
              <div className='flex gap-0.5 px-2'>
                {item.events &&
                  item.events.map((budget, colIndex) => (
                    <div
                      key={`${rowIndex}_${colIndex}`}
                      className='flex h-5 w-[124px] items-center justify-center bg-green-1 text-center text-xss font-medium text-green-7 first:w-[117px] last:w-[117px] last:border-0'
                    >
                      {budget.budget}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
