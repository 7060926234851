import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  OptionType,
  SavedSearchTypes,
  TalentSaveSearchQueryParams,
} from '../types'
import { commonToastStyles } from 'utils'
import {
  useSaveSearchPresetMutation,
  useSaveTelentListMutation,
  useEditSavedSearchPresetMutation,
  useEditTalentsListMutation,
} from 'store/api'
import { useTypedSelector, useUpdateUrlParams } from 'hooks'
import { toast } from 'react-toastify'

const defaultOptions: OptionType<any>[] = []

export const useSearchFilter = <TOptionTypeValue>(
  options: OptionType<TOptionTypeValue>[] = defaultOptions,
) => {
  const { selectedTalents } = useTypedSelector(state => state.talentReducer)
  const [searchTerm, setSearchTerm] = useState('')

  const [debouncedValue, setDebouncedValue] = useState('')
  const [saveSearchPreset] = useSaveSearchPresetMutation()
  const [saveTelentList] = useSaveTelentListMutation()
  const [editSearchPreset] = useEditSavedSearchPresetMutation()
  const [editTalentsList] = useEditTalentsListMutation()
  const [searchParams] = useSearchParams()
  const handleUpdateUrlParams = useUpdateUrlParams()

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedValue(searchTerm)
    }, 750)

    return () => clearTimeout(delayInputTimeoutId)
  }, [searchTerm])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target as HTMLInputElement) {
      setSearchTerm(e.target.value)
    }
  }

  const handleSaveSearchPreset = async (
    searchName: string,
    projectId?: number,
    roleId?: string,
    toastText: React.ReactNode = `New Search added `,
  ) => {
    const searchParameters = Object.fromEntries(searchParams.entries())
    const talentGender = searchParams.getAll('talentGender')
    const talentAge = searchParams.getAll('talentAge')
    const talentRace = searchParams.getAll('talentRace')

    searchParameters.talentGender = talentGender.length > 0 ? talentGender : []
    searchParameters.talentAge = talentAge.length > 0 ? talentAge : []
    searchParameters.talentRace = talentRace.length > 0 ? talentRace : []

    const searchDef = {
      name: searchName,
      searchParameters,
      projectId: projectId ?? null,
      roleId: roleId ?? null,
    }

    const { data } = await saveSearchPreset(searchDef)
    toast.success(toastText, { ...commonToastStyles })
    return data
  }

  const handleEditSearchPreset = (
    id: string,
    name: string,
    searchParameters?: TalentSaveSearchQueryParams,
    toastText: React.ReactNode = `New Search added `,
    noToast = false,
  ) => {
    editSearchPreset({
      id,
      name,
      searchParameters,
    })
      .unwrap()
      .then(() => {
        if (!noToast) {
          toast.success(toastText, { ...commonToastStyles })
        }
      })
  }

  const filteredOptions = useMemo(
    () =>
      options?.filter(option =>
        option.label
          .toLowerCase()
          .replace(/\s/g, '')
          .includes(searchTerm.toLowerCase()),
      ) || [],
    [searchTerm, options, setSearchTerm],
  )

  return {
    searchTerm,
    debouncedValue,
    handleSearchChange,
    handleSaveSearchPreset,
    handleEditSearchPreset,
    filteredOptions,
    setSearchTerm,
  }
}
