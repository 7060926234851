import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import {
  defineCreateTVFilmCompanyEndpoint,
  defineGetTVFilmCompaniesDataEndpoint,
  defineUpdateTVFilmCompanyEndpoint,
} from './mock-data/tVFilmCompanyMockData'
import { defineGetTVManagementDataEndpoint } from './mock-data/tVManagementMockData'

const noOpBaseQuery: BaseQueryFn = async () => {
  return { data: null }
}

export const mockApi = createApi({
  reducerPath: 'mockApi',
  baseQuery: noOpBaseQuery,
  endpoints: builder => ({
    getTVFilmCompaniesData: defineGetTVFilmCompaniesDataEndpoint(builder),
    addTVFilmCompany: defineCreateTVFilmCompanyEndpoint(builder),
    updateTVFilmCompany: defineUpdateTVFilmCompanyEndpoint(builder),
    getTVManagementData: defineGetTVManagementDataEndpoint(builder),
  }),
})

export const {
  useGetTVFilmCompaniesDataQuery,
  useAddTVFilmCompanyMutation,
  useUpdateTVFilmCompanyMutation,
  useGetTVManagementDataQuery,
} = mockApi
