export const formatNumberWithSuffix = (input: number | string): string => {
  if (!input) {
    return '0'
  }
  if (typeof input === 'string' && input.includes('%')) {
    const num = parseFloat(input.trim().replace('%', ''))

    const rounded = num.toFixed(1)

    return parseFloat(rounded) === 0 ? '0%' : `${rounded}%`
  }

  const num = typeof input === 'string' ? parseFloat(input) : input

  if (isNaN(num)) {
    return input.toString()
  }

  const isNegative = num < 0
  const absNum = Math.abs(num)

  let formattedNum: string

  if (absNum >= 1000000000) {
    formattedNum = (absNum / 1000000000).toFixed(1) + 'B'
  } else if (absNum >= 1000000) {
    formattedNum = (absNum / 1000000).toFixed(1) + 'M'
  } else if (absNum >= 1000) {
    formattedNum = (absNum / 1000).toFixed(1) + 'K'
  } else {
    formattedNum = absNum.toString()
  }

  return isNegative ? `-${formattedNum}` : formattedNum
}
