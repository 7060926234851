import { useState, useEffect } from 'react'

// Debounce Hook
export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value === debouncedValue) return // Skip debounce if value
      // Skip debounce if value is empty
      if (value === '' || value.length < 3) {
        setDebouncedValue('')
        return
      }

      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler) // Cleanup timeout if value changes before delay ends
    }
  }, [value, delay])

  return debouncedValue
}
