import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type WrapperAdditionalMenuProps = PropsWithChildren & {
  wrapperClassName?: string
}

export const WrapperAdditionalMenu = ({
  children,
  wrapperClassName,
}: WrapperAdditionalMenuProps) => {
  return (
    <div
      className={twMerge(
        'absolute z-50 h-[120px] w-40 rounded-md border border-primary-black/10 bg-primary-white shadow-md',
        wrapperClassName,
      )}
    >
      {children}
    </div>
  )
}
