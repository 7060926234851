import { Tooltip } from 'react-tooltip'

import { TooltipIcon } from 'components/ui'
import { Colors } from 'constnants'

export const Title = () => {
  return (
    <p className='flex gap-1 px-2 pb-2 pt-1 text-sm font-medium text-primary-black'>
      <span>Opening Weekend Projection</span>
      <TooltipIcon
        fill={Colors.BASE_ICON}
        width={20}
        height={20}
        data-tooltip-id='weekend-projection-tooltip'
      />
      <Tooltip
        id='weekend-projection-tooltip'
        place='top'
        content={'Opening weekend projection by days from release'}
        variant='dark'
      />
    </p>
  )
}

export const SubTitle = ({ label }: { label: string }) => {
  return <div className='p-2 text-xs font-medium'>{label}</div>
}
