import { OptionType } from 'types'

import {
  TVFilmCompanyTypeFilterValue,
  TVFilmDistributorTypeFilterValue,
} from '../types'

import {
  tVFilmCompanyTypeOptions,
  tVFilmDistributorTypeOptions,
} from './tVFilmTypeOptions'

export const tVFilmDistributorTypeFilterOptions: OptionType<TVFilmDistributorTypeFilterValue>[] =
  [
    { id: 'all', value: 'all', label: 'All Distributor Types' },
    ...tVFilmDistributorTypeOptions,
  ]

export const tVFilmCompanyTypeFilterOptions: OptionType<TVFilmCompanyTypeFilterValue>[] =
  [
    { id: 'all', value: 'all', label: 'All Company Types' },
    ...tVFilmCompanyTypeOptions,
  ]
