import React, { ChangeEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import {
  ArrowLeft,
  CheckCircle,
  Pencil,
  Plus,
  Pause,
  Play,
  Trash,
  ChevronDown,
} from 'components/ui/icons'
import { Colors } from 'constnants'
import { BaseModal, Button, Input, Select, ToggleSwitch } from '../ui'
import { UsersTable, UsersTableProps } from '../UsersTable'
import { DeleteModal } from '../DeleteModal'
import { FilterColumnGroup } from '../FilterColumnGroup'
import { FilterButton } from '../FilterButton'
import {
  useGetUserProjectsQuery,
  useGetProjectCompanyByIdQuery,
  useUpdateUserProjectsMutation,
  useDeleteUserProjectMutation,
  useUpdatedCompanyMutation,
  useGetCompanyByIdQuery,
  useUpdatedUserMutation,
  useDeleteUserMutation,
  useUpdatedCurrentUserMutation,
  useGetUserPermissionQuery,
} from 'store/api'
import { toastError } from 'utils'
import { OptionFilter, Project } from '../../types'

interface warningData {
  title: string
  description: string
  deleteTitle: string
  onConfirm?: () => void
  type: string
  deleteClassName?: string
  userToEdit: string
}

const initialData = {
  title: '',
  description: '',
  deleteTitle: '',
  type: '',
  deleteClassName: '',
  userToEdit: '',
}

const regExpEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validateEmail = (email: string) => ({
  validEmail: regExpEmail.test(String(email.toLowerCase())),
})

const accessLevelOptions = [
  [
    {
      id: '1',
      value: 'editor',
      label: 'Editor',
    },
    {
      id: '2',
      value: 'viewer',
      label: 'Viewer',
    },
  ],
  [
    {
      id: '1',
      value: 'superadmin',
      label: 'Super Admin',
    },
    {
      id: '2',
      value: 'moduleadmin',
      label: 'Module Admin',
    },
  ],
]

export const SettingsModal = () => {
  const companyId = localStorage.getItem('companyId')
  /*TODO replace testCompanyId with user companyId */
  const testCompanyId = '7a9a0a34-f892-4c20-8353-5caa4daa95c1'
  const [updatedUserProjects] = useUpdateUserProjectsMutation()
  const [deleteUserProject] = useDeleteUserProjectMutation()
  const [updatedCompany] = useUpdatedCompanyMutation()
  const [updatedUser] = useUpdatedUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [updatedCurrentUser] = useUpdatedCurrentUserMutation()
  const { data: companyProjectsList } = useGetProjectCompanyByIdQuery(
    {
      id: companyId ?? '',
    },
    { skip: !companyId },
  )
  const { data: companyData } = useGetCompanyByIdQuery(
    {
      id: testCompanyId ?? '',
    },
    { skip: !testCompanyId },
  )
  const [projectsList, setProjectsList] = useState<OptionFilter[]>([])
  const [activeMenu, setActiveMenu] = useState('business')
  const [companyName, setCompanyName] = useState('')
  const [corporateDomain, setCorporateDomain] = useState('')
  const [editField, setEditField] = useState<string>('')
  const [userAbleToEdit, setUserAbleToEdit] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [shareLink, setShareLink] = useState<boolean>(false)
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false)
  const [isUserUpdating, setIsUserUpdating] = useState<boolean>(false)
  const [userProfileOpen, setUserProfileOpen] = useState<UsersTableProps>({
    id: '',
    name: '',
    email: '',
    last_login: '',
    date_joined: '',
    projects: [{ id: 0, name: '' }],
    module_access: [2, 3, 4, 5],
    access_level: '',
    user_type: '',
  })
  const { data: userPermission } = useGetUserPermissionQuery(
    {
      userId: userProfileOpen.id.toString() || '',
    },
    { skip: !userProfileOpen.id },
  )

  const { data: userProjects, error } = useGetUserProjectsQuery(
    {
      userId: userProfileOpen.id.toString() || '',
    },
    { skip: !userProfileOpen.id },
  )

  useEffect(() => {
    if (companyProjectsList) {
      let filteredData = companyProjectsList
      if (Array.isArray(userProjects) && userProjects.length) {
        filteredData = companyProjectsList.filter(
          (project: Project) =>
            !userProjects.some(
              (userProject: { company_project_id: number }) =>
                userProject.company_project_id ===
                Number(project.company_project_id),
            ),
        )
      }
      setProjectsList(
        filteredData.map((project: Project) => ({
          id: project.company_project_id || '0',
          value: project.company_project_id || '0',
          label: project.name || '',
        })),
      )
    }
  }, [companyProjectsList, userProjects])

  useEffect(() => {
    if (companyData) {
      setCompanyName(companyData.name)
      setCorporateDomain(companyData.domain_name)
      setShareLink(companyData.is_guest_link_allowed)
      setUserAbleToEdit(companyData.owner_id === localStorage.getItem('userId'))
      setInputValues(prevState => ({
        ...prevState,
        name: companyData.name,
        corporateDomain: companyData.domain_name,
      }))
    }
  }, [companyData])

  const [warningModalData, setWarningModalData] =
    useState<warningData>(initialData)
  const [inputValues, setInputValues] = useState({
    name: companyName,
    corporateDomain: corporateDomain,
    userName: userProfileOpen.name,
    userEmail: userProfileOpen.email,
    userType: userProfileOpen.user_type,
    userAccessLevel: userProfileOpen.access_level,
    userProjects: userProfileOpen.projects,
    userDateJoined: userProfileOpen.date_joined,
    userModuleAccess: userProfileOpen.module_access,
  })

  const [validation, setValidation] = useState({
    validEmail: true,
    isAccessLevel: false,
  })
  const [openAddProjects, setOpenAddProjects] = useState<boolean>(false)
  const [newUserProjects, setNewUserProjects] = useState<string[]>([])
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputValues,
  ) => {
    const { value } = e.target
    setInputValues(prevState => ({ ...prevState, [key]: value || '' }))
  }

  const handleSelect = (
    option: { id: string; value: string; label: string },
    key: string,
  ) => {
    setInputValues(prevState => ({ ...prevState, [key]: option.value }))
    if (key === 'userType') {
      setInputValues(prevState => ({ ...prevState, userAccessLevel: '' }))
    }
  }

  const clearStatuses = () => {
    setEditField('')
    setShowSuccessMessage(true)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
    }, 3000)
  }

  const updateCompany = (
    data: {
      name?: string
      domain_name?: string
      is_guest_link_allowed?: boolean
    },
    type: 'name' | 'domain' | 'link',
    field?: string,
  ) => {
    try {
      updatedCompany({
        id: 1,
        data,
      })
        .unwrap()
        .then(() => {
          if (type === 'name') {
            setCompanyName(data.name || '')
          } else if (type === 'domain') {
            setCorporateDomain(data.domain_name || '')
          }
          if (type === 'name' || type === 'domain') {
            setSuccessMessage(field + ' updated')
            clearStatuses()
          }
        })
    } catch (e) {
      const { error, data } = e as { data?: any; error?: string }
      toastError(data, error)
      if (type === 'link') {
        setShareLink(!data.is_guest_link_allowed)
      }
    }
  }

  const handleModuleAccessChange = (moduleId: number) => {
    const newModuleAccess = inputValues.userModuleAccess.includes(moduleId)
      ? inputValues.userModuleAccess.filter(item => item !== moduleId)
      : [...inputValues.userModuleAccess, moduleId]
    setInputValues(prevState => ({
      ...prevState,
      userModuleAccess: newModuleAccess,
    }))
    handleSave('Module Access', true, newModuleAccess)
  }

  const handleSave = async (
    field: string,
    isUser = false,
    moduleAccess?: false | number[],
  ) => {
    if (isUser) {
      try {
        /*it could be simplified on API side aby PUT /admin/{user} is deleted on API */
        if (field === 'User Email') {
          await updatedCurrentUser({
            id: userProfileOpen.id.toString(),
            userData: {
              email: inputValues.userEmail,
              firstName: inputValues.userName.split(' ')[0],
              lastName: inputValues.userName.split(' ')[1],
            },
          }).unwrap()
        } else if (moduleAccess) {
          setIsUserUpdating(true)
          await updatedUser({
            id: userProfileOpen.id.toString(),
            userData: {
              moduleAccessIds: moduleAccess,
            },
          }).then(() => {
            setIsUserUpdating(false)
          })
        } else {
          await updatedUser({
            id: userProfileOpen.id.toString(),
            userData: {
              firstName: inputValues.userName.split(' ')[0],
              lastName: inputValues.userName.split(' ')[1],
              userType: inputValues.userType,
              accessLevel: inputValues.userAccessLevel,
            },
          }).unwrap()
        }
        if (field === 'User Name') {
          setUserProfileOpen({
            ...userProfileOpen,
            name: inputValues.userName,
          })
        } else if (field === 'User Email') {
          setUserProfileOpen({
            ...userProfileOpen,
            email: inputValues.userEmail,
          })
        } else if (field === 'User Type') {
          setUserProfileOpen({
            ...userProfileOpen,
            user_type: inputValues.userType,
            access_level: inputValues.userAccessLevel,
          })
        } else if (field === 'Module Access') {
          setUserProfileOpen({
            ...userProfileOpen,
            module_access: inputValues.userModuleAccess,
          })
        }
        setSuccessMessage(field + ' updated')
        clearStatuses()
      } catch (error) {
        console.error('Error updating user:', error)
      }
    } else {
      if (field === 'Company Name') {
        updateCompany(
          {
            name: inputValues.name,
          },
          'name',
          field,
        )
      } else if (field === 'Corporate Domain') {
        updateCompany(
          {
            domain_name: inputValues.corporateDomain,
          },
          'domain',
          field,
        )
      }
      if (field === 'inviteUser') {
        setSuccessMessage('New user invited')
        clearStatuses()
      }
    }
  }

  useEffect(() => {
    setValidation(prevState => ({
      ...prevState,
      ...validateEmail(inputValues.userEmail),
      isAccessLevel: inputValues.userAccessLevel !== '',
    }))
  }, [inputValues])

  useEffect(() => {
    if (userProfileOpen.id) {
      if (error) {
        toastError('Failed to get user projects')
      }
      let projects = [{ id: 0, name: '' }]
      if (Array.isArray(userProjects)) {
        projects = userProjects.map(
          (project: { company_project_id: number; name: string }) => ({
            id: project.company_project_id,
            name: project.name,
          }),
        )
      }
      setInputValues(prevState => ({
        ...prevState,
        userName: userProfileOpen.name,
        userEmail: userProfileOpen.email,
        userType: userProfileOpen.user_type,
        userAccessLevel: userProfileOpen.access_level,
        userProjects: projects,
        userModuleAccess: userProfileOpen.module_access,
      }))
    }
  }, [userProfileOpen.id, userProjects, userProfileOpen.module_access])

  useEffect(() => {
    if (userPermission && userPermission.user) {
      const user = userPermission.user
      const moduleIdMapping: { [key: string]: number } = {
        Talent: 2,
        CompFinder: 3,
        ReleaseCalendar: 4,
        FilmTracker: 5,
        ConceptTesting: 6,
        RoleResting: 7,
        AssetTesting: 8,
      }
      const valuesToAdd = [2, 3, 4, 5] // default values
      const permissions = user.modulePermissions.map(
        (module: { module_id: string }) =>
          moduleIdMapping[module.module_id] || 1,
      )
      permissions.forEach(value => {
        if (!valuesToAdd.includes(value)) {
          valuesToAdd.push(value) // force add all permissions because of the bug in API
        }
      })
      setUserProfileOpen({
        ...userProfileOpen,
        module_access: valuesToAdd,
      })
    }
  }, [userPermission, userProfileOpen.id])

  const handleOpenWarning = (field: string, name?: string, userId?: string) => {
    const userName = name ? name : userProfileOpen.name
    const userToEdit = userId ? userId : userProfileOpen.id.toString()
    if (field === 'delete') {
      setWarningModalData({
        title: 'Are you sure you want to delete ' + userName + '?',
        description:
          'This will remove the user from the company account. This action is non-reversible.',
        deleteTitle: 'Delete user',
        type: 'delete',
        deleteClassName: '',
        userToEdit,
      })
    } else if (field === 'pause') {
      setWarningModalData({
        title: 'Are you sure you want to pause ' + userName + '?',
        description: 'This will pause the user from the company account.',
        deleteTitle: 'Pause user',
        type: 'pause',
        deleteClassName: 'bg-primary-red',
        userToEdit,
      })
    } else {
      setWarningModalData({
        title: 'Are you sure you want to reactivate ' + userName + '?',
        description: 'This will reactivate the user to the company account.',
        deleteTitle: 'Reactivate user',
        type: 'reactivate',
        deleteClassName: 'bg-primary-red',
        userToEdit,
      })
    }

    setOpenWarningPopup(true)
  }

  const successUpdated = () => {
    setOpenWarningPopup(false)
    setShowSuccessMessage(true)
    setUserProfileOpen({
      id: '',
      name: '',
      email: '',
      last_login: '',
      date_joined: '',
      projects: [{ id: 0, name: '' }],
      module_access: [2, 3, 4, 5],
      access_level: '',
      user_type: '',
    })
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
      setWarningModalData(initialData)
      if (!userProfileOpen.id) {
        setActiveMenu('business')
        setTimeout(() => {
          setActiveMenu('users')
        }, 50)
      }
    }, 3000)
  }

  const handleConfirm = async () => {
    try {
      if (warningModalData.type === 'delete') {
        const response = await deleteUser({
          userId: warningModalData.userToEdit,
        })
        if (response.error) {
          const { error, data: errorData } = response.error as {
            data?: any
            error?: string
          }
          toastError(errorData, error)
          return
        }
        successUpdated()
        setSuccessMessage(`User account deleted`)
      } else if (warningModalData.type === 'pause') {
        await updatedUser({
          id: warningModalData.userToEdit.toString(),
          userData: {
            status: 'paused',
          },
        }).unwrap()
        successUpdated()
        setSuccessMessage(`User account paused`)
      } else {
        await updatedUser({
          id: warningModalData.userToEdit.toString(),
          userData: {
            status: 'active',
          },
        }).unwrap()
        successUpdated()
        setSuccessMessage(`User account reactivated`)
      }
    } catch (e) {
      const { error, data } = e as { data?: any; error?: string }
      toastError(data, error)
    }
  }

  const updateUserProjects = () => {
    if (newUserProjects.length) {
      const updatedProjects = [
        ...inputValues.userProjects,
        ...newUserProjects.map(project => {
          const foundProject = projectsList.find(item => item.value === project)
          return {
            id: foundProject ? foundProject.id : 0,
            name: foundProject ? foundProject.label : '',
          }
        }),
      ]

      const promises = newUserProjects.map((projectId: string) => {
        return updatedUserProjects({
          companyProjectId: Number(projectId),
          userId: userProfileOpen.id.toString(),
        }).unwrap()
      })

      Promise.all(promises)
        .then(() => {
          setInputValues(prevState => ({
            ...prevState,
            userProjects: updatedProjects,
          }))
          setSuccessMessage(`Projects Updated`)
          setShowSuccessMessage(true)
          setOpenAddProjects(false)
          setTimeout(() => {
            setShowSuccessMessage(false)
            setSuccessMessage('')
          }, 3000)
          setNewUserProjects([])
        })
        .catch(e => {
          const { error, data } = e as { data?: any; error?: string }
          toastError(data, error)
        })
    }
  }

  const handleDeleteUserProject = (projectId: string) => {
    deleteUserProject({
      companyProjectId: Number(projectId),
      userId: userProfileOpen.id.toString(),
    })
      .unwrap()
      .then(() => {
        const updatedProjects = inputValues.userProjects.filter(
          project => project.id !== projectId,
        )
        setInputValues(prevState => ({
          ...prevState,
          userProjects: updatedProjects,
        }))
        setSuccessMessage(`User removed from project successfully`)
        setShowSuccessMessage(true)
        setTimeout(() => {
          setShowSuccessMessage(false)
          setSuccessMessage('')
        }, 3000)
      })
      .catch(e => {
        const { error, data } = e as { data?: any; error?: string }
        toastError(data, error)
      })
  }

  return (
    <div
      className={twMerge(
        'relative flex h-[90vh] w-[90vw] flex-col lg:h-[80vh] lg:w-[70vw] lg:flex-row',
      )}
    >
      <div className='w-full rounded-t bg-grey-3 p-2 lg:w-52 lg:rounded-l lg:rounded-tr-none'>
        <p className='mt-4 px-[10px] text-lg font-medium'>Company Admin</p>
        <ul className='mt-6'>
          <li
            className={twMerge(
              'mb-1 rounded px-[10px] py-1 hover:cursor-pointer',
              activeMenu === 'business' && 'bg-primary-black/10',
            )}
            onClick={() => setActiveMenu('business')}
          >
            Business Info
          </li>
          <li
            className={twMerge(
              'rounded px-[10px] py-1 hover:cursor-pointer',
              activeMenu === 'users' && 'bg-primary-black/10',
            )}
            onClick={() => {
              setActiveMenu('users')
              setUserProfileOpen({
                id: '',
                name: '',
                email: '',
                last_login: '',
                date_joined: '',
                projects: [{ id: 0, name: '' }],
                module_access: [2, 3, 4, 5],
                access_level: '',
                user_type: '',
              })
            }}
          >
            Users & Permissions
          </li>
        </ul>
      </div>
      <div className='w-auto max-w-full grow overflow-auto rounded-b bg-primary-white px-7 py-6 lg:rounded-r lg:rounded-bl-none'>
        {activeMenu === 'business' ? (
          <>
            <div className='flex justify-between'>
              <h1 className='text-2xl font-semibold text-primary-black'>
                {companyName}
              </h1>
            </div>
            <div className='mb-[44px] mt-8 rounded border border-primary-black/20'>
              <div className='flex items-center border-b px-4 py-3'>
                <p className='w-24 text-sm font-medium lg:w-48'>Company Name</p>
                <div className='flex flex-grow items-center justify-between'>
                  {editField === 'name' ? (
                    <>
                      <Input
                        containerWrapperClassName='pr-5'
                        wrapperClassName='w-full h-8 rounded-md'
                        inputClassName='pl-3 text-md py-2'
                        value={inputValues.name}
                        onChange={e => handleInputChange(e, 'name')}
                      />
                      <button
                        className='px-[10px] text-xs font-semibold hover:text-primary-red'
                        onClick={() => setEditField('')}
                      >
                        Cancel
                      </button>
                      <button
                        className='rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white'
                        onClick={() => {
                          handleSave('Company Name')
                        }}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <>
                      <p className='text-md px-3 py-1'>{companyName}</p>
                      {userAbleToEdit && (
                        <div
                          className='flex items-center pl-8 pr-4 hover:cursor-pointer'
                          onClick={() => setEditField('name')}
                        >
                          <Pencil
                            fill={Colors.BASE_ICON}
                            width={16}
                            height={16}
                            className='hover:cursor-pointer'
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='flex items-center p-3 px-4'>
                <p className='w-24 text-sm font-medium lg:w-48'>
                  Corporate Domain
                </p>
                <div className='flex flex-grow items-center justify-between'>
                  {editField === 'corporateDomain' ? (
                    <>
                      <Input
                        containerWrapperClassName='pr-5'
                        wrapperClassName='w-full h-8 rounded-md'
                        inputClassName='pl-3 text-md py-2'
                        value={inputValues.corporateDomain}
                        onChange={e => handleInputChange(e, 'corporateDomain')}
                      />
                      <button
                        className='px-[10px] text-xs font-semibold hover:text-primary-red'
                        onClick={() => setEditField('')}
                      >
                        Cancel
                      </button>
                      <button
                        className={twMerge(
                          'rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white',
                        )}
                        onClick={() => {
                          handleSave('Corporate Domain')
                        }}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <>
                      <p className='text-md px-3 py-1'>{corporateDomain}</p>
                      {userAbleToEdit && (
                        <div
                          className='flex items-center pl-8 pr-4 hover:cursor-pointer'
                          onClick={() => setEditField('corporateDomain')}
                        >
                          <Pencil
                            fill={Colors.BASE_ICON}
                            width={16}
                            height={16}
                            className='hover:cursor-pointer'
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <p className='mb-4 text-sm font-medium'>Share settings</p>
            <div className='rounded border border-primary-black/20'>
              <div className='flex items-center p-3 px-4 py-2.5'>
                <p className='w-24 text-sm font-medium lg:w-48'>Guest Link</p>
                <div className='flex flex-grow items-center justify-between'>
                  <p className='px-3 py-1.5 text-sm'>
                    Allow external guest link sharing to data
                  </p>
                  <div className='flex items-center pl-8 pr-4'>
                    <ToggleSwitch
                      onChange={() => {
                        setShareLink(!shareLink)
                        updateCompany(
                          { is_guest_link_allowed: !shareLink },
                          'link',
                        )
                      }}
                      checked={shareLink}
                      id={'shareLink'}
                      color={'bg-primary-red'}
                      labelClassName={'hover:cursor-pointer'}
                      size={'medium'}
                      disabled={!userAbleToEdit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {userProfileOpen.id ? (
              <>
                <div className='flex items-center justify-between'>
                  <div className={'flex items-center gap-1'}>
                    <ArrowLeft
                      fill={'currentColor'}
                      width={24}
                      height={24}
                      onClick={() => {
                        setUserProfileOpen({
                          id: '',
                          name: '',
                          email: '',
                          last_login: '',
                          date_joined: '',
                          projects: [],
                          module_access: [2, 3, 4, 5],
                          access_level: '',
                          user_type: '',
                        })
                      }}
                      className='cursor-pointer text-grey-7 hover:text-primary-red'
                    />
                    <h1 className='text-2xl font-semibold text-primary-black'>
                      {userProfileOpen.name}
                    </h1>
                  </div>
                  <div className={'flex items-center'}>
                    <button
                      className='inline-flex items-center gap-1 rounded-full px-4 py-2 text-xs font-semibold text-primary-grey transition-colors hover:bg-primary-black/5'
                      onClick={() =>
                        handleOpenWarning(
                          userProfileOpen.last_login !== 'Paused'
                            ? 'pause'
                            : 'reactivate',
                        )
                      }
                    >
                      {userProfileOpen.last_login !== 'Paused' ? (
                        <>
                          <Pause fill={'currentColor'} width={12} height={12} />
                          Pause User
                        </>
                      ) : (
                        <>
                          <Play fill={'currentColor'} width={12} height={12} />
                          Reactivate user
                        </>
                      )}
                    </button>
                    <button
                      className='inline-flex items-center gap-1 rounded-full bg-primary-red px-4 py-2 text-xs font-semibold text-primary-white transition-colors hover:bg-red-9'
                      onClick={() => handleOpenWarning('delete')}
                    >
                      <Trash fill={Colors.ICON_WHITE} width={20} height={20} />
                      Delete User
                    </button>
                  </div>
                </div>
                <div className='mb-[44px] mt-8 rounded border border-primary-black/20'>
                  <div className='flex items-center border-b px-4 py-3'>
                    <p className='w-24 text-sm font-medium lg:w-48'>Name</p>
                    <div className='flex flex-grow items-center justify-between'>
                      {editField === 'userName' ? (
                        <>
                          <Input
                            containerWrapperClassName='pr-5'
                            wrapperClassName='w-full h-8 rounded-md'
                            inputClassName='pl-3 text-md py-2'
                            value={inputValues.userName}
                            onChange={e => handleInputChange(e, 'userName')}
                          />
                          <button
                            className='px-[10px] text-xs font-semibold hover:text-primary-red'
                            onClick={() => setEditField('')}
                          >
                            Cancel
                          </button>
                          <button
                            className={twMerge(
                              'rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white',
                              !inputValues?.userName && 'disabled:bg-gray-500',
                            )}
                            onClick={() => {
                              handleSave('User Name', true)
                            }}
                            disabled={!inputValues.userName}
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <>
                          <p className='px-3 py-1 text-sm'>
                            {userProfileOpen.name}
                          </p>
                          <div
                            className='flex items-center pl-8 hover:cursor-pointer'
                            onClick={() => setEditField('userName')}
                          >
                            <Pencil
                              fill={Colors.BASE_ICON}
                              width={16}
                              height={16}
                              className='hover:cursor-pointer'
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4'>
                    <p className='w-24 text-sm font-medium lg:w-48'>Email</p>
                    <div className='flex flex-grow items-center justify-between'>
                      {editField === 'userEmail' ? (
                        <>
                          <Input
                            containerWrapperClassName='pr-5'
                            wrapperClassName='w-full h-8 rounded-md'
                            inputClassName='pl-3 text-md py-2'
                            value={inputValues.userEmail}
                            onChange={e => handleInputChange(e, 'userEmail')}
                          />
                          <button
                            className='px-[10px] text-xs font-semibold hover:text-primary-red'
                            onClick={() => setEditField('')}
                          >
                            Cancel
                          </button>
                          <button
                            className={twMerge(
                              'rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white',
                              !validation?.validEmail && 'disabled:bg-gray-500',
                            )}
                            onClick={() => {
                              handleSave('User Email', true)
                            }}
                            disabled={!validation?.validEmail}
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <>
                          <p className='px-3 py-1 text-sm'>
                            {userProfileOpen.email}
                          </p>
                          <div
                            className='flex items-center pl-8 hover:cursor-pointer'
                            onClick={() => setEditField('userEmail')}
                          >
                            <Pencil
                              fill={Colors.BASE_ICON}
                              width={16}
                              height={16}
                              className='hover:cursor-pointer'
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      User Type
                    </p>
                    <div className='flex flex-grow items-center justify-between'>
                      {editField === 'userType' ? (
                        <>
                          <div className={'flex w-3/4 gap-1 px-3 pb-4'}>
                            <Select
                              label={'User Type'}
                              id={'userType'}
                              labelClass={
                                'pb-1 text-sm font-semibold text-primary-black'
                              }
                              containerClass='w-1/2'
                              buttonClass='h-8 items-center'
                              value={inputValues.userType}
                              handleClick={option => {
                                handleSelect(option, 'userType')
                              }}
                              options={[
                                {
                                  id: '1',
                                  value: 'standard',
                                  label: 'Standard',
                                },
                                {
                                  id: '2',
                                  value: 'admin',
                                  label: 'Admin',
                                },
                              ]}
                              size='small'
                              placeholder='User Type'
                              defaultValue={'all'}
                              hasRadioButton={false}
                              errorClass={'pt-1.5 text-xs'}
                            />
                            <Select
                              label={'Access Level'}
                              id={'accessLevel'}
                              labelClass={
                                'pb-1 text-sm font-semibold text-primary-black'
                              }
                              containerClass='w-1/2'
                              disabled={inputValues.userType === ''}
                              value={inputValues.userAccessLevel}
                              handleClick={option => {
                                handleSelect(option, 'userAccessLevel')
                              }}
                              buttonClass='h-8 items-center'
                              options={
                                inputValues.userType === 'standard'
                                  ? accessLevelOptions[0]
                                  : accessLevelOptions[1]
                              }
                              size='small'
                              placeholder='Access Level'
                              defaultValue={'all'}
                              hasRadioButton={false}
                              error={'Access Level is required'}
                              errorClass={'pt-1.5 text-xs'}
                            />
                          </div>
                          <div>
                            <button
                              className='px-[10px] text-xs font-semibold hover:text-primary-red'
                              onClick={() => setEditField('')}
                            >
                              Cancel
                            </button>
                            <button
                              className={twMerge(
                                'rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white',
                                !validation?.isAccessLevel &&
                                  'disabled:bg-gray-500',
                              )}
                              onClick={() => {
                                handleSave('User Type', true)
                              }}
                              disabled={!validation?.isAccessLevel}
                            >
                              Save
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className='px-3 py-1 text-sm capitalize'>
                            {userProfileOpen.user_type || 'N/A'}
                          </p>
                          <div
                            className='flex items-center pl-8 hover:cursor-pointer'
                            onClick={() => setEditField('userType')}
                          >
                            <Pencil
                              fill={Colors.BASE_ICON}
                              width={16}
                              height={16}
                              className='hover:cursor-pointer'
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      User Access
                    </p>
                    <div className='flex flex-grow items-center justify-between'>
                      <>
                        <p className='px-3 py-1 text-sm capitalize'>
                          {userProfileOpen.access_level === 'superadmin'
                            ? 'Super Admin'
                            : userProfileOpen.access_level === 'moduleadmin'
                              ? 'Module Admin'
                              : !userProfileOpen.access_level
                                ? 'N/A'
                                : userProfileOpen.access_level}
                        </p>
                      </>
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Date Joined
                    </p>
                    <div className='flex flex-grow items-center justify-between'>
                      <>
                        <p className='px-3 py-1 text-sm'>
                          {userProfileOpen.date_joined}
                        </p>
                      </>
                    </div>
                  </div>
                  <div className='flex items-center p-3 px-4'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Last Log In
                    </p>
                    <div className='flex flex-grow items-center justify-between'>
                      <>
                        <p className='flex items-center gap-1 px-3 py-1 text-sm'>
                          {userProfileOpen.last_login === 'Online' && (
                            <span className='h-2 w-2 rounded-full bg-green-6'></span>
                          )}
                          {userProfileOpen.last_login === 'Paused' && (
                            <span className='h-2 w-2 rounded-full bg-red-10'></span>
                          )}
                          {userProfileOpen.last_login}
                        </p>
                      </>
                    </div>
                  </div>
                </div>
                <p className='mb-4 text-sm font-medium'>
                  Projects ({inputValues.userProjects.length})
                </p>
                <div className='mb-[44px] rounded border border-primary-black/20'>
                  <div className='flex items-center justify-between border-b bg-grey-3 p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>Name</p>
                    <button
                      className={
                        'inline-flex items-center gap-1 rounded bg-primary-white px-1.5 py-1 text-sm font-semibold text-primary-grey hover:bg-primary-black/5'
                      }
                      onClick={() => setOpenAddProjects(true)}
                    >
                      <Plus fill={'currentColor'} width={16} height={16} />
                      Add Project
                    </button>
                  </div>
                  {inputValues.userProjects.map((project, index) => (
                    <div
                      className='flex items-center justify-between border-b p-3 px-4 py-2.5'
                      key={index}
                    >
                      <p className='w-3/4 text-sm font-medium'>
                        {project.name}
                      </p>
                      <Trash
                        fill={Colors.BASE_ICON}
                        width={24}
                        height={24}
                        className={'cursor-pointer hover:fill-primary-red'}
                        onClick={() => {
                          handleDeleteUserProject(project.id.toString())
                        }}
                      />
                    </div>
                  ))}
                </div>
                <BaseModal
                  isOpen={openAddProjects}
                  handleClose={() => setOpenAddProjects(false)}
                  modalZIndex={999}
                  title={'Add Projects'}
                  titleClass={'mb-6'}
                >
                  <div className='flex flex-col items-center justify-center'>
                    {projectsList.length === 0 ? (
                      <p
                        className={twMerge(
                          'flex pt-1.5 text-xs text-red-8 duration-500 ease-in',
                        )}
                      >
                        Please create a project at first
                      </p>
                    ) : (
                      <FilterButton
                        name={
                          newUserProjects.length === 1
                            ? '1 Project'
                            : newUserProjects.length > 1
                              ? newUserProjects.length + ' Projects'
                              : ''
                        }
                        containerMenuClassName='left-0'
                        nameCLassName='font-normal text-sm h-6 items-center inline-flex'
                        isMenuVisible={false}
                        leftIcon={false}
                        rightIcon={
                          <ChevronDown
                            fill={Colors.BASE_ICON}
                            width={18}
                            height={18}
                            className='ml-auto pl-1'
                          />
                        }
                        totalSelectedFilters={0}
                        containerClassName='w-full'
                        wrapperClasName='w-full border-primary-black/20'
                        wrapperMenuClassName='pr-0'
                        hideTriangle={true}
                      >
                        <div className='flex max-h-[400px] flex-row gap-10 overflow-auto pr-5'>
                          {
                            <FilterColumnGroup
                              key={'projects'}
                              category={'projects'}
                              optionsFilter={projectsList}
                              isSelect={false}
                              selectedFilters={{ projects: newUserProjects }}
                              onChange={selected => {
                                setNewUserProjects(selected['projects'])
                              }}
                              allLabel={'Select All'}
                            />
                          }
                        </div>
                      </FilterButton>
                    )}
                  </div>
                  <div className='flex w-full justify-end gap-2 pt-4'>
                    <Button
                      kind='text'
                      size='medium'
                      className='py-2.5'
                      onClick={() => {
                        setOpenAddProjects(false)
                        setNewUserProjects([])
                      }}
                    >
                      <span className='px-1 text-xs font-semibold'>Cancel</span>
                    </Button>

                    <Button
                      kind='filled'
                      size='medium'
                      className={twMerge(
                        'bg-red-6 py-2.5 hover:bg-red-6/80 active:bg-red-6/60',
                      )}
                      onClick={() => updateUserProjects()}
                      disabled={newUserProjects.length === 0}
                    >
                      <span className='px-2.5 text-xs font-semibold'>
                        Confirm
                      </span>
                    </Button>
                  </div>
                </BaseModal>
                <p className='mb-4 text-sm font-medium'>Module Access</p>
                <div className='mb-[44px] rounded border border-primary-black/20'>
                  <div className='flex items-center border-b bg-grey-3 p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>Name</p>
                  </div>
                  <div className='flex items-center border-b p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>Talent</p>
                    <div className='flex flex-grow items-center justify-end'>
                      <p className='px-3 py-1.5 text-sm text-grey-7'>
                        User always have access to this module.
                      </p>
                      <div className='flex items-center pl-4'>
                        <ToggleSwitch
                          checked={true}
                          id={'talentToggle'}
                          color={'bg-primary-blue/20'}
                          size={'medium'}
                          disabled={true}
                          labelClassName={'hover:cursor-not-allowed'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Comp Finder
                    </p>
                    <div className='flex flex-grow items-center justify-end'>
                      <p className='px-3 py-1.5 text-sm text-grey-7'>
                        User always have access to this module.
                      </p>
                      <div className='flex items-center pl-4'>
                        <ToggleSwitch
                          checked={true}
                          id={'compFinderToggle'}
                          color={'bg-primary-blue/20'}
                          size={'medium'}
                          disabled={true}
                          labelClassName={'hover:cursor-not-allowed'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Release Calendar
                    </p>
                    <div className='flex flex-grow items-center justify-end'>
                      <p className='px-3 py-1.5 text-sm text-grey-7'>
                        User always have access to this module.
                      </p>
                      <div className='flex items-center pl-4'>
                        <ToggleSwitch
                          checked={true}
                          id={'releaseCalendarToggle'}
                          color={'bg-primary-blue/20'}
                          size={'medium'}
                          disabled={true}
                          labelClassName={'hover:cursor-not-allowed'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Film Tracking
                    </p>
                    <div className='flex flex-grow items-center justify-end'>
                      <p className='px-3 py-1.5 text-sm text-grey-7'>
                        User always have access to this module.
                      </p>
                      <div className='flex items-center pl-4'>
                        <ToggleSwitch
                          checked={true}
                          id={'filmTrackingToggle'}
                          color={'bg-primary-blue/20'}
                          size={'medium'}
                          disabled={true}
                          labelClassName={'hover:cursor-not-allowed'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Concept Testing
                    </p>
                    <div className='flex flex-grow items-center justify-end'>
                      <div className='flex items-center pl-4'>
                        <ToggleSwitch
                          onChange={() => handleModuleAccessChange(6)}
                          checked={inputValues.userModuleAccess.includes(6)}
                          id={'conceptTestingToggle'}
                          color={'bg-primary-red'}
                          labelClassName={'hover:cursor-pointer'}
                          size={'medium'}
                          disabled={isUserUpdating}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Role Testing
                    </p>
                    <div className='flex flex-grow items-center justify-end'>
                      <div className='flex items-center pl-4'>
                        <ToggleSwitch
                          onChange={() => handleModuleAccessChange(7)}
                          checked={inputValues.userModuleAccess.includes(7)}
                          id={'roleTestingToggle'}
                          color={'bg-primary-red'}
                          labelClassName={'hover:cursor-pointer'}
                          size={'medium'}
                          disabled={isUserUpdating}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center border-b p-3 px-4 py-2.5'>
                    <p className='w-24 text-sm font-medium lg:w-48'>
                      Asset Testing
                    </p>
                    <div className='flex flex-grow items-center justify-end'>
                      <div className='flex items-center pl-4'>
                        <ToggleSwitch
                          onChange={() => handleModuleAccessChange(8)}
                          checked={inputValues.userModuleAccess.includes(8)}
                          id={'assetTestingToggle'}
                          color={'bg-primary-red'}
                          labelClassName={'hover:cursor-pointer'}
                          size={'medium'}
                          disabled={isUserUpdating}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <UsersTable
                  setUserProfileOpen={setUserProfileOpen}
                  handleOpenWarning={handleOpenWarning}
                  setEditField={setEditField}
                  editField={editField}
                  handleSave={handleSave}
                />
              </>
            )}
          </>
        )}
      </div>
      {showSuccessMessage && (
        <div className='absolute bottom-4 right-4 rounded-md bg-primary-white p-3 shadow-md'>
          <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
            {warningModalData.type === 'pause' ? (
              userProfileOpen.last_login === 'Paused' ? (
                <Play fill={Colors.BASE_ICON} width={19} height={19} />
              ) : (
                <Pause fill={Colors.BASE_ICON} width={19} height={19} />
              )
            ) : warningModalData.type === 'delete' ? (
              <Trash fill={Colors.ICON_NEGATIVE_RED} width={19} height={19} />
            ) : (
              <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
            )}{' '}
            {successMessage}
          </p>
        </div>
      )}
      <DeleteModal
        isOpen={openWarningPopup}
        handleClose={() => setOpenWarningPopup(false)}
        title={warningModalData.title}
        description={warningModalData.description}
        onDelete={() => {
          handleConfirm()
        }}
        deleteTitle={warningModalData.deleteTitle}
        zIndex={900}
        descriptionClassName={'text-center'}
        titleClassName={'text-center mb-1'}
        icon={
          warningModalData.type === 'pause' ? (
            <Pause fill={Colors.BASE_ICON} width={19} height={19} />
          ) : warningModalData.type === 'reactivate' ? (
            <Play fill={Colors.BASE_ICON} width={40} height={40} />
          ) : (
            <Trash fill={Colors.ICON_NEGATIVE_RED} width={40} height={40} />
          )
        }
        deleteClassName={warningModalData.deleteClassName}
      />
    </div>
  )
}
