import { OptionType } from 'types'

export type SelectionMode = 'explicit' | 'inverse'

export interface UseSelectionModeParams<TOptionFilterValue> {
  options: OptionType<TOptionFilterValue>[]
  selected: TOptionFilterValue[]
  onChange: (selected: TOptionFilterValue[]) => void
}

interface UseSelectionModeResult<TOptionFilterValue> {
  isAllSelected: boolean
  indeterminate: boolean
  handleSelectAll: () => void
  handleSelect: (
    option: OptionType<TOptionFilterValue>,
    checked: boolean,
  ) => void
}

export function useExplicitSelectAll<TOptionFilterValue>({
  options,
  selected,
  onChange,
}: UseSelectionModeParams<TOptionFilterValue>): UseSelectionModeResult<TOptionFilterValue> {
  const isAllSelected = selected.length === options.length
  const indeterminate = selected.length > 0 && !isAllSelected

  const handleSelectAll = () => {
    if (isAllSelected) {
      onChange([])
    } else {
      onChange(options.map(option => option.value))
    }
  }

  const handleSelect = (
    option: OptionType<TOptionFilterValue>,
    checked: boolean,
  ) => {
    const selectedNext = checked
      ? selected.filter(value => value !== option.value)
      : [...selected, option.value]

    if (selectedNext.length === options.length) {
      handleSelectAll()
    } else {
      onChange(selectedNext)
    }
  }

  return {
    isAllSelected,
    indeterminate,
    handleSelectAll,
    handleSelect,
  }
}

export function useInverseSelectAll<TOptionFilterValue>({
  options,
  selected,
  onChange,
}: UseSelectionModeParams<TOptionFilterValue>): UseSelectionModeResult<TOptionFilterValue> {
  const isAllSelected = selected.length === 0
  const indeterminate = selected.length > 0 && selected.length < options.length

  const handleSelectAll = () => onChange([])

  const handleSelect = (
    option: OptionType<TOptionFilterValue>,
    checked: boolean,
  ) => {
    const selectedNext = checked
      ? selected.filter(value => value !== option.value)
      : [...selected, option.value]

    if (selectedNext.length === options.length) {
      return handleSelectAll()
    }

    onChange(selectedNext)
  }

  return {
    isAllSelected,
    indeterminate,
    handleSelectAll,
    handleSelect,
  }
}

export const getHookByMode = (mode: SelectionMode) => {
  switch (mode) {
    case 'explicit':
      return useExplicitSelectAll
    case 'inverse':
    default:
      return useInverseSelectAll
  }
}
