import { twMerge } from 'tailwind-merge'
import { ToggleSwitch } from 'components'

interface TitleFilterProps {
  id: string
  title: string
  checked: boolean
  onChange: (checked: boolean) => void
  color: string
  wrapperClassName?: string
}

export const TitleFilter = ({
  id,
  title,
  checked,
  onChange,
  color,
  wrapperClassName,
}: TitleFilterProps) => {
  return (
    <div
      className={twMerge('flex items-center justify-between', wrapperClassName)}
    >
      <span className='text-sm font-medium text-primary-black'>{title}</span>
      <ToggleSwitch
        id={id}
        checked={checked}
        onChange={onChange}
        color={color}
      />
    </div>
  )
}
