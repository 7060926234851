import React from 'react'
import {
  AdditionalDetailsStep,
  AddRolesStep,
  ProjectDetailsStep,
  ProjectLogLinesStep,
} from 'components'
import { ProjectStep } from 'types'

interface StepComponentProps {
  step: ProjectStep
  nextStep: () => void
  setStepCompleted: (step: ProjectStep) => void
  handleCloseNewProject: () => void
}

export const StepComponent = ({
  step,
  nextStep,
  setStepCompleted,
  handleCloseNewProject,
}: StepComponentProps) => {
  switch (step) {
    case ProjectStep.PROJECT_DETAILS:
      return (
        <ProjectDetailsStep
          nextStep={nextStep}
          setStepCompleted={() => setStepCompleted(ProjectStep.PROJECT_DETAILS)}
        />
      )
    case ProjectStep.ADDITIONAL_DETAILS:
      return (
        <AdditionalDetailsStep
          nextStep={nextStep}
          setStepCompleted={() =>
            setStepCompleted(ProjectStep.ADDITIONAL_DETAILS)
          }
        />
      )
    case ProjectStep.PROJECT_LOGLINES:
      return (
        <ProjectLogLinesStep
          nextStep={nextStep}
          setStepCompleted={() =>
            setStepCompleted(ProjectStep.PROJECT_LOGLINES)
          }
        />
      )
    case ProjectStep.ADD_ROLES:
      return (
        <AddRolesStep
          setStepCompleted={() => setStepCompleted(ProjectStep.ADD_ROLES)}
          onClose={handleCloseNewProject}
        />
      )
    default:
      return null
  }
}
