import { twMerge } from 'tailwind-merge'
import { UsersTableHeaderProps } from '../UsersTable.types'
import { ChevronDown, ChevronUp } from 'components/ui/icons'
import { Colors } from 'constnants'
import { SortByOptions, SortOrderOptions } from 'types'

export const UsersTableHeader = ({
  columns,
  onSort,
  onSortOrder,
  sortingBY,
}: UsersTableHeaderProps) => {
  const onSortHandler = (value: SortByOptions) => {
    if (onSort) {
      if (sortingBY === value) {
        onSort((prevSort: SortOrderOptions) =>
          prevSort === 'ASC' ? 'DESC' : 'ASC',
        )
      } else {
        onSort('ASC' as SortOrderOptions)
      }
    }
    if (onSortOrder) {
      onSortOrder(value)
    }
  }

  return (
    <thead>
      <tr>
        {columns.map(column => (
          <th
            key={column.value}
            className={twMerge(
              'border-b border-b-primary-black/20 bg-grey-3 px-2 py-3 text-xs font-normal text-primary-grey hover:cursor-pointer',
            )}
          >
            <div>
              {column.title && (
                <div
                  className='flex items-center gap-2'
                  onClick={() => onSortHandler(column.value as SortByOptions)}
                >
                  {column.title}
                  <div>
                    <ChevronUp
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                      className='-mb-1'
                    />
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                    />
                  </div>
                </div>
              )}
            </div>
          </th>
        ))}
        <th
          className={twMerge(
            'border-b border-b-primary-black/20 bg-grey-3 p-3',
          )}
        ></th>
      </tr>
    </thead>
  )
}
