import { ColumnsProjectTable, Project } from 'types'
import { ProjectStatusItem, ProjectsTeam } from 'components'
import { formatStatusWithHyphen } from 'constnants'
import { formatDate } from 'utils'

interface TableCellProps {
  column: ColumnsProjectTable
  row: Project
  onNavigateProject: () => void
}

export const TableCell = ({
  column,
  row,
  onNavigateProject,
}: TableCellProps) => {
  switch (column.value) {
    case 'projectName':
      return (
        <span
          className='text-xs font-medium text-primary-black hover:cursor-pointer hover:opacity-80'
          onClick={onNavigateProject}
        >
          {row.name}
        </span>
      )
    case 'team':
      return <ProjectsTeam teams={row.projectUsers} />
    case 'projectStatus':
      return (
        <ProjectStatusItem
          status={formatStatusWithHyphen(row.phase.name)}
          wrapperClassName='bg-primary-blue-light'
        />
      )
    case 'createdAt':
      return (
        <span className='text-xs text-primary-black '>
          {formatDate(row.createdAt, 'MM/DD/YY')}
        </span>
      )
    case 'updatedAt':
      return (
        <span className='text-xs text-primary-black'>
          {formatDate(row.updatedAt, 'MM/DD/YY')}
        </span>
      )
    default:
      return <span></span>
  }
}
