import { AssignedUser } from '../types'

export const myProjectsAssignedUsers: AssignedUser[] = [
  {
    id: '1',
    fullName: 'Bill Ackers',
    email: 'bill.ackers@email.com',
    userType: '',
  },
  {
    id: '2',
    fullName: 'John Doe',
    email: 'john.doe@email.com',
    userType: '',
  },
  {
    id: '3',
    fullName: 'Jane Doe',
    email: 'jane.doe@email.com',
    userType: '',
  },
  {
    id: '4',
    fullName: 'Sam Hadler',
    email: 'sam.h@email.com',
    userType: '',
  },
  {
    id: '5',
    fullName: 'Rachel Lapworth',
    email: 'rachel.lapworth@email.com',
    userType: '',
  },
  {
    id: '6',
    fullName: 'Bill Ackers',
    email: 'bill.ackers@email.com',
    userType: '',
  },
  {
    id: '7',
    fullName: 'Elizabeth Swansbury',
    email: 'lizswan@email.com',
    userType: '',
  },
]
