import { PropsWithChildren, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface StepperStatusProps extends PropsWithChildren {
  statusTitle: string
  icon?: ReactNode
  wrapperClassName?: string
}

export const StepperStatus = ({
  statusTitle,
  icon,
  wrapperClassName,
}: StepperStatusProps) => {
  return (
    <div
      className={twMerge(
        'flex h-6 w-[90px] min-w-fit items-center justify-center gap-1 rounded-full px-2 text-sm font-medium uppercase text-primary-grey',
        wrapperClassName,
      )}
    >
      {statusTitle}
      {icon}
    </div>
  )
}
