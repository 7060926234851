import React, { useEffect, useMemo, useState } from 'react'
import { UsersTableHeader } from './components/UsersTableHeader'
import { UsersTableProps } from './UsersTable.types'
import { Input, Select } from '../ui'
import { CheckCircle, Close, Plus, Search, Trash } from '../ui/icons'
import { Colors } from '../../constnants'
import { OptionFilter } from '../../types'
import { DeleteModal } from '../DeleteModal'
import { UsersTableBody } from './components/UsersTableBody'
import { fetchUsersData } from '../../hooks'
import { InviteUserModal } from '../InviteUserModal'
import { useDeleteUserMutation } from '../../store/api'
import { toastError } from '../../utils'

const rolesFilterList: OptionFilter[] = [
  {
    id: '0',
    value: 'all',
    label: 'All',
  },
  {
    id: '1',
    value: 'superadmin',
    label: 'Super Admin',
  },
  {
    id: '2',
    value: 'moduleadmin',
    label: 'Module Admin',
  },
  {
    id: '3',
    value: 'editor',
    label: 'Editor',
  },
  {
    id: '4',
    value: 'viewer',
    label: 'Viewer',
  },
]

const columns = [
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Email',
    value: 'email',
  },
  {
    title: 'User type',
    value: 'access_level',
  },
  {
    title: 'Last Login',
    value: 'last_login',
  },
]

const initialActiveFilters = {
  userType: '',
  search: '',
}

export interface TableProps {
  setUserProfileOpen: (user: UsersTableProps) => void
  handleOpenWarning: (type: string, name?: string) => void
  setEditField: (field: string) => void
  editField: string
  handleSave: (field: string) => void
}

export const UsersTable = ({
  setUserProfileOpen,
  handleOpenWarning,
  setEditField,
  editField,
  handleSave,
}: TableProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sortingBY, setSortingBY] = useState<keyof UsersTableProps>('name')
  const [sortingOrder, setSortingOrder] = useState<'asc' | 'desc'>('asc')
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([])
  const urlParams = new URLSearchParams(window.location.search)
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const showEmptyState = !!urlParams.get('emptyState')
  const [usersData, setUsersData] = useState<UsersTableProps[]>([])
  const [usersCount, setUsersCount] = useState<number>(0)
  const [filteredUsers, setFilteredUsers] = useState<UsersTableProps[]>([])
  const [deleteUser] = useDeleteUserMutation()

  useEffect(() => {
    try {
      refreshUsersData()
    } catch (e) {
      handleError(e)
    }
  }, [])

  useMemo(() => {
    const sortedData = [...filteredUsers].sort(
      (a: UsersTableProps, b: UsersTableProps) => {
        if (sortingOrder === 'asc') {
          return a[sortingBY] > b[sortingBY] ? 1 : -1
        } else {
          return a[sortingBY] < b[sortingBY] ? 1 : -1
        }
      },
    )
    setFilteredUsers(sortedData)
  }, [sortingBY, sortingOrder, usersData])

  const toggleSelectAll = () => {
    const newSelectAllChecked = !selectAllChecked
    setSelectAllChecked(newSelectAllChecked)
    if (newSelectAllChecked) {
      const newSelectedRows = filteredUsers.map(row => row.id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const handleUncheckSelected = () => {
    setSelectedRows([])
    setSelectAllChecked(false)
  }

  const handleCheckboxChange = (rowId: string | number) => {
    const newSelected = selectedRows.includes(rowId)
      ? selectedRows.filter(id => id !== rowId)
      : [...selectedRows, rowId]

    setSelectedRows(newSelected)
    setSelectAllChecked(newSelected.length === filteredUsers.length)
  }

  const handleFilter = (option: OptionFilter, key: string) => {
    setActiveFilters(prevState => ({ ...prevState, [key]: option.value }))
  }

  const handleConfirmBulkUsersDelete = async () => {
    try {
      await deleteSelectedUsers()
      await refreshUsersData()
      resetSelection()
      showSuccessMessageFunc()
    } catch (e) {
      handleError(e)
    }
  }

  const deleteSelectedUsers = async () => {
    const promises = selectedRows.map(async userId =>
      deleteUser({ userId }).unwrap(),
    )
    return Promise.all(promises)
  }

  const refreshUsersData = async () => {
    setIsLoading(true)
    try {
      const usersData = await fetchUsersData()
      if (usersData) {
        setUsersData(usersData)
        setUsersCount(usersData.length)
      }
    } catch (e) {
      setIsLoading(false)
      handleError(e)
      throw e
    } finally {
      setIsLoading(false)
    }
  }

  const resetSelection = () => {
    setSelectedRows([])
    setSelectAllChecked(false)
  }

  const showSuccessMessageFunc = () => {
    setSuccessMessage(
      `User${selectedRows.length > 1 ? 's' : ''} account deleted`,
    )
    setOpenWarningPopup(false)
    setShowSuccessMessage(true)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
    }, 3000)
  }

  const handleError = (e: any) => {
    const { error, data } = e as { data?: any; error?: string }
    toastError(data, error)
  }

  useEffect(() => {
    let filteredData = showEmptyState ? [] : usersData
    if (activeFilters.search || activeFilters.userType) {
      if (activeFilters.search) {
        filteredData = filteredData.filter(user =>
          user.name.toLowerCase().includes(activeFilters.search.toLowerCase()),
        )
      }
      if (activeFilters.userType && activeFilters.userType !== 'all') {
        filteredData = filteredData.filter(
          user => user.access_level === activeFilters.userType,
        )
      }
    }
    setFilteredUsers(filteredData)
  }, [activeFilters, usersData])

  return (
    <>
      <div className='flex items-center justify-between'>
        <div>
          <h1 className='text-2xl font-semibold text-primary-black'>
            User & Permission
          </h1>
          <p className='text-sm font-semibold'>{`${usersCount} User${usersCount !== 1 && 's'}`}</p>
        </div>
        <button
          className='inline-flex items-center gap-1 rounded-full bg-primary-red px-4 py-2 text-xs font-semibold text-primary-white transition-colors hover:bg-red-9'
          onClick={() => setEditField('inviteUser')}
        >
          <Plus fill={Colors.ICON_WHITE} width={20} height={20} />
          New User
        </button>
        <InviteUserModal
          title={'Invite New User'}
          isOpen={editField === 'inviteUser'}
          handleClose={() => setEditField('')}
          onSubmit={() => {
            handleSave('inviteUser')
          }}
          modalZIndex={900}
        />
      </div>
      <div className='mt-3 flex items-center justify-between'>
        {selectedRows.length > 0 ? (
          <div className={'flex items-center gap-1'}>
            <p className={'text-xs font-medium'}>
              {selectedRows.length} Selected
            </p>
            <Close
              width={16}
              height={16}
              fill={Colors.NAV_ICON_THREE}
              onClick={handleUncheckSelected}
              className='cursor-pointer'
            />
            <button
              className={
                'ml-2 inline-flex items-center rounded-full bg-red-10 px-[10px] py-1 text-xs font-semibold text-primary-white'
              }
              onClick={() => setOpenWarningPopup(true)}
            >
              <Trash fill={Colors.ICON_WHITE} width={16} height={16} />
              Delete
            </button>
          </div>
        ) : (
          <Select
            id='userType'
            labelClass='pb-1 text-sm font-semibold text-primary-black'
            buttonClass='h-8 items-center'
            value={activeFilters.userType}
            handleClick={option => {
              handleFilter(option, 'userType')
            }}
            options={rolesFilterList}
            size='small'
            placeholder='All user types'
            defaultValue={''}
            hasRadioButton={false}
            error='User Type is required'
            errorClass='pt-1.5 text-xs'
            optionClass='whitespace-nowrap'
          />
        )}
        <Input
          containerWrapperClassName='w-[200px] h-6'
          placeholder='Search Name'
          value={activeFilters.search}
          onChange={e => {
            setActiveFilters(prevState => ({
              ...prevState,
              search: e.target.value,
            }))
          }}
          leftIcon={
            <Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />
          }
        />
      </div>
      <div className='w-full overflow-auto py-3 pb-5'>
        {isLoading ? (
          <>
            <table className='w-full border-spacing-0'>
              <UsersTableHeader
                columns={columns}
                onSort={setSortingOrder}
                onSortOrder={setSortingBY}
                sortingBY={sortingBY}
              />
              <UsersTableBody
                data={filteredUsers}
                columns={columns}
                isLoading={isLoading}
                selectedRows={selectedRows}
                handleCheckboxChange={handleCheckboxChange}
                setUserProfileOpen={setUserProfileOpen}
                handleOpenWarning={handleOpenWarning}
              />
            </table>
          </>
        ) : (
          <>
            <table className='w-full border-spacing-0'>
              <UsersTableHeader
                columns={columns}
                onSort={setSortingOrder}
                onSortOrder={setSortingBY}
                sortingBY={sortingBY}
                selectAllChecked={selectAllChecked}
                toggleSelectAll={toggleSelectAll}
              />
              <UsersTableBody
                data={filteredUsers}
                columns={columns}
                isLoading={isLoading}
                selectedRows={selectedRows}
                handleCheckboxChange={handleCheckboxChange}
                setUserProfileOpen={setUserProfileOpen}
                handleOpenWarning={handleOpenWarning}
              />
            </table>
          </>
        )}
      </div>
      <DeleteModal
        isOpen={openWarningPopup}
        handleClose={() => setOpenWarningPopup(false)}
        title={`Are you sure you want to delete selected user${selectedRows.length > 1 ? 's' : ''}?`}
        description={
          'This will remove the user from the company account. This action is non-reversible.'
        }
        onDelete={() => {
          handleConfirmBulkUsersDelete()
        }}
        deleteTitle={`Delete user${selectedRows.length > 1 ? 's' : ''}`}
        zIndex={900}
        descriptionClassName={'text-center'}
        titleClassName={'text-center mb-1'}
      />
      {showSuccessMessage && (
        <div className='absolute bottom-4 right-4 rounded-md bg-primary-white p-3 shadow-md'>
          <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
            <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />{' '}
            {successMessage}
          </p>
        </div>
      )}
    </>
  )
}
