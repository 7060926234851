import { OptionType, GetTelentsListsResponce } from 'types'

export const mapObjectTalentsLists = (
  original: GetTelentsListsResponce,
): OptionType => {
  return {
    id: original.list_id,
    label: original.list_name,
    value: original.list_name,
  }
}
