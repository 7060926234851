import { Colors } from 'constnants'
import { twMerge } from 'tailwind-merge'
import { ChevronRight } from 'components/ui/icons'

interface ExistingProjectItemProps {
  label: string
  hasIcon?: boolean
  isSelected: boolean
}

export const ExistingProjectItem = ({
  label,
  hasIcon = true,
  isSelected,
}: ExistingProjectItemProps) => {
  return (
    <div
      className={twMerge(
        'group flex w-full items-center justify-between rounded px-1 py-[5px] hover:cursor-pointer hover:bg-primary-black/5',
        isSelected && 'bg-primary-black/5',
      )}
    >
      <span className='text-sm leading-6 text-primary-black'>{label}</span>

      {hasIcon && (
        <ChevronRight fill={Colors.BASE_ICON} width={16} height={16} />
      )}
    </div>
  )
}
