import { api } from './api'

export type RevenueResponse = {
  start_date_id: number
  end_date_id: number
  revenue_last_year: number
  revenue_last_3years: number
}[]
export type CompanyResponse = {
  company_id: number
  company_name: string
  company_type: string
  abbreviation: string
  distributor_type: string
}[]
export type TitleSearchResponse = {
  end_date_id: number // Assuming date_id is a number
  start_date_id: number // Assuming date_id is a number
  title: string // Title of the series
  type: string // Type of the title (e.g., "Series", "Movie", etc.)
}[]
export type GroupResponse = {
  film_group_id: number
  film_group_key: string
  film_group_name: string
}[]
export type GenresResponse = {
  genre_id: number
  genre_key: string
  genre_name: string
}[]
export type ReleaseTypeResponse = {
  release_type_id: number
  release_type_key: string
  release_type_name: string
}[]
export type SidebarDataResponse = {
  companies: CompanyResponse
  groups: GroupResponse
  tvGenres: GenresResponse
  movieGenres: GenresResponse
  releaseTypes: ReleaseTypeResponse
}
export const releaseCalenderApi = api.injectEndpoints({
  endpoints: builder => ({
    getRevenue: builder.query<
      RevenueResponse,
      { months: number; startDate?: string | null }
    >({
      query: ({ months, startDate }) => {
        // Construct the query parameters
        const params = new URLSearchParams()
        params.append('months', months.toString())
        // Conditionally append startDate if it exists
        if (startDate && startDate?.length === 8) {
          params.append('startDate', startDate)
        }
        const paramString = params.toString()
        console.log('paramString', paramString)
        return {
          url: `/release-calender?${paramString}`,
          method: 'GET',
        }
      },
      providesTags: ['ReleaseCalender'],
    }),
    getCompanies: builder.query<CompanyResponse, void>({
      query: () => ({
        url: `/release-calender/companies`,
        method: 'GET',
      }),
      providesTags: ['ReleaseCalender'],
    }),
    getGroups: builder.query<CompanyResponse, void>({
      query: () => ({
        url: `/release-calender/groups`,
        method: 'GET',
      }),
      providesTags: ['ReleaseCalender'],
    }),
    getGenres: builder.query<CompanyResponse, void>({
      query: () => ({
        url: `/release-calender/genres`,
        method: 'GET',
      }),
      providesTags: ['ReleaseCalender'],
    }),
    getSidebarData: builder.query<SidebarDataResponse, void>({
      query: () => ({
        url: `/release-calender/sidebar`,
        method: 'GET',
      }),
      providesTags: ['ReleaseCalender'],
    }),
    searchTitle: builder.query<TitleSearchResponse, { title: string }>({
      query: ({ title }) => ({
        // Destructure title from the argument
        url: `/release-calender/titles?search=${encodeURIComponent(title)}`, // Use encodeURIComponent to handle special characters
        method: 'GET',
      }),
      providesTags: [],
    }),
  }),
})

export const {
  useGetRevenueQuery,
  useGetCompaniesQuery,
  useSearchTitleQuery,
  useGetGenresQuery,
  useGetGroupsQuery,
  useGetSidebarDataQuery,
} = releaseCalenderApi
