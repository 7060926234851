import { EntitySearchByField } from 'components'
import { MgmtCreateButton } from 'modules/management/shared'

interface TVFilmCompaniesToolbarProps {
  onSearchChange: (value: string) => void
}

export const TVManagementToolbar = ({
  onSearchChange,
}: TVFilmCompaniesToolbarProps) => {
  return (
    <div className='flex justify-between border-b border-primary-black/20 p-3'>
      <h1 className={'text-2xl font-semibold text-primary-black'}>
        TV Management
      </h1>
      <div className='flex items-center gap-4'>
        <EntitySearchByField
          placeholder='Search Name'
          onSearch={onSearchChange}
          onClear={() => onSearchChange('')}
        />

        <MgmtCreateButton label='New TV' />
      </div>
    </div>
  )
}
