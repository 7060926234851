export type TVFilmDistributor = {
  id: string
  type: TVFilmDistributorType
}

export type TVFilmCompany = {
  id: string
  type: TVFilmCompanyType
}

export type TVFilmCompanyData = {
  id: string
  name: string
  abbr: string
  imdbId: string
  companyType: TVFilmCompanyType
  distributorType?: TVFilmDistributorType | null
}

export enum TVFilmDistributorType {
  Major = 'major',
  Minor = 'minor',
  Other = 'other',
}

export enum TVFilmCompanyType {
  FilmDistributor = 'filmDistributor',
  TVBroadcast = 'tVBroadcast',
  TVCable = 'tVCable',
  TVStreamer = 'tVStreamer',
}

export enum TVFilmCompanyColKey {
  Name = 'name',
  Abbr = 'abbr',
  ImdbId = 'imdbId',
  CompanyType = 'companyType',
  DistributorType = 'distributorType',
  EditEntry = 'editEntry',
}

export type TVFilmCompanySortColKey =
  | TVFilmCompanyColKey.Name
  | TVFilmCompanyColKey.CompanyType
  | TVFilmCompanyColKey.DistributorType

export type TVFilmDistributorTypeFilterValue = TVFilmDistributorType | 'all'

export type TVFilmCompanyTypeFilterValue = TVFilmCompanyType | 'all'
