import React, { memo, useMemo } from 'react'
import { IconButton, Select } from 'components'
import { Previous, Next, ChevronRight, ChevronLeft } from 'components/ui/icons'
import { Colors } from 'constnants'
import { OptionType } from 'types'
import { twMerge } from 'tailwind-merge'

export interface PaginationChangeParams {
  page: number
  pageSize: number
}

interface PaginationToolbarProps {
  totalRows: number
  totalPages: number
  currentPage: number
  currentPageSize: number
  pageSizeOptions: OptionType<number>[]
  startContent?: React.ReactNode
  className?: string
  onChange: (params: PaginationChangeParams) => void
}

export const PaginationToolbar = memo(
  ({
    totalRows,
    totalPages,
    currentPage,
    currentPageSize,
    pageSizeOptions,
    startContent,
    className,
    onChange,
  }: PaginationToolbarProps) => {
    const startRange = (currentPage - 1) * currentPageSize + 1
    const endRange = Math.min(currentPage * currentPageSize, totalRows)
    const isPreviousDisabled = currentPage <= 1
    const isNextDisabled = currentPage >= totalPages
    const rangeInfo = totalRows > 0 ? `${startRange}-${endRange}` : 0

    const pageOptions = useMemo(
      () =>
        Array.from({ length: totalPages }, (_, i) => ({
          value: i + 1,
          label: (i + 1).toString(),
          id: (i + 1).toString(),
        })),
      [totalPages],
    )

    const handleRowsPerPageChange = (pageSize: number) =>
      onChange({ page: 1, pageSize })

    const handlePageChange = (page: number) =>
      onChange({ page, pageSize: currentPageSize })

    return (
      <div
        className={twMerge(
          'flex h-10 items-center justify-between border-t border-primary-black/20 bg-white p-2 pr-4',
          className,
        )}
      >
        <div>{startContent}</div>

        <div className='flex items-center space-x-12'>
          <div className='flex items-center space-x-4'>
            <span className='text-sm'>Rows:</span>
            <Select
              value={currentPageSize}
              options={pageSizeOptions}
              handleClick={option => handleRowsPerPageChange(option.value)}
              size='small'
              menuPosition='top'
              selectedLabelClass='font-normal'
            />
          </div>

          <div>
            <span className='text-sm text-primary-grey'>
              {`${rangeInfo} of `}
            </span>
            <span className='text-sm font-medium text-primary-grey'>
              {totalRows}
            </span>
          </div>

          <div className='flex items-center space-x-2 '>
            <Select
              value={currentPage}
              options={pageOptions}
              handleClick={option => handlePageChange(option.value)}
              size='small'
              menuPosition='top'
              buttonClass='min-w-16 px-1 h-[26px] py-0 items-center'
              withSeparator={true}
              selectedLabelClass='font-normal text-primary-black'
            />
            <span className='pl-1 text-xs text-primary-grey'>
              {`of ${totalPages} pages`}
            </span>

            <IconButton
              onClick={() => handlePageChange(1)}
              disabled={isPreviousDisabled}
              className='rounded hover:bg-opacityGrey-3'
            >
              <Previous fill={Colors.BASE_ICON} />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={isPreviousDisabled}
              className='rounded hover:bg-opacityGrey-3'
            >
              <ChevronLeft fill={Colors.BASE_ICON} width={16} height={16} />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={isNextDisabled}
              className='rounded hover:bg-opacityGrey-3'
            >
              <ChevronRight fill={Colors.BASE_ICON} width={16} height={16} />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(totalPages)}
              disabled={isNextDisabled}
              className='rounded hover:bg-opacityGrey-3'
            >
              <Next fill={Colors.BASE_ICON} />
            </IconButton>
          </div>
        </div>
      </div>
    )
  },
)

PaginationToolbar.displayName = 'PaginationToolbar'
