import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { twJoin, twMerge } from 'tailwind-merge'
import { DateRange as PickerDateRange } from 'react-day-picker'

import {
  DateRange,
  FilterButton,
  FilterColumnGroup,
  TalentDetail,
  TalentDetailAttributesList,
  TalentDetailContentHeader,
  Subheader,
  TalentDetailSimilarTalent,
  TalentTasteTable,
  LayoutContentWrapper,
  CompareTalentListMenu,
  IconButton,
  TalentMetricsChart,
} from 'components'
import { Attribute, ChevronDown, Talent } from 'components/ui/icons'
import {
  SelectedFilters,
  AttributeType,
  Urls,
  Attributes,
  TalentDateRange,
  DateRangeKey,
  TalentColors,
} from 'types'

import { buildParams, transformObjectToArray } from 'utils'
import { useChartData, useSelectAttribute } from 'hooks'
import {
  chartDetailAttributes,
  Colors,
  optionAttributes,
  optionSocialMedia,
  optionSearch,
  topOptions,
  TalentColorPalette,
} from 'constnants'
import useSerializeTalentData from '../../hooks/useSerializeTalentData'
import useTalentById from '../../hooks/useTalentById'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetTalentsChartDataQuery } from 'store/api'

import { toast } from 'react-toastify'
import { AudienceFilterButton } from 'components/page/talent/AudienceFilter'

export const TalentDetailPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
  const [contentTypeView, setContentTypeView] =
    useState<AttributeType>('percentage')
  const [showCompareTalentList, setShowCompareTalentList] = useState(false)
  const [talentIds, setTalentIds] = useState<string[]>([])
  const [sortedAttributes, setSortedAttributes] = useState<Attributes[]>([])
  const [top2Box, setTop2Box] = useState(topOptions[1].value)
  const [dataDateRange, setDataDateRange] = useState<TalentDateRange>('YEAR')
  const [audienceFilter, setAudienceFilter] = useState<string>('')

  const params = new URLSearchParams(location.search)
  const talentsIdsQueryParams = params.getAll('talentId')

  const onShowCompareTalentList = () => setShowCompareTalentList(true)
  const onHideCompareTalentList = () => setShowCompareTalentList(false)

  const [selectedRange, setSelectedRange] = useState<
    PickerDateRange | undefined
  >(undefined)
  const [selectedAttributeDropdown, setSelectedAttributeDropdown] =
    useState<SelectedFilters>({
      attribute: ['awareness'],
    })

  const { talentList: preparedTalentList, onSerializeTalent } =
    useSerializeTalentData()

  const { selectedAttribute } = useSelectAttribute(chartDetailAttributes)
  const {
    talentList,
    getTalent,
    getTalentsByIds,
    talentDetails,
    getTalentDetails,
  } = useTalentById()

  const { compareDetailsList, onPrepareTalentsDataChart } = useChartData()
  const searchParams = new URLSearchParams(location.search)
  const ids = searchParams.getAll('talentId')

  useEffect(() => {
    setTalentIds(ids)
    ids.length > 0 && getTalentsByIds(ids, top2Box, audienceFilter)
    id && getTalentDetails(id)
  }, [id, top2Box, audienceFilter])

  useEffect(() => {
    if (!ids.includes(id ?? '')) {
      ids.push(id ?? '')
      const queryParameters = buildParams({
        talentId: ids,
      })
      navigate(`${Urls.TALENT}/${id}?${queryParameters}`)
    }
  }, [id, location.search])

  useEffect(() => {
    if (talentIds.length > 0) {
      talentList && onSerializeTalent(talentList, contentTypeView)
    }
  }, [talentIds, contentTypeView, talentList, top2Box, audienceFilter])

  useEffect(() => {
    if (talentList.length > 0) {
      onPrepareTalentsDataChart({
        data: talentList,
        selectedAttributeDropdown,
        contentType: contentTypeView,
        isUniqueLabels: false,
      })
      onSerializeTalent(talentList, contentTypeView)
    }
  }, [talentList, selectedAttributeDropdown, contentTypeView, selectedRange])

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    if (showErrorMessage) {
      timeoutId = setTimeout(() => {
        setShowErrorMessage(false)
        setErrorMessage('')
      }, 2000)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [showErrorMessage])

  useEffect(() => {
    if (preparedTalentList.length > 0) {
      const firstColumn: Attributes[] = []
      const secondColumn: Attributes[] = []
      const firstColOrder = [
        'awareness',
        'fandom',
        'willseetheater',
        'willseestreaming',
        'authentic',
        'funny',
        'likable',
      ]
      const secondColOrder = [
        'inspirational',
        'trendy',
        'goodactor',
        'sexy',
        'overexposed',
        'overrated',
        'underrated',
      ]
      preparedTalentList[0]?.attributes.forEach(attr => {
        if (firstColOrder.includes(attr.name)) {
          firstColumn.push(attr)
        } else {
          secondColumn.push(attr)
        }
      })
      const sortedFirstColumn = firstColumn.sort(
        (a, b) => firstColOrder.indexOf(a.name) - firstColOrder.indexOf(b.name),
      )
      const sortedSecondColumn = secondColumn.sort(
        (a, b) =>
          secondColOrder.indexOf(a.name) - secondColOrder.indexOf(b.name),
      )
      setSortedAttributes([...sortedFirstColumn, ...sortedSecondColumn])
    }
  }, [preparedTalentList])

  const handleCompare = async (talentId: string) => {
    const res = await getTalent(talentId, top2Box)
    if (res?.statusCode === 404) {
      toast.info('Talent metrics data not found')
      return
    }
    const currentTalentIds = [...talentsIdsQueryParams, talentId]
    const queryParameters = buildParams({
      talentId: currentTalentIds,
    })
    navigate(`${Urls.TALENT}/${id}?${queryParameters}`)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const ids = searchParams.getAll('talentId')
    getTalentsByIds(ids, top2Box)
    setTalentIds(ids)
  }, [location.search])

  const selectedAttributeType = selectedAttributeDropdown['attribute'][0]
  const mergedAttributes = [
    ...optionAttributes,
    ...optionSocialMedia,
    ...optionSearch,
  ]
  const labelAttributeType = mergedAttributes.find(
    option => option.id === selectedAttributeType,
  )

  const { data: talentsChartData } = useGetTalentsChartDataQuery(
    {
      talentIds,
      metricsType:
        contentTypeView === 'percentage'
          ? 'PERCENTAGE'
          : contentTypeView === 'count'
            ? 'COUNT'
            : 'RANK',
      attribute: labelAttributeType?.key?.toUpperCase() || 'AWARENESS',
      duration: 'ALL',
      top2Box: top2Box === 'topTwoBox',
      audienceFilter,
    },

    {
      refetchOnMountOrArgChange: true,
      skip: talentIds?.length === 0,
    },
  )

  const handleApplyDateRange = (
    rangeKey: DateRangeKey,
    customRange?: PickerDateRange,
  ) => {
    setDataDateRange(rangeKey)
    setSelectedRange(customRange)
  }

  const handleDeleteCompareTalent = useCallback(
    (talentId: string) => {
      const updatedTalentIds = ids.filter(id => id != talentId?.toString())
      const queryParameters = buildParams({
        talentId: updatedTalentIds,
      })
      navigate(`${Urls.TALENT}/${id}?${queryParameters}`)
    },
    [compareDetailsList, location.search, id, navigate],
  )
  const handleAudienceFilterChange = (filters: string) => {
    setAudienceFilter(filters)
  }

  const talentColors = useMemo(() => {
    const { talents } = talentsChartData ?? {}
    if (!talents?.length) {
      return {}
    }

    return ids.reduce((colorsMapping, id, index) => {
      const talent = talents.find(talent => talent.talent_id.toString() === id)
      if (talent) {
        colorsMapping[talent.talent_id] = TalentColorPalette[index]
      }
      return colorsMapping
    }, {} as TalentColors)
  }, [talentsChartData])

  const compareDetailsListSorted = useMemo(() => {
    return [...compareDetailsList].sort(
      (a, b) =>
        ids.indexOf(a.talentId.toString()) - ids.indexOf(b.talentId.toString()),
    )
  }, [compareDetailsList])

  return (
    <LayoutContentWrapper wrapperClassName='bg-primary-white'>
      <Subheader navigateTo={Urls.TALENT} />
      <div className='flex h-[calc(100vh-85px)] w-full gap-5 overflow-y-auto pr-2 pt-4'>
        <TalentDetail talentDetails={talentDetails} />
        <div className='w-full space-y-2'>
          <TalentDetailContentHeader
            onAddTalentToCompare={handleCompare}
            onChangeContentTypeView={(typeView: string) =>
              setContentTypeView(typeView as AttributeType)
            }
            onChangeTop2Box={(top2Box: string) => setTop2Box(top2Box)}
            talentsCount={talentIds.length}
            selectedIds={ids}
          />
          {showErrorMessage && (
            <span className={twMerge('py-1 text-xs text-red-9')}>
              {errorMessage}
            </span>
          )}
          <div className='flex h-fit flex-col rounded-lg bg-grey-3 px-[15px] pb-[15px] pt-2 '>
            <div className='flex w-full justify-between pb-2'>
              <div className='flex flex-wrap gap-2'>
                <AudienceFilterButton
                  handleFilterChange={handleAudienceFilterChange}
                />
                <FilterButton
                  name={labelAttributeType?.label ?? ''}
                  trianglePosition='left'
                  totalSelectedFilters={0}
                  isMenuVisible={isMenuVisible}
                  setIsMenuVisible={setIsMenuVisible}
                  leftIcon={
                    <Attribute
                      width={20}
                      height={20}
                      fill={Colors.BASE_ICON}
                      className='pr-1'
                    />
                  }
                  rightIcon={
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={20}
                      height={20}
                      className='pl-1'
                    />
                  }
                  nameCLassName={twJoin(
                    selectedAttribute?.name && 'text-primary-red',
                  )}
                >
                  <div className='flex flex-row gap-10'>
                    <FilterColumnGroup
                      key={'attribute'}
                      category={'attribute'}
                      title={'Attributes'}
                      optionsFilter={optionAttributes}
                      selectedFilters={selectedAttributeDropdown}
                      isSingleSelection
                      onChange={selected => {
                        setIsMenuVisible(!isMenuVisible)
                        setSelectedAttributeDropdown(selected)
                      }}
                      isSelect={true}
                      titleClassName='text-start'
                      labelRadioClass='text-xs'
                    />
                    <FilterColumnGroup
                      key={'socialMedia'}
                      category={'attribute'}
                      title={'Social Media'}
                      optionsFilter={optionSocialMedia.filter(
                        item => item.value === 'allFollowers',
                      )}
                      selectedFilters={selectedAttributeDropdown}
                      isSingleSelection
                      onChange={selected => {
                        setIsMenuVisible(!isMenuVisible)
                        setSelectedAttributeDropdown(selected)
                      }}
                      isSelect={true}
                      titleClassName='text-start'
                      labelRadioClass='text-xs'
                    />
                    <FilterColumnGroup
                      key={'search'}
                      category={'attribute'}
                      title={'search'}
                      optionsFilter={optionSearch.filter(
                        item => item.value === 'pageViews',
                      )} //to see only WIKI VIEWS
                      selectedFilters={selectedAttributeDropdown}
                      isSingleSelection
                      onChange={selected => {
                        setIsMenuVisible(!isMenuVisible)
                        setSelectedAttributeDropdown(selected)
                      }}
                      isSelect={true}
                      titleClassName='text-start'
                      labelRadioClass='text-xs'
                    />
                  </div>
                </FilterButton>
              </div>
              <DateRange onApply={handleApplyDateRange} />
            </div>
            <div className='grid h-[311px] w-full grid-cols-2 gap-2'>
              <div className='relative h-[311px] rounded bg-primary-white pt-3'>
                {talentsChartData && (
                  <TalentMetricsChart
                    data={talentsChartData}
                    isPercentage={
                      labelAttributeType?.isSocial
                        ? labelAttributeType.isPercentage
                        : contentTypeView === 'percentage'
                    }
                    isRank={
                      labelAttributeType?.isSocial
                        ? false
                        : contentTypeView === 'rank'
                    }
                    talentDateRange={dataDateRange}
                    selectedDateRange={selectedRange}
                    talentColors={talentColors}
                  />
                )}

                {compareDetailsListSorted.length > 1 &&
                  (!showCompareTalentList ? (
                    <IconButton
                      className={twMerge(
                        'absolute bottom-12 left-6 h-6 w-6 rounded bg-primary-grey hover:cursor-pointer',
                        // talentIds.length > 1 && 'bottom-[90px]',     // TODO: check this behavior
                      )}
                      onClick={onShowCompareTalentList}
                      disabled={compareDetailsList.length === 1}
                    >
                      <Talent
                        fill={Colors.ICON_WHITE}
                        width={20}
                        height={20}
                        className='mx-auto'
                      />
                    </IconButton>
                  ) : (
                    <CompareTalentListMenu
                      talents={compareDetailsListSorted}
                      targetTalentId={id}
                      talentColors={talentColors}
                      onClose={onHideCompareTalentList}
                      handleDelete={id => {
                        handleDeleteCompareTalent(id)
                        onHideCompareTalentList()
                      }}
                      wrapperClassName='absolute bottom-12 left-6'
                    />
                  ))}
              </div>

              <TalentTasteTable
                data={talentsChartData}
                isPercentage={
                  labelAttributeType?.isSocial
                    ? labelAttributeType.isPercentage
                    : contentTypeView === 'percentage'
                }
                isRankView={
                  !labelAttributeType?.isSocial
                    ? contentTypeView === 'rank'
                    : false
                }
                compareDetails={compareDetailsListSorted}
                talentColors={talentColors}
                handleDelete={handleDeleteCompareTalent}
                talentDateRange={dataDateRange}
                selectedDateRange={selectedRange}
              />
            </div>
            <div className='grid grid-cols-2 gap-x-[5%] gap-y-4 pt-2 xl:flex xl:flex-row '>
              <TalentDetailAttributesList
                values={sortedAttributes.slice(0, 7)}
                isAttributes
                title='Attributes'
                selectedAttribute={selectedAttributeType || 'awareness'}
                contentTypeView={contentTypeView}
              />
              <TalentDetailAttributesList
                values={sortedAttributes.slice(7)}
                isAttributes
                hasTitle={false}
                selectedAttribute={selectedAttributeType || 'awareness'}
                contentTypeView={contentTypeView}
              />
              <TalentDetailAttributesList
                values={transformObjectToArray(preparedTalentList[0]?.social)}
                title='Social'
                selectedAttribute={selectedAttribute?.id || ''}
                isSocial
              />
              <TalentDetailAttributesList
                values={transformObjectToArray(
                  preparedTalentList[0]?.search,
                ).slice(1)}
                title='Search'
                selectedAttribute={selectedAttribute?.id || ''}
                isSocial
              />
            </div>
          </div>
          <div className='border-b border-b-opacityGrey-20' />
          <TalentDetailSimilarTalent
            similarTalents={talentDetails?.similarTalents || []}
          />
        </div>
      </div>
    </LayoutContentWrapper>
  )
}
