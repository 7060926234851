import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface NavItemProps {
  name: string
  icon: ReactNode
  isOpen?: boolean
  handleToggle?: () => void
  isActive: boolean
  isAdmin?: boolean
}

export const NavItem = ({
  name,
  icon,
  isOpen,
  handleToggle,
  isActive,
  isAdmin = false,
}: NavItemProps) => {
  return (
    <div
      onClick={handleToggle}
      className={twMerge(
        'flex h-8 max-w-full items-center gap-1.5 rounded-md px-2 transition-opacity hover:cursor-pointer hover:rounded-md hover:bg-white/20',
        isActive && 'rounded-md bg-white/20',
        !isOpen && isAdmin && 'bg-transparent',
      )}
    >
      {icon && <div>{icon}</div>}
      <span
        className={twMerge(
          'min-w-max text-sm font-medium leading-6 tracking-[-0.084px] text-green-1 transition-all duration-500',
          isOpen ? 'opacity-100' : 'hidden opacity-0',
        )}
      >
        {name}
      </span>
    </div>
  )
}
