import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Input, ReleaseCalendarSidebar, Select } from 'components'
import { Search, XCircle } from 'components/ui/icons'
import { Colors } from 'constnants'
import { useActions, useDebounce, useTypedSelector } from 'hooks'
import { dateRange } from './utils'
import { convertToDate, formatDate } from 'utils'
import { useSearchTitleQuery } from 'store/api'
import { OptionType } from 'types'
import { useDispatch } from 'react-redux'
import { releaseCalendarActions } from 'slices'

interface ReleaseCalendarWrapperContentProps extends PropsWithChildren {
  isOpen: boolean
  handleClose: () => void
  handleOpen: () => void
}

export const ReleaseCalendarWrapperContent = ({
  children,
  isOpen,
  handleOpen,
  handleClose,
}: ReleaseCalendarWrapperContentProps) => {
  const dispatch = useDispatch()
  const { setStartDate } = releaseCalendarActions
  const { selectedDateRange, startDate } = useTypedSelector(
    state => state.releaseCalendarReducer,
  )
  const { setSelectedDateRange } = useActions()

  const currentDate = useMemo(() => {
    const start = startDate ? convertToDate(startDate) : new Date()
    return formatDate(start, 'MMMM YYYY')
  }, [startDate])
  const [searchTitle, setSearchTitle] = useState<string>('')
  const debouncedTitle = useDebounce(searchTitle, 500) // A 500ms debounce delay
  const [isShowAutocomplete, setIsShowAutocomplete] = useState<boolean>(false)
  const [titleList, setTitleList] = useState<OptionType[]>([])

  const { data, error, isLoading } = useSearchTitleQuery(
    { title: debouncedTitle },
    {
      skip: !debouncedTitle, // Skip query if debouncedTitle is empty
    },
  )
  const handleAutoCompleteClick = useCallback(
    (item: OptionType) => {
      dispatch(setStartDate(item.id?.toString()))
      setIsShowAutocomplete(false)
    },
    [dispatch],
  )
  const handleCleanSearch = useCallback(() => {
    setSearchTitle('')
    setIsShowAutocomplete(false)
    dispatch(setStartDate(null))
  }, [dispatch, setSearchTitle, setIsShowAutocomplete])
  useEffect(() => {
    if (!debouncedTitle?.length) {
      setIsShowAutocomplete(false)
      setTitleList([])
    }
  }, [debouncedTitle])

  useEffect(() => {
    setIsShowAutocomplete(titleList.length > 0)
  }, [titleList])

  useEffect(() => {
    if (data && !isLoading && !error && data.length) {
      console.log('data', data)
      const titles = data.map(item => ({
        id: item.start_date_id,
        value: item.title,
        label: item.title,
        description: item.type,
      }))
      console.log('titles', titles)
      setTitleList(titles)
    }
  }, [data, error, isLoading])

  // set hidden to not display Select date range
  return (
    <div className='relative mx-2 mt-2 flex flex-col overflow-hidden rounded-tl bg-primary-white px-3 py-2'>
      <div className='flex items-center justify-between rounded'>
        <span className='py-3 text-lg font-medium text-primary-black'>
          {currentDate}
        </span>
        <div className='flex items-center gap-4'>
          <div className='flex hidden items-center gap-1'>
            <span className='text-xs font-medium text-primary-grey'>
              Range:
            </span>
            <Select
              value={selectedDateRange.value}
              handleClick={setSelectedDateRange}
              options={dateRange}
              buttonClass='border-0'
            />
          </div>
          <div>
            <Input
              containerWrapperClassName='w-[200px] h-6 hidden'
              placeholder='Name Lookup'
              value={searchTitle}
              onChange={e => {
                setSearchTitle(e.target.value)
              }}
              leftIcon={
                <Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />
              }
              rightIcon={
                searchTitle?.length ? (
                  <XCircle
                    onClick={handleCleanSearch}
                    className='mr-1 hover:cursor-pointer'
                  />
                ) : (
                  <></>
                )
              }
              onFocus={() => setIsShowAutocomplete(true)}
              autoComplete='off'
              isShowAutocomplete={isShowAutocomplete}
              autocompleteData={titleList}
              handleAutocompleteClick={handleAutoCompleteClick}
            />
          </div>
        </div>
      </div>
      <div className='flex gap-[1px]'>
        <ReleaseCalendarSidebar
          isOpen={isOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
        {children}
      </div>
    </div>
  )
}
