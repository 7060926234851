import { Link } from 'react-router-dom'
import { Image, TalentName } from 'components'
import { Talent, Urls } from 'types'

interface TalentPersonCellProps {
  talent: Talent
  isLargeRows: boolean
}

export const TalentPersonCell = ({
  talent,
  isLargeRows,
}: TalentPersonCellProps) => {
  return (
    <Link to={`${Urls.TALENT}/${talent.talentId}`} className='flex w-full'>
      <div className='flex flex-col'>
        <TalentName
          size='xs'
          talentName={talent.talentName}
          className='text-left'
        />
        {isLargeRows && (
          <div className='flex h-[50px] w-10 justify-start pt-0.5'>
            <Image alt={talent.talentName} src={talent.profileImage} />
          </div>
        )}
      </div>
    </Link>
  )
}
