import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useParams } from 'react-router'
import { Select as CustomSelect, FilmMetricsChart } from 'components'
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
} from 'react-select'
import { twMerge } from 'tailwind-merge'
import { JSX } from 'react/jsx-runtime'

import {
  FilmDetail,
  FilmDetailContentHeader,
  Subheader,
  LayoutContentWrapper,
  FilmWeekendProjectTable,
  FilmBoxOfficeUniverseTable,
} from 'components'

import { type FilmMetric } from 'types'
import {
  PlusInBox,
  Search,
  FilmTracking,
  CloseMenu,
  Close,
} from 'components/ui/icons'
import { Colors, ChartColorPalette } from 'constnants'

import './Search.css'
import {
  FilmChartData,
  useFilmMetricsControllerGetChartDataQuery,
  useFilmMetricsControllerGetFilmMetricsByFilmIdQuery,
  useLazyFilmMetricsControllerGetSearchFilmQuery,
} from 'store/api'

type FilmOption = {
  value: string
  label: string
}

export const FilmDetailPage = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { id } = useParams()
  const [activeButton, setActiveButton] = useState('awareness')
  const [selectedFilm, setSelectedFilm] = useState<FilmOption[]>([])
  const [addFilmToCompare, setAddFilmToCompare] = useState<FilmOption[]>([])
  const [inputValue, setInputValue] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [showFilmsList, setShowFilmsList] = useState(false)
  const [recentlyViewed, setRecentlyViewed] = useState<FilmOption[]>([])
  const [graphsOrder, setGraphsOrder] = useState('date')

  const { data: filmData, isLoading } =
    useFilmMetricsControllerGetFilmMetricsByFilmIdQuery({ filmId: Number(id) })

  const [trigger, searchResult] =
    useLazyFilmMetricsControllerGetSearchFilmQuery()

  const ids = useMemo(
    () => [
      ...new Set(
        selectedFilm
          .filter(sf => sf.value !== id)
          .map(item => Number(item.value)),
      ),
    ],
    [selectedFilm],
  )

  const { data: filmMetricsChart } = useFilmMetricsControllerGetChartDataQuery({
    filmIds: [Number(id!), ...ids],
    attribute: activeButton,
  })

  const toOptionsFormat = ({ data }) => {
    if (!data) return
    return data.map(sr => ({ value: sr.film_id, label: sr.film_name }))
  }

  const handleAddFilmsToCompare = (films: FilmOption[]) => {
    setShowSearch(false)
    setAddFilmToCompare(films)
  }

  /* Search data START */

  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Search fill={Colors.BASE_ICON} width={12} height={12} />
        </components.DropdownIndicator>
      )
    )
  }

  const filteredOptions = [
    {
      label: 'RECENTLY VIEWED',
      options: recentlyViewed,
    },
  ]

  const graphsOrderOptions = [
    { id: '1', value: 'date', label: 'Date' },
    { id: '2', value: 'release', label: 'Days before release' },
  ]

  const handleGraphsOrder = (value: string) => {
    setGraphsOrder(value)
  }

  const removeFilmToCompare = (filmId: string) => {
    setSelectedFilm(selectedFilm.filter(item => item.value !== filmId))
  }

  const handleActiveButton = (key: string) => setActiveButton(key)

  useEffect(() => {
    if (inputValue.length > 3) {
      trigger({ filmName: inputValue })
    }
  }, [inputValue])

  useEffect(() => {
    setSelectedFilm([
      { value: id!, label: filmData?.film_name || 'there is no name' },
    ])
  }, [filmData])

  // handle add film to compare toggle
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowSearch(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const removeDuplicates = (arr: FilmOption[]) => {
    return [...new Set([...arr])]
  }

  useEffect(() => {
    if (Array.isArray(addFilmToCompare) && addFilmToCompare.length > 0) {
      setSelectedFilm(prev => removeDuplicates([...prev, ...addFilmToCompare]))
      setRecentlyViewed(prev =>
        removeDuplicates([...prev, ...addFilmToCompare]),
      )
      setInputValue('')
    }
  }, [addFilmToCompare])

  useEffect(() => {
    if (selectedFilm.length === 1) {
      setShowFilmsList(false)
    }
  }, [selectedFilm])

  const getSortedFilmMetrics = (
    filmMetricsChart?: FilmChartData[],
  ): FilmChartData[] => {
    if (!filmMetricsChart || filmMetricsChart.length === 0) return []

    return selectedFilm
      .map(item =>
        filmMetricsChart.find(fm => fm.film_id === Number(item.value)),
      )
      .filter(Boolean) as FilmChartData[]
  }

  return (
    <LayoutContentWrapper>
      <Subheader hasButtonsLabels />
      <div className='flex h-[calc(100vh-100px)] w-full overflow-y-auto'>
        <FilmDetail filmData={filmData!} />
        <div className='w-[calc(100%_-_16rem)] border-x-8 border-primary-white bg-white'>
          {!isLoading && filmData && (
            <FilmDetailContentHeader
              activeButton={activeButton}
              onActiveButton={handleActiveButton}
              metrics={filmData?.film_metrics as FilmMetric[]}
            />
          )}
          <div className='relative mb-3 flex-col bg-grey-2 p-4 pt-3'>
            <div className='flex items-center gap-3.5'>
              <div className='relative py-1' ref={ref}>
                <div
                  className='relative flex items-center gap-1 text-sm font-semibold hover:cursor-pointer'
                  onClick={() => {
                    setShowSearch(!showSearch)
                  }}
                >
                  <PlusInBox fill={Colors.BASE_ICON} />
                  <span>Add Film to compare</span>
                </div>
                <div
                  className={twMerge(
                    'absolute left-0 top-[calc(100%_+_20px)] z-10 w-[312px] border border-grey-2 bg-white',
                    showSearch ? 'block' : 'hidden',
                  )}
                >
                  {selectedFilm.length === 4 ? (
                    <div className='p-2 text-xs'>
                      The maximum number of films for comparison is 4
                    </div>
                  ) : (
                    <Select
                      className='films-to-compare-select'
                      classNamePrefix='films-to-compare-select-options'
                      menuIsOpen={showSearch}
                      value=''
                      onChange={handleAddFilmsToCompare}
                      onInputChange={newValue => {
                        if (newValue.length > 3) setInputValue(newValue)
                      }}
                      placeholder={'Name Lookup'}
                      options={
                        inputValue
                          ? toOptionsFormat(searchResult)
                          : filteredOptions
                      }
                      isSearchable={true}
                      isMulti
                      isClearable={false}
                      closeMenuOnSelect={false}
                      defaultMenuIsOpen={true}
                      isDisabled={selectedFilm.length >= 4}
                      role='search'
                      components={{ DropdownIndicator }}
                      noOptionsMessage={() => 'No Films found!'}
                    />
                  )}
                </div>
              </div>
              {selectedFilm.length > 1 && (
                <CustomSelect
                  options={graphsOrderOptions}
                  size='small'
                  value={graphsOrder}
                  handleClick={option => {
                    handleGraphsOrder(option.value)
                  }}
                  defaultValue={'date'}
                />
              )}
            </div>
            <div className=' bg-primary-white pt-3'>
              {filmMetricsChart && (
                <FilmMetricsChart
                  data={getSortedFilmMetrics(filmMetricsChart)}
                />
              )}
            </div>
            {selectedFilm.length > 1 && (
              <div className='absolute bottom-[60px] left-[23px] rounded bg-primary-grey p-2 hover:cursor-pointer'>
                <FilmTracking
                  fill={Colors.ICON_WHITE}
                  onClick={() => setShowFilmsList(prev => !prev)}
                />
                <div
                  className={twMerge(
                    'absolute bottom-0 left-0 rounded border-primary-black/20 bg-grey-2 shadow-md' +
                      ' min-w-[250px] items-end border p-2 hover:cursor-default',
                    showFilmsList ? 'flex' : 'hidden',
                  )}
                >
                  <div className='basis-6 hover:cursor-pointer'>
                    <CloseMenu
                      fill={Colors.BASE_ICON}
                      width={24}
                      height={24}
                      onClick={() => setShowFilmsList(false)}
                    />
                  </div>
                  <div className='basis-full flex-col'>
                    {selectedFilm.map((film, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            'flex items-center justify-between gap-1 p-1 text-primary-black'
                          }
                        >
                          <div className='flex items-center gap-1'>
                            <div
                              className='h-[13px] w-[13px] rounded-full'
                              style={{
                                backgroundColor: ChartColorPalette[index],
                              }}
                            />
                            <span className='text-xs text-primary-black'>
                              {film.label}
                            </span>
                          </div>

                          {index !== 0 && (
                            <span
                              className='flex h-4 w-5 items-center justify-center rounded border border-primary-black/10 bg-primary-black/5 hover:cursor-pointer'
                              onClick={() => removeFilmToCompare(film.value)}
                            >
                              <Close
                                fill={Colors.BASE_ICON}
                                width={16}
                                height={16}
                              />
                            </span>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          {id && (
            <div className='flex gap-2'>
              <FilmWeekendProjectTable filmId={id} className={'w-2/6'} />
              <FilmBoxOfficeUniverseTable filmId={id} className={'w-2/3'} />
            </div>
          )}
        </div>
      </div>
    </LayoutContentWrapper>
  )
}
