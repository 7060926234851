import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { ReleaseCalendarData, ReleaseWeek } from 'types'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const splitIntoWeeks = (
  data: ReleaseCalendarData[],
  weeks: ReleaseWeek[],
) => {
  return data?.map(item => {
    const weeksData = weeks.map((week, index) => {
      const weekStart = dayjs(week.startDate)
      const weekEnd = dayjs(week.endDate)

      const matchingWeekItems = item.weeks
        ?.filter((weekItem: any) => {
          const startDate = dayjs(weekItem.startDay)
          const endDate = dayjs(weekItem.endDay)

          return (
            startDate.isSameOrBefore(weekEnd) &&
            endDate.isSameOrAfter(weekStart)
          )
        })
        .map((weekItem: any) => {
          const startDate = dayjs(weekItem.startDay)
          const endDate = dayjs(weekItem.endDay)

          const overlapStart = startDate.isBefore(weekStart)
            ? weekStart
            : startDate
          const overlapEnd = endDate.isAfter(weekEnd) ? weekEnd : endDate

          const continuesNextWeek = endDate.isAfter(weekEnd)
          const continuesPrevWeek = startDate.isBefore(weekStart)

          return {
            ...weekItem,
            weekId: week.value,
            overlapStart: overlapStart.format('YYYY-MM-DD'),
            overlapEnd: overlapEnd.format('YYYY-MM-DD'),
            continuesNextWeek,
            continuesPrevWeek,
            originalStart: weekItem.startDay,
            originalEnd: weekItem.endDay,
          }
        })

      return { week, weekItems: matchingWeekItems ?? [] }
    })

    return { ...item, weeks: weeksData }
  })
}
