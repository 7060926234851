import { RoleProject } from '../types'
import { v4 as uuidv4 } from 'uuid'

export const createEmptyProjectRole = (
  roleId: string = uuidv4(),
): RoleProject => ({
  roleId,
  roleName: '',
  roleType: { id: '', value: '', label: '' },
  attachedTalent: { id: '', value: '', label: '' },
  description: '',
  talents: [],
})
