import { useCallback, useMemo, useState } from 'react'
import { CompanyTableBody, CompanyTableHeader } from './components'
import { CompanyTableProps } from './CompanyTable.types'
import { managementRowsPerPageOptions } from 'constnants'
import { CompanyModal } from '../CompanyModal'
import { NewCompanyData } from '../CompanyModal/CompanyModal.types'
import { CheckCircle } from '../ui/icons'
import { Colors, MIN_PAGE_SIZE } from '../../constnants'
import {
  PaginationChangeParams,
  PaginationToolbar,
} from 'components/FilmTrackingTable'

const initialState = {
  companyName: '',
  companyType: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  primaryContact: '',
  email: '',
  accountStatus: '',
}
export const CompanyTable = ({
  columns,
  data,
  onSort,
  onSortOrder,
  sortingBY,
  setCurrentPage,
  currentPage = 1,
  rowsPerPage = MIN_PAGE_SIZE,
  setRowsPerPage,
  totalCompaniesCount = 0,
  isLoading,
}: CompanyTableProps) => {
  const [companyDataToEdit, setCompanyDataToEdit] =
    useState<NewCompanyData>(initialState)
  const [successMessage, setSuccessMessage] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const totalRows = totalCompaniesCount
  const totalPages = useMemo(
    () => Math.ceil(totalRows / rowsPerPage),
    [totalRows, rowsPerPage],
  )

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage?.(params.page)
      setRowsPerPage?.(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  return (
    <div className='h-[calc(100vh_-_125px)] w-full overflow-auto py-3 pb-6 xl:h-[calc(100vh_-_114px)]'>
      <table className='company-table'>
        <CompanyTableHeader
          columns={columns}
          onSort={onSort}
          onSortOrder={onSortOrder}
          sortingBY={sortingBY}
        />
        <CompanyTableBody
          data={data}
          columns={columns}
          setCompanyDataToEdit={setCompanyDataToEdit}
          isLoading={isLoading}
        />
      </table>
      <div className='fixed bottom-0 left-3 right-0'>
        <PaginationToolbar
          totalRows={totalRows}
          totalPages={totalPages}
          currentPage={currentPage}
          currentPageSize={rowsPerPage}
          pageSizeOptions={managementRowsPerPageOptions}
          onChange={handlePagination}
        />
      </div>
      {companyDataToEdit.companyName && (
        <CompanyModal
          title={'Edit Company Details'}
          handleClose={() => setCompanyDataToEdit(initialState)}
          isOpen={companyDataToEdit.companyName !== ''}
          onSubmit={() => {
            setSuccessMessage('Company details updated')
            setShowSuccessMessage(true)
            setCompanyDataToEdit(initialState)
            setTimeout(() => {
              setShowSuccessMessage(false)
              setSuccessMessage('')
            }, 3000)
          }}
          saveTitle={'Save'}
          initialData={companyDataToEdit}
          requestType={'PUT'}
        />
      )}
      {showSuccessMessage && (
        <div className='fixed bottom-12 right-4 rounded-md bg-primary-white p-3 shadow-md'>
          <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
            <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
            {successMessage}
          </p>
        </div>
      )}
    </div>
  )
}
