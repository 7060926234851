import React from 'react'
import { ReleaseWeek } from 'types'

interface WeekListProps {
  weeks: ReleaseWeek[] | null
}

const WeekListComponent = ({ weeks }: WeekListProps) => {
  if (!weeks) return null
  return (
    <div className='flex border-b border-b-primary-black/10 py-2 pt-1'>
      {weeks.map(week => (
        <div
          key={week.value}
          className='w-[126px] text-start text-xs font-medium text-grey-7 first:pl-2'
        >
          {week.title}
        </div>
      ))}
    </div>
  )
}
export const WeekList = React.memo(WeekListComponent)
