import { api } from './api'
import {
  Project,
  ProjectCompany,
  ProjectCompanyRole,
  ProjectResponse,
} from 'types'

export const myProjectsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProjects: builder.query<
      { projects: Project[]; all: number },
      {
        pageSize?: number
        pageNumber?: number
        name?: string
        phaseStatus?: string
        sortBy?: string
        sortOrder?: string
        isActive?: boolean
      }
    >({
      query: ({
        pageSize,
        pageNumber,
        name,
        phaseStatus,
        sortBy,
        sortOrder,
        isActive,
      }) => {
        return {
          url: `/project/company`,
          params: {
            pageSize,
            pageNumber,
            name,
            phaseStatus,
            sortBy,
            sortOrder,
            isActive,
          },
        }
      },
      providesTags: ['MyProject'],
    }),
    createProject: builder.mutation<ProjectCompany, Partial<ProjectCompany>>({
      query: project => ({
        body: project,
        url: '/project/company',
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'MyProject' }],
    }),
    updatedProject: builder.mutation<
      ProjectCompany,
      { id: number; project: Partial<ProjectCompany> }
    >({
      query: updateProject => ({
        body: { ...updateProject.project },
        url: `/project/company/${updateProject.id}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyProject' }],
    }),
    deleteProject: builder.mutation<Project, { id: number }>({
      query: project => ({
        url: `/project/company/${project.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'MyProject' }],
    }),
    getProjectCompanyById: builder.query<Project[], { id: string }>({
      query: ({ id }) => ({
        url: `/project/company/${id}`,
        method: 'GET',
      }),
      providesTags: ['MyProject'],
    }),
    getProjectCompanyDetailsByProjectId: builder.query<
      Project[],
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/project/company/details/${id}`,
        method: 'GET',
      }),
      providesTags: ['MyProject'],
    }),
    getProjectCompanyWithRoleData: builder.query<
      { projects: ProjectResponse[] },
      { id: number }
    >({
      query: () => ({
        url: `/projects`,
        method: 'GET',
      }),
      providesTags: ['MyProject'],
    }),
    createProjectRoles: builder.mutation<
      ProjectCompanyRole,
      Partial<ProjectCompanyRole>
    >({
      query: project => ({
        body: project,
        url: `/project/${project.company_project_id}/roles`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'MyProject' }],
    }),
  }),
})

export const {
  useGetProjectCompanyWithRoleDataQuery,
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useCreateProjectMutation,
  useUpdatedProjectMutation,
  useGetProjectCompanyByIdQuery,
  useGetProjectCompanyDetailsByProjectIdQuery,
  useCreateProjectRolesMutation,
} = myProjectsApi
