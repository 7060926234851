import { ChangeEvent, useEffect, useState } from 'react'
import { Button, LogLineCard, Select, Textarea } from '../../components'
import { OptionFilter, Project } from '../../types'
import { Colors } from 'constnants'
import { CheckCircle } from '../../components/ui/icons'
import { submitTesting } from '../../hooks'
import { useGetProjectCompanyByIdQuery } from 'store/api'

interface ConceptTemplateProps {
  returnBack: () => void
}

export const ConceptTemplate = ({ returnBack }: ConceptTemplateProps) => {
  const companyId = localStorage.getItem('companyId')
  const { data } = useGetProjectCompanyByIdQuery(
    {
      id: companyId ?? '',
    },
    { skip: !companyId },
  )
  const [projectsList, setProjectsList] = useState<OptionFilter[]>([])
  useEffect(() => {
    if (data) {
      setProjectsList(
        data.map((project: Project) => ({
          id: project.company_project_id || '0',
          value: project.company_project_id || '0',
          label: project.name || '',
        })),
      )
    }
  }, [data])
  const [isSuccess, setIsSuccess] = useState(false)
  const [fields, setFields] = useState({
    projectId: '',
    audience: '',
    logLine: '',
  })

  const handleSelectChange = (option: OptionFilter) => {
    setFields({ ...fields, projectId: option.value })
  }
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.length <= 500) {
      setFields({ ...fields, logLine: value })
    }
  }

  useEffect(() => {
    validateForm()
  }, [fields])

  const validateForm = () => {
    const { projectId, logLine } = fields
    return projectId && logLine
  }

  const submitForm = () => {
    submitTesting('concept', fields, setIsSuccess, returnBack).then(r => r)
  }

  return (
    <>
      {isSuccess && (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-primary-black/50'>
          <span
            className={
              'flex items-center rounded bg-primary-white px-3 py-2 shadow-md'
            }
          >
            <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
            <span className='pl-2'>Test created successfully</span>
          </span>
        </div>
      )}
      <div className='mb-8'>
        <p className={'text-lg font-medium text-primary-black'}>
          Select a Project and Audience
        </p>
        <p className={'text-sm text-primary-grey'}>
          Describe what a this test if about.
        </p>
      </div>
      <div className='w-1/2 min-w-[650px]'>
        <div className='mb-8'>
          <Select
            value={fields.projectId}
            handleClick={handleSelectChange}
            options={projectsList}
            containerClass='w-[400px]'
            placeholder='Select Project'
            label='Project'
            labelClass='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
            buttonClass='py-[9.4px]'
            size='medium'
            selectedLabelClass='text-sm text-grey-7 font-normal'
            hasRadioButton={false}
          />
        </div>
        <div className='mb-10 border-b pb-10'>
          <p
            className={
              'pb-0 text-sm font-semibold uppercase leading-6 text-grey-7'
            }
          >
            Your Custom Audience
          </p>
          <p className={'mb-3 text-sm text-primary-grey'}>
            In addition to the 30 general demographics we survey across, please
            provide other demographics you would like Greenlight to isolate.
          </p>
          <Textarea
            onChange={e => setFields({ ...fields, audience: e.target.value })}
            placeholder={
              'E.g. Fans of horror movies, Fans of John Carpenter, Video Gamers, People who watch the Olympics, Soccer Enthusiasts'
            }
          />
          <p className={'text-xs text-primary-grey'}>Optional</p>
        </div>
        <div className='mb-8'>
          <p className={'text-lg font-medium text-primary-black'}>
            Enter Logline to be tested
          </p>
          <p className={'mb-8 text-sm text-primary-grey'}>
            Please add the logline you would like to test.
          </p>
          <LogLineCard
            title='Logline'
            titleClassName='font-semibold text-grey-7'
            exampleLogline='“Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet. “otestate sunt infirmi, servilis, licet, lex pertinet.  Sunt infirmi, servilis, licet, lex pertinet. “otestate sunt infirmi, servilis, licet, lex pertinet.”'
            value={fields.logLine}
            handleChange={handleChange}
            isStep
          />
        </div>

        <Button
          kind='filled'
          size='medium'
          className='bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10 disabled:bg-primary-red'
          onClick={submitForm}
          disabled={!validateForm()}
        >
          <div className='flex items-center'>
            <span className='pr-1 text-sm font-semibold'>Submit test</span>
          </div>
        </Button>
      </div>
    </>
  )
}
