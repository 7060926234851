import { Link } from 'react-router-dom'
import { FilmsType, Urls } from 'types'

interface FilmTitleCellProps {
  row: FilmsType
}

export const FilmTitleCell = ({ row }: FilmTitleCellProps) => {
  return (
    <Link to={`${Urls.FILM}/${row.id}`} className='flex'>
      {row.film}
    </Link>
  )
}
