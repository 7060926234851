import React from 'react'

const PageLoader = () => {
  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40'>
      <div
        className='h-16 w-16 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue-500'
        style={{
          borderWidth: '3px', // Custom border width
          borderColor: '#f3f3f3', // Default color for other sides
          borderTopColor: 'var(--Icon-Colored-icon-brand, #FF4449)', // Custom border top color
        }}
      ></div>
    </div>
  )
}

export default PageLoader
