import React, { useEffect } from 'react'
import { Portal } from 'react-portal'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  targetRef: React.RefObject<HTMLDivElement>
  children: React.ReactNode
}
const PortalModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  targetRef,
  children,
}) => {
  if (!isOpen) return null

  const targetRect = targetRef.current?.getBoundingClientRect()

  useEffect(() => {
    window.addEventListener('resize', onClose)

    return () => window.removeEventListener('resize', onClose)
  }, [])

  return (
    <Portal>
      <div className='modal-overlay' onClick={onClose}>
        <div
          className='modal-content'
          style={{
            position: 'absolute',
            transform: 'translateY(-50%)',
            top: targetRect ? targetRect.top : 0,
            left: targetRect ? targetRect.right - 325 : 0, // 325 is the width of the modal and button
          }}
          onClick={e => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </Portal>
  )
}

export default PortalModal
