import { ImgHTMLAttributes, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import skeleton from 'assets/images/placeholder.png'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  alt: string
  src: string
  fallbackSrc?: string
  className?: string
}

export const Image = ({
  alt,
  src,
  fallbackSrc = skeleton,
  className,
  ...props
}: ImageProps) => {
  const [imgSrc, setImgSrc] = useState(src)

  useEffect(() => {
    setImgSrc(src || fallbackSrc)
  }, [src, fallbackSrc])

  const handleError = () => {
    setImgSrc(fallbackSrc)
  }

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={handleError}
      className={twMerge('h-full w-full object-cover', className)}
      {...props}
    />
  )
}
