import React, { useEffect, useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
  TalentColors,
  TalentDateRange,
  TalentMetricsChartResponse,
} from 'types'
import {
  dateToUTCTimestamp,
  formatNumberWithSuffix,
  getOrdinalSuffix,
  parseDateId,
} from 'utils'
import { subMonths, subYears, isWithinInterval, startOfMonth } from 'date-fns'
import { DateRange as PickerDateRange } from 'react-day-picker'
import { formatDateAxisValue, generateDateAxisTicks } from './utils'

const processData = (
  data,
  range: TalentDateRange,
  talentColors: TalentColors,
  selectedDateRange?: PickerDateRange,
) => {
  if (range === 'DATE_RANGE' && !selectedDateRange) {
    return { series: [], xAxisTicks: [] }
  }
  if (!data || !data.talents || data.talents.length === 0) {
    return { series: [], xAxisTicks: [] }
  }

  const allSurveyData = data.talents
    .map(talent => talent.surveys.map(survey => survey.data))
    .flat()
    .filter(value => value !== null)

  const globalYMin = Math.min(...allSurveyData)
  const globalYMax = Math.max(...allSurveyData)

  const isSurveyDates =
    data?.allSurveyDates?.length > 0 && data?.allSurveyDates?.[0]
  const extendedSurveyDates = isSurveyDates ? [...data.allSurveyDates] : []

  const sortedDates = [...extendedSurveyDates].sort((a, b) => a - b)
  const now =
    range === 'DATE_RANGE'
      ? (selectedDateRange?.to ?? selectedDateRange?.from)
      : startOfMonth(new Date())

  // Set rangeStartDate based on the selected range
  let rangeStartDate
  switch (range) {
    case 'MONTH':
      rangeStartDate = subMonths(now, 1)
      break
    case 'QUARTER':
      rangeStartDate = subMonths(now, 3)
      break
    case 'HALF_YEAR':
      rangeStartDate = subMonths(now, 6)
      break
    case 'YEAR':
      rangeStartDate = subYears(now, 1)
      break
    case 'DATE_RANGE':
      rangeStartDate = selectedDateRange?.from ? selectedDateRange.from : null
      break
    case 'ALL':
    default:
      rangeStartDate = null // No filter for 'ALL'
  }

  // Filter the dates based on rangeStartDate (if not 'ALL')
  const filteredDates = rangeStartDate
    ? sortedDates.filter(date => {
        const parsedDate = parseDateId(date) // Ensure date is properly parsed

        return isWithinInterval(parsedDate, { start: rangeStartDate, end: now })
      })
    : sortedDates // No filtering for 'ALL'

  const xAxisTicks = rangeStartDate
    ? generateDateAxisTicks(rangeStartDate, now)
    : generateDateAxisTicks(
        parseDateId(sortedDates[0]),
        parseDateId(sortedDates[sortedDates.length - 1]),
      )
  // dummy tick data for the xAxis label generations
  //const xAxisTicksData = xAxisTicks.map(date => [date, null, true])
  const series = data.talents.map((talent, index) => {
    return {
      name: talent.talent_name,
      data: filteredDates.map(date => {
        const survey = talent.surveys.find(s => s.date_survey_id === date)
        const value = survey ? survey.data : null
        return [
          dateToUTCTimestamp(parseDateId(date)),
          value,
          survey?.hide || false,
        ]
      }),
      connectNulls: true,
      color: talentColors[talent.talent_id],
      marker: {
        enabled: function () {
          return !this.point.hide
        },
        radius: 4, // Set the marker radius
        symbol: 'circle', // Set the marker shape
        lineColor: null, // Line color will be inherited from series
      },
    }
  })

  return {
    series,
    xAxisTicks,
    globalYMin,
    globalYMax,
  }
}

// Component to render the Highcharts line chart
export const TalentMetricsChart = ({
  data,
  isPercentage,
  isRank,
  talentDateRange, // Set default value to 'YEAR'
  selectedDateRange,
  chartHeight = 300,
  talentColors,
}: {
  data?: TalentMetricsChartResponse
  isPercentage?: boolean
  isRank?: boolean
  talentDateRange: TalentDateRange
  selectedDateRange?: PickerDateRange
  chartHeight?: number
  talentColors: TalentColors
}) => {
  const { series, xAxisTicks, globalYMin, globalYMax } = useMemo(
    () => processData(data, talentDateRange, talentColors, selectedDateRange),
    [data, talentDateRange, selectedDateRange, talentColors],
  )
  const getYAxisLabelFormatter = (isPercentage: boolean, isRank: boolean) => {
    return function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const value = this.value
      if ((isPercentage || isRank) && value === 110) {
        return null
      }
      if (isPercentage) {
        return value ? `${Highcharts.numberFormat(value, 0)}%` : null // Format as percentage
      }
      if (isRank) {
        return value
          ? `${Highcharts.numberFormat(value, 0)}<tspan style="font-size: 0.8em; color: gray;" dy="-0.5em">th</tspan>`
          : null // Format as percentile
      }
      return formatNumberWithSuffix(value) // Format as number
    }
  }

  useEffect(() => {
    const handleResize = () => {
      const chart = Highcharts.charts[0]
      if (chart) {
        chart.reflow()
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const renderSuffix = (rank: string | number) => {
    const numericRank = typeof rank === 'string' ? parseInt(rank, 10) : rank
    const suffix = getOrdinalSuffix(numericRank)

    return suffix
  }

  const chartOptions = {
    title: {
      text: null, // Remove chart title
    },
    xAxis: {
      type: 'datetime',

      labels: {
        formatter: function () {
          return formatDateAxisValue(this.value)
        },
        // step: 0, // Show every label
      },
      minPadding: 0.05, // Adds space before the first tick
      maxPadding: 0.05, // Adds space after the last tick
      tickPixelInterval: 100, // Adjust as needed
      tickLength: 0, // Remove tick marks if desired
      gridLineWidth: 1, // Width of vertical grid lines
      gridLineDashStyle: 'Dash', // Style of vertical grid lines
      gridLineColor: '#e0e0e0', // Color of vertical grid lines
      tickPositions: xAxisTicks, // Place ticks at each month
      endOnTick: true, // Prevent the last tick from being forced
      startOnTick: true, // Prevent the first tick from being forced
    },
    yAxis: {
      title: {
        text: null, // Remove y-axis title
      },
      opposite: true, // Moves the y-axis labels to the right side
      labels: {
        formatter: getYAxisLabelFormatter(isPercentage, isRank),
      },
      min: globalYMin,
      max: globalYMax,
      tickPositions:
        isPercentage || isRank
          ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110]
          : null, // Set custom tick positions for percentage/Rank values
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        if (this.point.hide) {
          return false
        }
        const date = Highcharts.dateFormat('%m/%d/%y', this.x)
        return `
          <div style="text-align:center;">
             <div style="font-size:0.8em; line-height:2; color:#4D4B4A;">${date}</div>
             <div>
              ${isPercentage ? `${this.y}%` : isRank ? `${this.y}<sup style="color: gray;">${renderSuffix(this.y)}</sup>` : formatNumberWithSuffix(this.y)}
             </div>
          </div>` // Display only the value in the same color as the line
      },
      // backgroundColor: null, // Remove the tooltip background
      borderWidth: 1, // Remove the tooltip border
      // shadow: true, // Remove the shadow
    },
    series: series,
    chart: {
      // styledMode: true,
      type: 'spline',
      height: chartHeight, // Ensure chart takes full height of container
      // width: '100%', // Ensure chart takes full width of container
      // spacing: [10, 10, 15, 10], // [top, right, bottom, left] padding
      // reflow: true // Enable reflow to handle resizing
    },
    plotOptions: {
      series: {
        marker: {
          radius: 4, // Set the marker radius
          symbol: 'circle', // Set the marker shape
        },
        lineWidth: 2, // Set the line width
        states: {
          hover: {
            lineWidthPlus: 0, // Disable hover effect
          },
        },
      },
    },
    credits: {
      enabled: false, // Remove the Highcharts watermark
    },
    legend: {
      enabled: false, // Enable the legend
      // layout: 'horizontal', // Horizontal layout
      // align: 'center', // Center align the legend
      // verticalAlign: 'bottom', // Place legend at the bottom
      // itemStyle: {
      //   fontWeight: 'normal', // Set legend item style
      // },
    },

    // responsive: {
    //   rules: [
    //     {
    //       condition: {
    //         maxWidth: 500
    //       },
    //       chartOptions: {
    //         legend: {
    //           layout: 'horizontal',
    //           align: 'center',
    //           verticalAlign: 'bottom'
    //         }
    //       }
    //     }
    //   ]
    // }
  }

  return (
    <div className={`h-[${chartHeight}px] w-full`}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        className={`h-[${chartHeight}px] w-full`}
      />
    </div>
  )
}
