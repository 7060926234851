import { twMerge } from 'tailwind-merge'
import { UsersTableHeaderProps } from '../UsersTable.types'
import { ChevronDown, ChevronUp } from 'components/ui/icons'
import { Colors } from 'constnants'

export const UsersTableHeader = ({
  columns,
  onSort,
  onSortOrder,
  sortingBY,
  isColumnSortable,
}: UsersTableHeaderProps) => {
  const onSortHandler = (value: string) => {
    if (onSort) {
      if (sortingBY === value) {
        onSort(prevSort => (prevSort === 'asc' ? 'desc' : 'asc'))
      } else {
        onSort('asc')
      }
    }
    if (onSortOrder) {
      onSortOrder(value)
    }
  }

  return (
    <thead>
      <tr>
        {columns.map(column => {
          const isSortable = isColumnSortable?.(column) ?? true
          return (
            <th
              key={column.value}
              className={twMerge(
                'border-b border-b-primary-black/20 bg-grey-3 px-2 py-3 text-xs font-normal text-primary-grey',
                isSortable && 'hover:cursor-pointer',
              )}
            >
              <div>
                {column.title && (
                  <div
                    className='flex items-center gap-2'
                    onClick={
                      isSortable ? () => onSortHandler(column.value) : undefined
                    }
                  >
                    {column.title}
                    {isSortable && (
                      <div>
                        <ChevronUp
                          fill={Colors.BASE_ICON}
                          width={10}
                          height={8}
                          className='-mb-1'
                        />
                        <ChevronDown
                          fill={Colors.BASE_ICON}
                          width={10}
                          height={8}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </th>
          )
        })}
        <th
          className={twMerge(
            'border-b border-b-primary-black/20 bg-grey-3 p-3',
          )}
        ></th>
      </tr>
    </thead>
  )
}
