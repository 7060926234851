import { twMerge } from 'tailwind-merge'
import { FilterButton, InclusiveFilter, ToggleSwitch } from 'components'
import { OptionFilter, OptionType } from 'types'
import { ChevronDown } from 'components/ui/icons'
import { Colors } from 'constnants'
import { useMemo } from 'react'

interface ReleaseCalendarFilmSelectProps {
  options: OptionType[]
  value: string
  checked: boolean
  isMenuVisible: boolean
  onToggleChange: (checked: boolean) => void
  selectIconClassName?: string
  colorToggle?: string
  isOther?: boolean
  id: string
  selectedOptions: { [key: string]: OptionType[] }
  onOptionChange: (id: string, option: OptionFilter[]) => void
  hasToggle?: boolean
  hasAll?: boolean
  mainToggleOff?: boolean
}

export const ReleaseCalendarFilmSelect = ({
  options,
  value,
  onToggleChange,
  selectIconClassName,
  checked,
  colorToggle,
  isOther,
  id,
  isMenuVisible,
  selectedOptions,
  onOptionChange,
  hasToggle,
  hasAll,
  mainToggleOff = false,
}: ReleaseCalendarFilmSelectProps) => {
  const handleOptionChange = (selected: { [key: string]: string[] }) => {
    const optionFilters: OptionFilter[] = Object.keys(selected)
      .flatMap(key =>
        selected[key].map(value => {
          return options.find(row => row.value === value)
        }),
      )
      .filter((options): options is OptionFilter => !!options)
    onOptionChange(id, optionFilters)
  }

  if (id === 'wideLimited' && selectedOptions[id].length === 1) {
    value = selectedOptions[id][0].label.split(' ')[0]
  }

  const selectedIds = useMemo(() => {
    const result: Record<string, string[]> = {}
    for (const [key, options] of Object.entries(selectedOptions)) {
      result[key] = options.map(opt => opt.id)
    }
    return result
  }, [selectedOptions])

  return (
    <div className='flex w-full items-center justify-between gap-3'>
      {!isOther ? (
        <FilterButton
          name={value}
          disabled={!checked}
          trianglePosition='left'
          containerMenuClassName='left-0'
          nameCLassName='font-normal text-sm'
          isMenuVisible={isMenuVisible}
          leftIcon={
            selectIconClassName ? (
              <div
                className={twMerge(
                  'mr-1 h-2 w-2 rounded-full',
                  selectIconClassName,
                )}
              />
            ) : null
          }
          rightIcon={
            <ChevronDown
              fill={Colors.BASE_ICON}
              width={18}
              height={18}
              className='ml-auto pl-1'
            />
          }
          totalSelectedFilters={
            options.length === selectedOptions[id].length ||
            id === 'wideLimited'
              ? 0
              : selectedOptions[id].length
          }
          containerClassName='w-full max-w-[210px]'
          wrapperClasName='w-full border-primary-black/20'
          wrapperMenuClassName='pr-0'
          totalNumberPosition='right'
          innerClassName='h-8 pl-3'
        >
          <div className='flex max-h-[400px] flex-row gap-10 overflow-y-auto overflow-x-hidden pr-5'>
            <InclusiveFilter
              options={options}
              selected={selectedIds[id]}
              onChange={selected => handleOptionChange({ [id]: selected })}
            />
          </div>
        </FilterButton>
      ) : (
        <div className='ml-1.5 flex items-center gap-1.5'>
          <div className='flex h-5 w-5 items-center justify-center rounded-full bg-primary-white'>
            <div
              className={twMerge('h-2 w-2 rounded-full', selectIconClassName)}
            />
          </div>
          <span className='text-sm leading-6 text-primary-black'>{value}</span>
        </div>
      )}

      {hasToggle ? (
        <ToggleSwitch
          id={id}
          checked={checked}
          onChange={onToggleChange}
          color={colorToggle}
          disabled={mainToggleOff}
        />
      ) : (
        <div className='w-[22px]' />
      )}
    </div>
  )
}
