import { ColumnsProjectTable } from 'types'

export const columnsMyProjectAssignedUsers: ColumnsProjectTable[] = [
  {
    title: 'User',
    value: 'fullName',
  },
  {
    title: 'Email',
    value: 'email',
  },
  {
    title: 'User Type',
    value: 'userType',
  },
]
