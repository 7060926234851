import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export type TextareaProps = ComponentProps<'textarea'> & {
  label?: string
  labelClassName?: string
  error?: string
  errorClassName?: string
  wrapperClassName?: string
}

export const Textarea = ({
  label,
  labelClassName,
  error,
  errorClassName,
  className,
  wrapperClassName,
  ...props
}: TextareaProps) => {
  const hasError = error ? error.length > 0 : false

  return (
    <div
      className={twMerge('flex w-full flex-col space-y-1', wrapperClassName)}
    >
      {label && (
        <p className={twMerge('text-sm text-primary-grey', labelClassName)}>
          {label}
        </p>
      )}

      <textarea
        data-error={hasError}
        className={twMerge(
          ` resize-none rounded border border-primary-black/10 bg-primary-white p-3 text-primary-black ring-0 
          transition-colors duration-300 placeholder:text-primary-grey focus:ring-0 
          focus-visible:outline-0 disabled:cursor-not-allowed disabled:bg-grey-2
          disabled:text-primary-grey data-[error=true]:border-primary-red 
          data-[error=false]:focus:border-primary-black enabled:data-[error=false]:hover:border-primary-dark`,
          className,
        )}
        {...props}
      />

      {hasError && (
        <p
          data-error={hasError}
          className={twMerge(
            'text-sm text-red-9 data-[error=true]:text-red-9',
            errorClassName,
          )}
        >
          {error}
        </p>
      )}
    </div>
  )
}
