import { useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import {
  FilterButton,
  FilterColumnGroup,
  Button,
  Select,
  IconTabs,
  SaveAsFiltersDropdown,
  SectionTitle,
  InclusiveFilter,
  IconTab,
  Close,
  List,
  Tables,
} from 'components'
import {
  MetricsType,
  QueryParams,
  TalentAge,
  TalentGender,
  TalentRace,
  useTalentHeaderData,
} from 'hooks'
import { SocialSearchFilters, SortOrderKey } from 'types'
import { isEmptyArrsInObj } from 'utils'
import { dataAudience } from 'constnants/audienceOptions'
import {
  Colors,
  dataSearch,
  dataTalent,
  percentagesOptions,
  SizeTalentRows,
  tableDirectionTabs,
  topOptions,
} from 'constnants'

interface FilterControlsProps {
  selectedTableTab: IconTab<string> | undefined
  setIsSavedSearchChanged: (value: boolean) => void
  onChangeContentTypeView: (typeView: string) => void
  setMetricsType: (type: MetricsType) => void
  setTopTwoBox: (value: string) => void
  setSelectTab: (value: IconTab<string>) => void
  socialSearchFilters: SocialSearchFilters
  setSocialSearchFilters?: (
    filterId: keyof SocialSearchFilters,
    filters: SocialSearchFilters,
  ) => void
  setIsOpenSaveSearchModal: React.Dispatch<React.SetStateAction<boolean>>
  onChangeTalentFilter: (query: QueryParams) => void
  sortBy?: string
  sortOrder?: SortOrderKey
  metricsType?: MetricsType
  handleSelectFilters: (filters: QueryParams) => void
}

export const TalentFilters = ({
  selectedTableTab,
  setIsSavedSearchChanged,
  onChangeContentTypeView,
  setMetricsType,
  setTopTwoBox,
  setSelectTab,
  socialSearchFilters,
  setSocialSearchFilters,
  setIsOpenSaveSearchModal,
  onChangeTalentFilter,
  sortBy,
  sortOrder,
  metricsType,
  handleSelectFilters,
}: FilterControlsProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const [isSocialMenuVisible, setIsSocialMenuVisible] = useState<boolean>(false)
  const [isOpenSeveAsSerch, setIsOpenSeveAsSerch] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const selectedProjectID = urlParams.get('selectedProject')
  const selectedRoleID = urlParams.get('selectedRole')
  const {
    selects,
    selectedFiltersAudience,
    handleSelectOptions,
    handleFilterChangeTalent,
    totalTalentFilters,
    handleResetAllFilters,
    selectedFiltersTalent,
    selectedFilterPresetSearch,
    hasChanges,
    handleResetFiltersTalent,
    handleResetFiltersAudience,
    handleFilterChangeAudience,
  } = useTalentHeaderData(dataTalent.concat(dataAudience))

  const updateUrlFilters = (presetSearch?: string) => {
    const audienceFilter = Object.values(selectedFiltersAudience).flat()[0]
    const isAddpresetSearch =
      presetSearch &&
      (audienceFilter || !isEmptyArrsInObj(prepareTalentFilters()))
    const searchParams = new URLSearchParams(location.search)
    const talentNameParam = searchParams.get('talentName') ?? ''

    onChangeTalentFilter({
      ...(isAddpresetSearch ? { presetSearch } : {}),
      ...prepareTalentFilters(),
      audienceFilter,
      sortBy,
      sortOrder,
      metricsType,
      selectedProject: selectedProjectID ?? '',
      selectedRole: selectedRoleID ?? '',
      topTwoBox: selects['top'],
      talentName: talentNameParam,
    })
  }

  useEffect(() => {
    const presetSearch = selectedFilterPresetSearch
      ? selectedFilterPresetSearch
      : ''

    updateUrlFilters(presetSearch)
  }, [selectedFiltersAudience, selectedFiltersTalent, selects])

  const onClearFilters = () => {
    onChangeTalentFilter({
      talentGender: undefined,
      talentRace: undefined,
      talentAge: undefined,
      audienceFilter: undefined,
      talentName: undefined,
      presetSearch: undefined,
    })
    onChangeContentTypeView('percentage')
    handleResetAllFilters()
  }

  const prepareTalentFilters = () => {
    if (Object.values(selectedFiltersTalent)?.length > 0) {
      const singleSelectedGender = selectedFiltersTalent[
        'gender'
      ] as TalentGender[]
      const singleSelectedRace = selectedFiltersTalent['race'] as TalentRace[]
      const singleSelectedAge = selectedFiltersTalent['age'] as TalentAge[]

      handleSelectFilters({
        talentGender: singleSelectedGender,
        talentRace: singleSelectedRace,
        talentAge: singleSelectedAge,
      })

      return {
        talentGender: singleSelectedGender,
        talentRace: singleSelectedRace,
        talentAge: singleSelectedAge,
      }
    }

    return {}
  }

  const audienceTitle = useMemo(() => {
    for (const audienceGroup of dataAudience) {
      const selected = selectedFiltersAudience[audienceGroup.id] ?? []
      const targetAudience = audienceGroup.options.find(it =>
        selected.includes(it.value),
      )
      if (targetAudience) {
        if (audienceGroup.id !== 'general') {
          return `${audienceGroup.name} ${targetAudience.label}`
        }

        if (targetAudience.value !== 'ALL') {
          return targetAudience.label
        }
      }
    }
    return 'Audience'
  }, [selectedFiltersAudience])

  const handlePresetSearchName = () => {
    if (!selectedFilterPresetSearch) return null
    const [presetSearchName] = selectedFilterPresetSearch.split('_')
    return (
      <div className='flex h-[24px] items-center rounded-full border border-red-12 bg-red-11 text-sm not-italic text-red-9'>
        <div className='max-w-52 overflow-hidden truncate whitespace-nowrap pl-[10px] pr-[2px]'>
          {presetSearchName}
        </div>
        <Close
          fill={Colors.NAV_ICON_THREE}
          width={16}
          height={16}
          className='h-fit w-fit p-1 hover:cursor-pointer'
          onClick={onClearFilters}
        />
      </div>
    )
  }

  return (
    <div className='flex flex-row flex-wrap-reverse items-center justify-end gap-3'>
      <div className='flex items-center gap-3'>
        {handlePresetSearchName()}

        {hasChanges && (
          <div className=' flex flex-row items-center gap-3'>
            <Button
              kind='filled'
              size='small'
              onClick={() => {
                if (selectedFilterPresetSearch) {
                  setIsOpenSeveAsSerch(true)
                } else {
                  setIsOpenSaveSearchModal(true)
                }
              }}
            >
              <span className='relative flex px-1'>
                Save as
                <SaveAsFiltersDropdown
                  selectedFilterPresetSearch={selectedFilterPresetSearch}
                  isOpen={isOpenSeveAsSerch}
                  setIsOpen={setIsOpenSeveAsSerch}
                  saveAsNewSearch={setIsOpenSaveSearchModal}
                />
              </span>
            </Button>

            <Button kind='text' size='small' onClick={onClearFilters}>
              <span className='px-1'>Clear</span>
            </Button>
          </div>
        )}

        <div className='flex flex-row items-center gap-3'>
          <FilterButton
            name='Talent'
            totalSelectedFilters={totalTalentFilters}
            handleReset={handleResetFiltersTalent}
            trianglePosition={hasChanges ? 'right' : 'left'}
            containerMenuClassName={hasChanges ? 'right-0' : 'left-0'}
          >
            <div className='flex flex-row gap-10'>
              {dataTalent.map(({ id, name, options }) => {
                return (
                  <div key={id} className='flex w-max flex-col gap-2'>
                    <SectionTitle
                      title={name}
                      className={twMerge(
                        'text-left text-xs text-primary-black',
                      )}
                    />
                    <InclusiveFilter
                      options={options}
                      selected={selectedFiltersTalent[id]}
                      onChange={selected => {
                        handleFilterChangeTalent({
                          ...selectedFiltersTalent,
                          [id]: selected,
                        })
                        setIsSavedSearchChanged(true)
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </FilterButton>
          <FilterButton
            name={audienceTitle}
            totalSelectedFilters={
              selectedFiltersAudience['general']?.includes('ALL') ? 0 : 1
            }
            hideTotalNumber
            handleReset={handleResetFiltersAudience}
            trianglePosition='right'
            containerMenuClassName='right-0'
            isMenuVisible={isMenuVisible}
            setIsMenuVisible={setIsMenuVisible}
          >
            <div className='flex flex-row gap-10'>
              {dataAudience.map(({ id, name, options }) => {
                return (
                  <FilterColumnGroup
                    key={id}
                    category={id}
                    title={name}
                    optionsFilter={options}
                    isSelect={true}
                    selectedFilters={selectedFiltersAudience}
                    onChange={selected => {
                      setIsMenuVisible(prev => !prev)
                      handleFilterChangeAudience(selected)
                      setIsSavedSearchChanged(true)
                    }}
                    isSingleSelection={true}
                  />
                )
              })}
            </div>
          </FilterButton>
        </div>
        {!hasChanges && (
          <div className='h-6 border-r border-primary-black/10' />
        )}
      </div>

      <div className='flex flex-row items-center gap-3'>
        {selectedTableTab?.value === SizeTalentRows.SMALL_ROWS && (
          <Select
            value={selects['percentages']}
            handleClick={option => {
              onChangeContentTypeView(option.value)
              setMetricsType((option.key as MetricsType) ?? 'PERCENTAGE')
              handleSelectOptions(option, 'percentages')
            }}
            options={percentagesOptions}
            size='small'
            // leftIcon={<Percent fill={Colors.BASE_ICON} />}
          />
        )}
        <Select
          value={selects['top']}
          handleClick={option => {
            handleSelectOptions(option, 'top')
            setTopTwoBox(option.value)
          }}
          options={topOptions}
          placeholder='Top Box'
          size='small'
          leftIcon={<List fill={Colors.BASE_ICON} width={16} height={16} />}
        />
        <FilterButton
          name='Social & Search'
          totalSelectedFilters={0}
          trianglePosition='right'
          containerMenuClassName='right-0'
          isMenuVisible={isSocialMenuVisible}
          setIsMenuVisible={setIsSocialMenuVisible}
          leftIcon={<Tables fill={Colors.BASE_ICON} />}
        >
          <div className='flex flex-row gap-10'>
            {dataSearch.map(({ id, name, options }) => {
              return (
                <FilterColumnGroup
                  key={id}
                  category={id}
                  title={name}
                  optionsFilter={options}
                  isSelect={true}
                  selectedFilters={socialSearchFilters}
                  onChange={selected => {
                    setIsSocialMenuVisible(prev => !prev)
                    if (setSocialSearchFilters) {
                      setSocialSearchFilters(id, {
                        ...socialSearchFilters,
                        ...selected,
                      })
                    }
                  }}
                  isSingleSelection={true}
                />
              )
            })}
          </div>
        </FilterButton>
        <IconTabs
          tabs={tableDirectionTabs}
          kind='outline'
          onChange={tab => setSelectTab(tab)}
          selectedTab={selectedTableTab}
        />
      </div>
    </div>
  )
}

{
  /*<div className='h-6 border-r border-primary-black/10' />*/
}
{
  /*<div className='flex flex-row items-center gap-1'>*/
}
{
  /*  <Share*/
}
{
  /*    fill={Colors.NAV_ICON_THREE}*/
}
{
  /*    width={16}*/
}
{
  /*    height={16}*/
}
{
  /*    className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'*/
}
{
  /*  />*/
}
{
  /*  <Export*/
}
{
  /*    fill={Colors.NAV_ICON_THREE}*/
}
{
  /*    width={16}*/
}
{
  /*    height={16}*/
}
{
  /*    className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'*/
}
{
  /*  />*/
}
{
  /*</div>*/
}
