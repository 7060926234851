import { ReactNode, useEffect, useState } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'
import { Colors } from 'constnants'
import { CheckCircle, ChevronDown, ChevronUp } from 'components/ui/icons'
import { StepperStatus } from '../StepperStatus'

interface AccordionProps {
  items: AccordionItem[]
  titleClassName?: string
  wrapperClassName?: string
  bodyClassName?: string
  wrapperBodyClassName?: string
  content?: ReactNode
  statusStep?: boolean
  activeIndex?: number
  setActiveIndex?: (index: number) => void
  previousStep?: (index: number) => void
}

export type AccordionItem = {
  title?: string
  body: ReactNode
  itemClassName?: string
  statusTitle?: string
  isActive?: boolean
}

type AccordionItemWithState = AccordionItem & { open: boolean }

function mapAccordionItemToState(
  item: AccordionItem,
  isOpen = false,
): AccordionItemWithState {
  return { ...item, open: isOpen }
}

export const Accordion = ({
  items,
  titleClassName,
  wrapperClassName,
  bodyClassName,
  wrapperBodyClassName,
  statusStep,
  activeIndex,
  setActiveIndex,
  previousStep,
}: AccordionProps) => {
  const [accordionItems, setAccordionItems] = useState<
    AccordionItemWithState[]
  >(items.map(item => mapAccordionItemToState(item, false)))

  useEffect(() => {
    setAccordionItems(
      items.map((item, index) =>
        mapAccordionItemToState(item, index === activeIndex),
      ),
    )
  }, [items, activeIndex])

  const handleStepChevronClick = (index: number) => {
    if (setActiveIndex) {
      previousStep && previousStep(index)
      setActiveIndex(index)
    }
  }

  const handleChevronClick = (index: number) => {
    if (setActiveIndex) {
      setActiveIndex(index)
    }
  }

  return (
    <ul className={twMerge('w-full', wrapperClassName)}>
      {accordionItems?.map((item, i) => {
        const statusColor = twJoin(
          'bg-grey-4',
          (item.open || !item.open) && item.isActive && 'bg-green-6',
          item.open && !item.isActive && 'bg-primary-red',
        )

        return (
          <li
            key={`exp_${i}`}
            className={twMerge(
              item.itemClassName,
              item.open && 'bg-primary-white',
            )}
          >
            <>
              {statusStep && (
                <div className='relative grid w-full grid-cols-[120px,8fr] gap-16'>
                  <StepperStatus
                    statusTitle={item.statusTitle ?? ''}
                    wrapperClassName={twJoin(
                      item.open && 'text-primary-white',
                      !item.open && item.isActive && 'text-primary-white',
                      statusColor,
                    )}
                    icon={
                      item.isActive ? (
                        <CheckCircle fill={Colors.ICON_WHITE} />
                      ) : null
                    }
                  />

                  {item.open && (
                    <div
                      className={twMerge(
                        'w-full transition-all duration-500',
                        item.open ? 'opacity-100' : 'opacity-0',
                        bodyClassName,
                      )}
                    >
                      {item.body}
                    </div>
                  )}

                  <div
                    className='absolute right-0 hover:cursor-pointer'
                    onClick={() => handleStepChevronClick(i)}
                  >
                    {!item.open && (
                      <ChevronDown
                        fill={Colors.BASE_ICON}
                        width={20}
                        height={20}
                        className={twMerge('rounded hover:bg-primary-black/5')}
                      />
                    )}
                  </div>
                </div>
              )}
            </>

            {!statusStep && (
              <div>
                <div className='flex items-center justify-between'>
                  <p className={twMerge('text-primary-dark', titleClassName)}>
                    {item.title}
                  </p>
                  <div onClick={() => handleChevronClick(i)}>
                    {item.open ? (
                      <ChevronUp
                        fill={Colors.BASE_ICON}
                        width={20}
                        height={20}
                      />
                    ) : (
                      <ChevronDown
                        fill={Colors.BASE_ICON}
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={twMerge(
                    'max-h-0 transform overflow-hidden transition-all duration-300',
                    item.open && 'max-h-fit',
                    wrapperBodyClassName,
                  )}
                >
                  <div
                    className={twMerge(
                      'transition-all duration-1000',
                      item.open ? 'opacity-100' : 'opacity-0',
                      bodyClassName,
                    )}
                  >
                    {item.body}
                  </div>
                </div>
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )
}
