export const columns = [
  {
    title: 'Test name',
    value: 'testName',
  },
  {
    title: 'Status',
    value: 'status',
  },
  {
    title: 'Submit date',
    value: 'submitDate',
  },
  {
    title: 'Complete date',
    value: 'completeDate',
  },
  {
    title: '',
    value: 'pdf',
  },
]

export const columnsTestingAdmin = [
  {
    title: 'Test name',
    value: 'testName',
  },
  {
    title: 'Project',
    value: 'project',
  },
  {
    title: 'Company',
    value: 'company',
  },
  {
    title: 'Status',
    value: 'status',
  },
  {
    title: 'Submit date',
    value: 'submitDate',
  },
  {
    title: 'Complete date',
    value: 'completeDate',
  },
]
