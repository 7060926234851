import { Input, Textarea } from '../../components'
import { ChangeEvent } from 'react'

interface DoubleAssetInputProps {
  label: string
  introText?: string
  listText?: string[]
  fields: {
    label: string
    inputValue: string
    inputPlaceholder: string
    textAreaValue: string
    textAreaPlaceholder: string
    onChangeHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      key: string,
      index: number,
      objectKey?: string,
    ) => void
  }[]
}
export const DoubleAssetInput = ({
  label,
  introText,
  listText,
  fields,
}: DoubleAssetInputProps) => {
  return (
    <div className='my-8 rounded bg-grey-3 px-6 py-4'>
      <div className={'mb-4 text-sm text-grey-7'}>
        <p className={'font-semibold uppercase '}>{label}</p>
        {introText && <p>{introText}</p>}
        {listText && (
          <ul className={'ml-5 list-disc'}>
            {listText.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        )}
      </div>
      <div>
        {fields.map((field, index) => (
          <div key={index}>
            <p className={'mb-1 font-semibold text-primary-black'}>
              {field.label}
            </p>
            <Input
              placeholder={field.inputPlaceholder}
              value={field.inputValue}
              onChange={e => {
                field.onChangeHandler(
                  e,
                  'trailerAbTestValues',
                  index,
                  'file' + (index + 1),
                )
              }}
              wrapperClassName='w-full h-8'
              inputClassName='pl-3 text-sm'
            />
            <Textarea
              onChange={e =>
                field.onChangeHandler(
                  e,
                  'trailerAbTestValues',
                  index,
                  'fileDescription' + (index + 1),
                )
              }
              placeholder={field.textAreaPlaceholder}
              className={'mt-1 text-sm'}
            />
            {index !== fields.length - 1 && <hr className='my-4' />}
          </div>
        ))}
      </div>
    </div>
  )
}
