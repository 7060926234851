import { useCallback } from 'react'
import { EntityLookupByField } from 'components'
import { OptionType } from 'types'

import { useLazyGetFilmNameSuggestionsQuery } from 'store/api'

interface CompFinderFilmLookupProps {
  className?: string
  projectId?: string
  onSelectFilm: (filmId: number) => void
  onDeleteFilm: () => void
}

export const CompFinderFilmLookup = ({
  className,
  projectId,
  onDeleteFilm,
  onSelectFilm,
}: CompFinderFilmLookupProps) => {
  const [trigger, filmSuggestions] = useLazyGetFilmNameSuggestionsQuery({
    selectFromResult: ({ data }): OptionType<number>[] =>
      data?.map(film => ({
        id: film.film_id.toString(),
        label: film.film_name,
        value: film.film_id,
      })) ?? [],
  })

  const load = useCallback(
    async (searchTerm: string): Promise<void> =>
      void (await trigger({ filmName: searchTerm, projectId })),
    [projectId],
  )

  return (
    <EntityLookupByField
      notFoundTitle='Comp not found'
      className={className}
      options={filmSuggestions}
      onSelect={({ value }) => onSelectFilm(value)}
      onClear={onDeleteFilm}
      load={load}
    />
  )
}
