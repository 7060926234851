import { IconButton } from 'components'
import { Pencil } from 'components/ui/icons'
import { Colors } from 'constnants'
import { twMerge } from 'tailwind-merge'

interface MyProjectLoglineItemProps {
  descriptions: string
  isActive?: boolean
  onOpenEdit: () => void
}

export const MyProjectLoglineItem = ({
  descriptions,
  isActive,
  onOpenEdit,
}: MyProjectLoglineItemProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-row items-center rounded bg-primary-white p-2',
        !isActive && 'border border-primary-black/10 bg-grey-3',
      )}
    >
      <p className='max-w-[95%] grow text-start text-sm leading-6 text-primary-grey'>
        {descriptions}
      </p>
      <IconButton onClick={onOpenEdit}>
        <Pencil fill={Colors.BASE_ICON} width={16} height={16} />
      </IconButton>
    </div>
  )
}
