import { Attributes, NameValuePair } from '../types'

interface DisplayValue {
  count: number
  rank?: number
  percentage?: string
}

function hasCountProperty(value: any): value is { count: number } {
  return value && typeof value === 'object' && 'count' in value
}

function formatValue(value: string | number): string | number {
  return value ?? '-'
}

export const getDisplayAttributeValue = <T extends object>(
  value: Attributes | NameValuePair<T>,
  isAttributes?: boolean,
): DisplayValue => {
  let displayValue: DisplayValue = { count: 0 }

  if (isAttributes) {
    const attrValues = (value as Attributes).values
    displayValue = {
      count: attrValues['count'] as number,
      rank: attrValues['rank'],
      percentage: attrValues['percentage'],
    }
  } else {
    const val = (value as NameValuePair<T>).value
    if (hasCountProperty(val)) {
      displayValue = {
        count: formatValue(val.count),
        rank: (val as { rank?: number }).rank,
        percentage: (val as { percentage?: string }).percentage,
      }
    } else {
      displayValue.count = formatValue(val)
    }
  }

  return displayValue
}
