import { Anchor, Image } from 'components'
import { Plus } from 'components/ui/icons'
import { Colors } from 'constnants'

interface TalentBottomCardProps {
  title: string
  detailsName: string
  isAttachedTalent?: boolean
  amountTalent?: number
  talentName?: string
  profileImage?: string
  onOpenEditModal: () => void
}

export const TalentBottomCard = ({
  title,
  detailsName,
  isAttachedTalent,
  amountTalent,
  talentName = '',
  profileImage = '',
  onOpenEditModal,
}: TalentBottomCardProps) => {
  return (
    <div className='h-full rounded bg-primary-white p-2'>
      <span className='text-xs uppercase text-primary-grey'>{title}</span>
      <div className='flex place-items-end justify-between'>
        {isAttachedTalent ? (
          <div className='flex gap-1'>
            <div
              className='flex h-[60px] min-w-10 items-center justify-center bg-grey-4 transition-opacity duration-300 hover:cursor-pointer hover:opacity-70'
              onClick={() => {
                onOpenEditModal()
              }}
            >
              {profileImage ? (
                <Image alt={talentName} src={profileImage} />
              ) : (
                <div className='flex h-6 w-6 items-center justify-center rounded-full border border-primary-black/20'>
                  <Plus fill={Colors.BASE_ICON} width={16} height={16} />
                </div>
              )}
            </div>
            <span className='text-xs font-medium text-primary-grey'>
              {talentName}
            </span>
          </div>
        ) : (
          <div className='block h-[50px] pt-2 text-2xl font-semibold text-grey-7'>
            {amountTalent}
          </div>
        )}
        <Anchor
          primary={false}
          className='border-b-0 text-xs text-red-9 hover:opacity-80'
          text={detailsName}
        />
      </div>
    </div>
  )
}
