import { twMerge } from 'tailwind-merge'
import { ChartHighlight } from 'components/ui/icons'
import { Colors } from 'constnants'

interface StatusTabProps {
  isActive?: boolean
  status: string
  amount: number
}

export const StatusTab = ({ isActive, status, amount }: StatusTabProps) => {
  return (
    <div
      className={twMerge(
        'rounded-full bg-grey-4 px-1.5 py-1 hover:cursor-pointer',
        isActive && 'border border-primary-black/40 bg-primary-white',
      )}
    >
      <div className='flex items-center space-x-1'>
        <ChartHighlight fill={Colors.ICON_BRAND} width={16} height={16} />
        <span className='text-xs font-semibold uppercase text-primary-grey'>
          {status}
        </span>
        <span className='flex h-6 w-6 items-center justify-center rounded-full bg-grey-4 text-xs font-medium text-primary-black'>
          {amount}
        </span>
      </div>
    </div>
  )
}
