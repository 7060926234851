import { BaseModal, Button } from 'components'
import { Trash } from 'components/ui/icons'
import { Colors } from 'constnants'

interface MyRoleModalDeleteProps {
  isOpen: boolean
  onClose: () => void
  roleName: string | undefined
  onDelete: () => void
}

export const MyRoleModalDelete = ({
  isOpen,
  onClose,
  roleName,
  onDelete,
}: MyRoleModalDeleteProps) => {
  const handleDelete = () => {
    onDelete()
    onClose()
  }
  return (
    <BaseModal isOpen={isOpen} handleClose={onClose}>
      <div className='flex flex-col items-center justify-center'>
        <Trash fill={Colors.ICON_NEGATIVE_RED} width={40} height={40} />
        <div className='flex flex-col pt-5 text-center'>
          <span className='text-lg font-medium leading-6 text-primary-black'>
            Are you sure you want to delete
          </span>
          <span className='text-lg font-medium leading-6 text-primary-black'>
            {roleName}
          </span>
          <span className='pt-2 text-center text-sm text-primary-black'>
            This will permanently delete the role, assigned talent, and all
            lists created under this role in the Talent pod. This action is
            non-reversible.
          </span>
        </div>
      </div>
      <div className='flex w-full gap-2 pt-4'>
        <Button
          kind='text'
          size='medium'
          className='w-1/2 py-2.5'
          onClick={onClose}
        >
          <span className='px-1 text-xs font-semibold'>Cancel</span>
        </Button>

        <Button
          kind='filled'
          size='medium'
          className='w-1/2 bg-red-10 py-2.5 hover:bg-red-10/80 active:bg-red-10/60'
          onClick={handleDelete}
        >
          <span className='px-1 text-xs font-semibold'>Delete</span>
        </Button>
      </div>
    </BaseModal>
  )
}
