import { useCallback, useState } from 'react'
import { PaginationChangeParams, PaginationToolbar } from 'components'
import { managementRowsPerPageOptions } from 'constnants'
import { MgmtLayout } from 'modules/management/shared'
import { useGetTVManagementDataQuery } from 'store/api/mock-api'
import { TVManagementToolbar } from '../../components'
import {
  TVManagementTable,
  TVManagementTableTypes,
} from '../../components/TVManagementTable'
import { tvManagementSelectors } from '../../slices/tvManagementSlice'
import { useSelector } from 'react-redux'
import { useTVManagementActions } from '../../hooks'

export const TVManagementPage = () => {
  const actions = useTVManagementActions()
  const { pageConfig, sorting } = useSelector(tvManagementSelectors.root)
  const [searchTerm, setSearchTerm] = useState('')

  const handlePagination = useCallback(
    (params: PaginationChangeParams) =>
      actions.setPageConfig({
        pageNumber: params.page,
        pageSize: params.pageSize,
      }),
    [actions],
  )
  const handleSortChange: TVManagementTableTypes['OnSortChange'] = useCallback(
    ({ sortConfig }) => actions.setSorting(sortConfig),
    [actions],
  )

  const { tvManagement, totalPages, totalRows, isFetching, isLoading } =
    useGetTVManagementDataQuery(
      {
        pageSize: pageConfig.pageSize,
        pageNumber: pageConfig.pageNumber,
        sortBy: sorting.by,
        sortOrder: sorting.order,
        searchTerm: searchTerm.length ? searchTerm : undefined,
      },
      {
        selectFromResult: ({ data, ...rest }) => {
          return {
            ...rest,
            tvManagement: data?.data ?? [],
            totalPages: data?.totalPages ?? 0,
            totalRows: data?.totalRecords ?? 0,
          }
        },
      },
    )

  const paginationBar = (
    <PaginationToolbar
      totalPages={totalPages}
      totalRows={totalRows}
      currentPage={pageConfig.pageNumber}
      currentPageSize={pageConfig.pageSize}
      pageSizeOptions={managementRowsPerPageOptions}
      onChange={handlePagination}
    />
  )

  return (
    <MgmtLayout>
      <div className='flex h-full flex-col'>
        <TVManagementToolbar onSearchChange={setSearchTerm} />
        <TVManagementTable
          rows={tvManagement}
          footer={paginationBar}
          loading={isLoading}
          fetching={isFetching}
          sortConfig={sorting}
          onSortChange={handleSortChange}
        />
      </div>
    </MgmtLayout>
  )
}
