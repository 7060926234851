import {
  projectActions,
  myProjectActions,
  releaseCalendarActions,
  talentActions,
} from '../slices'

export const rootActions = {
  ...projectActions,
  ...myProjectActions,
  ...releaseCalendarActions,
  ...talentActions,
}
