import { api } from './api'
import { prepareUrlParams } from '../../utils'
import {
  CompFinderDistributor,
  CompFinderFilmSuggestion,
  CompFinderGetData,
  CompFinderQuery,
  CompFinderQueryFilmByProject,
  CompFinderQueryOneFilm,
} from '../../types'

export const compFinderAPI = api.injectEndpoints({
  endpoints: builder => ({
    getCompFinderData: builder.query<
      CompFinderQuery | CompFinderQueryOneFilm,
      CompFinderGetData
    >({
      query: CompFinderGetData => {
        const params = prepareUrlParams(CompFinderGetData, true)
        return {
          url: `/comp-finder${params}`,
          method: 'GET',
        }
      },
      providesTags: ['CompFinder'],
    }),
    getDistributors: builder.query<CompFinderDistributor[], void>({
      query: () => ({
        url: '/comp-finder/distributors',
        method: 'GET',
      }),
    }),
    getFilmNameSuggestions: builder.query<
      CompFinderFilmSuggestion[],
      { filmName: string; projectId?: string }
    >({
      query: params => ({
        url: '/comp-finder/film-by-name',
        method: 'GET',
        params,
      }),
    }),
    addFilmToProject: builder.mutation<
      CompFinderQueryFilmByProject[],
      { filmIds: number[]; projectId: string }
    >({
      query: ({ filmIds, projectId }) => ({
        url: `/comp-finder/projects/${projectId}/films`,
        method: 'POST',
        body: { filmIds: filmIds },
      }),
      invalidatesTags: [{ type: 'CompFinder' }],
    }),
    deleteFilmFromProject: builder.mutation<
      CompFinderQueryFilmByProject[],
      { filmIds: number[]; projectId: string }
    >({
      query: ({ filmIds, projectId }) => {
        const params = prepareUrlParams(filmIds, true)
        return {
          url: `/comp-finder/projects/${projectId}/films${params}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ type: 'CompFinder' }],
    }),
    getReleaseYears: builder.query<number[], void>({
      query: () => ({
        url: '/comp-finder/release-year',
        method: 'GET',
      }),
    }),
    getAutoGeneratedCompList: builder.query<void, string>({
      query: projectId => ({
        url: `/comp-finder/projects/${projectId}/generate`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetCompFinderDataQuery,
  useGetDistributorsQuery,
  useGetFilmNameSuggestionsQuery,
  useLazyGetFilmNameSuggestionsQuery,
  useAddFilmToProjectMutation,
  useDeleteFilmFromProjectMutation,
  useGetReleaseYearsQuery,
  useLazyGetAutoGeneratedCompListQuery,
} = compFinderAPI
