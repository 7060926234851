import { apiURL } from '../store/api/api'

export const submitTesting = async (
  testingType: string,
  fields: object,
  setIsSuccess: (isSuccess: boolean) => void,
  returnBack: () => void,
) => {
  const companyId = localStorage.getItem('companyId')
  fetch(apiURL + `/test/${testingType}/${companyId}`, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        console.error('Error:', data.message)
      } else {
        setIsSuccess(true)
        setTimeout(() => {
          setIsSuccess(false)
          returnBack()
          window.location.reload()
        }, 1000)
      }
    })
    .catch(error => console.error('Error:', error))
}
