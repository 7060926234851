import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { WeekendProjectionType } from 'types/WeekendProjectionType'
import { useFilmMetricsControllerGetOpeningWeekendProjectionsQuery } from 'store/api'
import { FilmProjections } from 'types'
import { SubTitle, Title } from './Title'

import { XAxisLabels } from './XAxisLabels'
import { NoData } from './NoData'
import { FilmWeekendProjection } from './FilmWeekendProjection'

interface FilmWeekendProjectTableProps {
  filmId: string
  className?: string
}
type TransformedData = {
  [rowValue: string]: {
    '0': string | number
    '7': string | number
    '14': string | number
    '21': string | number
    '28': string | number
    '35': string | number
    '42': string | number
  }
}
const transformData = (apiData: FilmProjections | undefined) => {
  if (!apiData) return
  const transformedData: TransformedData = {}

  apiData.forEach(item => {
    transformedData[item.row_value] = {
      '0': item.c0 ? 'opacityGrey-3' : 0,
      '7': item.c7 ? 'opacityGrey-3' : 0,
      '14': item.c14 ? 'opacityGrey-3' : 0,
      '21': item.c21 ? 'opacityGrey-3' : 0,
      '28': item.c28 ? 'opacityGrey-3' : 0,
      '35': item.c35 ? 'opacityGrey-3' : 0,
      '42': item.c42 ? 'opacityGrey-3' : 0,
    }
  })

  return transformedData
}
export const FilmWeekendProjectTable = ({
  filmId,
  className,
}: FilmWeekendProjectTableProps) => {
  const { data: openingProjection } =
    useFilmMetricsControllerGetOpeningWeekendProjectionsQuery({
      filmId: Number(filmId),
    })

  const [fullData, setFullData] = useState<WeekendProjectionType>({
    filmId: '',
    data: {},
    currentProjection: '',
  })
  const [filmWeekendProjectionData, setFilmWeekendProjectionData] = useState<
    Record<string, any>
  >({})
  const [colLabels, setColLabels] = useState<number[]>([])

  useEffect(() => {
    if (!filmId) return

    const transformedData = transformData(openingProjection?.data) || {}
    const filmProjection = {
      label: 'Current Projection: ',
      filmId,
      data: transformedData,
      currentProjection: openingProjection?.currentProjection,
    }
    setFullData(filmProjection)
    const hasData =
      filmProjection.data && Object.keys(filmProjection.data).length > 0
    setFilmWeekendProjectionData(hasData ? filmProjection.data : {})
  }, [filmId, openingProjection])

  useEffect(() => {
    if (!filmWeekendProjectionData) return

    const uniqueColLabels = new Set<number>()

    for (const nestedObj of Object.values(filmWeekendProjectionData)) {
      for (const col of Object.keys(nestedObj)) {
        const colValue = Number(col)
        if (!isNaN(colValue)) {
          uniqueColLabels.add(colValue)
        }
      }
    }

    const sortedXAxisLabels = Array.from(uniqueColLabels).sort((a, b) => b - a)
    setColLabels(sortedXAxisLabels)
  }, [filmWeekendProjectionData])

  return (
    <div
      className={twMerge(
        'flex min-h-80 flex-col rounded bg-grey-3 p-1',
        className,
      )}
    >
      <Title />
      <div
        className={twMerge(
          'rounded-lg bg-primary-white p-1',
          Object.keys(filmWeekendProjectionData).length === 0 &&
            'flex h-[270px] place-content-center text-center',
        )}
      >
        {Object.keys(filmWeekendProjectionData).length > 0 ? (
          <>
            {fullData.currentProjection && (
              <SubTitle
                label={`${fullData.label!} ${fullData.currentProjection}`}
              />
            )}
            <FilmWeekendProjection
              data={filmWeekendProjectionData}
              colLabels={colLabels}
            />
            <XAxisLabels labels={colLabels} />
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}
