import { ToastContainerProps } from 'react-toastify'

export const commonToastStyles: ToastContainerProps = {
  className:
    'min-h-0 font-normal w-max text-[14px] leading-[24px] bg-white text-gray-800 border-l-4 px-3 py-2 rounded-lg' +
    ' shadow-lg',
  bodyClassName: 'flex  px-0 py-0 items-start',
  progressClassName: 'bg-red-600',
  toastClassName: 'custom-toast',
}
