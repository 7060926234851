import { useCallback } from 'react'
import { EntityLookupByFieldButton } from 'components'
import { OptionType } from 'types'

import { useLazyFilmMetricsControllerGetSearchFilmQuery } from 'store/api'

interface FilmTrackingLookupButtonProps {
  label: string
  disabled?: boolean
  className?: string
  filter?: (option: OptionType) => void
  onSelectFilm: (filmId: string) => void
}

export const FilmTrackingLookupButton = ({
  label,
  disabled,
  className,
  filter,
  onSelectFilm,
}: FilmTrackingLookupButtonProps) => {
  const [trigger, suggestions] = useLazyFilmMetricsControllerGetSearchFilmQuery(
    {
      selectFromResult: ({ data }): OptionType[] =>
        data?.map(film => ({
          id: film.film_id.toString(),
          label: film.film_name,
          value: film.film_id,
        })) ?? [],
    },
  )

  const load = useCallback(
    async (searchTerm: string): Promise<void> =>
      void (await trigger({ filmName: searchTerm })),
    [],
  )

  return (
    <EntityLookupByFieldButton
      label={label}
      disabled={disabled}
      className={className}
      options={suggestions}
      onSelect={({ value }) => onSelectFilm(value)}
      filter={filter}
      load={load}
    />
  )
}
