import { getOrdinalSuffix } from 'utils'

interface RankWithSuffixProps {
  rank: string | number
}

export const RankWithSuffix = ({ rank }: RankWithSuffixProps) => {
  const numericRank = typeof rank === 'string' ? parseInt(rank, 10) : rank
  const suffix = getOrdinalSuffix(numericRank)

  return (
    <>
      {numericRank}
      <span className='relative -top-1'> {suffix} </span>
    </>
  )
}
