import { useState, useEffect, useRef } from 'react'
import { BaseModal, Button, InformationBox, Input } from 'components'
import { Search, Close } from 'components/ui/icons'
import { Colors } from 'constnants'
import { useGetTalentMetricsByNameQuery } from 'store/api'
import { v4 as uuidv4 } from 'uuid'

interface SelectedTalents {
  id: number
  name: string
}

interface SaveListTalentModalProps {
  isOpen: boolean
  handleClose: () => void
  handleGetSelectedTalents: (selectedTalentsIds: string[]) => void
  maxWidth: string
}

export const AddTalentToListModal = ({
  isOpen,
  handleClose,
  handleGetSelectedTalents,
  maxWidth,
}: SaveListTalentModalProps) => {
  const [inputValue, setInputValue] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('')
  const [showTalents, setShowTalents] = useState(false)
  const [selectedTalents, setSelectedTalents] = useState<SelectedTalents[]>([])

  const {
    data: talentsData,
    isError: isTalentsDataErr,
    isLoading,
  } = useGetTalentMetricsByNameQuery(
    { name: debouncedValue },
    {
      skip: debouncedValue.length < 3,
    },
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }
  useEffect(() => {
    if (
      !isTalentsDataErr &&
      !isLoading &&
      talentsData &&
      talentsData.length > 0 &&
      debouncedValue.length > 2
    ) {
      const timer = setTimeout(() => {
        setShowTalents(true)
      }, 1000)

      return () => clearTimeout(timer)
    } else {
      setShowTalents(false)
    }
  }, [isTalentsDataErr, isLoading, talentsData, debouncedValue])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue)
    }, 0)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue])

  const handleCloseModal = () => {
    setInputValue('')
    handleClose()
    setSelectedTalents([])
  }

  const handleSaveClick = () => {
    const selectedTalentsIds = selectedTalents.map(talent => talent.id)
    const uniqueIds = [...new Set(selectedTalentsIds)]
    handleGetSelectedTalents(uniqueIds)
    handleCloseModal()
  }

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleCloseModal}
      titleClass='pb-4'
      wrapperClass='p-0'
      maxWidth={maxWidth}
    >
      <div
        className={`max-h-[90vh] px-8 pb-4 pt-8 ${
          selectedTalents.length > 2 ? 'overflow-y-auto' : ''
        }`}
      >
        <p className='mb-6 pb-4 text-lg font-medium text-primary-black'>
          Add Talent
        </p>
        <div>
          <div className='relative'>
            <Input
              containerWrapperClassName='pb-5'
              inputClassName='h-[40px] px-3'
              placeholder='Add Talent'
              value={inputValue}
              onChange={handleInputChange}
              leftIcon={
                <Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />
              }
            />
            {isTalentsDataErr && debouncedValue !== '' && (
              <p className='mb-6 px-1 text-sm font-normal leading-6'>
                No results found
              </p>
            )}

            {showTalents && (
              <>
                <ul className='absolute top-12 z-10 flex max-h-[200px] w-full flex-col items-start overflow-y-auto rounded-md border border-[#0307181a] bg-white p-3 shadow-sm'>
                  {talentsData &&
                    talentsData.map(talent => (
                      <li
                        className='bg-custom-light flex h-[32px] cursor-pointer flex-row items-start self-stretch rounded-md p-1  hover:bg-grey-8'
                        key={uuidv4()}
                        onClick={() => {
                          setInputValue('')
                          setSelectedTalents(prev => [
                            ...prev,
                            { id: talent.talent_id, name: talent.talent_name },
                          ])
                        }}
                      >
                        <p className='px-1 text-sm font-normal leading-6'>
                          {talent.talent_name}
                        </p>
                      </li>
                    ))}
                </ul>
              </>
            )}
          </div>
          {selectedTalents.length > 0 && (
            <div className='mb-6 '>
              <p
                style={{ color: '#4D4B4A' }}
                className='border-gray-9 flex h-12 flex-row items-center border-b p-2.5 text-sm font-normal leading-6'
              >
                Talent
              </p>
              {selectedTalents.map(talent => (
                <div
                  className='border-gray-9 flex h-12 flex-row items-center justify-between border-b p-2.5 text-sm font-normal leading-6'
                  key={uuidv4()}
                >
                  <p>{talent.name} </p>{' '}
                  <Close
                    className='cursor-pointer'
                    onClick={() => {
                      setSelectedTalents(prev =>
                        prev.filter(t => t.id !== talent.id),
                      )
                    }}
                    fill={Colors.NAV_ICON_THREE}
                    width={16}
                    height={16}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='flex justify-end gap-2'>
          <Button
            kind='text'
            size='medium'
            onClick={handleCloseModal}
            className='py-2.5'
          >
            <span className='px-3 text-[13px] font-semibold'>Cancel</span>
          </Button>

          <Button
            onClick={handleSaveClick}
            kind='filled'
            size='medium'
            className='bg-primary-red py-2.5 disabled:bg-primary-red'
            disabled={selectedTalents.length === 0}
          >
            <span className='px-3 text-[13px] font-semibold'>Add Talent</span>
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
