import { useState } from 'react'
import { twJoin } from 'tailwind-merge'
import { DateRange as PickerDateRange } from 'react-day-picker'

import { Select, FilterButton, FilterColumnGroup, DateRange } from 'components'
import { Attribute, ChevronDown, List, Percent } from 'components/ui/icons'
import { formatCamelCaseString } from 'utils'
import { useClickOutsideComponent, useSelectOptions } from 'hooks'
import {
  Colors,
  optionAttributes,
  optionSearch,
  optionSocialMedia,
} from 'constnants'
import {
  initialOptions,
  percentagesOptions,
  topOptions,
} from 'constnants/selectOptions'
import { AudienceFilterButton } from 'components/page/talent/AudienceFilter'
import { DateRangeKey } from 'types'

interface TalentCompareGraphControlsProps {
  handleSelectChange: (selectedFilter: { [key: string]: string[] }) => void
  selectedAttribute: { [key: string]: string[] }
  updateChartData: () => void
  onChangeContentTypeView: (typeView: string) => void
  onChangeTopBox: (topBox: string) => void
  onToggleDateRange: (
    rangeKey: DateRangeKey,
    customRange?: PickerDateRange,
  ) => void
  onAudienceFilterChange: (filters: string) => void
}

export const TalentCompareGraphControls = ({
  selectedAttribute,
  handleSelectChange,
  onChangeContentTypeView,
  updateChartData,
  onChangeTopBox,
  onToggleDateRange,
  onAudienceFilterChange,
}: TalentCompareGraphControlsProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const { selects, handleSelectOptions } = useSelectOptions(initialOptions)

  const selectedAttributeType = selectedAttribute['attribute'][0]

  return (
    <div className='flex justify-between py-2'>
      <div className='flex gap-2'>
        <AudienceFilterButton handleFilterChange={onAudienceFilterChange} />

        <FilterButton
          name={formatCamelCaseString(selectedAttributeType || '')}
          trianglePosition='left'
          totalSelectedFilters={0}
          isMenuVisible={isMenuVisible}
          setIsMenuVisible={setIsMenuVisible}
          leftIcon={
            <Attribute
              width={20}
              height={20}
              fill={Colors.BASE_ICON}
              className='pr-1'
            />
          }
          rightIcon={
            <ChevronDown
              fill={Colors.BASE_ICON}
              width={22}
              height={22}
              className='pl-1'
            />
          }
          nameCLassName={twJoin(selectedAttribute?.name && 'text-primary-red')}
        >
          <div className='flex flex-row gap-10'>
            {/*{chartDetailAttributes.map(({ id, name, selectOptions }) => {*/}
            {/*  return (*/}
            {/*    <FilterColumnGroup*/}
            {/*      key={id}*/}
            {/*      category={id}*/}
            {/*      title={name}*/}
            {/*      optionsFilter={transformToOptionFilters(selectOptions!)}*/}
            {/*      selectedFilters={{*/}
            {/*        [id]: [selectedAttribute?.name || ''],*/}
            {/*      }}*/}
            {/*      onChange={selected => {*/}
            {/*        setIsMenuVisible(true)*/}
            {/*        handleSelectChange(selected)*/}
            {/*      }}*/}
            {/*      isSelect={true}*/}
            {/*      titleClassName='text-start'*/}
            {/*      labelRadioClass='text-xs'*/} {/*    />*/}
            {/*  )*/}
            {/*})}*/}
            <FilterColumnGroup
              category={'attribute'}
              title={'Attributes'}
              optionsFilter={optionAttributes}
              selectedFilters={selectedAttribute}
              isSingleSelection={true}
              onChange={selected => {
                setIsMenuVisible(!isMenuVisible)
                handleSelectChange(selected)
              }}
              isSelect={true}
              titleClassName='text-start'
              labelRadioClass='text-xs'
            />
            <FilterColumnGroup
              key={'socialMedia'}
              category={'attribute'}
              title={'Social Media'}
              optionsFilter={optionSocialMedia.filter(
                item => item.value === 'allFollowers',
              )}
              selectedFilters={selectedAttribute}
              isSingleSelection
              onChange={selected => {
                setIsMenuVisible(!isMenuVisible)
                handleSelectChange(selected)
              }}
              isSelect={true}
              titleClassName='text-start'
              labelRadioClass='text-xs'
            />
            <FilterColumnGroup
              key={'search'}
              category={'attribute'}
              title={'Search'}
              optionsFilter={optionSearch.filter(
                item => item.value === 'pageViews',
              )}
              selectedFilters={selectedAttribute}
              isSingleSelection
              onChange={selected => {
                setIsMenuVisible(!isMenuVisible)
                handleSelectChange(selected)
              }}
              isSelect={true}
              titleClassName='text-start'
              labelRadioClass='text-xs'
            />
          </div>
        </FilterButton>
        <Select
          value={selects['percentages']}
          handleClick={option => {
            onChangeContentTypeView(option.value)
            handleSelectOptions(option, 'percentages')
          }}
          options={percentagesOptions}
          size='small'
          leftIcon={
            selects['percentages'] === 'percentage' ? (
              <Percent fill={Colors.BASE_ICON} />
            ) : (
              <div></div>
            )
          }
        />
        <Select
          value={selects['top']}
          handleClick={option => {
            handleSelectOptions(option, 'top')
            onChangeTopBox(option.value)
          }}
          options={topOptions}
          size='small'
          leftIcon={<List fill={Colors.BASE_ICON} width={16} height={16} />}
        />
      </div>
      <div>
        <DateRange onApply={onToggleDateRange} />
      </div>
    </div>
  )
}
