import { twMerge } from 'tailwind-merge'
import { ProjectsTableHeaderProps } from '../ProjectsTable.types'
import { ChevronDown, ChevronUp } from 'components/ui/icons'
import { Colors } from 'constnants'
import { DirectionSort } from '../../../types'

export const ProjectsTableHeader = <T,>({
  columns,
  handleUpdateQueryParams,
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  phaseStatus,
}: ProjectsTableHeaderProps<T>) => {
  return (
    <thead>
      <tr>
        {columns.map(column => (
          <th
            key={column.value}
            className={twMerge(
              'p-3 text-xs font-normal text-primary-grey hover:cursor-pointer',
            )}
          >
            <div
              className='flex items-center gap-2 hover:cursor-pointer hover:opacity-80'
              onClick={() =>
                handleUpdateQueryParams &&
                (column.value === 'createdAt' ||
                  column.value === 'updatedAt') &&
                handleUpdateQueryParams({
                  pageSize,
                  pageNumber,
                  sortBy: column.value,
                  phaseStatus,
                  sortOrder:
                    sortOrder === DirectionSort.ASC
                      ? DirectionSort.DESC
                      : DirectionSort.ASC,
                })
              }
            >
              {column.title}
              <div>
                <ChevronUp
                  color={
                    column.value === sortBy && sortOrder === DirectionSort.ASC
                      ? Colors.ICON_BRAND
                      : Colors.BASE_ICON
                  }
                  width={10}
                  height={8}
                  className={twMerge(
                    '-mb-1',
                    column.value === sortBy && 'text-lg',
                  )}
                />
                <ChevronDown
                  color={
                    column.value === sortBy && sortOrder === DirectionSort.DESC
                      ? Colors.ICON_BRAND
                      : Colors.BASE_ICON
                  }
                  width={10}
                  height={8}
                />
              </div>
            </div>
          </th>
        ))}
        <th></th>
      </tr>
    </thead>
  )
}
