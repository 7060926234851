import { twMerge } from 'tailwind-merge'
import { TalentName } from '../TalentName'
import { Image } from '../ui'
import { SectionTitle } from '../SectionTitle'
import { useNavigate } from 'react-router-dom'
import { type Cast } from 'types'
import { useMemo } from 'react'

interface FilmCastProps {
  casts: Cast[]
  title?: string
  className?: string
}

export const FilmCast = ({ title, casts, className }: FilmCastProps) => {
  const navigate = useNavigate()

  const talentsWithIds = useMemo(() => casts.filter(cast => cast.id), [casts]) // choose talents with ids

  const handleNavigation = (talentId: number) => {
    navigate(`/talent/${talentId}`, { replace: true })
  }

  const renderCast = (cast: Cast, index: number) => {
    const { name, img: talentUrl, id } = cast // Default ID for cases where API doesn't provide it. default is set to 1
    const talentId = id ?? 1

    return (
      <div
        key={index}
        className='flex gap-4 hover:cursor-pointer hover:opacity-80'
        onClick={() => handleNavigation(talentId)}
      >
        <Image alt={name} src={talentUrl!} className='h-[50px] w-[40px]' />
        <TalentName fullName={name} size='xs' className='font-semibold' />
      </div>
    )
  }

  return (
    <span className={twMerge('flex flex-col text-primary-black', className)}>
      {title && <SectionTitle title={title} className='pb-5' />}
      <div className='flex flex-col gap-y-2.5'>
        {talentsWithIds.map(renderCast)}
      </div>
    </span>
  )
}
