import { CreateProjectRole, ProjectRolesResponse } from 'types'
import { api } from './api'

export const projectRoleApi = api.injectEndpoints({
  endpoints: builder => ({
    getProjectRoles: builder.query<
      ProjectRolesResponse[],
      { projectId: number }
    >({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/roles`,
        method: 'GET',
      }),
      providesTags: ['ProjectRole'],
    }),
    createProjectRole: builder.mutation<
      CreateProjectRole,
      { role: CreateProjectRole; projectId: number }
    >({
      query: ({ role, projectId }) => ({
        body: role,
        url: `/projects/${projectId}/roles`,
        method: 'POST',
      }),
      invalidatesTags: ['ProjectRole'],
    }),
    deleteProjectRole: builder.mutation<
      void,
      { roleId: string; projectId: number }
    >({
      query: ({ projectId, roleId }) => ({
        url: `/projects/${projectId}/roles/${roleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectRole'],
    }),
    updateProjectRole: builder.mutation<
      ProjectRolesResponse,
      {
        role: Partial<ProjectRolesResponse>
        roleId: string
        projectId: number
      }
    >({
      query: ({ role, projectId, roleId }) => ({
        body: role,
        url: `/projects/${projectId}/roles/${roleId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['ProjectRole'],
    }),
  }),
})

export const {
  useCreateProjectRoleMutation,
  useGetProjectRolesQuery,
  useDeleteProjectRoleMutation,
  useUpdateProjectRoleMutation,
} = projectRoleApi
