export enum Colors {
  NAV_ICON_ONE = 'rgba(255, 255, 255, 0.8)',
  NAV_ICON_TWO = 'rgba(255, 255, 255, 0.25)',
  NAV_ICON_THREE = 'rgba(3, 7, 18, 0.5)',
  BASE_ICON = '#6b6b6b',
  GRAY_600 = '#ADADAD',
  ICON_BRAND = '#FF4449',
  ICON_WHITE = '#FFFFFF',
  ICON_NEGATIVE_RED = '#99292C',
  GRAPH_LINE = '#99292C',
  ICON_POSITIVE_BLUE = '#4A8FDC',
  SUCCESS = '#009039',
}

export const TalentColorPalette = [
  '#99292C', // Provided color 1
  '#FF4B9E', // Provided color 2
  '#FFA500', // Provided color 3
  '#B85AFA', // Provided color 4
  '#b43a3a', // Lightened variant of color 1
  '#d64a4a', // Lightened variant of color 1
  '#ff6fb0', // Lightened variant of color 2
  '#ff8bc3', // Lightened variant of color 2
  '#ffd571', // Lightened variant of color 3
  '#ffb8e0', // Lightened variant of color 2
]
export const ChartColorPalette = [
  '#99292C', // Provided color 1
  '#FF4B9E', // Provided color 2
  '#FFA500', // Provided color 3
  '#B85AFA', // Provided color 4
  '#b43a3a', // Lightened variant of color 1
  '#d64a4a', // Lightened variant of color 1
  '#ff6fb0', // Lightened variant of color 2
  '#ff8bc3', // Lightened variant of color 2
  '#ffd571', // Lightened variant of color 3
  '#ffb8e0', // Lightened variant of color 2
]
