import { useState, useRef } from 'react'
import { OptionType } from 'types'
import { Role } from 'types/ProjectCompany'
import { ExistingMyLists, ExistingProjectItem } from 'components'
import { useUpdateTelentListMutation } from 'store/api'
import { toast } from 'react-toastify'
import { commonToastStyles } from 'utils'
import { Success } from 'components/ui/icons'

interface ExistingProjectListProps {
  list: any[]
  selectedProjectId: string | null
  setSelectedProjectId: (id: string | null) => void
  selectedSubProjectId: string | null
  setSelectedSubProjectId: (id: string | null) => void
  close: () => void
  selectedTalents: string[]
  selectedProjectLabel: string | undefined
}

export const ExistingProjectList = ({
  list,
  selectedProjectId,
  setSelectedProjectId,
  selectedSubProjectId,
  setSelectedSubProjectId,
  close,
  selectedTalents,
  selectedProjectLabel,
}: ExistingProjectListProps) => {
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([])
  const [selectedRoleLists, setSelectedRoleLists] = useState<
    OptionType[] | undefined
  >([])
  const [roleMenuTopPosition, setRoleMenuTopPosition] = useState(0)
  const [listMenuTopPosition, setListMenuTopPosition] = useState(0)
  const [updateTelentList] = useUpdateTelentListMutation()

  const handleProjectClick = (id: string, e: React.MouseEvent) => {
    e.stopPropagation()
    setSelectedProjectId(id)
    setSelectedSubProjectId(null)
    const roles = list?.find(project => project.id === id)?.ProjectRoles
    setSelectedRoles(roles)

    if (e.currentTarget instanceof HTMLElement) {
      setRoleMenuTopPosition(e.currentTarget.offsetTop - 8)
    }
    setSelectedRoleLists(undefined)
  }

  const handleSubProjectClick = (id: string, e: React.MouseEvent) => {
    e.stopPropagation()
    const selectedRole = selectedRoles.find(
      selectedRole => id === selectedRole.id,
    )

    const selectedRoleList =
      selectedRole?.lists?.map(list => ({
        id: list.listId,
        label: list.listName,
        value: list.listName,
      })) || []

    if (e.currentTarget instanceof HTMLElement) {
      setListMenuTopPosition(e.currentTarget.offsetTop - 8)
    }

    setSelectedRoleLists(selectedRoleList)
    setSelectedSubProjectId(id)
  }

  const handleAddToExistingList = (listId: string, listLabel: string) => {
    if (
      !selectedProjectId ||
      !selectedSubProjectId ||
      !selectedTalents.length
    ) {
      return
    }
    close()
    updateTelentList({
      listId,
      talentIdsToAdd: selectedTalents,
      projectId: selectedProjectId,
      roleId: selectedSubProjectId,
    })
      .unwrap()
      .then(() =>
        toast.success(
          <>
            Talent added to <b>{selectedProjectLabel}</b> project:
            <br />
            <b>{listLabel}</b>
          </>,
          {
            icon: <Success width={24} height={24} />,
            ...commonToastStyles,
          },
        ),
      )
  }

  return (
    <div className='flex'>
      <ul className='flex w-full flex-col space-y-0.5 p-2'>
        {list.map((item, i) => (
          <li
            key={`${item.value}_${i}`}
            onClick={e => handleProjectClick(item.id, e)}
          >
            <ExistingProjectItem
              label={item.label}
              isSelected={selectedProjectId === item.id}
            />
          </li>
        ))}
      </ul>
      {Array.isArray(selectedRoles) && selectedRoles.length > 0 && (
        <ul
          className={`absolute left-[100%] ml-1 w-full flex-col space-y-0.5 rounded border bg-primary-white p-2 shadow-lg`}
          style={{ top: roleMenuTopPosition }}
        >
          {selectedRoles.map((role: any, i: number) => (
            <li
              key={`${role.id}_${i}`}
              onClick={e => handleSubProjectClick(role.id, e)}
            >
              <ExistingProjectItem
                label={role.label}
                isSelected={selectedSubProjectId === role.id}
              />
            </li>
          ))}
        </ul>
      )}
      {Array.isArray(selectedRoleLists) && selectedRoleLists.length > 0 && (
        <ul
          style={{ top: roleMenuTopPosition + listMenuTopPosition }}
          className={`absolute left-[200%] ml-2 w-full flex-col space-y-0.5 rounded border bg-primary-white p-2 shadow-lg`}
        >
          <ExistingMyLists
            list={selectedRoleLists}
            classNameParentVal={' px-0 '}
            handleAddToExistingList={handleAddToExistingList}
          />
        </ul>
      )}
    </div>
  )
}
