import { twMerge } from 'tailwind-merge'
import { Close } from '../../ui/icons'

interface navigationLiItemProps {
  project: {
    id: string
    name: string
  }
  className?: string
  setActiveTabHeader: (id: string) => void
  handelDeleteProject: (id: string) => void
  buttonClassName?: string
  textClassName?: string
}

export const NavigationLiItem = ({
  project,
  className,
  setActiveTabHeader,
  handelDeleteProject,
  buttonClassName,
  textClassName,
}: navigationLiItemProps) => {
  return (
    <li className={className}>
      <button
        className={twMerge('flex w-full items-center gap-1', buttonClassName)}
      >
        <span
          className={twMerge('flex-grow truncate text-left', textClassName)}
          onClick={() => setActiveTabHeader(project.id)}
        >
          {project.name}
        </span>
        <span
          className={'p-1 text-grey-7 hover:text-red-9'}
          onClick={() => {
            handelDeleteProject(project.id)
          }}
        >
          <Close fill={'currentColor'} width={16} height={16} />
        </span>
      </button>
    </li>
  )
}
