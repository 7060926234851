import { ChangeEvent } from 'react'
import { Button, Input, RoleItemList } from 'components'
import { Plus, Search } from 'components/ui/icons'
import { Colors } from 'constnants'
import { moviesFiltersType, OptionType } from 'types'
import { twMerge } from 'tailwind-merge'

interface MyListMenuProps {
  recentLists?: OptionType[]
  allLists: OptionType[]
  titleButton: string
  handleOpenEditModal?: (value: string, id: string) => void
  handleOpenDeleteModal?: (id: string, value: string) => void
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleChange?: () => void
  onClickItem?: (role: OptionType) => void
  activeSavedSearch?: string
  isSavedSearchChanged?: boolean
  setIsSavedSearchChanged?: (isChanged: boolean) => void
  titleFirstList?: string
  titleSecondList?: string
  className?: string
  separator?: boolean
  resetSavedSearch?: (option: OptionType) => void
  activeFilters?: moviesFiltersType
  searchToModify?: string
  setUpdatedFilters?: (filters: moviesFiltersType) => void
  setSearchModalOpened?: (status: boolean) => void
  onUpdateSearchPreset?: () => void
}

export const MyListMenu = ({
  recentLists,
  allLists,
  titleButton,
  handleOpenEditModal,
  handleOpenDeleteModal,
  value,
  onChange,
  onClickItem,
  handleChange,
  titleFirstList,
  titleSecondList,
  activeSavedSearch,
  isSavedSearchChanged,
  setIsSavedSearchChanged,
  className,
  separator,
  resetSavedSearch,
  activeFilters,
  searchToModify,
  setUpdatedFilters,
  setSearchModalOpened,
  onUpdateSearchPreset,
}: MyListMenuProps) => {
  return (
    <div
      className={twMerge(
        'flex w-full flex-col gap-3 overflow-y-auto p-2',
        className,
      )}
    >
      <Button
        kind='filled'
        size='small'
        className='flex w-full justify-center gap-0.5'
        onClick={handleChange}
      >
        <Plus fill={Colors.ICON_WHITE} />
        <span className='text-xs font-semibold text-primary-white'>
          {titleButton}
        </span>
      </Button>
      <Input
        leftIcon={<Search fill={Colors.BASE_ICON} width={16} height={16} />}
        value={value}
        onChange={onChange}
      />

      <RoleItemList
        list={recentLists}
        title={titleFirstList}
        handleOpenEdit={handleOpenEditModal}
        handleOpenDelete={handleOpenDeleteModal}
        hasEditTrash={false}
        onClickItem={onClickItem}
        isSavedSearchChanged={isSavedSearchChanged}
        setIsSavedSearchChanged={setIsSavedSearchChanged}
        onReset={resetSavedSearch}
        onUpdateSearchPreset={onUpdateSearchPreset}
      />

      {separator && <div className='w-full border-b border-primary-black/10' />}

      <RoleItemList
        list={allLists}
        title={titleSecondList}
        handleOpenEdit={handleOpenEditModal}
        handleOpenDelete={handleOpenDeleteModal}
        hasEditTrash
        onClickItem={onClickItem}
        activeSavedSearch={activeSavedSearch}
        isSavedSearchChanged={isSavedSearchChanged}
        setIsSavedSearchChanged={setIsSavedSearchChanged}
        onReset={resetSavedSearch}
        activeFilters={activeFilters}
        searchToModify={searchToModify}
        setUpdatedFilters={setUpdatedFilters}
        setSearchModalOpened={setSearchModalOpened}
        onUpdateSearchPreset={onUpdateSearchPreset}
      />
    </div>
  )
}
