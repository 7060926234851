import { useEffect, useState } from 'react'
import { SortOrderKey, TalentMetricsResponse } from 'types'
import { apiURL } from '../store/api/api'
import { useLocation } from 'react-router-dom'
import { useTalentHeaderData, useUpdateUrlParams } from 'hooks'
import { DEFAULT_PAGE_SIZE } from 'constnants/talentTableData'
import { dataTalent } from 'constnants'
import { dataAudience } from 'constnants/audienceOptions'

export type MetricsType = 'PERCENTAGE' | 'RANK' | 'COUNT'
export type TalentGender = 'Male' | 'Female' | 'Non-Binary'
export type TalentRace = 'black' | 'hispanic' | 'white' | 'asian' | 'nhpi'
export type TalentAge = '12-17' | '18-24' | '25-34' | '35-44' | '45-59' | '60+'

export type SortQueryParams = {
  sortBy: string
  sortOrder: SortOrderKey
  metricsType: MetricsType
}
interface TalentAttributes {
  talentGender?: TalentGender[]
  talentAge?: TalentAge[]
  talentRace?: TalentRace[]
}
export type QueryParams = {
  pageSize?: string
  currentPage?: string
  audienceFilter?: string
  sortBy?: string
  sortOrder?: SortOrderKey
  metricsType?: MetricsType
  talentGender?: TalentGender[]
  talentRace?: TalentRace[]
  talentAge?: TalentAge[]
  talentName?: string
  selectedProject?: string
  selectedRole?: string
  topTwoBox?: string
  presetSearch?: string
}

interface UseTalentArgs {
  listId?: string
  refreshTalentMetrics?: string[]
}

export default function useTalent({
  listId,
  refreshTalentMetrics,
}: UseTalentArgs) {
  const [talentList, setTalentList] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  const [totalResults, setTotalResults] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [sortBy, setSortBy] = useState<string>('AWARENESS')
  const [sortOrder, setSortOrder] = useState<SortOrderKey>('DESC')
  const [metricsType, setMetricsType] = useState<MetricsType>('PERCENTAGE')
  const [topTwoBox, setTopTwoBox] = useState('topTwoBox')
  const { selectedFiltersAudience, selectedFiltersTalent } =
    useTalentHeaderData(dataTalent.concat(dataAudience))
  const timeoutValue = 1200
  let urlTalentMetrics = `${apiURL}/talentMetrics`
  if (listId) {
    urlTalentMetrics = `${apiURL}/talentMetrics/list/${listId}/metrics`
  }

  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const query: Partial<QueryParams> = {}

    searchParams.forEach((value, key) => {
      const typedKey = key as keyof QueryParams
      if (
        typedKey === 'talentGender' ||
        typedKey === 'talentRace' ||
        typedKey === 'talentAge'
      ) {
        if (query[typedKey]) {
          query[typedKey] = [...query[typedKey], value] as any
        } else {
          query[typedKey] = [value] as any
        }
      } else {
        query[typedKey] = value as any
      }
    })

    try {
      fetchTalent(query).then(data => {
        if (data) {
          const {
            talentList,
            totalPages,
            totalResults,
            currentPage,
            pageSize,
          } = data || {}

          if (talentList) {
            setTalentList(talentList)
            setTotalPages(totalPages)
            setTotalResults(totalResults)
            setCurrentPage(currentPage)
            setPageSize(pageSize)
          } else {
            setTalentList([])
            setTotalPages(0)
            setTotalResults(0)
            setCurrentPage(1)
            setPageSize(25)
          }
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('An unknown error occurred')
      }
    }
  }, [location.search, listId, refreshTalentMetrics])

  const handleUpdateUrlParams = useUpdateUrlParams()

  const [timeoutId, setTimeoutId] = useState(null)

  const fetchTalent = async (
    queryParams?: QueryParams,
  ): Promise<TalentMetricsResponse> => {
    setLoading(true)
    setError('')
    const {
      pageSize,
      currentPage,
      audienceFilter,
      sortBy,
      sortOrder,
      metricsType,
      talentGender,
      talentRace,
      talentAge,
      talentName,
      listId,
      topTwoBox,
    } = queryParams || {}
    const url = new URL(urlTalentMetrics)
    const searchParams = url.searchParams
    searchParams.append('pageSize', pageSize || `${DEFAULT_PAGE_SIZE}`)
    searchParams.append('pageNumber', currentPage || '1')

    if (audienceFilter) {
      searchParams.append('audienceFilter', audienceFilter)
    }

    if (Array.isArray(talentGender)) {
      talentGender.forEach(gender => {
        searchParams.append('talentGender[]', gender)
      })
    }

    if (Array.isArray(talentRace)) {
      talentRace.forEach(race => {
        searchParams.append('talentRace[]', race)
      })
    }

    if (Array.isArray(talentAge)) {
      talentAge.forEach(age => {
        searchParams.append('talentAge[]', age)
      })
    }
    if (talentName) {
      searchParams.append('talentName', talentName)
    }

    searchParams.append('sortBy', sortBy ?? 'AWARENESS')
    searchParams.append('sortOrder', sortOrder ?? 'DESC')
    searchParams.append('metricsType', metricsType ?? 'PERCENTAGE')
    if (topTwoBox === 'topTwoBox') {
      searchParams.append('top2Box', 'true')
    } else {
      searchParams.append('top2Box', 'false')
    }

    const urlWithQuery = url.toString()

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const p = new Promise(resolve => {
      const idTimeout = setTimeout(async () => {
        const result = await fetchData()
        resolve(result)
      }, timeoutValue)

      setTimeoutId(idTimeout)
    })

    async function fetchData() {
      try {
        const response = await fetch(urlWithQuery, {
          method: 'GET',
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        })

        if (!response.ok) {
          if (response.status === 404) {
            setError('Talent not found')
          } else {
            setError('An error occurred while fetching talent data')
          }
        }

        const data = await response.json()
        if (!data || !data.talentList || data.talentList.length === 0) {
          setError('Not Found')
        }

        return data
      } finally {
        setLoading(false)
      }
    }

    return p
  }

  //todo delete it after filters test
  /*useEffect( () => {
    try {
       fetchTalent().then(data => {
        if (data) {
          const {
            talentList,
            totalPages,
            totalResults,
            currentPage,
            pageSize,
          } = data || {}
          if (talentList) {
            setTalentList(talentList)
            setTotalPages(totalPages)
            setTotalResults(totalResults)
            setCurrentPage(currentPage)
            setPageSize(pageSize)
          }
        } else {
          setTalentList([])
          setTotalPages(0)
          setTotalResults(0)
          setCurrentPage(1)
          setPageSize(25)
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }, [])*/

  const handleChangePagination = (query: QueryParams) => {
    const audienceFilter = getAudienceFilter(selectedFiltersAudience)
    const { talentAge, talentGender, talentRace } = getTalentAttributes(
      selectedFiltersTalent,
    )
    const queryParams = {
      ...query,
      audienceFilter,
      talentAge,
      talentGender,
      talentRace,
      talentName,
    }
    try {
      fetchTalent(queryParams).then(data => {
        if (data) {
          const {
            talentList,
            totalPages,
            totalResults,
            currentPage,
            pageSize,
          } = data || {}

          if (talentList && talentList.length > 0) {
            setTalentList(talentList)
            setTotalPages(totalPages)
            setTotalResults(totalResults)
            setCurrentPage(currentPage)
            setPageSize(pageSize)
          }
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('An unknown error occurred')
      }
    }
  }

  const handleChangeFilter = (query: QueryParams) => {
    handleUpdateUrlParams(query)
  }
  const getAudienceFilter = (
    data: Record<string, string[]>,
  ): string | undefined => {
    for (const key in data) {
      if (data[key].length === 1) {
        return data[key][0]
      }
    }
    return undefined // Return undefined if no single value is found
  }
  const getTalentNameFromQuery = () => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('talentName') || ''
  }

  const getTalentAttributes = (
    data: Record<string, string[]>,
  ): TalentAttributes => {
    const { gender, age, race } = data

    // Get the first value if the array is not empty, otherwise return undefined
    const talentGender = gender.length ? (gender as TalentGender[]) : undefined
    const talentAge = age.length ? (age as TalentAge[]) : undefined
    const talentRace = race.length ? (race as TalentRace[]) : undefined

    return { talentGender, talentAge, talentRace }
  }
  const handleSortChange = ({
    sortBy,
    sortOrder,
    metricsType,
    topTwoBox,
  }: SortQueryParams) => {
    const audienceFilter = getAudienceFilter(selectedFiltersAudience)
    const { talentAge, talentGender, talentRace } = getTalentAttributes(
      selectedFiltersTalent,
    )
    const talentNameFromQuery = getTalentNameFromQuery()

    fetchTalent({
      pageSize: pageSize.toString(),
      currentPage: currentPage.toString(),
      sortBy,
      sortOrder,
      metricsType,
      topTwoBox: topTwoBox,
      audienceFilter,
      talentAge,
      talentGender,
      talentRace,
      talentName: talentNameFromQuery,
    }).then(data => {
      if (data) {
        const { talentList, totalPages, totalResults, currentPage, pageSize } =
          data || {}

        if (talentList) {
          setTalentList(talentList)
          setTotalPages(totalPages)
          setTotalResults(totalResults)
          setCurrentPage(currentPage)
          setPageSize(pageSize)
          setSortBy(sortBy)
          setSortOrder(sortOrder)
          setMetricsType(metricsType)
        }
      }
    })
  }

  return {
    talentList,
    totalPages,
    totalResults,
    currentPage,
    pageSize,
    loading,
    error,
    sortBy,
    sortOrder,
    metricsType,
    topTwoBox,
    setTopTwoBox,
    setMetricsType,
    handleChangePagination,
    handleChangeFilter,
    handleSortChange,
  }
}
