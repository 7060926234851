import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  LayoutContentWrapper,
  FilmTrackingTable,
  FilmMetricsChart,
  FilterButton,
  ExclusiveFilter,
  Close,
  CloseMenu,
  FilmTracking,
  Attribute,
  ChevronDown,
  SectionTitle,
} from 'components'

import { AudienceDemoGroup, AudienceGeneralDemo, OptionType, Urls } from 'types'
import { columnsFilms, topColumnsFilms } from 'constnants/filmsTableData'

import {
  useFilmMetricsControllerGetChartDataQuery,
  useFilmMetricsControllerGetFilmTrackingQuery,
} from 'store/api'
import { buildParams } from 'utils'
import { PageHeader } from './components'
import {
  filmTrackingComparePageSelectors,
  useFilmTrackingCopmarePageActions,
} from 'slices'
import { useSelector } from 'react-redux'
import PageLoader from 'components/PageLoader'
import {
  ChartColorPalette,
  Colors,
  filmMetricCategoryOptions,
} from 'constnants'
import { twMerge } from 'tailwind-merge'
import { audienceFilterDef } from 'constnants/audienceOptionsFilms'

export const FilmTrackingComparePage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const actions = useFilmTrackingCopmarePageActions()
  const [showFilmsList, setShowFilmsList] = useState(false)

  const filmIdsFromParams = searchParams.getAll('filmId')

  const filmIds = useMemo(
    () => filmIdsFromParams.map(it => Number(it)),
    [filmIdsFromParams],
  )

  const {
    sorting,
    selectedFilms,
    metricCategory,
    audienceFilter,
    isInterestAwareActive,
  } = useSelector(filmTrackingComparePageSelectors.root)

  const sortByResolved =
    isInterestAwareActive && sorting.column === 'interest'
      ? 'interestawaretab'
      : sorting.column

  const { films, isLoading } = useFilmMetricsControllerGetFilmTrackingQuery(
    {
      pageSize: 25,
      pageNumber: 1,
      sortBy: sortByResolved,
      sortOrder: sorting.direction,
      audienceFilter: audienceFilter,
      filmIds: filmIds,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        return {
          ...rest,
          films: data?.data ?? [],
        }
      },
    },
  )

  const { data: filmMetricsChart } = useFilmMetricsControllerGetChartDataQuery({
    filmIds: filmIds,
    attribute: metricCategory,
    audienceFilter: audienceFilter,
  })

  const selectAllChecked =
    selectedFilms.length > 0 && selectedFilms.length === films.length

  const handleToggleSelectOne = (rowId: string) => {
    const rowIdStr = rowId.toString()
    const newSelected = selectedFilms.includes(rowIdStr)
      ? selectedFilms.filter(id => id !== rowIdStr)
      : [...selectedFilms, rowIdStr]
    actions.setSelectedFilms(newSelected)
  }

  const handleToggleSelectAll = () => {
    const newSelected = selectAllChecked
      ? []
      : films.map(it => it.id.toString())
    actions.setSelectedFilms(newSelected)
  }

  const removeFromCompare = (filmIdsUpdated: number[]) => {
    actions.setSelectedFilms([])

    if (!filmIdsUpdated.length) {
      return navigate(Urls.FILM)
    }

    const queryParameters = buildParams({ filmId: filmIdsUpdated })
    navigate(`${Urls.FILM}${Urls.COMPARE}?${queryParameters}`)
  }

  const handleRemoveFromCompareById = (filmId: string) => {
    const filmIdsUpdated = filmIds.filter(id => id.toString() !== filmId)

    removeFromCompare(filmIdsUpdated)
  }

  const handleRemoveFromCompare = () => {
    const filmIdsUpdated = filmIds.filter(
      filmId => !selectedFilms.includes(filmId.toString()),
    )

    removeFromCompare(filmIdsUpdated)
  }

  const handleAddToCompare = (filmId: string) => {
    const filmIdsUpdated = [...filmIds, filmId]
    const queryParameters = buildParams({ filmId: filmIdsUpdated })
    navigate(`${Urls.FILM}${Urls.COMPARE}?${queryParameters}`)
  }

  const filmMetricCategoryTitle = useMemo(
    () =>
      filmMetricCategoryOptions.find(it => it.value === metricCategory)
        ?.label ?? '',
    [metricCategory],
  )

  const filmOptions: OptionType[] = useMemo(
    () =>
      filmIdsFromParams.map(id => {
        const film = filmMetricsChart?.find(it => it.film_id.toString() === id)
        return { id, label: film?.film_name ?? '', value: id }
      }),
    [filmIdsFromParams],
  )

  const handleResetFiltersAudience = useCallback(
    () => actions.setAudienceFilter(AudienceGeneralDemo.ALL),
    [],
  )

  const audienceTitle = useMemo(() => {
    for (const audienceGroup of audienceFilterDef.options) {
      const targetAudience = audienceGroup.options.find(
        it => it.value === audienceFilter,
      )
      if (targetAudience) {
        if (audienceGroup.id !== AudienceDemoGroup.GENERAL) {
          return `${audienceGroup.name} ${targetAudience.label}`
        }

        if (targetAudience.value !== AudienceGeneralDemo.ALL) {
          return targetAudience.label
        }
      }
    }
    return audienceFilterDef.name
  }, [audienceFilter])

  return (
    <LayoutContentWrapper wrapperClassName='flex'>
      {isLoading && <PageLoader />}
      <div className='flex min-h-[calc(100vh-44px)] flex-col'>
        <PageHeader
          filmIds={filmIdsFromParams}
          selectedFilmIds={selectedFilms}
          onRemoveFromCompare={handleRemoveFromCompare}
          onAddToCompare={handleAddToCompare}
        />
        <div className='relative min-h-[508px] px-6'>
          <div className='flex gap-2 py-4'>
            <FilterButton
              name={filmMetricCategoryTitle}
              totalSelectedFilters={0}
              handleReset={actions.resetMetricCategory}
              trianglePosition='left'
              containerMenuClassName='left-0'
              leftIcon={
                <Attribute
                  width={20}
                  height={20}
                  fill={Colors.BASE_ICON}
                  className='pr-1'
                />
              }
              rightIcon={
                <ChevronDown
                  fill={Colors.BASE_ICON}
                  width={22}
                  height={22}
                  className='pl-1'
                />
              }
            >
              <ExclusiveFilter
                slug='metricCategory'
                clasName='min-w-max'
                options={filmMetricCategoryOptions}
                selected={metricCategory}
                onChange={actions.setMetricCategory}
              />
            </FilterButton>
            <FilterButton
              name={audienceTitle}
              totalSelectedFilters={
                audienceFilter === AudienceGeneralDemo.ALL ? 0 : 1
              }
              hideTotalNumber
              handleReset={handleResetFiltersAudience}
              trianglePosition='left'
              containerMenuClassName='left-0'
            >
              <div className='flex flex-row gap-10'>
                {audienceFilterDef.options.map(({ id, name, options }) => {
                  return (
                    <div key={id} className='flex w-max flex-col gap-2'>
                      <SectionTitle
                        title={name}
                        className={twMerge(
                          'text-left text-xs text-primary-black',
                        )}
                      />
                      <ExclusiveFilter
                        slug={id}
                        key={id}
                        options={options}
                        selected={audienceFilter}
                        onChange={actions.setAudienceFilter}
                      />
                    </div>
                  )
                })}
              </div>
            </FilterButton>
          </div>

          {filmMetricsChart && (
            <FilmMetricsChart data={filmMetricsChart} chartHeight={443} />
          )}
          {filmOptions.length > 1 && (
            <div className='absolute bottom-[100px] left-[36px] rounded bg-primary-grey p-2 hover:cursor-pointer'>
              <FilmTracking
                fill={Colors.ICON_WHITE}
                onClick={() => setShowFilmsList(prev => !prev)}
              />
              <div
                className={twMerge(
                  'absolute bottom-0 left-0 rounded border-primary-black/20 bg-grey-2 shadow-md' +
                    ' min-w-[250px] items-end border p-2 hover:cursor-default',
                  showFilmsList ? 'flex' : 'hidden',
                )}
              >
                <div className='basis-6 hover:cursor-pointer'>
                  <CloseMenu
                    fill={Colors.BASE_ICON}
                    width={24}
                    height={24}
                    onClick={() => setShowFilmsList(prev => !prev)}
                  />
                </div>
                <div className='basis-full flex-col'>
                  {filmOptions.map((film, index) => {
                    return (
                      <div
                        key={film.id}
                        className={
                          'flex items-center justify-between gap-1 p-1 text-primary-black'
                        }
                      >
                        <div className='flex items-center gap-1'>
                          <div
                            className='h-[13px] w-[13px] rounded-full'
                            style={{
                              backgroundColor: ChartColorPalette[index],
                            }}
                          />
                          <span className='text-xs text-primary-black'>
                            {film.label}
                          </span>
                        </div>
                        <span
                          className='flex h-4 w-5 items-center justify-center rounded border border-primary-black/10 bg-primary-black/5 hover:cursor-pointer'
                          onClick={() =>
                            handleRemoveFromCompareById(film.value)
                          }
                        >
                          <Close
                            fill={Colors.BASE_ICON}
                            width={16}
                            height={16}
                          />
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <FilmTrackingTable
          selectedRows={selectedFilms}
          toggleSelectOne={handleToggleSelectOne}
          toggleSelectAll={handleToggleSelectAll}
          selectAllChecked={selectAllChecked}
          paginatedData={films}
          topColumns={topColumnsFilms}
          columns={columnsFilms}
          isInterestAwareActive={isInterestAwareActive}
          onChangeInterestAware={actions.setInterestAware}
          requestSort={actions.setSorting}
          sortConfig={sorting}
          hasFooter={false}
        />
      </div>
    </LayoutContentWrapper>
  )
}
