import { useEffect, useMemo, useState } from 'react'
import {
  LayoutContentWrapper,
  TestingTopContent,
  TestingTable,
} from 'components'
import { columns } from 'constnants'

import { NewTestingPage } from '../NewTestingPage'
import { fetchTestingList } from '../../hooks'

interface testingDataProps {
  id: string
  testName: string
  submitDate: string
  completeDate: string
  status: string
  pdfLink: boolean | string
  [key: string]: any
}

export const ConceptTestingPage = () => {
  const [isShowNewTest, setIsShowNewTest] = useState<boolean>(false)
  const onShowNewTest = () => setIsShowNewTest(true)
  const onCloseNewTesting = () => setIsShowNewTest(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sortingBY, setSortingBY] = useState<string>('testName')
  const [sortingOrder, setSortingOrder] = useState<string>('asc')
  const [testingData, setTestingData] = useState<testingDataProps[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [totalTestsCount, setTotalTestsCount] = useState(0)

  useEffect(() => {
    fetchTestingList(
      'concept',
      currentPage,
      rowsPerPage,
      setIsLoading,
      setTestingData,
      setTotalTestsCount,
    ).catch(error => console.error('Error:', error))
  }, [rowsPerPage, currentPage])
  const urlParams = new URLSearchParams(window.location.search)
  const showEmptyState = !!urlParams.get('emptyState')
  const data: testingDataProps[] = showEmptyState ? [] : testingData
  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => {
      if (sortingOrder === 'asc') {
        return a[sortingBY] > b[sortingBY] ? 1 : -1
      } else {
        return a[sortingBY] < b[sortingBY] ? 1 : -1
      }
    })
  }, [sortingBY, sortingOrder, data])

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-white'
      wrapperChildrenClassName='h-full'
    >
      <>
        {!isShowNewTest ? (
          <>
            <TestingTopContent
              subTitle={'All Tests'}
              title={'Concept Tests'}
              handleAddTest={onShowNewTest}
            />
            <TestingTable
              columns={columns}
              data={sortedData}
              handleAddTest={onShowNewTest}
              emptyStateTitle='You haven’t submitted a Concept Test yet'
              emptyStateDescription='Is there an audience for this film? And what does that audience look like? Find out by testing a single or multiple loglines against preset demographics as well as project-specific custom audiences.'
              onSort={setSortingOrder}
              onSortOrder={setSortingBY}
              sortingBY={sortingBY}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setRowsPerPage}
              totalTestsCount={!showEmptyState ? totalTestsCount : 0}
              isLoading={isLoading}
            />
          </>
        ) : (
          <NewTestingPage
            handleCloseNewTesting={onCloseNewTesting}
            subTitle={'Concept Testing'}
            title={'New Concept Test'}
            type={'concept'}
          />
        )}
      </>
    </LayoutContentWrapper>
  )
}
