import { useCallback } from 'react'
import { EntityLookupByField } from 'components'
import { OptionType } from 'types'

import { useLazyFilmMetricsControllerGetSearchFilmQuery } from 'store/api'

interface FilmTrackingLookupProps {
  className?: string
  projectId?: string
  onSelectFilm: (filmId: string) => void
  onClearFilm?: () => void
}

export const FilmTrackingLookup = ({
  className,
  onClearFilm,
  onSelectFilm,
}: FilmTrackingLookupProps) => {
  const [trigger, suggestions] = useLazyFilmMetricsControllerGetSearchFilmQuery(
    {
      selectFromResult: ({ data }): OptionType<string>[] =>
        data?.map(film => ({
          id: film.film_id.toString(),
          label: film.film_name,
          value: film.film_id,
        })) ?? [],
    },
  )

  const load = useCallback(
    async (searchTerm: string): Promise<void> =>
      void (await trigger({ filmName: searchTerm })),
    [],
  )

  return (
    <EntityLookupByField
      notFoundTitle='Film not found'
      className={className}
      options={suggestions}
      onSelect={({ value }) => onSelectFilm(value)}
      onClear={onClearFilm}
      load={load}
    />
  )
}
