import { twMerge } from 'tailwind-merge'

interface TalentNameProps {
  firstName?: string
  lastName?: string
  size: 'xs' | 'sm' | 'm' | 'lg'
  className?: string
  talentName?: string
  fullName?: string
}

export const TalentName = ({
  firstName,
  lastName,
  size,
  className,
  talentName,
  fullName,
}: TalentNameProps) => {
  return (
    <div
      className={twMerge(
        'flex text-primary-black group-hover:text-primary-red',
        size === 'xs' && 'text-xs',
        size === 'sm' && 'text-sm',
        size === 'm' && 'text-base',
        size === 'lg' && 'text-lg font-medium leading-6',
        className,
      )}
    >
      <span className='flex'>
        <span>{firstName && firstName}</span>
        <span>{lastName && lastName}</span>
        <span>{fullName && fullName}</span>
      </span>
      {talentName && talentName}
    </div>
  )
}
