import React, {
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react'
import { twJoin, twMerge } from 'tailwind-merge'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  CellContent,
  Checkbox,
  IconTab,
  VerticalTableText,
  TalentPersonCell,
  Button,
  FilterSearchButton,
  AddToExistingContent,
  AddTalentToListModal,
  DeleteModal,
  PaginationToolbar,
  PaginationChangeParams,
} from 'components'
import {
  AttributeType,
  ColumnsTalent,
  DirectionSort,
  SocialSearchFilters,
  SortOrderKey,
  TalentType,
  Urls,
} from 'types'
import { commonToastStyles } from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { useUpdateTelentListMutation } from 'store/api'

import {
  useActions,
  useClickOutsideComponent,
  useTypedSelector,
  MetricsType,
  SortQueryParams,
} from 'hooks'
import { DEFAULT_PAGE_SIZE } from 'constnants/talentTableData'
import { Colors, SizeTalentRows } from 'constnants'
import { rowsPerPageOptions } from 'constnants/talentTableData'
import {
  Compare,
  Star,
  SquarePlus,
  SquareMinus,
  Trash,
  Success,
} from 'components/ui/icons'
import { buildParams, formatBaseDate, getTalentCellValue } from 'utils'
import { getSocialSearchColumnDef } from './socialSearchFilters'
import AutoSizer from 'react-virtualized-auto-sizer'

interface BaseTableProps<T> {
  columns: ColumnsTalent[]
  data: T[]
  tableRow?: IconTab<string> | undefined
  hasCompareButtons?: boolean
  hasFooter?: boolean
  customFooter?: ReactNode
  contentTypeView?: AttributeType
  audienceFilter?: string
  metricsType?: MetricsType
  totalRows?: number
  listName?: string
  listId?: string
  setRefreshTalentMetrics?: (value: string) => void
  handleSortChange?: (sortParams: SortQueryParams) => void
  sortBy?: string
  sortOrder?: SortOrderKey
  error: string
  topTwoBox?: string
  selectedRows: string[]
  setSelectedRows: (value: string[]) => void
  socialSearchFilters: SocialSearchFilters
}

const isSortedByColumn = (column: ColumnsTalent, sortBy?: string) => {
  if (!sortBy) {
    return false
  }
  const isSortBySocial = sortBy.startsWith('SOCIAL')
  const isSortBySearch = sortBy.startsWith('SOCIAL_WIKIPEDIA')
  const isSocialColumn = column.key?.startsWith('SOCIAL')
  const isSearchColumn = column.key?.startsWith('SOCIAL_WIKIPEDIA')

  const isActiveSocialSearchColumn =
    (column.search && isSortBySearch && isSearchColumn) ||
    (column.social && isSortBySocial && isSocialColumn && !isSortBySearch)

  return sortBy === column.key || isActiveSocialSearchColumn
}

export const BaseTable = <T extends TalentType>({
  columns,
  data,
  tableRow,
  hasCompareButtons = true,
  hasFooter = true,
  customFooter,
  contentTypeView,
  listName,
  listId,
  setRefreshTalentMetrics,
  handleSortChange,
  sortBy = '',
  sortOrder = DirectionSort.ASC,
  metricsType,
  totalRows = 0,
  error,
  topTwoBox,
  selectedRows,
  setSelectedRows,
  socialSearchFilters = { social: [], search: [] },
}: BaseTableProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isOpenAddTalentToListModal, setIsOpenAddTalentToListModal] =
    useState(false)
  const [isOpenAddRemoveToListModal, setIsOpenAddRemoveToListModal] =
    useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [queryParameters, setQueryParameters] = useState('')
  const location = useLocation()
  const currentPath = location.pathname
  const { selectedTalents } = useTypedSelector(state => state.talentReducer)
  const navigate = useNavigate()
  const isLargeRows = tableRow?.value === SizeTalentRows.LARGE_ROWS
  const [updateTelentList, { isSuccess: isSuccessUpdateTelentList }] =
    useUpdateTelentListMutation()

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return data.slice(startIndex, endIndex)
  }, [data, currentPage, rowsPerPage])

  const totalPages = useMemo(
    () => Math.ceil(totalRows / rowsPerPage),
    [totalRows, rowsPerPage],
  )

  const { addSelectedTalentsIds } = useActions()

  const {
    ref: refExisting,
    isComponentVisible: isExistingComponentVisible,
    setIsComponentVisible: setIsExistingComponentVisible,
  } = useClickOutsideComponent(false)

  useEffect(() => {
    addSelectedTalentsIds(selectedRows)

    const searchParams = buildParams({ talentId: selectedRows })
    setQueryParameters(searchParams)

    return () => {
      addSelectedTalentsIds([])
    }
  }, [selectedRows])

  useEffect(() => {
    isSuccessUpdateTelentList &&
      setRefreshTalentMetrics &&
      setRefreshTalentMetrics(uuidv4())
  }, [isSuccessUpdateTelentList])

  const toggleSelectAll = () => {
    const newSelectAllChecked = !selectAllChecked
    setSelectAllChecked(newSelectAllChecked)
    if (newSelectAllChecked) {
      const newSelectedRows = paginatedData.map(
        row => row.talentPerson.talentId ?? '',
      )
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const sortSelectedRowsByIndex = (selectedRows: string[]): string[] => {
    return [...selectedRows].sort((a, b) => {
      const indexA = data.findIndex(item => item.talentPerson.talentId === a)
      const indexB = data.findIndex(item => item.talentPerson.talentId === b)
      return indexA - indexB
    })
  }

  const handleCheckboxChange = (rowId: string) => {
    const newSelected = selectedRows.includes(rowId)
      ? selectedRows.filter(id => id !== rowId)
      : [...selectedRows, rowId]

    const sortedSelected = sortSelectedRowsByIndex(newSelected)

    setSelectedRows(sortedSelected)
    setSelectAllChecked(newSelected.length === paginatedData.length)
  }

  const isSelectedSomeTalents =
    selectedRows.length === 0 || selectedRows.length > 4
  const isSelectCompare =
    (selectedRows.length > 0 &&
      selectedRows.length < 5 &&
      `Compare ${selectedRows.length}/4`) ||
    (selectedRows.length > 3 && `Compare`)
  const selectAmountTalent = !selectedRows.length ? 'Compare' : isSelectCompare
  const isRemoveTalenButtonDisabled = selectedRows.length === 0

  const handleCompareClick = () => {
    navigate(`${Urls.TALENT}${Urls.COMPARE}?${queryParameters}`)
  }

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage(params.page)
      setRowsPerPage(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  const rowData = customFooter ? data : paginatedData

  const socialFilterValue = socialSearchFilters.social[0]
  const searchFilterValue = socialSearchFilters.search[0]

  const isSortable = (title: string) => {
    if (socialFilterValue !== 'sentiment') return true
    return title !== 'All Followers'
  }

  return (
    <>
      {hasCompareButtons && (
        <>
          <div className='relative flex gap-1 px-[6px]'>
            {!listName && (
              <FilterSearchButton
                name='Add to existing List'
                isComponentVisible={isExistingComponentVisible}
                disabled={selectedTalents.length === 0}
                setIsComponentVisible={() => {
                  if (selectedTalents.length > 0) {
                    setIsExistingComponentVisible(prevState => !prevState)
                  }
                }}
                leftIcon={
                  <Star fill={Colors.BASE_ICON} width={16} height={16} />
                }
                ref={refExisting}
              >
                <AddToExistingContent
                  selectedTalents={selectedTalents}
                  close={() => setIsExistingComponentVisible(false)}
                />
              </FilterSearchButton>
            )}
            <Button
              kind='text'
              size='small'
              className={twJoin(
                'flex items-center gap-1 px-2 text-xs font-semibold text-primary-black',
                isSelectedSomeTalents && 'opacity-50',
              )}
              disabled={isSelectedSomeTalents}
              onClick={handleCompareClick}
            >
              <Compare fill={Colors.BASE_ICON} width={16} height={16} />
              {selectAmountTalent}
            </Button>
          </div>
          {listId && (
            <div className='flex px-[6px] '>
              <Button
                kind='text'
                size='small'
                className={twJoin(
                  'flex items-center gap-1 px-2 text-xs font-semibold text-primary-black',
                )}
                disabled={false}
                onClick={() => {
                  setIsOpenAddTalentToListModal(!!listId)
                }}
              >
                <SquarePlus fill={Colors.BASE_ICON} width={16} height={16} />
                Add Talent
              </Button>
              <Button
                kind='text'
                size='small'
                className={twJoin(
                  'flex items-center gap-1 px-2 text-xs font-semibold text-primary-black',
                  isRemoveTalenButtonDisabled && 'opacity-50',
                )}
                disabled={isRemoveTalenButtonDisabled}
                onClick={() => {
                  setIsOpenAddRemoveToListModal(true)
                }}
              >
                <SquareMinus fill={Colors.BASE_ICON} width={16} height={16} />
                Remove Talent
              </Button>
            </div>
          )}
        </>
      )}
      <div className='flex flex-auto'>
        <AutoSizer disableWidth className='flex flex-auto'>
          {({ height }) => (
            <div className='flex-grow overflow-auto' style={{ height }}>
              <table className='table-talent'>
                <thead className='z-10 h-28 bg-grey-3'>
                  <tr className={twMerge('relative')}>
                    <th className={'pb-5 align-bottom'}>
                      <Checkbox
                        kind='small'
                        checked={selectAllChecked}
                        onChange={toggleSelectAll}
                      />
                    </th>
                    <th className='p-2 pb-5 text-left align-bottom text-xss text-primary-grey hover:cursor-pointer'>
                      Rank
                    </th>
                    {columns.map((column, i) => {
                      const isActiveColumn = isSortedByColumn(column, sortBy)
                      const changeSortBy =
                        sortOrder === DirectionSort.ASC
                          ? DirectionSort.DESC
                          : DirectionSort.ASC
                      const socialSearchDef = column.social
                        ? getSocialSearchColumnDef(socialFilterValue)
                        : column.search
                          ? getSocialSearchColumnDef(searchFilterValue)
                          : null

                      return (
                        <th
                          key={`${column.accessor}-${i}`}
                          className={twMerge(
                            'hover:cursor-pointer',
                            isActiveColumn && 'selected-column',
                            (i === 0 || i === 1) && 'pb-5 align-bottom',
                          )}
                          onClick={() => {
                            isSortable(column.title) &&
                              handleSortChange &&
                              handleSortChange({
                                sortBy: socialSearchDef
                                  ? socialSearchDef.key
                                  : (column.key ?? 'AWARENESS'),
                                sortOrder: isActiveColumn
                                  ? changeSortBy
                                  : DirectionSort.DESC,
                                metricsType: metricsType ?? 'COUNT',
                                topTwoBox: topTwoBox ?? 'topBox',
                              })
                          }}
                        >
                          <VerticalTableText
                            vertical={column.horizontal}
                            title={
                              socialSearchDef
                                ? socialSearchDef.title
                                : column.title
                            }
                            selectItem={isActiveColumn}
                            directionSort={sortOrder}
                            hasIcon
                            containerClass={twJoin(
                              column.key === 'TALENT_NAME' && 'justify-start',
                            )}
                          />
                        </th>
                      )
                    })}
                  </tr>
                </thead>

                {error ? (
                  <tbody>
                    <tr className='!bg-primary-white'>
                      <td
                        colSpan={columns.length + 2}
                        className='text-xs text-primary-black hover:!bg-transparent'
                      >
                        {error}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className='overflow-auto'>
                    {rowData.map((row, rowIndex) => {
                      return (
                        <tr key={`${row.talentPerson.talentId}-${rowIndex}`}>
                          <td
                            className={twMerge(
                              'bg-primary-white pl-2',
                              isLargeRows && '',
                            )}
                          >
                            <Checkbox
                              checked={selectedRows.includes(
                                row.talentPerson.talentId ?? '',
                              )}
                              onChange={() => {
                                handleCheckboxChange(
                                  row.talentPerson.talentId ?? '',
                                )
                              }}
                              kind='small'
                            />
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white text-primary-grey',
                            )}
                          >
                            {row.srNumber}
                          </td>
                          <td className='items-center bg-primary-white text-primary-black'>
                            <TalentPersonCell
                              isLargeRows={isLargeRows}
                              talent={row['talentPerson']}
                            />
                          </td>
                          <td className='bg-primary-white text-primary-grey'>
                            {currentPath === Urls.TALENT ||
                            currentPath.includes('/talent/listDetails')
                              ? row['surveyDate' as keyof T] &&
                                formatBaseDate(
                                  row['surveyDate' as keyof T] as string,
                                )
                              : formatBaseDate(row['lastFielding'])}
                          </td>
                          {columns.slice(2).map(column => {
                            const isActiveColumn = isSortedByColumn(
                              column,
                              sortBy,
                            )
                            return (
                              <td
                                key={column.accessor}
                                className={twMerge(
                                  'bg-primary-white',
                                  isActiveColumn &&
                                    '!bg-primary-red text-primary-white',
                                  column.social && '!p-0',
                                )}
                              >
                                <CellContent
                                  value={getTalentCellValue(
                                    row,
                                    column,
                                    socialSearchFilters,
                                  )}
                                  isLargeRows={isLargeRows}
                                  contentTypeView={contentTypeView}
                                />
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </AutoSizer>
      </div>
      {hasFooter && (
        <div>
          {customFooter ? (
            <>{customFooter}</>
          ) : (
            <PaginationToolbar
              totalRows={totalRows}
              totalPages={totalPages}
              currentPage={currentPage}
              currentPageSize={rowsPerPage}
              pageSizeOptions={rowsPerPageOptions}
              onChange={handlePagination}
            />
          )}
        </div>
      )}
      {!!listId && (
        <>
          <AddTalentToListModal
            isOpen={isOpenAddTalentToListModal}
            handleClose={() => setIsOpenAddTalentToListModal(false)}
            maxWidth='600px'
            handleGetSelectedTalents={selectedTalentsIds => {
              updateTelentList({
                listId,
                talentIdsToAdd: [...selectedTalentsIds],
              })
                .unwrap()
                .then(() => {
                  toast.success(<>New Talent(s) added to list</>, {
                    icon: <Success width={24} height={24} />,
                    ...commonToastStyles,
                  })
                })
                .catch(error => {
                  toast.error(
                    <>
                      Failed to add New Talent list to <b>{listName}</b>
                    </>,
                  )
                })
            }}
          />
          <DeleteModal
            isOpen={isOpenAddRemoveToListModal}
            handleClose={() => setIsOpenAddRemoveToListModal(false)}
            title='Remove talent from this list?'
            description='Don’t worry, you can always add the removed talent back.'
            deleteTitle='Remove'
            onDelete={() => {
              updateTelentList({
                listId,
                talentIdsToRemove: selectedTalents,
              })
                .unwrap()
                .then(() => {
                  setSelectedRows([])
                  toast.success(<>Talent(s) removed from list</>, {
                    icon: (
                      <Trash
                        fill={Colors.ICON_NEGATIVE_RED}
                        width={24}
                        height={24}
                      />
                    ),
                    ...commonToastStyles,
                  })
                })
                .catch(() => {
                  toast.error(<>Failed to remove Talent from list</>)
                })
              setIsOpenAddRemoveToListModal(false)
            }}
          />
        </>
      )}
    </>
  )
}
