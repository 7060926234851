import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface TalentRightItemProps {
  icon: ReactNode
  title: string
  kind: 'base' | 'up' | 'down'
  handleOpenClick: () => void
}

export const TalentRightItem = ({
  icon,
  title,
  kind,
  handleOpenClick,
}: TalentRightItemProps) => {
  return (
    <div
      className={twMerge(
        'flex h-[72px] w-[72px] transform flex-col items-center justify-center gap-0.5 bg-primary-white transition-all duration-300 hover:cursor-pointer hover:opacity-90',
        kind === 'base' && 'rounded',
        kind === 'up' && 'rounded-t',
        kind === 'down' && 'rounded-b',
      )}
      onClick={handleOpenClick}
    >
      <div>{icon}</div>
      <span className='text-center text-xs uppercase text-primary-grey'>
        {title}
      </span>
    </div>
  )
}
