import { useMemo } from 'react'

interface UseProgressBarMessagesProps {
  value: number
  max: number
}

export const useProgressBarMessages = ({
  value,
  max,
}: UseProgressBarMessagesProps) => {
  const percentage = useMemo(() => (value / max) * 100, [value, max])
  const maxLengthText =
    'You may want to reduce the length of this logline to successfully compare against the others.'
  const bestResultsText = 'Stay in this range for the best results'

  const isMaxValue = value === max
  const isBestAmountValue = percentage >= 60

  const maxLengthMessage = isMaxValue ? maxLengthText : null
  const bestResultsMessage = isBestAmountValue ? bestResultsText : null

  return { maxLengthMessage, bestResultsMessage, percentage }
}
