import { api } from './api'
import { UserPermission, UserType } from 'types'

export const myUserApi = api.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<UserType, void>({
      query: () => ({
        url: '/user/profile',
        method: 'GET',
      }),
      providesTags: ['MyUser'],
    }),
    getUserProjects: builder.query<
      { company_project_id: number; name: string },
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `/users/${userId}/projects`,
        method: 'GET',
      }),
      providesTags: ['MyUser'],
    }),
    updateUserProjects: builder.mutation<
      { userId: string },
      { companyProjectId: number; userId: string }
    >({
      query: ({ companyProjectId, userId }) => ({
        body: { companyProjectId },
        url: `/users/${userId}/projects`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'MyUser' }],
    }),
    deleteUserProject: builder.mutation<
      { userId: string },
      { companyProjectId: number; userId: string }
    >({
      query: ({ companyProjectId, userId }) => ({
        body: { userId },
        url: `/users/${userId}/projects/${companyProjectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'MyUser' }],
    }),
    updatedUser: builder.mutation<
      {
        firstName: string
        lastName: string
        role: string
        phone: number
        userType: string
        accessLevel: string
        status: string
        lastLoginDate: string
        moduleAccessIds: number[]
      },
      {
        id: string
        userData: {
          firstName?: string
          lastName?: string
          role?: string
          phone?: number
          userType?: string
          accessLevel?: string
          status?: string
          lastLoginDate?: string | Date
          moduleAccessIds?: number[]
        }
      }
    >({
      query: updateUser => ({
        body: { ...updateUser.userData },
        url: `/user/update/${updateUser.id}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyUser' }],
    }),
    updatedCurrentUser: builder.mutation<
      {
        firstName: string
        lastName: string
        email: string
      },
      {
        id: string
        userData: {
          firstName: string
          lastName: string
          email: string
        }
      }
    >({
      query: updateUser => ({
        body: { ...updateUser.userData },
        url: `/admin/${updateUser.id}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyUser' }],
    }),
    inviteUser: builder.mutation<
      UserType,
      {
        firstName: string
        lastName: string
        name: string
        email: string
        userType: string
        accessLevel: string
        projectIds: number[]
        moduleAccessIds: number[]
      }
    >({
      query: ({
        firstName,
        lastName,
        name,
        email,
        userType,
        accessLevel,
        projectIds,
        moduleAccessIds,
      }) => ({
        body: {
          firstName,
          lastName,
          name,
          email,
          userType,
          accessLevel,
          projectIds,
          moduleAccessIds,
        },
        url: `/user/invite`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'MyUser' }],
    }),
    deleteUser: builder.mutation<
      { userId: string },
      { userId: string | number | null }
    >({
      query: ({ userId }) => ({
        url: `/admin/softDelete/${userId}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyUser' }],
    }),
    getUserPermission: builder.query<UserPermission, { userId: string }>({
      query: ({ userId }) => ({
        url: `/user/${userId}/permission`,
        method: 'GET',
      }),
      providesTags: ['MyUser'],
    }),
  }),
})

export const {
  useGetCurrentUserQuery,
  useGetUserProjectsQuery,
  useUpdateUserProjectsMutation,
  useDeleteUserProjectMutation,
  useUpdatedUserMutation,
  useInviteUserMutation,
  useDeleteUserMutation,
  useUpdatedCurrentUserMutation,
  useGetUserPermissionQuery,
} = myUserApi
