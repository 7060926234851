import { useCallback, useMemo } from 'react'
import {
  TestingManagementTableBody,
  TestingManagementTableHeader,
} from './components'
import { TestingManagementTableProps } from 'types'
import { managementRowsPerPageOptions } from 'constnants'
import { Colors } from '../../constnants'
import { Folder } from '../ui/icons'
import {
  PaginationChangeParams,
  PaginationToolbar,
} from 'components/FilmTrackingTable'

export const TestingManagementTable = ({
  columns,
  data,
  onSort,
  onSortOrder,
  sortingBY,
  setCurrentPage,
  currentPage = 1,
  rowsPerPage = 50,
  setRowsPerPage,
  totalTestsCount = 0,
  isLoading,
  setTestingItemOpen,
}: TestingManagementTableProps) => {
  const totalRows = totalTestsCount
  const totalPages = useMemo(
    () => Math.ceil(totalRows / rowsPerPage),
    [totalRows, rowsPerPage],
  )

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage?.(params.page)
      setRowsPerPage?.(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  return (
    <div className='h-[calc(100vh_-_125px)] w-full overflow-auto py-3 pb-12 xl:h-[calc(100vh_-_114px)]'>
      {isLoading ? (
        <>
          <table className='company-table'>
            <TestingManagementTableHeader
              columns={columns}
              onSort={onSort}
              onSortOrder={onSortOrder}
              sortingBY={sortingBY}
            />
            <TestingManagementTableBody
              data={data}
              columns={columns}
              isLoading={isLoading}
            />
          </table>
        </>
      ) : totalRows === 0 ? (
        <>
          <table className='company-table'>
            <TestingManagementTableHeader columns={columns} />
          </table>
          <div className='mx-auto flex h-full w-[505px] flex-col items-center justify-center '>
            <Folder fill={Colors.GRAY_600} width={48} height={48} />
            <div className='my-4 text-center text-primary-black'>
              <p className='text-lg font-medium'>
                {'You haven’t submitted a any Testing yet'}
              </p>
              <p className='text-sm'>
                {'Create your first testing to get started.'}
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <table className='company-table'>
            <TestingManagementTableHeader
              columns={columns}
              onSort={onSort}
              onSortOrder={onSortOrder}
              sortingBY={sortingBY}
            />
            <TestingManagementTableBody
              data={data}
              columns={columns}
              setTestingItemOpen={setTestingItemOpen}
            />
          </table>
          <div className='fixed bottom-0 left-3 right-0'>
            <PaginationToolbar
              totalRows={totalRows}
              totalPages={totalPages}
              currentPage={currentPage}
              currentPageSize={rowsPerPage}
              pageSizeOptions={managementRowsPerPageOptions}
              onChange={handlePagination}
            />
          </div>
        </>
      )}
    </div>
  )
}
