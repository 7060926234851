import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssignedUser, MyProjectState, RoleProject } from 'types'

const initialState: { selectedTalents: string[] } = { selectedTalents: [] }

export const talentSlice = createSlice({
  name: 'talent',
  initialState,
  reducers: {
    addSelectedTalentsIds: (state, action: PayloadAction<string[]>) => {
      state.selectedTalents = state.selectedTalents = Array.from(
        new Set([...action.payload]),
      )
    },
  },
})

export const { actions: talentActions, reducer: talentReducer } = talentSlice
