import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import {
  useSaveTelentListMutation,
  useEditTalentsListMutation,
} from 'store/api'
import { OptionType } from '../types'
import { useTypedSelector } from 'hooks'
import { toast } from 'react-toastify'
import { commonToastStyles } from 'utils'

export const useTalentsList = (
  options?: OptionType[],
  onSelectTalentsList?: (talentList: OptionType) => void,
) => {
  const { selectedTalents } = useTypedSelector(state => state.talentReducer)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('')
  const [saveTelentList] = useSaveTelentListMutation()

  const [editTalentsList] = useEditTalentsListMutation()

  const handleSaveTelentList = (
    listName: string,
    projectId?: number,
    roleId?: string,
    successText: React.ReactNode = `New Talent list added to My Lists: ${listName}`,
  ) => {
    const name = listName
    const talentIds = selectedTalents

    saveTelentList({
      name,
      talentIds,
      projectId,
      roleId,
    })
      .unwrap()
      .then(() => {
        toast.success(successText, {
          ...commonToastStyles,
        })
      })
      .catch(error => {
        toast.error(`Failed to add New Talent list to My Lists: ${listName}`)
      })
  }

  const handleEditTalentsList = async (
    id: string,
    name: string,
    currentListId?: string,
    toastText: React.ReactNode = `Renamed  talent list `,
    noToast = false,
  ) => {
    return editTalentsList({
      id,
      name,
    })
      .unwrap()
      .then(res => {
        if (!noToast) {
          toast.success(toastText, { ...commonToastStyles })
        }

        if (currentListId === id && onSelectTalentsList && res) {
          onSelectTalentsList({
            id: res?.list_id || '',
            value: res?.list_name || '',
            label: res?.list_name || '',
          })
        }
      })
  }

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedValue(searchTerm)
    }, 750)

    return () => clearTimeout(delayInputTimeoutId)
  }, [searchTerm])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target as HTMLInputElement) {
      setSearchTerm(e.target.value)
    }
  }

  const filteredOptions = useMemo(
    () =>
      options?.filter(option =>
        option.label
          .toLowerCase()
          .replace(/\s/g, '')
          .includes(searchTerm.toLowerCase()),
      ) || [],
    [searchTerm, options, setSearchTerm],
  )

  return {
    searchTerm,
    handleEditTalentsList,
    handleSaveTelentList,
    handleSearchChange,
    filteredOptions,
  }
}
