import { uniqBy } from 'lodash'

// Define the type for company data
type Company = {
  abbreviation: string
  company_id: string
  company_key: string
  company_name: string
  company_type: string
  distributor_type: string
}

// Define the type for the desired structure
type CompanyOption = {
  id: string
  value: string
  label: string
}

type CompanyGroup = {
  id: string
  name: string
  hasAll?: boolean
  options?: CompanyOption[]
  colorSelect?: string
  colorToggle?: string
  isOther?: boolean
  hasToggle?: boolean
}
type Companies = {
  distributors: CompanyGroup[]
  tvDistributors: CompanyGroup[]
}

// Function to build the required structure for both Major and Minor distributors
export const buildCompanyGroups = (dataArray: Company[]): Companies => {
  // Function to map companies to the desired structure
  const mapCompanies = (companies: Company[]): CompanyOption[] =>
    companies.map(company => ({
      id: `${company.company_name}`,
      value: `${company.company_name}`,
      label: `${company.company_name}`,
    }))
  const companyGroups = dataArray.reduce(
    (acc, company) => {
      // Split company types and categorize accordingly
      company.company_type.split('/').forEach(type => {
        if (type === 'Broadcast' || type === 'Cable') {
          // Group as Broadcast (including Cable companies)
          acc.broadcastCompanies.push(company)
        } else if (type === 'Streamer') {
          // Group as Streamer
          acc.streamerCompanies.push(company)
        } else if (type === 'Distributor') {
          // Further categorize as Major or Minor distributor
          if (company.distributor_type === 'Major') {
            acc.majorCompanies.push(company)
          }
          if (company.distributor_type === 'Minor') {
            acc.minorCompanies.push(company)
          }
        }
      })

      return acc
    },
    {
      majorCompanies: [] as Company[],
      minorCompanies: [] as Company[],
      broadcastCompanies: [] as Company[],
      streamerCompanies: [] as Company[],
    },
  )
  // Filter companies by distributor type
  console.warn('companyGroups', companyGroups)
  const majorCompanies = uniqBy(companyGroups.majorCompanies, 'company_id')
  const minorCompanies = uniqBy(companyGroups.minorCompanies, 'company_id')
  const broadcastCompanies = uniqBy(
    companyGroups.broadcastCompanies,
    'company_id',
  )
  const streamerCompanies = uniqBy(
    companyGroups.streamerCompanies,
    'company_id',
  )
  // Return the grouped structure for both Major and Minor distributors
  return {
    distributors: [
      {
        id: 'major',
        name: 'Major',
        options: mapCompanies(majorCompanies),
        hasAll: true,
        hasToggle: true,
        colorSelect: 'bg-yellow-2',
        colorToggle: 'bg-yellow-5 ',
      },
      {
        id: 'minor',
        name: 'Minor',
        options: mapCompanies(minorCompanies),
        hasAll: true,
        hasToggle: true,
        colorSelect: 'bg-yellow-2',
        colorToggle: 'bg-yellow-5 ',
      },
      {
        id: 'other',
        name: 'Other',
        colorSelect: 'bg-yellow-2',
        colorToggle: 'bg-yellow-5 ',
        isOther: true,
        hasToggle: true,
      },
    ],
    tvDistributors: [
      {
        id: 'streamer',
        name: 'Streamers',
        colorSelect: 'bg-red-10',
        colorToggle: 'bg-red-10',
        options: mapCompanies(streamerCompanies),
        hasToggle: true,
        hasAll: true,
      },
      {
        id: 'broadcast',
        name: 'Cable/Broadcast',
        colorSelect: 'bg-red-10/50',
        colorToggle: 'bg-red-10/50',
        options: mapCompanies(broadcastCompanies),
        hasToggle: true,
        hasAll: true,
      },
    ],
  }
}

export const buildFilmGroups = (dataArray: Company[]): CompanyGroup => {
  return {
    id: 'allGroups',
    name: 'All Groups',
    hasAll: true,
    options: dataArray?.map(group => ({
      id: `${group.film_group_id}`,
      value: `${group.film_group_id}`,
      label: group.film_group_name,
    })),
  }
}
export const buildTvGenres = (dataArray: Company[]): CompanyGroup => {
  return {
    id: 'allGenres',
    name: 'All Genres',
    hasAll: true,
    options: dataArray?.map(genres => ({
      id: genres?.genre_id?.toString(),
      value: genres.genre_id?.toString(),
      label: genres.genre_name,
    })),
  }
}
export const buildFilmsGenres = (dataArray: Company[]): CompanyGroup => {
  return {
    id: 'allFilmsGenres',
    name: 'All Genres',
    hasAll: true,
    options: dataArray?.map(genres => ({
      id: genres?.genre_id?.toString(),
      value: genres.genre_id?.toString(),
      label: genres.genre_name,
    })),
  }
}
export const buildTheaterReleaseTypes = (
  dataArray: Company[],
): CompanyGroup => {
  const getReleaseTypeLabel = (releaseType: string) => {
    if (releaseType.toLowerCase().includes('wide')) {
      return 'Wide Release'
    }
    if (releaseType.toLowerCase().includes('limited')) {
      return 'Limited Release'
    }
    return releaseType
  }
  return {
    id: 'wideLimited',
    name: 'Wide & Limited',
    hasAll: true,
    options: dataArray
      ?.filter(release => release?.release_type_name?.startsWith('FilmRelease'))
      .map(release => ({
        id: release?.release_type_id?.toString() || '',
        value: release?.release_type_id?.toString() || '',
        label: getReleaseTypeLabel(release?.release_type_name || ''),
      })),
  }
}

export const buildTvReleaseTypes = (dataArray: Company[]): CompanyGroup => {
  return {
    id: 'allTVTypes',
    name: 'All TV Types',
    hasAll: true,
    options: dataArray
      ?.filter(
        release => !release?.release_type_name?.startsWith('FilmRelease'),
      )
      .map(release => ({
        id: release?.release_type_id?.toString() || '', // Assuming both have `release_type_id`
        value: release?.release_type_id?.toString() || '', // Assuming both have `release_type_id`
        label: release?.release_type_name || '', // Assuming both have `release_type_name`
      })),
  }
}
