import React, { InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import { Colors } from '../../../constnants'
import { TooltipIcon } from '../icons'
import { Tooltip } from 'react-tooltip'

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  wrapperClass?: string
  inputClass?: string
  labelClass?: string
  slug?: string
  description?: string
  tooltipText?: string
  alignItems?: 'center' | 'start'
}

export const RadioButton = ({
  id,
  name,
  label,
  checked,
  disabled,
  inputClass,
  wrapperClass,
  defaultChecked,
  labelClass,
  slug,
  description,
  tooltipText,
  alignItems = 'center',
  ...rest
}: RadioButtonProps) => {
  return (
    <label
      htmlFor={`${slug}_${id}`}
      className={twMerge(
        'flex-start flex flex-col bg-primary-white transition-all duration-150 ease-linear hover:cursor-pointer',
        disabled && 'cursor-default',
        wrapperClass,
      )}
    >
      <div
        className={twMerge(
          'relative flex flex-row space-x-2',
          alignItems === 'center' ? 'items-center' : 'items-start',
        )}
      >
        <input
          type='radio'
          name={name}
          id={`${slug}_${id}`}
          defaultChecked={defaultChecked}
          checked={checked}
          disabled={disabled}
          className={twMerge(
            'peer h-4 w-4 cursor-pointer appearance-none rounded-full border-2 border-primary-black/20 ring-0 ring-offset-0',
            'hover:border-primary-black/40 hover:bg-primary-black/5',
            'checked:border-red-9 checked:hover:border-red-9 checked:disabled:border-primary-grey/30',
            'disabled:cursor-default disabled:border-primary-grey/30 disabled:hover:bg-primary-white disabled:checked:active:border-primary-grey/30',
            inputClass,
            alignItems === 'start' && 'mt-0.5',
          )}
          value={id}
          {...rest}
        />
        <div
          className={twMerge(
            'absolute top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-full bg-primary-white',
            'peer-hover:bg-primary-black/5 peer-checked:peer-focus:h-2 peer-checked:peer-focus:w-2 peer-checked:peer-focus:bg-red-9 peer-disabled:bg-primary-grey',
            'peer-checked:bg-primary-red peer-checked:peer-focus:peer-hover:bg-primary-red peer-disabled:bg-primary-white',
            'peer-checked:h-2 peer-checked:w-2 peer-checked:peer-hover:border-red-9 peer-checked:peer-hover:bg-red-10',
            'peer-checked:peer-disabled:h-2 peer-checked:peer-disabled:w-2 peer-checked:peer-disabled:bg-primary-grey/30',
            inputClass,
            alignItems === 'start' &&
              'top-1 translate-y-0 peer-checked:top-1.5',
          )}
        />
        <span
          className={twMerge(
            'text-sm text-primary-grey',
            labelClass,
            tooltipText && 'flex items-center gap-1',
          )}
        >
          {label}
          {tooltipText && (
            <>
              <TooltipIcon
                fill={Colors.BASE_ICON}
                width={16}
                height={16}
                data-tooltip-id={id}
              />
              <Tooltip
                id={id}
                content={tooltipText}
                place='bottom-start'
                className='z-50 whitespace-pre-line'
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  padding: '4px 8px',
                }}
              />
            </>
          )}
          {description && (
            <span className='block text-xs text-primary-grey'>
              {description}
            </span>
          )}
        </span>
      </div>
    </label>
  )
}
