import Hotjar from '@hotjar/browser'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const HOTJAR_ID = 5090043
const HOTJAR_VERSION = 6

const allowedPatterns = [
  /^\/$/,
  /^\/talent(\/.*)?$/,
  /^\/film(\/.*)?$/,
  /^\/conceptTesting$/,
  /^\/roleTesting$/,
  /^\/assetTesting$/,
]

let isInitialized = false

export const useHotjar = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const isAllowedPath = allowedPatterns.some(regex => regex.test(pathname))

    if (!isAllowedPath) {
      return
    }

    if (!isInitialized) {
      isInitialized = Hotjar.init(HOTJAR_ID, HOTJAR_VERSION, { debug: true })
    }

    if (Hotjar.isReady()) {
      Hotjar.stateChange(pathname)
    }
  }, [pathname])
}
