import React, {
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { IconProps } from 'types'
import { IconButton } from '../IconButton'
import { Colors } from 'constnants'
import { EyeSlash } from 'components/ui/icons'
import './InputAuth.css'

export interface InputAuthProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean
  error?: string
  label?: ReactNode
  inputClassName?: string
  labelClassName?: string
  wrapperClassName?: string
  containerWrapperClassName?: string
  errorClassName?: string
  rightIcon?: ReactElement<IconProps>
  leftIcon?: ReactElement<IconProps>
}

export const InputAuth = ({
  id,
  type,
  disabled,
  label,
  error,
  isError,
  rightIcon,
  leftIcon,
  inputClassName,
  labelClassName,
  wrapperClassName,
  containerWrapperClassName,
  errorClassName,
  ...rest
}: InputAuthProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const isPasswordType = type === 'password'
  const toggleType = isShowPassword ? 'text' : 'password'

  const toggleShowPassword = () => {
    if (!disabled) {
      setIsShowPassword(prev => !prev)
    }
  }

  const focusInput = () => {
    ref.current?.focus()
  }

  return (
    <div className={twMerge('mb-4 flex flex-col', containerWrapperClassName)}>
      {label && (
        <label
          htmlFor={id}
          className={twMerge(
            'mb-2 text-sm font-semibold text-primary-black',
            labelClassName,
          )}
        >
          {label}
        </label>
      )}

      <div
        className={twMerge(
          'relative flex items-center rounded-md bg-white focus-within:ring-2 focus-within:ring-blue-500',
          disabled && 'cursor-not-allowed bg-gray-100',
          isError ? 'border-red-500' : 'border-gray-300',
          wrapperClassName,
        )}
      >
        {leftIcon && (
          <IconButton className='mr-2' disabled={disabled} onClick={focusInput}>
            {leftIcon}
          </IconButton>
        )}

        <input
          {...rest}
          ref={ref}
          id={id}
          type={isPasswordType ? toggleType : type}
          disabled={disabled}
          className={twMerge(
            'w-full bg-transparent px-2 text-sm leading-4 outline-none', // Remove h-4 to allow custom class to take precedence
            isError && 'placeholder-red-500',
            inputClassName,
          )}
        />

        {isPasswordType && (
          <IconButton className='ml-2' onClick={toggleShowPassword}>
            {isShowPassword ? <EyeSlashIcon /> : <EyeSlashIcon />}
          </IconButton>
        )}

        {rightIcon && (
          <IconButton className='ml-2' disabled={disabled} onClick={focusInput}>
            {rightIcon}
          </IconButton>
        )}
      </div>

      {isError && error && (
        <span className={twMerge('mt-1 text-xs text-red-500', errorClassName)}>
          {error}
        </span>
      )}
    </div>
  )
}

// Dummy icons for show/hide password
const EyeSlashIcon = () => (
  <EyeSlash fill={Colors.BASE_ICON} width={16} height={16} />
)
