import { ColumnsProjectTable } from 'types'
import { twMerge } from 'tailwind-merge'
import { Export, File } from 'components/ui/icons'
import { Colors } from 'constnants'
import pdfFile from 'assets/pdf/sample.pdf'
import { formatDate } from 'utils'

interface TestingTableCellProps {
  column: ColumnsProjectTable
  row: {
    id: string | number
    testName: string
    submitDate: string
    completeDate: string
    status: string
    pdfLink: boolean | string
  }
  isLoading?: boolean
}

export const TestingTableCell = ({
  column,
  row,
  isLoading = false,
}: TestingTableCellProps) => {
  switch (column.value) {
    case 'testName':
      return (
        <span
          className={twMerge(
            'flex text-xs font-medium text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          <File
            fill={Colors.BASE_ICON}
            width={16}
            height={16}
            className='mr-2'
          />
          {row.testName}
        </span>
      )
    case 'status':
      return (
        <span
          className={twMerge(
            'text-xs font-medium capitalize',
            row.status === 'complete' ? 'text-green-6' : 'text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.status}
        </span>
      )
    case 'pdf':
      return (
        <span
          className={twMerge(
            'flex',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          <span className='hover:cursor-pointer'>
            {typeof row.pdfLink === 'string' ? (
              <a href={pdfFile} download>
                <Export fill={Colors.BASE_ICON} />
              </a>
            ) : (
              row.pdfLink && <Export fill={Colors.BASE_ICON} />
            )}
          </span>
        </span>
      )
    case 'submitDate':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {formatDate(row.submitDate, 'MM/DD/YYYY')}
        </span>
      )
    case 'completeDate':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {formatDate(row.completeDate, 'MM/DD/YYYY')}
        </span>
      )
    default:
      return <span></span>
  }
}
