import { twMerge } from 'tailwind-merge'
import { OptionType } from 'types'
import { RadioButton } from 'components'

export type SelectMenuProps<TOptionTypeValue = string> = {
  options: OptionType<TOptionTypeValue>[] | undefined
  isOpen: boolean
  handleClick: (option: OptionType<TOptionTypeValue>) => void
  wrapperOptionStyles: string
  isError?: boolean
  optionClass?: string
  selectedValue: TOptionTypeValue | undefined
  hasRadioButton?: boolean
  alignItems?: 'center' | 'start'
  optionsHidden?: TOptionTypeValue[]
}

export const SelectMenu = <TOptionTypeValue,>({
  isOpen,
  wrapperOptionStyles,
  handleClick,
  options,
  optionClass,
  selectedValue,
  hasRadioButton = true,
  alignItems = 'center',
  optionsHidden,
}: SelectMenuProps<TOptionTypeValue>) => {
  if (!options?.length) return null
  return (
    <>
      {isOpen && (
        <ul
          className={twMerge(
            'mt-0.5 min-w-fit rounded border-primary-black/10 p-2 shadow',
            wrapperOptionStyles,
          )}
        >
          {options?.map((option, i) => {
            return (
              <li
                key={`${option.value}_${i}`}
                className={twMerge(
                  'group flex w-full flex-row items-center text-sm leading-4 text-primary-grey hover:cursor-pointer',
                  optionClass,
                )}
                onClick={() => handleClick(option)}
              >
                {hasRadioButton ? (
                  <RadioButton
                    label={option.label}
                    defaultChecked={selectedValue === option.value}
                    wrapperClass='group-hover:bg-primary-black/5 p-1 rounded w-full min-w-max'
                    description={option.description || ''}
                    alignItems={alignItems}
                  />
                ) : (
                  <span
                    className={twMerge(
                      'w-full rounded px-2 py-1 text-sm text-primary-black hover:bg-primary-black/10',
                      optionsHidden?.includes(option.value) && 'hidden',
                    )}
                  >
                    {option.label}
                    {option.description && (
                      <span className='block text-xs text-primary-grey'>
                        {option.description}
                      </span>
                    )}
                  </span>
                )}
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}
