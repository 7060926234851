import { Tooltip } from 'react-tooltip'
import { TooltipIcon } from 'components/ui/icons'
import { Colors } from 'constnants'

export const Header = () => (
  <p className='flex gap-1 px-2 pb-2 pt-1 text-sm font-medium text-primary-black'>
    <span>The Box Office Universe</span>
    <TooltipIcon
      fill={Colors.BASE_ICON}
      width={20}
      height={20}
      data-tooltip-id={'box-office-universe-tooltip'}
    />
    <Tooltip
      id={'box-office-universe-tooltip'}
      place='top'
      variant={'dark'}
      style={{ width: '700px', maxWidth: '100vw' }}
    >
      <>
        <p>
          What is the Box Office Universe (BOU)? It is a list of comp titles
          based on various factors including the month of release, rating,
          genre, theater count, etc. The variables used in selecting the BOU can
          change from film to film. The ones for this film are listed below in
          red. The BOU provides context on where the subject film might perform
          at the high and low end of the box office. It’s also helpful in
          identifying the sweet spot where most of these films perform
        </p>

        <br />
        <p>OW: OPENING WEEKEND.</p>
        <p>
          OW - ADJ: OPENING WEEKEND ADJUSTED TO AVG. TICKET PRICE IN YEAR OF
          RELEASE (based on NATO figures)
        </p>
      </>
    </Tooltip>
  </p>
)
