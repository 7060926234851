import { ChangeEvent, useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'

import {
  Button,
  Input,
  Select,
  TypeSelectorTitle,
  TitleBlockStep,
  RunTime,
} from 'components'
import { AdditionalDetails, OptionFilter } from 'types'
import { Calendar } from 'components/ui/icons'
import {
  Colors,
  currentTime,
  currentYear,
  FROM_YEAR,
  releasePatternMenu,
  yearsAhead,
} from 'constnants'
import { useActions, useClickOutsideComponent, useTypedSelector } from 'hooks'
import { formatDate } from 'utils'

interface AdditionalDetailsStepProps {
  nextStep: () => void
  setStepCompleted: () => void
}

export const AdditionalDetailsStep = ({
  nextStep,
  setStepCompleted,
}: AdditionalDetailsStepProps) => {
  const { additionalDetails } = useTypedSelector(state => state.projectReducer)
  const { setAdditionalDetails, resetAdditionalDetails } = useActions()
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const [selectedDate, setSelectedDate] = useState<Date | string | undefined>(
    additionalDetails.releaseDate ?? '',
  )
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isFocusedDirector, setIsFocusedDirector] = useState(false)
  const [isFocusedWriter, setIsFocusedWriter] = useState(false)

  useEffect(() => {
    validateForm()
  }, [additionalDetails])

  const validateForm = () => {
    const {
      runTime,
      releaseDate,
      releasePattern,
      expectedDirector,
      expectedWriter,
    } = additionalDetails
    const isValid =
      runTime.hours ||
      runTime.minutes ||
      releaseDate ||
      releasePattern ||
      expectedDirector ||
      expectedWriter
    setIsButtonDisabled(!isValid)
  }

  const handleDateSelect = (date: Date) => {
    const dateFormat = formatDate(date, 'dddd, MMMM MM, YYYY')
    setAdditionalDetails({
      ...additionalDetails,
      releaseDate: dateFormat,
    })
    setSelectedDate(date)
    setIsComponentVisible(false)
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof AdditionalDetails,
  ) => {
    const { value } = e.target
    setAdditionalDetails({ ...additionalDetails, [key]: value })
  }

  const handleRunTimeChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: 'hours' | 'minutes',
  ) => {
    const { value } = e.target
    setAdditionalDetails({
      ...additionalDetails,
      runTime: { ...additionalDetails.runTime, [key]: value },
    })
  }

  const handleSaveAndNextStep = () => {
    setStepCompleted()
    nextStep()
  }

  const handleSelectChange = (option: OptionFilter) => {
    setAdditionalDetails({ ...additionalDetails, releasePattern: option.value })
  }

  const handleToggleDatePicker = () => setIsComponentVisible(prev => !prev)

  const dateSelect = selectedDate
    ? formatDate(selectedDate ?? '', 'dddd, MMMM MM, YYYY')
    : 'Release Date'

  return (
    <div className='flex flex-col space-y-5'>
      <TitleBlockStep
        title='Additional details (optional)'
        descriptions='Please add the project information below. Note that these inputs can be changed in the future.'
      />
      <div>
        <TypeSelectorTitle title='expected run time' />
        <RunTime
          hoursValue={additionalDetails.runTime.hours}
          minutesValue={additionalDetails.runTime.minutes}
          inputFirstWrapperClassName='w-[120px]'
          inputSecondWrapperClassName='w-[120px]'
          handleRunTimeChange={handleRunTimeChange}
        />
      </div>
      <div className='relative'>
        <Input
          type='text'
          value={dateSelect}
          wrapperClassName='w-64 h-10 items-center hover:cursor-pointer focus:border-primary-black'
          inputClassName='pl-2 text-md text-start text-primary-grey caret-transparent focus:border-primary-black'
          rightIcon={
            <Calendar
              fill={Colors.BASE_ICON}
              className='pr-1'
              onClick={handleToggleDatePicker}
            />
          }
          onClick={handleToggleDatePicker}
          label='Expected Release Date'
          labelClassName='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
          readOnly
        />
        {isComponentVisible && (
          <div
            className='absolute top-[70px] z-50 flex w-[230px] items-center justify-center rounded border border-primary-black bg-primary-white p-3 shadow-lg'
            ref={ref}
          >
            <DayPicker
              mode='single'
              selected={selectedDate as Date}
              captionLayout='dropdown'
              showOutsideDays
              fromYear={currentYear}
              toYear={currentYear + yearsAhead}
              disabled={{ before: currentTime }}
              onSelect={date => handleDateSelect(date as Date)}
            />
          </div>
        )}
      </div>
      <div>
        <Select
          value={additionalDetails.releasePattern}
          handleClick={handleSelectChange}
          options={releasePatternMenu}
          hasRadioButton={false}
          containerClass='w-56'
          placeholder='Select'
          label='Release Pattern'
          labelClass='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
          buttonClass='py-[9.4px]'
          size='medium'
          selectedLabelClass='text-sm text-grey-7 font-normal'
        />
      </div>
      <div>
        <Input
          value={additionalDetails.expectedDirector}
          placeholder={
            !isFocusedDirector ? 'e.g. Ellen Smithee, Alan Smithee' : ''
          }
          wrapperClassName='w-96 h-10'
          inputClassName='pl-2 text-md'
          label='Expected Director'
          labelClassName='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
          onChange={e => handleChange(e, 'expectedDirector')}
          onFocus={() => setIsFocusedDirector(true)}
          onBlur={() => setIsFocusedDirector(false)}
        />
        <span className='text-xs text-primary-grey'>
          Please use commas to separate multiple directors.
        </span>
      </div>
      <div>
        <Input
          value={additionalDetails.expectedWriter}
          placeholder={
            !isFocusedWriter ? 'e.g. Ellen Smithee, Alan Smithee' : ''
          }
          wrapperClassName='w-96 h-10'
          inputClassName='pl-2 text-md'
          label='Expected Writer'
          labelClassName='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
          onChange={e => handleChange(e, 'expectedWriter')}
          onFocus={() => setIsFocusedWriter(true)}
          onBlur={() => setIsFocusedWriter(false)}
        />
        <span className='text-xs text-primary-grey'>
          Please use commas to separate multiple writers.
        </span>
      </div>

      <div className='flex gap-3'>
        <Button
          kind='filled'
          size='medium'
          className='w-fit py-1.5'
          onClick={handleSaveAndNextStep}
          disabled={isButtonDisabled}
        >
          <span className='px-1'>Save and next step</span>
        </Button>
        <Button
          kind='text'
          size='medium'
          className='w-fit py-1.5'
          onClick={() => {
            nextStep()
            resetAdditionalDetails()
          }}
        >
          <span className='px-1'>Skip and enter later</span>
        </Button>
      </div>
    </div>
  )
}
