import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Pencil, Trash } from 'components/ui/icons'
import { OptionType } from 'types'
import { Colors } from 'constnants'

interface TalentListMenuItemProps {
  role: OptionType
  handleOpenEdit?: () => void
  handleOpenDelete?: () => void
  hasEditTrash?: boolean
  icon?: ReactNode
  selectedItem?: string
  handleSelectedItem?: () => void
  hasTrash?: boolean
  textClassName?: string
  handleClick?: (isChanged: boolean) => void
  isActive: boolean
}

export const TalentListMenuItem = ({
  role,
  handleOpenEdit,
  handleOpenDelete,
  hasEditTrash = false,
  handleSelectedItem,
  icon,
  hasTrash = true,
  textClassName,
  handleClick,
  isActive = false,
}: TalentListMenuItemProps) => {
  return (
    <div
      className={twMerge(
        'group flex w-full items-center justify-between rounded px-2 py-1 text-primary-black hover:cursor-pointer hover:bg-primary-black/5',
        isActive ? 'bg-red-2 text-red-9' : '',
        // role.id && 'bg-red-2 hover:bg-red-2',
        textClassName,
      )}
      onClick={() => {
        handleSelectedItem && handleSelectedItem()
        handleClick && handleClick(false)
      }}
    >
      <span className='truncate text-sm leading-6'>{role.label}</span>

      {hasEditTrash && (
        <div className='hidden gap-1 group-hover:flex'>
          <Pencil
            fill={Colors.BASE_ICON}
            width={16}
            height={16}
            onClick={handleOpenEdit}
            className='hover:opacity-70'
          />
          {hasTrash && (
            <Trash
              fill={Colors.BASE_ICON}
              width={16}
              height={16}
              onClick={handleOpenDelete}
              className='hover:opacity-70'
            />
          )}
        </div>
      )}

      {icon && <div>{icon}</div>}
    </div>
  )
}
