import { Checkbox } from 'components'
import {
  SelectionMode,
  UseSelectionModeParams,
  getHookByMode,
} from './selectionModeHooks'

interface InclusiveFilterProps<TOptionFilterValue>
  extends UseSelectionModeParams<TOptionFilterValue> {
  mode?: SelectionMode
  selectAllLabel?: string
  checkboxSize?: 'small' | 'medium'
}

export const InclusiveFilter = <TOptionFilterValue,>({
  options,
  selected,
  mode = 'inverse',
  selectAllLabel = 'All',
  checkboxSize,
  onChange,
}: InclusiveFilterProps<TOptionFilterValue>) => {
  const useSelection = getHookByMode(mode)
  const { isAllSelected, indeterminate, handleSelectAll, handleSelect } =
    useSelection({ options, selected, onChange })

  return (
    <ul className='flex flex-col gap-2'>
      <li className='flex items-center gap-2'>
        <Checkbox
          kind={checkboxSize}
          label={selectAllLabel}
          checked={isAllSelected}
          isNotAllChecked={indeterminate}
          onChange={handleSelectAll}
        />
      </li>
      {options.map(option => {
        const checked = selected.includes(option.value)
        return (
          <li key={option.id}>
            <Checkbox
              kind={checkboxSize}
              label={option.label}
              checked={checked}
              onChange={() => handleSelect(option, checked)}
            />
          </li>
        )
      })}
    </ul>
  )
}
