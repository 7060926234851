import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Input } from 'components'
import { Search } from 'components/ui/icons'
import { Colors } from 'constnants'
import { OptionType } from 'types'
import { useSearchFilter } from 'hooks'

interface SelectSearchMenuProps<TOptionTypeValue = string> {
  options: OptionType<TOptionTypeValue>[] | undefined
  isOpen: boolean
  handleClick: (option: OptionType<TOptionTypeValue>) => void
  wrapperOptionStyles?: string
  isError?: boolean
  optionClass?: string
  placeholderSearch?: string
  hasAdditionalValue?: boolean
  additionalValueClassName?: string
}

export const SelectSearchMenu = <TOptionTypeValue,>({
  options,
  optionClass,
  wrapperOptionStyles,
  handleClick,
  isOpen,
  placeholderSearch,
  hasAdditionalValue,
  additionalValueClassName,
}: SelectSearchMenuProps<TOptionTypeValue>) => {
  const { searchTerm, handleSearchChange, filteredOptions, setSearchTerm } =
    useSearchFilter(options ?? [])

  return (
    <>
      {isOpen && (
        <ul
          className={twMerge(
            'z-50 mt-0.5 min-w-fit overflow-hidden rounded border-primary-black/10 p-2 shadow',
            wrapperOptionStyles,
          )}
        >
          <Input
            id='search-input'
            leftIcon={<Search fill={Colors.BASE_ICON} width={20} height={20} />}
            placeholder={placeholderSearch}
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, i) => (
              <li
                key={`${option.value}_${i}`}
                className={twMerge(
                  'group flex w-full flex-row items-center text-sm leading-4 text-primary-grey hover:cursor-pointer',
                  optionClass,
                )}
                onClick={() => {
                  handleClick(option)
                  setSearchTerm('')
                }}
              >
                <span>{option.label}</span>
                {hasAdditionalValue && (
                  <span className={twMerge(additionalValueClassName)}>
                    {option.additionalValue}
                  </span>
                )}
              </li>
            ))
          ) : (
            <li className='pt-1 text-sm leading-4 text-primary-grey'>
              No results found
            </li>
          )}
        </ul>
      )}
    </>
  )
}
