import { BaseModal, Button, Select } from 'components'
import { SquarePlus } from 'components/ui/icons'
import { Colors, myProjectsAssignedUsers } from 'constnants'
import { useActions, useTypedSelector } from 'hooks'
import { OptionType } from 'types'

interface AssignUsersToProjectProps {
  isOpen: boolean
  onClose: () => void
}

export const AssignUsersToProject = ({
  isOpen,
  onClose,
}: AssignUsersToProjectProps) => {
  const { assignedUserList, assignedUser } = useTypedSelector(
    state => state.myProjectReducer,
  )
  const {
    setAssignedUserList,
    setAssignedUsers,
    resetAssignedUserList,
    setAssignedUser,
    resetAssignedUser,
  } = useActions()
  const updatedUserOptions = myProjectsAssignedUsers.map(user => ({
    id: user.id,
    value: user.fullName,
    label: user.fullName,
    additionalValue: user.email,
  }))

  const handleSelectUser = (option: OptionType) => {
    setAssignedUser({
      id: crypto.randomUUID(),
      fullName: option.value,
      email: option.additionalValue ?? '',
      userType: 'Module Admin',
    })
  }

  const handleAddUser = () => {
    setAssignedUserList(assignedUser)
    resetAssignedUser()
  }

  const handleAssignUsers = () => {
    setAssignedUsers(assignedUserList)
    onClose()
  }

  return (
    <BaseModal isOpen={isOpen} handleClose={onClose} maxWidth='600px'>
      <div className='flex w-full flex-col'>
        <span className='block py-3 text-lg font-medium leading-6 text-primary-black'>
          Assign users to project
        </span>
      </div>

      <Select
        value={assignedUser.fullName}
        handleClick={handleSelectUser}
        placeholder='Select'
        selectedLabelClass='text-sm font-normal'
        options={updatedUserOptions}
        additionalValueClassName='text-xs text-grey-6'
        optionClass='flex flex-col w-full items-start py-1'
        hasAdditionalValue
        hasSearch
      />

      <div className='flex flex-col space-y-2 pt-2'>
        {assignedUserList.length > 0 &&
          assignedUserList.map(user => (
            <Select
              key={user.id}
              value={user.fullName}
              placeholder='Select'
              selectedLabelClass='text-sm font-normal'
              options={updatedUserOptions}
              additionalValueClassName='text-xs text-grey-6'
              optionClass='flex flex-col w-full items-start py-1'
              handleClick={() => ''}
              hasAdditionalValue
              hasSearch
            />
          ))}
      </div>

      <div className='flex w-full gap-4 pt-4'>
        <Button
          kind='text'
          size='small'
          className='flex min-w-fit items-center gap-1'
          onClick={handleAddUser}
        >
          <SquarePlus fill={Colors.BASE_ICON} width={16} height={16} />
          <span>Add another user</span>
        </Button>
        <div className='mb-3 w-full border-b border-b-primary-black/10' />
      </div>

      <div className='ml-auto flex gap-3'>
        <Button
          kind='text'
          size='medium'
          className='ml-auto w-fit py-1.5'
          onClick={() => {
            onClose()
            resetAssignedUserList()
          }}
        >
          <span className='px-1'>Cancel</span>
        </Button>
        <Button
          kind='filled'
          size='medium'
          className='w-fit bg-primary-red py-1.5 hover:bg-red-9 active:bg-red-10'
          onClick={handleAssignUsers}
        >
          <span className='px-1'>Assign</span>
        </Button>
      </div>
    </BaseModal>
  )
}
