import { ColumnsProjectTable } from 'types'
import { twMerge } from 'tailwind-merge'
import { File } from 'components/ui/icons'
import { Colors } from 'constnants'
import { formatDate, isFormattedDate } from 'utils'

interface TestingManagementTableCellProps {
  column: ColumnsProjectTable
  row: {
    id: string | number
    testName: string
    submitDate: string
    completeDate: string
    status: string
    company: string
    project: string
  }
  isLoading?: boolean
}

export const TestingManagementTableCell = ({
  column,
  row,
  isLoading = false,
}: TestingManagementTableCellProps) => {
  switch (column.value) {
    case 'testName':
      return (
        <span
          className={twMerge(
            'flex text-xs font-medium text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.testName}
        </span>
      )
    case 'project':
      return (
        <span
          className={twMerge(
            'text-xs capitalize',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.project}
        </span>
      )
    case 'company':
      return (
        <span
          className={twMerge(
            'text-xs capitalize',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.company}
        </span>
      )
    case 'status':
      return (
        <span
          className={twMerge(
            'flex items-baseline gap-1 text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.status === 'needsapproval' && (
            <>
              <span className='h-2 w-2 rounded-full bg-primary-red'></span>
              Needs approval
            </>
          )}
          {row.status === 'Inactive' && (
            <>
              <span className='h-2 w-2 rounded-full bg-red-10'></span>
              Inactive
            </>
          )}
          {row.status === 'submitedtoprodoge' && (
            <>
              <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
              Submitted to Prodege
            </>
          )}
          {row.status === 'denied' && (
            <>
              <span className='h-2 w-2 rounded-full bg-red-10'></span>
              Denied
            </>
          )}
          {row.status === 'complete' && (
            <>
              <span className='h-2 w-2 rounded-full bg-[#00B447]'></span>
              Complete
            </>
          )}
          {isLoading && (
            <span className='bg-gray-500 text-transparent'>Loading...</span>
          )}
        </span>
      )
    case 'submitDate':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {formatDate(row.submitDate, 'MM/DD/YY')}
        </span>
      )
    case 'completeDate':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {isFormattedDate(row.completeDate)
            ? formatDate(row.completeDate, 'MM/DD/YY')
            : row.completeDate}
        </span>
      )
    default:
      return <span></span>
  }
}
