export const getLastSymbol = (str: string): string => {
  if (str.length === 0) return ''
  return str.charAt(str.length - 1)
}

export const getOrdinalSuffix = (rank: number): string => {
  const lastTwoDigits = rank % 100
  const lastDigit = rank % 10

  // Special cases for 11, 12, 13
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return 'th'

  switch (lastDigit) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}
