import { useCallback, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  IconButton,
  LayoutContentWrapper,
  StepComponent,
} from 'components'
import { Close } from 'components/ui/icons'
import { Colors, stepNewProject } from 'constnants'
import { useTypedSelector, useActions } from 'hooks'

interface NewProjectPageProps {
  handleCloseNewProject: () => void
}

export const NewProjectPage = ({
  handleCloseNewProject,
}: NewProjectPageProps) => {
  const { currentStep, completedSteps } = useTypedSelector(
    state => state.projectReducer,
  )
  const { setCurrentStep, setStepCompleted } = useActions()
  const [activeIndex, setActiveIndex] = useState(
    stepNewProject.indexOf(currentStep),
  )

  const stepLength = stepNewProject.length
  const statusTitle = (index: number) => `step ${index + 1}/${stepLength}`

  const nextStep = useCallback(() => {
    const currentIndex = stepNewProject.indexOf(currentStep)
    if (currentIndex < stepLength - 1) {
      const nextStep = stepNewProject[currentIndex + 1]
      setCurrentStep(nextStep)
      setActiveIndex(stepNewProject.indexOf(nextStep))
    }
  }, [currentStep, setCurrentStep])

  const previousStep = useCallback(
    (index: number) => {
      const prevStep = stepNewProject[index]
      setCurrentStep(prevStep)
    },
    [setCurrentStep],
  )

  const steps: AccordionItem[] = stepNewProject.map((step, index) => ({
    body: (
      <StepComponent
        step={step}
        nextStep={nextStep}
        setStepCompleted={setStepCompleted}
        handleCloseNewProject={handleCloseNewProject}
      />
    ),
    statusTitle: statusTitle(index),
    isActive: completedSteps[step],
    itemClassName: 'rounded bg-grey-3 px-2 pb-4 pt-4 shadow',
  }))

  return (
    <LayoutContentWrapper wrapperClassName='bg-grey-4 p-4 overflow-y-auto'>
      <div className='flex items-center justify-between'>
        <div className='pb-1'>
          <span className='text-xs font-semibold text-primary-black/50'>
            My Projects
          </span>
          <h3 className='text-2xl font-semibold text-primary-black'>
            New Project
          </h3>
        </div>
        <IconButton
          className='rounded-full bg-primary-black/5 hover:bg-primary-black/15'
          onClick={handleCloseNewProject}
        >
          <Close fill={Colors.BASE_ICON} width={20} height={20} />
        </IconButton>
      </div>

      <div className='flex flex-col'>
        <Accordion
          items={steps}
          statusStep
          wrapperClassName='flex flex-col space-y-3 pb-5'
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          previousStep={previousStep}
        />
      </div>
    </LayoutContentWrapper>
  )
}
