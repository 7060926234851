import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import InputMask from 'react-input-mask'
import { z } from 'zod'
import { Button, Checkbox, InputAuth } from 'components'
import { LoginInfoForm } from '../LoginInfoForm'
import './Form.css'

const schema = z.object({
  companyName: z.string(),
  fullName: z.string().nonempty('Full Name is required'),
  role: z.string().nonempty('Role/Title is required'),
  phone: z
    .string()
    .regex(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid'),
  terms: z.boolean().refine(val => val === true, {
    message: 'You must agree to the terms and conditions',
  }),
})

type FormData = z.infer<typeof schema>

export const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    trigger,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      companyName: 'Wonderment App',
    },
  })
  const [step, setStep] = useState<'personalInfo' | 'loginInfo'>('personalInfo')

  const [userData, setUserData] = useState({
    fullName: '',
    role: '',
    phone: '',
    companyName: '',
  })

  const onSubmit = (data: FormData) => {
    setUserData(data)
    setStep('loginInfo')
  }

  useEffect(() => {
    setValue('companyName', 'Wonderment App')
  }, [setValue])

  if (step === 'loginInfo') {
    return <LoginInfoForm userData={userData} />
  }

  return (
    <div className='flex flex-col items-center text-primary-black'>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-md'>
        <h2 className='text-center text-2xl font-semibold'>
          Let’s Set Up Your Account
        </h2>
        <p className='mb-5 mt-2 text-center text-xs font-normal uppercase text-red-9'>
          Step 1: Personal information
        </p>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col'>
            <label
              htmlFor='companyName'
              className='text-sm font-semibold text-primary-black opacity-40'
            >
              Company Name
            </label>
            <InputAuth
              id='companyName'
              disabled
              inputClassName='bg-gray-100 text-gray-600 text-sm font-normal border border-gray-300 rounded-md h-8'
              {...register('companyName')}
              defaultValue={'Pearl Street Films'}
            />
          </div>
          <div className='flex flex-col'>
            <label
              htmlFor='fullName'
              className='text-sm font-semibold text-primary-black'
            >
              Full Name
            </label>
            <Controller
              name='fullName'
              control={control}
              render={({ field }) => (
                <InputAuth
                  {...field}
                  id='fullName'
                  isError={!!errors.fullName}
                  error={errors.fullName?.message}
                  inputClassName={`text-sm font-normal border rounded-md h-8 ${errors.fullName ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                  onChange={e => {
                    field.onChange(e)
                    trigger('fullName')
                  }}
                />
              )}
            />
          </div>
          <div className='flex flex-col'>
            <label
              htmlFor='role'
              className='text-sm font-semibold text-primary-black'
            >
              Your Role/Title
            </label>
            <Controller
              name='role'
              control={control}
              render={({ field }) => (
                <InputAuth
                  {...field}
                  id='role'
                  isError={!!errors.role}
                  error={errors.role?.message}
                  inputClassName={`text-sm font-normal border rounded-md h-8 ${errors.role ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                  onChange={e => {
                    field.onChange(e)
                    trigger('role')
                  }}
                />
              )}
            />
          </div>
          <div className='flex flex-col'>
            <label
              htmlFor='phone'
              className='text-sm font-semibold text-primary-black'
            >
              Your Phone
            </label>
            <Controller
              name='phone'
              control={control}
              render={({ field }) => (
                <InputMask
                  mask='+1 (999) 999-9999'
                  value={field.value}
                  onChange={e => {
                    field.onChange(e)
                    trigger('phone')
                  }}
                >
                  {(InputAuthProps: any) => (
                    <InputAuth
                      {...InputAuthProps}
                      id='phone'
                      isError={!!errors.phone}
                      error={errors.phone?.message}
                      inputClassName={`text-sm font-normal border rounded-md h-8 ${errors.phone ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                    />
                  )}
                </InputMask>
              )}
            />
          </div>
          <div className='flex flex-col items-start gap-2 text-xs text-gray-700'>
            <Controller
              name='terms'
              control={control}
              render={({ field }) => (
                <div className='flex items-start gap-2'>
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    value={field.value ? 'true' : 'false'}
                  />
                  <span>
                    I agree to Greenlight Analytics’{' '}
                    <a href='/terms' className='text-primary-red'>
                      Terms of Service & Privacy Policy
                    </a>
                  </span>
                </div>
              )}
            />
            {errors.terms && (
              <p className='mt-1 text-xs text-red-500'>
                {errors.terms?.message}
              </p>
            )}
          </div>
          <div>
            <Button
              type='submit'
              kind='filled'
              size='medium'
              className='mt-4 w-full rounded-full bg-red-500 px-4 py-2 text-white'
            >
              <span>Continue</span>
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
