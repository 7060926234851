import { OptionType } from 'types'

const generateDateRangeOptions = (): OptionType[] => {
  const currentDate = new Date()

  return Array.from({ length: 2 }, (_, i) => {
    const pastDate = new Date(currentDate)
    pastDate.setMonth(currentDate.getMonth() - (i + 1))
    const monthLabel = i + 1 === 1 ? '1 month' : `${i + 1} months`

    return {
      id: (i + 1).toString(),
      value: (i + 1).toString(),
      label: `${monthLabel}`,
    } as OptionType
  })
}

export const dateRange = generateDateRangeOptions()
