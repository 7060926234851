import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectsTableBody, ProjectsTableHeader } from './components'
import { Project, Urls } from 'types'
import { ProjectsTableProps } from './ProjectsTable.types'
import {
  EditModal,
  Input,
  PaginationChangeParams,
  PaginationToolbar,
} from 'components'
import { Search, XCircle } from 'components/ui/icons'
import { Colors } from 'constnants'
import { rowsPerPageOptions } from 'constnants/talentTableData'
import { useUpdatedProjectMutation } from 'store/api'
import { toastError } from 'utils'

export const ProjectsTable = <T extends Project>({
  totalRows,
  columns,
  data,
  hasAmountMessage,
  handleUpdateQueryParams,
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  phaseStatus,
  deferredQuery,
  handleSearchChange,
  handeClearSearchInput,
}: ProjectsTableProps<T>) => {
  const navigate = useNavigate()
  const [openAdditionalMenuId, setOpenAdditionalMenuId] = useState<
    number | null
  >(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editProjectId, setEditProjectId] = useState<number | null>(null)
  const [editProjectName, setEditProjectName] = useState<string>('')
  const [updateProject] = useUpdatedProjectMutation()

  const totalPages = useMemo(
    () => Math.ceil(totalRows / pageSize),
    [totalRows, pageSize],
  )

  const handleOpenEditModal = (id: number, name: string) => {
    setEditProjectId(id)
    setEditProjectName(name)
    setIsEditModalOpen(true)
    setOpenAdditionalMenuId(null)
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false)
    setEditProjectId(null)
    setEditProjectName('')
  }

  const handleUpdateFavorite = async (selectedProject: Project) => {
    try {
      await updateProject({
        id: selectedProject.id ?? 0,
        project: { is_featured: !selectedProject.isFeatured },
      }).unwrap()
    } catch (error) {
      toastError(error)
    }
  }

  const handleArchiveProject = async (selectedProject: Project) => {
    try {
      await updateProject({
        id: selectedProject.id ?? 0,
        project: { is_active: !selectedProject.isActive },
      }).unwrap()
    } catch (error) {
      toastError(error)
    }
  }

  const saveHandleChange = () => {
    try {
      updateProject({
        id: editProjectId!,
        project: { name: editProjectName },
      })
    } catch (e) {
      toastError(e)
    }
    setIsEditModalOpen(false)
  }

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      handleUpdateQueryParams({
        pageNumber: params.page,
        pageSize: params.pageSize,
        sortBy,
        phaseStatus,
        sortOrder,
      })
    },
    [handleUpdateQueryParams],
  )

  const handleToggleAdditionalMenu = (id: number) => {
    setOpenAdditionalMenuId(openAdditionalMenuId === id ? null : id)
  }

  return (
    <div className='h-[calc(100vh_-_251px)] w-full overflow-auto p-3 pb-12'>
      <div className='flex items-center justify-between pb-4'>
        <span className='text-xs font-semibold text-primary-black/50'>
          {hasAmountMessage}
        </span>
        <div className='w-[200px]'>
          <Input
            placeholder='Name Lookup'
            value={deferredQuery}
            onChange={handleSearchChange}
            leftIcon={<Search fill={Colors.BASE_ICON} width={16} height={16} />}
            rightIcon={
              deferredQuery.length ? (
                <XCircle
                  onClick={handeClearSearchInput}
                  className='mr-1 hover:cursor-pointer'
                />
              ) : (
                <></>
              )
            }
          />
        </div>
      </div>

      <table className='project-table'>
        <ProjectsTableHeader
          columns={columns}
          handleUpdateQueryParams={handleUpdateQueryParams}
          sortBy={sortBy}
          sortOrder={sortOrder}
          pageSize={pageSize}
          pageNumber={pageNumber}
          phaseStatus={phaseStatus}
        />
        <ProjectsTableBody
          data={data}
          columns={columns}
          searchValue={deferredQuery}
          onClearSearch={handeClearSearchInput}
          onNavigateProject={id => {
            navigate(`${Urls.DETAIL_PROJECT.replace(':id', id)}`)
          }}
          onToggleMenu={handleToggleAdditionalMenu}
          openMenuId={openAdditionalMenuId}
          onOpenEditModal={handleOpenEditModal}
          onUpdateFavorite={handleUpdateFavorite}
          onArchiveProject={handleArchiveProject}
        />
      </table>

      <EditModal
        currentInputValue={editProjectName}
        isOpen={isEditModalOpen}
        handleClose={handleCloseEditModal}
        onChange={e => setEditProjectName(e.target.value)}
        handleSave={saveHandleChange}
        title='Edit Project Name'
        hasInput
      />
      <div className='fixed bottom-0 right-2 w-full'>
        <PaginationToolbar
          totalRows={totalRows}
          totalPages={totalPages}
          currentPage={pageNumber}
          currentPageSize={pageSize}
          pageSizeOptions={rowsPerPageOptions}
          onChange={handlePagination}
        />
      </div>
    </div>
  )
}
