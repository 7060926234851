import { twMerge } from 'tailwind-merge'

interface ProjectStatusItemProps {
  status: string | string[] | undefined
  wrapperClassName?: string
  statusClassName?: string
}

export const ProjectStatusItem = ({
  status,
  wrapperClassName,
  statusClassName,
}: ProjectStatusItemProps) => {
  const isStatusEmpty = status === undefined

  return (
    <>
      {!isStatusEmpty ? (
        <div
          className={twMerge(
            'flex w-fit items-center rounded-full px-[5px] py-0.5',
            wrapperClassName,
          )}
        >
          <span
            className={twMerge(
              'text-center text-xs font-medium uppercase text-primary-white',
              statusClassName,
            )}
          >
            {status}
          </span>
        </div>
      ) : (
        <div>-</div>
      )}
    </>
  )
}
