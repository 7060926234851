import { ButtonHTMLAttributes, ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { IconProps } from 'types'

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactElement<IconProps>
}

export const IconButton = ({
  children,
  className,
  type = 'button',
  disabled = false,
  ...props
}: IconButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={twMerge(
        'group flex items-center justify-center rounded-full p-1 duration-500 hover:cursor-pointer hover:opacity-90 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none',
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )
}
