import { useMemo, useState } from 'react'
import { Info, Plus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { Logline } from 'types'
import {
  LoglineCard,
  Button,
  IconButton,
  AddOrEditLoglineModalContent,
} from 'components'
import { useUpdatedProjectMutation } from '../../store/api'

interface MyProjectLoglineListProps {
  loglines: Logline[]
  onOpen: () => void
  handleOpenEditLogline: (logline: Logline) => void
  selectedLogline: Logline | null
  projectId: number
  preparedLoglines: Logline[]
  projectName: string | undefined
}

export const MyProjectLoglineList = ({
  loglines,
  onOpen,
  handleOpenEditLogline,
  selectedLogline,
  projectId,
  preparedLoglines,
  projectName,
}: MyProjectLoglineListProps) => {
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false)
  const [updateLogline] = useUpdatedProjectMutation()

  const handleUpdateActiveLogline = async (selectedLogline: Logline) => {
    const updatedLoglines = preparedLoglines.reduce(
      (acc, logline) => {
        acc[logline.id] = {
          ...logline,
          isActive: logline.id === selectedLogline.id ? true : false,
        }
        return acc
      },
      {} as { [key: string]: Logline },
    )

    await updateLogline({
      id: projectId,
      project: {
        name: projectName ?? '',
        log_lines: updatedLoglines,
      },
    }).unwrap()
  }

  const toggleEditModal = () => setIsOpenEdit(prev => !prev)

  const handleOpenEdit = (logline: Logline) => {
    handleOpenEditLogline(logline)
    setIsOpenEdit(true)
  }

  return (
    <div className='h-full w-full bg-grey-2 p-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-1 pb-3 pt-2'>
          <h3 className='text-2xl font-semibold text-primary-black'>
            Loglines
          </h3>
          <IconButton>
            <Info fill={Colors.BASE_ICON} width={20} height={20} />
          </IconButton>
        </div>

        <Button
          kind='filled'
          size='medium'
          className='w-fit bg-primary-red hover:bg-red-9 active:bg-red-10'
          onClick={onOpen}
        >
          <div className='flex items-center'>
            <span className='pr-1 text-sm font-semibold'>
              New Logline version
            </span>
            <Plus fill={Colors.ICON_WHITE} width={16} height={16} />
          </div>
        </Button>
      </div>
      <ul className='grid w-[86%] grid-cols-2 gap-5'>
        {loglines.map(logline => (
          <LoglineCard
            key={logline.id}
            loglineData={logline}
            isActive={logline.isActive ?? false}
            toggleActive={() => {
              if (!logline.isActive) {
                handleUpdateActiveLogline(logline)
              }
            }}
            onOpenEdit={() => handleOpenEdit(logline)}
          />
        ))}
      </ul>

      <AddOrEditLoglineModalContent
        isOpen={isOpenEdit}
        onClose={toggleEditModal}
        selectedLogline={selectedLogline}
        projectId={projectId}
        preparedLoglines={preparedLoglines}
        projectName={projectName}
        isEdit
      />
    </div>
  )
}
