import { useParams } from 'react-router-dom'
import { TalentPage } from 'pages'

export const TalentListDetailPage = () => {
  const { listName, listId } = useParams<{ listName: string; listId: string }>()

  return (
    <>
      <TalentPage listName={listName} listId={listId} />
    </>
  )
}
