import { formatNumberWithSuffix } from 'utils'

export const formatRevenueData = data => {
  if (!data) return []
  console.log('data', data)
  return [
    {
      label: 'LY',
      id: 'lastYear',
      events: data.map(item => ({
        weekNumber: item.week_number,
        budget: `$${formatNumberWithSuffix(item.revenue_last_year)}`,
        startDay: formatDate(item.start_date_id),
        endDay: formatDate(item.end_date_id),
      })),
    },
    {
      label: 'L3Y',
      id: 'last3Year',
      events: data.map(item => ({
        weekNumber: item.week_number,
        budget: `$${formatNumberWithSuffix(item.revenue_last_3years)}`,
        startDay: formatDate(item.start_date_id),
        endDay: formatDate(item.end_date_id),
      })),
    },
  ]
}
const formatDate = dateId => {
  const dateStr = dateId.toString()
  const year = dateStr.slice(0, 4)
  const month = dateStr.slice(4, 6)
  const day = dateStr.slice(6, 8)
  return `${year}-${month}-${day}`
}
