import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type LayoutContentWrapperProps = PropsWithChildren & {
  rightSidebar?: React.ReactNode
  wrapperClassName?: string
  wrapperChildrenClassName?: string
}

export const LayoutContentWrapper = ({
  children,
  rightSidebar,
  wrapperClassName,
  wrapperChildrenClassName,
}: LayoutContentWrapperProps) => {
  return (
    <div
      className={twMerge(
        'relative flex h-full rounded-tl-md rounded-tr-md bg-grey-2',
        wrapperClassName,
      )}
    >
      <div className={twMerge('w-full', wrapperChildrenClassName)}>
        {children}
      </div>

      {rightSidebar}
    </div>
  )
}
