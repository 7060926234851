import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
} from 'date-fns'
import Highcharts from 'highcharts'
import { dateToUTCTimestamp } from 'utils'

export const formatDateAxisValue = (timestampUTC: number) => {
  const date = new Date(timestampUTC)
  const month = Highcharts.dateFormat('%b', timestampUTC) // Short month name
  const year = Highcharts.dateFormat('%Y', timestampUTC) // Year

  return date.getMonth() === 0 ? `${year}` : month
}

export const generateDateAxisTicks = (startDate: Date, endDate: Date) => {
  const adjustedStartDate =
    startDate.getDate() < 15 ? subMonths(startDate, 1) : startDate

  const adjustedEndDate =
    endDate.getDate() > 15 ? addMonths(endDate, 2) : addMonths(endDate, 1)

  const ticks = eachMonthOfInterval({
    start: startOfMonth(adjustedStartDate),
    end: startOfMonth(adjustedEndDate),
  }).map(date => dateToUTCTimestamp(date))

  return ticks
}
