import { OptionType } from 'types'

export function createOptionsFromEnum<T extends string | number>(
  enumObj: Record<string, T>,
  labelMapping: Record<T, string>,
): OptionType<T>[] {
  return Object.values(enumObj).map(value => ({
    id: String(value),
    value,
    label: labelMapping[value],
  }))
}
