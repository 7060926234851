import React, { useEffect, useMemo, useState } from 'react'
import { FilterButton } from '../../../FilterButton'
import { FilterColumnGroup } from '../../../FilterColumnGroup'
import { dataAudience } from '../../../../constnants/audienceOptions'
import { SelectedFilters } from 'types'
export interface AudienceFilterButtonProps {
  handleFilterChange: (filters: string) => void
}
const defaultState = { general: ['ALL'] }

export function AudienceFilterButton({
  handleFilterChange,
}: AudienceFilterButtonProps) {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const [selectedFiltersAudience, setSelectedFiltersAudience] =
    useState<SelectedFilters>(defaultState)
  useEffect(() => {
    const audienceFilter = Object.values(selectedFiltersAudience).flat()[0]
    handleFilterChange ? handleFilterChange(audienceFilter) : null
  }, [selectedFiltersAudience])
  const handleResetFiltersAudience = () => {
    setSelectedFiltersAudience(defaultState)
  }

  const audienceTitle = useMemo(() => {
    for (const audienceGroup of dataAudience) {
      const selected = selectedFiltersAudience[audienceGroup.id] ?? []
      const targetAudience = audienceGroup.options.find(it =>
        selected.includes(it.value),
      )
      if (targetAudience) {
        if (audienceGroup.id !== 'general') {
          return `${audienceGroup.name} ${targetAudience.label}`
        }

        if (targetAudience.value !== 'ALL') {
          return targetAudience.label
        }
      }
    }
    return 'Audience'
  }, [selectedFiltersAudience])

  return (
    <FilterButton
      name={audienceTitle}
      totalSelectedFilters={
        selectedFiltersAudience['general']?.includes('ALL') ? 0 : 1
      }
      hideTotalNumber
      handleReset={handleResetFiltersAudience}
      isMenuVisible={isMenuVisible}
      setIsMenuVisible={setIsMenuVisible}
      trianglePosition='left'
      containerMenuClassName='left-0 pr-2'
    >
      <div className='flex flex-row gap-10'>
        {dataAudience.map(({ id, name, options }) => {
          return (
            <FilterColumnGroup
              key={id}
              category={id}
              title={name}
              optionsFilter={options}
              isSelect={true}
              selectedFilters={selectedFiltersAudience}
              onChange={selected => {
                console.log('selected', selected)
                setIsMenuVisible(true)
                setSelectedFiltersAudience(selected)
              }}
              isSingleSelection={true}
            />
          )
        })}
      </div>
    </FilterButton>
  )
}
