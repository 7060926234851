import { twMerge } from 'tailwind-merge'
import { ChevronDown, ChevronUp } from 'components'
import type {
  MgmtTableColData,
  MgmtTableRowData,
  MgmtTableTypes,
} from 'modules/management/shared'
import { MouseEventHandler } from 'react'

type SortOrder = MgmtTableTypes['SortConfig']['order']

const SORT_ICON_COLOR = {
  DEFAULT: '#03071280',
  ACTIVE: '#FF4449',
}

const getSortArrowColor = (
  isSortedBy: boolean,
  currentOrder: SortOrder,
  targetOrder: SortOrder,
): string => {
  return isSortedBy && currentOrder === targetOrder
    ? SORT_ICON_COLOR.ACTIVE
    : SORT_ICON_COLOR.DEFAULT
}

const renderSortingIndicators = <
  Row extends MgmtTableRowData,
  Col extends MgmtTableColData,
>(
  key: string | undefined,
  config: MgmtTableTypes<Row, Col>['SortConfig'],
) => {
  const isSortedBy = key === config.by
  const ascArrowColor = getSortArrowColor(isSortedBy, config.order, 'ASC')
  const descArrowColor = getSortArrowColor(isSortedBy, config.order, 'DESC')
  return (
    <div className='mt-1'>
      <ChevronUp
        fill={ascArrowColor}
        width={14}
        height={10}
        className='-mb-1.5'
      />
      <ChevronDown fill={descArrowColor} width={14} height={10} />
    </div>
  )
}

export const MgmtTableHeaderCell = <
  Row extends MgmtTableRowData,
  Col extends MgmtTableColData,
>({
  children,
  className,
  column,
  sortConfig,
  sortHandler,
  onSortChange,
}: MgmtTableTypes<Row, Col>['HeaderCellProps']) => {
  const { width, sortKey } = column

  const handleCellClick: MouseEventHandler<HTMLElement> = () => {
    if (sortKey && sortConfig && onSortChange && sortHandler) {
      onSortChange({
        sortConfig: sortHandler({ column, sortConfig }),
      })
    }
  }

  return (
    <th
      style={{ width }}
      className={twMerge(
        'pointer-events-none bg-grey-3 p-3 text-left text-xs font-normal text-primary-grey',
        column.sortKey && 'pointer-events-auto cursor-pointer',
        className,
      )}
      onClick={handleCellClick}
    >
      <div className='flex items-center gap-2'>
        <span>{children}</span>
        {sortKey && sortConfig && renderSortingIndicators(sortKey, sortConfig)}
      </div>
    </th>
  )
}
