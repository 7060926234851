import { Fragment, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useNavigate } from 'react-router-dom'

import { useIsMobile, useKeyPress, useScrollLock } from 'hooks'
import { getStylesForSidebarContainer } from 'utils'
import { IconButton, NavItem } from 'components'
import { CloseMenu, Logo, OpenMenu } from 'components/ui/icons'
import {
  Colors,
  routes,
  RouteType,
  adminRouteGroups,
  userRouteGroups,
} from 'constnants'

interface SidebarProps {
  topGap?: number
  bottomGap?: number
  isOpenMob?: boolean
  handleToggleMobile?: () => void
  isOpenSidenav?: boolean
  closeSideNavigation?: () => void
  openSideNavigation?: () => void
  toggleSettings: () => void
  isAdmin?: boolean
}

export const Sidebar = ({
  topGap = 0,
  bottomGap = 0,
  isOpenMob,
  handleToggleMobile,
  isOpenSidenav,
  closeSideNavigation,
  openSideNavigation,
  toggleSettings,
  isAdmin = false,
}: SidebarProps) => {
  const [activeRoute, setActiveRoute] = useState('')
  const { lockScroll, unlockScroll } = useScrollLock()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const styles = getStylesForSidebarContainer(topGap ?? 0, bottomGap ?? 0)

  useEffect(() => {
    if (isOpenSidenav) {
      openSideNavigation && openSideNavigation()
      lockScroll()
    } else {
      closeSideNavigation && closeSideNavigation()
      unlockScroll()
    }
  }, [isOpenSidenav])

  useKeyPress(() => {
    if (isOpenSidenav) {
      closeSideNavigation && closeSideNavigation()
    }
  }, ['Escape'])

  const handleToggle = () => {
    if (isOpenSidenav) {
      closeSideNavigation && closeSideNavigation()
    } else {
      openSideNavigation && openSideNavigation()
    }
  }

  const handleRouteClick = (url: string) => {
    if (isMobile) {
      handleToggleMobile && handleToggleMobile()
    } else if (isOpenSidenav) {
      handleToggle()
    }
    navigate(url)
  }

  useEffect(() => {
    const currentRoute = routes.find(
      route => route.path === window.location.pathname,
    )
    if (currentRoute) {
      setActiveRoute(currentRoute.path)
    }
  }, [window.location.pathname])

  const renderNavItem = (route: RouteType, index: number) => (
    <NavItem
      key={index}
      name={route.name}
      icon={route.icon}
      isOpen={isOpenSidenav || isOpenMob}
      handleToggle={
        route.name === 'Settings'
          ? toggleSettings
          : () => handleRouteClick(route.path)
      }
      isActive={activeRoute === route.path}
      isAdmin={route.isAdminNavigation}
    />
  )

  return (
    <div
      className={twMerge(
        'fixed left-0 top-0 z-50 flex h-screen flex-col place-items-start gap-4 overflow-hidden bg-primary-blue px-1.5 py-3 transition-all duration-300',
        isOpenSidenav && !isMobile
          ? isAdmin
            ? 'left-0 w-[177px]'
            : 'left-0 w-44'
          : !isAdmin
            ? 'left-0 w-12'
            : 'left-0 w-2',
        isOpenMob && isMobile && 'w-full overflow-visible',
        !isOpenMob && isMobile && 'w-0 overflow-hidden px-0',
      )}
      style={{ ...styles }}
    >
      {!isMobile && !isAdmin && (
        <Logo width={30} height={32} className={'shrink-0'} />
      )}

      {!isMobile && !isAdmin && (
        <IconButton
          onClick={handleToggle}
          className={twMerge(
            'rounded-md p-1.5 transition-all duration-300 hover:bg-white/20',
            isOpenSidenav ? 'ml-32' : 'ml-0',
          )}
        >
          {isOpenSidenav ? (
            <CloseMenu fill={Colors.NAV_ICON_ONE} width={20} height={20} />
          ) : (
            <OpenMenu fill={Colors.NAV_ICON_ONE} width={20} height={20} />
          )}
        </IconButton>
      )}
      {!isAdmin ? (
        <>
          {userRouteGroups.map(({ routes }, groupIndex) => {
            const isFirstGroup = groupIndex === 0
            const isLastGroup = groupIndex === userRouteGroups.length - 1
            return (
              <Fragment key={groupIndex}>
                {!isLastGroup && !isFirstGroup && (
                  <div className='w-full border-b border-green-4' />
                )}
                <div
                  className={twMerge(
                    'flex min-w-full max-w-full flex-col gap-1',
                    isLastGroup && 'mt-auto',
                  )}
                >
                  {routes.map(renderNavItem)}
                </div>
              </Fragment>
            )
          })}
        </>
      ) : (
        <div className={'pt-14'}>
          <div className='flex min-w-full max-w-full flex-col gap-1'>
            {adminRouteGroups.map(({ title, routes }, groupIndex) => {
              return (
                <Fragment key={groupIndex}>
                  <div className='text-nowrap px-2 pb-2 pt-4 text-xs uppercase text-tertiary-inverse'>
                    {title}
                  </div>
                  {routes.map(renderNavItem)}
                </Fragment>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
