import { useState } from 'react'
import { LayoutContentWrapper, MyProjectsTopContent } from 'components'
import { recentActivities } from 'constnants/myProjectsRecentActivities'
import { NewProjectPage } from 'pages'

export const MyProjectsPage = () => {
  const [isShowNewProject, setIsShowNewProject] = useState<boolean>(false)
  const onShowNewProject = () => setIsShowNewProject(true)
  const onCloseNewProject = () => setIsShowNewProject(false)

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-white'
      wrapperChildrenClassName='h-full'
    >
      {!isShowNewProject ? (
        <MyProjectsTopContent
          recentActivities={recentActivities}
          handleAddProject={onShowNewProject}
        />
      ) : (
        <NewProjectPage handleCloseNewProject={onCloseNewProject} />
      )}
    </LayoutContentWrapper>
  )
}
