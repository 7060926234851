import { Input, Textarea } from '../../components'
import { ChangeEvent } from 'react'

interface SingleAssetInputProps {
  label: string
  introText?: string
  listText?: string[]
  fieldsLabel: string
  onChangeHandler: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
    objectKey?: string,
  ) => void
  inputValue: string
  inputPlaceholder: string
  inputKey: string
  inputObjectKey?: string
  textareaPlaceholder: string
  textareaKey: string
  textareaObjectKey?: string
}
export const SingleAssetInput = ({
  label,
  introText,
  listText,
  fieldsLabel,
  onChangeHandler,
  inputPlaceholder,
  inputValue,
  textareaPlaceholder,
  inputKey,
  textareaKey,
  textareaObjectKey,
  inputObjectKey,
}: SingleAssetInputProps) => {
  return (
    <div className='my-8 rounded bg-grey-3 px-6 py-4'>
      <div className={'mb-4 text-sm text-grey-7'}>
        <p className={'font-semibold uppercase '}>{label}</p>
        {introText && <p>{introText}</p>}
        {listText && (
          <ul className={'ml-5 list-disc'}>
            {listText.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        )}
      </div>
      <div>
        <p className={'font-semibold text-primary-black'}>{fieldsLabel}</p>
        <Input
          placeholder={inputPlaceholder}
          value={inputValue}
          onChange={e => onChangeHandler(e, inputKey, inputObjectKey)}
          wrapperClassName='w-full h-8'
          inputClassName='pl-3 text-sm'
        />
        <Textarea
          onChange={e => onChangeHandler(e, textareaKey, textareaObjectKey)}
          placeholder={textareaPlaceholder}
          className={'mt-1 text-sm'}
        />
      </div>
    </div>
  )
}
