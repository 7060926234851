import { useNavigate } from 'react-router-dom'

export function useUpdateUrlParams() {
  const navigate = useNavigate()

  const convertToQueryString = (query: Record<string, any>): string => {
    const searchParams = new URLSearchParams()

    Object.entries(query).forEach(([key, value]) => {
      if (value !== undefined && value !== '' && value !== null) {
        if (Array.isArray(value)) {
          value.forEach(val => {
            searchParams.append(key, val.toString())
          })
        } else {
          searchParams.append(key, value.toString())
        }
      }
    })

    return searchParams.toString()
  }

  const handleUrlParams = (
    query?: Record<string, any>,
    name?: string,
  ): void => {
    const queryString = query
      ? `?${convertToQueryString(query)}`
      : location.search
    navigate(name ? `${queryString}&talentName=${name}` : `${queryString}`)
  }

  return handleUrlParams
}
