import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CircleCross, CheckCircle, EyeSlash, Eye } from 'components/ui/icons'
import { Colors } from 'constnants'
import { apiURL } from 'store/api/api'

interface LoginInfoFormData {
  email: string
  password: string
  confirmPassword: string
  terms: boolean
}

const validatePassword = (password: string) => ({
  hasLowercase: /[a-z]/.test(password),
  hasUppercase: /[A-Z]/.test(password),
  hasNumber: /\d/.test(password),
  hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  hasMinLength: password?.length >= 8,
})

interface loginInfoProps {
  userData: {
    fullName: string
    role: string
    phone: string
    companyName: string
  }
}

export const LoginInfoForm = ({ userData }: loginInfoProps) => {
  const [step, setStep] = useState<'loginInfo' | 'thankYou'>('loginInfo')
  const [validation, setValidation] = useState(validatePassword(''))
  const [apiError, setApiError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginInfoFormData>()

  const onSubmit = (data: LoginInfoFormData) => {
    if (data.password !== data.confirmPassword) {
      return
    }
    const userFullName = userData.fullName.split(' ')
    const firstName = userFullName[0]
    const lastName = userFullName[1] ?? ''
    const requestBody = {
      email: data.email,
      password: data.password,
      firstName,
      lastName,
      userType: 'standard',
    }
    fetch(apiURL + '/user/signUp', {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setApiError(data.message)
        } else {
          setStep('thankYou')
        }
      })
      .catch(error => console.error('Error:', error))
  }

  const watchedPassword = watch('password')

  useEffect(() => {
    setValidation(prevState => ({
      ...prevState,
      ...validatePassword(watchedPassword),
    }))
  }, [watchedPassword])

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  if (step === 'thankYou') {
    return (
      <div>
        <h2 className='text-center text-2xl font-semibold text-gray-950'>
          Thank you!
        </h2>
        <p className='text-center text-sm font-normal text-neutral-600'>
          Check your email for instruction to complete your account setup.
        </p>
      </div>
    )
  }

  return (
    <div className='flex flex-col items-center text-gray-950'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex w-full flex-col items-center'
      >
        <h2 className='break-words text-2xl font-semibold'>
          Let’s Set Up Your Account
        </h2>
        <p className='break-words py-2 text-center text-xs font-normal uppercase leading-4 tracking-wide text-red-500'>
          Step 2: Login Info
        </p>
        <div className='flex w-full flex-col gap-4'>
          <div className='flex flex-col items-start'>
            <label
              htmlFor='email'
              className='text-sm font-semibold text-gray-950'
            >
              Your Email
            </label>
            <input
              id='email'
              className={`w-full rounded-md border border-gray-100 p-1 text-sm font-normal leading-6 text-gray-950 ${errors.email ? 'border-red-500 bg-red-100' : ''}`}
              {...register('email', {
                required: 'Email address is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email address format',
                },
              })}
              type='email'
            />
            {errors.email && (
              <p className='mt-1 text-xs text-red-500'>
                {errors.email.message?.toString()}
              </p>
            )}
            {apiError && (
              <p className='mt-1 text-xs text-red-500'>{apiError}</p>
            )}
          </div>
          <div className='flex flex-col items-start gap-2'>
            <label
              htmlFor='password'
              className='text-sm font-semibold text-gray-950'
            >
              Choose Password
            </label>
            <div className='relative w-full'>
              <input
                id='password'
                placeholder='Minimum 8 characters'
                type={showPassword ? 'text' : 'password'}
                className={`w-full rounded-md border border-gray-100 p-1 text-sm font-normal leading-6 text-gray-950 ${errors.password ? 'border-red-500 bg-red-100' : ''}`}
                {...register('password', { required: 'Password is required' })}
              />
              <button
                type='button'
                className='absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer border-none bg-none focus:outline-none'
                onClick={toggleShowPassword}
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </button>
            </div>
            <div className='mt-2 flex items-center gap-2 text-gray-500'>
              {validation.hasLowercase ? (
                <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
              ) : (
                <CircleCross
                  fill={Colors.NAV_ICON_ONE}
                  width={20}
                  height={20}
                />
              )}{' '}
              One lowercase character
            </div>
            <div className='flex items-center gap-2 text-gray-500'>
              {validation.hasUppercase ? (
                <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
              ) : (
                <CircleCross
                  fill={Colors.NAV_ICON_ONE}
                  width={20}
                  height={20}
                />
              )}{' '}
              One uppercase character
            </div>
            <div className='flex items-center gap-2 text-gray-500'>
              {validation.hasNumber ? (
                <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
              ) : (
                <CircleCross
                  fill={Colors.NAV_ICON_ONE}
                  width={20}
                  height={20}
                />
              )}{' '}
              One number
            </div>
            <div className='flex items-center gap-2 text-gray-500'>
              {validation.hasSpecialChar ? (
                <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
              ) : (
                <CircleCross
                  fill={Colors.NAV_ICON_ONE}
                  width={20}
                  height={20}
                />
              )}{' '}
              One special character
            </div>
            <div className='flex items-center gap-2 text-gray-500'>
              {validation.hasMinLength ? (
                <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
              ) : (
                <CircleCross
                  fill={Colors.NAV_ICON_ONE}
                  width={20}
                  height={20}
                />
              )}{' '}
              8 characters minimum
            </div>
          </div>
          <div className='flex flex-col items-start gap-2'>
            <label
              htmlFor='confirmPassword'
              className='text-sm font-semibold text-gray-950'
            >
              Confirm Password
            </label>
            <div className='relative w-full'>
              <input
                id='confirmPassword'
                type={showConfirmPassword ? 'text' : 'password'}
                className={`w-full rounded-md border border-gray-100 p-1 text-sm font-normal leading-6 text-gray-950 ${errors.confirmPassword ? 'border-red-500 bg-red-100' : ''}`}
                {...register('confirmPassword', {
                  required: 'Confirm Password is required',
                })}
              />
              <button
                type='button'
                className='absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer border-none bg-none focus:outline-none'
                onClick={toggleShowConfirmPassword}
              >
                {showConfirmPassword ? <EyeSlash /> : <Eye />}
              </button>
            </div>
            {errors.confirmPassword && (
              <p className='mt-1 text-xs text-red-500'>
                {errors.confirmPassword.message?.toString()}
              </p>
            )}
            {watchedPassword !== watch('confirmPassword') && (
              <p className='mt-1 text-xs text-red-500'>
                Passwords do not match
              </p>
            )}
          </div>
          <div className='w-full'>
            <button
              type='submit'
              className='mt-4 w-full rounded-full bg-red-500 px-4 py-2 text-white'
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
