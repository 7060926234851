import {
  DeleteModal,
  Input,
  InviteUserModal,
  LayoutContentWrapper,
  Select,
} from 'components'
import { usePageTitle } from '../../hooks'
import {
  CheckCircle,
  Pause,
  Play,
  Plus,
  Search,
  Trash,
} from '../../components/ui/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { UsersTable } from '../../components/UserManagementAdminTable'
import {
  adminUsersColumns,
  adminUserTypesFilterOptions,
  Colors,
} from '../../constnants'
import { AdminUserData } from '../../types'
import { UserEditPage } from './UserEditPage'

interface warningData {
  title: string
  description: string
  deleteTitle: string
  onConfirm?: () => void
  type: string
  deleteClassName?: string
  userToEdit: string
}

const initialActiveFilters: {
  userType: string
  search: string
} = {
  userType: '',
  search: '',
}

const initialUserProfile: AdminUserData = {
  id: 0,
  userName: '',
  userId: '',
  email: '',
  userType: '',
  lastLogin: '',
  dateJoined: '',
  accountStatus: '',
}

const warningInitialData = {
  title: '',
  description: '',
  deleteTitle: '',
  type: '',
  deleteClassName: '',
  userToEdit: '',
}

export const AdminManagementPage = () => {
  const data: AdminUserData[] = [
    {
      id: 1,
      userName: 'Alex Grant',
      userId: 'userId',
      email: 'alex.g@pearlstreetfilms.com',
      userType: 'Super Admin',
      lastLogin: '04/05/2023, 1:05 PM',
      dateJoined: '01/01/2023',
      accountStatus: 'Active',
    },
    {
      id: 2,
      userName: 'Dolores Keebler',
      userId: 'userId',
      email: 'dolores.k@planb.com',
      userType: 'Admin',
      lastLogin: 'Online',
      dateJoined: '01/01/2023',
      accountStatus: 'Active',
    },
    {
      id: 3,
      userName: 'Gilberto Simonis',
      userId: 'userId',
      email: 'gilberto.s@warnerbros.com',
      userType: 'Super Admin',
      lastLogin: 'Online',
      dateJoined: '01/01/2023',
      accountStatus: 'Active',
    },
    {
      id: 4,
      userName: 'Jean Olson',
      userId: 'userId',
      email: 'jean.o@playtone.com',
      userType: 'Super Admin',
      lastLogin: 'Online',
      dateJoined: '01/01/2023',
      accountStatus: 'Active',
    },
    {
      id: 5,
      userName: 'John Doe',
      userId: 'userId',
      email: 'john.d@universal.com',
      userType: 'Admin',
      lastLogin: '03/15/2023, 10:30 AM',
      dateJoined: '01/01/2023',
      accountStatus: 'Active',
    },
    {
      id: 6,
      userName: 'Jane Smith',
      userId: 'userId',
      email: 'jane.s@disney.com',
      userType: 'Admin',
      lastLogin: 'Online',
      dateJoined: '01/01/2023',
      accountStatus: 'Active',
    },
    {
      id: 7,
      userName: 'Michael Brown',
      userId: 'userId',
      email: 'michael.b@pixar.com',
      userType: 'Super Admin',
      lastLogin: '--',
      dateJoined: '01/01/2023',
      accountStatus: 'Pending',
    },
    {
      id: 8,
      userName: 'Emily Davis',
      userId: 'userId',
      email: 'emily.d@sony.com',
      userType: 'Super Admin',
      lastLogin: 'Online',
      dateJoined: '01/01/2023',
      accountStatus: 'Inactive',
    },
    {
      id: 9,
      userName: 'Christopher Wilson',
      userId: 'userId',
      email: 'christopher.w@mgm.com',
      userType: 'Admin',
      lastLogin: '01/10/2023, 8:15 AM',
      dateJoined: '01/01/2023',
      accountStatus: 'Inactive',
    },
    {
      id: 10,
      userName: 'Patricia Johnson',
      userId: 'userId',
      email: 'patricia.j@lionsgate.com',
      userType: 'Admin',
      lastLogin: '--',
      dateJoined: '01/01/2023',
      accountStatus: 'Pending',
    },
    {
      id: 11,
      userName: 'Robert Martinez',
      userId: 'userId',
      email: 'robert.m@paramount.com',
      userType: 'Super Admin',
      lastLogin: '12/25/2022, 11:00 AM',
      dateJoined: '01/01/2023',
      accountStatus: 'Inactive',
    },
    {
      id: 12,
      userName: 'Linda Anderson',
      userId: 'userId',
      email: 'linda.a@fox.com',
      userType: 'Super Admin',
      lastLogin: 'Inactive',
      dateJoined: '01/01/2023',
      accountStatus: 'Inactive',
    },
    {
      id: 13,
      userName: 'James Thomas',
      userId: 'userId',
      email: 'james.t@dreamworks.com',
      userType: 'Admin',
      lastLogin: 'Inactive',
      dateJoined: '01/01/2023',
      accountStatus: 'Inactive',
    },
    {
      id: 14,
      userName: 'Barbara Jackson',
      userId: 'userId',
      email: 'barbara.j@newline.com',
      userType: 'Admin',
      lastLogin: 'Inactive',
      dateJoined: '01/01/2023',
      accountStatus: 'Inactive',
    },
  ]

  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  const [sortingBY, setSortingBY] = useState<string>('createdDate')
  const [sortingOrder, setSortingOrder] = useState<string>('asc')
  const [totalUsersCount, setTotalUsersCount] = useState(14)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [filteredUsers, setFilteredUsers] = useState<AdminUserData[]>([])
  const [userProfileOpen, setUserProfileOpen] =
    useState<AdminUserData>(initialUserProfile)
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false)
  const [warningModalData, setWarningModalData] =
    useState<warningData>(warningInitialData)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false)
  const pageTitle = usePageTitle()

  useMemo(() => {
    const sortedData = [...filteredUsers].sort((a, b) => {
      if (sortingOrder === 'asc') {
        return a[sortingBY as keyof AdminUserData] >
          b[sortingBY as keyof AdminUserData]
          ? 1
          : -1
      } else {
        return a[sortingBY as keyof AdminUserData] <
          b[sortingBY as keyof AdminUserData]
          ? 1
          : -1
      }
    })
    setFilteredUsers(sortedData)
  }, [sortingBY, sortingOrder])

  useEffect(() => {
    let filteredData = data
    if (activeFilters.search || activeFilters.userType) {
      if (activeFilters.search) {
        filteredData = filteredData.filter(user =>
          user.userName
            .toLowerCase()
            .includes(activeFilters.search.toLowerCase()),
        )
      }
      if (activeFilters.userType && activeFilters.userType !== 'All') {
        filteredData = filteredData.filter(
          user => activeFilters.userType === user.userType,
        )
      }
    }
    setFilteredUsers(filteredData)
  }, [activeFilters])

  const handleOpenWarning = (field: string, name?: string, userId?: string) => {
    const userName = name ? name : userProfileOpen.userName
    const userToEdit = userId ? userId : userProfileOpen.id.toString()
    if (field === 'delete') {
      setWarningModalData({
        title: 'Are you sure you want to delete ' + userName + '?',
        description:
          'This will remove the user from the company account. This action is non-reversible.',
        deleteTitle: 'Delete user',
        type: 'delete',
        deleteClassName: '',
        userToEdit,
      })
    } else if (field === 'pause') {
      setWarningModalData({
        title: 'Are you sure you want to pause ' + userName + '?',
        description: 'This will pause the user from the company account.',
        deleteTitle: 'Pause user',
        type: 'pause',
        deleteClassName: 'bg-primary-red',
        userToEdit,
      })
    } else {
      setWarningModalData({
        title: 'Are you sure you want to reactivate ' + userName + '?',
        description: 'This will reactivate the user to the company account.',
        deleteTitle: 'Reactivate user',
        type: 'reactivate',
        deleteClassName: 'bg-primary-red',
        userToEdit,
      })
    }

    setOpenWarningPopup(true)
  }

  const successUpdated = () => {
    setOpenWarningPopup(false)
    setShowSuccessMessage(true)
    setUserProfileOpen({
      id: 0,
      userName: '',
      userId: '',
      email: '',
      userType: '',
      lastLogin: '',
      dateJoined: '',
      accountStatus: '',
    })
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
      setWarningModalData(warningInitialData)
    }, 3000)
  }

  const handleConfirm = () => {
    if (warningModalData.type === 'delete') {
      successUpdated()
      setSuccessMessage(`User account deleted`)
    } else if (warningModalData.type === 'pause') {
      successUpdated()
      setSuccessMessage(`User account paused`)
    } else {
      successUpdated()
      setSuccessMessage(`User account reactivated`)
    }
  }

  const clearStatuses = () => {
    setShowSuccessMessage(true)
    setInviteUserModalOpen(false)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
    }, 3000)
  }

  const handleInviteUser = () => {
    setSuccessMessage('New user invited')
    clearStatuses()
  }

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-white'
      wrapperChildrenClassName='h-full'
    >
      {userProfileOpen.id > 0 ? (
        <UserEditPage
          userData={userProfileOpen}
          setUserProfileOpen={setUserProfileOpen}
          initialUserProfile={initialUserProfile}
        />
      ) : (
        <div className='p-3'>
          <div className='flex items-center justify-between'>
            <h1 className={'text-2xl font-semibold text-primary-black'}>
              {pageTitle}
            </h1>
            <div className={'flex items-center gap-4'}>
              <Select
                id={'userType'}
                value={activeFilters['userType']}
                handleClick={selected => {
                  setActiveFilters({
                    ...activeFilters,
                    userType: selected.value,
                  })
                }}
                buttonClass='items-center'
                optionClass='whitespace-nowrap'
                options={adminUserTypesFilterOptions}
                size='small'
                placeholder={'All'}
                defaultValue={'all'}
                hasRadioButton={false}
              />
              <Input
                containerWrapperClassName='w-[200px] h-6'
                placeholder='Search Name'
                value={activeFilters['search']}
                onChange={e => {
                  setActiveFilters({ ...activeFilters, search: e.target.value })
                }}
                leftIcon={
                  <Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />
                }
              />
              <button
                className='inline-flex items-center gap-1 rounded-full bg-primary-red px-4 py-1.5 text-sm font-semibold text-primary-white transition-colors hover:bg-red-9'
                onClick={() => {
                  setInviteUserModalOpen(true)
                }}
              >
                New User
                <Plus fill={Colors.ICON_WHITE} width={20} height={20} />
              </button>
            </div>
          </div>
          <UsersTable
            columns={adminUsersColumns}
            data={filteredUsers}
            onSort={setSortingOrder}
            onSortOrder={setSortingBY}
            sortingBY={sortingBY}
            totalUsersCount={totalUsersCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setUserProfileOpen={setUserProfileOpen}
            handleOpenWarning={handleOpenWarning}
          />
          {showSuccessMessage && (
            <div className='fixed bottom-12 right-4 rounded-md bg-primary-white p-3 shadow-md'>
              <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
                {warningModalData.type === 'pause' ? (
                  userProfileOpen.lastLogin === 'Inactive' ? (
                    <Play fill={Colors.BASE_ICON} width={19} height={19} />
                  ) : (
                    <Pause fill={Colors.BASE_ICON} width={19} height={19} />
                  )
                ) : warningModalData.type === 'delete' ? (
                  <Trash
                    fill={Colors.ICON_NEGATIVE_RED}
                    width={19}
                    height={19}
                  />
                ) : (
                  <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
                )}{' '}
                {successMessage}
              </p>
            </div>
          )}
          <DeleteModal
            isOpen={openWarningPopup}
            handleClose={() => setOpenWarningPopup(false)}
            title={warningModalData.title}
            description={warningModalData.description}
            onDelete={() => {
              handleConfirm()
            }}
            deleteTitle={warningModalData.deleteTitle}
            zIndex={900}
            descriptionClassName={'text-center'}
            titleClassName={'text-center mb-1'}
            icon={
              warningModalData.type === 'pause' ? (
                <Pause fill={Colors.BASE_ICON} width={19} height={19} />
              ) : warningModalData.type === 'reactivate' ? (
                <Play fill={Colors.BASE_ICON} width={40} height={40} />
              ) : (
                <Trash fill={Colors.ICON_NEGATIVE_RED} width={40} height={40} />
              )
            }
            deleteClassName={warningModalData.deleteClassName}
          />
          <InviteUserModal
            title={'Invite New Greenlight Admin'}
            requestType={'inviteAdmin'}
            isOpen={inviteUserModalOpen}
            handleClose={() => setInviteUserModalOpen(false)}
            onSubmit={() => {
              handleInviteUser()
            }}
            modalZIndex={900}
          />
          {showSuccessMessage && (
            <div className='fixed bottom-12 right-4 rounded-md bg-primary-white p-3 shadow-md'>
              <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
                <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
                {successMessage}
              </p>
            </div>
          )}
        </div>
      )}
    </LayoutContentWrapper>
  )
}
