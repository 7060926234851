import {
  Attribute,
  ColumnsTalent,
  SocialItem,
  SocialSearchColumnKey,
  SocialSearchFilters,
  TalentType,
} from 'types'

import { formatNumberWithSuffix } from 'utils'

const socialColumnSortObj: Record<SocialSearchColumnKey, string | string[]> = {
  allFollowers: 'subscriber_total_count',
  socialVelocity: 'subscriber_velocity',
  socialVelocityPercentage: 'subscriber_velocity_percentage',
  mentions: 'total_neutral_mentions',
  sentiment: [
    'negative_percentage',
    'neutral_percentage',
    'positive_percentage',
  ],
  reach: 'reach_total_count',
  wikipediaPageViews: 'wikipedia_total_count',
  velocity: 'wikipedia_velocity',
  velocityPercentage: 'wikipedia_velocity_percentage',
}

function formatValid(
  value: string | number | undefined | null,
  formatter: (value: string | number) => string | number,
): string | number {
  return typeof value === 'string' || typeof value === 'number'
    ? formatter(value)
    : '-'
}

export const getTalentCellValue = (
  row: TalentType,
  column: ColumnsTalent,
  socialSearchFilters: SocialSearchFilters,
): string | string[] | number | SocialItem | Attribute | undefined => {
  const socialFilterValue = socialSearchFilters.social[0]
  const searchFilterValue = socialSearchFilters.search[0]
  if (column.attributes) {
    const attribute = row.attributes.find(attr => attr.name === column.accessor)

    if (attribute) {
      const formattedAttribute: Attribute = {
        count: formatNumberWithSuffix(attribute.values.count),
        rank: attribute.values.rank,
        percentage: attribute.values.percentage,
      }

      return formattedAttribute
    }

    return undefined
  } else if (column.social) {
    const socialKey = socialColumnSortObj[socialFilterValue]
    if (Array.isArray(socialKey)) {
      return socialKey.map(key =>
        formatValid(
          row?.socialMediaData?.[key],
          value => parseFloat(value).toFixed(0) + '%',
        ),
      )
    }

    return formatValid(row?.socialMediaData?.[socialKey], value =>
      formatNumberWithSuffix(value),
    )
  } else if (column.search) {
    const wikipedia_key = socialColumnSortObj[searchFilterValue]

    return formatValid(row?.socialMediaData?.[wikipedia_key], value =>
      formatNumberWithSuffix(value),
    )
  } else {
    return undefined
  }
}
