import { useCallback, useMemo } from 'react'
import { UsersTableBody, UsersTableHeader } from './components'
import { UsersTableProps } from './UsersTable.types'
import { managementRowsPerPageOptions } from 'constnants'
import {
  PaginationChangeParams,
  PaginationToolbar,
} from 'components/FilmTrackingTable'

export const UsersTable = ({
  columns,
  data,
  onSort,
  onSortOrder,
  sortingBY,
  setCurrentPage,
  currentPage = 1,
  rowsPerPage = 50,
  setRowsPerPage,
  totalUsersCount = 0,
  setUserProfileOpen,
  handleOpenWarning,
  isLoading,
}: UsersTableProps) => {
  const totalRows = totalUsersCount
  const totalPages = useMemo(
    () => Math.ceil(totalRows / rowsPerPage),
    [totalRows, rowsPerPage],
  )

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage?.(params.page)
      setRowsPerPage?.(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  return (
    <div className='h-[calc(100vh_-_125px)] w-full overflow-auto py-3 pb-12 xl:h-[calc(100vh_-_114px)]'>
      <table className='company-table'>
        <UsersTableHeader
          columns={columns}
          onSort={onSort}
          onSortOrder={onSortOrder}
          sortingBY={sortingBY}
        />
        <UsersTableBody
          data={data}
          columns={columns}
          setUserProfileOpen={setUserProfileOpen}
          handleOpenWarning={handleOpenWarning}
          isLoading={isLoading}
        />
      </table>
      <div className='fixed bottom-0 left-3 right-0'>
        <PaginationToolbar
          totalRows={totalRows}
          totalPages={totalPages}
          currentPage={currentPage}
          currentPageSize={rowsPerPage}
          pageSizeOptions={managementRowsPerPageOptions}
          onChange={handlePagination}
        />
      </div>
    </div>
  )
}
