import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './rootReducer'
import { middleware } from './middlewares/middleware'

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
})

export type RootState = ReturnType<typeof store.getState>
