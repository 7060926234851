export const formatCamelCaseString = (str: string | undefined): string => {
  if (typeof str !== 'string') {
    return ''
  }

  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, match => match.toUpperCase())
    .trim()
}
