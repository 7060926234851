import { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'
import AutoSizer from 'react-virtualized-auto-sizer'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import type {
  MgmtTableColData,
  MgmtTableRowData,
  MgmtTableTypes,
} from '../../types'
import { MgmtTableLoading } from './components'
import { createCellValueGetter, defaultSortHandler } from './utils'
import {
  defaultCellRenderer,
  defaultHeaderCellRenderer,
  defaultHeaderRowRenderer,
  defaultRowRenderer,
} from './renderers'

export function MgmtTable<
  Row extends MgmtTableRowData,
  Col extends MgmtTableColData,
>({
  rows,
  columns,
  footer,
  className,
  loading,
  fetching,
  sortConfig,
  cellContentFallback = '--',
  onSortChange,
  rowKeyGetter,
  cellContentGetter,
  sortHandler = defaultSortHandler,
  rowRenderer = defaultRowRenderer,
  cellRenderer = defaultCellRenderer,
  headerRowRenderer = defaultHeaderRowRenderer,
  headerCellRenderer = defaultHeaderCellRenderer,
}: MgmtTableTypes<Row, Col>['TableProps']) {
  const renderHeaderRow = () => {
    return headerRowRenderer({
      rowContent: columns.map(column => (
        <Fragment key={String(column.key)}>
          {headerCellRenderer({
            column,
            cellContent: column.title,
            sortConfig,
            sortHandler,
            onSortChange,
          })}
        </Fragment>
      )),
    })
  }

  const renderRowContent = (row: Row, rowIndex: number) => {
    const getCellValue = createCellValueGetter(row)
    return columns.map((column, columnIndex) => {
      const cellContent = fetching ? (
        <Skeleton height={22} className='pointer-events-none' />
      ) : (
        (cellContentGetter({
          row,
          column,
          rowIndex,
          columnIndex,
          getCellValue,
        }) ?? cellContentFallback)
      )

      return (
        <Fragment key={column.key}>
          {cellRenderer({ column, cellContent })}
        </Fragment>
      )
    })
  }

  const renderRows = () => {
    return rows.map((row, rowIndex) => (
      <Fragment key={String(rowKeyGetter({ row, rowIndex }))}>
        {rowRenderer({
          rowContent: renderRowContent(row, rowIndex),
        })}
      </Fragment>
    ))
  }

  return (
    <>
      <div className={twMerge('flex-grow', className)}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <div className='w-full overflow-auto px-3 py-3' style={{ height }}>
              <table className='w-full table-fixed border-collapse'>
                <thead>{renderHeaderRow()}</thead>
                <tbody>{renderRows()}</tbody>
              </table>
              {loading && <MgmtTableLoading />}
            </div>
          )}
        </AutoSizer>
      </div>
      {footer}
    </>
  )
}
