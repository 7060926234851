import { useState, useEffect } from 'react'
import { ChartCategory, ChartDetailAttributes } from 'types'

export const useSelectAttribute = (
  chartDetailAttributes: ChartDetailAttributes[] | null,
) => {
  const [selectedAttribute, setSelectedAttribute] =
    useState<ChartCategory | null>(null)

  useEffect(() => {
    if (chartDetailAttributes && chartDetailAttributes.length > 0) {
      const initialSelected = chartDetailAttributes
        .flatMap(category => category.options)
        .find(attr => attr !== undefined)

      if (initialSelected) {
        setSelectedAttribute(initialSelected)
      }
    }
  }, [chartDetailAttributes])

  const handleSelectChange = (selectedFilter: { [key: string]: string[] }) => {
    if (!chartDetailAttributes) {
      return
    }

    const selectedValue = Object.values(selectedFilter).flat()[1]

    const selectedAttr = chartDetailAttributes
      .flatMap(category => category.options)
      .find(attr => attr?.id === selectedValue)

    if (selectedAttr) {
      setSelectedAttribute(selectedAttr)
    }
  }

  return {
    selectedAttribute,
    handleSelectChange,
    setSelectedAttribute,
  }
}
