import { Key, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Tabs, Tab } from 'components'
import { Lists, Searches } from 'components/ui/icons'
import { Colors } from 'constnants'

interface TalentRightItemCardProps {
  icon?: ReactNode
  title?: string
  isSimple?: boolean
  contentMyLists?: ReactNode
  contentMySearches?: ReactNode
  contentProjectRole?: ReactNode
  wrapperClass?: string
  selectTab?: Key | null
  handleSelectTab?: (tab: Key | null) => void
}

export const TalentRightItemCard = ({
  title,
  icon,
  isSimple = false,
  contentMyLists,
  contentMySearches,
  contentProjectRole,
  wrapperClass,
  selectTab,
  handleSelectTab,
}: TalentRightItemCardProps) => {
  return (
    <div
      className={twMerge(
        'flex h-full overflow-hidden rounded bg-primary-white',
        wrapperClass,
      )}
    >
      {!isSimple ? (
        <Tabs
          initialSelectedTab={selectTab}
          handleSelectTab={handleSelectTab}
          wrapperClasses='overflow-y-auto'
        >
          <Tab
            key='first'
            label='My Lists'
            icon={
              <Lists
                fill={
                  selectTab === 'first' ? Colors.ICON_BRAND : Colors.BASE_ICON
                }
                width={20}
                height={20}
              />
            }
          >
            {contentMyLists}
          </Tab>

          <Tab
            key='second'
            label='My Searches'
            icon={
              <Searches
                fill={
                  selectTab === 'second' ? Colors.ICON_BRAND : Colors.BASE_ICON
                }
                width={20}
                height={20}
              />
            }
          >
            {contentMySearches}
          </Tab>
        </Tabs>
      ) : (
        <div className='flex h-full w-full flex-col'>
          <div className='flex h-[40px] w-full items-center gap-1 border-b-2 border-b-primary-black/10 p-2'>
            <div>{icon}</div>
            <span className='text-center text-sm font-semibold uppercase text-primary-grey'>
              {title}
            </span>
          </div>

          {contentProjectRole}
        </div>
      )}
    </div>
  )
}
