import type {
  MgmtTableCellValueTransformer,
  MgmtTableColData,
  MgmtTableRowData,
  MgmtTableTypes,
} from '../../types'

export function createCellValueGetter<
  Row extends MgmtTableRowData,
  Col extends MgmtTableColData,
>(row: Row) {
  const cellValueGetter: MgmtTableTypes<Row, Col>['CellValueGetter'] = <
    T extends keyof Row,
    R extends string | number | null | undefined,
  >(
    key: T,
    transform?: MgmtTableCellValueTransformer<Row, T, R>,
  ) => {
    const value = row[key]

    if (transform && value != null && value != undefined) {
      return transform(value as NonNullable<Row[T]>)
    }

    return value
  }

  return cellValueGetter
}

export function defaultSortHandler<
  Row extends MgmtTableRowData,
  Col extends MgmtTableColData,
>(
  params: MgmtTableTypes<Row, Col>['SortHandlerParams'],
): MgmtTableTypes<Row, Col>['SortConfig'] {
  const { column, sortConfig } = params

  if (sortConfig.by === column.sortKey) {
    return {
      ...sortConfig,
      order: sortConfig.order === 'ASC' ? 'DESC' : 'ASC',
    }
  }
  return {
    by: column.sortKey,
    order: 'DESC',
  }
}
