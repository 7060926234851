import { MouseEventHandler } from 'react'
import { Plus } from 'components'
import { Colors } from 'constnants'

interface MgmtCreateButtonProps {
  label: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const MgmtCreateButton = ({ label, onClick }: MgmtCreateButtonProps) => (
  <button
    className='inline-flex items-center rounded-full bg-primary-red px-2 py-1.5 text-sm font-semibold text-primary-white transition-colors hover:bg-red-9'
    onClick={onClick}
  >
    <span className='px-1.5'>{label}</span>
    <Plus fill={Colors.ICON_WHITE} width={20} height={20} />
  </button>
)
