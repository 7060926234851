export function convertToDate(dateString: string) {
  // Ensure the string is exactly 8 characters
  if (dateString.length !== 8) {
    throw new Error('Date string must be in YYYYMMDD format.')
  }

  // Extract year, month, and day from the string
  const year = parseInt(dateString.substring(0, 4), 10)
  const month = parseInt(dateString.substring(4, 6), 10) - 1 // Months are 0-indexed in Date
  const day = parseInt(dateString.substring(6, 8), 10)

  // Create a new Date object
  return new Date(year, month, day)
}
