export const paramsSerializer = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams()

  for (const key in params) {
    const value = params[key]

    if (Array.isArray(value) && value.length > 0) {
      value.forEach(item => searchParams.append(key, item))
    } else if (value !== undefined && value !== null) {
      searchParams.append(key, value)
    }
  }

  return searchParams.toString()
}
