import { twMerge } from 'tailwind-merge'
import { type CompFinderTableCellProps } from '../CompFinderTable.types'
import { formatNumberWithSuffix, formatSurveyDate } from '../../../utils'
import { PlatformMovie, RepeatTag } from 'components/ui/icons'
import { Colors } from '../../../constnants'
import { movieType } from 'types'

const getClassNames = (isLoading: boolean) =>
  twMerge(
    'flex text-xs text-primary-black',
    isLoading && 'bg-gray-500 text-transparent',
  )
export const CompFinderTableCell = ({
  column,
  row,
  isLoading = false,
  isHybrid,
  hasPlatformRelease,
}: CompFinderTableCellProps) => {
  let cellContent = ''
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday']

  if (weekdays.includes(column.value)) {
    cellContent = row[column.value as keyof movieType]
      ? `$${formatNumberWithSuffix(row[column.value as keyof movieType])}`
      : '--'
  } else if (column.value === 'release_date_id') {
    cellContent = formatSurveyDate(row.release_date_id, true)
  } else {
    cellContent = row[column.value as keyof movieType] || '--'
  }

  switch (column.value) {
    case 'film_name':
      return (
        <span
          className={twMerge(
            getClassNames(isLoading),
            'block truncate font-medium',
          )}
        >
          {row.film_name}
        </span>
      )
    case 'release_date_id':
      return (
        <span className={twMerge(getClassNames(isLoading), 'gap-2')}>
          {cellContent}{' '}
          {isHybrid && (
            <span className='text-primary-grey'>
              <RepeatTag fill={Colors.BASE_ICON} width={20} height={20} />
            </span>
          )}
        </span>
      )
    case 'ow_theatres':
      return (
        <span className={twMerge(getClassNames(isLoading), 'font-medium')}>
          <span className={'mr-[3px]'}>
            {Number(cellContent).toLocaleString('en-US')}
          </span>
          {hasPlatformRelease && (
            <PlatformMovie fill={Colors.BASE_ICON} width={16} height={16} />
          )}
        </span>
      )
    case 'open_weekend':
      return (
        <span className={twMerge(getClassNames(isLoading), 'font-medium')}>
          ${formatNumberWithSuffix(row.open_weekend)}
        </span>
      )
    case 'total_gross':
      return (
        <span className={twMerge(getClassNames(isLoading), 'font-medium')}>
          ${formatNumberWithSuffix(row.total_gross)}
        </span>
      )
    case 'budget_range':
      return (
        <span className={twMerge(getClassNames(isLoading))}>
          ${cellContent}
        </span>
      )
    case 'film_group_key':
      return (
        <span className={twMerge(getClassNames(isLoading))}>
          <span className={'inline-block rounded-full bg-grey-3 px-2 py-0.5'}>
            {row.film_group_name}
          </span>
        </span>
      )
    case 'tags':
      return (
        <span className={twMerge(getClassNames(isLoading))}>
          {Array.isArray(cellContent) &&
            cellContent.map((tag: string, index: number) => {
              if (index === 2)
                return (
                  <span
                    key={index}
                    className={
                      'inline-block rounded-full bg-grey-4 px-2 py-0.5'
                    }
                  >
                    +{cellContent.length - 2}
                  </span>
                )
              if (index > 2) return null
              return (
                <span
                  key={index}
                  className='mr-1 inline-block rounded-full bg-grey-4 px-2 py-0.5'
                >
                  {tag}
                </span>
              )
            })}
        </span>
      )
    case 'genres':
      return (
        <span className={twMerge(getClassNames(isLoading))}>
          {Array.isArray(cellContent)
            ? cellContent.map((tag: string, index: number) => {
                if (index === 0)
                  return (
                    <span
                      key={tag}
                      className={
                        'mr-1 inline-block rounded-full bg-[#2C5684] px-2 py-0.5 text-primary-white'
                      }
                    >
                      {tag}
                    </span>
                  )
                if (index > 2) return null
                return (
                  <span
                    key={tag}
                    className='mr-1 inline-block rounded-full bg-grey-4 px-2 py-0.5'
                  >
                    {tag}
                  </span>
                )
              })
            : (row.primary_genre_name || row.secondary_genre_name) && (
                <>
                  {row.primary_genre_name && (
                    <span
                      className={
                        'mr-1 inline-block rounded-full bg-[#2C5684] px-2 py-0.5 text-primary-white'
                      }
                    >
                      {row.primary_genre_name}
                    </span>
                  )}
                  {row.secondary_genre_name && (
                    <span className='mr-1 inline-block rounded-full bg-grey-4 px-2 py-0.5'>
                      {row.secondary_genre_name}
                    </span>
                  )}
                </>
              )}
        </span>
      )
    case 'monday':
    case 'tuesday':
    case 'wednesday':
    case 'thursday':
      return (
        <span className={twMerge(getClassNames(isLoading), 'font-medium')}>
          {cellContent}
        </span>
      )
    default:
      return <span className={getClassNames(isLoading)}>{cellContent}</span>
  }
}
