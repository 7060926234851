export const stylesTooltip = {
  width: '150px',
  backgroundColor: '#99292C',
  color: '#ffff',
  textSize: '12px',
  fontWeight: 500,
  borderRadius: '6px',
  boxShadow: '0px 8px 16px 0px #00000033',
  paddingLeft: '10px',
  paddingRight: '6px',
}
