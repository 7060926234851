import { useNavigate } from 'react-router-dom'
import { TalentName, SectionTitle, Image } from 'components'
import { TalentType } from 'types'

interface TalentDetailSimilarTalentProps {
  similarTalents: TalentType[]
}

export const TalentDetailSimilarTalent = ({
  similarTalents,
}: TalentDetailSimilarTalentProps) => {
  const navigate = useNavigate()
  const handleNavigation = (talentId: string) => {
    navigate(`/talent/${talentId}`, { replace: true })
  }

  return (
    <div className='pb-5'>
      <SectionTitle title='Similar talent' className='pb-2' />
      <ul className='flex max-w-[1000px] space-x-4 overflow-x-auto'>
        {similarTalents.map(talent => (
          <li
            key={talent.talentPerson.talentId}
            className='group flex max-w-16 flex-col gap-0.5 hover:cursor-pointer'
            onClick={() => handleNavigation(talent.talentPerson.talentId ?? '')}
          >
            <Image
              alt={`${talent.talentPerson.talentName}`}
              src={talent.talentPerson.profileImage}
              className='h-24 w-16'
            />
            <TalentName
              firstName={talent.talentPerson.talentName}
              lastName=''
              size='xs'
              className='font-normal'
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
