import { Link } from 'react-router-dom'
import { Urls } from 'types'
import { useGetAllTalentsQuery } from 'store/api'
import { useMemo } from 'react'

interface ProjectCastProps {
  casts: string[] | undefined
}

export const ProjectCast = ({ casts = [] }: ProjectCastProps) => {
  const { data: talentsData } = useGetAllTalentsQuery()

  const talentMap = useMemo(() => {
    return talentsData
      ? Object.fromEntries(talentsData.map(item => [item.talent_name, item]))
      : {}
  }, [talentsData])

  if (!casts.length) return null

  return casts.map((cast, i) => {
    const talent = talentMap[cast]
    const isLastItem = i === casts.length - 1
    const separator = isLastItem ? '' : ', '
    const linkStyle = { color: '#CC363A' }

    return talent && talent.talent_id ? (
      <Link
        key={talent.talent_id}
        to={`${Urls.TALENT}/${talent.talent_id}`}
        style={linkStyle}
      >
        {cast}
        {separator}
      </Link>
    ) : (
      <span key={cast}>
        {cast}
        {separator}
      </span>
    )
  })
}
