import { FC } from 'react'

import { twMerge } from 'tailwind-merge'
import { findLabel, toLocalNumber } from './utils'
import { DataFiltersType, OptionFilterDef } from 'types'

type OptionFilter = {
  value: string | number
  label: string
}

type UpdatedFilters = {
  [key: string]: any
}

interface FilterDisplayProps {
  updatedFilters: UpdatedFilters
  dataFilters: DataFiltersType[]
}

export const FilterDisplay: FC<FilterDisplayProps> = ({
  updatedFilters,
  dataFilters,
}) => {
  return (
    <>
      {Object.entries(updatedFilters)
        .filter(([key]) => key !== 'onlyPlatformMovies') // Exclude 'onlyPlatformMovies'
        .map(([key, value], index) => {
          const { title, displayValue } = getFilterDisplayValue(
            key,
            value,
            dataFilters,
          )

          return (
            <p key={index} className={twMerge('inline capitalize')}>
              {`${title}: `}
              <span className={'inline font-bold'}>{displayValue}</span>
              {index !== Object.entries(updatedFilters).length - 2 && (
                <span className={'pr-0.5 font-bold'}>,</span>
              )}
            </p>
          )
        })}
    </>
  )
}

function getFilterDisplayValue(
  key: string,
  value: any,
  dataFilters: DataFiltersType[],
): { title: string; displayValue: string } {
  const title = formatTitle(key)

  switch (key) {
    case 'distributor':
      return {
        title,
        displayValue: formatDistributor(value, dataFilters),
      }
    case 'releaseMonth':
      return {
        title,
        displayValue: formatArrayValues(dataFilters, value, 'releaseMonth'),
      }
    case 'releaseYears':
      return {
        title,
        displayValue: formatArrayValues(dataFilters, value, 'releaseYears'),
      }
    case 'boxOffice':
      return {
        title,
        displayValue: formatBoxOffice(value),
      }
    case 'openingWeekendTheaters':
      return {
        title,
        displayValue: formatOpeningWeekendTheaters(value),
      }
    case 'totalGross':
      return {
        title,
        displayValue: formatTotalGross(value),
      }
    case 'primaryGenre':
      return {
        title,
        displayValue: formatLabels(dataFilters, value, 'primaryGenre'),
      }
    case 'secondaryGenre':
      return {
        title,
        displayValue: formatLabels(dataFilters, value, 'secondaryGenre'),
      }
    case 'rating':
      return {
        title: 'Rating',
        displayValue: formatArrayValues(dataFilters, value, 'rating'),
      }
    case 'budget':
      return {
        title: 'Budget',
        displayValue: formatArrayValues(
          dataFilters,
          value,
          'budget',
          customSortRange,
        ),
      }
    case 'groups':
      return {
        title: 'Group',
        displayValue: formatArrayValues(dataFilters, value, 'groups'),
      }
    case 'psrr':
      return {
        title: 'PSRR',
        displayValue: formatArrayOrSingleValue(value),
      }
    default:
      return { title, displayValue: formatLabels(dataFilters, value, key) }
  }
}

function formatTitle(key: string): string {
  const titles: { [key: string]: string } = {
    distributor: 'Distributor',
    releaseMonth: 'Release Month',
    releaseYears: 'Release Year',
    boxOffice: 'Box Office',
    openingWeekendTheaters: 'OW Theaters',
    totalGross: 'Total Gross',
    primaryGenre: 'Primary Genre',
    secondaryGenre: 'Secondary Genre',
    rating: 'Rating',
    budget: 'Budget',
    groups: 'Groups',
    psrr: 'PSRR',
  }
  return titles[key] || key
}

function formatDistributor(value: any, dataFilters: DataFiltersType[]): string {
  if (Array.isArray(value) && value[0] !== 'all') {
    return value
      .map(val => {
        const distributorFilter = dataFilters.find(
          filterOption => filterOption.id === 'distributor',
        )

        if (!distributorFilter || !Array.isArray(distributorFilter.options)) {
          return null
        }

        const distributorOptions = distributorFilter.options.flatMap(option => {
          if ('options' in option) {
            return (option as OptionFilterDef).options
          } else if ('value' in option) {
            return [option as OptionFilter]
          } else {
            return []
          }
        })

        const distributor = distributorOptions.find(
          option => option.value === val,
        )

        return distributor ? distributor.label : null
      })
      .filter(Boolean)
      .join(', ')
  }
  return 'All'
}

function formatRangeLabel(
  dataFilters: DataFiltersType[],
  value: any[],
  labelType: string,
): string {
  const startLabel = findLabel(dataFilters, labelType, value[0]) || ''
  const endLabel =
    value.length > 1
      ? '-' + findLabel(dataFilters, labelType, value[value.length - 1])
      : ''
  return startLabel + endLabel || 'All'
}

function formatRange(value: any[]): string {
  return (
    (value[0] || '') +
      (value.length > 1 ? '-' + value[value.length - 1] : '') || 'All'
  )
}

function formatBoxOffice(value: any[]): string {
  return value[0] !== 0 || value[1] !== 400
    ? `${value[0]}-${value[1] === 400 ? '400' : value[1]}`
    : 'All'
}

function formatOpeningWeekendTheaters(value: any[]): string {
  return value[0] !== 500 || value[1] !== 5000
    ? `${toLocalNumber(value[0])}-${value[1] === 5000 ? `${toLocalNumber('5000')}+` : toLocalNumber(value[1])}`
    : 'All'
}

function formatTotalGross(value: any[]): string {
  return value[0] !== 0 || value[1] !== 990
    ? `${toLocalNumber(value[0])}-${toLocalNumber(value[1])}`
    : 'All'
}

function formatLabels(
  dataFilters: DataFiltersType[],
  value: any[],
  labelType: string,
): string {
  return Array.isArray(value)
    ? value.map(val => findLabel(dataFilters, labelType, val)).join(', ') ||
        'All'
    : 'All'
}

function formatArrayOrSingleValue(value: any): string {
  return Array.isArray(value) ? value.join(', ') : value || 'All'
}

function formatArrayValues(
  dataFilters: DataFiltersType[],
  values: any[],
  labelType: string,
  sort?: (value: string[]) => string[],
): string {
  if (Array.isArray(values)) {
    const tmp = sort ? sort(values) : values

    return (
      tmp.map(val => findLabel(dataFilters, labelType, val)).join(', ') || 'All'
    )
  }

  return 'All'
}

function customSortRange(values: string[]) {
  const sortOrder: {
    [key in
      | 'M_BELOW_1'
      | 'M_1_9'
      | 'M10_24'
      | 'M25_49'
      | 'M50_74'
      | 'M75_99'
      | 'M100_149'
      | 'M200Plus']: number
  } = {
    M_BELOW_1: 1,
    M_1_9: 2,
    M10_24: 3,
    M25_49: 4,
    M50_74: 5,
    M75_99: 6,
    M100_149: 7,
    M200Plus: 8,
  }

  return [...values].sort((a, b) => {
    return (
      sortOrder[a as keyof typeof sortOrder] -
      sortOrder[b as keyof typeof sortOrder]
    )
  })
}
