export enum MovieDetailsKey {
  GENRE = 'genre',
  RATING = 'rating',
  RUN_TIME = 'runTime',
  RELEASE_DATE = 'releaseDate',
  BUDGET = 'budget',
  DIRECTOR = 'director',
  WRITER = 'writer',
  RELEASE_PATTERN = 'releasePattern',
  CAST = 'cast',
}
