import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import { moviesFiltersType, OptionType, SavedSearchTypes } from '../types'
import { commonToastStyles } from 'utils'
import { useEditSavedSearchPresetMutation } from 'store/api'
import { toast } from 'react-toastify'

export const useCompFinderSearchFilter = (options?: OptionType[]) => {
  const [searchTerm, setSearchTerm] = useState('')

  const [debouncedValue, setDebouncedValue] = useState('')
  const [editSearchPreset] = useEditSavedSearchPresetMutation()

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedValue(searchTerm)
    }, 750)

    return () => clearTimeout(delayInputTimeoutId)
  }, [searchTerm])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target as HTMLInputElement) {
      setSearchTerm(e.target.value)
    }
  }

  const handleEditSearchPreset = (
    filters: moviesFiltersType,
    id: string,
    currentSearchTitle: string,
    setUpdatedFilters: (filters: moviesFiltersType) => void,
    type?: SavedSearchTypes,
  ) => {
    if (!id) {
      return
    }
    editSearchPreset({
      searchParameters: filters,
      type: type ? type : SavedSearchTypes.COMP,
      id: id,
    })
      .unwrap()
      .then(() => {
        toast.success(
          <p>
            <b>{currentSearchTitle}</b> has been updated
          </p>,
          {
            ...commonToastStyles,
            bodyClassName: 'flex  px-0 py-0 items-start w-[288px]',
          },
        )
        setUpdatedFilters(filters)
      })
      .catch(error => {
        toast.error(`Failed to update Search. Error: ${error}`)
      })
  }

  const filteredOptions = useMemo(
    () =>
      options?.filter(option =>
        option.label
          .toLowerCase()
          .replace(/\s/g, '')
          .includes(searchTerm.toLowerCase()),
      ) || [],
    [searchTerm, options, setSearchTerm],
  )

  return {
    searchTerm,
    debouncedValue,
    handleSearchChange,
    handleEditSearchPreset,
    filteredOptions,
    setSearchTerm,
  }
}
