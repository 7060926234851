import { Input } from 'components'
import { OptionType } from 'types'
import { Search, XCircle } from 'components/ui/icons'
import { Colors } from 'constnants'
import { useEntityLookupByField } from 'hooks'

interface EntityLookupByFieldProps<T = number> {
  notFoundTitle?: string
  notFoundDescription?: string
  className?: string
  options: OptionType<T>[]
  onSelect: (option: OptionType<T>) => void
  onClear?: () => void
  load: (searchTerm: string) => Promise<void>
}

export const EntityLookupByField = <T,>({
  notFoundTitle,
  notFoundDescription,
  className,
  options,
  onSelect,
  onClear,
  load,
}: EntityLookupByFieldProps<T>) => {
  const {
    searchTerm,
    suggestions,
    showAutocomplete,
    setSearchTerm,
    setShowAutocomplete,
    handleInputChange,
  } = useEntityLookupByField({
    options,
    load,
  })

  const handleClearSelection = () => {
    setSearchTerm('')
    setShowAutocomplete(false)
    onClear?.()
  }

  const handleAutocompleteClick = (option: OptionType<T>) => {
    setSearchTerm(option.label)
    setShowAutocomplete(false)
    onSelect(option)
  }

  return (
    <Input
      containerWrapperClassName={className || 'w-[200px] h-6'}
      placeholder='Name Lookup'
      value={searchTerm}
      onChange={handleInputChange}
      leftIcon={<Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />}
      rightIcon={
        searchTerm ? (
          <XCircle
            onClick={handleClearSelection}
            className='mr-1 hover:cursor-pointer'
          />
        ) : undefined
      }
      autoComplete='off'
      isShowAutocomplete={showAutocomplete}
      autocompleteData={suggestions}
      autocompleteClassName='left-0 top-7'
      handleAutocompleteClick={handleAutocompleteClick}
      notFoundTitle={notFoundTitle}
      notFoundDescription={notFoundDescription}
    />
  )
}
