import { NameValuePair } from '../types'

export const transformObjectToArray = <T extends object>(
  obj: T,
): Array<NameValuePair<T>> => {
  if (!obj) {
    return []
  }
  return Object.entries(obj).map(([key, value]) => ({
    name: key,
    value: value as T[keyof T],
    hasInfo: value.hasInfo,
  }))
}
