import { Colors } from 'constnants'
import { Button, StatusTab } from 'components'
import { Minus, Plus } from 'components/ui/icons'

interface MyProjectsActiveContentProps {
  showRecentActivity: boolean
  toggleRecentActivity: () => void
  allProjectsAmount: number | undefined
  inDevelopmentAmount: number | undefined
  conceptAmount: number | undefined
  releasedAmount: number | undefined
}

export const MyProjectsActiveContent = ({
  showRecentActivity,
  toggleRecentActivity,
  allProjectsAmount = 0,
  inDevelopmentAmount = 0,
  conceptAmount = 0,
  releasedAmount = 0,
}: MyProjectsActiveContentProps) => {
  return (
    <div className='-mt-0.5 border-t-2 border-t-grey-5 p-3'>
      <div className='flex space-x-2 pb-2'>
        {allProjectsAmount > 0 && (
          <StatusTab status='All' amount={allProjectsAmount} isActive />
        )}
        {conceptAmount > 0 && (
          <StatusTab status='Concept' amount={conceptAmount} />
        )}
        {inDevelopmentAmount > 0 && (
          <StatusTab status='In development' amount={inDevelopmentAmount} />
        )}
        {releasedAmount > 0 && (
          <StatusTab status='released' amount={releasedAmount} />
        )}
      </div>

      <Button kind='text' size='small' onClick={toggleRecentActivity}>
        <div className='flex items-center gap-1'>
          {!showRecentActivity ? (
            <Plus fill={Colors.BASE_ICON} width={16} height={16} />
          ) : (
            <Minus fill={Colors.BASE_ICON} width={12} height={16} />
          )}
          <span>Recent activity</span>
        </div>
      </Button>
    </div>
  )
}
