import { EntitySearchByField } from 'components/EntitySearchByField'

interface TalentLookupProps {
  className?: string
  onChange: (searchTerm: string) => void
  onClear: () => void
}

export const TalentLookup = ({
  className,
  onChange,
  onClear,
}: TalentLookupProps) => {
  return (
    <EntitySearchByField
      className={className}
      onSearch={onChange}
      onClear={onClear}
    />
  )
}
