import { useState } from 'react'
import { apiURL } from '../store/api/api'
import { TalentMetricsResponse } from '../store/api'

export type Film = {
  film_id: string
  film_key: string
  film_name: string
  primary_image_url: string
  release_year: string
  genres: string
  companies: string
  cast: string
  imdb_url: string
}

export type FilmData = {
  talent_id: string
  film_id: string
  film: Film[]
}
export type TalentDetails = {
  talent_id: string
  talent_name: string
  talent_key: string
  x_url: string
  hashtags: string
  biography: string
  instagramUrl: string
  xHandle: string
  gender: string
  race: string
  height: string
  wikipediaArticleTitle: string
  profile_image: string
  dateOfBirth: string
  date_of_birth: string
  role_id: string
  talentFilms: FilmData[]
}
export default function useTalentById() {
  const [talent, setTalent] = useState<TalentMetricsResponse>()
  const [talentDetails, setTalentDetails] = useState<TalentDetails>()
  const [talentList, setTalentList] = useState<TalentMetricsResponse[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const fetchTalent = async (
    talentId: string,
    top2Box: string,
    audienceFilter?: string,
  ) => {
    setLoading(true)
    const url = new URL(`${apiURL}/talentMetrics/talent/${talentId}/metrics?`) //top2Box=${top2Box==='topTwoBox'?'true':'false'}&audienceFilter=${audienceFilter}
    url.searchParams.append(
      'top2Box',
      top2Box === 'topTwoBox' ? 'true' : 'false',
    )
    if (audienceFilter) {
      url.searchParams.append('audienceFilter', audienceFilter)
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    const data: TalentMetricsResponse = await response.json()

    return data || {}
  }

  const fetchTalentDetails = async (talentId: string) => {
    setLoading(true)
    const url = new URL(`${apiURL}/talent/${talentId}`)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    const data: any = await response.json()

    return data || {}
  }

  const getTalentsByIds = async (
    talentIds: string[],
    top2Box: string,
    audienceFilter?: string,
  ) => {
    try {
      const fetchTalents = talentIds.map(talentId =>
        fetchTalent(talentId, top2Box, audienceFilter),
      )
      const talentsData = await Promise.all(fetchTalents)
      const filterEmptyTalents = talentsData.filter(talent => !!talent.talentId)

      setTalentList(filterEmptyTalents)
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  const getTalent = async (talentId: string, top2Box: string) => {
    try {
      const talentsData = await fetchTalent(talentId, top2Box)
      setTalent(talentsData)
      return talentsData
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  const getTalentDetails = async (talentId: string) => {
    try {
      const talentsDetailsData = await fetchTalentDetails(talentId)

      setTalentDetails(talentsDetailsData)
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    talent,
    getTalent,
    talentDetails,
    getTalentDetails,
    talentList,
    getTalentsByIds,
    loading,
    error,
  }
}
