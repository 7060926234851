import { ReleaseCalendarSelectKey } from 'types'

export const sortedGroupedKeys = [
  ReleaseCalendarSelectKey.MAJOR,
  ReleaseCalendarSelectKey.MINOR,
  ReleaseCalendarSelectKey.OTHER,
  ReleaseCalendarSelectKey.PREMIERES_EVENTS,
  ReleaseCalendarSelectKey.STREAMERS,
  ReleaseCalendarSelectKey.CABLE_BROADCAST,
  ReleaseCalendarSelectKey.SPORTING_EVENTS,
  ReleaseCalendarSelectKey.HOLIDAY_AND_SCHOOL,
]
