import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import {
  ChartHighlightOutline,
  ChevronDown,
  ChevronUp,
  Pencil,
  Trash,
} from 'components/ui/icons'
import { Anchor, IconButton, TalentBottomCard } from 'components'
import { Colors } from 'constnants'
import { RoleProject } from 'types'

interface MyRoleCardProps {
  role: RoleProject
  onOpenDeleteModal: () => void
  onOpenEditModal: () => void
}

export const MyRoleCard = ({
  role,
  onOpenDeleteModal,
  onOpenEditModal,
}: MyRoleCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }
  const amountTalent = role.talents.length

  return (
    <div className='w-full overflow-hidden rounded shadow-md'>
      <div className='bg-primary-white p-2'>
        <div className='flex items-center'>
          <div className='w-full'>
            <div className='flex w-full items-center justify-between gap-1'>
              <div className='flex items-center'>
                <ChartHighlightOutline />
                <span className='text-xs uppercase text-primary-grey'>
                  {role.roleType.label}
                </span>
              </div>
              <div className='flex'>
                <IconButton onClick={onOpenEditModal}>
                  <Pencil fill={Colors.BASE_ICON} width={16} height={16} />
                </IconButton>
                <IconButton onClick={onOpenDeleteModal}>
                  <Trash fill={Colors.BASE_ICON} width={16} height={16} />
                </IconButton>
              </div>
            </div>
            <p className='pt-2 text-lg font-medium leading-6 text-primary-black'>
              “{role.roleName}”
            </p>
          </div>
        </div>
        <p
          className={twMerge(
            'w-full text-xs text-primary-grey transition-all duration-500',
            isExpanded ? 'text-ellipsis' : 'line-clamp-2',
          )}
        >
          {role.description}
        </p>
        <Anchor
          primary={false}
          className='mt-1 flex items-center border-b-0 text-xs font-normal text-primary-grey'
          onClick={toggleExpand}
        >
          {!isExpanded ? (
            <div className='flex items-center gap-1'>
              <span>Read more</span>
              <ChevronDown fill={Colors.BASE_ICON} width={12} height={12} />
            </div>
          ) : (
            <div className='flex items-center gap-1'>
              <span>Read less</span>
              <ChevronUp fill={Colors.BASE_ICON} width={12} height={12} />
            </div>
          )}
        </Anchor>
      </div>
      <div className='grid max-h-full grid-cols-2 gap-2 p-2'>
        <TalentBottomCard
          onOpenEditModal={onOpenEditModal}
          title='Attached Talent'
          detailsName='Talent Details'
          isAttachedTalent
          talentName={role.talents[0]?.talentName}
          profileImage={role.talents[0]?.profileImage}
        />
        <TalentBottomCard
          onOpenEditModal={onOpenEditModal}
          title='Talent Lists'
          detailsName='Open role in talent'
          amountTalent={amountTalent}
        />
      </div>
    </div>
  )
}
