import { twMerge } from 'tailwind-merge'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { addQueryParam, commonToastStyles, prepareUrlParams } from 'utils'
import {
  IconButton,
  MyListMenu,
  TalentRightItem,
  TalentRightItemCard,
  ProjectsListSearch,
  Trash,
  DeleteModal,
  EditSearchResultsTalentModal,
} from 'components'
import {
  ChevronLeft,
  ChevronRight,
  Folder,
  Searches,
} from 'components/ui/icons'
import { useIsMobile, useKeyPress, useScrollLock, useSearchFilter } from 'hooks'
import { Colors } from 'constnants'
import { moviesFiltersType, OptionType } from '../../types'
import {
  useDeleteSavedSearchPresetMutation,
  useGetAllTalentsSerchesQuery,
} from '../../store/api'
import { toast } from 'react-toastify'

interface TalentRightSidebarProps {
  isOpen?: boolean
  handleOpen: () => void
  handleClose: () => void
  isSavedSearchChanged?: boolean
  setIsSavedSearchChanged?: (isChanged: boolean) => void
  handleAddProject?: (project: OptionType<number>) => void
  resetSavedSearch?: () => void
  activeFilters?: moviesFiltersType
  searchToModify?: string
  setUpdatedFilters?: (filters: moviesFiltersType) => void
  setSearchModalOpened?: (status: boolean) => void
  resetFilters?: () => void
}

export const CompFinderRightSidebar = ({
  isOpen,
  handleOpen,
  handleClose,
  handleAddProject,
  setIsSavedSearchChanged,
  isSavedSearchChanged,
  resetSavedSearch,
  activeFilters,
  searchToModify,
  setUpdatedFilters,
  setSearchModalOpened,
  resetFilters,
}: TalentRightSidebarProps) => {
  const { data: allList } = useGetAllTalentsSerchesQuery({ type: 'comp' })
  const [deleteSavedSearchPreset] = useDeleteSavedSearchPresetMutation()
  const dataToUpdateSearchList = localStorage.getItem('dataToUpdateSearchList')
  const navigate = useNavigate()
  const [searchTermPresetSearch, setSearchTermPresetSearch] =
    useState<string>('')
  const [activeSavedSearch, setActiveSavedSearch] = useState<string>('')
  const [searchList, setSearchList] = useState<OptionType[]>([])
  const [isOpenEditSearchModal, setIsOpenEditSearchModal] =
    useState<boolean>(false)
  const [isOpenDeletePrivateSearchModal, setOpenDeletePrivateSearchModal] =
    useState<boolean>(false)

  const { handleEditSearchPreset } = useSearchFilter()
  const handleSearchChangePresetSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTermPresetSearch(e.target.value)
  }

  const { lockScroll, unlockScroll } = useScrollLock()
  const isMobile = useIsMobile()

  const selectedPresetSearch = useRef<{ id: string; value?: string }>({
    id: '',
    value: '',
  })

  const handleClickItemPresetSearchList = (search?: OptionType) => {
    if (dataToUpdateSearchList === search?.id) {
      localStorage.removeItem('dataToUpdateSearchList')
      const newURL = addQueryParam('resetFilter', 'true')
      navigate(newURL)
    } else {
      localStorage.setItem('dataToUpdateSearchList', search?.id || '')
      setActiveSavedSearch(search?.id || '')
      if (search?.search_parameters) {
        navigate(search.search_parameters)
      }
    }
  }

  const handleDeletePrivateSearch = (id: string, value: string) => {
    setOpenDeletePrivateSearchModal(true)
    selectedPresetSearch.current = { id, value }
  }

  const handleEditPresetSearchList = (id: string, value: string) => {
    setIsOpenEditSearchModal(true)
    selectedPresetSearch.current = { id, value }
  }

  const handleSaveSearchResults = () => {
    const newURL = addQueryParam('openCallout', 'true')
    navigate(newURL)
  }

  useEffect(() => {
    setActiveSavedSearch(dataToUpdateSearchList || '')
  }, [dataToUpdateSearchList])

  useEffect(() => {
    if (allList) {
      setSearchList(
        allList.map(item => ({
          value: item.name,
          id: item.search_id,
          label: item.name,
          search_parameters: prepareUrlParams(item.search_parameters),
        })),
      )
    }
  }, [allList])

  useEffect(() => {
    if (isOpen) {
      handleOpen()
      lockScroll()
    } else {
      handleClose()
      unlockScroll()
    }
  }, [isOpen])

  useKeyPress(() => {
    if (isOpen) {
      handleClose()
    }
  }, ['Escape'])

  const toggleSidebar = () => {
    isOpen ? handleClose() : handleOpen()
  }

  const filteredSearchList = useMemo(
    () =>
      searchList?.filter(option =>
        option.label
          .toLowerCase()
          .includes(searchTermPresetSearch.toLowerCase()),
      ) || [],
    [searchList, searchTermPresetSearch],
  )

  return (
    <div
      className={twMerge(
        'sticky top-0 float-right flex h-[calc(100vh-44px)] flex-col place-items-start gap-2 bg-primary-blue-medium p-2' +
          ' transition-all duration-300',
        isOpen && !isMobile ? 'w-[328px]' : 'w-[88px]',
      )}
    >
      <IconButton
        className='rounded bg-primary-grey p-0.5 hover:opacity-70'
        onClick={toggleSidebar}
      >
        {!isOpen ? (
          <ChevronLeft width={16} height={16} fill={Colors.ICON_WHITE} />
        ) : (
          <ChevronRight width={16} height={16} fill={Colors.ICON_WHITE} />
        )}
      </IconButton>
      <div
        className={twMerge(
          'h-full flex-col gap-2 transition-opacity duration-300',
          isOpen ? 'hidden opacity-0' : 'flex opacity-100',
        )}
      >
        <TalentRightItem
          kind='base'
          icon={<Folder fill={Colors.BASE_ICON} />}
          title='Project Comps'
          handleOpenClick={() => handleOpen()}
        />
        <TalentRightItem
          kind='base'
          title='Searches'
          icon={<Searches fill={Colors.BASE_ICON} />}
          handleOpenClick={() => handleOpen()}
        />
      </div>
      <div
        className={twMerge(
          'flex h-[calc(100%-28px)] min-w-[312px] max-w-full flex-col gap-2 transition-opacity duration-300',
          isOpen ? 'flex opacity-100' : 'hidden opacity-0',
        )}
      >
        <TalentRightItemCard
          icon={<Folder fill={Colors.BASE_ICON} />}
          title='Project Comp lists'
          wrapperClass='h-1/2'
          isSimple
          contentProjectRole={
            <div className={'overflow-auto p-3'}>
              <ProjectsListSearch onSelectProject={handleAddProject} />
            </div>
          }
        />

        <TalentRightItemCard
          icon={<Searches fill={Colors.BASE_ICON} />}
          title={'Saved Searches'}
          wrapperClass='h-1/2'
          isSimple
          contentProjectRole={
            <MyListMenu
              className={'p-3'}
              titleButton='Save search preset'
              titleSecondList='All searches'
              value={searchTermPresetSearch}
              onChange={handleSearchChangePresetSearch}
              allLists={filteredSearchList}
              handleOpenEditModal={handleEditPresetSearchList}
              handleOpenDeleteModal={handleDeletePrivateSearch}
              handleChange={handleSaveSearchResults}
              onClickItem={handleClickItemPresetSearchList}
              separator={true}
              activeSavedSearch={activeSavedSearch}
              isSavedSearchChanged={isSavedSearchChanged}
              setIsSavedSearchChanged={setIsSavedSearchChanged}
              resetSavedSearch={resetSavedSearch}
              activeFilters={activeFilters}
              searchToModify={searchToModify}
              setUpdatedFilters={setUpdatedFilters}
              setSearchModalOpened={setSearchModalOpened}
            />
          }
        />
      </div>
      <EditSearchResultsTalentModal
        isOpen={isOpenEditSearchModal}
        handleClose={() => setIsOpenEditSearchModal(false)}
        handleEdit={(id, name) => {
          handleEditSearchPreset(
            id,
            name,
            undefined,
            <>
              Updated <b>Saved Search:</b> <br /> <b>{name}</b>
            </>,
          )
        }}
        value={{ ...selectedPresetSearch.current }}
      />
      <DeleteModal
        isOpen={isOpenDeletePrivateSearchModal}
        handleClose={() => setOpenDeletePrivateSearchModal(false)}
        title='Delete search from your Saved Searches?'
        description='Warning: this action cannot be undone!'
        deleteTitle='Delete saved search'
        onDelete={() => {
          const searchLabel = selectedPresetSearch.current.value
          const isDeleteActiveSearch =
            selectedPresetSearch.current.id === searchToModify
          deleteSavedSearchPreset({
            id: selectedPresetSearch.current.id,
          })
            .unwrap()
            .then(() => {
              toast.success(
                <>
                  Saved search deleted from <b>Saved Searches:</b>{' '}
                  <b>{searchLabel}</b>
                </>,
                {
                  icon: (
                    <Trash
                      fill={Colors.ICON_NEGATIVE_RED}
                      width={24}
                      height={24}
                    />
                  ),
                  ...commonToastStyles,
                },
              )
              handleClickItemPresetSearchList()
              selectedPresetSearch.current = { id: '' }
              setOpenDeletePrivateSearchModal(false)
              if (isDeleteActiveSearch) {
                resetFilters && resetFilters()
              }
            })
            .catch(error => {
              console.log('%c error:', 'color: #7dd3fc', error) //eslint-disable-line
              toast.error(
                <>Failed to delete Saved search from Saved Searches</>,
              )
            })
        }}
      />
    </div>
  )
}
