import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  TVFilmCompanyColKey,
  TVFilmCompanySortColKey,
  TVFilmDistributorTypeFilterValue,
  TVFilmCompanyTypeFilterValue,
  TVFilmCompanyType,
} from '../types'

interface PageFilters {
  companyType: TVFilmCompanyTypeFilterValue
  distributorType: TVFilmDistributorTypeFilterValue
}

interface PageSorting {
  order: 'ASC' | 'DESC'
  by: TVFilmCompanySortColKey
}

interface PageConfig {
  pageSize: number
  pageNumber: number
}

interface PageState {
  pageConfig: PageConfig
  filters: PageFilters
  sorting: PageSorting
}

const filtersInitialState: PageFilters = {
  companyType: 'all',
  distributorType: 'all',
}

const sortingInitialState: PageSorting = {
  order: 'ASC',
  by: TVFilmCompanyColKey.Name,
}

const pageConfigInitialState: PageConfig = {
  pageSize: 50,
  pageNumber: 1,
}

const initialState: PageState = {
  pageConfig: pageConfigInitialState,
  filters: filtersInitialState,
  sorting: sortingInitialState,
}

export const tvFilmCompaniesSlice = createSlice({
  name: 'tvFilmCompanies',
  initialState,
  reducers: {
    setPageConfig: (state, action: PayloadAction<PageState['pageConfig']>) => {
      state.pageConfig = action.payload
    },
    setCompanyTypeFilter: (
      state,
      action: PayloadAction<PageFilters['companyType']>,
    ) => {
      state.filters.companyType = action.payload
      if (action.payload !== TVFilmCompanyType.FilmDistributor) {
        state.filters.distributorType = 'all'
      }
    },
    setDistributorTypeFilter: (
      state,
      action: PayloadAction<PageFilters['distributorType']>,
    ) => {
      state.filters.distributorType = action.payload
    },
    setSorting: (state, action: PayloadAction<Partial<PageSorting>>) => {
      state.sorting = {
        ...sortingInitialState,
        ...action.payload,
      }
    },
  },
  selectors: {
    root: state => state,
    filters: state => state.filters,
  },
})

export const {
  actions: tvFilmCompaniesActions,
  selectors: tvFilmCompaniesSelectors,
} = tvFilmCompaniesSlice
