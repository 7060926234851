export enum DatasetColors {
  BURGUNDY = '#99292C',
  PINK = '#FF4B9E',
  YELLOW = '#FFA500',
  PURPLE = '#B85AFA',
  BLUE = '#2C5684',
  GREEN = '#006C2B',
  GOLD = '#CFC266',
  ORANGE = '#FF9E83',
  MAGENTA = '#FFB7D8',
  VIOLET = '#D49CFC',
}

export const datasetColors = [
  DatasetColors.BURGUNDY,
  DatasetColors.PINK,
  DatasetColors.YELLOW,
  DatasetColors.PURPLE,
  DatasetColors.BLUE,
  DatasetColors.GREEN,
  DatasetColors.GOLD,
  DatasetColors.ORANGE,
  DatasetColors.MAGENTA,
  DatasetColors.VIOLET,
]

export const filmDatasetColors = [
  DatasetColors.BURGUNDY,
  DatasetColors.PINK,
  DatasetColors.YELLOW,
  DatasetColors.PURPLE,
]
