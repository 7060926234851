import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ClassNameValue, twMerge } from 'tailwind-merge'
import { Tooltip } from 'react-tooltip'

import {
  Checkbox,
  PaginationToolbar,
  VerticalTableText,
  FilmTitleCell,
  PaginationChangeParams,
  ToggleSwitch,
} from 'components'
import { TooltipIcon } from 'components/ui/icons'
import { Colors } from 'constnants'
import {
  ColumnsFilms,
  FilmsType,
  TopColumnsFilms,
  DirectionSort,
  FilmsTypeColumn,
  AudienceDemoGroup,
  AudienceDemo,
  AudienceGeneralDemo,
  AudienceIdentityDemo,
  FilmMetricsSortBy,
  AudienceRaceDemoBlack,
  AudienceRaceDemoWhite,
  AudienceRaceDemoHispLat,
} from 'types'
import { rowsPerPageOptions } from 'constnants/filmsTableData'
import { toast } from 'react-toastify'
import { commonToastStyles, parseDateId, formatDate } from 'utils'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FilmTrackingGuideTrigger } from './FilmTrackingGuideTrigger'

export interface FilmTrackingTableSorting {
  column: FilmMetricsSortBy
  direction: DirectionSort
}

interface FilmTrackingTableProps<T> {
  columns: ColumnsFilms[]
  topColumns: TopColumnsFilms[]
  totalPages: number
  totalRows: number
  hasCompareButtons?: boolean
  hasFooter?: boolean
  selectedRows?: string[]
  toggleSelectOne?: (rowId: string) => void
  toggleSelectAll?: () => void
  selectAllChecked?: boolean
  setCurrentPage: (page: number) => void
  currentPage?: number
  setRowsPerPage: (rows: number) => void
  rowsPerPage?: number
  requestSort?: (config: FilmTrackingTableSorting) => void
  sortConfig?: FilmTrackingTableSorting
  paginatedData: T[]
  setColumnIsActive?: (isActive: boolean) => void
  columnIsActive?: boolean
  visibleDemos?: Record<AudienceDemoGroup, string[]>
  getActiveFilterColumnName?: () => string | undefined
  allowExpandableView?: boolean
  isInterestAwareActive: boolean
  onChangeInterestAware: (active: boolean) => void
}

const mapping: Record<FilmsTypeColumn, FilmMetricsSortBy> = {
  general_awareness: 'awareness',
  general_interest: 'interest',
  general_location: 'location',
  general_pay_to_see: 'paytosee',
  general_unaided_awareness: 'unaidedawareness',
  general_awareness_interest: 'awarenessinterest',
  general_heat: 'heat',
  general_plf: 'plf',
  date: 'latest_date_survey_id',
  film: 'film_name',
  dist: 'company_name',
  group: 'film_group_key',
  rating: 'rating',
}

const mapColumnKeyToSortBy = (
  column: FilmsTypeColumn,
): FilmMetricsSortBy | undefined => mapping[column]

export const FilmTrackingTable = <T extends FilmsType>({
  columns,
  totalRows = 0,
  totalPages = 0,
  hasFooter = true,
  selectedRows,
  toggleSelectOne,
  toggleSelectAll,
  selectAllChecked,
  setCurrentPage,
  currentPage = 1,
  rowsPerPage = 1,
  setRowsPerPage,
  requestSort,
  sortConfig,
  paginatedData,
  topColumns,
  setColumnIsActive,
  columnIsActive,
  visibleDemos,
  getActiveFilterColumnName,
  allowExpandableView = false,
  isInterestAwareActive,
  onChangeInterestAware,
}: FilmTrackingTableProps<T>) => {
  const [activeTopItem, setActiveTopItem] = useState<string>('')
  const [activePrimaryColor, setActivePrimaryColor] = useState<string>('')
  const [activeSecondaryColor, setActiveSecondaryColor] = useState<string>('')
  const [rowHoveredId, setRowHoveredId] = useState<string>('')

  const isColumnActive = useCallback(
    (key: string | undefined) => activeTopItem === key,
    [activeTopItem],
  )
  const isColumnExpanded = useCallback(
    (key: string | undefined) => allowExpandableView && isColumnActive(key),
    [isColumnActive],
  )

  const isDemoColumnVisible = (
    group: AudienceDemoGroup,
    columnKey: AudienceDemo,
  ) => {
    if (!visibleDemos) {
      return true
    }
    return visibleDemos[group].includes(columnKey)
  }

  const handleToggleSort = (column: FilmsTypeColumn) => {
    const sortByColumn = mapColumnKeyToSortBy(column)
    requestSort?.({
      column: sortByColumn,
      direction:
        sortConfig?.column === sortByColumn
          ? sortConfig?.direction === DirectionSort.DESC
            ? DirectionSort.ASC
            : DirectionSort.DESC
          : DirectionSort.DESC,
    })
  }

  const isSortedBy = (columnKey: FilmsTypeColumn) =>
    sortConfig?.column === mapColumnKeyToSortBy(columnKey)

  const handleSetActiveColumn = (isActive: boolean) => {
    if (!setColumnIsActive || columnIsActive === isActive) {
      return
    }

    setColumnIsActive(isActive)
    if (isActive) {
      toast.success(
        <>
          <p className='-mt-1 mb-0 font-bold'>
            Your Audience filter reset to default.
          </p>
          <p className='mb-0 text-xs'>
            The Audience filter is disabled when viewing Attributes details.
          </p>
        </>,
        {
          icon: (
            <TooltipIcon
              fill={Colors.ICON_POSITIVE_BLUE}
              width={16}
              height={16}
              data-tooltip-id={'activeColumnTooltip'}
            />
          ),
          ...commonToastStyles,
          toastId: 'Audience_Filter_Reset_Toast',
        },
      )
    }
  }

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage(params.page)
      setRowsPerPage(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  const cellsClasses =
    'bg-primary-white text-primary-black rounded h-7 ' +
    'min-w-28 w-[calc(100%/8-4px)] m-0.5 flex items-center justify-center shrink-0'

  const columnsVisible = useMemo(() => {
    if (!visibleDemos) {
      return columns
    }
    return columns.map(it => {
      const { hiddenInfo, relatedParent } = it
      if (!relatedParent) {
        return it
      }
      return {
        ...it,
        title: getActiveFilterColumnName?.() ?? it.title,
        hiddenInfo: hiddenInfo?.map(group => {
          const visibleDemosByGroup = visibleDemos[group.key]
          if (!hiddenInfo) {
            return group
          }
          return {
            ...group,
            items: group.items.filter(
              it => !it.key || visibleDemosByGroup.includes(it.key),
            ),
          }
        }),
      }
    })
  }, [columns, visibleDemos, getActiveFilterColumnName])

  const hiddenElementsCount = () => {
    const needleEl = columnsVisible.filter(column => column.hiddenInfo)
    let count = 0
    if (needleEl[0].hiddenInfo) {
      const hiddenInfo = needleEl[0].hiddenInfo
      hiddenInfo.map(item => {
        count += item.items.length
      })
    }
    return count
  }

  const renderHidden = (
    content: string,
    classes?: ClassNameValue,
    hasColor = true,
  ) => {
    return (
      <div
        className={twMerge(
          'flex h-7 shrink-0 basis-8 items-center justify-center text-center',
          classes,
        )}
        style={{
          backgroundColor: `${hasColor ? activeSecondaryColor : ''}`,
        }}
      >
        {content}
      </div>
    )
  }

  const renderHiddenGroup = (
    groupKey: AudienceDemoGroup,
    columns: Array<{
      key: AudienceDemo
      render: (className?: string) => ReactNode
    }>,
    firstItemClassName?: string,
    lastItemClassName?: string,
  ) => {
    return columns
      .filter(it => isDemoColumnVisible(groupKey, it.key))
      .map((it, index, arr) => {
        const isFirst = index === 0
        const isLast = index === arr.length - 1
        return (
          <Fragment key={index}>
            {it.render(
              twMerge(
                'first:rounded-l last:rounded-r',
                isFirst && firstItemClassName,
                isLast && lastItemClassName,
              ),
            )}
          </Fragment>
        )
      })
  }

  const hiddenInfoExtended = (row: any, key: string) => {
    const { hiddenInfo: info } = row[`general_${key}`]
    const partKey = key === 'interest_aware' ? 'interestawaretab' : key
    return (
      <>
        {renderHidden(info.change.value, null, false)}
        {renderHidden(info.avg.value, null, false)}

        <div className='flex flex-shrink-0 flex-grow'>
          {renderHiddenGroup(AudienceDemoGroup.GENERAL, [
            {
              key: AudienceGeneralDemo.MEN,
              render: className =>
                renderHidden(info[`${partKey}_men`].value, className),
            },
            {
              key: AudienceGeneralDemo.WOMEN,
              render: className =>
                renderHidden(info[`${partKey}_women`].value, className),
            },
          ])}

          {renderHiddenGroup(
            AudienceDemoGroup.GENERAL,
            [
              {
                key: AudienceGeneralDemo.BELOW_35,
                render: className =>
                  renderHidden(info[`${partKey}_less_35`], className),
              },
              {
                key: AudienceGeneralDemo.ABOVE_35,
                render: className =>
                  renderHidden(info[`${partKey}_older_35`], className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.GENERAL,
            [
              {
                key: AudienceGeneralDemo.MEN_BELOW_35,
                render: className =>
                  renderHidden(
                    info[`${partKey}_men_younger_35`].value,
                    className,
                  ),
              },
              {
                key: AudienceGeneralDemo.MEN_ABOVE_35,
                render: className =>
                  renderHidden(
                    info[`${partKey}_men_older_35`].value,
                    className,
                  ),
              },
              {
                key: AudienceGeneralDemo.WOMEN_BELOW_35,
                render: className =>
                  renderHidden(
                    info[`${partKey}_women_younger_35`].value,
                    className,
                  ),
              },
              {
                key: AudienceGeneralDemo.WOMEN_ABOVE_35,
                render: className =>
                  renderHidden(
                    info[`${partKey}_women_older_35`].value,
                    className,
                  ),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.BLACK,
            [
              {
                key: AudienceRaceDemoBlack.ALL,
                render: className => renderHidden(info.blk_all, className),
              },
              {
                key: AudienceRaceDemoBlack.MEN,
                render: className => renderHidden(info.blk_men, className),
              },
              {
                key: AudienceRaceDemoBlack.WOMEN,
                render: className => renderHidden(info.blk_women, className),
              },
              {
                key: AudienceRaceDemoBlack.BELOW_35,
                render: className => renderHidden(info.blk_less_35, className),
              },
              {
                key: AudienceRaceDemoBlack.ABOVE_35,
                render: className => renderHidden(info.blk_older_35, className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.HISPANIC_LATINO,
            [
              {
                key: AudienceRaceDemoHispLat.ALL,
                render: className => renderHidden(info.hsp_all, className),
              },
              {
                key: AudienceRaceDemoHispLat.MEN,
                render: className => renderHidden(info.hsp_men, className),
              },
              {
                key: AudienceRaceDemoHispLat.WOMEN,
                render: className => renderHidden(info.hsp_women, className),
              },
              {
                key: AudienceRaceDemoHispLat.BELOW_35,
                render: className => renderHidden(info.hsp_less_35, className),
              },
              {
                key: AudienceRaceDemoHispLat.ABOVE_35,
                render: className => renderHidden(info.hsp_older_35, className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.WHITE,
            [
              {
                key: AudienceRaceDemoWhite.ALL,
                render: className => renderHidden(info.wht_all, className),
              },
              {
                key: AudienceRaceDemoWhite.MEN,
                render: className => renderHidden(info.wht_men, className),
              },
              {
                key: AudienceRaceDemoWhite.WOMEN,
                render: className => renderHidden(info.wht_women, className),
              },
              {
                key: AudienceRaceDemoWhite.BELOW_35,
                render: className => renderHidden(info.wht_less_35, className),
              },
              {
                key: AudienceRaceDemoWhite.ABOVE_35,
                render: className => renderHidden(info.wht_older_35, className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.LGBTQI,
            [
              {
                key: AudienceIdentityDemo.ALL,
                render: className => renderHidden(info.lgbt, className),
              },
            ],
            'ml-0.5',
          )}
        </div>
      </>
    )
  }

  const renderExpandableRow = (row: any, key: string, dataViewKey?: string) => {
    const metricKey = `general_${key}`
    const isActive = isColumnActive(metricKey)
    const isExpanded = isColumnExpanded(metricKey)
    const dataKey = dataViewKey ?? key
    const metricDataKey = `general_${dataKey}`

    return (
      <>
        <div
          className={twMerge(
            cellsClasses,
            rowHoveredId == row.id && 'bg-red-2',
          )}
          style={
            isActive
              ? { backgroundColor: activePrimaryColor, color: '#fff' }
              : undefined
          }
        >
          {row[metricDataKey]?.general}
        </div>
        {isExpanded ? (
          <div
            className='m-0.5 flex shrink-0 flex-grow'
            style={{
              flexBasis: getExpandedPartWidth(),
            }}
          >
            {hiddenInfoExtended(row, dataKey)}
          </div>
        ) : null}
      </>
    )
  }

  const renderStaticRow = (row: any, key: string) => {
    const metricKey = `general_${key}`
    const isActive = isColumnActive(metricKey)
    return (
      <div
        className={twMerge(cellsClasses, rowHoveredId == row.id && 'bg-red-2')}
        style={isActive ? { backgroundColor: activePrimaryColor } : undefined}
      >
        {row[metricKey]}
      </div>
    )
  }

  const getExpandedPartWidth = () => 32 * hiddenElementsCount() + 6 * 2

  const tableLeftPart = useRef<HTMLTableSectionElement>(null)

  const handleTableScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (tableLeftPart.current && event.target instanceof HTMLElement) {
        const { scrollTop } = event.target
        tableLeftPart.current.style.transform = `translateY(-${scrollTop}px)`
      }
    },
    [],
  )

  const footerHeight = 40

  return (
    <div className='flex flex-auto flex-grow'>
      <AutoSizer>
        {({ height, width }) => (
          (height = hasFooter ? height - footerHeight : height),
          (
            <div style={{ width }} className='bg-white'>
              <div className={'flex overflow-hidden text-xs'}>
                <div
                  style={{
                    height,
                  }}
                  className='films-table__left-part flex h-max min-h-full w-[calc(100vw_-_60vw)] min-w-[calc(600px)] flex-shrink-0 flex-col'
                >
                  <div className='sticky top-0 z-10 flex min-h-14 items-center border-b border-green-1 bg-white py-2.5 pl-2.5 font-semibold'>
                    Upcoming Releases
                  </div>
                  <table className='border-spacing-y-2 bg-green-1'>
                    <thead className='sticky top-14 z-10 h-10 border-b border-green-1 bg-white'>
                      <tr>
                        <th className='w-9 pl-2.5'>
                          <Checkbox
                            kind='small'
                            checked={selectAllChecked}
                            onChange={toggleSelectAll}
                            isNotAllChecked={
                              !selectAllChecked &&
                              selectedRows &&
                              selectedRows?.length > 0
                            }
                          />
                        </th>
                        {columnsVisible.map((column, i) => {
                          if (i < 5) {
                            return (
                              <th
                                key={column.accessor}
                                className={twMerge(
                                  'font-normal hover:cursor-pointer',
                                  isSortedBy(column.accessor) &&
                                    'selected-column',
                                  i === 0 && 'w-1/3',
                                  (i === 3 || i === 4) && 'w-1/6',
                                )}
                                onClick={() =>
                                  handleToggleSort(column.accessor)
                                }
                              >
                                <VerticalTableText
                                  vertical={true}
                                  title={column.title}
                                  selectItem={isSortedBy(column.accessor)}
                                  directionSort={sortConfig?.direction}
                                  hasIcon
                                  containerClass={'justify-start'}
                                />
                              </th>
                            )
                          }
                          return null
                        })}
                      </tr>
                    </thead>
                    <tbody ref={tableLeftPart}>
                      {paginatedData.map(row => (
                        <tr
                          key={row.id}
                          className='border-b border-green-1'
                          onMouseOver={() => setRowHoveredId(row.id)}
                          onMouseLeave={() => setRowHoveredId('')}
                        >
                          <td
                            className={twMerge(
                              'h-8 bg-primary-white pl-2.5',
                              rowHoveredId == row.id && 'bg-red-2',
                            )}
                          >
                            <Checkbox
                              checked={selectedRows?.includes(
                                row.id.toString(),
                              )}
                              onChange={() => {
                                toggleSelectOne && toggleSelectOne(row.id)
                              }}
                              kind='small'
                            />
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white font-medium text-primary-black hover:cursor-pointer',
                              rowHoveredId == row.id && 'bg-red-2 text-red-9',
                            )}
                          >
                            <FilmTitleCell row={row} />
                          </td>
                          <td
                            className={twMerge(
                              'items-center bg-primary-white text-primary-black',
                              rowHoveredId == row.id && 'bg-red-2',
                            )}
                          >
                            {formatDate(parseDateId(row.date), 'MM/DD/YY')}
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white text-primary-grey',
                              rowHoveredId == row.id && 'bg-red-2',
                            )}
                          >
                            {row.dist.abbr}
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white text-primary-grey',
                              rowHoveredId == row.id && 'bg-red-2',
                            )}
                          >
                            {row.group.name}
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white text-primary-grey',
                              rowHoveredId == row.id && 'bg-red-2',
                            )}
                          >
                            {row.rating}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{ height }}
                  className={twMerge(
                    'films-table__right-part flex flex-grow flex-col overflow-y-auto bg-green-1',
                  )}
                  onScroll={handleTableScroll}
                >
                  {topColumns.length > 0 && (
                    <div className='sticky top-0 flex h-14 min-h-14 justify-between border-b border-green-1 bg-primary-white pl-1.5'>
                      {topColumns.map(column => (
                        <React.Fragment key={column.accessor}>
                          <div
                            className={twMerge(
                              `flex w-auto shrink-0 bg-white py-1.5 text-xs text-primary-grey` +
                                ' items-top min-w-[116px] basis-[calc(100%/8)] gap-1 px-1',
                              column.tooltipText && 'hover:cursor-pointer',
                              column.accessor === 'general_interest' &&
                                'flex-wrap',
                              isColumnActive(column.accessor) &&
                                'font-semibold',
                            )}
                          >
                            {column.tooltipText && (
                              <>
                                <Tooltip
                                  id={column.accessor}
                                  content={column.tooltipText}
                                  openOnClick
                                  place='bottom'
                                  variant='dark'
                                  className='z-50 whitespace-pre-line'
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    padding: '4px 8px',
                                    width: 286,
                                  }}
                                />
                                <TooltipIcon
                                  fill={Colors.BASE_ICON}
                                  width={16}
                                  height={16}
                                  data-tooltip-id={column.accessor}
                                />
                              </>
                            )}
                            <span
                              className={'flex-grow'}
                              onClick={() => {
                                const isActive = isColumnActive(column.accessor)

                                if (
                                  isActive &&
                                  (!column.isExpandable || !allowExpandableView)
                                ) {
                                  handleToggleSort(column.accessor)
                                  return
                                }

                                if (!isActive) {
                                  requestSort?.({
                                    column: mapColumnKeyToSortBy(
                                      column.accessor,
                                    ),
                                    direction: DirectionSort.DESC,
                                  })
                                }

                                setActiveTopItem(
                                  isActive ? '' : column.accessor,
                                )
                                setActivePrimaryColor(
                                  isActive ? '' : column.primaryColor,
                                )
                                setActiveSecondaryColor(
                                  isActive
                                    ? ''
                                    : column.secondaryColor
                                      ? column.secondaryColor
                                      : '',
                                )

                                handleSetActiveColumn(
                                  !!column.isExpandable && !isActive,
                                )
                              }}
                            >
                              {column.title}
                            </span>
                            {column.accessor === 'general_interest' &&
                              !isColumnExpanded('general_interest') && (
                                <div className='flex basis-full items-center pl-5'>
                                  <ToggleSwitch
                                    id='interest_type'
                                    checked={isInterestAwareActive}
                                    onChange={onChangeInterestAware}
                                  />
                                  <label
                                    htmlFor='interest_type'
                                    className='cursor-pointer pl-2 text-xs'
                                  >
                                    Aware
                                  </label>
                                </div>
                              )}
                          </div>
                          <div
                            className={twMerge(
                              'items-top flex flex-grow overflow-hidden bg-white py-1.5',
                              `${
                                isColumnExpanded(column.accessor) &&
                                column.secondaryColor
                                  ? 'shrink-0 '
                                  : 'w-0'
                              }`,
                            )}
                            style={{
                              flexBasis:
                                isColumnExpanded(column.accessor) &&
                                column.secondaryColor
                                  ? getExpandedPartWidth() + 2
                                  : '0',
                            }}
                          ></div>
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  <div className='sticky top-14 flex items-center bg-primary-white pl-1.5'>
                    {columnsVisible.map((column, i) => {
                      if (i > 4) {
                        return (
                          <React.Fragment key={column.accessor}>
                            <div
                              className={twMerge(
                                'flex h-10 items-center justify-center bg-primary-white font-normal hover:cursor-pointer' +
                                  ' w-auto' +
                                  ' min-w-[calc(116px)] shrink-0 basis-[calc(100%/8)] p-0.5',
                                isSortedBy(column.accessor) &&
                                  'selected-column',
                              )}
                              onClick={() => handleToggleSort(column.accessor)}
                            >
                              <VerticalTableText
                                containerClass='flex-1'
                                vertical={true}
                                title={column.title}
                                selectItem={isSortedBy(column.accessor)}
                                directionSort={sortConfig?.direction}
                                hasIcon
                              />
                            </div>
                            {column.hiddenInfo && (
                              <div
                                className={`flex flex-grow bg-primary-white ${isColumnExpanded(column.relatedParent) ? 'ml-0.5 shrink-0' : 'w-0 overflow-hidden'}`}
                                style={{
                                  flexBasis: isColumnExpanded(
                                    column.relatedParent,
                                  )
                                    ? getExpandedPartWidth()
                                    : '0',
                                }}
                              >
                                {column.hiddenInfo.map((info, key: number) => {
                                  return (
                                    info.items &&
                                    info.items.map((item, index: number) => (
                                      <div
                                        key={`${i}_${key}_${index}`}
                                        className={twMerge(
                                          'primary-white shrink-0 basis-8 hover:cursor-pointer' +
                                            ' flex h-10 flex-col items-center justify-center text-center text-xss',
                                          `${[4, 6, 10, 15, 20, 25].includes(index) ? 'ml-0.5' : ''}`,
                                        )}
                                      >
                                        {info.title && index === 0 && (
                                          <span className='-mt-3 block font-semibold leading-none'>
                                            {info.title}
                                          </span>
                                        )}
                                        <span>{item.label}</span>
                                      </div>
                                    ))
                                  )
                                })}
                              </div>
                            )}
                          </React.Fragment>
                        )
                      }
                      return null
                    })}
                  </div>
                  <div className='flex flex-col'>
                    {paginatedData?.map(row => (
                      <div
                        key={row.id}
                        className='flex px-1.5 text-center font-medium'
                        onMouseOver={() => setRowHoveredId(row.id)}
                        onMouseLeave={() => setRowHoveredId('')}
                      >
                        {renderExpandableRow(row, 'awareness')}
                        {renderExpandableRow(
                          row,
                          'interest',
                          isInterestAwareActive ? 'interest_aware' : 'interest',
                        )}
                        {renderExpandableRow(row, 'location')}
                        {renderExpandableRow(row, 'pay_to_see')}
                        {renderStaticRow(row, 'unaided_awareness')}
                        {renderStaticRow(row, 'awareness_interest')}
                        {renderStaticRow(row, 'heat')}
                        {renderStaticRow(row, 'plf')}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {hasFooter && (
                <PaginationToolbar
                  totalRows={totalRows}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  currentPageSize={rowsPerPage}
                  pageSizeOptions={rowsPerPageOptions}
                  onChange={handlePagination}
                  startContent={<FilmTrackingGuideTrigger />}
                />
              )}
            </div>
          )
        )}
      </AutoSizer>
    </div>
  )
}
