import { useEffect, useState } from 'react'
import {
  LayoutContentWrapper,
  ReleaseCalendarTable,
  ReleaseCalendarTopSection,
  ReleaseCalendarWrapperContent,
} from 'components'
// import { weeksReleaseCalendar } from 'constnants'
import { useActions, useTypedSelector } from 'hooks'
import { useGetRevenueQuery as useGetReleaseCalendarQuery } from 'store/api'
import PageLoader from 'components/PageLoader'

export const ReleaseCalendarPage = () => {
  const { selectedDateRange, startDate, loading } = useTypedSelector(
    state => state.releaseCalendarReducer,
  )
  const { setLoading, setError, initRevenue } = useActions() // Destructure the actions
  console.log('selectedDateRange', startDate)
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true)
  // Trigger the query with months and startDate
  const { data, error, isLoading } = useGetReleaseCalendarQuery({
    months: selectedDateRange.value as unknown as number, // Assuming selectedDateRange.value is a number
    startDate: startDate || null, // Ensure startDate is defined
  })
  console.log('review data', data?.[0]?.revenue_last_year)
  // Dispatch the data to the Redux store once it's fetched
  useEffect(() => {
    if (isLoading) {
      setLoading()
    } else if (error) {
      setError(error.toString())
    } else if (data) {
      initRevenue(data) // Pass the array to the init action
    }
  }, [data, error, isLoading])

  const handleOpenSidebar = () => setIsOpenSidebar(true)

  const handleCloseSidebar = () => {
    setIsOpenSidebar(false)
  }

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-blue-medium'
      wrapperChildrenClassName='w-[calc(100vw-48px)]'
    >
      {loading && <PageLoader />}
      <ReleaseCalendarTopSection />
      <ReleaseCalendarWrapperContent
        isOpen={isOpenSidebar}
        handleOpen={handleOpenSidebar}
        handleClose={handleCloseSidebar}
      >
        <ReleaseCalendarTable />
      </ReleaseCalendarWrapperContent>
    </LayoutContentWrapper>
  )
}
