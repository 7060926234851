import {
  ArrowLeft,
  CheckCircle,
  ChevronDown,
  Plus,
  Trash,
} from '../../components/ui/icons'
import { Colors } from '../../constnants'
import {
  BaseModal,
  Button,
  FilterButton,
  FilterColumnGroup,
  Input,
  Select,
  ToggleSwitch,
} from '../../components'
import { twMerge } from 'tailwind-merge'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { OptionFilter, Project, userManagement } from '../../types'
import {
  useGetProjectCompanyByIdQuery,
  useGetManagementUserQuery,
  useGetUserProjectsQuery,
  useDeleteUserProjectMutation,
  useUpdateUserProjectsMutation,
  useUpdatedManagementUserMutation,
} from '../../store/api'
import { formatDate, toastError } from '../../utils'

interface UserEditPageProps {
  userData: userManagement
  setUserProfileOpen: (user: userManagement) => void
  initialUserProfile: userManagement
  companyFilterOptions: OptionFilter[]
}
const accessLevelOptions = [
  [
    {
      id: '1',
      value: 'editor',
      label: 'Editor',
    },
    {
      id: '2',
      value: 'viewer',
      label: 'Viewer',
    },
  ],
  [
    {
      id: '1',
      value: 'superadmin',
      label: 'Super Admin',
    },
    {
      id: '2',
      value: 'moduleadmin',
      label: 'Module Admin',
    },
  ],
]

export const UserEditPage = ({
  userData,
  setUserProfileOpen,
  initialUserProfile,
  companyFilterOptions,
}: UserEditPageProps) => {
  const companyId = localStorage.getItem('companyId')
  const [deleteUserProject] = useDeleteUserProjectMutation()
  const { data: companyProjectsList } = useGetProjectCompanyByIdQuery(
    {
      id: companyId ?? '',
    },
    { skip: !companyId },
  )
  const { data: singleUserData } = useGetManagementUserQuery(
    { userID: userData.userId },
    { skip: !userData.userId },
  )
  const { data: userProjects, error } = useGetUserProjectsQuery(
    {
      userId: userData.userId.toString() || '',
    },
    { skip: !userData.userId },
  )
  const [updatedUserProjects] = useUpdateUserProjectsMutation()
  const [updatedUser] = useUpdatedManagementUserMutation()
  const [isEditState, setIsEditState] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [openAddProjects, setOpenAddProjects] = useState<boolean>(false)
  const [newUserProjects, setNewUserProjects] = useState<string[]>([])
  const preparedFormData = {
    userName: userData.firstName + ' ' + userData.lastName,
    userCompany:
      companyFilterOptions.filter(
        company => company.label === userData.company,
      )[0]?.value || '',
    userEmail: userData.email,
    userType: '',
    userLastLogin: userData.lastLogin,
    userProjects: [{ id: 0, name: '' }],
    userModuleAccess: [2, 3, 4, 5],
    jobTitle: '',
    userAccessLevel: '',
    userDateJoined: '',
    userAccountStatus: '',
    companyLabel:
      companyFilterOptions.filter(
        company => company.label === userData.company,
      )[0]?.label || '',
  }
  const [inputValues, setInputValues] = useState(preparedFormData)
  const [defaultUserData, setDefaultUserData] = useState(preparedFormData)
  const [projectsList, setProjectsList] = useState<OptionFilter[]>([])
  const [isUserUpdating, setIsUserUpdating] = useState<boolean>(false)
  useEffect(() => {
    if (singleUserData && singleUserData.user) {
      const user = singleUserData.user
      const moduleIdMapping: { [key: string]: number } = {
        Talent: 2,
        CompFinder: 3,
        ReleaseCalendar: 4,
        FilmTracker: 5,
        ConceptTesting: 6,
        RoleResting: 7,
        AssetTesting: 8,
      }

      const permissions = user.modulePermissions.map(
        (module: { module_id: string }) =>
          moduleIdMapping[module.module_id] || 1,
      )
      setInputValues(prevState => ({
        ...prevState,
        jobTitle: user.job_title || '--',
        userType: user.user_type,
        userAccessLevel: user.access_level,
        userDateJoined: formatDate(user.created_at, 'MMM DD, YYYY'),
        userAccountStatus: user.status,
        userModuleAccess: permissions,
      }))
      setDefaultUserData(prevState => ({
        ...prevState,
        userType: user.user_type,
        userAccountStatus: user.status,
      }))
    }
  }, [singleUserData, isEditState])

  useEffect(() => {
    if (companyProjectsList) {
      let filteredData = companyProjectsList
      if (Array.isArray(userProjects) && userProjects.length) {
        filteredData = companyProjectsList.filter(
          (project: Project) =>
            !userProjects.some(
              (userProject: { company_project_id: number }) =>
                userProject.company_project_id ===
                Number(project.company_project_id),
            ),
        )

        if (error) {
          toastError('Failed to get user projects')
        }
        let projects = [{ id: 0, name: '' }]
        if (Array.isArray(userProjects)) {
          projects = userProjects.map(
            (project: { company_project_id: number; name: string }) => ({
              id: project.company_project_id,
              name: project.name,
            }),
          )
        }

        setInputValues(prevState => ({
          ...prevState,
          userProjects: projects,
        }))
      }
      setProjectsList(
        filteredData.map((project: Project) => ({
          id: project.company_project_id || '0',
          value: project.company_project_id || '0',
          label: project.name || '',
        })),
      )
    }
  }, [companyProjectsList, userProjects])

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputValues,
  ) => {
    const { value } = e.target
    setInputValues(prevState => ({ ...prevState, [key]: value || '' }))
  }

  const handleSelect = (
    option: { id: string; value: string; label: string },
    key: string,
  ) => {
    setInputValues(prevState => ({ ...prevState, [key]: option.value }))
    if (key === 'userType') {
      setInputValues(prevState => ({ ...prevState, userAccessLevel: '' }))
    }
    if (key === 'userCompany') {
      setInputValues(prevState => ({
        ...prevState,
        companyLabel: option.label,
      }))
    }
  }

  const handleDeleteUserProject = (projectId: number) => {
    deleteUserProject({
      companyProjectId: projectId,
      userId: userData.userId.toString(),
    })
      .unwrap()
      .then(() => {
        const updatedProjects = inputValues.userProjects.filter(
          project => project.id !== projectId,
        )
        setInputValues(prevState => ({
          ...prevState,
          userProjects: updatedProjects,
        }))
        setSuccessMessage(`User removed from project successfully`)
        setShowSuccessMessage(true)
        setTimeout(() => {
          setShowSuccessMessage(false)
          setSuccessMessage('')
        }, 3000)
      })
      .catch(e => {
        const { error, data } = e as { data?: any; error?: string }
        toastError(data, error)
      })
  }

  const updateUserProjects = () => {
    if (newUserProjects.length) {
      const updatedProjects = [
        ...inputValues.userProjects,
        ...newUserProjects.map(project => {
          const foundProject = projectsList.find(item => item.value === project)
          return {
            id: foundProject ? Number(foundProject.id) : 0,
            name: foundProject ? foundProject.label : '',
          }
        }),
      ]

      const promises = newUserProjects.map((projectId: string) => {
        return updatedUserProjects({
          companyProjectId: Number(projectId),
          userId: userData.userId.toString(),
        }).unwrap()
      })

      Promise.all(promises)
        .then(() => {
          setInputValues(prevState => ({
            ...prevState,
            userProjects: updatedProjects,
          }))
          setSuccessMessage(`Projects Updated`)
          setShowSuccessMessage(true)
          setOpenAddProjects(false)
          setTimeout(() => {
            setShowSuccessMessage(false)
            setSuccessMessage('')
          }, 3000)
          setNewUserProjects([])
        })
        .catch(e => {
          const { error, data } = e as { data?: any; error?: string }
          toastError(data, error)
        })
    }
  }

  const handleModuleAccessChange = async (moduleId: number) => {
    setIsUserUpdating(true)
    const newModuleAccess = inputValues.userModuleAccess.includes(moduleId)
      ? inputValues.userModuleAccess.filter(item => item !== moduleId)
      : [...inputValues.userModuleAccess, moduleId]
    setInputValues(prevState => ({
      ...prevState,
      userModuleAccess: newModuleAccess,
    }))
    await updatedUser({
      id: userData.userId.toString(),
      userData: {
        moduleAccessIds: newModuleAccess,
      },
    }).then(() => {
      setSuccessMessage('Module Access Updated')
      clearStatuses()
      setIsUserUpdating(false)
    })
  }

  const clearStatuses = () => {
    setIsEditState(false)
    setShowSuccessMessage(true)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
    }, 3000)
  }

  const handleSave = () => {
    updatedUser({
      id: userData.userId.toString(),
      userData: {
        firstName: inputValues.userName.split(' ')[0],
        lastName: inputValues.userName.split(' ')[1],
        email: inputValues.userEmail,
        userType: inputValues.userType,
        accessLevel: inputValues.userAccessLevel,
        jobTitle: inputValues.jobTitle,
        companyId: inputValues.userCompany,
        status: inputValues.userAccountStatus,
      },
    }).then(() => {
      setSuccessMessage('User Details Updated')
      clearStatuses()
      setDefaultUserData(inputValues)
    })
  }

  return (
    <div className={'h-full overflow-auto pb-4'}>
      <div
        className={
          'mb-12 flex items-center gap-4 border-b border-b-primary-black/10 px-4 py-3'
        }
      >
        <ArrowLeft
          fill={'currentColor'}
          width={24}
          height={24}
          onClick={() => {
            setUserProfileOpen(initialUserProfile)
          }}
          className='cursor-pointer text-grey-7 hover:text-primary-red'
        />
        <div>
          <p className={'text-xs font-semibold opacity-50'}>
            {defaultUserData.companyLabel}
          </p>
          <h1 className='text-2xl font-semibold text-primary-black'>
            {defaultUserData.userName}
          </h1>
        </div>
      </div>

      <div className='mx-auto w-[800px] max-w-[90%]'>
        <div className='mb-3 flex items-center justify-between'>
          <p className={'text-sm font-medium text-primary-black'}>
            User Details
          </p>
          <div>
            {isEditState ? (
              <>
                <button
                  className='px-[10px] text-xs font-semibold hover:text-primary-red'
                  onClick={() => setIsEditState(false)}
                >
                  Cancel
                </button>
                <button
                  className={twMerge(
                    'rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white',
                    (!inputValues.userName ||
                      !inputValues.userCompany ||
                      !inputValues.jobTitle ||
                      !inputValues.userEmail ||
                      !inputValues.userType ||
                      !inputValues.userAccessLevel ||
                      !inputValues.userDateJoined ||
                      !inputValues.userLastLogin) &&
                      'disabled:bg-gray-500',
                  )}
                  onClick={() => {
                    handleSave()
                  }}
                  disabled={
                    !inputValues.userName ||
                    !inputValues.userCompany ||
                    !inputValues.jobTitle ||
                    !inputValues.userEmail ||
                    !inputValues.userType ||
                    !inputValues.userAccessLevel ||
                    !inputValues.userDateJoined ||
                    !inputValues.userLastLogin
                  }
                >
                  Save
                </button>
              </>
            ) : (
              <button
                className='rounded-full bg-primary-grey px-[10px] py-1 text-xs font-semibold text-primary-white transition-colors hover:bg-primary-grey/50'
                onClick={() => setIsEditState(true)}
              >
                Edit Details
              </button>
            )}
          </div>
        </div>
        <div className='mb-[44px] rounded border border-primary-black/20'>
          <div className='flex items-center border-b px-4 py-3'>
            <p className='w-24 text-sm font-medium lg:w-48'>Name</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Input
                  containerWrapperClassName='pr-5'
                  wrapperClassName='w-full h-8 rounded-md'
                  inputClassName='pl-3 text-md py-2'
                  value={inputValues.userName}
                  onChange={e => handleInputChange(e, 'userName')}
                />
              ) : (
                <p className='px-3 py-1 text-sm'>{defaultUserData.userName}</p>
              )}
            </div>
          </div>
          <div className='flex items-center border-b px-4 py-3'>
            <p className='w-24 text-sm font-medium lg:w-48'>Company</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Select
                  id={'userCompany'}
                  containerClass='w-full mr-5'
                  value={inputValues.userCompany}
                  handleClick={option => {
                    handleSelect(option, 'userCompany')
                  }}
                  buttonClass='h-8 items-center'
                  options={companyFilterOptions}
                  size='small'
                  placeholder='Select Company'
                  defaultValue={'all'}
                  hasRadioButton={false}
                  error={'Company is required'}
                  errorClass={'pt-1.5 text-xs'}
                />
              ) : (
                <p className='px-3 py-1 text-sm'>
                  {defaultUserData.companyLabel || defaultUserData.userCompany}
                </p>
              )}
            </div>
          </div>
          <div className='flex items-center border-b px-4 py-3'>
            <p className='w-24 text-sm font-medium lg:w-48'>Job Role</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Input
                  containerWrapperClassName='pr-5'
                  wrapperClassName='w-full h-8 rounded-md'
                  inputClassName='pl-3 text-md py-2'
                  value={inputValues.jobTitle}
                  onChange={e => handleInputChange(e, 'jobTitle')}
                />
              ) : (
                <p className='px-3 py-1 text-sm'>{defaultUserData.jobTitle}</p>
              )}
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Email</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='px-3 py-1 text-sm'>{defaultUserData.userEmail}</p>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>User Type</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Select
                  id={'userType'}
                  containerClass='w-full mr-5'
                  buttonClass='h-8 items-center'
                  value={inputValues.userType}
                  handleClick={option => {
                    handleSelect(option, 'userType')
                  }}
                  options={[
                    {
                      id: '1',
                      value: 'standard',
                      label: 'Standard',
                    },
                    {
                      id: '2',
                      value: 'admin',
                      label: 'Admin',
                    },
                  ]}
                  size='small'
                  placeholder='User Type'
                  defaultValue={'all'}
                  hasRadioButton={false}
                  errorClass={'pt-1.5 text-xs'}
                />
              ) : (
                <p className='px-3 py-1 text-sm capitalize'>
                  {defaultUserData.userType || 'N/A'}
                </p>
              )}
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>User Access</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Select
                  id={'accessLevel'}
                  containerClass='w-full mr-5'
                  disabled={inputValues.userType === ''}
                  value={inputValues.userAccessLevel}
                  handleClick={option => {
                    handleSelect(option, 'userAccessLevel')
                  }}
                  buttonClass='h-8 items-center'
                  options={
                    inputValues.userType === 'standard'
                      ? accessLevelOptions[0]
                      : accessLevelOptions[1]
                  }
                  size='small'
                  placeholder='Access Level'
                  defaultValue={'all'}
                  hasRadioButton={false}
                  error={'Access Level is required'}
                  errorClass={'pt-1.5 text-xs'}
                />
              ) : (
                <p className='px-3 py-1 text-sm capitalize'>
                  {defaultUserData.userAccessLevel === 'superadmin' ||
                  singleUserData?.user.access_level === 'superadmin'
                    ? 'Super Admin'
                    : defaultUserData.userAccessLevel === 'moduleadmin' ||
                        singleUserData?.user.access_level === 'moduleadmin'
                      ? 'Module Admin'
                      : !defaultUserData.userAccessLevel &&
                          !singleUserData?.user.access_level
                        ? 'N/A'
                        : defaultUserData.userAccessLevel ||
                          singleUserData?.user.access_level}
                </p>
              )}
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Date Joined</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='px-3 py-1 text-sm'>
                {defaultUserData.userDateJoined}
              </p>
            </div>
          </div>
          <div className='flex items-center p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Last Log In</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='flex items-center gap-1 px-3 py-1 text-sm'>
                {userData.lastLogin === 'Online' && (
                  <span className='h-2 w-2 rounded-full bg-green-6'></span>
                )}
                {userData.lastLogin === 'Paused' && (
                  <span className='h-2 w-2 rounded-full bg-red-10'></span>
                )}
                {defaultUserData.userAccountStatus === 'Inactive' && (
                  <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
                )}
                {userData.lastLogin}
              </p>
            </div>
          </div>
          <div className='flex items-center p-3 px-4'>
            <p className='w-24 text-sm font-medium lg:w-48'>Account Status</p>
            <div className='flex flex-grow items-center justify-between'>
              {isEditState ? (
                <Select
                  id={'userStatus'}
                  containerClass='w-full mr-5'
                  value={inputValues.userAccountStatus}
                  handleClick={option => {
                    setInputValues(prevState => ({
                      ...prevState,
                      userAccountStatus: option.value,
                    }))
                  }}
                  buttonClass='h-8 items-center'
                  options={[
                    { id: '1', value: 'active', label: 'Active' },
                    { id: '2', value: 'paused', label: 'Pending' },
                  ]}
                  size='small'
                  placeholder={inputValues.userAccountStatus}
                  hasRadioButton={false}
                />
              ) : (
                <p className='flex w-full items-center justify-between px-3 py-1 text-sm text-primary-black'>
                  <span className={twMerge('flex items-baseline gap-1')}>
                    {defaultUserData.userAccountStatus === 'paused' ? (
                      <>
                        <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
                        Pending
                      </>
                    ) : (
                      <>
                        <span className='h-2 w-2 rounded-full bg-green-6'></span>
                        Active
                      </>
                    )}
                  </span>
                  {defaultUserData.userAccountStatus === 'paused' && (
                    <button className='rounded-full bg-primary-grey px-[10px] py-1 text-xs font-semibold text-primary-white transition-colors hover:bg-primary-grey/50'>
                      Resend Invite
                    </button>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
        <p className='mb-4 text-sm font-medium'>
          Projects ({inputValues.userProjects.length})
        </p>
        <div className='mb-[44px] rounded border border-primary-black/20'>
          <div className='flex items-center justify-between border-b bg-grey-3 p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Name</p>
            <button
              className='inline-flex rounded-full bg-primary-grey px-[10px] py-1 text-xs font-semibold text-primary-white transition-colors hover:bg-primary-grey/50'
              onClick={() => setOpenAddProjects(true)}
            >
              <Plus fill={'currentColor'} width={16} height={16} />
              Add Project
            </button>
          </div>
          {inputValues.userProjects.map((project, index) => (
            <div
              className='flex items-center justify-between border-b p-3 px-4 py-2.5'
              key={index}
            >
              <p className='w-3/4 text-sm font-medium'>{project.name}</p>
              <Trash
                fill={Colors.BASE_ICON}
                width={24}
                height={24}
                className={'cursor-pointer hover:fill-primary-red'}
                onClick={() => {
                  handleDeleteUserProject(project.id)
                }}
              />
            </div>
          ))}
        </div>
        <BaseModal
          isOpen={openAddProjects}
          handleClose={() => setOpenAddProjects(false)}
          modalZIndex={999}
          title={'Add Projects'}
          titleClass={'mb-6'}
        >
          <div className='flex flex-col items-center justify-center'>
            {projectsList.length === 0 ? (
              <p
                className={twMerge(
                  'flex pt-1.5 text-xs text-red-8 duration-500 ease-in',
                )}
              >
                Please create a project at first
              </p>
            ) : (
              <FilterButton
                name={
                  newUserProjects.length === 1
                    ? '1 Project'
                    : newUserProjects.length > 1
                      ? newUserProjects.length + ' Projects'
                      : ''
                }
                containerMenuClassName='left-0'
                nameCLassName='font-normal text-sm h-6 items-center inline-flex'
                isMenuVisible={false}
                leftIcon={false}
                rightIcon={
                  <ChevronDown
                    fill={Colors.BASE_ICON}
                    width={18}
                    height={18}
                    className='ml-auto pl-1'
                  />
                }
                totalSelectedFilters={0}
                containerClassName='w-full'
                wrapperClasName='w-full border-primary-black/20'
                wrapperMenuClassName='pr-0'
                hideTriangle={true}
              >
                <div className='flex max-h-[400px] flex-row gap-10 overflow-auto pr-5'>
                  {
                    <FilterColumnGroup
                      key={'projects'}
                      category={'projects'}
                      optionsFilter={projectsList}
                      isSelect={false}
                      selectedFilters={{ projects: newUserProjects }}
                      onChange={selected => {
                        setNewUserProjects(selected['projects'])
                      }}
                      allLabel={'Select All'}
                    />
                  }
                </div>
              </FilterButton>
            )}
          </div>
          <div className='flex w-full justify-end gap-2 pt-4'>
            <Button
              kind='text'
              size='medium'
              className='py-2.5'
              onClick={() => {
                setOpenAddProjects(false)
                setNewUserProjects([])
              }}
            >
              <span className='px-1 text-xs font-semibold'>Cancel</span>
            </Button>

            <Button
              kind='filled'
              size='medium'
              className={twMerge(
                'bg-red-6 py-2.5 hover:bg-red-6/80 active:bg-red-6/60',
              )}
              onClick={() => updateUserProjects()}
              disabled={newUserProjects.length === 0}
            >
              <span className='px-2.5 text-xs font-semibold'>Confirm</span>
            </Button>
          </div>
        </BaseModal>
        <p className='mb-4 text-sm font-medium'>Module Access</p>
        <div className='rounded border border-primary-black/20'>
          <div className='flex items-center border-b bg-grey-3 p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Name</p>
          </div>
          <div className='flex items-center border-b p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Talent</p>
            <div className='flex flex-grow items-center justify-end'>
              <p className='px-3 py-1.5 text-sm text-grey-7'>
                User always have access to this module.
              </p>
              <div className='flex items-center pl-4'>
                <ToggleSwitch
                  checked={true}
                  id={'talentToggle'}
                  color={'bg-primary-blue/20'}
                  size={'medium'}
                  disabled={true}
                  labelClassName={'hover:cursor-not-allowed'}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Comp Finder</p>
            <div className='flex flex-grow items-center justify-end'>
              <p className='px-3 py-1.5 text-sm text-grey-7'>
                User always have access to this module.
              </p>
              <div className='flex items-center pl-4'>
                <ToggleSwitch
                  checked={true}
                  id={'compFinderToggle'}
                  color={'bg-primary-blue/20'}
                  size={'medium'}
                  disabled={true}
                  labelClassName={'hover:cursor-not-allowed'}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Release Calendar</p>
            <div className='flex flex-grow items-center justify-end'>
              <p className='px-3 py-1.5 text-sm text-grey-7'>
                User always have access to this module.
              </p>
              <div className='flex items-center pl-4'>
                <ToggleSwitch
                  checked={true}
                  id={'releaseCalendarToggle'}
                  color={'bg-primary-blue/20'}
                  size={'medium'}
                  disabled={true}
                  labelClassName={'hover:cursor-not-allowed'}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Film Tracking</p>
            <div className='flex flex-grow items-center justify-end'>
              <p className='px-3 py-1.5 text-sm text-grey-7'>
                User always have access to this module.
              </p>
              <div className='flex items-center pl-4'>
                <ToggleSwitch
                  checked={true}
                  id={'filmTrackingToggle'}
                  color={'bg-primary-blue/20'}
                  size={'medium'}
                  disabled={true}
                  labelClassName={'hover:cursor-not-allowed'}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Concept Testing</p>
            <div className='flex flex-grow items-center justify-end'>
              <div className='flex items-center pl-4'>
                <ToggleSwitch
                  onChange={() => handleModuleAccessChange(6)}
                  checked={inputValues.userModuleAccess.includes(6)}
                  id={'conceptTestingToggle'}
                  color={'bg-primary-red'}
                  labelClassName={'hover:cursor-pointer'}
                  size={'medium'}
                  disabled={isUserUpdating}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Role Testing</p>
            <div className='flex flex-grow items-center justify-end'>
              <div className='flex items-center pl-4'>
                <ToggleSwitch
                  onChange={() => handleModuleAccessChange(7)}
                  checked={inputValues.userModuleAccess.includes(7)}
                  id={'roleTestingToggle'}
                  color={'bg-primary-red'}
                  labelClassName={'hover:cursor-pointer'}
                  size={'medium'}
                  disabled={isUserUpdating}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4 py-2.5'>
            <p className='w-24 text-sm font-medium lg:w-48'>Asset Testing</p>
            <div className='flex flex-grow items-center justify-end'>
              <div className='flex items-center pl-4'>
                <ToggleSwitch
                  onChange={() => handleModuleAccessChange(8)}
                  checked={inputValues.userModuleAccess.includes(8)}
                  id={'assetTestingToggle'}
                  color={'bg-primary-red'}
                  labelClassName={'hover:cursor-pointer'}
                  size={'medium'}
                  disabled={isUserUpdating}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div className='fixed bottom-4 right-4 rounded-md bg-primary-white p-3 shadow-md'>
          <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
            <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
            {successMessage}
          </p>
        </div>
      )}
    </div>
  )
}
