import { Key, PropsWithChildren, ReactNode } from 'react'

export type TabHeader = {
  label: ReactNode | undefined
  key: Key | null
  icon?: ReactNode | undefined
  headerClasses?: string
  wrapperClasses?: string
  activeTabHeaderClasses?: string
}

export type TabProps = PropsWithChildren<TabHeader>

export const Tab = ({ children }: TabProps) => {
  return <div>{children}</div>
}
