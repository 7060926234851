import { Close } from 'components/ui/icons'
import { CompareDetails, TalentColors, TalentDateRange } from 'types'
import { IconButton, RankWithSuffix } from 'components'
import { Colors } from 'constnants'
import { twMerge } from 'tailwind-merge'
import { formatNumberWithSuffix } from 'utils'
import { subDays, format, eachDayOfInterval } from 'date-fns'
import { DateRange as PickerDateRange } from 'react-day-picker'
import { useMemo } from 'react'

interface TalentTasteTableProps {
  compareDetails: CompareDetails[]
  talentColors: TalentColors
  handleDelete: (id: string) => void
  data: any
  isPercentage?: boolean
  isRankView?: boolean
  talentDateRange?: TalentDateRange
  selectedDateRange?: PickerDateRange
}

const transformDataToTalentData = (
  responseData: any,
  isPercentage = false,
  range: TalentDateRange = 'YEAR',
  selectedDateRange?: PickerDateRange,
) => {
  if (!responseData || !responseData.talents) {
    return []
  }

  const calculateStartDate = () => {
    const today = new Date()
    switch (range) {
      case 'YEAR':
        return subDays(today, 365)
      case 'HALF_YEAR':
        return subDays(today, 182)
      case 'QUARTER':
        return subDays(today, 91)
      case 'MONTH':
        return subDays(today, 30)
      case 'DATE_RANGE':
        if (selectedDateRange) {
          return selectedDateRange.from
        }
        return subDays(today, 365)
      default:
        return subDays(today, 365)
    }
  }

  const startDate = calculateStartDate()
  const endDate =
    range === 'DATE_RANGE'
      ? (selectedDateRange?.to ?? selectedDateRange?.from)
      : new Date()

  // Generate all the days in the range (year, half-year, quarter, or month)
  const allDays = eachDayOfInterval({
    start: startDate,
    end: endDate,
  })

  return responseData.talents.map((talent: any) => {
    const surveys = [...talent.surveys] // Copy the array to avoid mutating the original
    // Assuming allDays is an array of dates sorted in descending order (most recent first)
    const firstDay = allDays[0] // The current survey date

    // Find the previous survey with a non-null value
    const previousSurvey = surveys.find(
      (s: any) =>
        s.date_survey_id < format(firstDay, 'yyyyMMdd') && s.data !== null,
    )

    if (previousSurvey) {
      console.log('Previous non-null survey found:', previousSurvey)
    } else {
      console.log('No previous survey with non-null value found.')
    }
    let nextValue: number | null = previousSurvey ? previousSurvey?.data : null // Store the next (previous in descending order) non-null value
    // Map all days and find matching surveys
    const periods = allDays
      .map(day => {
        // console.log('day', day, format(day, 'yyyyMMdd'))
        // Find survey data for the current date
        const survey = surveys.find((s: any) => {
          return s.date_survey_id == format(day, 'yyyyMMdd')
        })

        let value = survey ? survey.data : nextValue
        nextValue = value !== null ? value : nextValue // Update nextValue if the current one is not null
        let hide = false
        if (!survey || survey.data === null || survey.data === undefined) {
          hide = true
        }
        if (!value) {
          value = nextValue || 0
        }
        return {
          date: format(day, 'MM/dd/yy'),
          value: isPercentage
            ? `${value?.toFixed(1)}%`
            : formatNumberWithSuffix(value),
          hide, // Add hide: true if present in the survey
        }
      })
      .reverse() // Reverse the array to show the latest date first

    return {
      fullName: talent.talent_name,
      talentId: talent.talent_id,
      periods,
    }
  })
}

export const TalentTasteTable = ({
  compareDetails,
  talentColors,
  handleDelete,
  data,
  isPercentage = false,
  isRankView = false,
  talentDateRange,
  selectedDateRange,
}: TalentTasteTableProps) => {
  const isOneCompareDetails = compareDetails.length === 1

  const talentData = useMemo(
    () =>
      transformDataToTalentData(
        data,
        isPercentage,
        talentDateRange,
        selectedDateRange,
      ),
    [data, isPercentage, talentDateRange, selectedDateRange],
  )

  const compareDetailsUpdated = useMemo(() => {
    return compareDetails.map(talent => {
      const data = talentData.find(it => it.talentId === talent.talentId)
      const periods = data?.periods ?? talent.periods
      return {
        ...talent,
        periods: isRankView
          ? periods.map(period => {
              return {
                ...period,
                value: <RankWithSuffix rank={period.value} />,
              }
            })
          : periods,
      }
    })
  }, [compareDetails, talentData, isRankView])

  return (
    <div className='flex h-[311px] flex-col rounded bg-primary-white p-2'>
      <div className='max-h-[295px] overflow-auto'>
        <table className='w-full'>
          <thead>
            <tr className='border-b-opacity-10 border-b p-1'>
              <th className='w-32 min-w-[140px] text-left text-xs font-normal text-gray-700'>
                Date
              </th>
              {compareDetailsUpdated.map(({ fullName, talentId }, i) => (
                <th
                  key={`${fullName}_${i}`}
                  className='w-38 min-w-[140px] gap-0.5 text-left text-xs font-normal text-gray-700'
                >
                  <div className='flex min-w-full items-center space-x-1'>
                    <div
                      className={twMerge(
                        'mr-1 h-3 w-3 rounded-full',
                        isOneCompareDetails && 'hidden',
                      )}
                      style={{
                        backgroundColor: !isOneCompareDetails
                          ? talentColors[talentId]
                          : '',
                      }}
                    />
                    {fullName}
                    {i > 0 && (
                      <IconButton
                        className='rounded p-0.5 hover:bg-opacityGrey-10'
                        onClick={() => handleDelete(talentId)}
                      >
                        <Close fill={Colors.BASE_ICON} width={14} height={14} />
                      </IconButton>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {compareDetailsUpdated[0]?.periods.map((period, index) => {
              return (
                <tr
                  key={`${period.date}_${index}`}
                  className='border-b-opacity-10 border-b p-1'
                >
                  <td className='w-32 text-xs text-gray-700'>{period.date}</td>
                  {compareDetailsUpdated.map(({ periods, talentId }, i) => {
                    const period = periods[index]

                    let value = period?.value

                    if (isPercentage) {
                      const num = parseFloat(
                        period?.value.trim().replace('%', ''),
                      )

                      const rounded = num.toFixed(0)
                      value = parseFloat(rounded) === 0 ? '0%' : `${rounded}%`
                    }

                    return (
                      <td
                        key={`${period?.value}_${i}`}
                        className='w-38 text-xs font-medium text-gray-700'
                        style={{
                          color: period?.hide
                            ? '#374151'
                            : talentColors[talentId],
                        }}
                      >
                        {value}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
