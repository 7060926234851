import { twMerge } from 'tailwind-merge'

interface LabelProps {
  label: string
  labelClassName?: string
}

export const Label = ({ label, labelClassName }: LabelProps) => {
  return (
    <span
      className={twMerge(
        'text-sm tracking-[-0.6%] text-grey-7',
        labelClassName,
      )}
    >
      {label}
    </span>
  )
}
