import { useCallback, useState } from 'react'
import { ProjectsListSearch } from 'components/ProjectsListSearch'
import { OptionType } from 'types'

type ListSize = 'small' | 'medium'
interface ProjectsListDropdownProps {
  projects: { name: string; id: string }[]
  listSize?: ListSize
  handleAddProject: (project: OptionType<number>) => void
}

const listMaxHeightBindings: Record<ListSize, string> = {
  small: 'max-h-36',
  medium: 'max-h-80',
}

export const ProjectsListDropdown = ({
  projects,
  listSize = 'medium',
  handleAddProject,
}: ProjectsListDropdownProps) => {
  const filter = useCallback(
    (project: OptionType<number>) => !projects.some(it => it.id == project.id),
    [projects],
  )
  const listMaxHeight = listMaxHeightBindings[listSize]
  return (
    <div>
      <p
        className={
          'mb-2 border-b border-b-primary-black/10 p-2 text-sm font-medium text-primary-black'
        }
      >
        Open Project Comp List
      </p>
      <div className={`${listMaxHeight} overflow-auto p-2`}>
        <ProjectsListSearch
          filter={filter}
          onSelectProject={handleAddProject}
        />
      </div>
    </div>
  )
}
