import { ColumnsProjectTable } from 'types'
import { twMerge } from 'tailwind-merge'
import { formatDate } from 'utils'

interface UsersTableCellProps {
  column: ColumnsProjectTable
  row: {
    id: string | number
    name: string
    email: string
    access_level: string
    last_login: string
  }
  isLoading?: boolean
}

export const UsersTableCell = ({
  column,
  row,
  isLoading = false,
}: UsersTableCellProps) => {
  switch (column.value) {
    case 'name':
      return (
        <span
          className={twMerge(
            'text-xs font-medium text-primary-black',
            isLoading ? 'bg-gray-500 text-transparent' : '-mt-0.5 block',
          )}
        >
          {row.name}
        </span>
      )
    case 'email':
      return (
        <span
          className={twMerge(
            'text-xs',
            row.email === 'complete' ? 'text-green-6' : 'text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.email}
        </span>
      )
    case 'last_login':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
            (row.last_login === 'Online' || row.last_login === 'Paused') &&
              'flex items-center gap-1',
          )}
        >
          {row.last_login === 'Online' && (
            <span className='h-2 w-2 rounded-full bg-green-6'></span>
          )}
          {row.last_login === 'Paused' && (
            <span className='h-2 w-2 rounded-full bg-red-10'></span>
          )}
          {row.last_login}
        </span>
      )
    case 'access_level':
      return (
        <span
          className={twMerge(
            'text-xs capitalize text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.access_level === 'superadmin'
            ? 'Super Admin'
            : row.access_level === 'moduleadmin'
              ? 'Module Admin'
              : !row.access_level
                ? 'N/A'
                : row.access_level}
        </span>
      )
    default:
      return <span></span>
  }
}
