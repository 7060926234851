import React from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

const whiteTextKeys = ['location', 'interest', 'paytosee']

type ButtonProps = {
  value: string
  label: string
  labelSize?: 'xs' | 'sm'
  primaryColor: string
  id: string
  activeButton: string
  onActiveButton: (key: string) => void
}

const ButtonComponent: React.FC<ButtonProps> = ({
  value,
  label,
  labelSize = 'sm',
  primaryColor,
  id,
  activeButton,
  onActiveButton,
}) => {
  const isActive = activeButton === id
  const buttonClasses = twMerge(
    'flex w-[120px] items-center justify-between font-semibold h-[34px] rounded-md border border-primary-grey/40 px-2 text-left',
    classNames({
      'text-primary-white': whiteTextKeys.includes(id) && isActive,
    }),
  )

  const buttonStyles = isActive
    ? { backgroundColor: primaryColor, borderColor: 'transparent' }
    : {}

  return (
    <button
      className={buttonClasses}
      onClick={() => onActiveButton(id)}
      style={buttonStyles}
    >
      <span className={labelSize ? `text-${labelSize}` : 'text-[14px]/[24px]'}>
        {label}
      </span>
      <span className='text-[14px]/[24px]'>{value}</span>
    </button>
  )
}

export default ButtonComponent
