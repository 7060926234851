import { Image, SectionTitle } from 'components'
import { Film } from '../../hooks'

interface LatestProjectListProps {
  projects: Film[] | undefined
}

export const LatestProjectList = ({ projects }: LatestProjectListProps) => {
  return (
    <div>
      <SectionTitle title='Latest project' />
      <ul className='flex flex-col gap-2.5'>
        {projects &&
          projects.map(
            ({ film_id, primary_image_url, film_name, release_year }) => (
              <li key={film_id} className='flex gap-4'>
                <Image
                  src={primary_image_url}
                  alt={film_name}
                  className='h-[50px] w-10'
                />
                <div>
                  <p className='text-sm font-medium text-primary-black'>
                    {film_name}
                  </p>
                  <p className='text-sm text-primary-black'>{release_year}</p>
                </div>
              </li>
            ),
          )}
      </ul>
    </div>
  )
}
