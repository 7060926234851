import { TypeSelectorTitle } from 'components'
import { RoleCard } from './RoleCard'
import { RoleProject } from 'types'

interface RoleListProps {
  roles: RoleProject[]
  onDeleteRole: (id: string) => void
  onUpdateRole: (roleId: string, data: Partial<RoleProject>) => void
}

export const RoleList = ({
  roles,
  onDeleteRole,
  onUpdateRole,
}: RoleListProps) => {
  return (
    <div>
      <div className='grid grid-cols-[3fr,4fr] gap-4'>
        <TypeSelectorTitle title='Role name & Type' />
        <TypeSelectorTitle title='Role Description' />
      </div>
      <ul className='flex flex-col space-y-5'>
        {roles.map((role, index) => {
          const allowDeletetion = index > 0
          return (
            <li
              key={role.roleId}
              className='border-t border-t-primary-black/10 pt-5'
            >
              <RoleCard
                roleName={role.roleName}
                roleType={role.roleType.value}
                description={role.description}
                attachedTalent={role.attachedTalent.label}
                onUpdateChange={data => onUpdateRole(role.roleId, data)}
                onDeleteRole={
                  allowDeletetion ? () => onDeleteRole(role.roleId) : undefined
                }
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
