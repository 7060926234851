import { BaseQueryFn, EndpointBuilder } from '@reduxjs/toolkit/query/react'

import type {
  TVManagementData,
  TVManagementSortColKey,
} from 'modules/management'

type PaginatedRequest = {
  pageSize: number
  pageNumber: number
  searchTerm: string | undefined
  sortBy: TVManagementSortColKey
  sortOrder: 'ASC' | 'DESC'
}

type PaginatedResponse = {
  totalRecords: number
  totalPages: number
  currentPage: number
  pageSize: number
  data: TVManagementData[]
}

function simulatePaginatedApi(
  mockData: TVManagementData[],
  delay: number,
  request: PaginatedRequest,
): Promise<PaginatedResponse> {
  const { pageSize, pageNumber, sortOrder, sortBy, searchTerm } = request

  return new Promise(resolve => {
    setTimeout(() => {
      // Filter data by `Title`
      const filteredData = mockData.filter(item => {
        const matchesSearchTerm = searchTerm
          ? item.title.toLowerCase().includes(searchTerm.toLowerCase())
          : true

        return matchesSearchTerm
      })

      const totalRecords = filteredData.length

      const sortedData = [...filteredData].sort((a, b) => {
        if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
          const comparison = (a[sortBy] as string).localeCompare(
            b[sortBy] as string,
          )
          return sortOrder === 'ASC' ? comparison : -comparison
        }
        if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
          const comparison = (a[sortBy] as number) - (b[sortBy] as number)
          return sortOrder === 'ASC' ? comparison : -comparison
        }
        return 0
      })

      const totalPages = Math.ceil(totalRecords / pageSize)
      const startIndex = (pageNumber - 1) * pageSize
      const endIndex = Math.min(startIndex + pageSize, totalRecords)
      const paginatedData = sortedData.slice(startIndex, endIndex)

      const response: PaginatedResponse = {
        totalRecords,
        totalPages,
        currentPage: pageNumber,
        pageSize,
        data: paginatedData,
      }

      resolve(response)
    }, delay)
  })
}

export function defineGetTVManagementDataEndpoint(
  builder: EndpointBuilder<BaseQueryFn, never, 'mockApi'>,
) {
  return builder.query<PaginatedResponse, PaginatedRequest>({
    queryFn: async request => {
      try {
        const response = await simulatePaginatedApi(
          tvManagementMockData,
          2000,
          request,
        )
        return { data: response }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error fetching paginated data' },
        }
      }
    },
  })
}

const tvManagementMockData: TVManagementData[] = [
  {
    id: '1',
    imdbId: 'tt21191806',
    title: 'Back in Action',
    date: '2024-11-15',
    networkType: 'TV - Streamer',
    network: 'NFX',
    networkImdbId: 'co0144901',
    tvType: 'Film',
    tvSubType: 'Streaming Original',
    genre: 'Comedy',
  },
  {
    id: '2',
    imdbId: 'tt17491088',
    title: 'The Diplomat',
    date: '2024-10-31',
    networkType: 'TV - Streamer',
    network: 'NFX',
    networkImdbId: 'co0144901',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Drama',
  },
  {
    id: '3',
    imdbId: 'tt13332622',
    title: 'Before',
    date: '2024-10-25',
    networkType: 'TV - Streamer',
    network: 'APL+',
    networkImdbId: 'co0546168',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Talk Series',
  },
  {
    id: '4',
    imdbId: 'tt7908628',
    title: 'What We Do in the Shadows',
    date: '2024-10-21',
    networkType: 'TV - Cable',
    network: 'FX',
    networkImdbId: 'co0060381',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Comedy',
  },
  {
    id: '5',
    imdbId: 'tt21191806',
    title: "Happy's Place",
    date: '2024-11-15',
    networkType: 'TV - Cable',
    network: 'NBC',
    networkImdbId: 'co0072315',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Family - Live Action',
  },
  {
    id: '6',
    imdbId: 'tt14852960',
    title: 'Lopez vs. Lopez',
    date: '2024-10-18',
    networkType: 'TV - Cable',
    network: 'NBC',
    networkImdbId: 'co0072315',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Comedy',
  },
  {
    id: '7',
    imdbId: 'tt14852960',
    title: '2024 Emmys',
    date: '2024-10-18',
    networkType: 'TV - Cable',
    network: 'NBC',
    networkImdbId: 'co0072315',
    tvType: 'Live Event / Specials',
    tvSubType: '--',
    genre: '--',
  },
  {
    id: '8',
    imdbId: 'tt1595859',
    title: 'Blue Bloods',
    date: '2024-10-18',
    networkType: 'TV - Cable',
    network: 'NBC',
    networkImdbId: 'co0072315',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Documentary',
  },
  {
    id: '9',
    imdbId: 'tt21906238',
    title: 'Rivals',
    date: '2024-10-18',
    networkType: 'TV - Streamer',
    network: 'HULU',
    networkImdbId: 'co02188568',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Game Show',
  },
  {
    id: '10',
    imdbId: 'tt15677150',
    title: 'Shrinking',
    date: '2024-10-16',
    networkType: 'TV - Streamer',
    network: 'APL+',
    networkImdbId: 'co0546168',
    tvType: 'Comedy',
    tvSubType: 'Season Premiere',
    genre: 'Comedy',
  },
  {
    id: '11',
    imdbId: 'tt15677150',
    title: 'Disclaimer',
    date: '2024-10-11',
    networkType: 'TV - Streamer',
    network: 'APL+',
    networkImdbId: 'co0546168',
    tvType: 'Comedy',
    tvSubType: 'Series Premiere',
    genre: 'Sports',
  },
  {
    id: '12',
    imdbId: 'tt13930822',
    title: 'Tomb Raider: The Legend of Lara Croft',
    date: '2024-10-10',
    networkType: 'TV - Streamer',
    network: 'NFX',
    networkImdbId: 'co0144901',
    tvType: 'Comedy',
    tvSubType: 'Series Premiere',
    genre: 'Drama',
  },
  {
    id: '13',
    imdbId: 'tt14218830',
    title: 'Abbott Elementary',
    date: '2024-10-09',
    networkType: 'TV - Cable',
    network: 'ABC',
    networkImdbId: 'co0037052',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Comedy',
  },
  {
    id: '14',
    imdbId: 'tt14577874',
    title: "It's What's Inside",
    date: '2024-10-04',
    networkType: 'TV - Streamer',
    network: 'NFX',
    networkImdbId: 'co0144901',
    tvType: 'Film',
    tvSubType: 'Streaming Premiere',
    genre: 'Comedy',
  },
  {
    id: '15',
    imdbId: 'tt12345678',
    title: 'Time Warriors',
    date: '2024-10-23',
    networkType: 'TV - Streamer',
    network: 'HULU',
    networkImdbId: 'co02188568',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Sci-Fi',
  },
  {
    id: '16',
    imdbId: 'tt89012345',
    title: 'Cooking Masters',
    date: '2024-12-01',
    networkType: 'TV - Cable',
    network: 'FOX',
    networkImdbId: 'co0041172',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Reality',
  },
  {
    id: '17',
    imdbId: 'tt90123456',
    title: 'Space Chronicles',
    date: '2024-12-05',
    networkType: 'TV - Streamer',
    network: 'APL+',
    networkImdbId: 'co0546168',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Adventure',
  },
  {
    id: '18',
    imdbId: 'tt15654867',
    title: 'Epic Quests',
    date: '2024-11-12',
    networkType: 'TV - Streamer',
    network: 'DIS+',
    networkImdbId: 'co0333509',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Adventure',
  },
  {
    id: '19',
    imdbId: 'tt12457890',
    title: 'Nature Watch',
    date: '2024-12-10',
    networkType: 'TV - Cable',
    network: 'PBS',
    networkImdbId: 'co0001968',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Documentary',
  },
  {
    id: '20',
    imdbId: 'tt21456890',
    title: 'World Travels',
    date: '2024-12-15',
    networkType: 'TV - Streamer',
    network: 'APL+',
    networkImdbId: 'co0546168',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Travel',
  },
  {
    id: '21',
    imdbId: 'tt87345982',
    title: 'Hidden Legends',
    date: '2024-12-18',
    networkType: 'TV - Streamer',
    network: 'NFX',
    networkImdbId: 'co0144901',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Mystery',
  },
  {
    id: '22',
    imdbId: 'tt45612378',
    title: 'Galaxy Explorers',
    date: '2024-12-20',
    networkType: 'TV - Streamer',
    network: 'DIS+',
    networkImdbId: 'co0333509',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Sci-Fi',
  },
  {
    id: '23',
    imdbId: 'tt75894567',
    title: 'The Big Quiz',
    date: '2024-11-30',
    networkType: 'TV - Cable',
    network: 'CBS',
    networkImdbId: 'co0026472',
    tvType: 'Game Show',
    tvSubType: 'Special',
    genre: 'Reality',
  },
  {
    id: '24',
    imdbId: 'tt94857632',
    title: 'Romance Chronicles',
    date: '2024-12-07',
    networkType: 'TV - Streamer',
    network: 'NFX',
    networkImdbId: 'co0144901',
    tvType: 'Film',
    tvSubType: 'Streaming Original',
    genre: 'Romance',
  },
  {
    id: '25',
    imdbId: 'tt13257648',
    title: 'Warriors Unite',
    date: '2024-12-25',
    networkType: 'TV - Streamer',
    network: 'APL+',
    networkImdbId: 'co0546168',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Action',
  },
  {
    id: '26',
    imdbId: 'tt85245789',
    title: 'Magic Makers',
    date: '2024-11-29',
    networkType: 'TV - Cable',
    network: 'HULU',
    networkImdbId: 'co02188568',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Fantasy',
  },
  {
    id: '27',
    imdbId: 'tt98456732',
    title: 'City Stories',
    date: '2024-12-02',
    networkType: 'TV - Cable',
    network: 'NBC',
    networkImdbId: 'co0072315',
    tvType: 'Series',
    tvSubType: 'Season Premiere',
    genre: 'Drama',
  },
  {
    id: '28',
    imdbId: 'tt12547683',
    title: 'Holiday Heroes',
    date: '2024-12-24',
    networkType: 'TV - Streamer',
    network: 'DIS+',
    networkImdbId: 'co0333509',
    tvType: 'Film',
    tvSubType: 'Streaming Premiere',
    genre: 'Family',
  },
  {
    id: '29',
    imdbId: 'tt74356894',
    title: 'Journey of the Stars',
    date: '2024-12-12',
    networkType: 'TV - Streamer',
    network: 'HULU',
    networkImdbId: 'co02188568',
    tvType: 'Series',
    tvSubType: 'Series Premiere',
    genre: 'Sci-Fi',
  },
  {
    id: '30',
    imdbId: 'tt31254789',
    title: 'Winter Tales',
    date: '2024-12-22',
    networkType: 'TV - Streamer',
    network: 'APL+',
    networkImdbId: 'co0546168',
    tvType: 'Film',
    tvSubType: 'Streaming Original',
    genre: 'Drama',
  },
]
