import { useState, useMemo } from 'react'

import { Tooltip } from 'react-tooltip'

import { AssignUsersToProject } from 'components'
import { IconButton, Plus } from 'components/ui'
import { Colors, myProjectsAssignedUsers } from 'constnants'
import { getInitials } from 'utils'
import { AssigneeAvatar } from '../../AssigneeAvatar'
import { Label } from './Label'
import { AssignedUser } from 'types'

interface AssignedUsersProps {
  users?: AssignedUser[]
}

const MAX_DISPLAYED_USERS = 5
const tooltipStyles = {
  width: 'auto',
  backgroundColor: '#4D4B4A',
  color: '#FFFFFF',
  borderRadius: '6px',
  boxShadow: '0 3px 3px 3px rgb(0 0 0 / 0.02)',
  padding: '8px',
}

export const AssignedUsers = ({
  users = myProjectsAssignedUsers,
}: AssignedUsersProps) => {
  const [isOpenAddAssignedUser, setIsOpenAddAssignedUser] = useState(false)

  const { displayedUsers, additionalUsers } = useMemo(() => {
    const sortedUsersByFirstName = [...users].sort((a, b) =>
      a.fullName.localeCompare(b.fullName),
    )

    const displayedUsers = sortedUsersByFirstName.slice(0, MAX_DISPLAYED_USERS)
    const additionalUsers = sortedUsersByFirstName.slice(MAX_DISPLAYED_USERS)
    return {
      displayedUsers,
      additionalUsers,
    }
  }, [users])

  return (
    <div className='flex items-center space-x-0.5'>
      <Label label='Assignees' />
      <div className='flex space-x-0.5 pl-3 pr-1'>
        {displayedUsers.map(({ fullName, id }) => (
          <AssigneeAvatar
            key={id}
            className='bg-grey-4 text-black'
            initials={getInitials(fullName) || ''}
          />
        ))}

        {additionalUsers.length > 0 && (
          <>
            <span
              data-tooltip-id='team-tooltip'
              className='ml-1 text-sm text-primary-black hover:cursor-pointer'
            >
              <AssigneeAvatar
                className='border-transparent bg-white text-black hover:border-primary-black/10 hover:bg-black/5'
                initials={`+${additionalUsers.length}`}
              />
            </span>
            <Tooltip
              id='team-tooltip'
              place='bottom'
              style={tooltipStyles}
              render={() => (
                <div className='flex flex-col space-y-1'>
                  {additionalUsers.map(({ fullName, id }) => (
                    <div key={id} className='flex items-center gap-2'>
                      <AssigneeAvatar
                        initials={getInitials(fullName) || ''}
                        className='h-6 w-6 text-black'
                      />
                      <span className='text-xs font-medium text-primary-white'>
                        {fullName}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              opacity={1}
            />
          </>
        )}

        <IconButton
          className='border border-primary-black/20'
          onClick={() => setIsOpenAddAssignedUser(true)}
        >
          <Plus fill={Colors.BASE_ICON} width={16} height={16} />
        </IconButton>
      </div>
      <AssignUsersToProject
        isOpen={isOpenAddAssignedUser}
        onClose={() => setIsOpenAddAssignedUser(false)}
      />
    </div>
  )
}
