import { ChangeEvent, useState } from 'react'
import { Input } from 'components'

interface RunTimeProps {
  hoursValue: string
  minutesValue: string
  handleRunTimeChange?: (
    e: ChangeEvent<HTMLInputElement>,
    key: 'hours' | 'minutes',
  ) => void
  inputFirstWrapperClassName?: string
  inputSecondWrapperClassName?: string
}

export const RunTime = ({
  hoursValue,
  minutesValue,
  handleRunTimeChange,
  inputSecondWrapperClassName,
  inputFirstWrapperClassName,
}: RunTimeProps) => {
  const [isHoursFocused, setIsHoursFocused] = useState(false)
  const [isMinutesFocused, setIsMinutesFocused] = useState(false)

  const onFocusHoursInput = () => setIsHoursFocused(true)
  const onBlurHoursInput = () => setIsHoursFocused(false)
  const onFocusMinutesInput = () => setIsMinutesFocused(true)
  const onBlurMinutesInput = () => setIsMinutesFocused(false)

  return (
    <div className='flex w-fit gap-3'>
      <Input
        placeholder={!isHoursFocused && hoursValue === '' ? '0' : ''}
        type='number'
        value={hoursValue}
        wrapperClassName={inputFirstWrapperClassName}
        inputClassName='pl-6 text-md'
        onChange={e => handleRunTimeChange && handleRunTimeChange(e, 'hours')}
        rightIcon={
          <div className='border-l-primary-black/3 flex h-full place-items-center border-l bg-opacityGrey-3 px-2 py-[9.5px] text-sm font-medium leading-5 text-grey-7'>
            Hours
          </div>
        }
        onFocus={onFocusHoursInput}
        onBlur={onBlurHoursInput}
      />
      <Input
        value={minutesValue}
        placeholder={!isMinutesFocused && minutesValue === '' ? '0' : ''}
        type='number'
        wrapperClassName={inputSecondWrapperClassName}
        inputClassName='pl-4 text-md'
        onChange={e => handleRunTimeChange && handleRunTimeChange(e, 'minutes')}
        rightIcon={
          <div className='border-l-primary-black/3 flex h-full place-items-center border-l bg-opacityGrey-3 px-2 py-[9.5px] text-sm font-medium text-grey-7'>
            Minutes
          </div>
        }
        onFocus={onFocusMinutesInput}
        onBlur={onBlurMinutesInput}
      />
    </div>
  )
}
