import { Colors } from 'constnants'
import { Pencil } from 'components'
import { MouseEventHandler } from 'react'

interface EditEntryCellContentProps {
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const EditEntryCellContent = ({
  onClick,
}: EditEntryCellContentProps) => {
  return (
    <div
      className='flex h-6 w-6 cursor-pointer flex-wrap content-center justify-center'
      onClick={onClick}
    >
      <Pencil fill={Colors.BASE_ICON} width={16} height={16} />
    </div>
  )
}
