import { RadioButton } from 'components' // Assuming a RadioButton component exists
import { twMerge } from 'tailwind-merge'
import { OptionType } from 'types'

interface ExclusiveFilterProps<TOptionFilterValue> {
  slug: string
  options: OptionType<TOptionFilterValue>[]
  selected: TOptionFilterValue | undefined
  clasName?: string
  onChange: (selected: TOptionFilterValue) => void
}

export const ExclusiveFilter = <TOptionFilterValue,>({
  slug,
  options,
  selected,
  clasName,
  onChange,
}: ExclusiveFilterProps<TOptionFilterValue>) => {
  return (
    <ul className={twMerge('flex flex-col gap-2', clasName)}>
      {options.map(option => {
        const checked = selected === option.value
        return (
          <li key={option.id}>
            <RadioButton
              slug={slug}
              id={option.id}
              label={option.label}
              checked={checked}
              onChange={() => onChange(option.value)}
            />
          </li>
        )
      })}
    </ul>
  )
}
