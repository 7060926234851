import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

const isAuthenticated = () => {
  return localStorage.getItem('auth') === 'true'
}

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  return isAuthenticated() ? <>{children}</> : <Navigate to='/login' />
}
