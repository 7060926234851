import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { tvFilmCompaniesActions } from '../slices'
import { useMemo } from 'react'

export const useTVFilmCompaniesActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(tvFilmCompaniesActions, dispatch),
    [dispatch],
  )
}
