import { twMerge } from 'tailwind-merge'
import type { MgmtTableTypes } from 'modules/management/shared'

export const MgmtTableRow = ({
  children,
  className,
}: MgmtTableTypes['RowProps']) => (
  <tr className={twMerge('border-b border-primary-black/20', className)}>
    {children}
  </tr>
)
