import { Form } from '../../components/Form'
import './SignUp.css'
export const SignUp = () => {
  return (
    <div className='form-wrapper'>
      <div className='centered-div'>
        <Form />
      </div>
    </div>
  )
}
