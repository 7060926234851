import Modal, { Props as ModalProps } from 'react-modal'
import { twMerge } from 'tailwind-merge'
import { IconButton } from '../IconButton'
import { Colors } from 'constnants'
import { Close } from '../icons'

export type BaseModalProps = ModalProps & {
  handleClose: () => void
  title?: string
  titleClass?: string
  wrapperClass?: string
  maxWidth?: string
  modalZIndex?: number
}

const customStyles = (maxWidth: string, modalZIndex: number) =>
  ({
    overlay: {
      zIndex: modalZIndex,
      backgroundColor: 'rgba(3, 7, 18, 0.5)',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      top: '50%',
      bottom: 'auto',
      margin: '0 auto',
      padding: '0',
      transform: 'translateY(-50%)',
      maxWidth: maxWidth,
      inset: '50% 20px auto',
      overflow: 'visible',
    },
  }) as const

export const BaseModal = ({
  handleClose,
  title,
  children,
  titleClass,
  wrapperClass,
  maxWidth = '420px',
  modalZIndex = 40,
  isOpen,
}: BaseModalProps) => {
  return (
    <Modal
      onRequestClose={handleClose}
      contentLabel={title}
      style={customStyles(maxWidth ?? '', modalZIndex)}
      ariaHideApp={false}
      isOpen={isOpen}
    >
      <>
        <div className={twMerge('relative flex flex-col p-8', wrapperClass)}>
          <IconButton onClick={handleClose} className='absolute right-2 top-2'>
            <Close fill={Colors.BASE_ICON} width={16} height={16} />
          </IconButton>

          {title && (
            <span
              className={twMerge(
                'text-lg font-medium text-primary-black',
                titleClass,
              )}
            >
              {title}
            </span>
          )}
          <div>{children}</div>
        </div>
      </>
    </Modal>
  )
}
