import { ChangeEvent, Key, useEffect, useMemo, useState } from 'react'
import {
  Button,
  CardActivity,
  MyProjectActiveProjectHeader,
  MyProjectsActiveContent,
  ProjectDetailsEmptyContent,
  ProjectsTable,
  StatusTab,
  Tab,
  Tabs,
} from 'components'
import { Colors, columnsProjectsData, MIN_PAGE_SIZE } from 'constnants'
import { Plus } from 'components/ui/icons'
import { IMyProjectRecentActivities, ProjectStatus, SortOrderKey } from 'types'
import { useGetProjectsQuery } from 'store/api'
import { countProjectsByPhaseStatus, formatDate } from 'utils'

interface MyProjectsTopContentProps {
  handleAddProject: () => void
  recentActivities: IMyProjectRecentActivities[]
}

export type SortBy = 'createdAt' | 'updatedAt'
export type PhaseStatus = 'InDevelopment' | 'Concept' | 'Released'
export type SortOrder = 'ASC' | 'DESC'

export type QueryParams = {
  phaseStatus: PhaseStatus
  name?: string
  sortBy: SortBy
  sortOrder: SortOrder
  pageNumber: number
  pageSize: number
}

export const MyProjectsTopContent = ({
  handleAddProject,
}: MyProjectsTopContentProps) => {
  const [initialSelectTab, setInitialSelectTab] = useState<Key | null>('active')
  const [showRecentActivity, setShowRecentActivity] = useState<boolean>(false)
  const token = localStorage.getItem('access_token')
  const companyId = localStorage.getItem('companyId')
  const [searchValue, setSearchValue] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('')
  const [phaseStatus, setPhaseStatus] = useState<PhaseStatus>('InDevelopment')
  const [sortBy, setSortBy] = useState<SortBy>('createdAt')
  const [sortOrder, setSortOrder] = useState<SortOrderKey>('ASC')
  const [pageSize, setPageSize] = useState<number>(MIN_PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const { isLoading, data, error, refetch } = useGetProjectsQuery(
    {
      name: debouncedValue,
      phaseStatus,
      sortBy,
      sortOrder,
      pageSize,
      pageNumber: searchValue ? 1 : pageNumber,
      isActive: initialSelectTab === 'active',
    },
    { skip: !token || !companyId },
  )

  const totalRows = data?.all ?? 0

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedValue(searchValue)
    }, 750)

    return () => clearTimeout(delayInputTimeoutId)
  }, [searchValue])

  useEffect(() => {
    refetch()
  }, [sortBy, sortOrder, pageSize, pageNumber, debouncedValue])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handeClearSearchInput = () => setSearchValue('')

  const archiveProjects = useMemo(() => {
    return data?.projects.filter(project => !project.isActive) || []
  }, [data?.projects])

  const activeProjects = useMemo(() => {
    return data?.projects.filter(project => project.isActive)
  }, [data?.projects])
  const recentActivityProjects = activeProjects?.slice(0, 3)

  const amountActiveProjectsMessage = `You have ${activeProjects?.length} active projects`
  const amountArchiveProjectsMessage = `You have ${archiveProjects?.length} archived projects`

  const handleSelectTab = (tab: Key | null) => {
    setInitialSelectTab(tab)
  }
  const toggleRecentActivity = () => setShowRecentActivity(prev => !prev)

  const inDevelopmentActiveProjects = countProjectsByPhaseStatus(
    activeProjects,
    ProjectStatus.IN_DEVELOPMENT,
  )
  const conceptActiveProjects = countProjectsByPhaseStatus(
    activeProjects,
    ProjectStatus.CONCEPT,
  )
  const releasedActiveProjects = countProjectsByPhaseStatus(
    activeProjects,
    ProjectStatus.RELEASED,
  )

  const inDevelopmentArchiveProjects = countProjectsByPhaseStatus(
    archiveProjects,
    ProjectStatus.IN_DEVELOPMENT,
  )
  const conceptArchiveProjects = countProjectsByPhaseStatus(
    archiveProjects,
    ProjectStatus.CONCEPT,
  )
  const releasedArchiveProjects = countProjectsByPhaseStatus(
    archiveProjects,
    ProjectStatus.RELEASED,
  )

  const handleUpdateQueryParams = (params: Partial<QueryParams>) => {
    !!params.phaseStatus && setPhaseStatus(params.phaseStatus)
    !!params.sortBy && setSortBy(params.sortBy)
    !!params.sortOrder && setSortOrder(params.sortOrder)
    !!params.pageSize && setPageSize(params.pageSize)
    !!params.pageNumber && setPageNumber(params.pageNumber)
  }

  return (
    <div className='h-full'>
      <div className='flex items-center justify-between p-3'>
        <div>
          <span className='block text-xs font-semibold text-primary-black/50'>
            All Projects
          </span>
          <span className='text-lg font-medium leading-6 text-primary-black'>
            My Projects
          </span>
        </div>
        <Button
          kind='filled'
          size='medium'
          className='bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10'
          onClick={handleAddProject}
        >
          <div className='flex items-center'>
            <span className='pr-1 text-sm font-semibold'>New Project</span>
            <Plus fill={Colors.ICON_WHITE} width={22} height={22} />
          </div>
        </Button>
      </div>

      <Tabs
        initialSelectedTab={initialSelectTab}
        handleSelectTab={handleSelectTab}
        tabHeaderClasses='w-1/4 z-10'
      >
        <Tab
          label='Active'
          key='active'
          headerClasses='text-sm font-medium normal-case bg-primary-white hover:bg-trasnparent'
          activeTabHeaderClasses='border-b-2 border-b-primary-blue'
          wrapperClasses='h-full'
        >
          <div className='flex h-[calc(100%-150px)] flex-col'>
            <MyProjectsActiveContent
              showRecentActivity={showRecentActivity}
              toggleRecentActivity={toggleRecentActivity}
              allProjectsAmount={
                inDevelopmentActiveProjects +
                conceptActiveProjects +
                releasedActiveProjects
              }
              inDevelopmentAmount={inDevelopmentActiveProjects}
              conceptAmount={conceptActiveProjects}
              releasedAmount={releasedActiveProjects}
            />
            {!showRecentActivity && (
              <div className='h-3 w-full bg-primary-blue-medium' />
            )}
            {showRecentActivity && (
              <div className='flex h-[190px] w-[calc(100vw_-_56px)] space-x-3 overflow-x-auto bg-primary-blue-medium p-3'>
                {recentActivityProjects?.map(project => {
                  return (
                    <CardActivity
                      key={project.id}
                      name={project.name}
                      lastActivity={formatDate(
                        project.updatedAt,
                        'MMMM DD,YYYY',
                      )}
                    />
                  )
                })}
              </div>
            )}
            {isLoading && !error ? (
              <span className='mx-auto'>Loading...</span>
            ) : !isLoading && !error && data?.projects?.length ? (
              <ProjectsTable
                totalRows={totalRows}
                columns={columnsProjectsData}
                data={activeProjects}
                hasAmountMessage={amountActiveProjectsMessage}
                handleUpdateQueryParams={handleUpdateQueryParams}
                sortBy={sortBy}
                sortOrder={sortOrder}
                pageSize={pageSize}
                pageNumber={pageNumber}
                phaseStatus={phaseStatus}
                deferredQuery={searchValue}
                handleSearchChange={handleInputChange}
                handeClearSearchInput={handeClearSearchInput}
              />
            ) : (
              <div className='grow p-3'>
                <MyProjectActiveProjectHeader amountProjects='You have 0 active projects' />
                <ProjectDetailsEmptyContent
                  title='You don’t have any projects yet'
                  descriptions='Adding projects allows you to leverage our talent database'
                  nameButton='Create your first project'
                  wrapperClassName='bg-primary-white my-auto'
                  descriptionClassName='w-full'
                  onOpen={handleAddProject}
                />
              </div>
            )}
          </div>
        </Tab>

        <Tab
          label='Archive'
          key='archive'
          headerClasses='text-sm font-medium normal-case bg-primary-white hover:bg-trasnparent'
          activeTabHeaderClasses='border-b-2 border-b-primary-blue'
          wrapperClasses='h-full'
        >
          <div>
            <div className='-mt-0.5 border-t-2 border-t-grey-5 p-3'>
              <div className='flex space-x-2'>
                {archiveProjects.length > 0 && (
                  <StatusTab
                    status='All'
                    amount={archiveProjects.length}
                    isActive
                  />
                )}
                {conceptArchiveProjects > 0 && (
                  <StatusTab
                    status='Concept'
                    amount={conceptArchiveProjects ?? 0}
                  />
                )}
                {inDevelopmentArchiveProjects > 0 && (
                  <StatusTab
                    status='In development'
                    amount={inDevelopmentArchiveProjects ?? 0}
                  />
                )}
                {releasedArchiveProjects > 0 && (
                  <StatusTab
                    status='released'
                    amount={releasedArchiveProjects ?? 0}
                  />
                )}
              </div>
            </div>
            <ProjectsTable
              totalRows={totalRows}
              columns={columnsProjectsData}
              data={archiveProjects}
              hasAmountMessage={amountArchiveProjectsMessage}
              handleUpdateQueryParams={handleUpdateQueryParams}
              sortBy={sortBy}
              sortOrder={sortOrder}
              pageSize={pageSize}
              pageNumber={pageNumber}
              phaseStatus={phaseStatus}
              deferredQuery={debouncedValue}
              handleSearchChange={handleInputChange}
              handeClearSearchInput={handeClearSearchInput}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}
