import { twMerge } from 'tailwind-merge'
import { IconButton, TableAssignedUserCell } from 'components'
import { Trash } from 'components/ui/icons'
import { Colors } from 'constnants'
import { ColumnsProjectTable, MyProjectAssignedUser } from 'types'

interface AssignedUserTableBodyProps {
  data: MyProjectAssignedUser[]
  columns: ColumnsProjectTable[]
  onDeleteUser: (user: MyProjectAssignedUser) => void
}

export const AssignedUserTableBody = ({
  data,
  columns,
  onDeleteUser,
}: AssignedUserTableBodyProps) => {
  return (
    <tbody>
      {data.map((row, index) => (
        <tr key={`${row.id}_${index + 1}`} className='project-row'>
          {columns.map(column => (
            <td
              key={column.value}
              className={twMerge('mb-10 rounded bg-primary-white p-2')}
            >
              <TableAssignedUserCell row={row} column={column} />
            </td>
          ))}
          <td className='bg-primary-white'>
            <IconButton onClick={() => onDeleteUser(row)}>
              <Trash fill={Colors.BASE_ICON} width={16} height={16} />
            </IconButton>
          </td>
        </tr>
      ))}
    </tbody>
  )
}
