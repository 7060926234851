export const usersColumns = [
  {
    title: 'Name',
    value: 'first_name',
  },
  {
    title: 'Email',
    value: 'email',
  },
  {
    title: 'Company',
    value: 'company',
  },
  {
    title: 'Job Title',
    value: 'job_title',
  },
  {
    title: 'User Type',
    value: 'user_type',
  },
  {
    title: 'Last Login',
    value: 'last_login_date',
  },
]

export const userTypesFilterOptions = [
  {
    id: '1',
    value: 'superadmin',
    label: 'Super Admin',
  },
  {
    id: '2',
    value: 'moduleadmin',
    label: 'Module Admin',
  },
  {
    id: '3',
    value: 'editor',
    label: 'Editor',
  },
  {
    id: '4',
    value: 'viewer',
    label: 'Viewer',
  },
]

export const adminUsersColumns = [
  {
    title: 'Name',
    value: 'userName',
  },
  {
    title: 'Email',
    value: 'email',
  },
  {
    title: 'User Type',
    value: 'userType',
  },
  {
    title: 'Last Login',
    value: 'lastLogin',
  },
]

export const adminUserTypesFilterOptions = [
  {
    id: '0',
    value: 'All',
    label: 'All',
  },
  {
    id: '1',
    value: 'Super Admin',
    label: 'Super Admin',
  },
  {
    id: '2',
    value: 'Admin',
    label: 'Admin',
  },
]
