import { FilmSelectType } from 'constnants'
import { ReleaseCalendarFilmSelect } from 'components'
import { OptionFilter, SelectedOptions } from 'types'

interface ReleaseCalendarSelectListProps {
  selects: FilmSelectType[]
  filmSelect: Record<string, boolean>
  handleSelectChange: (id: string, checked: boolean) => void
  selectedOptions: SelectedOptions
  onOptionChange: (id: string, selectedOption: OptionFilter[]) => void
  mainToggleOff?: boolean
}

export const ReleaseCalendarSelectList = ({
  selects,
  filmSelect,
  handleSelectChange,
  selectedOptions,
  onOptionChange,
  mainToggleOff = false,
}: ReleaseCalendarSelectListProps) => {
  return (
    <div className='flex w-full flex-col space-y-2'>
      {selects.map(select => {
        return (
          <ReleaseCalendarFilmSelect
            id={select.id}
            key={select.name}
            options={select.options ?? []}
            value={select.name}
            selectIconClassName={select.colorSelect}
            colorToggle={select.colorToggle}
            checked={filmSelect[select.id]}
            isMenuVisible={filmSelect[select.id]}
            onToggleChange={checked => handleSelectChange(select.id, checked)}
            selectedOptions={selectedOptions}
            onOptionChange={onOptionChange}
            isOther={select.isOther}
            hasToggle={select.hasToggle}
            hasAll={select.hasAll}
            mainToggleOff={mainToggleOff}
          />
        )
      })}
    </div>
  )
}
