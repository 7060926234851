import { twMerge } from 'tailwind-merge'
import { UsersTableCellProps } from '../UsersTable.types'

export const UsersTableCell = ({
  column,
  row,
  isLoading = false,
}: UsersTableCellProps) => {
  switch (column.value) {
    case 'userName':
      return (
        <span
          className={twMerge(
            'flex text-xs font-medium text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.userName}
        </span>
      )
    case 'email':
      return (
        <span
          className={twMerge(
            'flex',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          <span className='text-sm'>{row.email}</span>
        </span>
      )
    case 'userType':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.userType}
        </span>
      )
    case 'lastLogin':
      return (
        <span
          className={twMerge(
            'flex items-baseline gap-1 text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.lastLogin === 'Online' && (
            <span className='h-2 w-2 rounded-full bg-green-6'></span>
          )}
          {row.lastLogin === 'Inactive' && (
            <span className='h-2 w-2 rounded-full bg-red-10'></span>
          )}
          {row.lastLogin === '--' && (
            <>
              <span className='h-2 w-2 rounded-full bg-[#ADADAD]'></span>
              Pending
            </>
          )}
          {row.lastLogin !== '--' && row.lastLogin}
        </span>
      )
    default:
      return <span></span>
  }
}
