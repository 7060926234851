import { formatSurveyDate } from './formatSurveyDate'
import { formatDate } from './formatDate'

export const formatBaseDate = (
  date: string | Date | number | undefined,
  format?: string,
) => {
  if (typeof date === 'number') {
    return formatSurveyDate(date)
  }

  return formatDate(date ?? '', format ?? 'MM/DD/YY')
}
