import { Image, LayoutContentWrapper } from 'components'
import homepage from 'assets/images/homepage.png'

export const HomePage = () => {
  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-white'
      wrapperChildrenClassName='flex justify-center items-center'
    >
      <div>
        <h1 className='pb-4 text-center text-2xl font-semibold text-primary-black'>
          Homepage coming soon!
        </h1>
        <Image alt='homepage' src={homepage} className='h-[405px] w-[648px]' />
      </div>
    </LayoutContentWrapper>
  )
}
