import { twMerge } from 'tailwind-merge'
import { ProjectsTableBodyProps } from '../ProjectsTable.types'
import { Project } from 'types'
import { Button, IconButton, WrapperAdditionalMenu } from 'components'
import { MoreVertical, Search, Star, YellowStar } from 'components/ui/icons'
import { Colors } from 'constnants'
import { TableCell } from './TableCell'
import { useClickOutsideComponent } from 'hooks'

export const ProjectsTableBody = <T extends Project>({
  data,
  columns,
  hasSpecialFirstCell = true,
  searchValue,
  onClearSearch,
  onNavigateProject,
  onToggleMenu,
  openMenuId,
  onOpenEditModal,
  onUpdateFavorite,
  onArchiveProject,
}: ProjectsTableBodyProps<T>) => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useClickOutsideComponent(false)

  return (
    <tbody>
      {data?.length ? (
        data.map((row, index) => {
          return (
            <tr key={`${row.id}_${index + 1}`} className='project-row'>
              {columns.map(column => (
                <td
                  key={column.value}
                  className={twMerge(
                    'mb-10 rounded p-2',
                    hasSpecialFirstCell && 'special-cell',
                  )}
                >
                  <TableCell
                    row={row}
                    column={column}
                    onNavigateProject={() =>
                      onNavigateProject(row.id?.toString() ?? '1')
                    }
                  />
                </td>
              ))}
              <td className='relative'>
                <div className='flex gap-2'>
                  <IconButton onClick={() => onUpdateFavorite(row)}>
                    {row.isFeatured ? (
                      <YellowStar />
                    ) : (
                      <Star fill={Colors.BASE_ICON} width={16} height={16} />
                    )}
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setIsComponentVisible(prev => !prev)
                      onToggleMenu(row?.id ?? 0)
                    }}
                  >
                    <MoreVertical
                      fill={Colors.BASE_ICON}
                      width={16}
                      height={16}
                    />
                  </IconButton>
                </div>

                {openMenuId === row.id && isComponentVisible && (
                  <WrapperAdditionalMenu wrapperClassName='right-5 top-8 px-3 py-4 h-fit'>
                    <div className='flex flex-col space-y-2' ref={ref}>
                      <span
                        className='rounded px-2 text-sm leading-6 text-primary-black hover:cursor-pointer hover:bg-primary-black/5'
                        onClick={() => onNavigateProject(row?.id!.toString())}
                      >
                        Open
                      </span>
                      <span
                        className='rounded px-2 text-sm leading-6 text-primary-black hover:cursor-pointer hover:bg-primary-black/5'
                        onClick={() => onOpenEditModal(row?.id ?? 1, row.name)}
                      >
                        Rename
                      </span>
                      <span
                        className='rounded px-2 text-sm leading-6 text-red-10 hover:cursor-pointer hover:bg-primary-black/5'
                        onClick={() => {
                          onArchiveProject(row)
                          setIsComponentVisible(false)
                        }}
                      >
                        {row.isActive ? 'Archive' : 'Unarchive'}
                      </span>
                    </div>
                  </WrapperAdditionalMenu>
                )}
              </td>
            </tr>
          )
        })
      ) : (
        <tr className={twMerge(!data?.length && 'empty-table-body')}>
          <td colSpan={columns.length + 1} rowSpan={300} className='p-4'>
            <div className='flex flex-col items-center justify-center'>
              <Search fill={Colors.BASE_ICON} width={48} height={48} />
              <p className='text-lg font-medium leading-6 text-primary-black'>{`No results found for “${searchValue}”`}</p>
              <span className='text-sm text-primary-black'>
                Please check your spelling, and try again
              </span>
              <Button
                kind='text'
                size='medium'
                className='mt-4 font-normal text-red-9'
                onClick={onClearSearch}
              >
                Clear search
              </Button>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  )
}
