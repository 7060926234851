import { useCallback, useState } from 'react'

import {
  FilmTrackingTable,
  FilmTrackingHeader,
  LayoutContentWrapper,
} from 'components'
import {
  topColumnsFilms,
  columnsFilms,
  getHiddenGroupAndColumnName,
} from 'constnants/filmsTableData'
import { filmTrackingPageSelectors, useFilmTrackingPageActions } from 'slices'
import { useSelector } from 'react-redux'
import { useFilmMetricsControllerGetFilmTrackingQuery } from 'store/api'
import PageLoader from 'components/PageLoader'
import { AudienceGeneralDemo } from 'types'

export const FilmTrackingPage = () => {
  const actions = useFilmTrackingPageActions()

  const {
    pageSize,
    pageNumber,
    selectedFilms,
    filters,
    sorting,
    visibleDemos,
    isInterestAwareActive,
  } = useSelector(filmTrackingPageSelectors.root)

  const sortByResolved =
    isInterestAwareActive && sorting.column === 'interest'
      ? 'interestawaretab'
      : sorting.column

  const { films, totalPages, totalRecords, isLoading } =
    useFilmMetricsControllerGetFilmTrackingQuery(
      {
        pageSize,
        pageNumber,
        audienceFilter: filters.audience ? filters.audience : undefined,
        dist: filters.distributors.length ? filters.distributors : undefined,
        groups: filters.groups.length ? filters.groups : undefined,
        sortBy: sortByResolved,
        sortOrder: sorting.direction,
      },
      {
        selectFromResult: ({ data, ...rest }) => {
          return {
            ...rest,
            totalPages: data?.totalPages ?? 0,
            totalRecords: data?.totalRecords ?? 0,
            films: data?.data ?? [],
          }
        },
      },
    )

  const [columnIsActive, setColumnIsActive] = useState(false)

  const selectAllChecked =
    selectedFilms.length > 0 && selectedFilms.length === films.length

  const handleToggleSelectOne = (rowId: string) => {
    const rowIdStr = rowId.toString()
    const newSelected = selectedFilms.includes(rowIdStr)
      ? selectedFilms.filter(id => id !== rowIdStr)
      : [...selectedFilms, rowIdStr]
    actions.setSelectedFilms(newSelected)
  }

  const handleToggleSelectAll = () => {
    const newSelected = selectAllChecked
      ? []
      : films.map(it => it.id.toString())
    actions.setSelectedFilms(newSelected)
  }

  const setCurrentPage = (value: number) => {
    actions.setPageNumber(value)
  }

  const setRowsPerPage = (value: number) => actions.setPageSize(value)

  const getActiveFilterColumnName = useCallback(
    () => getHiddenGroupAndColumnName(filters.audience),
    [filters.audience],
  )

  const handleSetColumnIsActive = (active: boolean) => {
    actions.setAudienceFilter(AudienceGeneralDemo.ALL)
    setColumnIsActive(active)
  }

  return (
    <LayoutContentWrapper wrapperClassName={'bg-white'}>
      {isLoading && <PageLoader />}
      <div className='flex h-full w-full flex-col overflow-hidden'>
        <FilmTrackingHeader
          results={totalRecords}
          selectedRows={selectedFilms}
          columnIsActive={columnIsActive}
        />
        <FilmTrackingTable
          topColumns={topColumnsFilms}
          columns={columnsFilms}
          totalRows={totalRecords}
          totalPages={totalPages}
          selectedRows={selectedFilms}
          toggleSelectOne={handleToggleSelectOne}
          toggleSelectAll={handleToggleSelectAll}
          selectAllChecked={selectAllChecked}
          rowsPerPage={pageSize}
          currentPage={pageNumber}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          requestSort={actions.setSorting}
          sortConfig={sorting}
          paginatedData={films}
          setColumnIsActive={handleSetColumnIsActive}
          columnIsActive={columnIsActive}
          visibleDemos={visibleDemos.active}
          isInterestAwareActive={isInterestAwareActive}
          onChangeInterestAware={actions.setInterestAware}
          getActiveFilterColumnName={getActiveFilterColumnName}
          allowExpandableView
        />
      </div>
    </LayoutContentWrapper>
  )
}
