import { createLogger } from 'redux-logger'
import { rtkQueryErrorLogger } from './error.middlewares'
import { api } from '../api/api'
import { mockApi } from '../api/mock-api'

export const logger = createLogger({
  collapsed: true,
})

export const middleware = [
  rtkQueryErrorLogger,
  api.middleware,
  mockApi.middleware,
  logger,
]
