import { Tooltip } from 'react-tooltip'
import { Colors } from 'constnants'
import { twMerge } from 'tailwind-merge'
import { Info } from 'components/ui/icons'
import { RankWithSuffix } from 'components'

interface TalentDetailAttributeItemProps {
  percent: string | number
  name: string
  isAttributes?: boolean
  hasInfo?: boolean
  isSelected: boolean
  contentTypeView?: string
  tooltipText?: string
  columnName: string
}

export const TalentDetailAttributeItem = ({
  percent,
  name,
  isAttributes,
  hasInfo = false,
  isSelected,
  contentTypeView,
  tooltipText,
  columnName,
}: TalentDetailAttributeItemProps) => {
  const renderPercent = () => {
    if (name === 'Sentiment (pos, neu, neg)' && typeof percent === 'string') {
      const values = percent.split(',')
      return values.map((value, index) => {
        const num = parseFloat(value.trim().replace('%', ''))

        const rounded = num.toFixed(0)
        const formatted = parseFloat(rounded) === 0 ? '0%' : `${rounded}%`

        return (
          <span
            key={index}
            className={twMerge(
              'text-xs font-medium',
              'flex-1 bg-primary-white py-[7px] text-center text-xs font-semibold text-primary-black',
              // getColorSentimentByIndex(index, isSelected),
              index === 0 && 'rounded-l',
              index === 1 && 'border-x-[1px]',
              index === 2 && 'rounded-r',
            )}
          >
            {formatted}
            {/* {index < values.length - 1 } */}
          </span>
        )
      })
    } else {
      return (
        <span
          className={twMerge(
            'text-xs font-semibold',
            isSelected && 'text-primary-white',
          )}
        >
          {percent?.toString().replace(/\s/g, '')}
        </span>
      )
    }
  }

  return (
    <div className='flex items-center gap-x-[22px]'>
      <div
        className={twMerge(
          'min-w-14 rounded text-center text-xs font-semibold text-primary-black',
          !isAttributes && 'w-[96px] px-0',
          name !== 'Sentiment (pos, neu, neg)'
            ? 'bg-primary-white p-1 py-[7px]'
            : 'inline-flex justify-between',
          isSelected && 'bg-primary-red text-primary-white duration-300',
        )}
      >
        {name === 'Sentiment (pos, neu, neg)' ? (
          renderPercent()
        ) : contentTypeView === 'rank' ? (
          <RankWithSuffix rank={percent} />
        ) : (
          renderPercent()
        )}
      </div>
      <span
        className={twMerge(
          'flex gap-1 text-xs text-primary-black',
          isSelected && 'font-semibold',
        )}
      >
        {name}
        {hasInfo && (
          <Info
            fill={Colors.BASE_ICON}
            className='hover:cursor-pointer hover:opacity-80'
            data-tooltip-id={`${columnName}_${name.toLowerCase()}`}
          />
        )}
      </span>
      {tooltipText && (
        <Tooltip
          id={`${columnName}_${name.toLowerCase()}`}
          place='bottom'
          render={() => (
            <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
              {tooltipText}
            </div>
          )}
          variant={'dark'}
          openOnClick={true}
          style={{ fontSize: '12px', fontWeight: 500, padding: '4px 8px' }}
        />
      )}
    </div>
  )
}
