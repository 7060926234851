import { CSSProperties } from 'react'

export const getStylesForSidebarContainer = (
  top: number,
  bottom: number,
): CSSProperties => {
  if (top || bottom) {
    return {
      top: `${top}px`,
      bottom: `${bottom}px`,
      height: `calc(100vh - ${top + bottom}px)`,
    }
  } else {
    return {}
  }
}
