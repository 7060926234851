import { useState, useCallback } from 'react'
import { OptionType } from '../types'

type useProjectRightSidebarType = {
  isOpened?: boolean
}

export const useProjectRightSidebar = ({
  isOpened = false,
}: useProjectRightSidebarType) => {
  const [projectRoleValue, setProjectRoleValue] = useState<string>('')
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(isOpened)

  const handleOpenRightSidebar = useCallback(() => setIsOpenSidebar(true), [])

  const handleCloseRightSidebar = useCallback(() => {
    setIsOpenSidebar(false)
  }, [])

  const handleSelectRoleValue = useCallback((option: OptionType) => {
    setProjectRoleValue(option.value ?? '')
  }, [])

  return {
    projectRoleValue,
    handleSelectRoleValue,
    isOpenSidebar,
    handleOpenRightSidebar,
    handleCloseRightSidebar,
  }
}
