import { Help } from 'components'
import { Colors } from 'constnants'
import { memo } from 'react'

export const TalentGuideTrigger = memo(() => {
  return (
    <>
      <button className='flex gap-1 text-xs font-semibold text-primary-grey'>
        <Help fill={Colors.BASE_ICON} width={16} height={16} />
        Talent Guide
      </button>
      {/* Add <BaseModal/> with proper content here */}
    </>
  )
})

TalentGuideTrigger.displayName = 'TalentGuideTrigger'
