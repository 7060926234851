import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { EyeSlash } from 'components/ui/icons'
import { ValidationIndicator } from 'components'
import { Colors } from 'constnants'

interface ResetPasswordFormData {
  password: string
  confirmPassword: string
}

const validatePassword = (password: string) => ({
  hasLowercase: /[a-z]/.test(password),
  hasUppercase: /[A-Z]/.test(password),
  hasNumber: /\d/.test(password),
  hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  hasMinLength: password.length >= 8,
})

export const ResetPassword = ({
  onSubmit,
}: {
  onSubmit: (data: ResetPasswordFormData) => void
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordFormData>()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const navigate = useNavigate()

  const password = watch('password', '')
  const validation = validatePassword(password)

  const toggleShowPassword = () => setShowPassword(!showPassword)
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword)

  const onSubmitForm = (data: ResetPasswordFormData) => {
    if (data.password === data.confirmPassword) {
      onSubmit(data)
      navigate('/login')
    }
  }

  return (
    <div className='flex h-[96vh] items-center justify-center'>
      <div className='flex flex-col items-center justify-center'>
        <div className='w-[400px] max-w-[90%] rounded-lg bg-white p-5'>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <h2 className='text-center text-2xl font-semibold'>
              Reset Password
            </h2>
            <div className='mt-4 flex flex-col'>
              <div className='mb-4'>
                <label htmlFor='password' className='mb-1 block font-semibold'>
                  Password
                </label>
                <div className='relative w-full'>
                  <input
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Minimum 8 characters'
                    className={`w-full rounded-md border p-1 ${errors.password ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                    {...register('password', {
                      required: 'Password is required',
                    })}
                  />
                  <button
                    type='button'
                    className='absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer border-none bg-none focus:outline-none'
                    onClick={toggleShowPassword}
                  >
                    <EyeSlash />
                  </button>
                </div>
                {errors.password && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.password.message}
                  </p>
                )}
                <ValidationIndicator
                  isValid={validation.hasLowercase}
                  message='One lowercase character'
                />
                <ValidationIndicator
                  isValid={validation.hasUppercase}
                  message='One uppercase character'
                />
                <ValidationIndicator
                  isValid={validation.hasNumber}
                  message='One number'
                />
                <ValidationIndicator
                  isValid={validation.hasSpecialChar}
                  message='One special character'
                />
                <ValidationIndicator
                  isValid={validation.hasMinLength}
                  message='8 characters minimum'
                />
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='confirmPassword'
                  className='mb-1 block font-semibold'
                >
                  Confirm Password
                </label>
                <div className='relative w-full'>
                  <input
                    id='confirmPassword'
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder='Confirm password'
                    className={`w-full rounded-md border p-1 ${errors.confirmPassword ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                    {...register('confirmPassword', {
                      required: 'Confirm Password is required',
                      validate: value =>
                        value === password || 'Passwords do not match',
                    })}
                  />
                  <button
                    type='button'
                    className='absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer border-none bg-none focus:outline-none'
                    onClick={toggleShowConfirmPassword}
                  >
                    <EyeSlash />
                  </button>
                </div>
                {errors.confirmPassword && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
              <button
                type='submit'
                className='mt-4 w-full rounded-full bg-red-500 px-4 py-2 text-white'
              >
                Reset Password
              </button>
              <a
                href='/login'
                className='mt-4 text-center text-sm text-red-500'
              >
                Remember your Password? Log in
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
