import { useState } from 'react'
import {
  AssignedUserTableBody,
  Button,
  DeleteModal,
  ProjectsTableHeader,
} from 'components'
import { Plus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { ColumnsProjectTable, MyProjectAssignedUser } from 'types'
import { useActions } from 'hooks'

interface MyProjectAssignedUserListProps {
  onOpen: () => void
  columns: ColumnsProjectTable[]
  data: MyProjectAssignedUser[]
}

export const MyProjectAssignedUserList = ({
  onOpen,
  data,
  columns,
}: MyProjectAssignedUserListProps) => {
  const { deleteAssignedUser } = useActions()
  const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false)
  const [selectedUser, setSelectedUser] =
    useState<MyProjectAssignedUser | null>(null)

  const handleDeleteUser = () => {
    if (selectedUser?.fullName) {
      deleteAssignedUser(selectedUser.id)
    }
    handleCloseDeleteUser()
  }

  const handleOpenDeleteUser = (user: MyProjectAssignedUser) => {
    setSelectedUser(user)
    setIsOpenDeleteUser(true)
  }

  const handleCloseDeleteUser = () => setIsOpenDeleteUser(false)

  return (
    <div className='h-full w-full bg-grey-2 p-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-1 pb-3 pt-2'>
          <h3 className='text-2xl font-semibold text-primary-black'>
            Assigned Users
          </h3>
        </div>

        <Button
          kind='filled'
          size='medium'
          className='w-fit bg-primary-red hover:bg-red-9 active:bg-red-10'
          onClick={onOpen}
        >
          <div className='flex items-center'>
            <span className='pr-1 text-sm font-semibold'>New User</span>
            <Plus fill={Colors.ICON_WHITE} width={16} height={16} />
          </div>
        </Button>
      </div>

      <div className='w-full xl:w-2/4'>
        <table className='project-table'>
          <ProjectsTableHeader columns={columns} />
          <AssignedUserTableBody
            data={data}
            columns={columns}
            onDeleteUser={handleOpenDeleteUser}
          />
        </table>

        <DeleteModal
          title={
            <span>
              Are you sure you want to unassign <br />
              {selectedUser?.fullName}
            </span>
          }
          description='The user will be removed and no longer have access to this project.'
          isOpen={isOpenDeleteUser}
          handleClose={handleCloseDeleteUser}
          onDelete={handleDeleteUser}
          deleteTitle='Remove user'
          hasTrash={false}
          titleClassName='mr-auto'
        />
      </div>
    </div>
  )
}
