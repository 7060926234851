import { useMemo } from 'react'
import dayjs from 'dayjs'

import { ReleaseCalendarData } from 'types'
import { BudgetRow, MonthAhead, WeekList, RowTemplate } from './components'
import { useTypedSelector } from 'hooks'
import {
  filterEventsByDateRange,
  getDynamicAbbreviation,
  groupWeekItems,
  shouldIncludeReleaseData,
  sortedGroupedKeys,
  isOther,
} from './utils'
import { addAheadMonths, convertToDate } from 'utils'

export const ReleaseCalendarTable = () => {
  const {
    switchFilmSelect,
    switchTitle,
    selectedDateRange,
    revenue,
    releaseWeeks,
    films,
    events,
    startDate: titleStartDate,
  } = useTypedSelector(state => state.releaseCalendarReducer)
  const filteredData = useMemo(() => {
    let fullData = []
    if (!films && !events && !releaseWeeks) return fullData
    if (films) {
      fullData = [...films]
    }
    if (events) {
      fullData = [...fullData, ...events]
    }

    console.log('fullData', fullData)
    if (!switchFilmSelect && !switchTitle) {
      return fullData
    }
    return fullData?.filter(releaseData =>
      shouldIncludeReleaseData(releaseData, switchTitle, switchFilmSelect),
    )
  }, [switchFilmSelect, switchTitle, films, events])
  console.log('filteredData', filteredData)
  const targetDate = new Date(releaseWeeks?.[0]?.startDate)
  const currentDate = titleStartDate
    ? convertToDate(titleStartDate)
    : targetDate
      ? targetDate
      : new Date()
  const addMonths = Number(selectedDateRange.value) * 30

  const startDate = dayjs(currentDate).startOf('day').toDate()
  const endDate = dayjs(currentDate)
    .add(addMonths, 'day')
    .endOf('month')
    .toDate()

  const monthsIncluded = []
  let current = dayjs(startDate)

  while (current.isBefore(endDate) || current.isSame(endDate, 'month')) {
    monthsIncluded.push(current.format('MMMM'))
    current = current.add(1, 'month').startOf('month')
  }

  const selectedMonthAhead = monthsIncluded
  const groupedData = useMemo(() => {
    const filteredGroupedData = filteredData.map(item => ({
      ...item,
      events: filterEventsByDateRange(
        item.events || [],
        startDate.toISOString(),
        endDate.toISOString(),
      ),
    }))
    console.log('filteredGroupedData', filteredGroupedData)
    const groupedByValue = filteredGroupedData.reduce(
      (acc, item) => {
        if (item.value) {
          if (!acc[item.value]) {
            acc[item.value] = []
          }
        }
        item.value && acc[item.value].push(item)
        return acc
      },
      {} as Record<string, ReleaseCalendarData[]>,
    )
    console.log('groupedByValue', groupedByValue)

    const sortedGroupedData = sortedGroupedKeys
      .reduce((acc, key) => {
        if (groupedByValue[key]) {
          acc.push(...groupedByValue[key])
        }
        return acc
      }, [] as ReleaseCalendarData[])
      .filter(item => item.events?.length)
    console.log('sortedGroupedData', sortedGroupedData)
    const eventsData = sortedGroupedData.map(item => {
      const groupedWeeks = groupWeekItems(item.events || [], releaseWeeks)
      return { ...item, groupedWeeks }
    })
    console.log('eventsData', eventsData)
    return eventsData
  }, [filteredData, releaseWeeks, currentDate, addMonths, films])
  console.log('groupedData', groupedData)
  const showHeatMap = switchTitle.heatMap
  if (!releaseWeeks) return null
  return (
    <div className='flex h-[calc(100vh-192px)] w-full flex-col overflow-x-auto border-t-primary-black/10 pb-0.5'>
      <div className='flex w-fit border-r border-t'>
        <div className='flex w-fit'>
          <div className='h-full w-2 bg-grey-4 ' />
          <div className='w-12 border-r bg-grey-2' />
        </div>
        <div className='flex flex-col'>
          <MonthAhead months={selectedMonthAhead} weeks={releaseWeeks} />
          <WeekList weeks={releaseWeeks} />
        </div>
      </div>

      {showHeatMap && (
        <>
          <BudgetRow
            data={revenue ? revenue.slice(0, 1) : []}
            wrapperBudgetClassName='[&:not(:first-child)]:mt-2'
          />
          <BudgetRow
            data={revenue?.slice(1, 2) ?? []}
            wrapperBudgetClassName='[&:not(:first-child)]:mb-2 [&:not(:first-child)]:mt-0.5'
          />
        </>
      )}

      <div className='flex w-fit flex-1 flex-col border-t'>
        {groupedData.map((item, rowIndex) => {
          const maxOverlap = Math.max(
            ...releaseWeeks.map(week => {
              const weekEvents = item.groupedWeeks[week.value] || []
              return weekEvents.length > 1 ? weekEvents.length : 1
            }),
          )
          const colorClass = item.colorClass
          const updatedLabel = getDynamicAbbreviation(item.label)
          const isSportOrHoliday = item.hasLine
          const isOtherItem = isOther(item.value)
          return (
            <RowTemplate
              key={rowIndex}
              item={item}
              rowIndex={rowIndex}
              colorClass={colorClass}
              updatedLabel={item.abbreviation || updatedLabel}
              maxOverlap={maxOverlap}
              isSportOrHoliday={isSportOrHoliday}
              isOtherItem={isOtherItem}
              releaseWeeks={releaseWeeks as any}
              isLastChild={rowIndex === groupedData.length - 1}
            />
          )
        })}
      </div>
    </div>
  )
}
