import { TalentMetrics } from 'types'
import { api } from './api'

export interface SocialMediaData {
  created_at: Date
  negative_percentage: number
  neutral_percentage: number
  positive_percentage: number
  reach_total_count: string
  reach_velocity: number
  reach_velocity_percentage: number
  subscriber_total_count: string
  subscriber_velocity: number
  subscriber_velocity_percentage: number
  talent_id: string
  talent_key: string
  talent_name: string
  total_mentions: string
  total_negative_mentions: string
  total_neutral_mentions: string
  total_positive_mentions: string
  wikipedia_total_count: string
  wikipedia_velocity: number
  wikipedia_velocity_percentage: number
}

export interface TalentMetricsResponse {
  talentId: string
  talentName: string
  talentMetrics: {
    [key: string]: TalentMetrics[]
  }
  socialMediaData: SocialMediaData
  statusCode?: number
  srNumber?: number
}

export const talentMetricsApi = api.injectEndpoints({
  endpoints: builder => ({
    getTalentMetricsById: builder.query<
      TalentMetricsResponse,
      { talentId: string }
    >({
      query: ({ talentId }) => ({
        url: `/talentMetrics/talent/${talentId}/metrics`,
        method: 'GET',
      }),
      providesTags: ['TalentMetrics'],
    }),
  }),
})

export const { useGetTalentMetricsByIdQuery } = talentMetricsApi
