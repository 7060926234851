import { twMerge } from 'tailwind-merge'
import { TestingTableHeaderProps } from '../TestingTable.types'
import { ChevronDown, ChevronUp } from 'components/ui/icons'
import { Colors } from 'constnants'

export const TestingTableHeader = <T,>({
  columns,
  onSort,
  onSortOrder,
  sortingBY,
}: TestingTableHeaderProps<T>) => {
  const onSortHandler = (value: string) => {
    if (onSort) {
      if (sortingBY === value) {
        onSort(prevSort => (prevSort === 'asc' ? 'desc' : 'asc'))
      } else {
        onSort('asc')
      }
    }
    if (onSortOrder) {
      onSortOrder(value)
    }
  }

  return (
    <thead>
      <tr>
        {columns.map(column => (
          <th
            key={column.value}
            className={twMerge(
              'p-3 text-xs font-normal text-primary-grey hover:cursor-pointer',
            )}
          >
            <div>
              {column.title && (
                <div
                  className='flex items-center gap-2'
                  onClick={() => onSortHandler(column.value)}
                >
                  {column.title}
                  <div>
                    <ChevronUp
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                      className='-mb-1'
                    />
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                    />
                  </div>
                </div>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}
