import { OptionType } from '../types'

export const generalOptions: OptionType[] = [
  { id: '1', label: 'All', value: 'ALL' },
  { id: 'gender_Male', value: 'GENDER_MALE', label: 'Men' },
  { id: 'gender_Female', value: 'GENDER_FEMALE', label: 'Women' },
  { id: 'agesegment_<35', value: 'AGE_SEGMENT_UNDER_35', label: '< 35' },
  { id: 'agesegment_35+', value: 'AGE_SEGMENT_35_PLUS', label: '35 +' },
  {
    id: 'agegendersegment_M<',
    value: 'AGE_GENDER_SEGMENT_M_LESS',
    label: 'Men < 35',
  },
  {
    id: 'agegendersegment_M+',
    value: 'AGE_GENDER_SEGMENT_M_PLUS',
    label: 'Men 35 +',
  },
  {
    id: 'agegendersegment_W<',
    value: 'AGE_GENDER_SEGMENT_W_LESS',
    label: 'Women < 35',
  },
  {
    id: 'agegendersegment_W+',
    value: 'AGE_GENDER_SEGMENT_W_PLUS',
    label: 'Women 35 +',
  },
  { id: 'AGE_GROUP_16_24', value: 'AGE_GROUP_16_24', label: '16-24' },
  { id: 'AGE_GROUP_25_34', value: 'AGE_GROUP_25_34', label: '25-34' },
  { id: 'AGE_GROUP_35_44', value: 'AGE_GROUP_35_44', label: '35-44' },
  { id: 'AGE_GROUP_45_54', value: 'AGE_GROUP_45_54', label: '45-54' },
  { id: 'AGE_GROUP_55_PLUS', value: 'AGE_GROUP_55_PLUS', label: '55 +' },
]

export const commonOptions: OptionType[] = [
  { id: '1', value: 'men', label: 'Men' },
  { id: '2', value: 'women', label: 'Women' },
  { id: '3', value: '<35', label: '< 35' },
  { id: '4', value: '35+', label: '35 +' },
]

export const raceOptions: OptionType[] = [
  { id: '3', value: 'RACE_BLACK', label: 'African American' },
  { id: '4', value: 'RACE_HISPANIC', label: 'Hispanic' },
  { id: '7', value: 'RACE_WHITE', label: 'White' },
]

export const blackOptions: OptionType[] = [
  { id: 'race_black', value: 'RACE_BLACK', label: 'All' },
  {
    id: 'race_black_gender_male',
    value: 'RACE_BLACK_GENDER_MALE',
    label: 'Men',
  },
  {
    id: 'race_black_gender_female',
    value: 'RACE_BLACK_GENDER_FEMALE',
    label: 'Women',
  },
  {
    id: 'race_black_age_segment_under_35',
    value: 'RACE_BLACK_AGE_SEGMENT_UNDER_35',
    label: '< 35',
  },
  {
    id: 'race_black_age_segment_35_plus',
    value: 'RACE_BLACK_AGE_SEGMENT_35_PLUS',
    label: '35 +',
  },
]

export const hispanicOptions: OptionType[] = [
  { id: 'RACE_HISPANIC', value: 'RACE_HISPANIC', label: 'All' },
  {
    id: 'RACE_HISPANIC_GENDER_MALE',
    value: 'RACE_HISPANIC_GENDER_MALE',
    label: 'Men',
  },
  {
    id: 'RACE_HISPANIC_GENDER_FEMALE',
    value: 'RACE_HISPANIC_GENDER_FEMALE',
    label: 'Women',
  },
  {
    id: 'RACE_HISPANIC_AGE_SEGMENT_UNDER_35',
    value: 'RACE_HISPANIC_AGE_SEGMENT_UNDER_35',
    label: '< 35',
  },
  {
    id: 'RACE_HISPANIC_AGE_SEGMENT_35_PLUS',
    value: 'RACE_HISPANIC_AGE_SEGMENT_35_PLUS',
    label: '35 +',
  },
]

export const whiteOptions: OptionType[] = [
  { id: 'RACE_WHITE', value: 'RACE_WHITE', label: 'All' },
  {
    id: 'RACE_WHITE_GENDER_MALE',
    value: 'RACE_WHITE_GENDER_MALE',
    label: 'Men',
  },
  {
    id: 'RACE_WHITE_GENDER_FEMALE',
    value: 'RACE_WHITE_GENDER_FEMALE',
    label: 'Women',
  },
  {
    id: 'RACE_WHITE_AGE_SEGMENT_UNDER_35',
    value: 'RACE_WHITE_AGE_SEGMENT_UNDER_35',
    label: '< 35',
  },
  {
    id: 'RACE_WHITE_AGE_SEGMENT_35_PLUS',
    value: 'RACE_WHITE_AGE_SEGMENT_35_PLUS',
    label: '35 +',
  },
]

export const ageSegmentOptions: OptionType[] = [
  { id: 'agesegment_35+', value: 'AGE_SEGMENT_35_PLUS', label: '35 +' },
  { id: 'agesegment_<35', value: 'AGE_SEGMENT_UNDER_35', label: '< 35' },
  {
    id: 'agegendersegment_M+',
    value: 'AGE_GENDER_SEGMENT_M_PLUS',
    label: 'M+',
  },
  {
    id: 'agegendersegment_M<',
    value: 'AGE_GENDER_SEGMENT_M_LESS',
    label: 'M<',
  },
]

export const identityOptions: OptionType[] = [
  { id: 'identity_LGBTQI', value: 'IDENTITY_LGBT_QI', label: 'All' },
]

export const theaterfreqOptions: OptionType[] = [
  { id: 'theaterfreq_FREQ', value: 'THEATER_FREQ_ALL', label: 'All' },
  { id: 'theaterfreq_FREQ', value: 'THEATER_FREQ_FREQ', label: 'Frequent' },
  { id: 'theaterfreq_CASUA', value: 'THEATER_FREQ_CASUAL', label: 'Casual' },
  { id: 'theaterfreq_HOME/NON', value: 'THEATER_FREQ_HOME_NON', label: 'Home' },
]

// export const blackOptions: OptionType[] = commonOptions

export const hispanicLatinoOptions: OptionType[] = commonOptions

// export const whiteOptions: OptionType[] = commonOptions

export const lgbtqiOptions: OptionType[] = []

export const theatergoersOptions: OptionType[] = [
  { id: 'frequent', value: 'frequent', label: 'Frequent' },
  { id: 'casual', value: 'casual', label: 'Casual' },
  { id: 'home', value: 'home', label: 'Home' },
]

export const dataAudience = [
  { id: 'general', name: 'General', options: generalOptions },
  { id: 'black', name: 'Black', options: blackOptions },
  { id: 'hispanic', name: 'Hispanic/Latino', options: hispanicOptions },
  { id: 'white', name: 'White', options: whiteOptions },
  { id: 'identity', name: 'LGBTQI+', options: identityOptions },
  { id: 'theaterfreq', name: 'Theater Frequency', options: theaterfreqOptions },
]

export const emptyHandleStructure = {
  general: [],
  black: [],
  hispanic: [],
  white: [],
  ageSegment: [],
  identity: [],
  theaterfreq: [],
}
