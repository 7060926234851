import {
  CompFinder,
  Close,
  Plus,
  MoreVertical,
  TooltipIcon,
} from 'components/ui/icons'
import { Colors } from 'constnants'
import { twMerge } from 'tailwind-merge'
import { FilterMenu } from '../FilterMenu'
import { useClickOutsideComponent } from '../../hooks'
import { moviesFiltersType, movieType, OptionType } from '../../types'
import { NavigationLiItem, ProjectsListDropdown, CompFilters } from './parts'

interface CompFinderHeaderProps {
  activeTabHeader: string
  setActiveTabHeader: (value: string) => void
  setProjectsNav: (
    value: { name: string; id: string; films?: movieType[] }[],
  ) => void
  projectsNav: {
    name: string
    id: string
    films?: movieType[]
  }[]
  isSidebarOpened: boolean
  showCallout: boolean
  setShowCallout: (value: boolean) => void
  filtersOpened: boolean
  setFiltersOpened: (value: boolean) => void
  activeFilters: moviesFiltersType
  setActiveFilters: (value: moviesFiltersType) => void
  updatedFilters: moviesFiltersType
  setUpdatedFilters: (value: moviesFiltersType) => void
  initialActiveFilters: moviesFiltersType
  setSearchModalOpened: (value: boolean) => void

  handleAddProject: (project: OptionType<number>) => void
  searchToModify?: string
  isSavedFilterChanged?: boolean
  resetSavedSearch: () => void
}

export const CompFinderHeader = ({
  activeTabHeader,
  setActiveTabHeader,
  setProjectsNav,
  projectsNav,
  isSidebarOpened,
  showCallout,
  setShowCallout,
  filtersOpened,
  setFiltersOpened,
  activeFilters,
  setActiveFilters,
  updatedFilters,
  setUpdatedFilters,
  initialActiveFilters,
  setSearchModalOpened,
  handleAddProject,
  searchToModify,
  isSavedFilterChanged,
  resetSavedSearch,
}: CompFinderHeaderProps) => {
  const {
    ref: refExisting,
    isComponentVisible: isExistingComponentVisible,
    setIsComponentVisible: setIsExistingComponentVisible,
  } = useClickOutsideComponent(false)
  const {
    ref: refMoreProjects,
    isComponentVisible: isMoreProjectVisible,
    setIsComponentVisible: setIsMoreProjectVisible,
  } = useClickOutsideComponent(false)

  const handleOpenList = () => {
    setIsExistingComponentVisible(!isExistingComponentVisible)
  }
  const handleAddProjectInner = (project: OptionType<number>) => {
    handleAddProject(project)
    setIsExistingComponentVisible(false)
    setIsMoreProjectVisible(false)
  }

  const handelDeleteProject = (id: string) => {
    setProjectsNav(projectsNav.filter(project => project.id !== id))
    if (activeTabHeader === id) {
      setActiveTabHeader('compFinder')
    }
  }

  return (
    <>
      <div
        className={
          'flex justify-between border-b border-b-grey-5 bg-grey-3 px-1.5 pt-1.5'
        }
      >
        <ul
          className={twMerge(
            '-mb-[1px] flex items-center gap-1.5 transition-all duration-300',
            isSidebarOpened
              ? 'w-[calc(100vw_-_442px)]'
              : 'w-[calc(100vw_-_210px)]',
          )}
        >
          <li>
            <button
              className={twMerge(
                'flex items-center gap-2 border-x bg-primary-black/5 px-2.5 py-1.5 text-sm font-medium' +
                  ' text-primary-black' +
                  ' whitespace-nowrap rounded-t border-t border-x-grey-5 border-t-grey-5 text-lg',
                activeTabHeader === 'compFinder' && 'bg-grey-2 ',
              )}
              onClick={() => setActiveTabHeader('compFinder')}
            >
              <CompFinder fill={Colors.ICON_BRAND} width={20} height={20} />
              Comp Finder
            </button>
          </li>
          {projectsNav.slice(0, 6).map((project, index) => {
            return (
              <NavigationLiItem
                className={twMerge(
                  'flex-shrink-0 transition-all duration-300',
                  isSidebarOpened
                    ? 'w-[calc((100%_-_240px)/6)]'
                    : 'w-[calc((100%_-_235px)/6)]',
                )}
                setActiveTabHeader={setActiveTabHeader}
                handelDeleteProject={handelDeleteProject}
                project={project}
                buttonClassName={twMerge(
                  'border-x bg-primary-black/5 px-3 text-sm font-medium text-primary-black',
                  'rounded-t border-t border-x-grey-5 border-t-grey-5',
                  activeTabHeader === project.id && 'bg-grey-2 text-red-9',
                )}
                textClassName={'py-2.5'}
                key={index}
              />
            )
          })}
          <div className={'relative'} ref={refExisting}>
            <button
              className={twMerge(
                'rounded-md p-1 px-1.5 py-[5px] hover:bg-primary-black/10',
                isExistingComponentVisible && 'bg-primary-black/10',
              )}
              onClick={() => handleOpenList()}
            >
              {projectsNav.length < 6 ? (
                <Plus fill={Colors.ICON_BRAND} width={20} height={20} />
              ) : (
                <MoreVertical
                  fill={Colors.NAV_ICON_THREE}
                  width={20}
                  height={20}
                />
              )}
            </button>

            {isExistingComponentVisible &&
              (projectsNav.length > 5 ? (
                <FilterMenu
                  trianglePosition={'right'}
                  wrapperClassName='p-2 text-left'
                  containerClassName={'-right-[20px] w-[218px]'}
                >
                  <div ref={refMoreProjects} className={'z-10'}>
                    {projectsNav.length > 6 && (
                      <>
                        <ul className={'max-h-80 overflow-auto'}>
                          {projectsNav.slice(6).map((project, index) => {
                            return (
                              <NavigationLiItem
                                setActiveTabHeader={setActiveTabHeader}
                                handelDeleteProject={handelDeleteProject}
                                project={project}
                                key={index}
                                buttonClassName={twMerge(
                                  'rounded px-1 py-1',
                                  'hover:bg-primary-black/5',
                                  activeTabHeader === project.id &&
                                    'bg-grey-2 text-red-9',
                                )}
                              />
                            )
                          })}
                        </ul>
                        <div
                          className={
                            'mx-2 mb-[11px] mt-4 border-b border-b-primary-black/10'
                          }
                        ></div>
                      </>
                    )}
                    <button
                      className={twMerge(
                        'flex w-full gap-2 rounded-md p-2 text-left text-sm hover:bg-primary-black/5',
                        isMoreProjectVisible && 'bg-primary-black/5',
                      )}
                      onClick={() =>
                        setIsMoreProjectVisible(!isMoreProjectVisible)
                      }
                    >
                      <Plus fill={Colors.ICON_BRAND} width={20} height={20} />
                      <span className={'text-primary-black'}>
                        Open Project Comp List
                      </span>
                    </button>
                    {isMoreProjectVisible && (
                      <FilterMenu
                        trianglePosition={'right-center'}
                        wrapperClassName='p-0 text-left relative z-10'
                        containerClassName={
                          'right-[calc(100%+17px)] top-full w-[312px] -translate-y-[calc(50%+25px)]'
                        }
                      >
                        <ProjectsListDropdown
                          listSize='small'
                          projects={projectsNav}
                          handleAddProject={handleAddProjectInner}
                        />
                      </FilterMenu>
                    )}
                  </div>
                </FilterMenu>
              ) : (
                <FilterMenu
                  trianglePosition={'left'}
                  wrapperClassName='p-0 text-left'
                  containerClassName={'-left-4 w-[312px]'}
                >
                  <ProjectsListDropdown
                    projects={projectsNav}
                    handleAddProject={handleAddProjectInner}
                  />
                </FilterMenu>
              ))}
          </div>
        </ul>
        {/* TODO: should be returned in appropriate moment */}
        {/* <div className={'flex items-center pr-1.5'}>
          <Share
            fill={Colors.NAV_ICON_THREE}
            width={16}
            height={16}
            className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'
          />
          <Export
            fill={Colors.NAV_ICON_THREE}
            width={16}
            height={16}
            className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'
          />
        </div> */}
      </div>
      {showCallout && activeTabHeader !== 'compFinder' && (
        <div
          className={
            'mx-3 mt-3 flex items-start gap-2 rounded bg-primary-black/5 px-5 py-4 text-sm text-primary-black' +
            ' relative'
          }
        >
          <TooltipIcon fill={Colors.BASE_ICON} width={20} height={20} />
          <div className={'flex-grow'}>
            <p className={'mb-0.5 font-semibold'}>
              We thought we would give you a head start on finding Project Comps
            </p>
            <p>
              This List is provided to you by Greenlights proprietary Comp
              finder generator. From the project details you provided, our
              system has produced comparable films to help produce your projects
              box office universe.
            </p>
          </div>
          <div
            className={'cursor-pointer opacity-50 hover:text-primary-red'}
            onClick={() => {
              setShowCallout(false)
              localStorage.setItem('hideCallout', 'true')
            }}
          >
            <Close fill={'currentColor'} width={16} height={16} />
          </div>
        </div>
      )}

      <CompFilters
        isOpened={filtersOpened}
        setIsOpened={setFiltersOpened}
        setActiveFilters={setActiveFilters}
        activeFilters={activeFilters}
        updatedFilters={updatedFilters}
        setUpdatedFilters={setUpdatedFilters}
        initialActiveFilters={initialActiveFilters}
        setSearchModalOpened={setSearchModalOpened}
        searchToModify={searchToModify}
        isSavedFilterChanged={isSavedFilterChanged}
        resetSavedSearch={resetSavedSearch}
      />
    </>
  )
}
