import React, { ReactNode, FC } from 'react'
import { twMerge } from 'tailwind-merge'

type ResultLookupContainerProps = {
  children: ReactNode
  autocompleteClassName?: string
}

const ResultLookupContainer = React.memo(
  ({ children, autocompleteClassName }: ResultLookupContainerProps) => {
    return (
      <div
        className={twMerge(
          'absolute top-8 z-50 max-h-40 w-full overflow-auto rounded border border-primary-black/20 bg-white p-2 shadow',
          autocompleteClassName,
        )}
      >
        {children}
      </div>
    )
  },
)

ResultLookupContainer.displayName = 'ResultLookupContainer'

export default ResultLookupContainer
