import { OptionType } from 'types'
import { createOptionsFromEnum } from 'utils'
import { TVFilmCompanyType, TVFilmDistributorType } from '../types'
import {
  tVFilmCompanyLabelByType,
  tVFilmDistributorLabelByType,
} from './tVFilmTypeLabelMapping'

export const tVFilmDistributorTypeOptions: OptionType<TVFilmDistributorType>[] =
  createOptionsFromEnum(TVFilmDistributorType, tVFilmDistributorLabelByType)

export const tVFilmCompanyTypeOptions: OptionType<TVFilmCompanyType>[] =
  createOptionsFromEnum(TVFilmCompanyType, tVFilmCompanyLabelByType)
