import {
  OptionFilter,
  OptionFilterDef,
  SocialSearchFilters,
  Theatergoers,
} from '../types'

export const percentagesOptions: OptionFilter[] = [
  { id: '1', value: 'count', key: 'COUNT', label: 'People' },
  { id: '2', value: 'rank', key: 'RANK', label: 'Percentile Rank' },
  { id: '3', value: 'percentage', key: 'PERCENTAGE', label: 'Percentages' },
]

export const topOptions: OptionFilter[] = [
  { id: '1', value: 'topBox', label: 'Top Box' },
  { id: '2', value: 'topTwoBox', label: 'Top Two Box' },
]

export const socialOptions: OptionFilter[] = [
  {
    id: '1',
    value: 'allFollowers',
    label: 'All followers',
    tooltipText: 'Instagram, X (followers)',
  },
  {
    id: '2',
    value: 'socialVelocity',
    label: 'Velocity (0.0)',
    tooltipText: 'Absolute change in followers',
  },
  {
    id: '3',
    value: 'socialVelocityPercentage',
    label: 'Velocity (%)',
    tooltipText: 'Percent change in followers',
  },
  { id: '4', value: 'mentions', label: 'Mentions' },
  {
    id: '5',
    value: 'sentiment',
    label: 'Sentiment (pos, neu, neg)',
    tooltipText:
      'Positive, Neutral, Negative \nshare of instagram conversation',
  },
  {
    id: '6',
    value: 'reach',
    label: 'Reach',
    tooltipText: 'Total number of people \nwho see a talent’s posts',
  },
]

export const searchOptions: OptionFilter[] = [
  {
    id: '1',
    value: 'wikipediaPageViews',
    label: 'Wikipedia Page Views',
    tooltipText: 'Last 24 hours',
  },
  {
    id: '2',
    value: 'velocity',
    label: 'Velocity (0.0)',
    tooltipText: 'Absolute change in page views',
  },
  {
    id: '3',
    value: 'velocityPercentage',
    label: 'Velocity (%)',
    tooltipText: 'Percent change in page views',
  },
]

export const dataSearch: OptionFilterDef<string, keyof SocialSearchFilters>[] =
  [
    { id: 'social', name: 'Social Media', options: socialOptions },
    { id: 'search', name: 'Search', options: searchOptions },
  ]

export const theatergoersOptions: OptionFilter<Theatergoers>[] = [
  {
    id: '1',
    value: Theatergoers.ALL_RESPONDENTS,
    label: 'All respondents',
    description: 'Frequents + Casuals + Home',
  },
  {
    id: '2',
    value: Theatergoers.THEATERGOERS_ONLY,
    label: 'Theatergoers only',
    description: 'Frequents + Casuals',
  },
]

export const theatergoersFilterDef: OptionFilterDef<Theatergoers> = {
  id: 'theatergoers',
  name: 'Theatergoers',
  options: theatergoersOptions,
}

export const initialOptions = {
  percentages: percentagesOptions,
  top: topOptions,
  theatergoers: theatergoersOptions,
}
