export const companiesColumns = [
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: '',
    value: 'usersLink',
  },
  {
    title: 'Status',
    value: 'status',
  },
  {
    title: 'Created date',
    value: 'created_at',
  },
  {
    title: '',
    value: 'actions',
  },
]

export const statusFilterOptions = [
  {
    id: '1',
    value: 'all',
    label: 'All',
  },
  {
    id: '2',
    value: 'active',
    label: 'Active',
  },
  {
    id: '3',
    value: 'inactive',
    label: 'Inactive',
  },
]
