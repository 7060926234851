import { twMerge } from 'tailwind-merge'
import { type FilmRow } from 'types'

export const Table = ({ data }: { data: FilmRow[] }) => (
  <div className='max-h-[270px] overflow-auto rounded-lg bg-primary-white p-1'>
    <TableHeader />
    {data.length > 0 &&
      data.map((row, index) => <TableRow key={index} row={row} />)}
  </div>
)

const TableHeader = () => (
  <div className='flex flex-row justify-between text-xss'>
    {[
      'Comps',
      'Date',
      'Dist.',
      'Rating',
      'OW',
      'OW - Adj.',
      'Theatres',
      'Total Gross',
    ].map((header, idx) => (
      <p
        key={idx}
        className={twMerge(
          'p-2  text-grey-7',
          header === 'OW' ? 'bg-green-1' : '',
          idx === 0 ? 'w-full' : 'w-[11.5%]',
          idx !== 0 ? 'shrink-0' : '',
        )}
      >
        {header}
      </p>
    ))}
  </div>
)

const TableRow = ({ row }: { row: FilmRow }) => (
  <div className='flex flex-row justify-between text-xss '>
    <p className='w-full content-center border-t p-2 text-xss font-medium text-primary-black'>
      {row.comps}
    </p>
    {[
      'releaseDate',
      'dist',
      'rating',
      'ow',
      'owAdjusted',
      'theatres',
      'totalGross',
    ].map(key => (
      <p
        key={key}
        className={twMerge(
          'w-[11.5%] shrink-0 border-t p-2 text-primary-black',
          key === 'ow' && 'border-[#0307121A] bg-green-1',
        )}
      >
        {row[key as keyof FilmRow]}
      </p>
    ))}
  </div>
)
