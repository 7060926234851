import { twMerge } from 'tailwind-merge'
import { UsersTableHeaderProps, UsersTableProps } from '../UsersTable.types'
import { ChevronDown, ChevronUp } from 'components/ui/icons'
import { Colors } from 'constnants'
import { Checkbox } from '../../ui'

export const UsersTableHeader = ({
  columns,
  onSort,
  onSortOrder,
  sortingBY,
  selectAllChecked,
  toggleSelectAll,
}: UsersTableHeaderProps) => {
  const onSortHandler = (value: keyof UsersTableProps) => {
    if (onSort) {
      if (sortingBY === value) {
        onSort(prevSort => (prevSort === 'asc' ? 'desc' : 'asc'))
      } else {
        onSort('asc')
      }
    }
    if (onSortOrder) {
      onSortOrder(value)
    }
  }

  return (
    <thead className='bg-grey-3'>
      <tr>
        <th className='p-3 pr-0'>
          <Checkbox
            checked={selectAllChecked}
            onChange={toggleSelectAll}
            kind='small'
          />
        </th>
        {columns.map(column => (
          <th
            key={column.value}
            className={twMerge(
              'p-3 pl-0 text-xs font-normal text-primary-grey hover:cursor-pointer',
            )}
          >
            <div>
              {column.title && (
                <div
                  className='flex items-center gap-2'
                  onClick={() =>
                    onSortHandler(column.value as keyof UsersTableProps)
                  }
                >
                  {column.title}
                  <div>
                    <ChevronUp
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                      className='-mb-1'
                    />
                    <ChevronDown
                      fill={Colors.BASE_ICON}
                      width={10}
                      height={8}
                    />
                  </div>
                </div>
              )}
            </div>
          </th>
        ))}
        <th className='p-3 pr-0'></th>
      </tr>
    </thead>
  )
}
