import { useCallback, useMemo } from 'react'
import { TestingTableBody, TestingTableHeader } from './components'
import { OptionType, ProjectType } from 'types'
import { TestingTableProps } from './TestingTable.types'
import { Colors } from '../../constnants'
import { Folder, Plus } from '../ui/icons'
import { Button } from '../ui'
import {
  PaginationChangeParams,
  PaginationToolbar,
} from 'components/FilmTrackingTable'

export const rowsPerPageOptions: OptionType<number>[] = [
  { id: '1', value: 25, label: '25' },
  { id: '2', value: 50, label: '50' },
  { id: '3', value: 100, label: '100' },
]

export const TestingTable = <T extends ProjectType>({
  handleAddTest,
  columns,
  data,
  emptyStateTitle,
  emptyStateDescription,
  onSort,
  onSortOrder,
  sortingBY,
  setCurrentPage,
  currentPage = 1,
  rowsPerPage = 25,
  setRowsPerPage,
  totalTestsCount = 0,
  isLoading,
}: TestingTableProps<T>) => {
  const totalRows = totalTestsCount
  const totalPages = useMemo(
    () => Math.ceil(totalRows / rowsPerPage),
    [totalRows, rowsPerPage],
  )

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage?.(params.page)
      setRowsPerPage?.(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  return (
    <div className='h-[calc(100vh_-_125px)] w-full overflow-auto p-3 pb-12 xl:h-[calc(100vh_-_114px)]'>
      {isLoading ? (
        <>
          <table className='project-table testing-table'>
            <TestingTableHeader
              columns={columns}
              onSort={onSort}
              onSortOrder={onSortOrder}
              sortingBY={sortingBY}
            />
            <TestingTableBody
              data={data}
              columns={columns}
              isLoading={isLoading}
            />
          </table>
        </>
      ) : totalRows === 0 ? (
        <>
          <table className='project-table testing-table'>
            <TestingTableHeader columns={columns} />
          </table>
          <div className='mx-auto flex h-full w-[505px] flex-col items-center justify-center '>
            <Folder fill={Colors.GRAY_600} width={48} height={48} />
            <div className='my-4 text-center text-primary-black'>
              <p className='text-lg font-medium'>
                {emptyStateTitle
                  ? emptyStateTitle
                  : 'You haven’t submitted a any Testing yet'}
              </p>
              <p className='text-sm'>
                {emptyStateDescription
                  ? emptyStateDescription
                  : 'Create your first testing to get started.'}
              </p>
            </div>
            <Button
              kind='filled'
              size='medium'
              className='bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10'
              onClick={handleAddTest}
            >
              <div className='flex items-center'>
                <span className='pr-1 text-sm font-semibold'>New Test</span>
                <Plus fill={Colors.ICON_WHITE} width={22} height={22} />
              </div>
            </Button>
          </div>
        </>
      ) : (
        <>
          <table className='project-table testing-table'>
            <TestingTableHeader
              columns={columns}
              onSort={onSort}
              onSortOrder={onSortOrder}
              sortingBY={sortingBY}
            />
            <TestingTableBody data={data} columns={columns} />
          </table>
          <div className='fixed bottom-0 left-12 right-2'>
            <PaginationToolbar
              totalRows={totalRows}
              totalPages={totalPages}
              currentPage={currentPage}
              currentPageSize={rowsPerPage}
              pageSizeOptions={rowsPerPageOptions}
              onChange={handlePagination}
            />
          </div>
        </>
      )}
    </div>
  )
}
