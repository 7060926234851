import React, { useEffect, useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { dateToUTCTimestamp, parseDateId } from 'utils'
import { ChartColorPalette } from 'constnants/colors'
import { FilmChartData } from 'store/api'
import { formatDateAxisValue, generateDateAxisTicks } from './utils'

function transformData(inputData: FilmChartData[]) {
  let utcTimestampMin = Number.MAX_SAFE_INTEGER
  let utcTimestampMax = Number.MIN_SAFE_INTEGER

  const series: Array<Highcharts.SeriesLineOptions> = inputData.map(
    (film, i) => ({
      type: 'line',
      name: `film name${film.film_id}`, // Placeholder for film name
      color: ChartColorPalette[i],
      data: [...film.data]
        .sort((a, b) => a.date_survey_id - b.date_survey_id)
        .map(survey => {
          const utcTimestamp = dateToUTCTimestamp(
            parseDateId(survey.date_survey_id),
          )
          utcTimestampMin = Math.min(utcTimestamp, utcTimestampMin)
          utcTimestampMax = Math.max(utcTimestamp, utcTimestampMax)
          return [utcTimestamp, survey.value]
        }),
    }),
  )

  const xAxisTicks = generateDateAxisTicks(
    new Date(utcTimestampMin),
    new Date(utcTimestampMax),
  )

  return {
    series,
    xAxisTicks,
  }
}

const getYAxisLabelFormatter = () => {
  return function (this: { value: number | string }) {
    return typeof this.value === 'number'
      ? `${Highcharts.numberFormat(this.value, 0)}%`
      : ''
  }
}

const getXAxisLabelFormatter = () => {
  return function (this: { value: number | string }) {
    return typeof this.value === 'number' ? formatDateAxisValue(this.value) : ''
  }
}

export const FilmMetricsChart = ({
  data,
  chartHeight = 450,
}: {
  data?: FilmChartData[]
  chartHeight?: number
}) => {
  useEffect(() => {
    const handleResize = () => {
      const chart = Highcharts.charts[0]
      if (chart) {
        chart.reflow()
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const { series, xAxisTicks } = useMemo(() => transformData(data!), [data])

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      reflow: true,
    },
    title: undefined,
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: getXAxisLabelFormatter(),
      },
      minPadding: 0.05, // Adds space before the first tick
      maxPadding: 0.05, // Adds space after the last tick
      tickPixelInterval: 500, // Adjust as needed
      tickLength: 0, // Remove tick marks if desired
      gridLineWidth: 1, // Width of vertical grid lines
      gridLineDashStyle: 'Dash', // Style of vertical grid lines
      gridLineColor: '#e0e0e0', // Color of vertical grid lines
      tickPositions: xAxisTicks, // Place ticks at each month
      endOnTick: true, // Prevent the last tick from being forced
      startOnTick: true, // Prevent the first tick from being forced
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter: getYAxisLabelFormatter(),
      },
      min: 0,
      max: 100,
      tickInterval: 10,
      opposite: true,
    },
    tooltip: {
      headerFormat: '<b>{point.y}</b><br>',
      pointFormat: undefined,
    },
    series: series,
    plotOptions: {
      series: {
        marker: {
          radius: 4, // Set the marker radius
          symbol: 'circle', // Set the marker shape
        },
        lineWidth: 2, // Set the line width
        states: {
          hover: {
            lineWidthPlus: 0, // Disable hover effect
          },
        },
      },
    },
    credits: {
      enabled: false, // Remove the Highcharts watermark
    },
    legend: {
      enabled: false, // Enable the legend
    },
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      className={`h-[${chartHeight}px] w-full`}
    />
  )
}
