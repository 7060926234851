import { useNavigate } from 'react-router-dom'
import { Button } from 'components'
import { ArrowLeft, Export, Share } from 'components/ui/icons'
import { Colors } from 'constnants'
import { twMerge } from 'tailwind-merge'

interface SubheaderProps {
  hasButtons?: boolean
  hasButtonsLabels?: boolean
  containerClassName?: string
  navigateTo?: string
}

export const Subheader = ({
  hasButtons,
  hasButtonsLabels,
  containerClassName,
  navigateTo,
}: SubheaderProps) => {
  const navigate = useNavigate()

  return (
    <div
      className={twMerge(
        'flex items-center justify-between border-b border-b-opacityGrey-10 bg-primary-white p-2',
        containerClassName,
      )}
    >
      <Button
        kind='text'
        size='small'
        className='flex'
        onClick={() => {
          navigateTo ? navigate(navigateTo) : navigate(-1)
        }}
      >
        <ArrowLeft fill={Colors.BASE_ICON} width={16} height={16} />
        <span className='px-1'>Return to full list</span>
      </Button>

      {hasButtons && (
        <div className='flex flex-row items-center gap-1'>
          <Share
            fill={Colors.NAV_ICON_THREE}
            width={16}
            height={16}
            className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'
          />
          {hasButtonsLabels && (
            <span className='text-xs font-semibold text-primary-black'>
              Share
            </span>
          )}
          <Export
            fill={Colors.NAV_ICON_THREE}
            width={16}
            height={16}
            className='h-fit w-fit rounded p-1 hover:cursor-pointer hover:bg-primary-black/5'
          />
          {hasButtonsLabels && (
            <span className='text-xs font-semibold text-primary-black'>
              Export
            </span>
          )}
        </div>
      )}
    </div>
  )
}
