export const getDynamicAbbreviation = (value: string): string => {
  if (value === 'Minor' || value === 'Other') {
    return value
  }

  const cleanValue = value.replace(/\(.*?\)/g, '')
  const parts = cleanValue.split(/(?=[A-Z])|[\W_]+/)

  const combinedParts = parts.join(' ')
  const numberMatch = combinedParts.match(/\d+/)
  if (numberMatch) {
    const numberPart = numberMatch[0]
    const remainingString = combinedParts
      .slice(numberMatch.index! + numberPart.length)
      .trim()
    const firstLetterAfterNumber = remainingString[0]?.toUpperCase() || ''
    return numberPart + firstLetterAfterNumber
  }

  const abbreviation = parts
    .filter(part => part)
    .map(part => part[0].toUpperCase())

  if (abbreviation.length === 1) {
    return abbreviation[0] + (parts[0][1] ? parts[0][1].toUpperCase() : '')
  } else {
    return abbreviation.join('')
  }
}
