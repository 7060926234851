import { DataFiltersType } from 'types'

export const findLabel = (
  dataFilters: DataFiltersType[],
  filterId: string,
  value: number | string,
) => {
  const filterOption = dataFilters.find(filter => filter.id === filterId)
  if (!filterOption) return ''

  const option = filterOption.options.find(opt => {
    if ('value' in opt) {
      return opt.value === value.toString()
    }
    return false
  })

  return option && 'label' in option ? option.label : ''
}

export const toLocalNumber = (value: string | number) => {
  return Number(value).toLocaleString('en-US')
}
