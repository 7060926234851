import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  AudienceDemo,
  AudienceGeneralDemo,
  DirectionSort,
  FilmMetricCategory,
  FilmMetricsSortBy,
} from 'types'

interface PageSortingState {
  direction: DirectionSort
  column: FilmMetricsSortBy
}

interface PageState {
  selectedFilms: string[]
  metricCategory: FilmMetricCategory
  audienceFilter: AudienceDemo
  sorting: PageSortingState
  isInterestAwareActive: boolean
}

const sortingInitialState: PageSortingState = {
  direction: DirectionSort.ASC,
  column: 'latest_date_survey_id',
}

const metricCategoryInitialState = FilmMetricCategory.Awareness

const initialState: PageState = {
  selectedFilms: [],
  metricCategory: metricCategoryInitialState,
  audienceFilter: AudienceGeneralDemo.ALL,
  sorting: sortingInitialState,
  isInterestAwareActive: false,
}

export const filmTrackingComparePageSlice = createSlice({
  name: 'filmTrackingComparePage',
  initialState,
  reducers: {
    setSelectedFilms: (
      state,
      action: PayloadAction<PageState['selectedFilms']>,
    ) => {
      state.selectedFilms = action.payload
    },
    setSorting: (state, action: PayloadAction<PageSortingState>) => {
      state.sorting = action.payload
    },
    resetSorting: state => {
      state.sorting = sortingInitialState
    },
    setMetricCategory: (
      state,
      action: PayloadAction<PageState['metricCategory']>,
    ) => {
      state.metricCategory = action.payload
    },
    resetMetricCategory: state => {
      state.metricCategory = metricCategoryInitialState
    },
    setAudienceFilter: (state, action: PayloadAction<AudienceDemo>) => {
      state.audienceFilter = action.payload
    },
    setInterestAware: (
      state,
      action: PayloadAction<PageState['isInterestAwareActive']>,
    ) => {
      state.isInterestAwareActive = action.payload
    },
  },
  selectors: {
    root: state => state,
  },
})

export const {
  actions: filmTrackingComparePageActions,
  selectors: filmTrackingComparePageSelectors,
} = filmTrackingComparePageSlice

export const useFilmTrackingCopmarePageActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(filmTrackingComparePageActions, dispatch),
    [dispatch],
  )
}
