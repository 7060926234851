export enum ReleaseCalendarSelectKey {
  MAJOR = 'major',
  MINOR = 'minor',
  SPORTING_EVENTS = 'sportingEvents',
  HOLIDAY_AND_SCHOOL = 'holidayAndSchool',
  PREMIERES_EVENTS = 'premieresEvents',
  HEAT_MAP = 'heatMap',
  THEATRICAL = 'theatrical',
  OTHER = 'other',
  STREAMERS = 'streamer',
  CABLE_BROADCAST = 'broadcast',
}
