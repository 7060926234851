import { BaseModal, Help } from 'components'
import { Colors } from 'constnants'
import { memo, useState } from 'react'

export const FilmTrackingGuideTrigger = memo(() => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <>
      <button
        className='flex gap-1 text-xs font-semibold text-primary-grey'
        onClick={handleOpen}
      >
        <Help fill={Colors.BASE_ICON} width={16} height={16} />
        Film Tracking Guide
      </button>

      <BaseModal
        isOpen={isOpen}
        handleClose={handleClose}
        title='Film Tracking Guide'
        titleClass='text-4xl font-extrabold text-gray-900'
        maxWidth='562px'
        wrapperClass='p-16pt-12 pr-14 pb-10 pl-12'
      >
        <div className='mx-auto'>
          <div className='mt-6'>
            <h2 className='mb-4 text-2xl font-bold text-gray-800'>Groups</h2>

            <ul className='list-outside list-disc pl-5 text-sm'>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Animation/Family
                </span>
                – Animated or live-action films with a G or PG rating.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>Horror</span>–
                Horror films including all budgets and sequels.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>Low</span>– Films
                with a production budget below $50M.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>Medium</span>–
                Films with a production budget between $50M and $125M.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Tentpole Original
                </span>
                – Non-sequel films with a production budget above $125M.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Tentpole Sequel
                </span>
                – Sequels or films from a known franchise with a production
                budget above $125M.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  DC/MCU Origin
                </span>
                – Films from the DC or Marvel cinematic universes focused on a
                character that has not previously been featured in a stand-alone
                film, or has a production budget below $100M.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  DC/MCU Sequel
                </span>
                – Films from the DC or Marvel cinematic universes that are a
                sequel or an extension of previous films.
              </li>
            </ul>
          </div>

          <div className='mt-6'>
            <h2 className='mb-4 text-2xl font-bold text-gray-800'>
              Theatergoers
            </h2>
            <ul className='list-outside list-disc pl-5 text-sm'>
              <li>
                <span className='font-extrabold text-gray-900'>Frequent</span>–
                Respondents who see a movie in a theater at least once a month.
                This group represents 50% of the sample.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>Casual</span>–
                Respondents who see a movie in a theater a few times a year.
                This group represents 40% of the sample.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>Home</span>–
                Respondents who only watch movies at home. This group represents
                10% of the sample.
              </li>
            </ul>
          </div>
        </div>
      </BaseModal>
    </>
  )
})

FilmTrackingGuideTrigger.displayName = 'FilmTrackingGuideTrigger'
