const pathMapping: { [key: string]: string } = {
  talent: 'Talent',
  compare: 'Compare',
  myProjects: 'My Projects',
  compFinder: 'Comp Finder',
  releaseCalendar: 'Release Calendar',
  conceptTesting: 'Concept Testing',
  roleTesting: 'Role Testing',
  assetTesting: 'Asset Testing',
  settings: 'Settings',
  help: 'Help',
  film: 'Film Tracking',
  uiElements: 'UI Elements',
  companyManagement: 'Company Management',
  userManagement: 'User Management',
  adminManagement: 'Admin Management',
  testingModules: 'Testing Modules',
  tvFilmCompanies: 'TV & Film Companies',
  tvManagement: 'TV Management',
}

export const formatBreadcrumbNavigation = (path: string): string => {
  return pathMapping[path] || path
}
