interface XAxisLabelsProps {
  labels: number[]
}

export const XAxisLabels = ({ labels: colLabels }: XAxisLabelsProps) => {
  return (
    <div className='flex h-6 shrink-0'>
      <div className='h-full w-14 shrink-0' />
      {colLabels.map(colLabel => (
        <ColumnLabel key={colLabel} label={colLabel} />
      ))}
    </div>
  )
}

const ColumnLabel = ({ label }: { label: number }) => (
  <div className='flex h-full w-full items-center justify-center border-l text-primary-black'>
    <span className='text-xss'>{label}</span>
  </div>
)
