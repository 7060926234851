import { OptionFilterDef, OptionFilter } from '../types'

export const distributorOptions: OptionFilter[] = [
  { id: '1', value: '20th', label: '20th' },
  { id: '2', value: 'a24', label: 'A24' },
  { id: '3', value: 'angel_studios', label: 'Angel Studios' },
  { id: '4', value: 'apple', label: 'Apple' },
  { id: '5', value: 'bleecker_street', label: 'Bleecker Street' },
  { id: '6', value: 'crunchy_roll', label: 'Crunchy Roll' },
  { id: '7', value: 'disney', label: 'Disney' },
  { id: '8', value: 'focus_features', label: 'Focus Features' },
  { id: '9', value: 'lionsgate', label: 'Lionsgate' },
  { id: '10', value: 'mgm/amazon', label: 'MGM/Amazon' },
  { id: '11', value: 'neon', label: 'Neon' },
  { id: '12', value: 'netflix', label: 'Netflix' },
  { id: '13', value: 'paramount', label: 'Paramount' },
  { id: '14', value: 'roadside_attractions', label: 'Roadside Attractions' },
  { id: '15', value: 'sony', label: 'Sony' },
  { id: '16', value: 'searchlight', label: 'Searchlight' },
  { id: '17', value: 'universal', label: 'Universal' },
  { id: '18', value: 'warner_bros', label: 'Warner Bros.' },
]

export const dataDistributors = [
  { id: 'distributor', name: 'Distributor', options: distributorOptions },
]

export const majorsOptions: OptionFilter[] = [
  { id: '20th', value: '20th', label: '20th' },
  { id: 'a24', value: 'a24', label: 'A24' },
  { id: 'affirm_films', value: 'affirm_films', label: 'Affirm Films' },
  { id: 'amazon_studios', value: 'amazon_studios', label: 'Amazon Studios' },
  { id: 'disney', value: 'disney', label: 'Disney' },
  { id: 'lionsgate', value: 'lionsgate', label: 'Lionsgate' },
  { id: 'paramount', value: 'paramount', label: 'Paramount' },
  { id: 'sony', value: 'sony', label: 'Sony' },
  { id: 'universal', value: 'universal', label: 'Universal' },
  { id: 'warner_bros', value: 'warner_bros', label: 'Warner Bros.' },
]

export const minorOptions: OptionFilter[] = [
  { id: 'angel_studios', value: 'angel_studios', label: 'Angel Studios' },
  {
    id: 'annapurna_pictures',
    value: 'annapurna_pictures',
    label: 'Annapurna Pictures',
  },
  { id: 'apple_tv', value: 'apple_TV+', label: 'Apple TV+' },
  {
    id: 'bleecker_street',
    value: 'bleecker_street',
    label: 'CBleecker Street',
  },
  {
    id: 'briarcliff_entertainment',
    value: 'briarcliff_entertainment',
    label: 'Briarcliff Entertainment',
  },
  { id: 'crunchy_roll', value: 'crunchy_roll', label: 'Crunchy Roll' },
  {
    id: 'cloudburst_entertainment',
    value: 'cloudburst_entertainment',
    label: 'Cloudburst Entertainment',
  },
  { id: 'focus_features', value: 'focus_features', label: 'Focus Features' },
]

export const otherOptions: OptionFilter[] = [
  { id: 'amc', value: 'AMC', label: 'AMC' },
  { id: 'aviron_pictures', value: 'aviron_pictures', label: 'Aviron Pictures' },
  { id: 'bh_tilt', value: 'BH_tilt', label: 'BH Tilt' },
  {
    id: 'blue_fox_entertainment',
    value: 'blue_fox_entertainment',
    label: 'Blue Fox Entertainment',
  },
]
export const dataDistributorsCompFinder: OptionFilterDef[] = [
  { id: 'major', name: 'Majors', options: [] },
  { id: 'minor', name: 'Minors', options: [] },
  { id: 'other', name: 'Other', options: [] },
]

export const distributorsFilterDef: OptionFilterDef = {
  id: 'distributor',
  name: 'Distributor',
  options: [],
}

export const groupFilterDef: OptionFilterDef = {
  id: 'group',
  name: 'Group',
  options: [],
}
