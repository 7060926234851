import { twMerge } from 'tailwind-merge'
import { Close, CloseArrow } from 'components/ui/icons'
import { Colors } from 'constnants'
import { CompareDetails, TalentColors } from 'types'

interface CompareTalentListMenuProps {
  talents: Omit<CompareDetails[], 'periods'>
  targetTalentId?: string
  talentColors: TalentColors
  wrapperClassName?: string
  isShowDeleteTaletnBtn: boolean
  onClose: () => void
  handleDelete: (id: string) => void
}

export const CompareTalentListMenu = ({
  talents,
  talentColors,
  targetTalentId,
  wrapperClassName = 'absolute bottom-10 left-10',
  onClose,
  handleDelete,
  isShowDeleteTaletnBtn = true,
}: CompareTalentListMenuProps) => {
  return (
    <div
      className={twMerge(
        'flex h-fit w-[204px] gap-4 rounded border bg-grey-2 p-2 shadow-md',
        wrapperClassName,
      )}
    >
      <div className='mt-auto'>
        <CloseArrow
          fill={Colors.BASE_ICON}
          width={16}
          height={16}
          className='hover:cursor-pointer hover:opacity-80'
          onClick={onClose}
        />
      </div>
      <div className='flex flex-col space-y-2'>
        {talents.map(talent => {
          const talendId = talent.talentId.toString()
          return (
            <div key={talent.talentId}>
              <div className='flex w-[155px] items-center justify-between'>
                <div className='flex items-center gap-1'>
                  <div
                    className='h-[13px] w-[13px] rounded-full'
                    style={{
                      backgroundColor: talentColors[talent.talentId],
                    }}
                  />
                  <span className='text-xs text-primary-black'>
                    {talent.fullName}
                  </span>
                </div>
                {talendId !== targetTalentId && isShowDeleteTaletnBtn && (
                  <div
                    className='flex h-4 w-5 items-center justify-center rounded border bg-primary-black/5 p-0.5 hover:cursor-pointer hover:opacity-80'
                    onClick={() => handleDelete(talendId)}
                  >
                    <Close fill={Colors.BASE_ICON} />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
