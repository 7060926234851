import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { CompanyData, CompanyTableBodyProps } from '../CompanyTable.types'
import { CompanyTableCell } from './CompanyTableCell'
import { CompanyItem } from '../../../types'

export const CompanyTableBody = ({
  data,
  columns,
  isLoading,
  setCompanyDataToEdit,
}: CompanyTableBodyProps) => {
  const placeholderData = Array.from({ length: 20 }, (_, index) => ({
    id: index.toString(),
    name: 'name',
    status: 'status',
    owner_id: 'ownerId',
    updated_at: 'updatedData',
    created_at: 'createdDate',
  }))

  const [dataToMap, setDataToMap] = useState<CompanyItem[]>(data)

  useEffect(() => {
    setDataToMap(isLoading ? placeholderData : data)
  }, [isLoading, data])

  return (
    <tbody>
      {dataToMap.length > 0 &&
        dataToMap.map((row: CompanyItem) => (
          <tr key={row.name + '_' + row.id}>
            {columns.map((column, index) => (
              <td
                key={column.value}
                className={twMerge(
                  'rounded border-b border-b-primary-black/20 p-2 py-4',
                  index !== 0 && 'w-36',
                  index === columns.length - 1 && 'w-12',
                  isLoading && 'animate-pulse',
                )}
              >
                <CompanyTableCell
                  row={row}
                  column={column}
                  isLoading={isLoading}
                  setCompanyDataToEdit={setCompanyDataToEdit}
                />
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  )
}
