import { z } from 'zod'
import { TVFilmCompanyType, TVFilmDistributorType } from '../../types'
import { zodResolver } from '@hookform/resolvers/zod'

const requiredString = (fieldName: string) =>
  z
    .string({ required_error: `${fieldName} is required` })
    .min(1, `${fieldName} is required`)

const requiredEnum = <T extends z.EnumLike>(enumObj: T, fieldName: string) =>
  z.nativeEnum(enumObj, { required_error: `${fieldName} is required` })

export const tVFilmCompanyFormSchema = z
  .object({
    name: requiredString('Company Name'),
    abbr: requiredString('Abbreviation'),
    imdbId: requiredString('IMDB ID'),
    companyType: requiredEnum(TVFilmCompanyType, 'Company Type'),
    distributorType: requiredEnum(TVFilmDistributorType, 'Distributor Type')
      .nullable()
      .optional(),
  })
  .superRefine(({ companyType, distributorType }, ctx) => {
    if (companyType === TVFilmCompanyType.FilmDistributor && !distributorType) {
      ctx.addIssue({
        code: 'custom',
        path: ['distributorType'],
        message: 'Distributor Type is required',
      })
    }
  })

export const tvFilmCompanyFormResolver = zodResolver(tVFilmCompanyFormSchema)

export type TVFilmCompanyFormData = z.infer<typeof tVFilmCompanyFormSchema>
