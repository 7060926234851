import React from 'react'
import { useForm } from 'react-hook-form'

interface ForgotPasswordFormData {
  email: string
}

export const ForgotPassword = ({
  onSubmit,
}: {
  onSubmit: (data: ForgotPasswordFormData) => void
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>()

  return (
    <div className='flex h-[96vh] items-center justify-center'>
      <div className='flex flex-col items-center justify-center'>
        <div className='w-[400px] max-w-[90%] rounded-lg bg-white p-5'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className='text-center text-2xl font-semibold'>
              Forgot Password
            </h2>
            <p className='pt-3 text-center'>
              Enter the email address you used to sign up for your account. We
              will send you instructions to reset your password.
            </p>
            <div className='flex flex-col pt-5'>
              <div className='mb-4'>
                <label htmlFor='email' className='mb-1 block font-semibold'>
                  Email
                </label>
                <input
                  id='email'
                  className={`w-full rounded-md border p-1 ${errors.email ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                  {...register('email', {
                    required: 'Email address is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Invalid email address format',
                    },
                  })}
                />
                {errors.email && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.email.message?.toString()}
                  </p>
                )}
              </div>
              <div className='mb-4'>
                <button
                  type='submit'
                  className='w-full rounded-full bg-red-500 px-4 py-2 text-white'
                >
                  Send Instructions
                </button>
              </div>
              <p className='pt-5 text-center text-sm font-normal leading-5 tracking-tight'>
                Remember your Password?
                <a href='/login' className='text-red-500'>
                  {' '}
                  Log in
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
