import { useMemo } from 'react'
import {
  Image,
  LatestProjectList,
  TalentBiography,
  TalentName,
} from 'components'
import { TalentDetails } from 'hooks'
import { calculateAge, parseDateOfBirth } from 'utils'

interface TalentDetailProps {
  talentDetails?: TalentDetails
}

export const TalentDetail = ({ talentDetails }: TalentDetailProps) => {
  if (!talentDetails) return null

  const {
    talent_name,
    biography,
    profile_image,
    height,
    date_of_birth: dateOfBirth,
    talentFilms,
  } = talentDetails || {}

  const { birthYear, age } = useMemo(() => {
    const birthDate = parseDateOfBirth(dateOfBirth)
    return {
      birthYear: birthDate ? birthDate.getFullYear() : '',
      age: birthDate ? calculateAge(birthDate) : null,
    }
  }, [dateOfBirth])
  const talentProjects = useMemo(
    () => talentFilms?.map(filmData => filmData.film).flat() ?? [],
    [talentFilms],
  )

  return (
    <div className='h-full w-64 space-y-6 bg-primary-white px-3 pb-2'>
      <div className='flex flex-col'>
        <Image
          alt={talent_name}
          src={profile_image}
          className='h-[210px] w-[140px]'
        />
        <TalentName
          firstName={talent_name}
          lastName=''
          size='lg'
          className='pb-2.5 pt-5 text-[22px] font-semibold leading-6 tracking-tight'
        />
        <TalentBiography
          biography={biography}
          birthYear={birthYear}
          age={age}
          height={height}
        />
      </div>
      <LatestProjectList projects={talentProjects} />
      <div>
        {/*<SectionTitle title='IMDB Profile' />*/}
        {/*<Anchor href='' className='flex w-fit items-center gap-0.5'>*/}
        {/*  <span>View full profile on IMDB</span>*/}
        {/*  <Url fill={Colors.BASE_ICON} width={12} height={12} />*/}
        {/*</Anchor>*/}
      </div>
    </div>
  )
}
