import { useState } from 'react'
import { RoleProject } from 'types'
import { Info, Plus } from 'components/ui/icons'
import { Colors } from 'constnants'
import {
  Button,
  IconButton,
  MyRoleModalDelete,
  MyRoleCard,
  EditRoleModalContent,
} from 'components'
import { useDeleteProjectRoleMutation } from 'store/api'

interface MyRoleListProps {
  roles: RoleProject[]
  onOpen: () => void
  projectId?: number
}

export const MyRoleList = ({ roles, onOpen, projectId }: MyRoleListProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState<RoleProject | null>(null)
  const [deleteProjectRole] = useDeleteProjectRoleMutation()

  const handleOpenDeleteModal = (role: RoleProject) => {
    setSelectedRole(role)
    setIsDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setSelectedRole(null)
    setIsDeleteModalOpen(false)
  }

  const handleDeleteRole = () => {
    if (selectedRole) {
      deleteProjectRole({
        projectId: projectId ?? 0,
        roleId: selectedRole.roleId,
      })
      handleCloseDeleteModal()
    }
  }

  const handleOpenEditModal = (role: RoleProject) => {
    setSelectedRole(role)
    setIsEditModalOpen(true)
  }

  const handleCloseEditModal = () => {
    setSelectedRole(null)
    setIsEditModalOpen(false)
  }

  return (
    <div className='h-full w-full bg-grey-2 p-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-1 pb-3 pt-2'>
          <h3 className='text-2xl font-semibold text-primary-black'>Roles</h3>
          <IconButton>
            <Info fill={Colors.BASE_ICON} width={20} height={20} />
          </IconButton>
        </div>

        <Button
          kind='filled'
          size='medium'
          className='w-fit bg-primary-red hover:bg-red-9 active:bg-red-10'
          onClick={onOpen}
        >
          <div className='flex items-center'>
            <span className='pr-1 text-sm font-semibold'>New Role</span>
            <Plus fill={Colors.ICON_WHITE} width={16} height={16} />
          </div>
        </Button>
      </div>
      <ul className='grid h-fit w-full grid-cols-3 gap-5'>
        {roles.map(role => (
          <li key={role.roleId}>
            <MyRoleCard
              role={role}
              onOpenDeleteModal={() => handleOpenDeleteModal(role)}
              onOpenEditModal={() => handleOpenEditModal(role)}
            />
          </li>
        ))}
      </ul>

      {selectedRole && (
        <>
          <MyRoleModalDelete
            isOpen={isDeleteModalOpen}
            onClose={handleCloseDeleteModal}
            roleName={selectedRole.roleName}
            onDelete={handleDeleteRole}
          />

          <EditRoleModalContent
            isOpen={isEditModalOpen}
            onClose={handleCloseEditModal}
            role={selectedRole}
            projectId={projectId ?? 0}
          />
        </>
      )}
    </div>
  )
}
