import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  OptionFilter,
  OptionType,
  ReleaseCalendarSelectKey,
  ReleaseCalendarState,
  ToggleSwitchTitle,
} from 'types'
import { releaseCalendarData } from 'constnants'
import { formatRevenueData } from './revenue-formatter'
import { buildWeeks } from './week-builder'
import { buildFilmCompanyReleases } from './film-formatter'
import { buildEventGroups } from './event-builder'
import {
  buildCompanyGroups,
  buildFilmGroups,
  buildFilmsGenres,
  buildTheaterReleaseTypes,
  buildTvGenres,
  buildTvReleaseTypes,
} from './company-group-option-builder'
import { moveOneMonthBack } from 'utils'

const initialStateSwitchFilmSelect = {
  allGenres: true,
  allGroups: true,
  broadcast: true,
  allTVTypes: true,
  cableBroadcast: true,
  major: true,
  minor: true,
  other: true,
  streamer: true,
  wideLimited: true,
  allFilmsGenres: true,
}
const initialState: ReleaseCalendarState = {
  switchTitle: {
    heatMap: true,
    theatrical: true,
    sportingEvents: true,
    holidayAndSchool: true,
    premieresEvents: true,
  },
  switchFilmSelect: initialStateSwitchFilmSelect,
  selectedOptions: {},
  releaseCalendarData: [],
  allReleaseCalendarData: releaseCalendarData,
  selectedDateRange: {
    id: '2',
    value: '2',
    label: '2 month',
  },
  releaseWeeks: buildWeeks(2),
  revenue: [],
  films: [],
  events: [],
  loading: false,
  error: null,
  startDate: null,
  distributors: [],
  tvDistributors: [],
  serverData: {},
  groups: [],
}

export const releaseCalendarSlice = createSlice({
  name: 'releaseCalendar',
  initialState,
  reducers: {
    setSwitchTitle: (
      state,
      action: PayloadAction<{ id: keyof ToggleSwitchTitle; checked: boolean }>,
    ) => {
      const { id, checked } = action.payload

      state.switchTitle[id] = checked

      if (id === ReleaseCalendarSelectKey.THEATRICAL) {
        state.switchFilmSelect[ReleaseCalendarSelectKey.MAJOR] = checked
        state.switchFilmSelect[ReleaseCalendarSelectKey.MINOR] = checked
        state.switchFilmSelect[ReleaseCalendarSelectKey.OTHER] = checked
        state.switchFilmSelect['allGroups'] = checked
        state.switchFilmSelect['wideLimited'] = checked
      }
      if (id === ReleaseCalendarSelectKey.PREMIERES_EVENTS) {
        state.switchFilmSelect[ReleaseCalendarSelectKey.STREAMERS] = checked
        state.switchFilmSelect[ReleaseCalendarSelectKey.CABLE_BROADCAST] =
          checked
        state.switchFilmSelect['allGenres'] = checked
        state.switchFilmSelect['allTVTypes'] = checked
      }
    },
    setSwitchFilmSelect: (
      state,
      action: PayloadAction<{ id: string; checked: boolean }>,
    ) => {
      state.switchFilmSelect[action.payload.id] = action.payload.checked
      // if (action.payload.id === 'other') {
      //   state.switchFilmSelect['allGroups'] = action.payload.checked
      //   state.switchFilmSelect['wideLimited'] = action.payload.checked
      // }
    },
    setOption(
      state,
      action: PayloadAction<{ id: string; options: OptionFilter[] }>,
    ) {
      const { id, options } = action.payload
      if (!state.selectedOptions[id]) {
        state.selectedOptions[id] = []
      }

      state.selectedOptions = {
        ...state.selectedOptions,
        [id]: options,
      }
    },
    addOptionToReleaseCalendar: (
      state,
      action: PayloadAction<{ id: string; options: OptionFilter[] }>,
    ) => {
      const { id, options } = action.payload
      if (!state.selectedOptions[id]) {
        state.selectedOptions[id] = []
      }

      state.selectedOptions = {
        ...state.selectedOptions,
        [id]: options,
      }
      state.films = buildFilmCompanyReleases(
        state?.serverData?.films || [],
        state.selectedOptions,
      )
    },
    setSelectedDateRange: (state, action: PayloadAction<OptionType>) => {
      const dateRange = action.payload
      state.selectedDateRange = { ...dateRange }
      state.releaseWeeks = buildWeeks(Number(dateRange.value), state.startDate)
    },
    initRevenue: (state, action: PayloadAction<any>) => {
      state.serverData = action.payload
      state.revenue = formatRevenueData(action?.payload?.revenue)
      state.films = buildFilmCompanyReleases(
        action?.payload?.films,
        state.selectedOptions,
      )
      state.events = buildEventGroups(action?.payload?.events)
      state.loading = false
      state.error = null
    },
    setLoading: state => {
      state.loading = true
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.loading = false
    },
    setStartDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.loading = true
        state.startDate = moveOneMonthBack(action.payload)
        state.releaseWeeks = buildWeeks(
          Number(state.selectedDateRange.value),
          action.payload,
        )
      } else {
        state.startDate = null
        state.releaseWeeks = buildWeeks(
          Number(state.selectedDateRange.value),
          null,
        )
      }
    },
    initCompanies: (state, action: PayloadAction<any>) => {
      const { companies, groups, tvGenres, releaseTypes, movieGenres } =
        action.payload
      const { distributors, tvDistributors } = buildCompanyGroups(companies)
      const sidebarGroups = buildFilmGroups(groups)
      const sidebarGenres = buildTvGenres(tvGenres)
      const sidebarFilmsGenres = buildFilmsGenres(movieGenres)
      const theaterReleaseType = buildTheaterReleaseTypes(releaseTypes)
      const tvReleaseType = buildTvReleaseTypes(releaseTypes)
      state.distributors = [
        ...distributors,
        theaterReleaseType,
        sidebarFilmsGenres,
        sidebarGroups,
      ]
      state.tvDistributors = [...tvDistributors, sidebarGenres, tvReleaseType]

      state.selectedOptions[ReleaseCalendarSelectKey.MAJOR] = []
      state.selectedOptions[ReleaseCalendarSelectKey.MINOR] = []
      state.selectedOptions[ReleaseCalendarSelectKey.OTHER] = []
      state.selectedOptions[ReleaseCalendarSelectKey.CABLE_BROADCAST] = []
      state.selectedOptions[ReleaseCalendarSelectKey.STREAMERS] = []
      state.selectedOptions['wideLimited'] = []
      state.selectedOptions['allGroups'] = []
      state.selectedOptions['allGenres'] = []
      state.selectedOptions['allTVTypes'] = []
      state.selectedOptions['allFilmsGenres'] = []

      state.films = buildFilmCompanyReleases(
        state?.serverData?.films,
        state.selectedOptions,
      )
    },
  },
})

export const {
  actions: releaseCalendarActions,
  reducer: releaseCalendarReducer,
} = releaseCalendarSlice
