import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type MgmtLayoutProps = PropsWithChildren<{
  className?: string
  contentClassName?: string
}>

export const MgmtLayout = ({
  children,
  className,
  contentClassName,
}: MgmtLayoutProps) => {
  return (
    <div
      className={twMerge(
        'relative mr-2 flex h-full overflow-hidden rounded-tl-md rounded-tr-md bg-primary-white',
        className,
      )}
    >
      <div className={twMerge('w-full', contentClassName)}>{children}</div>
    </div>
  )
}
