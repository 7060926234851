import { ReactNode } from 'react'
import { FilterSearchButton, Input, SearchListMenu } from 'components'
import { OptionType } from 'types'
import { Search, SquarePlus } from 'components/ui/icons'
import { Colors } from 'constnants'
import { useClickOutsideComponent, useEntityLookupByField } from 'hooks'

interface EntityLookupByFieldButtonProps<T = number> {
  className?: string
  disabled?: boolean
  leftIcon?: ReactNode
  label: string
  options: OptionType<T>[]
  onSelect: (option: OptionType<T>) => void
  filter?: (option: OptionType<T>) => void
  load: (searchTerm: string) => Promise<void>
}

export const EntityLookupByFieldButton = <T,>({
  className,
  disabled,
  label,
  options,
  onSelect,
  filter,
  load,
}: EntityLookupByFieldButtonProps<T>) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)

  const { searchTerm, suggestions, setSearchTerm, handleInputChange } =
    useEntityLookupByField({
      options,
      filter,
      load,
    })

  const handleOptionClick = (option: OptionType<T>) => {
    setSearchTerm('')
    setIsComponentVisible(false)
    onSelect(option)
  }

  return (
    <FilterSearchButton
      name={label}
      leftIcon={<SquarePlus fill={Colors.BASE_ICON} />}
      isComponentVisible={isComponentVisible}
      setIsComponentVisible={() =>
        setIsComponentVisible(prevState => !prevState)
      }
      ref={ref}
      disabled={disabled}
      filterWrapperClassName={className}
    >
      <div className='pt-3'>
        <Input
          value={searchTerm}
          onChange={handleInputChange}
          leftIcon={<Search fill={Colors.BASE_ICON} width={16} height={16} />}
          containerWrapperClassName='px-3'
        />
        <SearchListMenu list={suggestions} handleChange={handleOptionClick} />
      </div>
    </FilterSearchButton>
  )
}
