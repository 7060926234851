import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { CompFinderTableBodyProps } from '../CompFinderTable.types'
import { movieType } from 'types'
import { CompFinderTableCell } from './CompFinderTableCell'
import { Checkbox } from '../../ui'

export const CompFinderTableBody = ({
  data,
  columns,
  isLoading,
  selectedRows,
  handleCheckboxChange,
  activeTableTab,
  sortingBY,
  isTheatersOpened,
}: CompFinderTableBodyProps) => {
  const placeholderData = Array.from({ length: 20 }, (_, index) => ({
    film_id: index,
    film_name: 'string',
    abbreviation: 'string',
    release_date_id: 20251225,
    ow_theatres: 10000,
    open_weekend: 100000,
    total_gross: 100000000,
    budget: 10000000,
    film_group_name: 'string',
    genres: ['string'],
    average_ticket_price: 100,
    box_office_universe: 'string',
    budget_range: 'string',
    company_id: 10000,
    company_key: 'string',
    company_name: 'string',
    company_type: 'string',
    current_year_ticket_price: 100,
    distributor_type: 'string',
    film_group_id: 10000,
    film_group_key: 'string',
    film_key: 'string',
    film_type: 'string',
    is_comp_finder: false,
    opening_weekend_adjusted: 100000,
    tags: ['string', 'string'],
    rating: 'string',
    psrr: 'string',
    franchise: 'string',
    universe: 'string',
    isHybrid: false,
    hasPlatformRelease: false,
    primary_genre_id: 10000,
    primary_genre_key: 'string',
    primary_genre_name: 'string',
    release_day: 1,
    release_month: 1,
    release_year: 2021,
    secondary_genre_id: 10000,
    secondary_genre_key: 'string',
    secondary_genre_name: 'string',
    subgenres: 'string',
    group: 'string',
    platformRelease: [
      {
        monday: 'string',
        tuesday: 'string',
        wednesday: 'string',
        thursday: 'string',
      },
    ],
  }))

  const [dataToMap, setDataToMap] = useState<movieType[]>(data)

  useEffect(() => {
    setDataToMap(isLoading ? placeholderData : data)
  }, [isLoading, data])

  return (
    <tbody>
      {dataToMap.length > 0 &&
        dataToMap.map((row: movieType) => (
          <tr key={row.film_id}>
            <td
              className={twMerge(
                'w-9 min-w-9 border-b border-b-primary-black/20 pl-2.5',
              )}
            >
              <Checkbox
                checked={selectedRows?.includes(row.film_id)}
                onChange={() => {
                  handleCheckboxChange && handleCheckboxChange(row.film_id)
                }}
                kind='small'
              />
            </td>
            {columns
              .filter(column =>
                activeTableTab === 'Group'
                  ? column.value !== 'genres'
                  : column.value !== 'film_group_key',
              )
              .map(column => (
                <td
                  key={column.value}
                  className={twMerge(
                    'h-8 cursor-pointer border-b border-b-primary-black/20 p-1 py-1.5',
                    isLoading && 'animate-pulse',
                    column.className && column.className,
                    column.value === sortingBY && 'bg-primary-red/30',
                    column.isHidden && !isTheatersOpened && 'hidden',
                  )}
                >
                  <CompFinderTableCell
                    row={row}
                    column={column}
                    isLoading={isLoading}
                    isHybrid={row.hybrid}
                    hasPlatformRelease={
                      !!(
                        row.monday ||
                        row.tuesday ||
                        row.wednesday ||
                        row.thursday
                      )
                    }
                  />
                </td>
              ))}
          </tr>
        ))}
    </tbody>
  )
}
