import { FilmMetricCategory, OptionType } from 'types'

export const filmMetricCategoryOptions: OptionType<FilmMetricCategory>[] = [
  { id: '0', label: 'Awareness', value: FilmMetricCategory.Awareness },
  { id: '1', label: 'Interest', value: FilmMetricCategory.Interest },
  { id: '2', label: 'Location', value: FilmMetricCategory.Location },
  { id: '3', label: 'Pay to See', value: FilmMetricCategory.PayToSee },
  {
    id: '4',
    label: 'Unaided Awareness',
    value: FilmMetricCategory.UnaidedAwareness,
  },
  {
    id: '5',
    label: 'Awareness Interest',
    value: FilmMetricCategory.AwarenessInterest,
  },
  { id: '6', label: 'Heat', value: FilmMetricCategory.Heat },
  { id: '7', label: 'PLF', value: FilmMetricCategory.Plf },
]
