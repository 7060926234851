import { ColumnsProjectTable, MyProjectAssignedUser } from 'types'
import { AssigneeAvatar } from 'components'
import { getInitials } from 'utils'

interface TableAssignedUserCellProps {
  column: ColumnsProjectTable
  row: MyProjectAssignedUser
}

export const TableAssignedUserCell = ({
  column,
  row,
}: TableAssignedUserCellProps) => {
  const initials = getInitials(row.fullName)

  switch (column.value) {
    case 'fullName':
      return (
        <div className='flex items-center gap-2'>
          <AssigneeAvatar initials={initials} />
          <span className='text-sm font-medium text-primary-black'>
            {row.fullName}
          </span>
        </div>
      )
    case 'email':
      return <span className='text-xs text-primary-black'>{row.email}</span>
    case 'userType':
      return <span className='text-xs text-primary-black'>{row.userType}</span>
    default:
      return <span></span>
  }
}
