import { TypeSelectorTitle, TypeSelector } from 'components'
import { NewProjectTypeSelector } from 'types'
import { isArrayContainsValue } from '../../constnants'

interface MovieDetailsGenreSectionProps {
  title: string
  descriptions: string
  genres: NewProjectTypeSelector[]
  handleChange: (value: string) => void
  selectedGenres: string[] | string
}

export const MovieDetailsGenreSection = ({
  title,
  descriptions,
  genres,
  handleChange,
  selectedGenres,
}: MovieDetailsGenreSectionProps) => {
  return (
    <div className='flex w-full flex-col pb-3'>
      <TypeSelectorTitle title={title} />
      <span className='text-md leading-6 text-grey-7'>{descriptions}</span>
      <div className='flex flex-wrap gap-1'>
        {genres.map(genre => (
          <TypeSelector
            key={genre.id}
            title={genre.name}
            kind='oval'
            size='large'
            onClick={() => handleChange(genre.id)}
            isSelected={
              Array.isArray(selectedGenres)
                ? isArrayContainsValue(selectedGenres, genre.id)
                : selectedGenres === genre.id
            }
          />
        ))}
      </div>
    </div>
  )
}
