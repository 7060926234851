import { Search } from 'components/ui/icons'
import { Input } from 'components/ui'
import { Colors } from 'constnants'
import { OptionType } from 'types'
import { useGetProjectsQuery } from 'store/api'
import { useMemo, useState } from 'react'

interface ProjectsListSearchProps {
  filter?: (option: OptionType<number>) => boolean
  onSelectProject?: (option: OptionType<number>) => void
}

export const ProjectsListSearch = ({
  filter,
  onSelectProject,
}: ProjectsListSearchProps) => {
  const companyId = localStorage.getItem('companyId')
  const [searchTerm, setSearchTerm] = useState('')

  const { projectOptions } = useGetProjectsQuery(
    {
      pageSize: 1000,
      pageNumber: 1,
    },
    {
      skip: !companyId,
      selectFromResult: ({ data, ...rest }) => {
        const projectOptions: OptionType<number>[] =
          data?.projects.map(project => ({
            id: project.id.toString() ?? '',
            value: project.id,
            label: project.name,
          })) || []
        return {
          projectOptions,
          ...rest,
        }
      },
    },
  )

  const projectOptionsFiltered = useMemo(
    () =>
      projectOptions.filter(option => {
        const matched = option.label
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
        if (typeof filter === 'function') {
          return matched && filter(option)
        }
        return matched
      }) || [],
    [projectOptions, searchTerm, filter],
  )

  return (
    <>
      <Input
        containerWrapperClassName={'px-1'}
        wrapperClassName={'z-10'}
        leftIcon={<Search fill={Colors.BASE_ICON} width={16} height={16} />}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <ul className={'mt-2 text-sm'}>
        {projectOptionsFiltered.map(project => (
          <li
            key={project.id}
            className={
              'my-0.5 cursor-pointer rounded px-1 py-1 hover:bg-primary-black/5'
            }
            onClick={() => onSelectProject?.(project)}
          >
            {project.label}
          </li>
        ))}
      </ul>
    </>
  )
}
