import { Export, Share } from 'components/ui/icons'
import { Colors } from 'constnants'

// set hidden to not display Share and export
export const ReleaseCalendarTopSection = () => {
  return (
    <div className='flex flex-col bg-primary-white p-2'>
      <h2 className='text-2xl font-semibold text-primary-black'>
        Release Calendar
      </h2>
      <div className='flex justify-between'>
        <span className='text-sm font-medium leading-6 text-primary-black'>
          At-a-glance insight into major and minor events in the entertainment
          industry
        </span>

        <div className='flex hidden flex-row items-center gap-1'>
          <Share
            fill={Colors.NAV_ICON_THREE}
            width={16}
            height={16}
            className='h-fit w-fit rounded bg-primary-black/5 p-1 hover:cursor-pointer'
          />
          <Export
            fill={Colors.NAV_ICON_THREE}
            width={16}
            height={16}
            className='h-fit w-fit rounded bg-primary-black/5 p-1 hover:cursor-pointer'
          />
        </div>
      </div>
    </div>
  )
}
