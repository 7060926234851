import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { paramsSerializer } from './utils'

export const apiURL = 'https://ga-api-100724.azurewebsites.net'
export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiURL,
    paramsSerializer,
    prepareHeaders: headers => {
      const localToken = localStorage.getItem('access_token')

      if (localToken) headers.set('Authorization', `Bearer ${localToken}`)

      return headers
    },
  }),
  tagTypes: [
    'MyProject',
    'MyUser',
    'MyTalent',
    'MyCompany',
    'TalentMetrics',
    'MyManagementUser',
    'ProjectRole',
    'TalentMetricsChart',
    'ReleaseCalender',
    'CompFinder',
    'FilmMetrics',
  ],
  endpoints: () => ({}),
})
