import { useEffect, useState } from 'react'

const MOBILE = 766

export const useIsMobile = (screenSize: number = MOBILE): boolean => {
  const [isMobile, setIsMobile] = useState(false)

  const handlerIsMobile = (): void => {
    setIsMobile(window.innerWidth < screenSize)
  }

  useEffect(() => {
    handlerIsMobile()

    window.addEventListener('resize', handlerIsMobile)

    return (): void => {
      window.removeEventListener('resize', handlerIsMobile)
    }
  }, [])

  return isMobile
}
