import { ChangeEvent, useEffect, useState } from 'react'
import { Logline } from 'types'
import { BaseModal, Button, LogLineCard } from 'components'
import { useUpdatedProjectMutation } from 'store/api'
import { toastError } from 'utils'

interface AddOrEditLoglineModalContentProps {
  isOpen: boolean
  onClose: () => void
  selectedLogline?: Logline | null
  preparedLoglines?: Logline[]
  projectId: number
  isEdit?: boolean
  projectName: string | undefined
}

const initialData: Logline = {
  id: '',
  myLogline: '',
  name: '',
  lastEdited: '',
}

export const AddOrEditLoglineModalContent = ({
  selectedLogline,
  isOpen,
  onClose,
  preparedLoglines,
  projectId,
  isEdit,
  projectName,
}: AddOrEditLoglineModalContentProps) => {
  const [updateLogline] = useUpdatedProjectMutation()
  const [formData, setFormData] = useState<Logline>(initialData)

  useEffect(() => {
    if (isEdit && selectedLogline) {
      setFormData(selectedLogline)
    } else {
      setFormData(initialData)
    }
  }, [isEdit, setFormData, selectedLogline])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.length <= 500) {
      setFormData({ ...formData, myLogline: value })
    }
  }

  const handleSave = async () => {
    if (!preparedLoglines || !projectId) {
      toastError('Project data is not available')
      return
    }

    try {
      if (isEdit && selectedLogline) {
        const updatedLoglines = preparedLoglines.reduce(
          (acc, logline) => {
            acc[logline.id] =
              logline.id === selectedLogline.id
                ? {
                    ...logline,
                    myLogline: formData.myLogline,
                    lastEdited: new Date().toISOString(),
                  }
                : logline
            return acc
          },
          {} as { [key: string]: Logline },
        )

        await updateLogline({
          id: projectId,
          project: {
            name: projectName ?? '',
            log_lines: updatedLoglines,
          },
        })
          .unwrap()
          .then(() => onClose())
      } else {
        const loglineId = (preparedLoglines.length + 1).toString()
        const newLogline: Logline = {
          id: loglineId,
          name: `Logline V${loglineId}`,
          lastEdited: new Date().toISOString(),
          myLogline: formData.myLogline,
          isActive: preparedLoglines.length === 0,
        }

        const updatedLoglines = {
          ...preparedLoglines.reduce(
            (acc, logline) => {
              acc[logline.id] = logline
              return acc
            },
            {} as { [key: string]: Logline },
          ),
          [loglineId]: newLogline,
        }

        await updateLogline({
          id: projectId,
          project: {
            name: projectName ?? '',
            log_lines: updatedLoglines,
          },
        })
          .unwrap()
          .then(() => {
            setFormData(initialData)
            onClose()
          })
      }
    } catch (e) {
      const { error, data } = e as { data?: any; error?: string }
      toastError(data, error)
    }
  }

  return (
    <BaseModal isOpen={isOpen} handleClose={onClose} maxWidth='716px'>
      <div className='flex max-h-[900px] flex-col overflow-y-auto'>
        <span className='block py-3 text-lg font-medium leading-6 text-primary-black'>
          {isEdit ? `Edit ${selectedLogline?.name}` : 'Add new Logline version'}
        </span>
      </div>

      <div className='flex flex-col space-y-5'>
        <LogLineCard
          exampleLogline='“Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet. “otestate sunt infirmi, servilis, licet, lex pertinet.  Sunt infirmi, servilis, licet, lex pertinet. “otestate sunt infirmi, servilis, licet, lex pertinet.”'
          descriptions='Please add three loglines per project. The first logline is only one sentence with no more than 75 characters. The second logline is a more fleshed-out description. The third logline is the same as the second one but can include the names of actors or filmmakers.'
          value={formData.myLogline}
          handleChange={e => handleChange(e)}
          isEdit={isEdit}
        />

        <div className='ml-auto flex gap-3'>
          <Button
            kind='text'
            size='medium'
            className='w-fit py-1.5'
            onClick={onClose}
          >
            <span className='px-1'>Cancel</span>
          </Button>
          <Button
            kind='filled'
            size='medium'
            className='w-fit bg-primary-red py-1.5 hover:bg-red-9 active:bg-red-10'
            onClick={handleSave}
          >
            <span className='px-1'>Save</span>
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
