import { ChangeEvent, useMemo, useState } from 'react'
import { IconButton, Input, Select, Textarea } from 'components'
import { OptionFilter, OptionType, RoleProject } from 'types'
import { Close, XCircle } from 'components/ui/icons'
import { Colors, newProjectRoleOptions } from 'constnants'
import { twMerge } from 'tailwind-merge'
import { useHandleInputRoleChange } from 'hooks'

interface RoleCardProps {
  roleName: string
  roleType: string
  attachedTalent: string
  description: string
  onUpdateChange: (data: Partial<RoleProject>) => void
  onDeleteRole?: () => void
}

export const RoleCard = ({
  roleName,
  roleType,
  attachedTalent,
  description,
  onDeleteRole,
  onUpdateChange,
}: RoleCardProps) => {
  const [focusedElementName, setFocusedElementName] = useState<
    'roleName' | 'roleType'
  >()

  const {
    filteredData,
    showAutocomplete,
    handleInputChange,
    setShowAutocomplete,
  } = useHandleInputRoleChange(onUpdateChange)

  const talentsOptions = useMemo(
    () =>
      filteredData.map(({ talent_id, talent_name }) => ({
        id: talent_id,
        label: talent_name,
        value: talent_id,
      })),
    [filteredData],
  )

  return (
    <div className='grid grid-cols-[3fr,4fr] gap-3'>
      <div className='space-y-2'>
        <Input
          placeholder={focusedElementName !== 'roleName' ? 'Role Name' : ''}
          value={roleName}
          onChange={e => onUpdateChange({ roleName: e.target.value })}
          wrapperClassName='w-full h-8'
          inputClassName='pl-2 text-sm'
          onFocus={() => setFocusedElementName('roleName')}
          onBlur={() => setFocusedElementName(undefined)}
        />

        <Select
          value={roleType}
          placeholder='Role type'
          options={newProjectRoleOptions}
          handleClick={option => {
            console.log('option', option)
            onUpdateChange({ roleType: option })
          }}
          buttonClass={twMerge(
            'py-[5.5px]',
            focusedElementName === 'roleType' &&
              'bg-primary-black/10 hover:border-primary-black/10',
          )}
          selectedLabelClass='text-sm font-normal text-primary-black'
          hasRadioButton={false}
          onFocus={() => setFocusedElementName('roleType')}
          onBlur={() => setFocusedElementName(undefined)}
        />

        <Input
          id='attachedTalent'
          placeholder='Attached talent'
          value={attachedTalent}
          onChange={e => handleInputChange(e, 'attachedTalent')}
          wrapperClassName='w-full h-8'
          containerWrapperClassName='relative'
          inputClassName='pl-2 text-sm'
          rightIcon={
            attachedTalent?.length ? (
              <XCircle
                onClick={() => {
                  onUpdateChange({
                    attachedTalent: { id: '', value: '', label: '' },
                  })
                  setShowAutocomplete(false)
                }}
                className='mr-1 hover:cursor-pointer'
              />
            ) : (
              <></>
            )
          }
          autoComplete='off'
          isShowAutocomplete={showAutocomplete}
          autocompleteData={talentsOptions}
          handleAutocompleteClick={option =>
            onUpdateChange({
              attachedTalent: option,
            })
          }
        />
      </div>

      <div className='flex gap-3'>
        <Textarea
          placeholder='A brief description of the role'
          value={description}
          onChange={e => onUpdateChange({ description: e.target.value })}
          className='h-full '
        />

        {typeof onDeleteRole === 'function' ? (
          <IconButton
            className='my-auto h-fit rounded hover:bg-primary-black/5'
            onClick={onDeleteRole}
          >
            <Close fill={Colors.BASE_ICON} width={20} height={20} />
          </IconButton>
        ) : (
          <div className='w-8' />
        )}
      </div>
    </div>
  )
}
